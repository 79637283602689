import router from "@/router"

const FETCH_STATUS = { FETCHING: 'FETCHING', DONE: 'DONE'}
const DEFAULT_TITLE = {
    LQ: 'Registrasi Program Keringanan Utang',
    SIGN_UP: 'Sign Up Program Keringanan Utang',
    DOCUMENT_COLLECTION: 'Daftar Dokumen',
    RDP: 'Pembukaan Rekening Dana Pelunasan (RDP)'
}

const state = () => ({
    pendingActivityData: [],
    fetchStatus: FETCH_STATUS.FETCHING
})

const getters = {
    getPendingActivityData(state) {
        return state.pendingActivityData
    },
    getFetchStatus(state) {
        return state.fetchStatus
    }
}

const actions = {
    async initialPendingActivityData({ dispatch, commit, rootGetters }){
        commit('setFetchingStatus', { status: FETCH_STATUS.FETCHING })
        dispatch('services/requestLatestService', null, { root: true })
        dispatch('formAmalanProtect/requestProtectLast', null, { root: true })
        dispatch('user/fetchDocumentPoa', null, { root: true })
        await dispatch('contact/requestSignUpStatus', null, { root: true })
        commit('setFetchingStatus', { status: FETCH_STATUS.DONE })
        dispatch('setPendingActivityData', {
            userStage: rootGetters['user/getUserStage'],
            signUpStatus: rootGetters['contact/getSignUpStatus'],
            lastAmalanProtect: rootGetters['formAmalanProtect/getLastProtect'],
            latestService: rootGetters['services/getLatestService'],
            timeToDocCollection: rootGetters['docCollection/getTimeToDocCollection'],
            billCompleted: rootGetters['docCollection/getbillStatus'],
            docCompleted: rootGetters['docCollection/getDocStatus'],
            poaDocument: rootGetters['user/getDocumentPoa'],
            lastAdditionalSuf: rootGetters['additionalSUF/getLastAdditionalSuf'],
            lastUpgradePackage: rootGetters['upgradePricingPackage/getLastUpgradePackage'],
            negotiationResultApprovalFlag: rootGetters['negotiationResult/getNegotiationResultApprovalFlag']
        })
    },
    setPendingActivityData({ commit }, {
        userStage,
        signUpStatus,
        lastAmalanProtect,
        latestService,
        timeToDocCollection,
        billCompleted,
        docCompleted,
        poaDocument,
        lastAdditionalSuf,
        lastUpgradePackage,
        negotiationResultApprovalFlag
    }) {
        commit('setPendingActivityData', {
            userStage,
            signUpStatus,
            lastAmalanProtect,
            latestService,
            timeToDocCollection,
            billCompleted,
            docCompleted,
            poaDocument,
            lastAdditionalSuf,
            lastUpgradePackage,
            negotiationResultApprovalFlag
        })
    },
    checkPendingAdditionalSuf({rootGetters}){
        const lastAdditionalSufStatus = rootGetters['additionalSUF/getLastAdditionalSufStatus']
        const lastInstallmentPayment = rootGetters['additionalSUF/getLastInstallmentPayment']
        const lastUpgradePackageStatus = rootGetters['upgradePricingPackage/getLastUpgradePackageStatus']

        if(lastInstallmentPayment.status !== 200) return router.push({ name: 'Loan Management - Home' })

        if(lastInstallmentPayment.data.is_exist) return router.push({ name: 'Loan Management - Home' })

        if(lastAdditionalSufStatus === 201 || lastAdditionalSufStatus === 202) return router.push({ name: 'Loan Management - Additional Suf - Payment Page' })
        
        if(lastAdditionalSufStatus === 203) return router.push({ name: 'Loan Management - Additional Suf - Sign' })

        if(lastUpgradePackageStatus === 201 || lastUpgradePackageStatus === 202) return router.push({ name: 'Loan Management - Upgrade Package - Payment Page' })

        return
    }
}

const mutations = {
    setFetchingStatus(state, payload){
        state.fetchStatus = payload.status
    },
    setPendingActivityData(state, {
        userStage,
        signUpStatus,
        lastAmalanProtect,
        latestService,
        timeToDocCollection,
        billCompleted,
        docCompleted,
        poaDocument,
        lastAdditionalSuf,
        lastUpgradePackage,
        negotiationResultApprovalFlag
    }) {
        state.pendingActivityData = []
        if(signUpStatus !== 200 && userStage && (userStage === 'lq-loan-list' || userStage === 'lq-loan-succeed')){
            state.pendingActivityData.push({
                title: DEFAULT_TITLE.LQ,
                body: userStage === 'lq-loan-list' ? 'Lengkapi data utang Anda' : 'Lengkapi informasi tambahan Anda',
                link: userStage === 'lq-loan-list' ? '/lead-qualification/daftar-pinjaman' : '/lead-qualification/informasi-tambahan',
                dateTime: null
            })
        }

        if(signUpStatus === 201 || signUpStatus === 202){
            state.pendingActivityData.push({
                title: DEFAULT_TITLE.SIGN_UP,
                body: signUpStatus === 201 ? 'Lanjutkan proses tanda tangan Anda' : 'Lanjutkan proses pembayaran Anda',
                link: signUpStatus === 201 ? '/sign-up/onboarding' : '/sign-up/onboarding',
                dateTime: null
            })
        }

        if(signUpStatus === 200 && (lastUpgradePackage.status === 201 || lastUpgradePackage.status === 202)){
            state.pendingActivityData.push({
                title: 'Peningkatan Paket Layanan',
                body: 'Lanjutkan proses pembayaran Anda',
                link: '/loan-management/upgrade-package/payment',
                dateTime: null
            })
        }

        if(signUpStatus === 200 && negotiationResultApprovalFlag){
            state.pendingActivityData.push({
                title: 'Persetujuan Hasil Negosiasi',
                body: 'Lakukan Persetujuan Hasil Negosiasi Pinjaman Anda sekarang!',
                link: '/negotiation-process/result',
                dateTime: null
            })
        }

        if(signUpStatus === 200 && (lastAdditionalSuf.status === 201 || lastAdditionalSuf.status === 202 || lastAdditionalSuf.status === 203)){
            state.pendingActivityData.push({
                title: lastAdditionalSuf.data?.type === 'Add' ? 'Penambahan Data Pinjaman Baru' : 'Perubahan Data Pinjaman',
                body: lastAdditionalSuf.status === 203 ? 'Lanjutkan proses tanda tangan Anda' : 'Lanjutkan proses pembayaran Anda',
                link: lastAdditionalSuf.status === 203 ? '/loan-management/additional-suf/sign' : '/loan-management/additional-suf/payment',
                dateTime: null
            })
        }

        if(signUpStatus === 200 && timeToDocCollection){
            state.pendingActivityData.push({
                title: DEFAULT_TITLE.DOCUMENT_COLLECTION,
                body: !billCompleted && docCompleted ? 'Anda belum melengkapi Lembar Tagihan' : billCompleted && !docCompleted ? 'Anda belum melengkapi Dokumen Wajib' : 'Anda belum melengkapi dokumen Lembar Tagihan dan Dokumen Wajib',
                link: !billCompleted && docCompleted ? '/doc-collection/lembar-tagihan' : billCompleted && !docCompleted ? '/doc-collection/dokumen-wajib' : '/doc-collection/lembar-tagihan',
                dateTime: null
            })
        }

        if(poaDocument && !poaDocument.status){
            const additionalData = JSON.parse(poaDocument.request_info)
            const preFormDone = additionalData.rdp_preform_done_at ? true : false

            state.pendingActivityData.push({
                title: DEFAULT_TITLE.RDP,
                body: !poaDocument.bni_kyc_uuid ? 'Lanjutkan proses e-KYC Anda' : !preFormDone ? 'Lengkapi informasi tambahan Anda' : 'Lanjutkan proses tanda tangan Anda',
                link: '/rdp/document-poa',
                dateTime: poaDocument.created_at
            })
        }

        if(lastAmalanProtect.status === 201 || lastAmalanProtect.status === 202){
            state.pendingActivityData.push({
                title: 'amalanPROTECT',
                body: lastAmalanProtect.status === 201 ? 'Lanjutkan proses tanda tangan Anda' : 'Lanjutkan proses pembayaran Anda',
                link: lastAmalanProtect.status === 201 ? '/amalan-protect/tanda-tangan/' + lastAmalanProtect.Id : '/amalan-protect/pembayaran/' + lastAmalanProtect.Id,
                dateTime: lastAmalanProtect.dateTime
            })
        }

        if(latestService && latestService.service_stage && latestService.service_stage.name && latestService.service_stage.name === 'CC_waiting for payment'){
            state.pendingActivityData.push({
                title: latestService.service_catalog.name || 'Credit Checkup',
                body: 'Lanjutkan proses pembayaran Anda',
                link: '/credit-checkup/checkout-summary/' + latestService.id,
                dateTime: latestService.created_at
            })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
