<script setup>
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import BasicCollapsable from '@/components/Collapsable/BasicCollapsable.vue'
import { useRouter, useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

const router = useRouter()
const route = useRoute()
const store = useStore()

const faqs = computed(() => store.getters['faq/getCreditCheckup'])

onMounted(() => {
    if(faqs.value.length > 0) return 
    store.dispatch('faq/setCreditCheckup')
})
</script>

<template>
    <div class="min-h-screen bg-amalan-white relative">
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="() => { router.go(-1) }" class="flex items-center mt-0">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold mt-0 text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full pt-8 pb-20 px-6 bg-amalan-white">
            <p class="text-center text-amalan-black text-xs">Temukan jawaban dari pertanyaan Anda di halaman ini</p>
            <div class="w-full mt-8 grid grid-cols-1 gap-y-6">
                <BasicCollapsable v-for="(faq, index) in faqs" :key="index" :title="faq.question" :content="faq.answer" />
            </div>
        </div>
    </div>
</template>