
<script setup>
import { onMounted, ref, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { CheckIcon } from '@heroicons/vue/solid'
import CurrencyInput from "@/components/CurrencyInputV2.vue"
import useModalPopUp from '@/composables/modalPopUp'
import amalansignupApi from "@/axios/amalansignup"

const store = useStore()
const router = useRouter()
const route = useRoute()
const modalPopUp = useModalPopUp()

const lastLoanConsolidation = computed(() => store.getters['loanConsolidation/getLastLoanConsolidation'])

const ExtraQuestionsForm = reactive({
    Family_and_Peer_Loan: null,
    Family_and_Peer_Loan_Outstanding: null,
    Need_Extra_Cash: null,
    Need_Extra_Cash_Amount: null
})
const isLoading = ref(false)
const isDisabledFamilyAndPeer = ref(false)
const isDisabledExtraCash = ref(false)

const ExtraQuestionsFormSchema = yup.object().shape({
    Family_and_Peer_Loan: yup.string().nullable().required('Silahkan dipilih').oneOf(['yes', 'no'], 'Silahkan dipilih'),
    Family_and_Peer_Loan_Outstanding: yup.number('Masukan dalam bentuk angka').nullable().required('Mohon diisi').max(999999999999999),
    Need_Extra_Cash: yup.string().nullable().required('Silahkan dipilih').oneOf(['yes', 'no'], 'Silahkan dipilih'),
    Need_Extra_Cash_Amount: yup.number('Masukan dalam bentuk angka').nullable().required('Mohon diisi').max(999999999999999)
})

const submitHandler = () => {
    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs mt-2 text-amalan-black">Apakah Anda yakin data Anda sudah benar?<br><br>Jika sudah, silahkan lakukan konfirmasi<br>agar data Anda segera diproses!</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Konfirmasi',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if (!result.isConfirmed) return
        
        isLoading.value = true
        amalansignupApi.post('loan-consolidation/extra-questions', {
            id_refinancing: lastLoanConsolidation.value.data.data.Id ?? null,
            family_loan: ExtraQuestionsForm.Family_and_Peer_Loan_Outstanding,
            extra_cash: ExtraQuestionsForm.Need_Extra_Cash_Amount
        }).then(async () => {
            await store.dispatch('loanConsolidation/requestLastLoanConsolidation')
            isLoading.value = false
            return router.push({ name: 'Loan Consolidation - Registrasi - Finish Page'})
        }).catch(() => {
            isLoading.value = false
            modalPopUp.swalError.fire({
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                showCancelButton: true,
                confirmButtonText: 'Bantuan',
                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
            }).then((result) => {
                if (!result.isConfirmed) return
                return window.location = 'https://wa.me/622150933150?text=Mohon bantuan saya gagal terus dalam pengisian Extra Questions pada proses Registrasi Kredit Bijak di web.amalan.com'
            })
        })
    })
}

const setFamiliAndPeerLoan = (value) => {
    ExtraQuestionsForm.Family_and_Peer_Loan = value
    isDisabledFamilyAndPeer.value = value === 'yes' ? false : true
    ExtraQuestionsForm.Family_and_Peer_Loan_Outstanding = value === 'yes' ? ExtraQuestionsForm.Family_and_Peer_Loan_Outstanding ? ExtraQuestionsForm.Family_and_Peer_Loan_Outstanding : null : 0
}

const setNeedExtraCash = (value) => {
    ExtraQuestionsForm.Need_Extra_Cash = value
    isDisabledExtraCash.value = value === 'yes' ? false : true
    ExtraQuestionsForm.Need_Extra_Cash_Amount = value === 'yes' ? ExtraQuestionsForm.Need_Extra_Cash_Amount ? ExtraQuestionsForm.Need_Extra_Cash_Amount : null : 0
}

onMounted(() => {
    if(lastLoanConsolidation.value.status === 404) return router.push({ name: 'Loan Consolidation - Registrasi - Informasi Umum'})
    if(lastLoanConsolidation.value.status === 200) return router.push({ name: 'Loan Consolidation - Registrasi - Finish Page'})
    if(lastLoanConsolidation.value.status === 201) return router.push({ name: 'Loan Consolidation - Registrasi - Daftar Pinjaman'})
    if(lastLoanConsolidation.value.status === 202) return
    return router.push({ name: 'Home'})
})
</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="router.push({name: 'Loan Consolidation - Registrasi - Daftar Pinjaman'})" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold text-amalan-black text-center">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full min-h-screen bg-amalan-white px-6 pb-24">
            <div class="w-full py-8 flex justify-between items-center">
                <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                    <span class="text-sm text-amalan-blue-1 font-bold">1</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3 mx-1"></div>
                <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400">
                    <span class="text-sm font-bold text-amalan-blue-1">2</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3 mx-1"></div>
                <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400">
                    <span class="text-sm font-bold text-amalan-blue-1">3</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3 mx-1"></div>
                <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400">
                    <span class="text-sm font-bold text-amalan-blue-1">4</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-grow mx-1"></div>
                <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                    <span class="text-sm text-amalan-blue-1 font-bold">5</span>
                </div>
                <p class="flex-none text-amalan-blue-1 text-sm font-bold ml-1">Daftar Pinjaman</p>
            </div>
            <p class="text-xs text-amalan-black text-center">Selain untuk menyelesaikan pinjaman Anda, Kredit Bijak Karyawan juga dapat digunakan untuk keperluan lain.</p>
            <Form
            :validation-schema="ExtraQuestionsFormSchema"
            v-slot="{ meta, errors }"
            @submit="submitHandler"
            >
                <div class="w-full grid grid-cols-1 gap-y-3.5 py-8 text-amalan-black">
                    <div class="w-full">
                        <p class="text-sm text-amalan-blue-1 font-bold">Apakah Anda memiliki pinjaman dari teman atau keluarga?</p>
                        <div class="w-full py-2 px-4 grid grid-cols-2 gap-x-3.5 border border-solid border-amalan-gray-4 rounded-lg mt-1.5">
                            <div @click="setFamiliAndPeerLoan('yes')" class="w-full py-2 flex justify-start items-center gap-2 cursor-pointer">
                                <div class="w-4 h-4 p-0 rounded-full flex justify-center items-center" :class="[ ExtraQuestionsForm.Family_and_Peer_Loan == 'yes' ? 'bg-amalan-yellow-400' : 'bg-amalan-gray-5']">
                                    <CheckIcon class="w-3 h-3" :class="[ ExtraQuestionsForm.Family_and_Peer_Loan == 'yes' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]" />
                                </div>
                                <p class="text-amalan-black text-xs">Ya</p>
                                <Field name="Family_and_Peer_Loan" type="radio" class="hidden" value="yes" :validateOnInput="true" v-model="ExtraQuestionsForm.Family_and_Peer_Loan" />
                            </div>
                            <div @click="setFamiliAndPeerLoan('no')" class="w-full py-2 flex justify-start items-center gap-2 cursor-pointer">
                                <div class="w-4 h-4 p-0 rounded-full flex justify-center items-center" :class="[ ExtraQuestionsForm.Family_and_Peer_Loan == 'no' ? 'bg-amalan-yellow-400' : 'bg-amalan-gray-5']">
                                    <CheckIcon class="w-3 h-3" :class="[ ExtraQuestionsForm.Family_and_Peer_Loan == 'no' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]" />
                                </div>
                                <p class="text-amalan-black text-xs">Tidak</p>
                                <Field name="Family_and_Peer_Loan" type="radio" class="hidden" value="no" :validateOnInput="true" v-model="ExtraQuestionsForm.Family_and_Peer_Loan" />
                            </div>
                        </div>
                    </div>
                    <div class="w-full">
                        <label class="text-sm text-amalan-blue-1 font-bold">Berapa pinjaman yang Anda miliki dari teman atau keluarga?</label>
                        <div class="flex flex-col relative w-full mt-1.5">
                            <CurrencyInput 
                            v-model="ExtraQuestionsForm.Family_and_Peer_Loan_Outstanding" 
                            :placeholder="'Rp'"
                            :disabled="isDisabledFamilyAndPeer"
                            :propsClass="errors.Family_and_Peer_Loan_Outstanding ? 'border-amalan-red rounded-lg' : 'border-amalan-gray-4 rounded-lg'"
                            :options="{
                                currency: 'IDR',
                                currencyDisplay: 'narrowSymbol',
                                hideCurrencySymbolOnFocus: false,
                                hideGroupingSeparatorOnFocus: false,
                                hideNegligibleDecimalDigitsOnFocus: false,
                                autoDecimalDigits: false,
                                autoSign: false,
                                useGrouping: true,
                                accountingSign: true
                            }" />
                            <Field name="Family_and_Peer_Loan_Outstanding" type="text" :validateOnInput="true" class="hidden" v-model="ExtraQuestionsForm.Family_and_Peer_Loan_Outstanding" />
                            <div v-if="errors.Family_and_Peer_Loan_Outstanding" class="mt-0.5 text-amalan-red text-2xs">{{errors.Family_and_Peer_Loan_Outstanding}}</div>
                        </div>
                    </div>
                    <div class="w-full">
                        <p class="text-sm text-amalan-blue-1 font-bold">Apakah Anda membutuhkan tambahan dana untuk keperluan lain?</p>
                        <div class="w-full py-2 px-4 grid grid-cols-2 gap-x-3.5 border border-solid border-amalan-gray-4 rounded-lg mt-1.5">
                            <div @click="setNeedExtraCash('yes')" class="w-full py-2 flex justify-start items-center gap-2 cursor-pointer">
                                <div class="w-4 h-4 p-0 rounded-full flex justify-center items-center" :class="[ ExtraQuestionsForm.Need_Extra_Cash == 'yes' ? 'bg-amalan-yellow-400' : 'bg-amalan-gray-5']">
                                    <CheckIcon class="w-3 h-3" :class="[ ExtraQuestionsForm.Need_Extra_Cash == 'yes' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]" />
                                </div>
                                <p class="text-amalan-black text-xs">Ya</p>
                                <Field name="Need_Extra_Cash" type="radio" class="hidden" value="yes" :validateOnInput="true" v-model="ExtraQuestionsForm.Need_Extra_Cash" />
                            </div>
                            <div @click="setNeedExtraCash('no')" class="w-full py-2 flex justify-start items-center gap-2 cursor-pointer">
                                <div class="w-4 h-4 p-0 rounded-full flex justify-center items-center" :class="[ ExtraQuestionsForm.Need_Extra_Cash == 'no' ? 'bg-amalan-yellow-400' : 'bg-amalan-gray-5']">
                                    <CheckIcon class="w-3 h-3" :class="[ ExtraQuestionsForm.Need_Extra_Cash == 'no' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]" />
                                </div>
                                <p class="text-amalan-black text-xs">Tidak</p>
                                <Field name="Need_Extra_Cash" type="radio" class="hidden" value="no" :validateOnInput="true" v-model="ExtraQuestionsForm.Need_Extra_Cash" />
                            </div>
                        </div>
                    </div>
                    <div class="w-full">
                        <label class="text-sm text-amalan-blue-1 font-bold">Berapa tambahan dana yang Anda butuhkan untuk keperluan lainnya?</label>
                        <div class="flex flex-col relative w-full mt-1.5">
                            <CurrencyInput 
                            v-model="ExtraQuestionsForm.Need_Extra_Cash_Amount" 
                            :placeholder="'Rp'"
                            :disabled="isDisabledExtraCash"
                            :propsClass="errors.Need_Extra_Cash_Amount ? 'border-amalan-red rounded-lg' : 'border-amalan-gray-4 rounded-lg'"
                            :options="{
                                currency: 'IDR',
                                currencyDisplay: 'narrowSymbol',
                                hideCurrencySymbolOnFocus: false,
                                hideGroupingSeparatorOnFocus: false,
                                hideNegligibleDecimalDigitsOnFocus: false,
                                autoDecimalDigits: false,
                                autoSign: false,
                                useGrouping: true,
                                accountingSign: true
                            }" />
                            <Field name="Need_Extra_Cash_Amount" type="text" :validateOnInput="true" class="hidden" v-model="ExtraQuestionsForm.Need_Extra_Cash_Amount" />
                            <div v-if="errors.Need_Extra_Cash_Amount" class="mt-0.5 text-amalan-red text-2xs">{{errors.Need_Extra_Cash_Amount}}</div>
                        </div>
                    </div>
                </div>
                <button type="submit" class="w-full mt-8 rounded-amalan-lg font-bold text-center text-sm p-3" :class="[ meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!meta.valid">Kirim</button>
            </Form>
            <loading-overlay v-if="isLoading" />
        </div>
    </div>
</template>