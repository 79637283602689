<script setup>
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'
import RDPCardMini from '../RDP/Components/RDPCardMini'

const router = useRouter()
const route = useRoute()
const store = useStore()

const billCompleted = computed(() => store.getters['docCollection/getbillStatus'])
const signUpStatus = computed(() => store.getters['contact/getSignUpStatus'])
const rdpData = computed(() => store.getters['RDPManagement/getRdpData'])
const LastUpgradePackageFetchStatus = computed(() => store.getters['upgradePricingPackage/getLastUpgradePackageFetchStatus'])
const LastAdditionalSufFetchStatus = computed(() => store.getters['additionalSUF/getLastAdditionalSufFetchStatus'])
const LastInstallmentPaymentFetchStatus = computed(() => store.getters['additionalSUF/getLastInstallmentPaymentFetchStatus'])

// const timeToDocCollection = ref(localStorage.getItem('document-collection') ? true : false)
const timeToDocCollection = ref(true)
const showMenuDocCollection = ref(false)

const MenuConfig = [
    {
        routeName: 'Loan Management - Loan List - Home',
        text: 'Daftar<br>Pinjaman',
        mustSignup: true
    },
    {
        routeName: 'Loan Management - Saving Activity - Home',
        text: 'Aktivitas<br>Tabungan RDP',
        mustSignup: false
    },
    {
        routeName: 'Loan Management - Loan Progress - Home',
        text: 'Progres<br>Pinjaman',
        mustSignup: true
    },
    {
        routeName: 'Loan Management - Upgrade Package - Home',
        text: 'Tingkatkan<br>Paket Layanan',
        mustSignup: true
    }
]

const routeBack = () => {
    if(route.query.from && route.query.from == 'widget') return router.push({ name: 'Home' })
    return router.push({ name: 'Akun' })
}

onMounted(() => {
    if(signUpStatus.value === 200 && timeToDocCollection.value === true) showMenuDocCollection.value = true
})
</script>

<template>
    <div class="min-h-screen bg-amalan-white">
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="routeBack" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full py-8 px-6 bg-amalan-white">
            <p class="text-center text-amalan-black text-xs sm:text-sm">Anda dapat melihat, mengubah, memonitor pinjaman atau meningkatkan paket layanan Anda disini!</p>
            <div class="w-full mt-8 grid grid-cols-2 gap-4">
                <router-link v-for="(menu, index) in MenuConfig" :key="index" :to="{ name: menu.routeName }" class="w-full rounded-lg font-bold text-sm relative" :class="[ menu.mustSignup && signUpStatus !== 200 ? 'cursor-not-allowed bg-amalan-gray-4 text-amalan-gray-2' : 'shadow-amalan-sm bg-amalan-blue-1 hover:bg-amalan-blue-2 text-amalan-white cursor-pointer' ]">
                    <div class="w-full relative px-4 py-6 rounded-lg overflow-hidden">
                        <p v-html="menu.text"></p>
                        <div class="absolute -bottom-9 -right-9 w-22 h-22 rounded-full bg-transparent border border-amalan-yellow-400 opacity-30 flex justify-center items-center">
                            <div class="w-20 h-20 rounded-full bg-amalan-white bg-opacity-50"></div>
                        </div>
                    </div>
                    <div v-if="index === 0 && showMenuDocCollection && !billCompleted" class="w-4 h-4 bg-amalan-red rounded-full absolute -top-1 -right-1"></div>
                </router-link>
                <div v-if="rdpData.isExist === 'FOUND'" class="w-full col-span-2">
                    <RDPCardMini :card-type="rdpData.cardType" :account-number="rdpData.accountNumber" :full-name="rdpData.fullName" :route-back="'loan-management'" />
                </div>
            </div>
        </div>
        <loading-overlay v-if="LastUpgradePackageFetchStatus || LastAdditionalSufFetchStatus || LastInstallmentPaymentFetchStatus" />
    </div>
</template>