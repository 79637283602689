<template>
    <div class="bg-blue-100 min-h-screen">
        <div class="justify-center flex pt-16 mb-14">
            <img class="h-16" src="../assets/img/logo-amalan.png">
        </div>
        <div class="rounded-tr-amalan-lg w-full min-h-screen bg-gray-50 pb-10">
            <div class="pt-12 px-6">
                <p class="text-sm leading-tight text-justify">Halo, Sobat <b>amalan</b> <br>Selamat datang di amalanPROTECT, pada layanan ini Anda dapat dengan mudah mengatasi perilaku debt collector yang tidak sesuai aturan dengan melaporkannya kepada pihak yang tepat</p>
                <div class="mt-3 grid grid-cols-10">
                    <div>
                        <div class="rounded-full h-7 w-7 mx-auto border-2 border-teal-600 border-amalan-yellow-400 bg-amalan-yellow-400 text-center">
                            <span class="text-blue-900 font-bold">1</span>
                        </div>
                        <hr class="h-full w-1/12 mx-auto border-t-0 border-l-4 border-blue-900">
                    </div>
                    <div class="col-span-9 px-2 pb-4">
                        <h1 class="text-base font-bold text-blue-900">Verifikasi Identitas</h1>
                        <p class="text-sm text-justify leading-tight">Pada proses ini, Anda akan memasukkan nomor ponsel yang terintegrasi dengan WhatsApp atau alamat email untuk mendapatkan kode OTP.</p>
                    </div>
                    <div>
                        <div class="rounded-full h-7 w-7 mx-auto border-2 border-teal-600 border-amalan-yellow-400 bg-amalan-yellow-400 text-center">
                            <span class="text-blue-900 font-bold">2</span>
                        </div>
                        <hr class="h-full w-1/12 mx-auto border-t-0 border-l-4 border-blue-900">
                    </div>
                    <div class="col-span-9 px-2 pb-4">
                        <h1 class="text-base font-bold text-blue-900">e-KTP & Swafoto</h1>
                        <p class="text-sm text-justify leading-tight">Anda akan diminta untuk mengunggah foto e-KTP dan Swafoto sebagai dokumen verifikasi data Anda.</p>
                    </div>
                    <div>
                        <div class="rounded-full h-7 w-7 mx-auto border-2 border-teal-600 border-amalan-yellow-400 bg-amalan-yellow-400 text-center">
                            <span class="text-blue-900 font-bold">3</span>
                        </div>
                        <hr class="h-full w-1/12 mx-auto border-t-0 border-l-4 border-blue-900">
                    </div>
                    <div class="col-span-9 px-2 pb-4">
                        <h1 class="text-base font-bold text-blue-900">Lengkapi Data</h1>
                        <p class="text-sm text-justify leading-tight">Pada langkah ini Anda akan diminta untuk melengkapi data sesuai dengan e-KTP yang sudah Anda unggah sebelumnya.</p>
                    </div>
                    <div>
                        <div class="rounded-full h-7 w-7 mx-auto border-2 border-teal-600 border-amalan-yellow-400 bg-amalan-yellow-400 text-center">
                            <span class="text-blue-900 font-bold">4</span>
                        </div>
                    </div>
                    <div class="col-span-9 px-2 pb-4">
                        <h1 class="text-base font-bold text-blue-900">Konfirmasi Data</h1>
                        <p class="text-sm text-justify leading-tight">Jika data Anda sudah lengkap, Anda dapat melakukan konfirmasi data untuk melanjutkan pembelian layanan dari amalan international.</p>
                    </div>
                </div>
                <router-link to="/login" class="mt-2 bg-blue-900 hover:bg-blue-600 w-full h-11 rounded-lg flex justify-center items-center">
                    <span class="text-white font-semibold">Mulai Sekarang</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import setCookies from '@/composables/setCookies'

export default {
    mounted(){
        localStorage.setItem('from-onboarding-go-to', 'amalan protect - data pinjaman')

        setCookies.setUtmCookies(this.$route.query)
    }
}
</script>