<script setup>
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { ChevronRightIcon } from '@heroicons/vue/solid'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { listLoanType, iconBankLender } from '@/constants/LoanConstant'

const router = useRouter()
const route = useRoute()
const store = useStore()

const dataOpportunities = computed(() => store.getters['loanProgress/getOpportunitiesData'])
const opportunitiesFetchStatus = computed(() => store.getters['loanProgress/getOpportunitiesFetchStatus'])
const requestReadyForNegotiationData = computed(() => store.getters['loanProgress/getRequestReadyForNegotiation'])

const pageView = ref('home')
const opportunityDetail = ref(null)

const getIcon = (loanName, loanType) => {
    const lenderName = loanName.replace(" " + loanType, "").toLowerCase()
    const lenderData = iconBankLender.filter(elem => elem.name == lenderName)
    if (lenderData[0]) return lenderData[0].icon

    const loanTypeIcon = listLoanType.filter(elem => elem.name == loanType)
    if (loanTypeIcon[0]) return loanTypeIcon[0].icon
}

const getLoanTitle = (loanType) => {
    let loanTitle = listLoanType.filter(elem => elem.name == loanType)
    if (loanTitle[0]) return loanTitle[0].title
}

const getBankName = (loanName, loanType) => loanName.replace(" " + loanType, "")

const viewDetail = (groupIndex, data, title) => {
    if(groupIndex === 1) return
    
    opportunityDetail.value = {...data, detail_page_title : title}
    return pageView.value = 'detail'
}

const backButton = () => {
    if(pageView.value === 'home') return router.go(-1)
    return pageView.value = 'home'
}
</script>

<template>
    <div class="min-h-screen bg-amalan-white relative">
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="backButton" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <template v-if="pageView === 'home'">
            <div class="w-full px-6 py-8 bg-amalan-white">
                <p class="px-6 text-center text-amalan-black text-xs sm:text-sm">Anda dapat memonitoring progres pinjaman Anda pada halaman ini</p>
                <div v-if="dataOpportunities.length > 0" class="w-full mt-8">
                    <template v-for="(dataOpp, index) in dataOpportunities" :key="index">
                        <div class="flex">
                            <div class="w-4 flex-none relative">
                                <div v-if="index !== 2" class="w-px bg-amalan-blue-1 absolute top-2 -bottom-2 left-1"></div>
                            </div>
                            <div class="flex-grow pb-8 relative">
                                <div class="w-2.5 h-2.5 border border-amalan-blue-1 rounded-lg absolute bg-amalan-yellow-400 top-1 -left-4"></div>
                                <p class="text-amalan-blue-1 text-sm font-bold">{{dataOpp.group}}</p>
                                <template v-if="dataOpp.data.length > 0">
                                    <template v-for="(data, opportIndex) in dataOpp.data" :key="opportIndex">
                                        <div class="w-full mt-2.5 rounded-lg border p-4 flex justify-between items-center border-amalan-gray-4 bg-amalan-white" :class="[ index !== 1 ? 'hover:bg-amalan-gray-5 cursor-pointer' : '' ]" @click="viewDetail(index, data, dataOpp.title)">
                                            <div class="flex-none w-14 h-14 rounded-lg flex justify-center items-center">
                                                <img :src="require('@/assets/img/bank-lender-icon/' + getIcon(data.Name, data.Loan_Type_2__c))" class="w-14">
                                            </div>
                                            <div class="flex-grow px-2.5 text-amalan-black">
                                                <p class="text-sm text-left font-bold">{{ data.Name ? getBankName(data.Name, data.Loan_Type_2__c) : '-' }}</p>
                                                <p class="text-xs mt-0.5">{{ data.Loan_Type_2__c ? getLoanTitle(data.Loan_Type_2__c) : '-' }}</p>
                                                <p class="text-xs mt-1">{{ data.Estimated_Outstanding_Amount__c ? $filters.format_number(data.Estimated_Outstanding_Amount__c) : '-' }}</p>
                                            </div>
                                            <div v-if="index !== 1" class="flex-none w-6 h-14 flex justify-center items-center">
                                                <button class="border border-amalan-blue-1 bg-amalan-white rounded-lg w-6 h-6 flex justify-center items-center shadow-amalan-md">
                                                    <ChevronRightIcon class="h-5 text-amalan-blue-1" />
                                                </button>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                                <div v-else class="w-full mt-2.5 rounded-lg border p-4 flex justify-between items-center border-amalan-gray-4 bg-amalan-white">
                                    <div class="flex-none w-14 h-14 rounded-lg flex justify-center items-center">
                                        <img src="@/assets/img/clipboard-v2.svg" class="w-14">
                                    </div>
                                    <div class="flex-grow px-2.5 text-amalan-black">
                                        <p v-if="index === 0" class="text-xs">Anda tidak memiliki data pinjaman yang menjadi target pelunasan.</p>
                                        <p v-if="index === 1" class="text-xs">Anda tidak memiliki data pinjaman dalam antrian pelunasan.</p>
                                        <p v-if="index === 2" class="text-xs">Anda tidak memiliki data pinjaman yang telah selesai / lunas.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <loading-data-without-margin v-else class="mt-20" />
            </div>
        </template>
        <template v-else>
            <div v-if="opportunityDetail" class="w-full px-6 py-8 bg-amalan-white">
                <p class="text-amalan-blue-1 text-sm sm:text-base font-bold text-center">{{opportunityDetail.detail_page_title}}</p>
                <p v-if="opportunityDetail.detail_page_title !== 'Pinjaman Selesai / Lunas'" class="px-6 text-center text-amalan-black text-xs sm:text-sm">Anda dapat memonitoring progres pinjaman Anda pada halaman ini</p>
                <div class="w-full mt-8 mb-3.5">
                    <div class="w-full rounded-lg border p-4 flex justify-between items-center border-amalan-gray-4 bg-amalan-white">
                        <div class="flex-none w-14 h-14 rounded-lg flex justify-center items-center">
                            <img :src="require('@/assets/img/bank-lender-icon/' + getIcon(opportunityDetail.Name, opportunityDetail.Loan_Type_2__c))" class="w-14">
                        </div>
                        <div class="flex-grow px-2.5 text-amalan-black">
                            <p class="text-sm text-left font-bold">{{ opportunityDetail.Name ? getBankName(opportunityDetail.Name, opportunityDetail.Loan_Type_2__c) : '-' }}</p>
                            <p class="text-xs mt-0.5">{{ opportunityDetail.Loan_Type_2__c ? getLoanTitle(opportunityDetail.Loan_Type_2__c) : '-' }}</p>
                            <p class="text-xs mt-1">{{ opportunityDetail.Estimated_Outstanding_Amount__c ? $filters.format_number(opportunityDetail.Estimated_Outstanding_Amount__c) : '-' }}</p>
                        </div>
                    </div>
                </div>
                <div v-if="opportunityDetail.case_progress_data" class="w-full mt-3.5 text-amalan-black">
                    <div v-for="(data, index) in opportunityDetail.case_progress_data" :key="index" class="flex">
                        <div class="w-14 flex-none pb-3.5">
                            <p class="text-xs text-right">{{ opportunityDetail[data.timestamp_object_key] ? $filters.formatDateShortMonth(opportunityDetail[data.timestamp_object_key]) : '-' }}</p>
                        </div>
                        <div class="w-7 flex-none relative">
                            <div v-if="index !== (opportunityDetail.case_progress_data.length - 1)" class="w-px bg-amalan-blue-1 absolute top-2 -bottom-2 left-3 z-1"></div>
                            <div v-if="index === (opportunityDetail.case_progress_data.length - 1) && opportunityDetail.Request_Negotiation_Approval__r" class="w-px bg-amalan-blue-1 absolute top-2 -bottom-2 left-3 z-1"></div>
                            <div class="w-2.5 h-2.5 border border-amalan-blue-1 rounded-lg absolute bg-amalan-yellow-400 top-px left-2 z-2"></div>
                        </div>
                        <div class="flex-grow pb-3.5">
                            <p v-if="data.name === 'Waiting for Customer Approval'" class="text-xs">{{ opportunityDetail.Customer_Feedback__c ? data.value[opportunityDetail.Customer_Feedback__c] : data.value.Default }}</p>
                            <p v-else class="text-xs">{{ data.value }}</p>
                        </div>
                    </div>
                    <template v-if="requestReadyForNegotiationData.length > 0 && opportunityDetail.Request_Negotiation_Approval__r">
                        <div v-for="(request_negotiation, index) in opportunityDetail.Request_Negotiation_Approval__r.records" :key="index" class="flex">
                            <div class="w-14 flex-none pb-3.5">
                                <p class="text-xs text-right">{{ request_negotiation.Approver_Date__c ? $filters.formatDateShortMonth(request_negotiation.Approver_Date__c) : request_negotiation.Date_Submitted__c ? $filters.formatDateShortMonth(request_negotiation.Date_Submitted__c) : '-' }}</p>
                            </div>
                            <div class="w-7 flex-none relative">
                                <div v-if="index !== (opportunityDetail.Request_Negotiation_Approval__r.records.length - 1)" class="w-px bg-amalan-blue-1 absolute top-2 -bottom-2 left-3 z-1"></div>
                                <div class="w-2.5 h-2.5 border border-amalan-blue-1 rounded-lg absolute bg-amalan-yellow-400 top-px left-2 z-2"></div>
                            </div>
                            <div class="flex-grow pb-3.5">
                                <p class="text-xs">{{ requestReadyForNegotiationData[0].value[request_negotiation.Approval_Status__c] }}</p>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
        <loading-overlay v-if="opportunitiesFetchStatus" />
    </div>
</template>