export default {
    format_number(value) {
        if (typeof value !== "number") {
            return value;
        }

        var formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0
        });

        return formatter.format(value);
    },
    format_number_without_symbol(value) {
        if (typeof value !== "number") {
            return value;
        }

        var formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
            currencyDisplay: 'code',
            minimumFractionDigits: 2
        });

        return formatter.format(value).replace('IDR', '');
    }
}