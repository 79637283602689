<script setup>
import { ChevronRightIcon } from '@heroicons/vue/solid'
import { ref, onMounted } from 'vue'
import router from "@/router"

const showPage = ref(false)
const hideLewati = ref(false)
const firstStep = ref(true)
const secondStep = ref(false)
const thirdStep = ref(false)
const fourStep = ref(false)
const currentPosition = ref(1)
const isLoading = ref(false)

const nextStep = () => {
    if(currentPosition.value == 1){
        firstStep.value = false
        hideLewati.value = true
        secondStep.value = true
        thirdStep.value = false
        fourStep.value = false
        currentPosition.value = 2
    }else if(currentPosition.value == 2){
        firstStep.value = false
        hideLewati.value = true
        secondStep.value = false
        thirdStep.value = true
        fourStep.value = false
        currentPosition.value = 3
    }else if(currentPosition.value == 3){
        firstStep.value = false
        hideLewati.value = true
        secondStep.value = false
        thirdStep.value = false
        fourStep.value = true
        currentPosition.value = 4
    }else{
        isLoading.value = true
        router.push({ name : 'Login' })
    }
}

const skip = () => {
    isLoading.value = true
    router.push({ name : 'Login' })
}

onMounted(() => {
    localStorage.setItem('from-onboarding-go-to', 'rdp opening - document poa')

    setTimeout(() => {
        showPage.value = true
    }, 500)
})
</script>

<template>
    <div class="bg-amalan-white text-amalan-black min-h-screen">
        <Transition name="fade-fast">
            <div v-if="showPage" class="w-full h-800 mb-10">
                <div class="w-full h-5/6 relative">
                    <Transition name="fade-fast">
                        <div v-show="firstStep" class="absolute z-11">
                            <div class="justify-center flex pt-16">
                                <img class="h-16" src="../assets/img/logo-amalan.png">
                            </div>
                            <div class="w-full flex justify-center my-16">
                                <img src="@/assets/img/rdp/first-onboarding.png" class="w-4/6">
                            </div>
                            <div class="px-10">
                                <p class="text-xs text-center"><b>RDP (Rekening Dana Pelunasan)</b> dari <b>amalan</b> adalah rekening tabungan yang digunakan untuk menabung dan melunasi utang Anda.</p>
                                <p class="text-xs text-center mt-6">Cukup dengan 3 langkah mudah, Anda sudah dapat membuka Rekening Dana Pelunasan dari <b>amalan</b>!</p>
                            </div>
                        </div>
                    </Transition>
                    <Transition name="fade-fast">
                        <div v-show="secondStep" class="absolute z-12">
                            <div class="w-full flex justify-center pt-24 mb-16">
                                <img src="@/assets/img/rdp/second-onboarding.png" class="w-5/6">
                            </div>
                            <div class="px-10">
                                <p class="text-center text-base font-bold text-amalan-blue-1">Verifikasi Data</p>
                                <p class="text-xs text-center mt-2">Anda akan melakukan Verifikasi Data dengan mengunggah e-KTP dan Swafoto sesuai dengan ketentuan yang berlaku.</p>
                            </div>
                        </div>
                    </Transition>
                    <Transition name="fade-fast">
                        <div v-show="thirdStep" class="absolute z-13">
                            <div class="absolute right-0">
                                <img class="h-28" src="@/assets/img/rdp/onboarding-decoration-1.svg">
                            </div>
                            <div class="w-full grid grid-cols-12 gap-x-3 mt-28 px-6">
                                <img src="@/assets/img/rdp/third-onboarding-1.png" class="col-span-6 order-1 ssm:w-4/5 ssm:mx-auto">
                                <div class="col-span-6 flex flex-col items-start justify-center order-2">
                                    <p class="text-base font-bold text-amalan-blue-1">Lengkapi<br>Data Pengguna</p>
                                    <p class="text-xs">Anda akan melengkapi data tambahan yang dibutuhkan untuk melakukan Pembukaan Rekening Dana Pelunasan</p>
                                </div>
                            </div>
                            <div class="w-full grid grid-cols-12 gap-x-3 mt-6 mb-14 px-6">
                                <img src="@/assets/img/rdp/third-onboarding-2.png" class="col-span-6 order-2 ssm:w-4/5 ssm:mx-auto">
                                <div class="col-span-6 flex flex-col items-start justify-center order-1">
                                    <p class="text-base font-bold text-amalan-blue-1">Verifikasi Email<br>& Tanda Tangan</p>
                                    <p class="text-xs">Anda akan diminta untuk memverifikasi email dan melakukan tanda tangan dokumen Pembukaan Rekening Dana Pelunasan</p>
                                </div>
                            </div>
                        </div>
                    </Transition>
                    <Transition name="fade-fast">
                        <div v-show="fourStep" class="absolute z-14">
                            <div class="absolute left-0">
                                <img class="h-28" src="@/assets/img/rdp/onboarding-decoration-2.svg">
                            </div>
                            <div class="w-full flex justify-center pt-40 mb-16">
                                <img src="@/assets/img/rdp/fourth-onboarding.png" class="w-3/5 ssm:w-2/4">
                            </div>
                            <div class="px-10">
                                <p class="text-center text-base font-bold text-amalan-blue-1">Registrasi Selesai</p>
                                <p class="text-xs text-center mt-2">Jika proses registrasi Pembukaan Rekening Dana Pelunasan Anda sudah selesai dilakukan, maka data Anda akan segera diproses</p>
                            </div>
                        </div>
                    </Transition>
                </div>
                <div class="w-full h-1/6 relative">
                    <Transition name="fade-fast">
                        <div v-if="firstStep" class="w-full flex justify-center absolute z-11">
                            <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                        </div>
                    </Transition>
                    <Transition name="fade-fast">
                        <div v-if="secondStep" class="w-full flex justify-center absolute z-12">
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                        </div>
                    </Transition>
                    <Transition name="fade-fast">
                        <div v-if="thirdStep" class="w-full flex justify-center absolute z-11">
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                        </div>
                    </Transition>
                    <Transition name="fade-fast">
                        <div v-if="fourStep" class="w-full flex justify-center absolute z-12">
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                        </div>
                    </Transition>
                    <div class="w-full px-6 flex justify-between items-center flex-row-reverse absolute bottom-2">
                        <div type="button" @click="nextStep" class="text-amalan-white text-sm font-bold bg-amalan-blue-1 h-12 w-40 hover:bg-blue-900 rounded-lg flex items-center justify-center cursor-pointer"><span :class="{ 'ml-3' : currentPosition !== 4}">{{ currentPosition == 4 ? 'Mulai Sekarang' : 'Selanjutnya' }}</span><ChevronRightIcon class="h-8" v-if="currentPosition !== 4" /></div>
                        <Transition name="fade-fast"><span @click="skip" class="text-sm font-bold text-amalan-blue-1 cursor-pointer" v-if="!hideLewati">Lewati</span></Transition>
                    </div>
                </div>
            </div>
        </Transition>
        <loading-overlay v-if="isLoading" />
    </div>
</template>

<style scoped>
.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.8s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.8s ease
}
</style>