import moment from "moment"
import amalanSignup from "../axios/amalansignup"
import setCookies from "./setCookies"
import { sha256 } from 'js-sha256'

const TIKTOK_PIXEL_CODE = "CF3SLIRC77U6VUEROSC0"

const getUserInfo = async () => {
    const {data} = await amalanSignup.get('/user-request-info')

    return {
        user_agent: data?.data?.user_agent,
        ip: data?.data?.ip
    }
}

export const sentTiktokEvent = async (eventType, eventId, {phone_number, email}, properties) => {
    const encryptedPhoneNumber = phone_number ? sha256(phone_number) : null
    const encryptedEmail = email ? sha256(email) : null

    const {user_agent, ip} = await getUserInfo()

    let data = {
        pixel_code: TIKTOK_PIXEL_CODE,
        event: eventType,
        event_id: eventId,
        timestamp: moment().utc().format(),
        context: {
            ad: {
                callback: setCookies.checkCookies('ttclid') || null
            },
            page: {
                url: window.location.protocol + "//" + window.location.host + window.location.pathname,
                referrer: window.location.protocol + "//" + window.location.host
            },
            user: {
                phone_number: encryptedPhoneNumber,
                email: encryptedEmail,
                ttp: setCookies.checkCookies('_ttp') || null
            },
            user_agent,
            ip
        }
    }

    if(properties) data = {...data, properties}

    amalanSignup.post('/event-api/tiktok', data)
}