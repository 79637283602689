<template>
    <div class="pt-4 min-h-screen h-auto relative">
        <div class="mt-2 pb-12 w-full px-4">
            <div class="flex items-center">
                <div class="flex items-center text-blue-900 relative">
                    <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                        <img src="@/assets/img/docCollection/lembar-tagihan-active.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Lembar<br>Tagihan</p></div>
                </div>
                <div class="flex-auto border-t-2 border-gray-300"></div>
                <div class="flex items-center text-gray-500 relative">
                    <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                        <img src="@/assets/img/docCollection/dokumen-wajib.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Dokumen<br>Wajib</p></div>
                </div>
                <div class="flex-auto border-t-2 border-gray-300"></div>
                <div class="flex items-center text-gray-500 relative">
                    <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                        <img src="@/assets/img/verified.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Unggah<br>Selesai</p></div>
                </div>
            </div>
        </div>
        <div class="mt-6 pb-48 w-full px-4">
            <div class="my-6" v-if="dataOpportunities">
                <template v-for="(dataOpp, index) in dataOpportunities" :key="index">
                    <div class="w-full rounded-lg px-4 py-3 mt-4 border border-solid" :class="[ dataOpp.Bill_Statement_1__c ? 'bg-white' : 'bg-red-50 border-red-500' ]">
                        <div class="flex items-center">
                            <div class="w-16 h-16 rounded-full flex justify-center items-center border-2 border-amalan-yellow-400 bg-white">
                                <img :src="require('@/assets/img/signup/icon-jenis-pinjaman/' + getIcon(dataOpp.Loan_Type_2__c))">
                            </div>
                            <div class="ml-4">
                                <p class="text-sm sm:text-base font-bold my-0">{{ dataOpp.Name ? getBankName(dataOpp.Name, dataOpp.Loan_Type_2__c) : '-' }}</p>
                                <p class="text-xs sm:text-sm font-semibold my-0">{{ getLoanName(dataOpp.Loan_Type_2__c) }}</p>
                                <p class="text-xs sm:text-sm font-semibold my-0">{{ dataOpp.Account_Number__c ?? '-' }}</p>
                            </div>
                        </div>
                        <div class="flex justify-between items-center mt-2">
                            <p class="text-xs sm:text-sm">
                                <span class="font-bold">Jumlah Pinjaman</span><br>
                                {{ dataOpp.Estimated_Outstanding_Amount__c || dataOpp.Estimated_Outstanding_Amount__c == 0 ? $filters.format_number(dataOpp.Estimated_Outstanding_Amount__c) : '-' }}
                            </p>
                            <p class="text-xs sm:text-sm">
                                <span class="font-bold">Cicilan Perbulan</span><br>
                                {{ dataOpp.Old_Installment__c || dataOpp.Old_Installment__c == 0 ? $filters.format_number(dataOpp.Old_Installment__c) : '-' }}
                            </p>
                        </div>
                        <router-link :to="{ name : 'doc collection - lembar tagihan detail', params: { opportunityId: dataOpp.Id } }" class="rounded-lg block w-full font-semibold text-xs text-center mt-3 py-1 cursor-pointer border border-blue-900" :class="[ dataOpp.Bill_Statement_1__c ? 'text-blue-900 bg-white hover:bg-blue-900 hover:text-white' : 'text-white bg-blue-900 hover:bg-blue-700 hover:border-blue-700' ]">
                            {{ dataOpp.Bill_Statement_1__c ? 'Lihat' : 'Unggah' }} Lembar Tagihan
                        </router-link>
                    </div>
                    <div v-if="!dataOpp.Bill_Statement_1__c" class="text-xs text-red-600">*Lengkapi Lembar Tagihan Anda</div>
                </template>
            </div>
            <div class="w-full rounded-lg bg-blue-100 px-4 py-20 my-6" v-else>
                <p class="text-sm text-center">Belum ada data pinjaman yang ditemukan</p>
            </div>
        </div>
        <div class="absolute bottom-0 mb-28 w-full px-4">
            <button type="button" @click="nextStep" class="rounded-lg block w-full font-medium border border-solid text-center text-base py-2 text-white" :class="[ billCompleted ? 'cursor-pointer bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700' : 'bg-gray-300 border-gray-300 cursor-not-allowed' ]" :disabled="!billCompleted">Lanjut ke Dokumen Wajib</button>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, inject, computed } from 'vue';
import { useStore } from 'vuex';
import amalansignupApi from "@/axios/amalansignup";
import router from "../../router";
import { listLoanType as listIcon } from '@/constants/LoanConstant'

export default defineComponent({
    methods: {
        getIcon(loanType){
            let loanIcon = listIcon.filter(elem => elem.name == loanType);
            if (loanIcon[0]) return loanIcon[0].icon
        }
    },
    setup(){
        const store = useStore();
        const swal = inject("$swal");
        const dataOpportunities = computed(() => store.getters['docCollection/getOpportunities']);
        const billCompleted = computed(() => store.getters['docCollection/getbillStatus']);
        let dataLoanTypes = ref([]);

        function getLoanName(oldLoan){
            let allLoan = dataLoanTypes.value;
            let newLoan = allLoan.filter(elem => elem.value == oldLoan);
            if (newLoan[0]) return newLoan[0].name
        }

        const swalErrorReload = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
            },
            buttonsStyling: false,
            title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
            html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
            showCancelButton: false,
            confirmButtonText: 'Perbaharui',
            width: '340px',
            allowOutsideClick: false,
        })

        const swalConfirm = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600',
                cancelButton: 'bg-white mx-2 py-2 px-5 rounded-lg border border-blue-900 text-blue-900 text-sm font-semibold hover:bg-red-400 hover:border-red-400 hover:text-white'
            },
            buttonsStyling: false,
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        function nextStep(){
            swalConfirm.fire({
                html: '<p class="text-center text-sm mt-2">Anda yakin dokumen sudah benar?<br>Jika sudah, silahkan untuk dikonfirmasi dan Anda akan melanjutkan proses selanjutnya</p>',
                showCancelButton: true,
                confirmButtonText: 'Konfirmasi',
                cancelButtonText: 'Batalkan',
            }).then((result) => {
                if (result.isConfirmed){
                    router.push({ name: 'doc collection - dokumen wajib' });
                }
            });
        }

        function getBankName(loanName, loanType){
            return loanName.replace(" " + loanType, "")
        }

        amalansignupApi.get('salesforce/loan-types').then(resp => {
            dataLoanTypes.value = resp.data.data;
        }).catch(() => {
            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        });

        return {
            dataOpportunities,
            getLoanName,
            billCompleted,
            nextStep,
            getBankName
        };
    }
})
</script>