import amalansignupApi from "../../axios/amalansignup";
import vm from "../../main";

const state = () => ({
    opportunities: null,
    amountLoan: null,
    opportunitiesNotComplete: false,
});

const getters = {
    getOpportunities(state) {
        return state.opportunities;
    },
    getAmountLoan(state) {
        return state.amountLoan;
    },
    getOpportunitiesStatus(state) {
        return state.opportunitiesNotComplete;
    },
};

const actions = {
    async requestOpportunities({ commit }) {
        amalansignupApi.get('salesforce/opportunities')
        .then( resp => {
            if(resp.status == 200){
                commit("requestOpportunities", resp.data);
                commit("calculateAmountLoan", resp.data.data);
            }
        })
        .catch(() => {
            const swalErrorReload = vm.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })

            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        })
    },
    requestOpportunitiesWithPromise({ commit }) {
        return amalansignupApi.get('salesforce/opportunities')
        .then( resp => {
            if(resp.status == 200){
                commit("requestOpportunities", resp.data);
                commit("calculateAmountLoan", resp.data.data);
            }
        })
        .catch(() => {
            const swalErrorReload = vm.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })

            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        })
    },
};

const mutations = {
    requestOpportunities(state, payload) {
        let data = payload.data;
        if(data && data.length > 0){
            let unfilledData = data.filter(elem => elem.Account_Number__c == null || elem.Old_Installment__c == null);
            let filledData = data.filter(elem => elem.Account_Number__c && elem.Old_Installment__c !== null);
            state.opportunitiesNotComplete = unfilledData.length > 0 ? true : false;
            
            Array.prototype.push.apply(unfilledData,filledData);
            data = unfilledData;
        }
        state.opportunities = data;
    },
    calculateAmountLoan(state, payload) {
        let initialLoan = 0;
        for (let i in payload){
            if(payload[i].Estimated_Outstanding_Amount__c){
                initialLoan += payload[i].Estimated_Outstanding_Amount__c;
            }
        }
        if(initialLoan > 0){
            state.amountLoan = initialLoan;
        }else{
            state.amountLoan = null;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
