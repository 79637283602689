import amalansignupApi from "../../axios/amalansignup";
import vm from "../../main";
import { listLoanType, iconBankLender } from '@/constants/LoanConstant'

const state = () => ({
    opportunities: null,
    amountLoan: null,
    productType: [],
    qualifiedLender: [],
    ccType: [],
    financialHardships: [],
    formUpdateContact: {
        financialHardship : null,
        financialHardshipDetail : null,
        optional : null,
        monthlySaving : null
    },
    contact: null
});

const getters = {
    getOpportunities(state) {
        return state.opportunities;
    },
    getAmountLoan(state) {
        return state.amountLoan;
    },
    getProductType(state) {
        return state.productType;
    },
    getCCType(state) {
        return state.ccType;
    },
    getQualifiedLender(state) {
        return state.qualifiedLender;
    },
    getFinancialHardship(state) {
        return state.financialHardships;
    },
    getFormUpdateContact(state) {
        return state.formUpdateContact;
    },
    getContact(state){
        return state.contact
    }
};

const actions = {
    async requestOpportunities({ commit }) {
        amalansignupApi.get('salesforce/lead-qualification/opportunities')
        .then( resp => {
            if(resp.status == 200){
                commit("requestOpportunities", resp.data);
                commit("calculateAmountLoan", resp.data.data);
            }
        })
        .catch(() => {
            const swalErrorReload = vm.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })

            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        })
    },
    requestOpportunitiesWithPromise({ commit }) {
        return amalansignupApi.get('salesforce/lead-qualification/opportunities')
        .then( resp => {
            if(resp.status == 200){
                commit("requestOpportunities", resp.data);
                commit("calculateAmountLoan", resp.data.data);
            }
        })
        .catch(() => {
            const swalErrorReload = vm.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })

            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        })
    },
    async requestProductType({ commit }) {
        amalansignupApi.get('salesforce/lead-qualification/product-types')
        .then( resp => {
            if(resp.status == 200){
                commit("requestProductType", resp.data);
            }
        })
        .catch(() => {
            const swalErrorReload = vm.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })

            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        })
    },
    async requestCCType({ commit }) {
        amalansignupApi.get('salesforce/lead-qualification/credit-card-types')
        .then( resp => {
            if(resp.status == 200){
                commit("requestCCType", resp.data);
            }
        })
        .catch(() => {
            const swalErrorReload = vm.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })

            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        })
    },
    async requestQualifiedLender({ commit }, payload) {
        amalansignupApi.get('salesforce/lead-qualification/qualified-lenders?productTypeId=' + payload)
        .then( resp => {
            if(resp.status == 200){
                commit("requestQualifiedLender", resp.data);
            }
        })
        .catch(() => {
            const swalErrorReload = vm.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })

            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        })
    },
    async resetQualifiedLender({ commit }){
        commit("resetQualifiedLender");
    },
    requestFinancialHardship({ commit }) {
        return amalansignupApi.get('salesforce/financial-hardships')
        .then( resp => {
            if(resp.status == 200){
                commit("requestFinancialHardship", resp.data);
            }
        })
        .catch(() => {
            const swalErrorReload = vm.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })

            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        })
    },
    setFormUpdateContact({ commit }, payload) {
        commit("setFormUpdateContact", payload);
    },
    setMonthlySaving({ commit }, payload) {
        commit("setMonthlySaving", payload);
    },
    prepopulateContactData({ commit }) {
        return amalansignupApi.get('salesforce/contact')
        .then( resp => {
            if(resp.status == 200){
                commit("prepopulateContactData", resp.data);
            }
        })
        .catch(() => {
            const swalErrorReload = vm.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })

            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        })
    }
};

const mutations = {
    requestOpportunities(state, payload) {
        let data = payload.data;
        if(data && data.length > 0){
            state.opportunities = data
        }else{
            state.opportunities = []
        }
    },
    calculateAmountLoan(state, payload) {
        let initialLoan = 0;
        for (let i in payload){
            if(payload[i].Estimated_Outstanding_Amount__c){
                initialLoan += payload[i].Estimated_Outstanding_Amount__c;
            }
        }

        if(initialLoan > 0){
            state.amountLoan = initialLoan;
        }else{
            state.amountLoan = null;
        }
    },
    requestProductType(state, payload) {
        let data = payload.data;
        if(data && data.length > 0){
            state.productType = data
        }else{
            state.productType = []
        }
    },
    requestCCType(state, payload) {
        let data = payload.data;
        if(data && data.length > 0){
            let dataCCTypes = []
            for (let i in data){
                dataCCTypes.push({ value: data[i].name, label: data[i].name});
            }
            state.ccType = dataCCTypes
        }else{
            state.ccType = []
        }
    },
    requestQualifiedLender(state, payload) {
        let data = payload.data;
        if(data && data.length > 0){
            let dataLenders = []
            
            for (let i in data){
                let fileIcon = 'default.png'

                const lenderName = data[i].lender_name__r.Name.toLowerCase()
                const lenderData = iconBankLender.filter(elem => elem.name == lenderName)

                if (!lenderData[0]){
                    const loanType = data[i].Name.replace(data[i].lender_name__r.Name + ' ', '')
                    const loanTypeIcon = listLoanType.filter(elem => elem.name == loanType);
                    if (loanTypeIcon[0]) fileIcon = loanTypeIcon[0].icon
                } else {
                    fileIcon = lenderData[0].icon
                }

                dataLenders.push({
                    value: data[i].Name,
                    label: data[i].lender_name__r.Name,
                    icon: require('@/assets/img/bank-lender-icon/' + fileIcon)
                });
            }

            state.qualifiedLender = dataLenders
        }else{
            state.qualifiedLender = []
        }
    },
    resetQualifiedLender(state){
        state.qualifiedLender = []
    },
    requestFinancialHardship(state, payload) {
        let data = payload.data;
        if(data && data.length > 0){
            state.financialHardships = data
        }else{
            state.financialHardships = []
        }
    },
    setFormUpdateContact(state, payload){
        state.formUpdateContact.financialHardship = payload.financialHardship ?? []
        state.formUpdateContact.financialHardshipDetail = payload.financialHardshipDetail ?? null
        state.formUpdateContact.optional = payload.optional ?? null
    },
    setMonthlySaving(state, payload){
        state.formUpdateContact.monthlySaving = payload.monthlySaving
    },
    prepopulateContactData(state, payload){
        state.contact = payload.data ?? null
        if(state.contact){
            state.formUpdateContact.financialHardshipDetail = state.contact.Financial_Hardships_Detail__c ?? null
            state.formUpdateContact.monthlySaving = state.contact.Monthly_Saving__c ?? null
        }

        if(state.financialHardships.length > 0 && state.contact.Financial_Hardships__c){
            const dataFinancialHardships = state.financialHardships
            const financialHardship = state.contact.Financial_Hardships__c.split(';')
            let newDataFinancialHardships = dataFinancialHardships.map(elem => elem.value)            
            let newfinancialHardship = []
            for(let i in financialHardship){
                if(newDataFinancialHardships.includes(financialHardship[i])){
                    newfinancialHardship.push(financialHardship[i])
                }else{
                    newfinancialHardship.push('optional')
                    state.formUpdateContact.optional = financialHardship[i]
                }
            }
            state.formUpdateContact.financialHardship = newfinancialHardship
        }else{
            state.formUpdateContact.financialHardship = null
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
