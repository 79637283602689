// import store from ".."
import moment from 'moment'
import apiRdpManagement from "@/axios/rdpManagement"
import { format_number_compact } from '@/composables/numberFormatting'

const FETCH_STATUS = { FETCH: 'FETCH', DONE: 'DONE' }
const MONTH_LIST = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des']
const MONTHLY_INITIAL = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

const fetchAccountStatement = async (payload) => {
    return new Promise((resolve, reject) => {
        apiRdpManagement.post('/bni/account-statement', payload)
        .then((res) => {
            resolve({
                ...payload,
                ...res.data.data
            })
        })
        .catch(() => {
            reject('cannot get account statement')
        })
    })
}

const state = () => ({
    filterData: {
        type: 'Bulanan',
        year: null
    },
    FETCH_STATUS: FETCH_STATUS.DONE,
    cacheChartSeriesData: [],
    chartConfigData: {
        textStyle: {
            fontFamily: 'Open Sans'
        },
        tooltip: {
            trigger: 'axis',
            position: ['5%', '5%']
        },
        grid: {
            left: 10,
            top: 20,
            right: 10,
            bottom: 20
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            axisTick: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    color: '#252161'
                }
            },
            axisLabel: {
                color: '#1A1A1A',
                fontSize: 9,
                interval: 0
            },
            data: MONTH_LIST
        },
        yAxis: {
            show: false,
            type: 'value'
        },
        series: [
            {
                name: 'Tabungan Ideal',
                type: 'line',
                symbol: 'circle',
                showSymbol: false,
                lineStyle: {
                    type: 'dashed',
                    width: 1,
                    color: '#E41C1C',
                    opacity: 0.5
                },
                itemStyle: {
                    color: '#E41C1C',
                    borderColor: '#E41C1C',
                    borderWidth: 3
                },
                data: []
            },
            {
                name: 'Tabungan',
                type: 'line',
                symbol: 'circle',
                lineStyle: {
                    color: '#56CC12',
                    width: 1
                },
                itemStyle: {
                    color: '#56CC12',
                    borderColor: '#56CC12',
                    borderWidth: 3
                },
                label: {
                    show: true,
                    position: 'top',
                    distance: 1,
                    formatter: (elem) => format_number_compact(elem.data)
                },
                data: []
            }
        ]
    }
})

const getters = {
    getFilterData(state) {
        return state.filterData
    },
    getFetchStatus(state) {
        return state.FETCH_STATUS
    },
    getChartConfigData(state) {
        return state.chartConfigData
    },
    getSavingSeriesData(state) {
        return state.chartConfigData.series[1].data
    }
}

const actions = {
    async getAccountStatement({ commit, state, rootGetters }, payload) {
        state.FETCH_STATUS = FETCH_STATUS.FETCH
        const cacheDataKey = state.cacheChartSeriesData.findIndex((value) => value.year === state.filterData.year)
        const cacheData = state.cacheChartSeriesData[cacheDataKey]
        const outstandingAmount = rootGetters['loanProgress/getOutstandingAmount']
        
        if(cacheData.isTouch){
            commit('setAccountStatementFromCache', {data: cacheData.data, outstandingAmount})
            state.FETCH_STATUS = FETCH_STATUS.DONE
            return
        }

        try {
            const data = await fetchAccountStatement(payload)
            commit('setAccountStatement', {to_date: data.to_date, data, cacheDataKey, outstandingAmount})
            state.FETCH_STATUS = FETCH_STATUS.DONE
        } catch (error) {
            console.log(error)
            commit('resetAccountStatement')
            state.FETCH_STATUS = FETCH_STATUS.DONE
        }
    },
    async getAccountStatementYearly({ commit, state, rootGetters }) {
        state.FETCH_STATUS = FETCH_STATUS.FETCH
        const untouchData = state.cacheChartSeriesData.filter((value) => !value.isTouch)
        const outstandingAmount = rootGetters['loanProgress/getOutstandingAmount']
        
        if(untouchData.length === 0){
            commit('setAccountStatementFromCacheYearly', {outstandingAmount})
            state.FETCH_STATUS = FETCH_STATUS.DONE
            return
        }
        
        const fetchData = untouchData.map((value) => fetchAccountStatement({
            from_date: value.year + '-01-01',
            to_date: value.year + '-12-31'
        }))

        const result = await Promise.allSettled(fetchData)
        result.forEach((value) => {
            if(value.status === 'rejected') return

            const year = value.value.to_date.split('-')[0]
            const cacheDataKey = state.cacheChartSeriesData.findIndex((value) => value.year === year)
            commit('setAccountStatement', {to_date: value.value.to_date, data: value.value, cacheDataKey, outstandingAmount})
        })

        commit('setAccountStatementFromCacheYearly', {outstandingAmount})
        state.FETCH_STATUS = FETCH_STATUS.DONE
    },
    changeFilterData({ commit }, payload) {
        switch (payload.filterType) {
            case 'type':
                commit('setFilterType', payload.data)
                break;
                
            default:
                commit('setFilterYear', payload.data)
                break;
        }
    },
    defineCacheChartSeries({ commit }, payload){
        commit('defineCacheChartSeries', payload)
    }
}

const mutations = {
    setAccountStatement(state, {to_date, data, cacheDataKey, outstandingAmount}){
        try {
            state.chartConfigData.xAxis.data = MONTH_LIST
            
            const constructedData = constractStatementData(to_date, data)
            state.cacheChartSeriesData[cacheDataKey].data = constructedData
            state.cacheChartSeriesData[cacheDataKey].isTouch = true
            state.chartConfigData.series[0].data = outstandingAmount ? MONTHLY_INITIAL.map((value, index) => Math.round((0.05 * outstandingAmount) * (index + 1))) : []
            state.chartConfigData.series[1].data = constructedData
        } catch (error) {
            state.cacheChartSeriesData[cacheDataKey].data = []
            state.cacheChartSeriesData[cacheDataKey].isTouch = true
            state.chartConfigData.xAxis.data = MONTH_LIST
            state.chartConfigData.series[0].data = []
            state.chartConfigData.series[1].data = []
        }
    },
    resetAccountStatement(state){
        state.chartConfigData.xAxis.data = MONTH_LIST
        state.chartConfigData.series[0].data = []
        state.chartConfigData.series[1].data = []
    },
    setFilterType(state, payload){
        state.filterData.type = payload
    },
    setFilterYear(state, payload){
        state.filterData.year = payload
    },
    setAccountStatementFromCache(state, {data, outstandingAmount}){
        state.chartConfigData.xAxis.data = MONTH_LIST
        state.chartConfigData.series[0].data = outstandingAmount ? MONTHLY_INITIAL.map((value, index) => Math.round((0.05 * outstandingAmount) * (index + 1))) : []
        state.chartConfigData.series[1].data = data
    },
    setAccountStatementFromCacheYearly(state, {outstandingAmount}){
        const maxYearIndex = state.cacheChartSeriesData.findIndex((value) => value.year === state.filterData.year)
        const cacheData = state.cacheChartSeriesData.slice(0, maxYearIndex + 1)

        let yearList = []
        let amountYearly = []

        cacheData.forEach((value) => {
            const amount = value.data.length > 0 ? value.data[value.data.length - 1] : 0

            amountYearly.push(amount)
            yearList.push(value.year)
        })

        state.chartConfigData.xAxis.data = yearList
        state.chartConfigData.series[0].data = outstandingAmount ? amountYearly.map((value, index) => Math.round((0.6 * outstandingAmount) * (index + 1))) : []
        state.chartConfigData.series[1].data = mapAmountData(amountYearly)
    },
    defineCacheChartSeries(state, payload){
        if(state.cacheChartSeriesData.length === 0){
            payload.forEach((value) => {
                state.cacheChartSeriesData.push({
                    year: value,
                    isTouch: false,
                    data: []
                })
            })
        }
    }
}

const constractStatementData = (to_date, data) => {
    const mutations = data.data
    if(mutations.length === 0) throw new Error('No data found')

    const initialAmountChart = defineInitialAmountChart(to_date)

    mutations.forEach((mutation) => {
        if(mutation.credit && mutation.credit < 1) return

        const month = moment(mutation.transactionTimestamp).format('MM')
        const monthIndex = +month

        return initialAmountChart[monthIndex - 1] += parseFloat(mutation.credit)
    })

    const result = mapAmountData(initialAmountChart)
    return result
}

const defineInitialAmountChart = (to_date) => {
    const end = +(to_date.split('-')[1])

    const result = []
    for (let index = 0; index < end; index++) {
        result.push(0)
    }

    return result
}

const mapAmountData = (data) => {
    return data.map((elem, index, arr) => {
        if(index === 0) return elem
        
        let amount = 0
        for (let i = 0; i <= index; i++) {
            amount += arr[i];
        }
        
        return amount
    })
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
