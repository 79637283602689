import InformasiUmum from '@/views/LoanConsolidation/InformasiUmum.vue'
import DataDiri from '@/views/LoanConsolidation/DataDiri.vue'
import DataPekerjaan from '@/views/LoanConsolidation/DataPekerjaan.vue'
import DataAset from '@/views/LoanConsolidation/DataAset.vue'
import DataUtang from '@/views/LoanConsolidation/DataUtang.vue'
import ExtraQuestions from '@/views/LoanConsolidation/ExtraQuestions.vue'
import KonfirmasiLayanan from '@/views/LoanConsolidation/KonfirmasiLayanan.vue'

export default [
    {
        path: '/kredit-bijak-karyawan/general-questions',
        name: 'Loan Consolidation - Registrasi - Informasi Umum',
        component: InformasiUmum,
        meta: {
            name: 'Loan Consolidation - Registrasi - Informasi Umum',
            isAuth: true,
            loanConsolidation: true,
            title: 'Registrasi Kredit Bijak',
            excludeKYC: true
        }
    },
    {
        path: '/kredit-bijak-karyawan/personal-information',
        name: 'Loan Consolidation - Registrasi - Data Diri',
        component: DataDiri,
        meta: {
            name: 'Loan Consolidation - Registrasi - Data Diri',
            isAuth: true,
            loanConsolidation: true,
            title: 'Registrasi Kredit Bijak',
            excludeKYC: true
        }
    },
    {
        path: '/kredit-bijak-karyawan/job-information',
        name: 'Loan Consolidation - Registrasi - Data Pekerjaan',
        component: DataPekerjaan,
        meta: {
            name: 'Loan Consolidation - Registrasi - Data Pekerjaan',
            isAuth: true,
            loanConsolidation: true,
            title: 'Registrasi Kredit Bijak',
            excludeKYC: true
        }
    },
    {
        path: '/kredit-bijak-karyawan/mortgage-information',
        name: 'Loan Consolidation - Registrasi - Data Aset',
        component: DataAset,
        meta: {
            name: 'Loan Consolidation - Registrasi - Data Aset',
            isAuth: true,
            loanConsolidation: true,
            title: 'Registrasi Kredit Bijak',
            excludeKYC: true
        }
    },
    {
        path: '/kredit-bijak-karyawan/initial-results',
        name: 'Loan Consolidation - Registrasi - Qualification',
        component: KonfirmasiLayanan,
        meta: {
            name: 'Loan Consolidation - Registrasi - Qualification',
            isAuth: true,
            loanConsolidation: true,
            title: 'Registrasi Kredit Bijak',
            excludeKYC: true
        }
    },
    {
        path: '/kredit-bijak-karyawan/loan-data',
        name: 'Loan Consolidation - Registrasi - Daftar Pinjaman',
        component: DataUtang,
        meta: {
            name: 'Loan Consolidation - Registrasi - Daftar Pinjaman',
            isAuth: true,
            loanConsolidation: true,
            title: 'Registrasi Kredit Bijak',
            excludeKYC: true
        }
    },
    {
        path: '/kredit-bijak-karyawan/extra-questions',
        name: 'Loan Consolidation - Registrasi - Extra Questions',
        component: ExtraQuestions,
        meta: {
            name: 'Loan Consolidation - Registrasi - Extra Questions',
            isAuth: true,
            loanConsolidation: true,
            title: 'Registrasi Kredit Bijak',
            excludeKYC: true
        }
    },
    {
        path: '/kredit-bijak-karyawan/closing',
        name: 'Loan Consolidation - Registrasi - Finish Page',
        component: KonfirmasiLayanan,
        meta: {
            name: 'Loan Consolidation - Registrasi - Finish Page',
            isAuth: true,
            loanConsolidation: true,
            title: 'Registrasi Kredit Bijak',
            excludeKYC: true
        }
    }
];