<script setup>
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { ref, onMounted, computed, inject, watch, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import RDPCard from './Components/RDPCard.vue'
import MutasiTab from './Components/MutasiTab.vue'
import NotifikasiTab from './Components/NotifikasiTab.vue'
import { useStore } from 'vuex'
import FeedBackLogo from '@/components/icon/FeedBackLogo.vue'
import FeedBackStarLogo from '@/components/icon/FeedBackStarLogo.vue'
import apiRdpManagement from "@/axios/rdpManagement"
import DoneBanner from '@/components/icon/DoneBanner'

const router = useRouter()
const route = useRoute()
const store = useStore()
const swal = inject('$swal')

const rdpData = computed(() => store.getters['RDPManagement/getRdpData'])
const rdpDataIsExist = computed(() => store.getters['RDPManagement/getRdpExisting'])
const notificationOn = computed(() => store.getters['RDPManagement/paymentDataNotification'])
const showMutasi = ref(true)
const showNotifikasi = ref(false)
const screenWidth = ref(null)
const RDP_DATA_STATUSES = { FETCH: 'FETCH', FOUND: 'FOUND', NOT_FOUND: 'NOT_FOUND', ERROR: 'ERROR' }
const RDPCardMounted = ref(true)
const showFeedbackPopUp = ref(false)
const feedbackFormData = reactive({
    rate: 0,
    remarks: null
})
const isLoading = ref(false)
const feedbackSuccessfully = ref(false)
const feedbackFormIsValid = computed(() => feedbackFormData.rate && feedbackFormData.remarks ? true : false)

const animatedTabStyle = ref({
    width: '0px',
    height: '0px',
    transform: 'translateX(0%)'
})

const swalErrorReload = swal.mixin({
    customClass: {
        confirmButton: 'bg-amalan-blue-1 mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-white text-sm font-semibold hover:bg-amalan-blue-2 hover:border-amalan-blue-2'
    },
    buttonsStyling: false,
    title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
    html: '<p class="text-center text-sm mt-2">Maaf, terjadi kesalahan dalam pengambilan informasi!</p>',
    showCancelButton: false,
    confirmButtonText: 'Reload',
    width: '340px',
    allowOutsideClick: false,
})

const selectMutasi = () => {
    showMutasi.value = true
    showNotifikasi.value = false
    animatedTabStyle.value.transform = 'translateX(0%)'
}

const selectNotifikasi = () => {
    showNotifikasi.value = true
    showMutasi.value = false
    animatedTabStyle.value.transform = screenWidth.value >= 420 ? 'translateX(107%)' : 'translateX(110%)'
}

const defineAnimatedTabStyle = () => {
    const rdpNavTab = document.getElementById('rdp-navigation-tab')
    animatedTabStyle.value.height = rdpNavTab.offsetHeight + 'px'
    animatedTabStyle.value.width = rdpNavTab.offsetWidth + 'px'
}

const callErrorPopUp = () => {
    swalErrorReload.fire().then((result) => {
        if(!result.isConfirmed) return
        window.location.reload()
    })
}

watch(rdpDataIsExist, () => {
    if(rdpDataIsExist.value == RDP_DATA_STATUSES.ERROR) callErrorPopUp()
    if(rdpDataIsExist.value == RDP_DATA_STATUSES.FOUND){
        setTimeout(() => {
            defineAnimatedTabStyle()
        }, 100);
    }
})

watch(rdpData, () => {
    RDPCardMounted.value = false
    setTimeout(() => {
        RDPCardMounted.value = true
    }, 100);
}, {
    deep: true
})

const setFeedbackRate = (i) => feedbackFormData.rate = i

const feedbackFormClose = () => showFeedbackPopUp.value = false

const feedbackFormSubmit = async () => {
    const payload = {
        rate: feedbackFormData.rate,
        remarks: feedbackFormData.remarks.replaceAll("\n", ' ')
    }
    
    try {
        isLoading.value = true
        await apiRdpManagement.post('/bni/feedback', payload)
        await store.dispatch('RDPManagement/requestRdpData')
        isLoading.value = false
        feedbackSuccessfully.value = true
        feedbackFormClose()
        setTimeout(() => {
            feedbackSuccessfully.value = false
        }, 4000);
    } catch {
        console.log('an error was occured during sending feedback')
    }
}

const routeBack = () => {
    if(route.query.from && route.query.from == 'widget') return router.push({ name: 'Home' })
    if(route.query.from && route.query.from == 'loan-management') return router.push({ name: 'Loan Management - Home' })
    return router.push({ name: 'Akun' })
}

onMounted(() => {
    screenWidth.value = screen.width
    if(route.query.tab == 'notifikasi') selectNotifikasi()

    if(rdpDataIsExist.value == RDP_DATA_STATUSES.ERROR) callErrorPopUp()
    if(rdpDataIsExist.value == RDP_DATA_STATUSES.FOUND){
        setTimeout(() => {
            defineAnimatedTabStyle()
        }, 100);

        setTimeout(() => {
            showFeedbackPopUp.value = rdpData.value.feedbackStatus ? false : true
        }, 1000);
    }
})

</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="routeBack" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div v-if="(rdpData.isExist === RDP_DATA_STATUSES.FOUND)" class="w-full px-6 pt-4 pb-10 h-800 relative">
            <div class="w-full py-4">
                <RDPCard v-if="RDPCardMounted" :card-type="rdpData.cardType" :account-number="rdpData.accountNumber" :balance="rdpData.balance" :full-name="rdpData.fullName" :created-date="rdpData.createdDate" />
            </div>
            <div class="w-full p-2 bg-amalan-gray-4 rounded-amalan-lg">
                <div class="w-full relative rounded-amalan-lg bg-transparent rdp-navigation-tab-wrapper">
                    <div class="rounded-amalan-lg absolute bg-amalan-white shadow-amalan-md cursor-pointer z-1 transition-transform ease-in-out duration-200" :style="animatedTabStyle"></div>
                    <div class="w-full h-full inset-0 rounded-amalan-lg absolute grid grid-cols-2 gap-x-4 z-10">
                        <div id="rdp-navigation-tab" @click="selectMutasi" class="flex justify-center items-center rounded-amalan-lg cursor-pointer" :class="{ 'hover:bg-amalan-gray-5': !showMutasi }">
                            <img v-show="showMutasi" src="@/assets/img/rdp/lihat-mutasi-icon-active.svg" class="h-4">
                            <img v-show="!showMutasi" src="@/assets/img/rdp/lihat-mutasi-icon.svg" class="h-4">
                            <p class="text-xs font-bold ml-2 transition-all ease-in-out duration-300" :class="[ showMutasi ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]">Lihat Mutasi</p>
                        </div>
                        <div @click="selectNotifikasi" class="flex justify-center items-center rounded-amalan-lg cursor-pointer" :class="{ 'hover:bg-amalan-gray-5': !showNotifikasi }">
                            <div class="relative">
                                <div v-if="(notificationOn && !showNotifikasi)" class="absolute h-2 w-2 bg-amalan-red rounded-full -top-0.5 -right-0.5"></div>
                                <img v-show="showNotifikasi" src="@/assets/img/rdp/notifikasi-icon-active.svg" class="h-4">
                                <img v-show="!showNotifikasi" src="@/assets/img/rdp/notifikasi-icon.svg" class="h-4">
                            </div>
                            <p class="text-xs font-bold ml-2 transition-all ease-in-out duration-300" :class="[ showNotifikasi ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]">Notifikasi</p>
                        </div>
                    </div>
                </div>
            </div>
            <Transition name="fade-fast">
                <div v-show="showMutasi" class="absolute inset-x-6">
                    <MutasiTab />
                </div>
            </Transition>
            <Transition name="fade-fast">
                <div v-show="showNotifikasi" class="absolute inset-x-6">
                    <NotifikasiTab />
                </div>
            </Transition>
            <Transition name="fade-fast">
                <div v-show="showFeedbackPopUp" class="fixed inset-0 z-50">
                    <div class="w-full bg-black bg-opacity-50 p-6 min-h-screen max-w-lg mx-auto flex justify-center items-center">
                        <div class="w-full py-8 px-4 bg-amalan-white rounded-lg -mt-10">
                            <div class="w-full flex justify-center items-center"><FeedBackLogo /></div>
                            <p class="text-amalan-black text-sm text-center mt-8">Apakah Anda menyukai fitur<br>“Informasi RDP” ini?</p>
                            <div class="w-full flex justify-center items-center gap-x-1.5 mt-3.5">
                                <FeedBackStarLogo v-for="i in 5" :key="i" class="cursor-pointer" @click="setFeedbackRate(i)" :active="i <= feedbackFormData.rate ? true : false" />
                            </div>
                            <textarea v-model="feedbackFormData.remarks" rows="6" class="w-full mt-3.5 p-2.5 border border-amalan-gray-3 bg-amalan-white rounded-lg text-xs text-amalan-black" placeholder="Tulis ulasan Anda"></textarea>
                            <div class="w-full mt-8 grid grid-cols-2 gap-x-4">
                                <button @click="feedbackFormClose" class="w-full py-3 text-center text-sm font-bold rounded-amalan-lg" :class="[ feedbackFormIsValid ? 'bg-amalan-white text-amalan-blue-1 border border-amalan-blue-1 shadow-amalan-md hover:bg-amalan-gray-5' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="feedbackFormIsValid ? false : true">Batal</button>
                                <button @click="feedbackFormSubmit" class="w-full py-3 text-center text-sm font-bold rounded-amalan-lg" :class="[ feedbackFormIsValid ? 'border shadow-amalan-md bg-amalan-blue-1 text-amalan-white border-amalan-blue-1 hover:bg-amalan-blue-2' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="feedbackFormIsValid ? false : true">Kirim</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
            <Transition name="fade-fast">
                <div v-show="feedbackSuccessfully" class="fixed inset-0 z-50">
                    <div class="w-full bg-black bg-opacity-50 p-6 min-h-screen max-w-lg mx-auto flex justify-center items-center">
                        <div class="w-full py-8 px-4 bg-amalan-white rounded-lg -mt-10">
                            <div class="w-full flex justify-center items-center"><DoneBanner /></div>
                            <p class="text-amalan-black text-xs text-center mt-8">Ulasan Anda sudah kami simpan.<br>Terima kasih telah mempercayakan masalah utang Anda kepada amalan.</p>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
        <div v-else-if="(rdpData.isExist === RDP_DATA_STATUSES.NOT_FOUND)" class="w-full min-h-screen px-6 flex flex-col justify-center items-center">
            <img src="@/assets/img/clipboard-v2.svg" class="w-2/6">
            <p class="text-center text-xs text-amalan-black mt-6">Anda belum melakukan Registrasi Pembukaan<br><b>Rekening Dana Pelunasan (RDP)</b>.<br>Silahkan melakukan registrasi terlebih dahulu.</p>
            <router-link to="/rdp" class="flex items-center justify-center py-3 px-10 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg mt-14">
                <span class="text-amalan-white text-sm font-bold">
                    Lakukan Pembukaan RDP Sekarang
                </span>
            </router-link>
        </div>
        <div v-else></div>
        <loading-overlay v-if="(rdpData.isExist === RDP_DATA_STATUSES.FETCH) || isLoading" />
    </div>
</template>

<style scoped>
.rdp-navigation-tab-wrapper{
    height: 29px;
}

.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.3s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.3s ease
}
</style>