<script setup>
import { defineProps, ref, onMounted, watch } from 'vue'
import Vue3autocounter from 'vue3-autocounter'

const props = defineProps({
    text: String,
    counterStartAt: {
        required: false,
        type: Number,
        default: 0
    },
    counterEndAt: {
        required: false,
        type: Number,
        default: 100
    },
    counterDuration: {
        required: false,
        type: Number,
        default: 2
    }
})

const counterRef = ref(null)

watch(() => props.counterStartAt, (value, oldValue) => {
    if(value !== oldValue) return counterRef.value?.reset()
    return
})

onMounted(() => counterRef.value?.start())
</script>

<template>
    <div class="fixed inset-0 z-50 bg-gray-900 bg-opacity-75 flex items-center justify-center p-3">
        <div class="bg-white max-w-lg rounded-lg px-6 py-4">
            <div class="flex justify-center mb-3 relative">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; display: block; shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <circle cx="50" cy="50" r="31" stroke-width="7" stroke="#ffe700" stroke-dasharray="48.69468613064179 48.69468613064179" fill="none" stroke-linecap="round">
                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.0526315789473684s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
                    </circle>
                </svg>
                <div class="absolute top-10 inset-x-0 mx-auto flex justify-center items-center">
                    <Vue3autocounter class="text-amalan-gray-1 text-xs font-bold" ref="counterRef" :startAmount="props.counterStartAt" :endAmount="props.counterEndAt" :duration="props.counterDuration" suffix="%" />
                </div>
            </div>
            <p class="text-amalan-black text-xs text-center" v-html="props.text ?? 'Sistem Kami sedang<br>memproses data Anda'"></p>
        </div>
    </div>
</template>