<script setup>
import { computed, reactive, onMounted, ref, inject, watch } from 'vue'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { Form, Field } from "vee-validate"
import * as yup from "yup"
import axios from "@/axios"
import CurrencyInput from "@/components/CurrencyInput2.vue"
import useModalPopUp from '@/composables/modalPopUp'

const route = useRoute()
const router = useRouter()
const store = useStore()
const modalPopUp = useModalPopUp()
const filters = inject("Filters")

const dataUser = computed(() => store.getters['user/getUser'])
const dataDocumentPoa = computed(() => store.getters['user/getDocumentPoa'])

const isLoading = ref(false)
const getDataLoading = ref(false)
const postalCodeValid = ref(false)
const currentStep = ref(2)
const formValues = reactive({
    npwp: null,
    monthly_income: 0,
    mother_maiden_name: null,
    occupationId: null,
    company_name: null,
    street: null,
    rt: null,
    rw: null,
    villageId: null,
    districtId: null,
    cityId: null,
    provinceId: null,
    postalCode: null
})
const dataOccupation = ref([])
const dataProvince = ref([])
const dataCity = ref([])
const dataDistrict = ref([])
const dataVillage = ref([])
const isFirstFormValid = ref(false)
const isSecondFormValid = ref(true)

const schemas = [
    yup.object({
        npwp: yup.string().nullable().matches(/^[0-9]+$/, "Harus dalam bentuk angka").length(15, "Nomor NPWP harus 15 digit").transform((value) => value === '' ? null : value),
        mother_maiden_name: yup.string().nullable().required('Nama Ibu kandung tidak boleh kosong').max(50, "Maksimal 50 karakter").trim('Mohon isi'),
    }),
    yup.object({
        occupationId: yup.string().nullable().required('Silahkan pilih pekerjaan'),
        company_name: yup.string().nullable().required('Tempat Bekerja tidak boleh kosong').max(255, "Maksimal 255 karakter"),
        street: yup.string().nullable().required('Alamat Instansi tidak boleh kosong').max(255, "Maksimal 255 karakter"),
        rt: yup.string().nullable().required('RT tidak boleh kosong').matches(/^[0-9]+$/, "RT harus berupa angka").max(5, "Maksimal 5 karakter"),
        rw: yup.string().nullable().required('RW tidak boleh kosong').matches(/^[0-9]+$/, "RW harus berupa angka").max(5, "Maksimal 5 karakter"),
        provinceId: yup.string().nullable().required('Silahkan pilih provinsi'),
        cityId: yup.string().nullable().required('Silahkan pilih kota'),
        districtId: yup.string().nullable().required('Silahkan pilih kecamatan'),
        villageId: yup.string().nullable().required('Silahkan pilih kelurahan'),
        postalCode: yup.string().nullable().required('Kode pos tidak boleh kosong')
        .matches(/^[0-9]+$/, "Kode pos harus berupa angka").max(5, "Maksimal 5 karakter"),
        monthly_income: yup.number('Masukan dalam bentuk angka').nullable().required('Pendapatan tidak boleh kosong')
        .test('maxLength', 'Maksimal 10 digit', (value) => value && value.toString().length <= 10)
        .test('minLength', 'Minimal 5 digit', (value) => value && value.toString().length >= 5)
        .transform((value) => value && value === Number(value) ? value : 0)
    }),
    yup.object({})
]

const updateForm = computed(() => schemas[currentStep.value])

const nextStep = (values) => {
    if (currentStep.value !== 2) {
        Object.assign(formValues, values)
        return currentStep.value = 2
    }

    const data = {
        npwp: formValues.npwp ? formValues.npwp === '' ? null : formValues.npwp : null,
        monthly_income: formValues.monthly_income ?? null,
        mother_maiden_name: formValues.mother_maiden_name ?? null
    }

    const occupationData = {
        occupation_id: formValues.occupationId,
        rt_rw: formValues.rt + '_' + formValues.rw,
        ...formValues
    }

    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs text-amalan-black mt-2">Anda yakin yang diinputkan sudah benar?<br>Jika sudah, silahkan untuk dikonfirmasi agar data Anda segera diproses!</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Konfirmasi',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if (!result.isConfirmed) return

        isLoading.value = true
        axios.post('/customer/occupation-address', occupationData)
        .then(() => {
            axios.put('/bni/rdp-preform', data)
            .then(() => {
                isLoading.value = false
                router.push({ name : 'rdp opening - email verification' })
            }).catch((err) => {
                isLoading.value = false

                if(err.response.data.message.includes('Duplicate entry')){
                    modalPopUp.swalError.fire({
                        html: '<p class="text-center text-xs text-amalan-black mt-2">Maaf, Nomor NPWP Anda terdeteksi sudah terdaftar sebelumnya. Silahkan tekan tombol bantuan dibawah ini atau hubungi konsultan Anda.</p>',
                        showCancelButton: true,
                        confirmButtonText: 'Bantuan',
                        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                    }).then((result) => {
                        if (!result.isConfirmed) return
                        return window.location = 'https://wa.me/622150933150?text=Mohon bantuan saya tidak dapat melanjutkan proses karena Nomor NPWP saya sudah terdaftar di akun lain.'
                    })
                } else {
                    modalPopUp.swalError.fire({
                        html: '<p class="text-center text-xs text-amalan-black mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                        showCancelButton: true,
                        confirmButtonText: 'Bantuan',
                        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                    }).then((result) => {
                        if (!result.isConfirmed) return
                        return window.location = 'https://wa.me/622150933150?text=Mohon bantuan saya gagal terus dalam pengisian data pengguna pada proses pembukaan RDP di web.amalan.com'
                    })
                }
            })
        })
        .catch(() => {
            isLoading.value = false

            modalPopUp.swalError.fire({
                html: '<p class="text-center text-xs text-amalan-black mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                showCancelButton: true,
                confirmButtonText: 'Bantuan',
                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
            }).then((result) => {
                if (!result.isConfirmed) return
                return window.location = 'https://wa.me/622150933150?text=Mohon bantuan saya gagal terus dalam pengisian data pengguna pada proses pembukaan RDP di web.amalan.com'
            })
        })
    })
}

watch(formValues, () => revalidateForm(), { deep: true })

const revalidateForm = async () => {
    isFirstFormValid.value = await schemas[0].isValid(formValues)
    isSecondFormValid.value = await schemas[1].isValid(formValues)
}

const goToFirstForm = () => currentStep.value = 0
const goToSecondForm = () => currentStep.value = 1

axios.get('/occupations')
.then((resp) => {
    dataOccupation.value = resp.data.data
    formValues.occupationId = dataUser.value.occupation.id
})
.catch(() => modalPopUp.swalErrorReload.fire().then(() => window.location.reload()))

setTimeout(() => {
    axios.get('/regions/provinces')
    .then((resp) => {
        dataProvince.value = resp.data.data
        formValues.provinceId = dataUser.value.address.province.id
    })
    .catch(() => modalPopUp.swalErrorReload.fire().then(() => window.location.reload()))

    if (dataUser.value.address.province.id !== null){
        axios.get('/regions/cities?province_id=' + dataUser.value.address.province.id)
        .then((resp) => {
            dataCity.value = resp.data.data
            formValues.cityId = dataUser.value.address.city.id
        })
        .catch(() => modalPopUp.swalErrorReload.fire().then(() => window.location.reload()))
    }
}, 1000)

setTimeout(() => {
    if (dataUser.value.address.city.id !== null){
        axios.get('/regions/districts?city_id=' + dataUser.value.address.city.id)
        .then((resp) => {
            dataDistrict.value = resp.data.data
            formValues.districtId = dataUser.value.address.district.id
        })
        .catch(() => modalPopUp.swalErrorReload.fire().then(() => window.location.reload()))
    }

    if (dataUser.value.address.district.id !== null){
        axios.get('/regions/villages?district_id=' + dataUser.value.address.district.id)
        .then((resp) => {
            dataVillage.value = resp.data.data
            formValues.villageId = dataUser.value.address.village.id
        })
        .catch(() => modalPopUp.swalErrorReload.fire().then(() => window.location.reload()))
    }
}, 3000)

const getCity = () => {
    getDataLoading.value = true
    formValues.cityId = null
    axios.get('/regions/cities?province_id=' + formValues.provinceId)
    .then((resp) => {
        dataCity.value = resp.data.data
        getDataLoading.value = false
    })
    .catch(() => {
        getDataLoading.value = false
        modalPopUp.swalErrorReload.fire().then(() => window.location.reload())
    })
}

const getDistrict = () => {
    getDataLoading.value = true
    formValues.districtId = null
    axios.get('/regions/districts?city_id=' + formValues.cityId)
    .then((resp) => {
        dataDistrict.value = resp.data.data
        getDataLoading.value = false
    })
    .catch(() => {
        getDataLoading.value = false
        modalPopUp.swalErrorReload.fire().then(() => window.location.reload())
    })
}

const getVillage = () => {
    getDataLoading.value = true
    formValues.villageId = null
    axios.get('/regions/villages?district_id=' + formValues.districtId)
    .then((resp) => {
        dataVillage.value = resp.data.data
        getDataLoading.value = false
    })
    .catch(() => {
        getDataLoading.value = false
        modalPopUp.swalErrorReload.fire().then(() => window.location.reload())
    })
}

const validatePostalCode = () => {
    axios.get('/postal-code/' + formValues.postalCode)
    .then(() => postalCodeValid.value = true)
    .catch(() => postalCodeValid.value = false)
}

watch(() => formValues.postalCode, () => {
    postalCodeValid.value = false
    if(formValues.postalCode) if(formValues.postalCode.length === 5) validatePostalCode()
})

const getNpwpData = () => {
    isLoading.value = true
    axios.get('/customer/id-card', {
        params: {
            id_type: 'npwp'
        }
    })
    .then((resp) => {
        formValues.npwp = resp.data.data.number
        isLoading.value = false
    })
    .catch(() => {
        isLoading.value = false
    })
}

const getIncomeData = () => {
    axios.get('/customer/income')
    .then((resp) => {
        formValues.monthly_income = resp.data.data.monthly_amount ? parseInt(resp.data.data.monthly_amount) : 0
    })
}

const backNavigation = () => {
    if (currentStep.value >= 2) return router.push({ name: 'Home'})
    return currentStep.value = 2
}

const occupationName = computed(() => {
    const result = dataOccupation.value.find(({ id }) => id === formValues.occupationId)
    if(result) return result.name
    return ''
})

const provinceName = computed(() => {
    const result = dataProvince.value.find(({ id }) => id === formValues.provinceId)
    if(result) return result.name
    return ''
})

const cityName = computed(() => {
    const result = dataCity.value.find(({ id }) => id === formValues.cityId)
    if(result) return result.name
    return ''
})

const districtName = computed(() => {
    const result = dataDistrict.value.find(({ id }) => id === formValues.districtId)
    if(result) return result.name
    return ''
})

const villageName = computed(() => {
    const result = dataVillage.value.find(({ id }) => id === formValues.villageId)
    if(result) return result.name
    return ''
})

onMounted(() => {
    if(!dataDocumentPoa.value) return router.push({ name : 'rdp opening - document poa' })

    getNpwpData()
    getIncomeData()

    const requestInfo = JSON.parse(dataDocumentPoa.value.request_info)
    formValues.mother_maiden_name = requestInfo.bni_mother_maiden_name ?? null
})

</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="backNavigation" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="bg-amalan-white px-6 pb-16 text-amalan-black min-h-screen">
            <Form @submit="nextStep" :validation-schema="updateForm" v-slot="{ handleSubmit, errors, meta }">
                <template v-if="currentStep === 0">
                    <div class="w-full pt-6 grid grid-col-1 gap-y-3.5">
                        <div class="w-full">
                            <label class="text-amalan-black font-bold text-sm">Nomor NPWP</label>
                            <Field name="npwp" v-model="formValues.npwp" :validateOnInput="true" :validateOnMount="true" type="text" placeholder="Nomor NPWP" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.npwp ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                            <div v-if="errors.npwp" class="mt-1 text-2xs text-amalan-red">*{{errors.npwp}}</div>
                            <div v-else class="mt-1 text-2xs text-amalan-blue-3">Silahkan isi jika Anda memilikinya</div>
                        </div>
                        <div class="w-full">
                            <label class="text-amalan-black font-bold text-sm">Nama Ibu Kandung</label>
                            <Field name="mother_maiden_name" v-model="formValues.mother_maiden_name" :validateOnInput="true" :validateOnMount="true" type="text" placeholder="Nama Ibu Kandung" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.mother_maiden_name ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                            <div v-if="errors.mother_maiden_name" class="mt-1 text-2xs text-amalan-red">*{{errors.mother_maiden_name}}</div>
                        </div>
                    </div>
                    <div class="mt-16">
                        <button type="submit" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!meta.valid" @submit="handleSubmit">Simpan</button>
                    </div>
                </template>

                <template v-if="currentStep === 1">
                    <div class="w-full pt-6 grid grid-col-1 gap-y-3.5">
                        <div>
                            <label class="text-sm font-bold">Pekerjaan</label>
                            <div class="flex relative w-full">
                                <Field name="occupationId" as="select" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none cursor-pointer" :class="{ 'border-amalan-red': errors.occupationId }" :validateOnMount="true" v-model="formValues.occupationId">
                                    <option v-for="occupation in dataOccupation" :key="occupation.key" :value="occupation.id">{{ occupation.name }}</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="errors.occupationId" class="mt-1 text-2xs text-amalan-red">{{errors.occupationId}}</div>
                        </div>
                        <div>
                            <label class="text-amalan-black font-bold text-sm">Tempat Bekerja</label>
                            <Field placeholder="Alamat" name="company_name" v-model="formValues.company_name" :validateOnInput="true" :validateOnMount="true" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.company_name ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                            <div v-if="errors.company_name" class="mt-1 text-2xs text-amalan-red">{{errors.company_name}}</div>
                        </div>
                        <div>
                            <label class="text-amalan-black font-bold text-sm">Alamat Instansi</label>
                            <Field placeholder="Alamat" name="street" v-model="formValues.street" :validateOnInput="true" :validateOnMount="true" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.street ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                            <div v-if="errors.street" class="mt-1 text-2xs text-amalan-red">{{errors.street}}</div>
                        </div>
                        <div class="grid grid-cols-2 gap-x-3.5">
                            <div>
                                <label class="text-amalan-black font-bold text-sm">RT</label>
                                <Field placeholder="RT" name="rt" v-model="formValues.rt" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.rt ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnInput="true" :validateOnMount="true"/>
                                <div v-if="errors.rt" class="mt-1 text-2xs text-amalan-red">{{errors.rt}}</div>
                            </div>
                            <div>
                                <label class="text-amalan-black font-bold text-sm">RW</label>
                                <Field placeholder="RW" name="rw" v-model="formValues.rw" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.rw ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnInput="true" :validateOnMount="true"/>
                                <div v-if="errors.rw" class="mt-1 text-2xs text-amalan-red">{{errors.rw}}</div>
                            </div>
                        </div>
                        <div>
                            <label class="text-amalan-black font-bold text-sm">Kode Pos</label>
                            <Field placeholder="Kode Pos" name="postalCode" v-model="formValues.postalCode" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.postalCode || !postalCodeValid ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnInput="true" :validateOnMount="true"/>
                            <div v-if="errors.postalCode" class="mt-1 text-2xs text-amalan-red">{{errors.postalCode}}</div>
                            <div class="mt-1 text-2xs" :class="[ postalCodeValid ? 'text-amalan-green' : 'text-amalan-red' ]">{{ postalCodeValid ? 'Kode Pos valid' : '*Kode Pos tidak valid' }}</div>
                        </div>
                        <div>
                            <label class="text-amalan-black font-bold text-sm">Provinsi</label>
                            <div class="flex relative w-full">
                                <Field name="provinceId" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.provinceId ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.provinceId" @change="getCity">
                                    <option v-for="province in dataProvince" :key="province.key" :value="province.id">{{ province.name }}</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="errors.provinceId" class="mt-1 text-2xs text-amalan-red">{{errors.provinceId}}</div>
                        </div>
                        <div>
                            <label class="text-amalan-black font-bold text-sm">Kota/Kabupaten</label>
                            <div class="flex relative w-full">
                                <Field name="cityId" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.cityId ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.cityId" @change="getDistrict">
                                    <option v-for="city in dataCity" :key="city.key" :value="city.id">{{ city.name }}</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="errors.cityId" class="mt-1 text-2xs text-amalan-red">{{errors.cityId}}</div>
                        </div>
                        <div>
                            <label class="text-amalan-black font-bold text-sm">Kecamatan</label>
                            <div class="flex relative w-full">
                                <Field name="districtId" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.districtId ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.districtId" @change="getVillage">
                                    <option v-for="district in dataDistrict" :key="district.key" :value="district.id">{{ district.name }}</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="errors.districtId" class="mt-1 text-2xs text-amalan-red">{{errors.districtId}}</div>
                        </div>
                        <div>
                            <label class="text-amalan-black font-bold text-sm">Kelurahan</label>
                            <div class="flex relative w-full">
                                <Field name="villageId" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.villageId ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.villageId">
                                    <option v-for="village in dataVillage" :key="village.key" :value="village.id">{{ village.name }}</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="errors.villageId" class="mt-1 text-2xs text-amalan-red">{{errors.villageId}}</div>
                        </div>
                        <div>
                            <label class="text-amalan-black font-bold text-sm">Pendapatan</label>
                            <div class="flex flex-col relative w-full">
                                <CurrencyInput 
                                v-model="formValues.monthly_income" 
                                :propsClass="errors.monthly_income ? 'border-amalan-red' : 'border-amalan-gray-4'"
                                :options="{
                                    currency: 'IDR',
                                    currencyDisplay: 'narrowSymbol',
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    hideNegligibleDecimalDigitsOnFocus: false,
                                    autoDecimalDigits: false,
                                    autoSign: false,
                                    useGrouping: true,
                                    accountingSign: true
                                }" />
                                <Field name="monthly_income" type="text" :validateOnMount="true" :validateOnInput="true" class="hidden" v-model="formValues.monthly_income" />
                                <div v-if="errors.monthly_income" class="mt-1 text-2xs text-amalan-red">*{{errors.monthly_income}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-16">
                        <button type="submit" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ meta.valid && postalCodeValid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!meta.valid || !postalCodeValid" @submit="handleSubmit">Simpan</button>
                    </div>
                </template>

                <template v-if="currentStep === 2">
                    <div class="w-full py-8 flex justify-between items-center">
                        <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                            <span class="text-sm text-amalan-blue-1 font-bold">1</span>
                        </div>
                        <p class="flex-none text-amalan-blue-1 text-sm font-bold mx-1">Data Pengguna & Pekerjaan</p>
                        <div class="bg-amalan-blue-1 h-px flex-grow"></div>
                        <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-gray-4 mx-1">
                            <span class="text-sm font-bold text-amalan-gray-2">2</span>
                        </div>
                        <div class="bg-amalan-blue-1 h-px flex-none w-3"></div>
                        <div class="w-6 h-6 rounded-full bg-amalan-gray-4 flex justify-center items-center flex-none ml-1">
                            <span class="text-sm text-amalan-gray-2 font-bold">3</span>
                        </div>
                    </div>
                    <div class="w-full">
                        <p class="text-center text-amalan-black text-xs">Pastikan data Anda sesuai dengan<br>data yang Anda miliki.</p>
                        <div class="w-full mt-7 px-4 py-4 border box-border rounded-lg" :class="[ isFirstFormValid ? 'bg-amalan-white border-amalan-gray-4' : 'bg-amalan-red-light border-amalan-red' ]">
                            <div class="w-full flex justify-between items-center">
                                <p class="text-amalan-blue-1 text-base font-bold">Informasi Tambahan</p>
                                <div @click="goToFirstForm" class="w-6 h-6 rounded-lg shadow-amalan-md border mr-1 flex justify-center items-center cursor-pointer" :class="[ isFirstFormValid ? 'bg-amalan-white border-amalan-blue-1 hover:border-amalan-blue-2 hover:bg-amalan-gray-5' : 'bg-amalan-blue-1 border-amalan-blue-1 hover:border-amalan-blue-2 hover:bg-amalan-blue-2']">
                                    <img v-if="isFirstFormValid" src="@/assets/img/pencil-primary-icon.svg" class="ml-px">
                                    <img v-else src="@/assets/img/pencil-secondary-icon.svg" class="ml-px">
                                </div>
                            </div>
                            <div class="border-t border-amalan-blue-1 mt-3.5 pt-3.5 grid grid-cols-10 gap-y-2.5 text-amalan-black text-xs">
                                <div class="font-bold col-span-4">Nomor NPWP</div>
                                <div class="col-span-6">: {{ formValues.npwp || '-' }}</div>
                                <div class="font-bold col-span-4">Nama Ibu Kandung</div>
                                <div class="col-span-6">: {{ formValues.mother_maiden_name || '-' }}</div>
                            </div>
                        </div>
                        <p v-if="!isFirstFormValid" class="text-amalan-red text-2xs mt-1">Lengkapi data yang dibutuhkan pada Informasi Tambahan Anda</p>
                        <div class="w-full mt-3.5 px-4 py-4 border box-border rounded-lg" :class="[ isSecondFormValid ? 'bg-amalan-white border-amalan-gray-4' : 'bg-amalan-red-light border-amalan-red' ]">
                            <div class="w-full flex justify-between items-center">
                                <p class="text-amalan-blue-1 text-base font-bold">Data Pekerjaan</p>
                                <div @click="goToSecondForm" class="w-6 h-6 rounded-lg shadow-amalan-md border mr-1 flex justify-center items-center cursor-pointer" :class="[ isSecondFormValid ? 'bg-amalan-white border-amalan-blue-1 hover:border-amalan-blue-2 hover:bg-amalan-gray-5' : 'bg-amalan-blue-1 border-amalan-blue-1 hover:border-amalan-blue-2 hover:bg-amalan-blue-2']">
                                    <img v-if="isSecondFormValid" src="@/assets/img/pencil-primary-icon.svg" class="ml-px">
                                    <img v-else src="@/assets/img/pencil-secondary-icon.svg" class="ml-px">
                                </div>
                            </div>
                            <div class="border-t border-amalan-blue-1 mt-3.5 pt-3.5 grid grid-cols-10 gap-y-2.5 text-amalan-black text-xs">
                                <div class="font-bold col-span-4">Pekerjaan</div>
                                <div class="col-span-6">: {{ occupationName }}</div>
                                <div class="font-bold col-span-4">Tempat Bekerja</div>
                                <div class="col-span-6">: {{ formValues.company_name || '' }}</div>
                                <div class="font-bold col-span-4">Alamat Instansi</div>
                                <div class="col-span-6">: {{ formValues.street || '' }}</div>
                                <div class="font-bold col-span-4">RT/RW</div>
                                <div class="col-span-6">: {{ formValues.rt || '' }}{{ formValues.rw ? '/' + formValues.rw : '' }}</div>
                                <div class="font-bold col-span-4">Kelurahan</div>
                                <div class="col-span-6">: {{ villageName }}</div>
                                <div class="font-bold col-span-4">Kecamatan</div>
                                <div class="col-span-6">: {{ districtName }}</div>
                                <div class="font-bold col-span-4">Kota</div>
                                <div class="col-span-6">: {{ cityName }}</div>
                                <div class="font-bold col-span-4">Provinsi</div>
                                <div class="col-span-6">: {{ provinceName }}</div>
                                <div class="font-bold col-span-4">Kode Pos</div>
                                <div class="col-span-6">: {{ formValues.postalCode || '' }}</div>
                                <div class="font-bold col-span-4">Pendapatan</div>
                                <div class="col-span-6">: {{ formValues.monthly_income ? filters.format_number(formValues.monthly_income) : '-' }}</div>
                            </div>
                        </div>
                        <p v-if="!isSecondFormValid" class="text-amalan-red text-2xs mt-1">Lengkapi data yang dibutuhkan pada Data Pekerjaan Anda</p>
                        <p v-if="!postalCodeValid" class="text-amalan-red text-2xs mt-1">*Kode Pos tidak valid</p>
                    </div>
                    <div class="mt-16">
                        <button type="submit" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ isFirstFormValid && isSecondFormValid && postalCodeValid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!isFirstFormValid || !isSecondFormValid || !postalCodeValid">Selanjutnya</button>
                    </div>
                </template>
            </Form>
        </div>
        <loading-overlay v-if="isLoading || getDataLoading" />
    </div>
</template>