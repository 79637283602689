import Viewer from 'viewerjs'

export const BasicImageViewer = (elementId) => {
    const elem = document.getElementById(elementId)
    const viewer = new Viewer(elem, {
        inline: false,
        toolbar: {
            zoomIn: 1,
            zoomOut: 1,
        },
        title: false,
        button: false,
        movable: false,
        tooltip: false,
        rotatable: false,
        scalable: false,
        navbar: false,
        hidden: () => {
            viewer.destroy()
        }
    })

    viewer.show()
}