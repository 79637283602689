import apiMediationService from "@/axios/mediationService"

const state = () => ({
    current_target_pelunasan: {
        isFetched: false,
        isFetching: true,
        data: []
    },
    new_target_pelunasan: {
        isFetched: false,
        isFetching: true,
        data: []
    },
    alasan_pengajuan: {
        isFetching: true,
        data: []
    },
    target_pelunasan_form: {
        canceledOpportunity: null,
        selectedOpportunity: null,
        reason: [],
        optional: null,
        isCompleted: false
    }
})

const getters = {
    getCurrentTargetPelunasan(state) {
        return state.current_target_pelunasan
    },
    getNewTargetPelunasan(state) {
        return state.new_target_pelunasan
    },
    getAlasanPengajuan(state) {
        return state.alasan_pengajuan
    },
    getTargetPelunasanForm(state){
        return state.target_pelunasan_form
    },
    getTargetPelunasanFormReason(state){
        if(!state.target_pelunasan_form.reason) return []
        const reason = [...state.target_pelunasan_form.reason]
        return reason.filter((value) => value !== 'optional')
    }
}

const actions = {
    async setAlasanPengajuan({ commit, state }){
        state.alasan_pengajuan.isFetching = true
        try {
            const result = await apiMediationService.get('api/negotiation-approval/submission')
            commit('setAlasanPengajuan', result.data.data)
        } catch {
            commit('setAlasanPengajuan', [])
        }
    },
    async setCurrentTargetPelunasan({ commit, state }){
        state.current_target_pelunasan.isFetching = true
        state.current_target_pelunasan.data = []
        try {
            const result = await apiMediationService.get('api/negotiation-approval')
            commit('setCurrentTargetPelunasan', result.data.data)
        } catch {
            commit('setCurrentTargetPelunasan', [])
        }
    },
    async setNewTargetPelunasan({ commit, state }){
        state.new_target_pelunasan.isFetching = true
        state.new_target_pelunasan.data = []
        try {
            const result = await apiMediationService.get('api/negotiation-approval/target')
            commit('setNewTargetPelunasan', result.data.data)
        } catch {
            commit('setNewTargetPelunasan', [])
        }
    },
    setTargetPelunasanForm({ commit }, payload){
        commit('setTargetPelunasanForm', payload)
    },
    resetTargetPelunasanForm({ commit }){
        commit('resetTargetPelunasanForm')
    }
}

const mutations = {
    setAlasanPengajuan(state, payload){
        state.alasan_pengajuan.isFetching = false
        state.alasan_pengajuan.data = payload
    },
    setCurrentTargetPelunasan(state, payload){
        state.current_target_pelunasan.isFetching = false
        state.current_target_pelunasan.isFetched = true
        state.current_target_pelunasan.data = payload
    },
    setNewTargetPelunasan(state, payload){
        state.new_target_pelunasan.isFetching = false
        state.new_target_pelunasan.isFetched = true
        state.new_target_pelunasan.data = payload
    },
    setTargetPelunasanForm(state, payload){
        state.target_pelunasan_form.canceledOpportunity = payload.canceledOpportunity ?? null
        state.target_pelunasan_form.selectedOpportunity = payload.selectedOpportunity ?? null
        state.target_pelunasan_form.reason = payload.reason ?? []
        state.target_pelunasan_form.optional = payload.optional ?? null
        state.target_pelunasan_form.isCompleted = true
    },
    resetTargetPelunasanForm(state){
        state.target_pelunasan_form.canceledOpportunity = null
        state.target_pelunasan_form.selectedOpportunity = null
        state.target_pelunasan_form.reason = []
        state.target_pelunasan_form.optional = null,
        state.target_pelunasan_form.isCompleted = false
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
