<script setup>
import { ref, reactive, computed, watch, onMounted, inject } from "vue"
import { useStore } from 'vuex'
import { Form, Field } from "vee-validate"
import * as yup from "yup"
import axios from "@/axios"
import { ChevronLeftIcon, CheckIcon } from '@heroicons/vue/outline'
import { useRoute, useRouter } from 'vue-router'
import useModalPopUp from '@/composables/modalPopUp'
import DoneBanner from '@/components/icon/DoneBanner'

const route = useRoute()
const router = useRouter()
const store = useStore()
const modalPopUp = useModalPopUp()
const filters = inject("Filters")

const dataUser = computed(() => store.getters['user/getUser'])
const idCardUser = computed(() => store.getters['user/getIdCard'])

const currentStep = ref(2)
const formValues = reactive({
    religionId: null,
    educationId: null,
    occupationId: null,
    maritalId: null,
    personalDataAgreement: null
})
const isLoading = ref(false)
const dataOccupation = ref([])
const dataReligion = ref([])
const dataEducation = ref([])
const dataMarital = ref([])
const isFirstFormValid = ref(false)
const isSecondFormValid = ref(true)
const autoRedirectTimeout = ref(null)

const updateForm = computed(() => schemas[currentStep.value])

const schemas = [
    yup.object({
        religionId: yup.string().nullable().required('Silahkan pilih agama'),
        educationId: yup.string().nullable().required('Silahkan pilih pendidikan'),
        occupationId: yup.string().nullable().required('Silahkan pilih pekerjaan'),
        maritalId: yup.string().nullable().required('Silahkan pilih status perkawinan')
    }),
    yup.object({}),
    yup.object({
        personalDataAgreement: yup.bool().required('centang disini').equals([true], 'centang disini').default(false),
    })
]

axios.get('/educations')
.then((resp) => {
    dataEducation.value = resp.data.data
    formValues.educationId = dataUser.value.education.id
})
.catch(() => modalPopUp.swalErrorReload.fire().then(() => window.location.reload()))

axios.get('/religions')
.then((resp) => {
    dataReligion.value = resp.data.data
    formValues.religionId = dataUser.value.religion.id
})
.catch(() => modalPopUp.swalErrorReload.fire().then(() => window.location.reload()))

setTimeout(() => {
    axios.get('/occupations')
    .then((resp) => {
        dataOccupation.value = resp.data.data
        formValues.occupationId = dataUser.value.occupation.id
    })
    .catch(() => modalPopUp.swalErrorReload.fire().then(() => window.location.reload()))

    axios.get('/marital-statuses')
    .then((resp) => {
        dataMarital.value = resp.data.data
        formValues.maritalId = dataUser.value.marital.id
    })
    .catch(() => modalPopUp.swalErrorReload.fire().then(() => window.location.reload()))
}, 2000)

const personalDataAgreementChecked = () => formValues.personalDataAgreement = !formValues.personalDataAgreement

const nextStep = (values) => {
    if (currentStep.value !== 2) {
        Object.assign(formValues, values)
        return currentStep.value = 2
    }

    const data = {
        title: dataUser.value.title,
        nik: idCardUser.value ? idCardUser.value.number : null,
        name: (dataUser.value.first_name || '') + ' ' + (dataUser.value.last_name || ''),
        email: dataUser.value.email,
        birthdate: dataUser.value.birthdate,
        birthplace: dataUser.value.birthplace,
        gender: dataUser.value.gender,
        street: dataUser.value.address.street,
        rt_rw: dataUser.value.address.rt + '_' + dataUser.value.address.rw,
        provinceId: dataUser.value.address.province.id,
        cityId: dataUser.value.address.city.id,
        districtId: dataUser.value.address.district.id,
        villageId: dataUser.value.address.village.id,
        postalCode: dataUser.value.address.postal_code,
        productStage: null,
        ...formValues
    }

    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs text-amalan-black mt-2">Anda yakin data yang diinput sudah benar?<br>Jika sudah, silahkan dikonfirmasi untuk<br>melanjutkan ke proses berikutnya!</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Konfirmasi',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if (result.isConfirmed){
            isLoading.value = true
            axios.put('/customer/update', data)
            .then(() => {
                store.dispatch('user/storeUser').then(() => {
                    setTimeout(() => {
                        isLoading.value = false
                        currentStep.value = 3
                    }, 1000)
                })
            }).catch(() => {
                isLoading.value = false
                modalPopUp.swalError.fire({
                    html: '<p class="text-center text-xs text-amalan-black mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                }).then((result) => {
                    if (!result.isConfirmed) return
                    return window.location = 'https://wa.me/622150933150?text=Mohon bantuan saya gagal terus dalam proses KYC - lengkapi data di web.amalan.com'
                })
            })
        }
    })
}

watch(formValues, () => revalidateForm(), { deep: true })

const revalidateForm = async () => isFirstFormValid.value = await schemas[0].isValid(formValues)

const goToFirstForm = () => currentStep.value = 0
const goToSecondForm = () => currentStep.value = 1

const backNavigation = () => {
    if (currentStep.value >= 2) return router.push({ name: 'Home'})
    return currentStep.value = 2
}

const occupationName = computed(() => {
    const result = dataOccupation.value.find(({ id }) => id === formValues.occupationId)
    if(result) return result.name
    return ''
})

const religionName = computed(() => {
    const result = dataReligion.value.find(({ id }) => id === formValues.religionId)
    if(result) return result.name
    return ''
})

const educationName = computed(() => {
    const result = dataEducation.value.find(({ id }) => id === formValues.educationId)
    if(result) return result.name
    return ''
})

const maritalName = computed(() => {
    const result = dataMarital.value.find(({ id }) => id === formValues.maritalId)
    if(result) return result.name
    return ''
})

watch(() => currentStep.value, () => {
    if(currentStep.value !== 3) return
    return autoRedirectTimeout.value = setTimeout(() => router.push({ name: 'rdp opening - document poa' }), 3000)
})

watch(() => idCardUser.value, () => formValues.nik = idCardUser.value ? idCardUser.value.number : null)

const redirectToPoa = () => {
    if(autoRedirectTimeout.value) clearTimeout(autoRedirectTimeout.value)
    router.push({ name: 'rdp opening - document poa' })
}

onMounted(() => {
    store.dispatch('user/requestIdCard')

    formValues.religionId = dataUser.value.religion.id || null
    formValues.educationId = dataUser.value.education.id || null
    formValues.maritalId = dataUser.value.marital.id || null
    formValues.occupationId = dataUser.value.occupation.id || null
    formValues.personalDataAgreement = false
})
</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <div v-if="currentStep === 3" class="flex items-center justify-center w-6 h-8"></div>
                    <button v-else @click="backNavigation" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div v-if="currentStep === 3" class="w-full min-h-screen px-6 -mt-14 flex flex-col justify-center items-center">
            <DoneBanner />
            <p class="text-center text-xs text-amalan-black mt-7">Verifikasi data Anda telah sukses dilakukan.<br>Selanjutnya, silahkan lakukan pembukaan<br>Rekening Dana Pelunasan (RDP) Anda!</p>
            <button @click="redirectToPoa" class="w-full flex items-center justify-center py-3 px-2 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg mt-7">
                <span class="text-amalan-white text-sm font-bold">
                    Lanjutkan Pembukaan RDP
                </span>
            </button>
        </div>
        <div v-else class="bg-amalan-white px-6 pb-16 text-amalan-black min-h-screen">
            <Form @submit="nextStep" :validation-schema="updateForm" v-slot="{ handleSubmit, errors, meta }">
                <template v-if="currentStep === 0">
                    <div class="w-full pt-6 grid grid-col-1 gap-y-3.5">
                        <div class="w-full">
                            <label class="text-amalan-black font-bold text-sm">Gelar</label>
                            <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ dataUser.title ? dataUser.title === 'Bpk' ? 'Bapak' : 'Ibu' : '-' }}</div>
                        </div>
                        <div class="w-full">
                            <label class="text-amalan-black font-bold text-sm">NIK</label>
                            <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ idCardUser ? idCardUser.number : '-' }}</div>
                        </div>
                        <div class="w-full">
                            <label class="text-amalan-black font-bold text-sm">Nama Lengkap</label>
                            <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ (dataUser.first_name || '') + ' ' + (dataUser.last_name || '') }}</div>
                        </div>
                        <div class="w-full">
                            <label class="text-amalan-black font-bold text-sm">Email</label>
                            <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ dataUser.email || '-' }}</div>
                        </div>
                        <div class="w-full grid grid-cols-2 gap-x-4">
                            <div>
                                <label class="text-amalan-black font-bold text-sm">Tempat Lahir</label>
                                <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ dataUser.birthplace || '-' }}</div>
                            </div>
                            <div>
                                <label class="text-amalan-black font-bold text-sm">Tanggal Lahir</label>
                                <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ dataUser.birthdate ? filters.formatDateOnlyDate(dataUser.birthdate) : '-' }}</div>
                            </div>
                        </div>
                        <div class="grid grid-cols-2 gap-x-3.5">
                            <div>
                                <label class="text-amalan-black font-bold text-sm">Jenis Kelamin</label>
                                <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ dataUser.gender == 'M' ? 'Laki-laki' : 'Perempuan' }}</div>
                            </div>
                            <div>
                                <label class="text-amalan-black font-bold text-sm">Agama</label>
                                <div class="flex relative w-full">
                                    <Field name="religionId" as="select" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none cursor-pointer" :class="{ 'border-amalan-red': errors.religionId }" :validateOnMount="true" v-model="formValues.religionId">
                                        <option v-for="religion in dataReligion" :key="religion.key" :value="religion.id">{{ religion.name }}</option>
                                    </Field>
                                    <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                        <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div v-if="errors.religionId" class="mt-1 text-2xs text-amalan-red">{{errors.religionId}}</div>
                            </div>
                        </div>
                        <div class="grid grid-cols-2 gap-x-3.5">
                            <div>
                                <label class="text-amalan-black font-bold text-sm">Pendidikan</label>
                                <div class="flex relative w-full">
                                    <Field name="educationId" as="select" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none cursor-pointer" :class="{ 'border-amalan-red': errors.educationId }" :validateOnMount="true" v-model="formValues.educationId">
                                        <option v-for="education in dataEducation" :key="education.key" :value="education.id">{{ education.name }}</option>
                                    </Field>
                                    <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                        <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div v-if="errors.educationId" class="mt-1 text-2xs text-amalan-red">{{errors.educationId}}</div>
                            </div>
                            <div>
                                <label class="text-amalan-black font-bold text-sm">Status Perkawinan</label>
                                <div class="flex relative w-full">
                                    <Field name="maritalId" as="select" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none cursor-pointer" :class="{ 'border-amalan-red': errors.maritalId }" :validateOnMount="true" v-model="formValues.maritalId">
                                        <option v-for="marital in dataMarital" :key="marital.key" :value="marital.id">{{ marital.name }}</option>
                                    </Field>
                                    <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                        <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div v-if="errors.maritalId" class="mt-1 text-2xs text-amalan-red">{{errors.maritalId}}</div>
                            </div>
                        </div>
                        <div class="w-full">
                            <label class="text-amalan-black font-bold text-sm">Pekerjaan</label>
                            <div class="flex relative w-full">
                                <Field name="occupationId" as="select" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none cursor-pointer" :class="{ 'border-amalan-red': errors.occupationId }" :validateOnMount="true" v-model="formValues.occupationId">
                                    <option v-for="occupation in dataOccupation" :key="occupation.key" :value="occupation.id">{{ occupation.name }}</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="errors.occupationId" class="mt-1 text-2xs text-amalan-red">{{errors.occupationId}}</div>
                        </div>
                    </div>
                    <div class="mt-16">
                        <button type="submit" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!meta.valid" @submit="handleSubmit">Simpan</button>
                    </div>
                </template>

                <template v-if="currentStep === 1">
                    <div class="w-full pt-6 grid grid-col-1 gap-y-3.5">
                        <div class="w-full">
                            <label class="text-amalan-black font-bold text-sm">Alamat</label>
                            <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ dataUser.address.street || '-' }}</div>
                        </div>
                        <div class="grid grid-cols-2 gap-x-3.5">
                            <div>
                                <label class="text-amalan-black font-bold text-sm">RT</label>
                                <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ dataUser.address.rt || '-' }}</div>
                            </div>
                            <div>
                                <label class="text-amalan-black font-bold text-sm">RW</label>
                                <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ dataUser.address.rw || '-' }}</div>
                            </div>
                        </div>
                        <div class="w-full">
                            <label class="text-amalan-black font-bold text-sm">Kode Pos</label>
                            <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ dataUser.address.postal_code || '-' }}</div>
                        </div>
                        <div class="w-full">
                            <label class="text-amalan-black font-bold text-sm">Provinsi</label>
                            <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ dataUser.address.province.name || '-' }}</div>
                        </div>
                        <div class="w-full">
                            <label class="text-amalan-black font-bold text-sm">Kota/Kabupaten</label>
                            <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ dataUser.address.city.name || '-' }}</div>
                        </div>
                        <div class="w-full">
                            <label class="text-amalan-black font-bold text-sm">Kecamatan</label>
                            <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ dataUser.address.district.name || '-' }}</div>
                        </div>
                        <div class="w-full">
                            <label class="text-amalan-black font-bold text-sm">Kabupaten</label>
                            <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ dataUser.address.village.name || '-' }}</div>
                        </div>
                    </div>
                    <div class="mt-16">
                        <button type="button" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3 text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md" @click="currentStep = 2">Simpan</button>
                    </div>
                </template>

                <template v-if="currentStep === 2">
                    <div class="w-full py-8 flex justify-between items-center">
                        <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                            <span class="text-sm text-amalan-blue-1 font-bold">1</span>
                        </div>
                        <div class="bg-amalan-blue-1 h-px flex-none w-3 mx-1"></div>
                        <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400">
                            <span class="text-sm font-bold text-amalan-blue-1">2</span>
                        </div>
                        <div class="bg-amalan-blue-1 h-px flex-grow mx-1"></div>
                        <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                            <span class="text-sm text-amalan-blue-1 font-bold">3</span>
                        </div>
                        <p class="flex-none text-amalan-blue-1 text-sm font-bold ml-1">Cek Data e-KTP</p>
                    </div>
                    <div class="w-full">
                        <p class="text-center text-amalan-black text-xs">Pastikan data Anda sesuai dengan data e-KTP,<br>silahkan ubah jika terdapat data yang keliru.</p>
                        <div class="w-full mt-7 px-4 py-4 border box-border rounded-lg" :class="[ isFirstFormValid ? 'bg-amalan-white border-amalan-gray-4' : 'bg-amalan-red-light border-amalan-red' ]">
                            <div class="w-full flex justify-between items-center">
                                <p class="text-amalan-blue-1 text-base font-bold">Data Diri</p>
                                <div @click="goToFirstForm" class="w-6 h-6 rounded-lg shadow-amalan-md border mr-1 flex justify-center items-center cursor-pointer" :class="[ isFirstFormValid ? 'bg-amalan-white border-amalan-blue-1 hover:border-amalan-blue-2 hover:bg-amalan-gray-5' : 'bg-amalan-blue-1 border-amalan-blue-1 hover:border-amalan-blue-2 hover:bg-amalan-blue-2']">
                                    <img v-if="isFirstFormValid" src="@/assets/img/pencil-primary-icon.svg" class="ml-px">
                                    <img v-else src="@/assets/img/pencil-secondary-icon.svg" class="ml-px">
                                </div>
                            </div>
                            <div class="border-t border-amalan-blue-1 mt-3.5 pt-3.5 grid grid-cols-10 gap-y-2.5 text-amalan-black text-xs">
                                <div class="font-bold col-span-4">Nama</div>
                                <div class="col-span-6">: {{ dataUser.title ? dataUser.title === 'Bpk' ? 'Bapak' : 'Ibu' : '' }} {{ dataUser.name || '' }}</div>
                                <div class="font-bold col-span-4">NIK</div>
                                <div class="col-span-6">: {{ idCardUser ? idCardUser.number : '' }}</div>
                                <div class="font-bold col-span-4">Email</div>
                                <div class="col-span-6">: {{ dataUser.email || '' }}</div>
                                <div class="font-bold col-span-4">Jenis Kelamin</div>
                                <div class="col-span-6">: {{ dataUser.gender == 'M' ? 'Laki-laki' : 'Perempuan' }}</div>
                                <div class="font-bold col-span-4">Tempat/Tgl Lahir</div>
                                <div class="col-span-6">: {{ dataUser.birthplace || '' }}{{ dataUser.birthdate ? '/' + filters.formatDateOnlyDate(dataUser.birthdate) : '' }}</div>
                                <div class="font-bold col-span-4">Agama</div>
                                <div class="col-span-6">: {{ religionName }}</div>
                                <div class="font-bold col-span-4">Status</div>
                                <div class="col-span-6">: {{ maritalName }}</div>
                                <div class="font-bold col-span-4">Pekerjaan</div>
                                <div class="col-span-6">: {{ occupationName }}</div>
                                <div class="font-bold col-span-4">Pendidikan</div>
                                <div class="col-span-6">: {{ educationName }}</div>
                            </div>
                        </div>
                        <p v-if="!isFirstFormValid" class="text-amalan-red text-2xs mt-1">Lengkapi data yang dibutuhkan pada Data Diri Anda</p>
                        <div class="w-full mt-3.5 px-4 py-4 border box-border rounded-lg" :class="[ isSecondFormValid ? 'bg-amalan-white border-amalan-gray-4' : 'bg-amalan-red-light border-amalan-red' ]">
                            <div class="w-full flex justify-between items-center">
                                <p class="text-amalan-blue-1 text-base font-bold">Alamat di e-KTP</p>
                                <div @click="goToSecondForm" class="w-6 h-6 rounded-lg shadow-amalan-md border mr-1 flex justify-center items-center cursor-pointer" :class="[ isSecondFormValid ? 'bg-amalan-white border-amalan-blue-1 hover:border-amalan-blue-2 hover:bg-amalan-gray-5' : 'bg-amalan-blue-1 border-amalan-blue-1 hover:border-amalan-blue-2 hover:bg-amalan-blue-2']">
                                    <img v-if="isSecondFormValid" src="@/assets/img/pencil-primary-icon.svg" class="ml-px">
                                    <img v-else src="@/assets/img/pencil-secondary-icon.svg" class="ml-px">
                                </div>
                            </div>
                            <div class="border-t border-amalan-blue-1 mt-3.5 pt-3.5 grid grid-cols-10 gap-y-2.5 text-amalan-black text-xs">
                                <div class="font-bold col-span-4">Alamat</div>
                                <div class="col-span-6">: {{ dataUser.address.street || '' }}</div>
                                <div class="font-bold col-span-4">RT/RW</div>
                                <div class="col-span-6">: {{ dataUser.address.rt || '' }}{{ dataUser.address.rw ? '/' + dataUser.address.rw : '' }}</div>
                                <div class="font-bold col-span-4">Kelurahan</div>
                                <div class="col-span-6">: {{ dataUser.address.village.name || '' }}</div>
                                <div class="font-bold col-span-4">Kecamatan</div>
                                <div class="col-span-6">: {{ dataUser.address.district.name || '' }}</div>
                                <div class="font-bold col-span-4">Kota</div>
                                <div class="col-span-6">: {{ dataUser.address.city.name || '' }}</div>
                                <div class="font-bold col-span-4">Provinsi</div>
                                <div class="col-span-6">: {{ dataUser.address.province.name || '' }}</div>
                                <div class="font-bold col-span-4">Kode Pos</div>
                                <div class="col-span-6">: {{ dataUser.address.postal_code || '' }}</div>
                            </div>
                        </div>
                        <p v-if="!isSecondFormValid" class="text-amalan-red text-2xs mt-1">Lengkapi data yang dibutuhkan pada Alamat Anda</p>
                        <div class="w-full mt-3.5">
                            <span class="text-4xs text-amalan-red pt-0.5 pb-1 px-1.5 rounded-amalan-4xs bg-amalan-yellow-300 triangle" :class="[!formValues.personalDataAgreement ? 'visible' : 'invisible']">*centang disini</span>
                            <div class="mt-4 flex justify-between items-start">
                                <label class="text-xs inline-flex" for="personalDataAgreement">
                                    <label class="h-4 w-4 border mr-2 cursor-pointer flex justify-center items-center" :class="[ !formValues.personalDataAgreement ? 'border-amalan-gray-4 bg-amalan-gray-4': 'bg-amalan-yellow-400 border-amalan-yellow-400' ]"  @click="personalDataAgreementChecked">
                                        <CheckIcon v-if="formValues.personalDataAgreement" class="w-4 text-amalan-blue-1" />
                                    </label>
                                    <Field name="personalDataAgreement" v-model="formValues.personalDataAgreement" type="checkbox" class="hidden" :validateOnMount="true" :validateOnInput="true" />
                                </label>
                                <span class="text-xs cursor-pointer select-none" @click="personalDataAgreementChecked">Pengguna dengan ini menyetujui untuk memberikan data pengguna kepada amalan dan amalan akan menjamin kerahasiaan serta keamanan data tersebut.</span>
                            </div>
                        </div>
                    </div>
                    <div class="mt-16">
                        <button type="submit" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ formValues.personalDataAgreement && isFirstFormValid && isSecondFormValid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!formValues.personalDataAgreement || !isFirstFormValid || !isSecondFormValid">Selanjutnya</button>
                    </div>
                </template>
            </Form>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>

<style scoped>
.triangle {
    position: relative;
}

.triangle::after{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: -9px;
    left: 7%;
    box-sizing: border-box;

    border-style: solid;
    border-width: 5px;
    border-color: transparent transparent #FBE855 #FBE855;
    
    transform-origin: 0 0;
    transform: rotate(-45deg);
}
</style>