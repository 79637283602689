<template>
    <div class="py-1 pb-10 px-4">
      <div class="container mx-auto">
          <div class="w-full sm:w-1/2 mx-auto grid grid-cols-1 pb-4">
              <div class="flex justify-center py-2 font-bold text-lg">
                Riyawat
              </div>
              <div class="justify-center py-2 text-sm bg-blue-200 rounded-xl grid grid-cols-2 text-blue-900">
                <div class="mx-2 py-1 flex items-center
                justify-center rounded-md cursor-pointer text-sm" :class="{'text-white font-bold bg-blue-900' : !isDone }"
                @click="onSwitch(1)">
                  Pending
                </div>
                <div class="mx-2 py-1 flex items-center
                justify-center rounded-md cursor-pointer text-sm" :class="{'text-white font-bold bg-blue-900' : isDone }"
                @click="onSwitch(2)">
                  Selesai
                </div>
              </div>
          </div>

          <!-- start sign up history -->
          <div class="flex flex-wrap -mx-1 lg:-mx-4" v-if="isSignUpExist">
              <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                <div class="grid grid-cols-1 rounded-lg shadow-lg px-4 py-2">
                    <header>
                      <div class="flex items-center justify-between leading-tight p-1 mt-4">

                        <!-- start stage -->
                          <span class="flex items-center justify-center px-4 py-1 text-xs text-md leading-none rounded-full capitalize"
                          :class="{'bg-green-500 text-white' : signUpStatus == 'done',
                          'bg-yellow-300 text-white' : signUpStatus == 'waiting for signature' || signUpStatus == 'waiting for payment'}">
                              <p v-if="signUpStatus == 'waiting for signature'">
                                  Menunggu Tanda Tangan
                              </p>
                              <p v-if="signUpStatus == 'waiting for payment'">
                                  Menunggu Pembayaran
                              </p>
                              <p v-if="signUpStatus == 'done'">
                                  Selesai
                              </p>
                          </span>
                        <!-- end stage -->
                        <h1 class="text-xs">
                            {{ $filters.formatDateCustom(hellosignData.CreatedDate,'DD/MM/YYYY') }}
                        </h1>
                      </div>
                      <!-- -- -->
                      <div class="flex items-center justify-between leading-tight p-1">
                        <span class="flex justify-start py-1 text-xs text-md leading-none font-bold">
                          # -
                        </span>
                        <h1 class="text-xs">
                            {{ $filters.formatDateCustom(hellosignData.CreatedDate,'HH:mm') }}
                        </h1>
                      </div>
                    </header>
                    <hr class="bg-black border-black my-2">
                    <div>
                      <div class="text-blue-900">
                        <p class="text-xs">Produk</p>
                        <h1 class="text-sm font-bold">amalan Sign-Up</h1>
                      </div>
                      <div class="flex py-2 items-center justify-between gap-2">
                          <a class="bg-blue-900 hover:bg-blue-800 justify-center flex
                          text-white text-sm font-bold mt-4 mb-2 w-full rounded-md py-2"
                          :href="signUp.url_contract" target='_blank'
                          v-on:click="onClickSignNow()"
                          v-if="signUpStatus == 'waiting for signature' && !isCheckSignUp">
                              Tanda Tangan Sekarang
                          </a>
                          <a class="bg-blue-900 hover:bg-blue-800 justify-center flex
                          text-white text-sm font-bold mt-4 mb-2 w-full rounded-md py-2"
                          :href="signUp.invoiceUrl" target='_blank'
                          v-on:click="onClickSignUpPayNow()" v-if="signUpStatus == 'waiting for payment' && !isCheckSignUp">
                              Bayar Sekarang
                          </a>
                          <!-- <button class="bg-blue-900 hover:bg-blue-800
                          text-white text-sm font-bold mt-4 mb-2 w-full rounded-md py-2"
                          v-on:click="onClickDetailSignUp()" v-if="signUpStatus == 'done'">
                              Lihat
                          </button> -->
                          <button id="btnCheckInvoiceSignUp" class="bg-green-400 hover:bg-green-300
                          text-white text-sm font-bold mt-4 mb-2 w-full rounded-md py-2"
                          v-on:click="onClickCheck()" v-if="isCheckSignUp">
                              Periksa
                          </button>
                      </div>
                    </div>
                </div>
              </div>
          </div>
          <!-- start sign up history -->
          
          <div class="flex flex-wrap -mx-1 lg:-mx-4">
            <!-- start card -->
            <template v-for="(service , index) in services" :key="index">
                <template v-if="activateStage.includes(service.service_stage.name)">
                  <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                    <div class="grid grid-cols-1 rounded-lg shadow-lg px-4 py-2">
                        <header>
                          <div class="flex items-center justify-between leading-tight p-1 mt-4">

                            <!-- start stage -->
                              <span class="flex items-center justify-center px-4 py-1 text-xs text-md leading-none rounded-full capitalize"
                              :class="{'bg-green-500 text-white' : service.service_stage.name == 'CC_done',
                              'bg-blue-500 text-white' : service.service_stage.name == 'CC_ordered' || service.service_stage.name == 'ordered'
                              || service.service_stage.name == 'CC_waiting for service' || service.service_stage.name == 'CC_waiting for payment',
                              'bg-red-500 text-white' : service.service_stage.name == 'CC_cancel' || service.service_stage.name == 'CC_refund'
                              || service.service_stage.name == 'CC_expired'}">
                                {{ converStageName(service.service_stage.name) }}
                              </span>
                            <!-- end stage -->
                            <h1 class="text-xs">
                                {{ $filters.formatDateCustom('2022-02-16T08:38:57.000000Z','DD/MM/YYYY') }}
                            </h1>
                          </div>
                          <!-- -- -->
                          <div class="flex items-center justify-between leading-tight p-1">
                            <span class="flex justify-start py-1 text-xs text-md leading-none font-bold">
                              # {{ service.id }}
                            </span>
                            <h1 class="text-xs">
                                {{ $filters.formatDateCustom(service.created_at,'HH:mm') }}
                            </h1>
                          </div>
                        </header>
                        <hr class="bg-black border-black my-2">
                        <div>
                          <div class="text-blue-900">
                            <p class="text-xs">Produk</p>
                            <h1 class="text-sm font-bold">{{ service.service_catalog.name }}</h1>
                          </div>
                          <div class="flex py-2 items-center justify-between gap-2">
                              <button class="bg-white border-blue-900 border-2 text-blue-900 font-bold mt-4 mb-2
                              w-full rounded-md h-8 mx-auto text-sm"
                                :class="{'hidden' : isLoadingData && detailId == service.id}"
                                v-on:click="onClickDetail(service.id)"
                                v-if="service.service_stage.name == 'CC_done'">
                                Unduh Report   
                              </button>
                              <button class="bg-blue-900 hover:bg-blue-800 text-white text-sm font-bold mt-4 mb-2
                              w-full rounded-md"
                                :class="{'hidden' : isLoadingData && detailId == service.id}"
                                v-on:click="onClickDetail(service.id)"
                                v-if="!isDetail || detailId != service.id">
                                    <ChevronDownIcon class="h-8 mx-auto"></ChevronDownIcon>   
                              </button>
                              <button class="bg-blue-900 hover:bg-blue-800 text-white text-sm font-bold mt-4 mb-2
                              w-full rounded-md"
                                :class="{'hidden' : isLoadingData && detailId == service.id}"
                                v-on:click="onClickCloseDetail()"
                                v-if="isDetail && detailId == service.id">
                                  <ChevronUpIcon class="h-8 mx-auto"></ChevronUpIcon>
                              </button>
                          </div>
                          <div class="flex items-center justify-center" v-if="isLoadingData && detailId == service.id">
                              <div class="w-10 h-10 border-b-2 border-gray-900 rounded-full animate-spin"></div>
                          </div>
                        </div>
                        <!-- start detail service data -->
                        <div v-if="isDetail && detailId == service.id" class="text-blue-900">
                            <!-- start invoice data -->
                            <div v-for="(detail, detailIndex) in detailService.invoice_detail"
                                :key="detailIndex">
                              <div class="flex items-center justify-center text-sm font-bold">
                                Invoice
                              </div>
                              <div class="flex py-2 items-center justify-between">
                                <div>
                                  <p class="text-xs flex items-center">
                                    Reference
                                  </p>
                                  <div class="font-bold text-sm">ID {{ detail.invoice.invoice_reference }} </div>
                                </div>
                                <div>
                                  <p class="text-xs flex items-center">
                                    Status
                                  </p>
                                  <div class="text-sm font-bold capitalize">{{ detail.invoice.invoice_stage.name }}</div>
                                </div>           
                              </div>
                              <div class="flow-root leading-none pr-2" 
                              v-if="detail.invoice.invoice_stage.name == 'issued' && checkInvoice != true">  
                                <a class="float-right text-sm bg-green-500 hover:bg-green-700 text-white font-bold py-2 my-2 px-4 rounded"
                                  :href="additionalDataService.cc_xendit[detailIndex]['invoice_url']"
                                  target='_blank' v-on:click="onClickPayNow()">
                                    Bayar
                                </a>
                              </div>     
                            </div>
                            <div class="leading-none p-2 hidden" v-if="enableGenerateInvoice">  
                              <button class="float-right bg-yellow-300 hover:bg-yellow-700 text-white text-sm font-bold py-2 px-4 rounded"
                              v-on:click="createInvoice(service.id)">
                                  Buat Invoice
                              </button>
                            </div>
                            <div class="flow-root leading-none pr-2" v-if="checkInvoice == true">
                              <button id="btnCheckInvoice" class="float-right bg-blue-500 hover:bg-blue-700 text-white
                              font-bold my-2 py-2 px-4 rounded text-sm"
                              v-on:click="onClickDetail(service.id)">
                                  Cek Invoice
                              </button>
                            </div>        
                            <!-- end invoice data -->

                            <!-- start promo data  -->
                            <div class="flex justify-between mb-2" v-if="service.promo_id">
                              <div class="w-1/2">
                                <p class="text-xs flex items-center">
                                  Promo
                                </p>
                                <div class="font-bold text-sm">{{ detailService.promo.name }} ({{ detailService.promo.discount_amount }}%) </div>
                              </div>
                            </div>                
                            <!-- end promo data  -->

                            <div class="flex justify-between mb-2">
                              <div>
                                <p class="text-xs flex items-center">
                                  Harga
                                </p>
                                <div v-if="service.promo_id">
                                  <div class="font-bold text-sm" v-if="service.promo_id">
                                    <span class="text-gray-500 line-through">
                                      {{ $filters.format_number(service.service_catalog.base_price) }}
                                    </span>
                                      {{ $filters.format_number(service.price) }}
                                  </div>
                                </div>
                                <div v-if="!service.promo_id">
                                  <div class="font-bold text-sm">{{ $filters.format_number(service.price) }}</div>
                                </div>
                              </div>
                            </div> 

                            <!-- start consultation detail -->
                            <div class="flex justify-between mb-2"
                            v-if="service.service_catalog.name == 'Credit Checkup'">
                              <div>
                                <p class="text-xs flex items-center">
                                  Tanggal Konsultasi
                                </p>
                                <div class="font-bold text-sm">{{ $filters.formatDate(additionalDataService.cc_start_datetime) }}</div>
                              </div>
                              <div class="w-1/2">
                                <p class="text-xs flex items-center">
                                  Konsulatan
                                </p>
                                <div class="font-bold text-sm">{{ additionalDataService.cc_provider_name }}</div>
                              </div>
                              <div>
                                <p class="text-xs flex items-center">
                                  Kode Booking
                                </p>
                                <div class="font-bold text-sm">{{ additionalDataService.cc_booking_code }}</div>
                              </div>
                            </div>
                            <!-- end consultation detail -->
                        </div>
                        <!-- end detail service data -->
                    </div>
                  </div>
                </template>
            </template>
            <!-- end card -->
          </div>
      </div>
      <loading-overlay v-if="isLoading" />
    </div>
</template>

<script>

import Pusher from 'pusher-js';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/solid';
import amalansignupApi from '../../axios/amalansignup'


export default {
    components : {
      ChevronDownIcon,
      ChevronUpIcon
    },
    data(){
        return {
            services : [],
            isLoading: false,
            isDetail : false,
            detailId : null,
            enableGenerateInvoice : null,
            checkInvoice : false,
            detailService : [],
            additionalDataService : [],
            invoiceStageName : [],
            isLoadingData : false,
            isDone : false,
            activateStage : [
              "CC_ordered",
              "CC_waiting for payment",
              "CC_waiting for service"
            ],
            signUp : [],
            signUpStatus : null,
            hellosignData : [],
            isCheckSignUp : false,
            isDetailSignUp : false,
            isSignUpExist : false
        }
    },
    created(){
        this.isLoading = true;
        this.axios.get('/customer/services')
        .then( resp => {
            if(resp.status == 200){
                this.services = resp.data.data;
                this.isLoading = false;
            }else{
                this.isLoading = false;
            }

            this.isLoading = false;
        })
        .catch(() => {
            this.isLoading = false;
        })
        this.getLastSignUp();
    },
    mounted(){
      var pusher = new Pusher('003caa8215988a0dd78d', {
        cluster: 'ap1'
      });
      var channel = pusher.subscribe('my-channel');
      channel.bind('my-event', function(data) {
          const channel = data.data['external_id'].split('_')[0];
          switch (channel) {
              case 'DEPOSIT':
                  document.getElementById("btnCheckInvoiceSignUp").click();
                  break;
              case 'CC':
                  document.getElementById("btnCheckInvoice").click();
                  break;
              default:
                  break;
          }
          //   app.messages.push(JSON.stringify(data));
          // if(data.data.payer_email == document.getElementById("payer_email").value){
          //     // $(document).ready(function() {
          //     //     $('#clickButtonDetail').click();
          //     // });
          // }
      });
    },
    methods : {
        onClickDetail(id){
            this.isDetail = false;
            this.detailId = null;
            
            this.detailId = id;
            this.isLoadingData = true;
            var link = "/customer/services/"+id;
            this.axios.get(link)
            .then( resp => {
                this.additionalDataService = JSON.parse(resp.data.data.additional_data);
                this.detailService = resp.data.data;

                for(var i = 0 ; i < this.detailService.invoice_detail.length; i ++){
                    if(this.detailService.invoice_detail[i].invoice.invoice_stage.name == 'paid'){
                        this.enableGenerateInvoice = false;
                        break
                    }

                    if(this.detailService.invoice_detail[i].invoice.invoice_stage.name == 'issued'){
                        this.enableGenerateInvoice = false;
                        break
                    }

                    if(this.detailService.invoice_detail[i].invoice.invoice_stage.name == 'expired'){
                        this.enableGenerateInvoice = true;
                        continue
                    }
                }
                this.isDetail = true;
                this.isLoadingData = false;
                this.checkInvoice = false;
            })
            .catch(() => {
                this.checkInvoice = false;
            })
        },
        onClickCloseDetail(){
            this.isDetail = false;
            this.detailId = null;
        },
        createInvoice(serviceId){
            this.isLoading = true;
            this.axios.post("/services/invoices",{
                serviceId : serviceId
            }).then(() => {
                this.onClickDetail(serviceId);
                this.isLoading = false;
            }).catch(() => {
                this.isLoading = false;
            });
        },
        onClickPayNow(){
            this.checkInvoice = true;
        },
        onSwitch(value){
          /**
           * value 1 = pending
           * value 2 = done
           */
          if(value == 2){
            this.activateStage = [
              "CC_done",
              "CC_cancel",
              "CC_refund",
              "CC_expired"
            ]
            this.isDone = true;
          }else{
            this.activateStage = [
              "CC_ordered",
              "CC_waiting for payment",
              "CC_waiting for service"
            ]
            this.isDone = false;
          }
        },
        converStageName(stageName){
          return stageName.replace("CC_","")
        },
        getLastHellosign(){
            this.isLoading = true;
            amalansignupApi.get('salesforce/contact/last-hellosign')
            .then((resp) => {
                this.hellosignData = resp.data.data.hellosign;
                this.isLoading = false;
            })
            .catch(() => {
                this.isLoading = false;
            })
            this.isCheckSignUp = false;
        },
        onClickSignNow(){
          this.isCheckSignUp = true;
        },
        onClickSignUpPayNow(){
          this.isCheckSignUp = true;
        },
        onClickDetailSignUp(){
          alert("detail");
        },
        onClickCheck(){
            this.getLastSignUp();
        },
        getLastSignUp(){
            this.isLoading = true;
            amalansignupApi.get('salesforce/contact/last-signup')
            .then((resp) => {
                if(resp.status == 201){
                    this.signUpStatus = "waiting for signature"
                }else if(resp.status == 202){
                    this.signUpStatus = "waiting for payment";
                    if(!resp.data.data.invoiceUrl){
                        // create new invoice
                        this.isLoading = true;
                        amalansignupApi.post('salesforce/contact/xendit')
                        .then(() => {
                            this.getLastSignUp();
                            this.isLoading = false;   
                        })
                        .catch(() => {
                            this.isLoading = false;
                        })
                    }
                }else if(resp.status == 200){
                    this.signUpStatus = "done"
                }else{
                    this.signUpStatus = false;
                }
                this.signUp = resp.data.data;
                this.getLastHellosign();
                this.isSignUpExist = true;
                this.isLoading = false;
            })
            .catch(() => {
                this.isLoading = false;
            })
        }
    }
}
</script>