const state = () => ({
    canSignup: null,
    message: 'default' 
});

const getters = {
    getCanSignup(state) {
        return state.canSignup;
    },
    getMessage(state) {
        return state.message;
    }
};

const actions = {
    changeCanSignup({ commit }, payload) {
        return commit("changeCanSignup", payload);
    },
};

const mutations = {
    changeCanSignup(state, payload) {
        state.canSignup = payload.canSignup
        state.message = payload.message
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
