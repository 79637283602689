import { listLoanType, iconBankLender } from '@/constants/LoanConstant'

const getIcon = (loanName, loanType) => {
    const lenderName = loanName.replace(" " + loanType, "").toLowerCase()
    const lenderData = iconBankLender.filter(elem => elem.name == lenderName)
    if (lenderData[0]) return lenderData[0].icon

    const loanTypeIcon = listLoanType.filter(elem => elem.name == loanType)
    if (loanTypeIcon[0]) return loanTypeIcon[0].icon
}

const getLoanTitle = (loanType) => {
    let loanTitle = listLoanType.filter(elem => elem.name == loanType)
    if (loanTitle[0]) return loanTitle[0].title
}

const getBankName = (loanName, loanType) => loanName.replace(" " + loanType, "")

export default {
    getIcon,
    getLoanTitle,
    getBankName
}