<script setup>
import { useRouter, useRoute } from 'vue-router'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { onMounted, ref, computed, inject } from 'vue'
import axios from '@/axios'
import Vue3autocounter from 'vue3-autocounter'
import { useStore } from 'vuex'
import ThanksModalWithMarkOption from '@/components/Modal/ThanksModalWithMarkOption.vue'
import DoneBanner from '@/components/icon/DoneBanner'
import { eventName, mixpanelPushEvent } from '@/composables/mixpanel'

const PAGE_STATE = {
    NOT_FOUND: 'not_found',
    PENDING: 'pending',
    FOUND: 'found'
}

const router = useRouter()
const route = useRoute()
const store = useStore()
const filters = inject("Filters")

const allServices = computed(() => store.getters['services/getAllServices'])
const ScoreInterpretations = computed(() => store.getters['creditCheckup/getScoreInterpretation'])

const showModal = ref(false)
const isFetching = ref(false)
const pageState = ref(PAGE_STATE.NOT_FOUND)
const counter = ref(null)
const productName = ref(route.query.catalog_name ?? '')
const documentPdf = ref({
    isFetching: false,
    cb_score_pdf: null,
    cb_report_pdf: null
})

const scoreRaw = ref('-')
const styleBar = ref('')
const styleIndicator = ref('')
const userScoreData = ref({
    idx: null,
    name: '-',
    color: 'bg-amalan-gray-5',
    content: '-',
    tips: '-',
    created_at: null
})

const fetchData = async () => {
    store.dispatch('creditCheckup/requestCBResult')

    try {
        isFetching.value = true
        const serviceResp = await axios.get('/customer/services/' + route.params.id)
        isFetching.value = false
        
        productName.value = serviceResp.data.data.service_catalog.name ?? ''
        const cbId = JSON.parse(serviceResp.data.data.additional_data).cc_cb_result_id ?? null
        if(!cbId) return constructPendingData()
        
        isFetching.value = true
        const creditScoreResp = await axios.get(`/clik/credit-score/${cbId}`)
        isFetching.value = false
        getDownloadableDocument()
        return constructData(creditScoreResp.data.data.cb_result, creditScoreResp.data.data.cb_score)
    } catch {
        isFetching.value = false
        return constructNotFoundData()
    }
}

const constructNotFoundData = () => pageState.value = PAGE_STATE.NOT_FOUND

const constructPendingData = () => pageState.value = PAGE_STATE.PENDING

const constructData = (CBResultData, CBScoreData) => {
    pageState.value = PAGE_STATE.FOUND
    userScoreData.value.created_at = CBResultData.created_at ?? null

    if(CBScoreData.ScoreRaw === 'n/a'){
        scoreRaw.value = '-'
        styleBar.value = 20 * 0.000545
        styleIndicator.value = 20 * 0.00056

        userScoreData.value.name = CBScoreData.Interpretation.name
        userScoreData.value.content = CBScoreData.Interpretation.detail
        userScoreData.value.tips = CBScoreData.Interpretation.tips

        return
    }

    return setTimeout(() => {
        // scoreRaw.value = 50
        scoreRaw.value = parseInt(CBScoreData.ScoreRaw)
        const score = scoreRaw.value <= 150 ? 150 : scoreRaw.value >= 700 ? 700 : scoreRaw.value

        for(let i in ScoreInterpretations.value){
            if(scoreRaw.value >= ScoreInterpretations.value[i].min && scoreRaw.value <= ScoreInterpretations.value[i].max){
                userScoreData.value.idx = i
                userScoreData.value.name = ScoreInterpretations.value[i].name
                userScoreData.value.color = ScoreInterpretations.value[i].color
                userScoreData.value.content = CBScoreData.Interpretation.detail
                userScoreData.value.tips = CBScoreData.Interpretation.tips

                styleBar.value = score * ScoreInterpretations.value[i].styleBarScale
                styleIndicator.value = score * ScoreInterpretations.value[i].styleIndicatorScale
                break
            }
        }

        counter.value?.start()
    }, 500)
}

const downloadDocument = (url) => {
    showModal.value = true
    setTimeout(() => showModal.value = false, 4000)
    return window.open(url, '_blank')
}

const getDownloadableDocument = () => {
    documentPdf.value.isFetching = true
    axios.post('/services/download-credit-product-pdf', {
        service_id: route.params.id
    })
    .then((resp) => {
        documentPdf.value.isFetching = false
        documentPdf.value.cb_score_pdf = resp.data.data.cb_score_pdf
        documentPdf.value.cb_report_pdf = resp.data.data.cb_report_pdf
    })
    .catch(() => {
        documentPdf.value.isFetching = false
    })
}

const goToSupport = () => window.location = 'https://wa.me/622150933150?text=Halo, amalan. Saya mengalami kendala dalam pengecekan penarikan Credit Score/Report, saya. Mohon bantuannya.';

const refreshPage = () => fetchData()

onMounted(() => {
    window.scrollTo(0, 0)
    fetchData()

    if(localStorage.getItem('ccu-product-destination')){
        mixpanelPushEvent(eventName.CC_RESULT, {
            applied_program: localStorage.getItem('ccu-product-destination')
        })

        localStorage.removeItem('ccu-product-destination')
    }

    if (allServices.value.data.length > 0) return
    store.dispatch('services/requestServices')
})
</script>

<template>
    <div class="min-h-screen bg-amalan-white relative">
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="() => { router.push({ name : 'Credit Checkup - History' }) }" class="flex items-center mt-0">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold mt-0 text-amalan-black">{{ productName }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <loading-data-without-margin v-if="isFetching" class="mt-32" />
        <template v-else>
            <template v-if="pageState === PAGE_STATE.FOUND">
                <div class="w-full relative h-68">
                    <div class="w-full h-68 bg-gradient-credit-score absolute top-0 rounded-br-amalan-lg pb-4 pt-6">
                        <div class="w-full flex justify-between items-center px-6">
                            <img src="@/assets/img/logo-clik.png" class="h-7">
                            <span class="text-xs">Credit Check: {{ userScoreData.created_at ? filters.formatDateOnlyDate(userScoreData.created_at) : '-' }}</span>
                        </div>
                        <div class="mx-auto w-9/12 h-40 mt-8">
                            <div class="h-full flex justify-center items-center">
                                <div class="h-auto text-center mt-2 mb-5">
                                    <div class="w-52 h-36 mb-1">
                                        <div class="gauge-background">
                                        <div class="gauge-a bg-amalan-gray-5"></div>
                                        <div class="gauge-b-background-1 bg-gradient-credit-score-inner"></div>
                                        <div class="gauge-b-background-2 bg-amalan-white"></div>
                                        <div class="gauge-b-background-3 bg-gradient-credit-score-inner"></div>
                                        <div class="gauge-fill" :style="{'transform': 'rotate(' + styleBar + 'turn)'}" :class="[userScoreData.color]"></div>
                                        <div class="gauge-sign bg-amalan-gray-3" :style="{'transform': 'rotate(' + styleIndicator + 'turn)'}"></div>
                                        <div class="gauge-b bg-amalan-white"></div>
                                        <div class="gauge-data pt-3">
                                            <Vue3autocounter v-if="scoreRaw !== '-'" class="text-2xl font-bold" ref='counter' :startAmount='0' :endAmount='scoreRaw' :duration='1.3'/>
                                            <span v-else class="text-2xl font-bold">-</span>
                                        </div>
                                        </div>
                                        <div class="div-clearly h-2 bg-transparent absolute z-10 flex justify-between">
                                            <div class="transition-custom-div h-2 rounded-b-full w-5 ml-1" :class="[userScoreData.color]"></div>
                                            <div class="h-2 w-40 bg-transparent flex justify-center">
                                                <div class="gauge-data-clearly h-2 rounded-b-full bg-amalan-white"></div>
                                            </div>
                                            <div class="transition-custom-div h-2 rounded-b-full w-5 mr-1 bg-amalan-gray-5"></div>
                                        </div>
                                    </div>
                                    <div class="botton-line mx-auto h-0.5 bg-amalan-blue-2 mb-2"></div>
                                    <span class="text-lg font-bold text-amalan-blue-1">{{ userScoreData.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full pt-8 pb-30 relative">
                    <div class="w-full px-6">
                        <div class="w-full bg-amalan-gray-3 border border-amalan-gray-3 rounded-md overflow-hidden">
                            <div class="grid grid-cols-10 gap-px">
                                <div v-for="(data, index) in ScoreInterpretations" :key="index" class="w-full p-2.5 flex justify-center items-center" :class="[scoreRaw == '-' ? 'bg-amalan-gray-5' : data.color]">
                                    <span class="text-xs font-bold">{{ data.risk }}</span>
                                </div>
                                <div class="col-span-10 bg-amalan-gray-5 h-3 grid grid-cols-10 gap-x-px">
                                    <div v-for="(_data, index) in ScoreInterpretations" :key="index" class="w-full flex justify-center items-center">
                                        <svg v-if="index == userScoreData.idx" xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                                            <path d="M7 0L14 11.6667H0L7 0Z" fill="#1A1A1A"/>
                                        </svg>
                                    </div>
                                </div>
                                <div class="col-span-10 flex justify-between items-center px-2 py-0.5 bg-amalan-white">
                                    <span class="text-2xs">>150</span>
                                    <span class="text-2xs">>596</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w-full px-6 py-4 bg-opacity-30 bg-amalan-yellow-100 mt-8">
                        <p class="text-xs" v-html="userScoreData.content"></p>
                    </div>

                    <div class="w-full px-6 relative mt-10">
                        <div class="absolute -top-3 px-3 py-1 bg-amalan-yellow-400 rounded-amalan-lg flex justify-center items-center gap-x-1.5">
                            <img src="@/assets/img/icon-idea.svg" class="h-4">
                            <span class="text-amalan-blue-1 text-xs font-bold">amalan Tips</span>
                        </div>
                        <div class="w-full border border-amalan-yellow-400 rounded-r-lg rounded-bl-lg px-4 py-6">
                            <p class="text-xs text-justify" v-html="userScoreData.tips"></p>
                        </div>

                        <div class="w-full mt-8">
                            <loading-data-without-margin v-if="documentPdf.isFetching" />
                            <template v-else>
                                <button v-if="documentPdf.cb_score_pdf" type="button" @click="downloadDocument(documentPdf.cb_score_pdf)" class="w-full rounded-amalan-lg font-bold text-center flex justify-center items-center gap-x-2.5 text-sm py-3 cursor-pointer shadow-amalan-md border border-amalan-blue-1 text-amalan-blue-1 bg-amalan-white hover:bg-amalan-gray-5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                        <path d="M14 10.5V13.1667C14 13.5203 13.8595 13.8594 13.6095 14.1095C13.3594 14.3595 13.0203 14.5 12.6667 14.5H3.33333C2.97971 14.5 2.64057 14.3595 2.39052 14.1095C2.14048 13.8594 2 13.5203 2 13.1667V10.5M4.66667 7.16667L8 10.5M8 10.5L11.3333 7.16667M8 10.5V2.5" stroke="#252161" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    Unduh Dokumen Credit Score
                                </button>
                                <button v-if="documentPdf.cb_report_pdf" @click="downloadDocument(documentPdf.cb_report_pdf)" class="w-full rounded-amalan-lg font-bold text-center flex justify-center items-center gap-x-2.5 text-sm py-3 cursor-pointer shadow-amalan-md border border-amalan-blue-1 text-amalan-blue-1 bg-amalan-white hover:bg-amalan-gray-5 mt-3.5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                        <path d="M14 10.5V13.1667C14 13.5203 13.8595 13.8594 13.6095 14.1095C13.3594 14.3595 13.0203 14.5 12.6667 14.5H3.33333C2.97971 14.5 2.64057 14.3595 2.39052 14.1095C2.14048 13.8594 2 13.5203 2 13.1667V10.5M4.66667 7.16667L8 10.5M8 10.5L11.3333 7.16667M8 10.5V2.5" stroke="#252161" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    Unduh Dokumen Credit Report
                                </button>
                            </template>
                        </div>

                        <div class="w-full mt-8 border-t border-amalan-yellow-400 pt-2">
                            <p class="text-2xs text-amalan-gray-1">Layanan <i>{{ productName }}</i> ini merupakan salah satu layanan yang disediakan oleh PT CRIF Lembaga Informasi Keuangan dan difasilitasi oleh amalan. PT amalan international indonesia merupakan perusahaan yang tercatat di OJK sesuai surat dengan nomor S-103/MS.72/2019 yang saat ini dalam Cluster Online Distress Solution sesuai surat dengan nomor S-20/MS.7/2020, dengan kegiatan usaha berupa jasa restrukturisasi pinjaman dan pendanaan ulang.</p>
                        </div>
                    </div>
                </div>
            </template>
            <div v-if="pageState === PAGE_STATE.PENDING" class="w-full min-h-screen px-6 bg-amalan-white flex justify-center items-center flex-col">
                <DoneBanner />
                <p class="text-center text-xs text-amalan-black mt-8">Pembelian layanan Anda telah berhasil dilakukan.<br><br>Mohon tunggu, saat ini sistem kami sedang memproses penarikan <i>Credit Score</i> atau <i>Credit Report</i> Anda.<br><br>Silakan cek berkala melalui tombol di bawah ini!</p>
                <button @click="refreshPage" class="w-full flex items-center justify-center py-3 px-2 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg mt-7">
                    <span class="text-amalan-white text-sm font-bold">
                        Periksa Hasil Perhitungan Kredit
                    </span>
                </button>
                <p class="text-center text-xs text-amalan-black mt-8">Jika dalam 10 menit hasil tidak muncul,<br>silakan hubungi tim amalan melalui <span class="text-amalan-blue-1 font-bold cursor-pointer" @click="goToSupport">WhatsApp</span></p>
            </div>
            <div v-if="pageState === PAGE_STATE.NOT_FOUND" class="w-full pt-8 pb-20 px-6 bg-amalan-white">
                <div class="w-full px-6 flex flex-col justify-center items-center pt-34">
                    <img src="@/assets/img/clipboard-v2.svg" class="w-40 mx-auto">
                    <p class="text-center text-xs mt-4">Mohon maaf, Data tidak ditemukan.</p>
                </div>
            </div>
        </template>
        <ThanksModalWithMarkOption :visible="showModal" content="Dokumen kredit Anda telah berhasil diunduh.<br>Silahkan cek folder unduhan Anda." animation-mark="check" />
    </div>
</template>

<style scoped>
.gauge-background {
    width: 200px;
    height: 100px;
    position: relative;
    top: 56%;
    left: 50%;
    overflow: hidden;
    text-align: center;
    transform: translate(-50%, -50%);
}

.gauge-a {
    z-index: 1;
    position: absolute;
    width: 200px;
    height: 100px;
    top: 0%;
    border-radius: 250px 250px 0px 0px;
}

.gauge-b {
    z-index: 10;
    position: absolute;
    width: 100px;
    height: 58px;
    top: 48px;
    margin-left: 49px;
    margin-right: auto;
    border-radius: 250px 250px 0px 0px;
}

.gauge-b-background-1 {
    z-index: 3;
    position: absolute;
    width: 160px;
    height: 120px;
    top: 20px;
    margin-left: 20px;
    margin-right: auto;
    border-radius: 250px 250px 0px 0px;
}

.gauge-b-background-2 {
    z-index: 3;
    position: absolute;
    width: 152px;
    height: 120px;
    top: 24px;
    margin-left: 24px;
    margin-right: auto;
    border-radius: 250px 250px 0px 0px;
}

.gauge-b-background-3 {
    z-index: 3;
    position: absolute;
    width: 148px;
    height: 120px;
    top: 26px;
    margin-left: 26px;
    margin-right: auto;
    border-radius: 250px 250px 0px 0px;
}

.gauge-fill {
    z-index: 2;
    position: absolute;
    width: 200px;
    height: 100px;
    top: 100px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0px 0px 200px 200px;
    transform-origin: center top;
    transition: all 1.3s ease-in-out;
}

.gauge-sign {
    z-index: 3;
    position: absolute;
    width: 200px;
    height: 6px;
    top: 100px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px 5px 5px 5px;
    transform-origin: center top;
    transition: all 1.3s ease-in-out;
}

.gauge-data {
    z-index: 10;
    position: absolute;
    width: 200px;
    height: 100px;
    top: 54px;
    margin-left: auto;
    margin-right: auto;
}

.div-clearly {
    margin-top: 30px;
    width: 208px;
}

.gauge-data-clearly {
    width: 100px;
    margin-right: 2px;
}

.transition-custom-div {
    transition: all 1.3s ease-in-out;
}

.botton-line {
    width: 95%;
}

.bg-gradient-credit-score {
    background: linear-gradient(180deg, #C8E7F5 50.39%, #F8ED8B 114.78%);
}

.bg-gradient-credit-score-inner {
    background: linear-gradient(180deg, #C8E7F5 6%, #F8ED8B 135.78%);
}
</style>