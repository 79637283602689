<script setup>
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import DoneBanner from '@/components/icon/DoneBanner'

const store = useStore()
const router = useRouter()
const route = useRoute()

const lastAdditionalSufData = computed(() => store.getters['additionalSUF/getLastAdditionalSuf'])

onMounted(() => {
    if(lastAdditionalSufData.value.status !== 200) return router.push({ name: 'Loan Management - Home' })
    store.dispatch('additionalSUF/removePaymentData')
    store.dispatch('loanProgress/fetchData')
    store.dispatch('docCollection/requestOpportunities')
})
</script>

<template>
    <div class="min-h-screen bg-amalan-white">
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <div class="flex items-center">
                        <div class="w-5 h-8 bg-transparent"></div>
                    </div>
                    <span class="text-base font-bold text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full min-h-screen px-6 -mt-14 flex flex-col justify-center items-center">
            <DoneBanner />
            <p class="text-center text-sm text-amalan-black mt-8">{{ route.query.update ? 'Perubahan' : 'Penambahan' }} Data Pinjaman Anda<br>telah selesai dilakukan.<br>Anda dapat melakukan pengecekan {{ route.query.update ? 'di' : 'pada' }}<br>halaman Manajemen Pinjaman.<br><br>Jika terjadi kendala,<br>Anda dapat menghubungi konsultan kami.</p>
            <router-link :to="{ name: 'Loan Management - Loan List - Home'}" class="w-full text-center py-3 px-2 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg mt-8 text-amalan-white text-sm font-bold">Kembali ke Manajemen Pinjaman</router-link>
        </div>
    </div>
</template>