<script setup>
import { CheckIcon, XIcon, ChevronLeftIcon } from '@heroicons/vue/outline';
import { ref, defineProps, defineEmits, watch } from 'vue'
import { useRoute } from 'vue-router'

const emit = defineEmits(['onClose', 'onBack'])
const props = defineProps({
    changeVisibility: Boolean
})

const route = useRoute()
const isShow = ref(props.changeVisibility)

watch(props, (value) => isShow.value = value.changeVisibility)

const onClose = () => {
    isShow.value = false
    emit('onClose')
}

const onBack = () => {
    isShow.value = false
    emit('onBack')
}

</script>

<template>
    <div class="w-full absolute bg-amalan-white shadow-xl z-50 -top-14" v-show="isShow">
        <div class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="onBack" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-12 w-full px-6 mt-7 text-amalan-black min-h-screen">
            <p class="text-center text-sm">Perhatikan petunjuk berikut sebelum Anda<br>mengambil atau mengunggah Swafoto Anda.</p>
            <div class="w-full p-4 mt-3.5 flex justify-between items-center border border-amalan-green-light rounded-lg">
                <div class="h-5 w-5 bg-amalan-green-light rounded-full flex-none flex justify-center items-center">
                    <CheckIcon class="w-4 h-4 text-amalan-white" />
                </div>
                <img src="@/assets/img/akun/sample-image/sample-selfie-1.png" class="h-20 mx-3 flex-none">
                <p class="text-left text-xs flex-grow">Pastikan foto tidak buram, tidak terpotong, jelas, dan menghadap ke kamera.</p>
            </div>
            <div class="w-full p-4 mt-3.5 flex justify-between items-center border border-amalan-red rounded-lg">
                <div class="h-5 w-5 bg-amalan-red rounded-full flex-none flex justify-center items-center">
                    <XIcon class="w-4 h-4 text-amalan-white" />
                </div>
                <img src="@/assets/img/akun/sample-image/sample-selfie-2.png" class="h-20 mx-3 flex-none">
                <p class="text-left text-xs flex-grow">Tidak menggunakan aksesoris seperti kacamata, topi, masker, ataupun lainnya.</p>
            </div>
            <button @click="onClose" type="button" class="w-full mt-16 shadow-amalan-md rounded-amalan-lg font-bold text-center text-sm py-3 text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2">Unggah Sekarang</button>
        </div>
    </div>
</template>