<template>
    <div class="bg-blue-100 min-h-screen">
        <div class="justify-center flex pt-6 mb-14">
            <img class="h-16" src="../../assets/img/logo-amalan.png">
        </div>
        <div class="rounded-tr-amalan-lg w-full min-h-screen bg-gray-50 pb-10">
            <div class="pt-6 px-6">
                <h1 class="text-xl font-bold text-blue-900 mb-4">Proses Registrasi</h1>
                <p class="text-sm leading-tight text-justify">Halo, Sobat <b>amalan</b> Pada proses ini, ada beberapa tahapan yang akan dilalui. Jangan lupa persiapkan dokumen yang dibutuhkan!</p>
                <div class="mt-3 grid grid-cols-10">
                    <div>
                        <div class="rounded-full h-7 w-7 mx-auto border-2 border-teal-600 border-amalan-yellow-400 bg-amalan-yellow-400 text-center">
                            <span class="text-blue-900 font-bold">1</span>
                        </div>
                        <hr class="h-full w-1 mx-auto bg-blue-900">
                    </div>
                    <div class="col-span-9 px-2 pb-4">
                        <h1 class="text-base font-bold text-blue-900">Lengkapi Daftar Pinjaman</h1>
                        <p class="text-sm text-justify leading-tight">Bagian ini Anda akan diminta untuk melengkapi dan mengkonfirmasi data pinjaman yang dimiliki untuk diajukan keringanan.</p>
                    </div>
                    <div>
                        <div class="rounded-full h-7 w-7 mx-auto border-2 border-teal-600 border-amalan-yellow-400 bg-amalan-yellow-400 text-center">
                            <span class="text-blue-900 font-bold">2</span>
                        </div>
                        <hr class="h-full w-1 mx-auto bg-blue-900">
                    </div>
                    <div class="col-span-9 px-2 pb-4">
                        <h1 class="text-base font-bold text-blue-900">Informasi Rekening</h1>
                        <p class="text-sm text-justify leading-tight">
                            Anda akan diminta untuk memasukkan informasi rekening Anda, ini dibutuhkan jika dikemudian hari terdapat penarikkan dana.
                        </p>
                    </div>
                    <div>
                        <div class="rounded-full h-7 w-7 mx-auto border-2 border-teal-600 border-amalan-yellow-400 bg-amalan-yellow-400 text-center">
                            <span class="text-blue-900 font-bold">3</span>
                        </div>
                        <hr class="h-full w-1 mx-auto bg-blue-900">
                    </div>
                    <div class="col-span-9 px-2 pb-4">
                        <h1 class="text-base font-bold text-blue-900">Tanda Tangan</h1>
                        <p class="text-sm text-justify leading-tight">
                            <b>amalan</b> akan memberikan Anda sebuah dokumen perjanjian kerjasama yang akan ditanda tangani secara online, sebagai bentuk sah kerjasama kedua belah pihak.
                        </p>
                    </div>
                    <div>
                        <div class="rounded-full h-7 w-7 mx-auto border-2 border-teal-600 border-amalan-yellow-400 bg-amalan-yellow-400 text-center">
                            <span class="text-blue-900 font-bold">4</span>
                        </div>
                        <hr class="h-full w-1 mx-auto bg-blue-900">
                    </div>
                    <div class="col-span-9 px-2 pb-4">
                        <h1 class="text-base font-bold text-blue-900">Pembayaran Registrasi</h1>
                        <p class="text-sm text-justify leading-tight">
                            Jika data Anda sudah lengkap, Anda akan melakukan pembayaran biaya registrasi atau deposit awal.
                        </p>
                    </div>
                    <div>
                        <div class="rounded-full h-7 w-7 mx-auto border-2 border-teal-600 border-amalan-yellow-400 bg-amalan-yellow-400 text-center">
                            <span class="text-blue-900 font-bold">5</span>
                        </div>
                    </div>
                    <div class="col-span-9 px-2 pb-4">
                        <h1 class="text-base font-bold text-blue-900">Registrasi Selesai</h1>
                        <p class="text-sm text-justify leading-tight">Setelah menyelesaikan proses registrasi konsultan <b>amalan</b> akan segera menghubungi Anda untuk proses selanjutnya.</p>
                    </div>
                </div>
                <router-link to="/sign-up/data-utang" class="mt-2 bg-blue-900 hover:bg-blue-600 w-full h-11 rounded-lg flex justify-center items-center">
                    <span class="text-white font-semibold">Mulai Sekarang</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters('contact',{
            signUpStatus:'getSignUpStatus'
        })
    },
    created(){
        if(this.signUpStatus == 200){
            this.$router.push({ name: 'Konfirmasi Layanan'});
        }else if(this.signUpStatus == 201){
            this.$router.push('/sign-up/tanda-tangan');
        }else if(this.signUpStatus == 202){
            this.$router.push('/sign-up/pembayaran');
        }else{
            this.$router.push('/sign-up/data-utang');
        }
    }
}
</script>