<script setup>
import { useStore } from 'vuex'
import { ref, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import HelloSign from 'hellosign-embedded'
import useModalPopUp from '@/composables/modalPopUp'

const store = useStore()
const router = useRouter()
const route = useRoute()
const modalPopUp = useModalPopUp()

const lastAdditionalSufData = computed(() => store.getters['additionalSUF/getLastAdditionalSuf'])

const HS_CLIENT_ID = computed(() => process.env.VUE_APP_HELLOSIGN_CLIENT_ID)
const ENV_MODE = computed(() => process.env.VUE_APP_ENVIRONMENT_MODE)

const urlHelloSign = ref(null)
const isLoading = ref(false)
const isFinish = ref(false)

const HS_CLIENT = new HelloSign({clientId: HS_CLIENT_ID.value})

const showSigningFrame = () => {
    isLoading.value = true
    const decodedURL = decodeURIComponent(urlHelloSign.value)
    const firstExplode = decodedURL.split('sign_url=')
    const secondExplode = firstExplode[1].split('&payment_url=')
    const domElement = document.getElementById('hellosign-embed-container')

    const debug = ENV_MODE.value === 'production' ? false : true
    const testMode = ENV_MODE.value === 'production' ? false : true

    HS_CLIENT.open(secondExplode[0], {
        locale: HelloSign.locales.ID_ID,
        container: domElement,
        allowCancel: false,
        debug,
        testMode
    })
}

HS_CLIENT.on('finish', () => isFinish.value = true)

HS_CLIENT.on('error', () => {
    isLoading.value = false
    HS_CLIENT.close()
    modalPopUp.swalErrorReload.fire().then((result) => {
        if (result.isConfirmed){
            window.location.reload()
        }
    })
});

HS_CLIENT.on('ready', () => isLoading.value = false)

const checkLastSigning = async () => {
    isLoading.value = true
    await store.dispatch('additionalSUF/requestLastAdditionalSuf')
    isLoading.value = false

    if(lastAdditionalSufData.value.status === 203){
        urlHelloSign.value = lastAdditionalSufData.value.data.url_hellosign
        return showSigningFrame()
    }

    if(lastAdditionalSufData.value.status === 200) return router.push({name: 'Loan Management - Additional Suf - Finish Page'})

    return router.push({ name: 'Loan Management - Loan List - Home' })
}

onMounted(() => {
    checkLastSigning()
})

</script>

<template>
    <div class="min-h-screen bg-amalan-white">
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <div class="flex items-center">
                        <div class="w-5 h-8 bg-transparent"></div>
                    </div>
                    <span class="text-sm font-bold text-amalan-black text-center">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full min-h-screen bg-amalan-white px-6 pb-24 pt-8">
            <div class="w-full rounded-lg border border-amalan-gray-4 bg-amalan-white p-4">
                <template v-if="isFinish">
                    <p class="text-sm text-amalan-black text-center pt-4 pb-8 px-6 sm:px-8">Silahkan tekan tombol dibawah untuk proses berikutnya, sistem akan melakukan pengecekan pada dokumen yang telah Anda tanda tangani.</p>
                    <button @click="checkLastSigning" type="button" class="w-full rounded-amalan-lg font-bold text-center text-sm p-3 text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md">Selanjutnya</button>
                </template>
                <template v-else>
                    <p class="text-sm text-amalan-black text-center pt-4 pb-8 px-6 sm:px-8">Silahkan tandatangani dokumen dibawah ini!</p>
                    <div class="w-full h-500" id="hellosign-embed-container"></div>
                </template>
            </div>
            <loading-overlay v-if="isLoading" />
        </div>
    </div>
</template>