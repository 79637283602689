<script setup>
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onMounted, ref, inject, watch } from 'vue'
import NavbarWithBackV2 from '@/components/NavbarWithBackV2.vue'
import opportunityHelper from '@/helpers/opportunity-helper'
import TableDiskon from '../Components/TableDiskon.vue'
import TableCicilan from '../Components/TableCicilan.vue'
import TableDiskonCicilan from '../Components/TableDiskonCicilan.vue'

const router = useRouter()
const route = useRoute()
const store = useStore()
const filters = inject('Filters')

const negotiationResults = computed(() => store.getters['negotiationResult/getNegotiationResultHistory'])
const negotiationResultConfig = computed(() => store.getters['negotiationResult/getConfig'])
const getterUser = computed(() => store.getters['user/getUser'])

const opportunity = ref(null)
const negotiationResult = ref(null)

watch(negotiationResults, () => findNegotiationResult(), {deep: true})

const findNegotiationResult = () => {
    const opport = negotiationResults.value.data.find((value) => value.Id == route.params.opportunityId)
    if(!opport) return router.push({ name: 'Negotiation Process - Result - History' })

    const negotiation = opport.Opportunity_Approval__r.records.find((value) => value.Id == route.params.negotiationId)
    if(!negotiation) return goToHistoryShow()
    
    opportunity.value = opport
    negotiationResult.value = negotiation
}

const findStamp = (status) => {
    const result = negotiationResultConfig.value.find((value) => value.status.toUpperCase() === status.toUpperCase())
    return result.stamp
}

const findClassConfig = (status) => {
    const result = negotiationResultConfig.value.find((value) => value.status.toUpperCase() === status.toUpperCase())
    if(!result) return ''
    return result.class
}

const getName = () => {
    const firstName = getterUser.value.first_name ? getterUser.value.first_name.trim() : ""
    const lastName = getterUser.value.last_name ? getterUser.value.last_name.trim() : ""
    return (firstName.toUpperCase() + " " + lastName.toUpperCase()).trim()
}

const getLoanType = (loanType) => {
    const result = opportunityHelper.getLoanTitle(loanType)
    if(!result) return '-'
    if(result.toUpperCase() == 'PINJAMAN ONLINE') return 'Pinjaman <span class="italic">Online</span>'
    return result
}

const getProgramTypeDescription = (restructuringType) => {
    const value = restructuringType ? restructuringType.toUpperCase() : ''
    if(value === 'DISCOUNT') return 'Diskon'
    if(value === 'RESCHEDULE') return 'Cicilan Jangka Panjang'
    return 'Diskon dengan Cicilan'
}

const createDueDateDescription = (due_date) => {
    if(!due_date) return '-'

    const result = filters.diffDateOnDays(filters.dateNow(), filters.dateTimeFormatBasic(due_date)) == 0
        ? `pukul ${filters.dateTimeFormatOnlyHourMinute(due_date)} WIB (HARI INI)`
        : filters.dateLongDayAndDMY(due_date)

    return result
}

const constructConfirmationDate = (status, date) => {
    if(status == 'Overdue') return 'Tidak Dikonfirmasi Hingga ' + filters.formatDateOnlyDate(date)
    return 'Dikonfirmasi ' + filters.formatDateOnlyDate(date)
}

const goToHistoryShow = () => router.push({ name: 'Negotiation Process - Result - History Show', params: { opportunityId: route.params.opportunityId } })

onMounted(() => {
    if(!route.params.opportunityId) return router.push({ name: 'Negotiation Process - Result - History' })
    if(!route.params.negotiationId) return goToHistoryShow()
    if(negotiationResults.value.isFetched) findNegotiationResult()
})
</script>

<template>
    <div class="min-h-screen bg-amalan-white">
        <NavbarWithBackV2 :title="route.meta.title" @back-button="goToHistoryShow" />
        <div class="w-full pt-8 pb-20 px-6 bg-amalan-white text-amalan-black">
            <p class="text-sm text-amalan-blue-1 font-bold underline uppercase text-center">HASIL NEGOSIASI PINJAMAN ANDA</p>
            <template v-if="opportunity && negotiationResult">
                <p class="text-xs text-justify mt-8">Kepada Yth Bapak/Ibu {{ getName() }}<br><br>Terima kasih telah mempercayakan masalah utang Anda kepada amalan. Berikut adalah hasil negosiasi yang kami dapatkan:</p>
                <table class="w-full mt-3.5 text-xs">
                    <body>
                        <tr>
                            <td class="w-28 align-top text-left">Institusi Pinjaman</td>
                            <td class="w-2 align-top text-left">:</td>
                            <td>{{ opportunity.Name ? opportunityHelper.getBankName(opportunity.Name, opportunity.Loan_Type_2__c) : '-' }}</td>
                        </tr>
                        <tr>
                            <td class="w-28 align-top text-left">Jenis Pinjaman</td>
                            <td class="w-2 align-top text-left">:</td>
                            <td v-html="opportunity.Loan_Type_2__c ? getLoanType(opportunity.Loan_Type_2__c) : '-'"></td>
                        </tr>
                        <tr>
                            <td class="w-28 align-top text-left">Nomor Pinjaman</td>
                            <td class="w-2 align-top text-left">:</td>
                            <td>{{ opportunity.Account_Number__c || '-' }}</td>
                        </tr>
                        <tr>
                            <td class="w-28 align-top text-left">Jenis Program</td>
                            <td class="w-2 align-top text-left">:</td>
                            <td>{{ getProgramTypeDescription(negotiationResult.Restructuring_Type__c) }}</td>
                        </tr>
                        <tr>
                            <td class="w-28 align-top text-left">Tenor Program</td>
                            <td class="w-2 align-top text-left">:</td>
                            <td>{{ negotiationResult.Number_of_Payments__c || '-' }}</td>
                        </tr>
                    </body>
                </table>
                <div class="w-full mt-3.5 px-4">
                    <TableDiskon
                        v-if="negotiationResult.Restructuring_Type__c.toUpperCase() === 'DISCOUNT'"
                        :amalan-success-fee="$filters.format_number_without_symbol(negotiationResult.Success_fee_before_discount__c)"
                        :amalan-discount="$filters.format_number_without_symbol(negotiationResult.Success_fee_discount__c)"
                        :amalan-total-success-fee="$filters.format_number_without_symbol(negotiationResult.Success_fee_after_discount__c)"
                        :amalan-total-fee="$filters.format_number_without_symbol(negotiationResult.First_Payment_Amount__c + negotiationResult.Success_fee_after_discount__c)"
                        :tagihan-terakhir="$filters.format_number_without_symbol(negotiationResult.Last_Outstanding_Amount__c)"
                        :discount="$filters.format_number_without_symbol(negotiationResult.Discounted_Amount__c)"
                        :total-tagihan="$filters.format_number_without_symbol(negotiationResult.First_Payment_Amount__c)"
                        :total-savings="$filters.format_number_without_symbol(negotiationResult.Last_Outstanding_Amount__c - (negotiationResult.First_Payment_Amount__c + negotiationResult.Success_fee_after_discount__c))"
                    />
                    <TableCicilan
                        v-else-if="negotiationResult.Restructuring_Type__c.toUpperCase() === 'RESCHEDULE'"
                        :amalan-success-fee="$filters.format_number_without_symbol(negotiationResult.Success_fee_before_discount__c)"
                        :amalan-discount="$filters.format_number_without_symbol(negotiationResult.Success_fee_discount__c)"
                        :amalan-total-success-fee="$filters.format_number_without_symbol(negotiationResult.Success_fee_after_discount__c)"
                        :amalan-total-fee="$filters.format_number_without_symbol(negotiationResult.First_Payment_Amount__c + negotiationResult.Success_fee_after_discount__c)"
                        :tagihan-terakhir="$filters.format_number_without_symbol(negotiationResult.Last_Outstanding_Amount__c)"
                        :cicilan-lama="$filters.format_number_without_symbol(opportunity.Old_Installment__c)"
                        :cicilan-baru="$filters.format_number_without_symbol(negotiationResult.Subsequent_Payments_Amount__c)"
                        :uang-muka="$filters.format_number_without_symbol(negotiationResult.First_Payment_Amount__c)"
                        :total-savings="$filters.format_number_without_symbol(opportunity.Old_Installment__c - negotiationResult.Subsequent_Payments_Amount__c)"
                    />
                    <TableDiskonCicilan
                        v-else
                        :amalan-success-fee="$filters.format_number_without_symbol(negotiationResult.Success_fee_before_discount__c)"
                        :amalan-discount="$filters.format_number_without_symbol(negotiationResult.Success_fee_discount__c)"
                        :amalan-total-success-fee="$filters.format_number_without_symbol(negotiationResult.Success_fee_after_discount__c)"
                        :amalan-total-fee="$filters.format_number_without_symbol(negotiationResult.First_Payment_Amount__c + negotiationResult.Success_fee_after_discount__c)"
                        :tagihan-terakhir="$filters.format_number_without_symbol(negotiationResult.Last_Outstanding_Amount__c)"
                        :discount="$filters.format_number_without_symbol(negotiationResult.Discounted_Amount__c)"
                        :tagihan-pertama="$filters.format_number_without_symbol(negotiationResult.First_Payment_Amount__c)"
                        :tagihan-berikutnya="$filters.format_number_without_symbol(negotiationResult.Subsequent_Payments_Amount__c)"
                        :total-savings="$filters.format_number_without_symbol(negotiationResult.Last_Outstanding_Amount__c - (negotiationResult.First_Payment_Amount__c + negotiationResult.Success_fee_after_discount__c))"
                    />
                </div>
                <p class="mt-3.5 text-justify text-xs">Kami <span class="uppercase font-bold" :class="[ negotiationResult.is_Recommended__c ? 'text-amalan-green-light' : 'text-amalan-red' ]">{{ negotiationResult.is_Recommended__c ? 'MEREKOMENDASIKAN' : 'TIDAK MEREKOMENDASIKAN' }}</span> Anda untuk menyetujui hasil negosiasi di atas. Silakan konfirmasi dengan klik tombol di bawah ini sebelum <span class="font-bold">{{ createDueDateDescription(negotiationResult.Customer_Feedback_Due_Date__c) }}.</span></p>
                <div class="w-full mt-8 flex flex-col justify-center items-center">
                    <img class="h-14" :src="require('@/assets/img/negotiation-process/stamp/' + findStamp(negotiationResult.Customer_Feedback__c))">
                    <p class="text-center font-bold text-xs mt-1" :class="findClassConfig(negotiationResult.Customer_Feedback__c)">{{ constructConfirmationDate(negotiationResult.Customer_Feedback__c, negotiationResult.Customer_Feedback_Timestamp__c ? negotiationResult.Customer_Feedback_Timestamp__c : negotiationResult.Customer_Feedback_Due_Date__c) }}</p>
                </div>
                <p class="text-xs mt-8"><span class="font-bold">Langkah Selanjutnya</span><br>Setelah Anda melakukan konfirmasi hasil negosiasi, mediator kami akan membantu mempersiapkan dokumen yang diperlukan untuk proses keringanan utang Anda. Proses pembayaran akan dilakukan melalui Rekening Dana Pelunasan (RDP) setelah administrasi dokumen selesai.<br><br>Jika Anda membutuhkan bantuan, silakan hubungi mediator kami, <span class="font-bold">{{ negotiationResult.Owner?.Name ? negotiationResult.Owner.Name + ',' : '' }}</span> melalui nomor 021 50933141 (Telepon dan WhatsApp)</p>
            </template>
        </div>
        <loading-overlay v-if="!negotiationResults.isFetched" />
    </div>
</template>