import axios from "../../axios";
import store from "../index";
import vm from "../../main";

const state = () => ({
    catalog : null,
});

const getters = {
    getCatalog(state) {
        return state.catalog;
    }
};

const actions = {
    requestCatalog({ commit }) {
        let data = {
            promoType : localStorage.getItem('promoType') ?? '',
            promoCode : localStorage.getItem('promoCode') ?? ''
        }

        store.dispatch('promo/savePromo', data);
        return axios.post('/services/check-prices-all', data)
        .then( resp => {
            if(resp.status == 200){
                commit("requestCatalog", resp.data.data);
            }
        })
        .catch( err => {
            if (err.status !== 401){
                const swalErrorReload = vm.$swal.mixin({
                    customClass: {
                        confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                    },
                    buttonsStyling: false,
                    title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                    showCancelButton: false,
                    confirmButtonText: 'Perbaharui',
                    width: '340px',
                    allowOutsideClick: false,
                })

                swalErrorReload.fire().then((result) => {
                    if (result.isConfirmed){
                        window.location.reload();
                    }
                });
            }
        })
    }
};

const mutations = {
    requestCatalog(state, payload) {
        let newDescription = null;
        let oldDescription = null;
        payload.forEach(elem => {
            oldDescription = elem.serviceCatalogDescription;
            newDescription = oldDescription.split("#");
            elem.serviceCatalogDescription = newDescription;
        });
        
        state.catalog = payload.reverse();
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
