<script setup>
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { ref, defineProps } from 'vue'

const props = defineProps({
    title: String,
    content: String
})

const isCollapse = ref(false)
const content = ref(null)
const collapsable = ref(null)

const collapse = () => {
    isCollapse.value = !isCollapse.value
    if (collapsable.value.style.maxHeight != '0px') return collapsable.value.style.maxHeight = '0px'
    return collapsable.value.style.maxHeight = content.value.scrollHeight + "px"
}

</script>

<template>
    <div class="w-full text-amalan-black">
        <div class="w-full relative z-2 rounded-lg shadow-amalan-sm bg-amalan-white flex justify-between items-center py-2 px-4 gap-x-2 cursor-pointer" @click="collapse">
            <p class="text-xs text-amalan-blue-1" v-html="props.title"></p>
            <ChevronDownIcon class="flex-none w-6 text-amalan-blue-1" :class="[ isCollapse ? 'chevron-open' : 'chevron-close' ]" />
        </div>
        <div ref="collapsable" class="w-full relative z-1 overflow-hidden rounded-b-lg bg-amalan-gray-5 bg-opacity-50 -mt-2 cursor-pointer transition-max-height" style="max-height: 0px;" @click="collapse">
            <p ref="content" class="px-4 pt-6 pb-4 text-xs" v-html="props.content"></p>
        </div>
    </div>
</template>

<style scoped>
.chevron-open {
    transform: rotate( -180deg );            
    transition: transform 0.3s ease;
}

.chevron-close {
    transform: rotate( -360deg );            
    transition: transform 0.3s ease;
}

.transition-max-height {
    transition: max-height 0.4s ease-out;
}
</style>