<script setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from "vue-router"
import setCookies from '@/composables/setCookies'
import { eventName, mixpanelPushEvent } from '@/composables/mixpanel';

const router = useRouter()
const route = useRoute()

const STEPS = {
    FIRST: 1,
    SECOND: 2,
    THIRD: 3,
    FOURTH: 4
}

const currentStep = ref(STEPS.FIRST)
const showPage = ref(false)
const isLoading = ref(false)

const nextStep = () => {
    if(currentStep.value === STEPS.FOURTH){
        isLoading.value = true
        return router.push({ name : 'Login' })
    }

    return currentStep.value++
}

const skip = () => {
    isLoading.value = true
    return router.push({ name : 'Login' })
}

const getLogoAsset = () => {
    if(route.path == '/credit-report-onboarding' || route.path == '/credit-report-internal-onboarding') return 'logo-amalan.png'
    return `logo-amalan-${route.path.slice(15).replace('-onboarding', '')}.png`
}

onMounted(() => {
    localStorage.setItem('from-onboarding-go-to', 'Catalog List')

    if(route.query.referral){            
        localStorage.setItem("promoType", "referral")
        localStorage.setItem("promoCode", route.query.referral)
    }

    localStorage.setItem("ccu-product-destination", route.path.slice(1).replace('-onboarding', ''))

    if(route.path == '/credit-report-onboarding'){
        setCookies.setUtmCookies(route.query)
    }else{
        if(route.query.utm_source) setCookies.setUtmCookies(route.query)
        else setCookies.setDefaultUtmParams(route.query, route.path.slice(1).replace('-onboarding', ''))
    }

    mixpanelPushEvent(eventName.CC_ONBOARDING, {
        applied_program: localStorage.getItem('ccu-product-destination')
    })

    setTimeout(() => showPage.value = true, 500)
})
</script>

<template>
    <div class="bg-amalan-white text-amalan-black min-h-screen">
        <Transition name="fade-fast">
            <div v-show="showPage" class="w-full h-800 sm:h-900 relative">
                <div class="w-full bg-amalan-white-200 h-500 relative overflow-hidden">
                    <div class="justify-center flex pt-13">
                        <img class="h-12" :src="require('@/assets/img/' + getLogoAsset())">
                    </div>
                    <Transition name="slide">
                        <div v-show="currentStep === STEPS.FIRST" class="w-full absolute justify-center flex">
                            <img class="w-full" src="@/assets/img/credit-report/onboarding-first.png">
                        </div>
                    </Transition>
                    <Transition name="slide">
                        <div v-show="currentStep === STEPS.SECOND" class="w-full absolute justify-center flex">
                            <img class="w-full" src="@/assets/img/credit-report/onboarding-second.png">
                        </div>
                    </Transition>
                    <Transition name="slide">
                        <div v-show="currentStep === STEPS.THIRD" class="w-full absolute justify-center flex">
                            <img class="w-full" src="@/assets/img/credit-report/onboarding-third.png">
                        </div>
                    </Transition>
                    <Transition name="slide">
                        <div v-show="currentStep === STEPS.FOURTH" class="w-full absolute justify-center flex">
                            <img class="w-full" src="@/assets/img/credit-report/onboarding-fourth.png">
                        </div>
                    </Transition>
                </div>
                <div class="w-full absolute bottom-0 sm:bottom-12 z-3 h-500 overflow-hidden">
                    <Transition name="slide-100">
                        <img v-show="currentStep === STEPS.FIRST" src="@/assets/img/credit-report/bg-onboarding-first.svg" class="w-full absolute">
                    </Transition>
                    <Transition name="slide-100">
                        <img v-show="currentStep === STEPS.SECOND" src="@/assets/img/credit-report/bg-onboarding-second.svg" class="w-full absolute">
                    </Transition>
                    <Transition name="slide-100">
                        <img v-show="currentStep === STEPS.THIRD" src="@/assets/img/credit-report/bg-onboarding-third.svg" class="w-full absolute">
                    </Transition>
                    <Transition name="slide-100">
                        <img v-show="currentStep === STEPS.FOURTH" src="@/assets/img/credit-report/bg-onboarding-fourth.svg" class="w-full absolute xs:top-10">
                    </Transition>
                    <Transition name="slide-100">
                        <div v-show="currentStep === STEPS.FIRST" class="w-full absolute top-36 ssm:top-40 sm:top-44 h-full px-6">
                            <p class="text-center font-bold text-base text-amalan-black">Cukup 5 Menit untuk Mengetahui<br>Reputasi Kredit Anda</p>
                            <p class="text-center text-xs text-amalan-black mt-3.5">Cek reputasi kredit Anda melalui layanan<br><i>Credit Score</i>, <i>Credit Report</i>, dan <i>Credit Checkup</i><br>dengan cepat dan kilat, tanpa antri!</p>
                        </div>
                    </Transition>
                    <Transition name="slide-100">
                        <div v-show="currentStep === STEPS.SECOND" class="w-full absolute top-36 ssm:top-40 sm:top-44 h-full px-6">
                            <p class="text-center font-bold text-base text-amalan-black">Persiapkan e-KTP<br>dan Foto Swafoto Anda!</p>
                            <p class="text-center text-xs text-amalan-black mt-3.5">Bagi Anda pengguna baru,<br>cukup dengan unggah foto e-KTP dan Swafoto<br>Anda untuk melakukan Verifikasi Data Diri</p>
                        </div>
                    </Transition>
                    <Transition name="slide-100">
                        <div v-show="currentStep === STEPS.THIRD" class="w-full absolute top-36 ssm:top-40 sm:top-44 h-full px-6">
                            <p class="text-center font-bold text-base text-amalan-black">Lengkapi Formulir Pembelian<br>Sesuai dengan Data Anda!</p>
                            <p class="text-center text-xs text-amalan-black mt-3.5"><b>amalan</b> akan menjamin keamanan<br>data diri, data kredit, dan privasi Anda!</p>
                        </div>
                    </Transition>
                    <Transition name="slide-100">
                        <div v-show="currentStep === STEPS.FOURTH" class="w-full absolute top-36 ssm:top-40 sm:top-44 h-full px-6">
                            <p class="text-center font-bold text-base text-amalan-black">Riwayat Kredit Dapat Diakses<br>Saat Pembayaran Telah Berhasil!</p>
                            <p class="text-center text-xs text-amalan-black mt-3.5">Akses riwayat kredit Anda hanya tersedia<br>di <b>amalan Web App</b>, jadi amalan menjamin keamanan data Anda!</p>
                        </div>
                    </Transition>
                    <div class="w-full absolute bottom-9">
                        <Transition name="fade-fast">
                            <div v-if="currentStep === STEPS.FIRST" class="w-full flex justify-center absolute">
                                <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            </div>
                        </Transition>
                        <Transition name="fade-fast">
                            <div v-if="currentStep === STEPS.SECOND" class="w-full flex justify-center absolute">
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                                <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            </div>
                        </Transition>
                        <Transition name="fade-fast">
                            <div v-if="currentStep === STEPS.THIRD" class="w-full flex justify-center absolute">
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                                <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            </div>
                        </Transition>
                        <Transition name="fade-fast">
                            <div v-if="currentStep === STEPS.FOURTH" class="w-full flex justify-center absolute">
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                                <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                            </div>
                        </Transition>
                        <div class="w-full px-6">
                            <div @click="nextStep" class="w-full mt-14 py-3 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg cursor-pointer">
                                <p class="text-amalan-white text-sm font-bold text-center">{{ currentStep === STEPS.FOURTH ? 'Mulai Sekarang' : 'Selanjutnya' }}</p>
                            </div>
                            <div class="w-full h-5 mt-4 flex justify-center items-center">
                                <Transition name="fade-fast">
                                    <div v-if="currentStep === STEPS.FIRST" @click="skip" class="text-amalan-blue-1 hover:text-amalan-blue-2 text-sm font-bold underline cursor-pointer">Lewati</div>
                                </Transition>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
        <loading-overlay v-if="isLoading" />
    </div>
</template>

<style scoped>
.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.8s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.8s ease
}

.slide-enter-active,
.slide-leave-active,
.slide-100-enter-active,
.slide-100-leave-active {
    transition: transform 0.6s ease-in-out;
}

.slide-enter-from {
    transform: translate(110%, 0);
}

.slide-leave-to {
    transform: translate(-110%, 0);
}

.slide-100-enter-from {
    transform: translate(100%, 0);
}

.slide-100-leave-to {
    transform: translate(-100%, 0);
}
</style>