<script setup>
import { ChevronRightIcon } from '@heroicons/vue/solid'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import moment from 'moment'

const store = useStore()
const router = useRouter()

const notificationData = computed(() => store.getters['pendingUserActivity/getPendingActivityData'])
const notificationDataFetching = computed(() => store.getters['pendingUserActivity/getFetchStatus'])
const isLoadingOverlay = ref(false)

const goTo = (path) => {
    isLoadingOverlay.value = true
    router.push(path)
}

const formatDateTime = (dateTime) => moment(dateTime).format('DD MMMM YYYY | HH.mm')

onMounted(() => {
    store.dispatch('pendingUserActivity/initialPendingActivityData')
})

</script>

<template>
    <div class="w-full h-screen-80 px-2 pb-2 mt-4 overflow-y-scroll scrollbar-hide">
        <div v-if="notificationDataFetching === 'FETCHING'" class="w-full h-full flex justify-center items-center">
            <loading-data-without-margin />
        </div>
        <template v-else>
            <template v-if="(notificationData.length > 0)">
                <div v-for="(data, index) in notificationData" class="w-full p-4 mt-4 shadow-amalan-sm bg-amalan-white hover:bg-amalan-gray-5 rounded-lg flex justify-between cursor-pointer" :key="index" @click="goTo(data.link)">
                    <div class="flex-grow">
                        <p class="text-xs text-amalan-black">{{ data.body }}</p>
                        <p class="text-xs text-amalan-blue-1 font-bold">{{ data.title }}</p>
                        <p v-if="data.dateTime" class="text-2xs text-amalan-gray-2 mt-4">{{ formatDateTime(data.dateTime) }}</p>
                    </div>
                    <div class="w-8 flex-none flex justify-end items-center">
                        <button class="border border-amalan-blue-1 rounded-lg w-6 h-6 flex justify-center items-center">
                            <ChevronRightIcon class="h-5 text-amalan-blue-1" />
                        </button>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="w-full h-full flex justify-center items-center">
                    <p class="text-xs text-amalan-black text-center">Anda belum memiliki<br>notifikasi pengingat aktivitas untuk saat ini</p>
                </div>
            </template>
        </template>
        <loading-overlay v-if="isLoadingOverlay" />
    </div>
</template>

<style scoped>
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
</style>