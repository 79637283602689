import CheckIdFormOCR from '@/views/Akun/CheckId/FormOCR.vue'
import CheckIdFormUpdate from '@/views/Akun/CheckId/FormUpdate.vue'
import RDPPreForm from '@/views/Akun/RDP/PreForm.vue'
import EmailVerification from '@/views/Akun/RDP/EmailVerification.vue'
import SignDocumentPOA from '@/views/Akun/RDP/SignDocumentPOA.vue'
import RDPHome from '@/views/Akun/RDP/Home.vue'

export default [
    {
        path: '/rdp/onboarding-1',
        name: 'rdp opening - onboarding 1',
        component: CheckIdFormOCR,
        meta: {
            name: 'rdp opening - onboarding 1',
            isAuth: true,
            title: 'Verifikasi Data',
            kycRedirectTo: 'rdp opening',
            rdpOpeningKYC: true,
            excludeKYC: true
        }
    },
    {
        path: '/rdp/onboarding-2',
        name: 'rdp opening - onboarding 2',
        component: CheckIdFormUpdate,
        meta: {
            name: 'rdp opening - onboarding 2',
            isAuth: true,
            title: 'Verifikasi Data',
            kycRedirectTo: 'rdp opening',
            rdpOpeningKYC: true,
            excludeKYC: true
        }
    },
    {
        path: '/rdp/preform',
        name: 'rdp opening - preform',
        component: RDPPreForm,
        meta: {
            name: 'rdp opening - preform',
            isAuth: true,
            title: 'Pembukaan RDP',
            kycRedirectTo: 'rdp opening',
            rdpOpeningKYC: true,
            excludeKYC: true
        }
    },
    {
        path: '/rdp/email-verification',
        name: 'rdp opening - email verification',
        component: EmailVerification,
        meta: {
            name: 'rdp opening - email verification',
            isAuth: true,
            title: 'Verifikasi Email',
            kycRedirectTo: 'rdp opening',
            rdpOpeningKYC: true,
            excludeKYC: true
        }
    },
    {
        path: '/rdp/document-poa',
        name: 'rdp opening - document poa',
        component: SignDocumentPOA,
        meta: {
            name: 'rdp opening - document poa',
            isAuth: true,
            title: 'Surat Kuasa RDP',
            kycRedirectTo: 'rdp opening',
            rdpOpeningKYC: true,
            excludeKYC: true
        }
    },
    {
        path: '/rdp/home',
        name: 'rdp - home',
        component: RDPHome,
        meta: {
            name: 'rdp - home',
            isAuth: true,
            title: 'Rekening Dana Pelunasan',
            excludeKYC: true
        }
    }
];