<script setup>
import { computed, reactive, onMounted, inject, ref } from 'vue'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { Form, Field } from "vee-validate"
import * as yup from "yup"
import stytchApi from '@/axios/stytch'
import axios from "@/axios"
import { VueRecaptcha } from 'vue-recaptcha'
import VueCountdown from '@chenfengyuan/vue-countdown'

const swal = inject("$swal")
const store = useStore()
const router = useRouter()
const route = useRoute()
const dataUser = computed(() => store.getters['user/getUser'])
const isLoading = ref(false)
const stepForm = ref(1)
const methodId = ref('')
const captchaShow = ref(false)
const recaptcha = ref('recaptcha')
const params = reactive({
    otpType: 'email',
    identifier: null,
    challenge: 'verification'
})
const codeOtp = reactive([])
const liveTimeOtp = ref(null)
const time = ref(58000)
const validateInput = ['1','2','3','4','5','6','7','8','9','0']
const countdownIsDone = ref(false)

const emailSchema = yup.object({
    email: yup.string().required('Email tidak boleh kosong')
    .email('Email tidak valid')
})

const otpSchema = yup.object({
    code1: yup.string().required().matches(/^[0-9]+$/).length(1),
    code2: yup.string().required().matches(/^[0-9]+$/).length(1),
    code3: yup.string().required().matches(/^[0-9]+$/).length(1),
    code4: yup.string().required().matches(/^[0-9]+$/).length(1),
    code5: yup.string().required().matches(/^[0-9]+$/).length(1),
    code6: yup.string().required().matches(/^[0-9]+$/).length(1),
})

const recaptchaSiteKey = computed(() => {
    return process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY
})

const swalError = swal.mixin({
    customClass: {
        confirmButton: 'bg-amalan-blue-1 mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-amalan-white text-sm font-semibold hover:bg-blue-900 hover:border-blue-900',
        cancelButton: 'absolute -top-3 -right-3'
    },
    buttonsStyling: false,
    title: '<img src="/img/alert-error-icon.svg" class="w-12 h-12 mx-auto">',
    width: '340px',
    allowOutsideClick: false,
    reverseButtons: true,
})

const countdownEndEvent = () => countdownIsDone.value = true

const submitEmailOtp = (values) => {
    params.identifier = values.email
    captchaShow.value = true
}

const recaptchaVerify = (response) => {
    if(response){
        setTimeout(() => {
            isLoading.value = true
            axios.get(`/customer/check-duplicate/email?email=${params.identifier}`)
            .then(() =>{
                recaptcha.value.reset()
                captchaShow.value = false
                requestOtp()
            }).catch(() => {
                recaptcha.value.reset()
                captchaShow.value = false
                isLoading.value = false
                swalError.fire({
                    html: '<p class="text-center text-sm mt-2">Maaf, email yang kamu masukkan telah digunakan, pastikan email tersebut adalah email anda atau gunakan email lain.</p>',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                })
            })
        }, 1000)
    }
}

const requestOtp = () => {
    stytchApi.post('request-otp', params)
    .then(resp => {
        isLoading.value = false
        methodId.value = resp.data.data.methodId
        stepForm.value++
        setTimeout(() => {
            document.getElementById('code1').focus()
        }, 2000)
    })
    .catch(err => {
        isLoading.value = false
        return handleErrorMessage(err.response.data.message)
    })
}

const resendOtp = () => {
    isLoading.value = true    
    stytchApi.post('request-otp', params)
    .then(resp => {
        isLoading.value = false;
        methodId.value = resp.data.data.methodId
        time.value += 10
        resetOtpField()
        countdownIsDone.value = false
    })
    .catch(err => {
        isLoading.value = false;
        return handleErrorMessage(err.response.data.message)
    })
}

const resetOtpField = () => {
    captchaShow.value = false
    countdownIsDone.value = false
}

const submitOtp = (values) => {
    isLoading.value = true
    const payload = {
        methodId: methodId.value,
        otpType: params.otpType,
        identifier: params.identifier,
        code: values.code1 + values.code2 + values.code3 + values.code4 + values.code5 + values.code6,
        challenge: params.challenge
    };

    stytchApi.post('authenticate-otp', payload)
    .then(resp => {
        if(resp.data.status_code == 200){
            axios.put('/customer/verification/email', { email: params.identifier })
            .then(() =>{
                store.dispatch('user/storeUser').then(() => {
                    setTimeout(() => {
                        isLoading.value = false
                        router.push({ name: 'rdp opening - document poa' })
                    }, 1000)
                })
            }).catch(() => {
                isLoading.value = false
                swalError.fire({
                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal melakukan validasi kode verifikasi, silahkan tunggu beberapa saat.<br><br>Atau klik tombol bantuan dibawah</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                }).then((result) => {
                    if (result.isConfirmed){
                        window.location = 'https://wa.me/622150933150?text=' + 
                        'Mohon bantuan saya tidak dapat verifikasi email di web.amalan.com, saya sudah memasukkan kode verifikasi dengan benar namun tetap gagal.'
                        return;
                    }
                })
            })
        }
    })
    .catch((err) => {
        isLoading.value = false
        return handleErrorMessage(err.response.data.message)
    });
}

const nextInput = (event) => {
    if(event.data && validateInput.includes(event.data)){
        const element = event.srcElement.name
        const el = parseInt(element.replace('code', ''))
        if(el < 6){
            const nextElem = el + 1
            document.getElementById('code' + nextElem).focus()
        }
    }
}

const deleteInput = (event) => {
    if(event.key == 'Backspace'){
        const element = event.srcElement.name
        const el = parseInt(element.replace('code', ''))
        if(el > 1){
            codeOtp[el - 1] = null
            codeOtp[el - 2] = null
            const nextElem = el - 1
            document.getElementById('code' + nextElem).focus()
            if(el === 6){
                codeOtp[5] = null
            }
        }
    }
}

const pasteInput = (event) => {
    const clipboard = event.clipboardData.getData('text').trim()
    if(/\d{6}/.test(clipboard)){
        const data = [...clipboard]
        data.forEach((elem, index) => {
            codeOtp[index] = elem
        })
        document.getElementById('code6').focus()
    }
}

const handleErrorMessage = (message) => {
    if(!message){
        return swalError.fire({
            html: '<p class="text-center text-xs mt-2">Maaf, server gagal mengirim kode OTP, silahkan tunggu beberapa saat.<br><br>Atau klik tombol bantuan dibawah</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        }).then((result) => {
            if (result.isConfirmed){
                window.location = 'https://wa.me/622150933150?text=' + 
                'Mohon bantuan saya tidak menerima OTP untuk login di web.amalan.com';
                return;
            }
        });
    }

    if(message.includes('Too Many Attempts')){
        captchaShow.value = false
        return swalError.fire({
            html: '<p class="text-center text-xs mt-2">Anda sudah melebihi limit percobaan verifikasi. Mohon coba beberapa saat lagi</p>',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        });
    }

    if(message.includes('Invalid OTP code') || message.includes('OTP not found')){
        return swalError.fire({
            html: '<p class="text-center text-xs mt-2">Maaf, kode OTP yang anda masukan salah. Mohon periksa kembali.</p>',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        });
    }

    if(message.includes('OTP has expired') || message.includes('reached the time limit')){
        return swalError.fire({
            html: '<p class="text-center text-xs mt-2">Maaf, kode OTP yang anda masukan sudah kadaluarsa. Mohon periksa kembali.</p>',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        });
    }

    return swalError.fire({
        html: '<p class="text-center text-xs mt-2">Maaf, server gagal mengirim kode OTP, silahkan tunggu beberapa saat.<br><br>Atau klik tombol bantuan dibawah</p>',
        showCancelButton: true,
        confirmButtonText: 'Bantuan',
        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
    }).then((result) => {
        if (result.isConfirmed){
            window.location = 'https://wa.me/622150933150?text=' + 
            'Mohon bantuan saya tidak menerima OTP untuk login di web.amalan.com';
            return;
        }
    });
}

onMounted(() => {
    if(dataUser.value.email_verified_at){
        router.push({ name : 'rdp opening - document poa' })
    }

    params.identifier = dataUser.value.email
})

</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="router.push({ name : 'Home' })" class="flex items-center mt-0">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold mt-0 text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full py-8 px-6 flex justify-between items-center">
            <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                <span class="text-sm text-amalan-blue-1 font-bold">1</span>
            </div>
            <div class="bg-amalan-blue-1 h-px flex-none w-3"></div>
            <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400 mx-1">
                <span class="text-sm font-bold text-amalan-blue-1">2</span>
            </div>
            <p class="flex-none text-amalan-blue-1 text-sm font-bold mx-1">Verifikasi Surel</p>
            <div class="bg-amalan-blue-1 h-px flex-grow"></div>
            <div class="w-6 h-6 rounded-full bg-amalan-gray-4 flex justify-center items-center flex-none ml-1">
                <span class="text-sm text-amalan-gray-2 font-bold">3</span>
            </div>
        </div>
        <div class="w-full px-6 pb-16 text-amalan-black">
            <div class="w-full px-10">
                <img src="@/assets/img/verifikasi-email.svg" class="w-full">
            </div>
            <div class="w-full mt-6" v-if="stepForm === 1">
                <p class="text-center text-amalan-blue-1 font-bold text-base">Verifikasi Email Anda</p>
                <p class="text-center text-xs mt-1">Masukkan alamat email Anda untuk mendapatkan kode verifikasi</p>
                <Form
                @submit="submitEmailOtp"
                :validation-schema="emailSchema"
                v-slot="{ errors, meta }"
                >
                    <div class="mt-8">
                        <Field placeholder="Masukan alamat email Anda" name="email" v-model="params.identifier" :validateOnInput="true" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.email ? 'border-amalan-red' : 'border-amalan-gray-4' ]" autofocus />
                        <div v-if="errors.email" class="mt-1 text-2xs text-amalan-red">*{{errors.email}}</div>
                    </div>
                    <div class="my-4" v-if="captchaShow">
                        <p class="text-center text-xs">Silahkan centang kotak di bawah untuk melanjutkan.</p>
                        <div class="flex justify-center items-center mt-1">
                            <VueRecaptcha
                                ref="recaptcha"
                                :sitekey="recaptchaSiteKey"
                                :language="'id'"
                                @verify="recaptchaVerify"
                            ></VueRecaptcha>
                        </div>
                    </div>
                    <button v-else type="submit" class="w-full mt-3.5 rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!meta.valid">Kirim</button>
                </Form>
            </div>
            <div v-else class="w-full mt-6">
                <p class="text-center text-amalan-blue-1 font-bold text-base">Verifikasi Email Anda</p>
                <p class="text-center text-xs mt-1">Kode verifikasi akan dikirimkan ke<br><b>{{ params.identifier }}</b></p>
                <Form
                @submit="submitOtp"
                :validation-schema="otpSchema"
                v-slot="{ errors, meta }"
                >
                    <div class="my-3.5 px-3.5 sm:px-10 w-full flex items-center justify-between">
                        <template v-for="i in 6" :key="i">
                            <Field :name="'code' + i" :id="'code' + i" type="tel" maxlength="1" class="bg-transparent mx-2 w-11 p-2 text-lg text-center border-2 rounded-lg outline-none focus:border-amalan-blue-3" :class="[errors['code' + i] ? 'border-amalan-red' : 'border-amalan-gray-3']" :validateOnMount="true" :validateOnInput="true" @input="nextInput" @keydown="deleteInput" @paste="pasteInput" v-model="codeOtp[i - 1]" />
                        </template>
                    </div>
                    <button type="submit" class="w-full rounded-amalan-lg font-bold border border-solid text-center text-sm py-3.5 px-6" :class="[ !meta.valid ? 'bg-amalan-gray-4 border-amalan-gray-4 cursor-not-allowed text-amalan-gray-2' : 'cursor-pointer bg-amalan-blue-1 border-amalan-blue-1 hover:bg-amalan-blue-2 hover:border-amalan-blue-2 text-amalan-white' ]" :disabled="!meta.valid">Verifikasi</button>
                    <p v-if="!countdownIsDone" class="mt-4 text-2xs text-center text-amalan-gray-2">
                        Mohon tunggu
                        <VueCountdown ref="liveTimeOtp" :time="time" v-slot="{ totalSeconds }" @end="countdownEndEvent" class="text-xs text-amalan-blue-3 font-bold">
                            {{ totalSeconds }} detik
                        </VueCountdown>
                        untuk mengirim ulang
                    </p>
                    <p v-else class="mt-4 text-2xs text-center text-amalan-gray-2 font-semibold">
                        Tidak menerima kode OTP? <span @click="resendOtp" class="text-xs text-amalan-blue-1 font-bold cursor-pointer">Kirim ulang kode OTP</span>
                    </p>
                </Form>
            </div>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>