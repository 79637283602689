<template>
    <div class="justify-center flex">
        <div class="w-full max-w-lg shadow-xl min-h-screen h-auto">
            <Body />
        </div>
    </div>
</template>

<script>
import Body from '../Body';

export default {
    components: {
        Body,
    },
}
</script>