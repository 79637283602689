import SignUpOnboarding from '@/views/signup/Onboarding.vue'
import FormDataBank from '@/views/signup/form/DataBank.vue'
import FormDataUtang from '@/views/signup/form/DataUtang.vue'
import FormTandaTangan from '@/views/signup/form/TandaTangan.vue'
import FormPembayaranLayanan from '@/views/signup/form/PembayaranLayanan.vue'
import FormKonfirmasiLayanan from '@/views/signup/form/KonfirmasiLayanan.vue'

import UpdateSignupDataUtang from '@/views/signup/update-poa/DataUtang.vue'
import UpdateSignupTandaTangan from '@/views/signup/update-poa/TandaTangan.vue'
import UpdateSignupFinishPage from '@/views/signup/update-poa/FinishPage.vue'

export default [
    {
        path: '/sign-up/onboarding',
        name: 'Sign Up Onboarding',
        component: SignUpOnboarding,
        meta: {
            name: 'Sign Up Onboarding',
            isAuth: true,
            amalanSignUp: true,
            title: 'Sign Up Program Keringanan Utang'
        }
    },
    {
        path: '/sign-up/data-bank',
        name: 'Data Bank',
        component: FormDataBank,
        meta: {
            name: 'Data Bank',
            isAuth: true,
            amalanSignUp: true,
            title: 'Sign Up Program Keringanan Utang'
        }
    },
    {
        path: '/sign-up/data-utang',
        name: 'Data Utang',
        component: FormDataUtang,
        meta: {
            name: 'Data Utang',
            isAuth: true,
            amalanSignUp: true,
            title: 'Sign Up Program Keringanan Utang'
        }
    },
    {
        path: '/sign-up/tanda-tangan',
        name: 'Tanda Tangan',
        component: FormTandaTangan,
        meta: {
            name: 'Tanda Tangan',
            isAuth: true,
            amalanSignUp: true,
            title: 'Sign Up Program Keringanan Utang'
        }
    },
    {
        path: '/sign-up/pembayaran',
        name: 'Pembayaran Layanan',
        component: FormPembayaranLayanan,
        meta: {
            name: 'Pembayaran Layanan',
            isAuth: true,
            amalanSignUp: true,
            title: 'Sign Up Program Keringanan Utang'
        }
    },
    {
        path: '/sign-up/konfirmasi',
        name: 'Konfirmasi Layanan',
        component: FormKonfirmasiLayanan,
        meta: {
            name: 'Konfirmasi Layanan',
            isAuth: true,
            amalanSignUp: true,
            title: 'Sign Up Program Keringanan Utang'
        }
    },
    {
        path: '/update-signup/data-utang',
        name: 'Update Signup - Data Utang',
        component: UpdateSignupDataUtang,
        meta: {
            name: 'Update Signup - Data Utang',
            isAuth: true,
            title: 'Sign Up Program Keringanan Utang'
        }
    },
    {
        path: '/update-signup/tanda-tangan',
        name: 'Update Signup - Tanda Tangan',
        component: UpdateSignupTandaTangan,
        meta: {
            name: 'Update Signup - Tanda Tangan',
            isAuth: true,
            title: 'Sign Up Program Keringanan Utang'
        }
    },
    {
        path: '/update-signup/finish',
        name: 'Update Signup - Finish Page',
        component: UpdateSignupFinishPage,
        meta: {
            name: 'Update Signup - Finish Page',
            isAuth: true,
            title: 'Sign Up Program Keringanan Utang'
        }
    },
];