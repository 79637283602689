<script setup>
import { onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()

onMounted(() => {
    localStorage.setItem('from-onboarding-go-to', 'Negotiation Process - Target Pelunasan - Home')

    if(route.query.IdApproval) localStorage.setItem('negotiation-approval-id', route.query.IdApproval)

    router.push({ name : 'Login' })
})
</script>

<template>
    <div>
        <loading-overlay />
    </div>
</template>