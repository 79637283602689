<script setup>
import { onMounted, reactive, ref, inject } from 'vue'
import { Form, Field } from 'vee-validate'
import * as yup from 'yup'
import axios from "@/axios"
import { ChevronDownIcon, CheckIcon, XIcon } from '@heroicons/vue/solid'

const swal = inject("$swal")
const isLoading = ref(false)
const stepForm = ref(1)
const showResult = ref(false)
const FILE_SIZE = 49152 * 1024
const SUPPORTED_FORMATS = [
    "application/pdf"
]
const formValues = reactive({
    file: []
})
const fileData = reactive({
    fileName: null,
    fileSize: null
})
const signatureResult = ref(null)
const documentWithSignature = ref(true)

const swalError = swal.mixin({
    customClass: {
        confirmButton: 'bg-amalan-blue-1 mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-white text-sm font-semibold hover:bg-blue-900 hover:border-blue-900',
        cancelButton: 'absolute -top-3 -right-3'
    },
    buttonsStyling: false,
    title: '<img src="/img/alert-error-icon.svg" class="w-12 h-12 mx-auto">',
    width: '340px',
    allowOutsideClick: false,
    reverseButtons: true,
})

const validateSchema = yup.object({
    file: yup.mixed().required("Pilih file.")
    .test("fileSize", "Ukuran file maksimal 48 MB.", (value) => { if (value && value.length > 0) return value[0].size <= FILE_SIZE }).test("fileFormat", "Mohon pilih file pdf.", (value) => { if (value && value.length > 0) return SUPPORTED_FORMATS.includes(value[0].type) }),
})

const submitForm = () => {
    isLoading.value = true
    documentWithSignature.value = true
    fileData.fileName = formValues.file[0].name
    fileData.fileSize = formValues.file[0].size
    let data = new FormData()
    data.append('attachments[]', formValues.file[0])
    axios.post('/verify-pdf', data,{
        headers: {
            'Accept': 'multipart/form-data',
            'Content-Type': 'multipart/form-data'
        }
    })
    .then((resp) => {
        isLoading.value = false
        signatureResult.value = resp.data.data[0]
        stepForm.value++
    })
    .catch((err) => {
        formValues.file = []
        isLoading.value = false
        if(err.response.status == 400){
            if(err.response.data.message.includes('cannot find subfilter')){
                documentWithSignature.value = false
            } else {
                swalError.fire({
                    html: '<p class="text-center text-sm mt-2">Maaf, Server gagal mendapatkan data</p>',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                });
            }
        }else{
            swalError.fire({
                html: '<p class="text-center text-sm mt-2">Maaf, Server gagal mendapatkan data</p>',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
            });
        }
    })
}

const clickInputFile = () => {
    document.getElementById('file-input').click()
}

const handleDrop = (e) => {
    const data = e.dataTransfer
    const files = data.files

    formValues.file[0] = files[0]
}

const showResultClick = () => {
    showResult.value = !showResult.value
}

const buttonBack = () => {
    signatureResult.value = null
    fileData.fileName = null
    fileData.fileSize = null
    formValues.file = []
    showResult.value = false
    stepForm.value--
}

onMounted(() => {
    const dropArea = document.getElementById('drop-zone')
    const preventDefault = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, preventDefault, false)
    })

    ;['dragenter', 'dragover'].forEach(eventName => {
        dropArea.addEventListener(eventName, () => {
            dropArea.classList.add('bg-amalan-gray-5')
        }, false)
    })

    ;['dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, () => {
            dropArea.classList.remove('bg-amalan-gray-5')
        }, false)
    })

    dropArea.addEventListener('drop', handleDrop, false)
})

</script>

<template>
    <div class="w-full min-h-screen bg-amalan-yellow-100 text-amalan-black flex justify-center items-center">
        <div class="bg-amalan-white w-full max-w-xl min px-8 sm:px-14 py-14 rounded-lg mx-4 my-4">
            <img class="h-16 mx-auto" src="@/assets/img/logo-amalan.png">
            <p class="text-amalan-blue-1 text-center font-bold text-lg my-8">VERIFIKASI PDF</p>
            <template v-if="stepForm === 1">
                <Form
                    @submit="submitForm"
                    :validation-schema="validateSchema"
                    v-slot="{ errors }"
                    >
                    <div class="mb-10">
                        <div v-if="!documentWithSignature" class="py-2 px-2 w-full my-2" style="background-color: rgba(228, 28, 28, 0.12);"><p class="text-center font-bold text-sm text-amalan-red">TANDA TANGAN TIDAK DITEMUKAN / DOKUMEN TIDAK SAH</p></div>
                        <div class="flex flex-col justify-center items-center rounded-lg h-40 cursor-pointer border-2 border-dashed border-amalan-gray-3 hover:bg-amalan-gray-5" id="drop-zone" @click="clickInputFile">
                            <div v-if="formValues.file[0]" class="px-4 grid grid-cols-5">
                                <img src="@/assets/img/protect/thumbnail-pdf.png" class="w-20">
                                <div class="col-span-4 flex items-center">
                                    <p class="text-xs px-2">{{ formValues.file[0].name }}<br><span class="text-amalan-gray-2">{{ parseFloat(formValues.file[0].size / (1024 ** 2)).toFixed(2) }}MB</span></p>
                                </div>
                            </div>
                            <template v-else>
                                <img class="w-10" src="@/assets/img/upload-file.svg">
                                <p class="text-center mt-4 text-sm">Tarik dan letakkan PDF Anda disini</p>
                                <p class="text-center text-xs">atau KLIK untuk mengunggah dokumen</p>
                            </template>
                        </div>
                        <Field type="file" id="file-input" name="file" class="hidden" v-model="formValues.file" />
                        <div v-if="errors.file" class="mt-1 text-xs text-amalan-red">*{{errors.file}}</div>
                        <button type="submit" class="rounded-lg font-bold text-center text-sm py-2 w-full mt-8" :class="[ formValues.file[0] ? 'cursor-pointer text-amalan-white bg-amalan-blue-1 border-amalan-blue-1 hover:bg-blue-900 hover:border-blue-900' : 'text-amalan-gray-3 bg-amalan-gray-5 border-amalan-gray-5 cursor-not-allowed' ]" :disabled="!formValues.file[0]">Unggah PDF</button>
                    </div>
                </Form>
            </template>
            <template v-else>
                <div class="mb-10">
                    <div class="flex flex-col justify-center items-center rounded-lg h-40 border-2 border-dashed border-amalan-gray-3">
                        <div class="px-4 grid grid-cols-5">
                            <img src="@/assets/img/protect/thumbnail-pdf.png" class="w-20">
                            <div class="col-span-4 flex items-center">
                                <p class="text-xs px-2">{{ fileData.fileName }}<br><span class="text-amalan-gray-2">{{ parseFloat(fileData.fileSize / (1024 ** 2)).toFixed(2) }}MB</span></p>
                            </div>
                        </div>
                    </div>
                    <p class="text-amalan-blue-1 text-center font-bold text-sm my-6">DOKUMEN INI TELAH DITANDATANGANI SECARA DIGITAL</p>
                    <div v-if="signatureResult" class="w-full relative section" :class="[ showResult ? 'section-show' : 'section-hide']">
                        <div class="w-full rounded-lg border border-amalan-blue-1 cursor-pointer bg-amalan-white absolute z-20 hover:bg-amalan-gray-5" @click="showResultClick">
                            <div class="w-full flex justify-between items-center py-2 px-2">
                                <div v-if="signatureResult.amalan_verification_result" class="w-full flex justify-start items-center">
                                    <div class="w-4 h-4 bg-amalan-green flex justify-center items-center"><CheckIcon class="w-4 text-amalan-white" /></div>
                                    <p class="text-sm ml-2">Ditandatangani oleh {{ signatureResult.signatures[0].meta.certs[0].issuedTo.commonName ?? '-' }} <span class="text-amalan-green">(VALID)</span></p>
                                </div>
                                <div v-else class="w-full flex justify-start items-center">
                                    <div class="w-4 h-4 bg-amalan-red flex justify-center items-center"><XIcon class="w-4 text-amalan-white" /></div>
                                    <p class="text-sm ml-2">Ditandatangani oleh {{ signatureResult.signatures[0].meta.certs[0].issuedTo.commonName ?? '-' }} <span class="text-amalan-red">(TIDAK VALID)</span></p>
                                </div>
                                <ChevronDownIcon class="h-6 text-amalan-blue-1" :class="[ showResult ? 'chevron-open' : 'chevron-close' ]" />
                            </div>
                        </div>
                        <Transition name='dropdown'>
                            <div v-show="showResult" class="w-full rounded-b-lg border border-amalan-gray-4 pb-3 pt-12 xs:pt-8 px-4 mt-10 sm:mt-6 absolute z-10">
                                <div class="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 gap-3">
                                    <div class="w-full text-xs">
                                        <p class="font-bold text-amalan-gray-1">SIGNING TIME</p>
                                        <p class="mt-1 text-amalan-gray-2">{{ signatureResult.signing_time ? $filters.dateTimeFormatBasic(signatureResult.signing_time) : '-' }}</p>
                                    </div>
                                    <div class="w-full text-xs">
                                        <p class="font-bold text-amalan-gray-1">SIGNATURE</p>
                                        <div v-if="signatureResult.amalan_verification_result" class="w-full flex justify-start items-center mt-1">
                                            <div class="w-3 h-3 bg-amalan-green flex justify-center items-center"><CheckIcon class="w-full text-amalan-white" /></div>
                                            <p class="ml-1"><span class="text-amalan-green">Valid</span></p>
                                        </div>
                                        <div v-else class="w-full flex justify-start items-center mt-1">
                                            <div class="w-3 h-3 bg-amalan-red flex justify-center items-center"><XIcon class="w-full text-amalan-white" /></div>
                                            <p class="ml-1"><span class="text-amalan-red">Tidak Valid</span></p>
                                        </div>
                                    </div>
                                    <div class="w-full text-xs">
                                        <p class="font-bold text-amalan-gray-1">LOCATION</p>
                                        <p class="mt-1 text-amalan-gray-2">{{ signatureResult.signatures[0].meta.signatureMeta.location ?? '-' }}</p>
                                    </div>
                                    <div class="w-full text-xs">
                                        <p class="font-bold text-amalan-gray-1">REASON</p>
                                        <p class="mt-1 text-amalan-gray-2">{{ signatureResult.signatures[0].meta.signatureMeta.reason ?? '-' }}</p>
                                    </div>
                                    <div class="w-full text-xs">
                                        <p class="font-bold text-amalan-gray-1">CERT VALID FROM</p>
                                        <p class="mt-1 text-amalan-gray-2">{{ signatureResult.signatures[0].meta.certs[0].validityPeriod.notBefore ? $filters.dateTimeFormatBasic(signatureResult.signatures[0].meta.certs[0].validityPeriod.notBefore) : '-' }}</p>
                                    </div>
                                    <div class="w-full text-xs">
                                        <p class="font-bold text-amalan-gray-1">CERT VALID UNTIL</p>
                                        <p class="mt-1 text-amalan-gray-2">{{ signatureResult.signatures[0].meta.certs[0].validityPeriod.notAfter ? $filters.dateTimeFormatBasic(signatureResult.signatures[0].meta.certs[0].validityPeriod.notAfter) : '-' }}</p>
                                    </div>
                                </div>
                                <div class="my-4">
                                    <div class="w-full bg-amalan-gray-5 py-1">
                                        <p class="text-center text-sm font-bold text-amalan-blue-1">PENERBIT SERTIFIKAT</p>
                                    </div>
                                    <div class="grid grid-cols-1 xs:grid-cols-3 text-xs border-b border-amalan-gray-5 py-2 px-2">
                                        <p class="text-amalan-gray-1 font-bold">Country</p>
                                        <p class="text-amalan-gray-2 xs:col-span-2">{{ signatureResult.signatures[0].meta.certs[0].issuedBy.countryName ?? '-' }}</p>
                                    </div>
                                    <div class="grid grid-cols-1 xs:grid-cols-3 text-xs border-b border-amalan-gray-5 py-2 px-2">
                                        <p class="text-amalan-gray-1 font-bold">Organization</p>
                                        <p class="text-amalan-gray-2 xs:col-span-2">{{ signatureResult.signatures[0].meta.certs[0].issuedBy.organizationName ?? '-' }}</p>
                                    </div>
                                    <div class="grid grid-cols-1 xs:grid-cols-3 text-xs border-b border-amalan-gray-5 py-2 px-2">
                                        <p class="text-amalan-gray-1 font-bold">Organization Unit</p>
                                        <p class="text-amalan-gray-2 xs:col-span-2">{{ signatureResult.signatures[0].meta.certs[0].issuedBy.organizationalUnitName ?? '-' }}</p>
                                    </div>
                                    <div class="grid grid-cols-1 xs:grid-cols-3 text-xs border-b border-amalan-gray-5 py-2 px-2">
                                        <p class="text-amalan-gray-1 font-bold">Common Name</p>
                                        <p class="text-amalan-gray-2 xs:col-span-2">{{ signatureResult.signatures[0].meta.certs[0].issuedBy.commonName ?? '-' }}</p>
                                    </div>
                                </div>
                                <div class="mt-4">
                                    <div class="w-full bg-amalan-gray-5 py-1">
                                        <p class="text-center text-sm font-bold text-amalan-blue-1">DETAIL SERTIFIKAT</p>
                                    </div>
                                    <div class="grid grid-cols-1 xs:grid-cols-3 text-xs border-b border-amalan-gray-5 py-2 px-2">
                                        <p class="text-amalan-gray-1 font-bold">Country</p>
                                        <p class="text-amalan-gray-2 xs:col-span-2">{{ signatureResult.signatures[0].meta.certs[0].issuedTo.countryName ?? '-' }}</p>
                                    </div>
                                    <div class="grid grid-cols-1 xs:grid-cols-3 text-xs border-b border-amalan-gray-5 py-2 px-2">
                                        <p class="text-amalan-gray-1 font-bold">Organization Unit</p>
                                        <p class="text-amalan-gray-2 xs:col-span-2">{{ signatureResult.signatures[0].meta.certs[0].issuedTo.organizationalUnitName ?? '-' }}</p>
                                    </div>
                                    <div class="grid grid-cols-1 xs:grid-cols-3 text-xs border-b border-amalan-gray-5 py-2 px-2">
                                        <p class="text-amalan-gray-1 font-bold">Common Name</p>
                                        <p class="text-amalan-gray-2 xs:col-span-2">{{ signatureResult.signatures[0].meta.certs[0].issuedTo.commonName ?? '-' }}</p>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                    <button @click="buttonBack" type="button" class="rounded-lg font-bold text-center text-sm py-2 mt-4 w-full text-amalan-gray-1 bg-amalan-white border border-amalan-gray-3">Kembali</button>
                </div>
            </template>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>

<style scoped>
.dropdown-enter-active,
.dropdown-leave-active {
    transition: all 0.2s ease;
    opacity: 1;
}

.dropdown-enter-from,
.dropdown-leave-to {
    opacity: 0;
    transition: all 0.2s ease;
}

.chevron-open {
    transform: rotate( -180deg );            
    transition: transform 0.3s ease;
}

.chevron-close {
    transform: rotate( -360deg );            
    transition: transform 0.3s ease;
}

.section {
    overflow: hidden;
    transition: min-height 0.4s ease-in-out;
    height: auto;
}

.section-hide {
    min-height: 100px;
}

.section-show {
    min-height: 870px;
}

@media (min-width: 640px){
    .section-show {
        min-height: 500px;
    }

    .section-hide {
        min-height: 50px;
    }
}

@media (min-width: 390px){
    .section-show {
        min-height: 650px;
    }

    .section-hide {
        min-height: 70px;
    }
}
</style>