<script setup>
import { defineProps, defineEmits } from 'vue'
import DoneBanner from '@/components/icon/DoneBanner'

const props = defineProps({
    visible: Boolean,
    content: String,
    animationMark: String,
    withButton: Boolean,
    buttonText: String
})

const emit = defineEmits(['action'])

const modalClick = () => {
    if(props.withButton) return
    return emit('action')
}

const buttonClick = () => emit('action')

</script>

<template>
    <Transition name="fade-fast">
        <div v-show="props.visible" class="fixed inset-0 z-50">
            <div class="w-full bg-black bg-opacity-50 p-6 min-h-screen max-w-lg mx-auto flex justify-center items-center">
                <div class="w-full py-8 px-4 bg-amalan-white rounded-lg -mt-10" :class="[ !props.withButton ? 'cursor-pointer' : '' ]" @click="modalClick">
                    <div class="w-full flex justify-center items-center mt-6">
                        <Transition name="fade-fast">
                            <DoneBanner v-if="props.visible" :is-fail="props.animationMark == 'check' ? false : true" />
                        </Transition>
                    </div>
                    <p class="mt-8 text-amalan-black text-xs text-center" v-html="props.content"></p>
                    <button v-if="props.withButton" @click="buttonClick" class="w-full mt-8 py-3 text-center text-sm font-bold bg-amalan-blue-1 text-amalan-white border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md hover:bg-amalan-blue-2">{{ props.buttonText }}</button>
                </div>
            </div>
        </div>
    </Transition>
</template>

<style scoped>
.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.3s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.3s ease
}
</style>