<template>
    <div class="text-center mt-5 pt-10 pb-44">
        <p class="text-7xl text-yellow-400 mt-2">404</p>
        <p class="text-xl text-red-600 mt-3">Opps, laman yang Anda cari tidak ditemukan.</p>
        <div class="justify-center flex">
            <img src="../assets/img/404.png" class="w-60">
        </div>
        <p class="text-md text-gray-400 my-4">Ada kemungkinan laman yang Anda cari sudah dipindahkan atau sudah dihapus.</p>
        <router-link to="/home" type="button" class="text-sm text-white font-semibold border-2 p-3 rounded-lg bg-blue-700 hover:bg-blue-400 inline-flex items-center">
            <ArrowCircleLeftIcon class="w-10 h-10" />
            <span>HOME</span>
        </router-link>
    </div>
</template>

<script>
import { ArrowCircleLeftIcon } from '@heroicons/vue/solid';

export default {
    components: {
        ArrowCircleLeftIcon,
    }
}
</script>