<script setup>
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, inject } from 'vue'
import NavbarWithBackV2 from '@/components/NavbarWithBackV2.vue'
import NegotiationResultCard from './Components/NegotiationResultCard.vue'
import opportunityHelper from '@/helpers/opportunity-helper'

const router = useRouter()
const route = useRoute()
const store = useStore()
const filters = inject('Filters')

const negotiationResults = computed(() => store.getters['negotiationResult/getNegotiationResultApproval'])

const goToNegotiationResultDetail = (Id) => router.push({ name: 'Negotiation Process - Result - Detail', params: { negotiationId: Id } })

const createDescription = (due_date) => {
    if(!due_date) return '-'

    const line1 = 'Konfirmasi sebelum ' + filters.dateLongDayAndDMY(due_date)
    const line2 = filters.diffDateOnDays(filters.dateNow(), filters.dateTimeFormatBasic(due_date)) == 0
        ? `<br>pukul ${filters.dateTimeFormatOnlyHourMinute(due_date)} WIB (HARI INI)`
        : ''

    return line1 + line2
}

</script>

<template>
    <div class="min-h-screen bg-amalan-white relative">
        <NavbarWithBackV2 :title="route.meta.title" @back-button="router.push({ name: 'Negotiation Process - Home' })" />
        <div class="w-full pt-8 pb-20 px-6 bg-amalan-white">
            <p class="text-center text-amalan-black text-xs sm:text-sm">Anda dapat melihat detail hasil negosiasi pinjaman<br>Anda dan menyetujui hasilnya</p>
            <div v-if="negotiationResults.data.length > 0" class="w-full mt-8 grid grid-cols-1 gap-y-4">
                <NegotiationResultCard
                    v-for="(data, index) in negotiationResults.data" :key="index"
                    :title="'Hasil Negosiasi'"
                    :description="createDescription(data.Customer_Feedback_Due_Date__c)"
                    :bank-name="data.Opportunity__r.Name ? opportunityHelper.getBankName(data.Opportunity__r.Name, data.Opportunity__r.Loan_Type_2__c) : '-'"
                    :image-source="require('@/assets/img/bank-lender-icon/' + opportunityHelper.getIcon(data.Opportunity__r.Name, data.Opportunity__r.Loan_Type_2__c))"
                    :loan-type="data.Opportunity__r.Loan_Type_2__c ? opportunityHelper.getLoanTitle(data.Opportunity__r.Loan_Type_2__c) : '-'"
                    :outstanding="data.Opportunity__r.Estimated_Outstanding_Amount__c ? $filters.format_number(data.Opportunity__r.Estimated_Outstanding_Amount__c) : '-'"
                    :button-text="'Lihat Selengkapnya'"
                    :is-priority="index === 0 ? true : false"
                    :is-primary="true"
                    @on-action="goToNegotiationResultDetail(data.Id)"
                />
            </div>
            <div v-else class="w-full px-6 flex flex-col justify-center items-center pt-34">
                <img src="@/assets/img/clipboard-v2.svg" class="w-40 mx-auto">
                <p class="text-center text-xs mt-4">Mohon maaf, Anda belum memiliki hasil negosiasi<br>yang harus dikonfirmasi.</p>
            </div>
        </div>
        <loading-overlay v-if="!negotiationResults.isFetched" />
    </div>
</template>