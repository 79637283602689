import amalansignupApi from "@/axios/amalansignup"

const state = () => ({
    touched_opportunities: [],
    new_opportunities: [],
    payment_page_data: {
        page_type: 'Add',
        isTouched: false,
        pricing_package_name: null,
        payment_purpose: null,
        payment_amount: null,
        discount: null,
        payment_expiration_date: null,
        payment_link: null,
        total_outstanding: null
    },
    last_additional_suf: {
        isFetching: false,
        status: null,
        data: null
    },
    last_installment_payment: {
        isFetching: false,
        status: null,
        data: null
    },
    history_additional_suf: {
        pending: [],
        done: []
    }
})

const getters = {
    getTouchedOpportunities(state) {
        return state.touched_opportunities
    },
    getNewOpportunities(state) {
        return state.new_opportunities
    },
    getPaymentPageData(state) {
        return state.payment_page_data
    },
    getLastAdditionalSufStatus(state) {
        return state.last_additional_suf.status
    },
    getLastAdditionalSufFetchStatus(state) {
        return state.last_additional_suf.isFetching
    },
    getLastAdditionalSuf(state) {
        return state.last_additional_suf
    },
    getLastInstallmentPaymentStatus(state) {
        return state.last_installment_payment.status
    },
    getLastInstallmentPaymentFetchStatus(state) {
        return state.last_installment_payment.isFetching
    },
    getLastInstallmentPayment(state) {
        return state.last_installment_payment
    },
    getHistoryAdditionalSuf(state) {
        return state.history_additional_suf
    }
}

const actions = {
    storeTouchedOpportToDocCollectionState({ state, dispatch }){
        state.touched_opportunities.forEach((value) => dispatch('docCollection/editOpportunityState', value, { root: true }))
    },
    setTouchedOpportunity({ dispatch, commit, rootGetters }, payload){
        const opportunities = rootGetters['docCollection/getOpportunities'] ?? []
        const findOpportDataIndex = opportunities.findIndex((value) => value.Id === payload.Id)
        if(findOpportDataIndex < 0) return

        if(
            opportunities[findOpportDataIndex].Estimated_Outstanding_Amount__c != payload.outstandingAmount ||
            opportunities[findOpportDataIndex].Month_Overdue_input_by_Lead__c != payload.overdue ||
            opportunities[findOpportDataIndex].Last_Payment_Date__c != payload.lastPaymentDate ||
            opportunities[findOpportDataIndex].Account_Number__c != payload.accountNumber
        ){
            const data = {
                opportId: payload.Id,
                accNumber: payload.accountNumber,
                outstandingAmount: payload.outstandingAmount,
                overdue: payload.overdue,
                lastPaymentDate: payload.lastPaymentDate
            }

            dispatch('docCollection/editOpportunityState', data, { root: true })
            commit('setTouchedOpportunity', data)
        }
    },
    removeTouchedOpportunityById({ commit }, payload){
        commit('removeTouchedOpportunityById', payload)
    },
    removeTouchedOpportunities({ commit }){
        commit('removeTouchedOpportunities')
    },
    setNewOpportunity({ commit }, payload){
        commit('setNewOpportunity', payload)
    },
    removeNewOpportunityByIndex({ commit }, payload){
        commit('removeNewOpportunityByIndex', payload)
    },
    removeNewOpportunities({ commit }){
        commit('removeNewOpportunities')
    },
    setPaymentData({ commit }, payload){
        commit('setPaymentData', payload)
    },
    removePaymentData({ commit }){
        commit('removePaymentData')
    },
    async requestLastAdditionalSuf({state, dispatch, commit, rootGetters}){
        try {
            state.last_additional_suf.isFetching = true
            const {status, data} = await amalansignupApi.get('loan-management/last-additional-suf')
            state.last_additional_suf.isFetching = false
            commit('setLastAdditionalSuf', {status, data})
            dispatch('pendingUserActivity/setPendingActivityData', {
                userStage: rootGetters['user/getUserStage'],
                signUpStatus: rootGetters['contact/getSignUpStatus'],
                lastAmalanProtect: rootGetters['formAmalanProtect/getLastProtect'],
                latestService: rootGetters['services/getLatestService'],
                timeToDocCollection: rootGetters['docCollection/getTimeToDocCollection'],
                billCompleted: rootGetters['docCollection/getbillStatus'],
                docCompleted: rootGetters['docCollection/getDocStatus'],
                poaDocument: rootGetters['user/getDocumentPoa'],
                lastAdditionalSuf: rootGetters['additionalSUF/getLastAdditionalSuf'],
                lastUpgradePackage: rootGetters['upgradePricingPackage/getLastUpgradePackage'],
                negotiationResultApprovalFlag: rootGetters['negotiationResult/getNegotiationResultApprovalFlag']
            }, {root: true})
            return
        } catch (error) {
            state.last_additional_suf.isFetching = false
            return commit('setLastAdditionalSuf', {status: error.response.status ?? 404, data: null})
        }
    },
    async requestLastInstallmentPayment({state, commit}){
        try {
            state.last_installment_payment.isFetching = true
            const {status, data} = await amalansignupApi.get('loan-management/last-payment-installment')
            state.last_installment_payment.isFetching = false
            return commit('setLastInstallmentPayment', {status, data})
        } catch (error) {
            state.last_installment_payment.isFetching = false
            return commit('setLastInstallmentPayment', {status: error.response.status ?? 404, data: null})
        }
    },
    async storeNewOpportunities({state, commit}){
        if(state.new_opportunities.length === 0) return

        try {
            await amalansignupApi.post('salesforce/lead-qualification/opportunities/batch', {
                loans: state.new_opportunities
            })
        } catch (error) {
            console.log(error)
        }

        return commit('removeNewOpportunities')
    },
    async requestPurchaseHistory({ commit }) {
        try {
            const {status, data} = await amalansignupApi.get('loan-management/history-additional-suf')
            return commit('setPurchaseHistory', {status, data: data?.data})
        } catch (error) {
            return commit('setPurchaseHistory', {status: error.response.status ?? 404, data: null})
        }
    }
}

const mutations = {
    setTouchedOpportunity(state, payload){
        const findTouchedOpportDataIndex = state.touched_opportunities.findIndex((value) => value.opportId === payload.opportId)
        if(findTouchedOpportDataIndex < 0) return state.touched_opportunities.push(payload)
        return state.touched_opportunities[findTouchedOpportDataIndex] = payload
    },
    removeTouchedOpportunityById(state, payload){
        const findTouchedOpportDataIndex = state.touched_opportunities.findIndex((value) => value.opportId === payload.Id)
        if(findTouchedOpportDataIndex < 0) return
        return state.touched_opportunities.splice(findTouchedOpportDataIndex, 1)
    },
    removeTouchedOpportunities(state){
        state.touched_opportunities = []
    },
    setNewOpportunity(state, payload){
        if(payload.index === null) return state.new_opportunities.push(payload.data)
        return state.new_opportunities[payload.index] = payload.data
    },
    removeNewOpportunityByIndex(state, payload){
        return state.new_opportunities.splice(payload, 1)
    },
    removeNewOpportunities(state){
        state.new_opportunities = []
    },
    setPaymentData(state, payload){
        state.payment_page_data.page_type = payload.page_type ? payload.page_type : state.payment_page_data.page_type
        state.payment_page_data.isTouched = true
        state.payment_page_data.pricing_package_name = payload.pricing_package_name ?? null
        state.payment_page_data.payment_purpose = payload.payment_purpose ?? null
        state.payment_page_data.payment_amount = payload.payment_amount ?? null
        state.payment_page_data.discount = payload.discount ?? null
        state.payment_page_data.payment_expiration_date = payload.payment_expiration_date ?? null
        state.payment_page_data.payment_link = payload.payment_link ?? null
        state.payment_page_data.total_outstanding = payload.total_outstanding ?? null
    },
    removePaymentData(state){
        state.payment_page_data.page_type = 'Add'
        state.payment_page_data.isTouched = false
        state.payment_page_data.pricing_package_name = null
        state.payment_page_data.payment_purpose = null
        state.payment_page_data.payment_amount = null
        state.payment_page_data.discount = null
        state.payment_page_data.payment_expiration_date = null
        state.payment_page_data.payment_link = null
        state.payment_page_data.total_outstanding = null
    },
    setLastAdditionalSuf(state, payload){
        state.last_additional_suf.status = payload.status
        state.last_additional_suf.data = payload.data?.data
    },
    setLastInstallmentPayment(state, payload){
        state.last_installment_payment.status = payload.status
        state.last_installment_payment.data = payload.data?.data
    },
    setPurchaseHistory(state, payload){
        if(!payload.data) return
        if(payload.data.length === 0) return

        state.history_additional_suf.pending = []
        state.history_additional_suf.done = []

        const reversedData = payload.data.reverse()

        const tempPending = []
        const tempDone = []
        reversedData.forEach((value) => {
            if(!value.Payment_Status__c || value.Payment_Status__c === 'Open') return tempPending.push(value)
            return tempDone.push(value)
        })

        state.history_additional_suf.pending = tempPending
        state.history_additional_suf.done = tempDone
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
