
<script setup>
import { onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { CheckIcon } from '@heroicons/vue/solid'
import axios from '@/axios'

const store = useStore()
const router = useRouter()
const route = useRoute()

const lastLoanConsolidation = computed(() => store.getters['loanConsolidation/getLastLoanConsolidation'])
const getInformasiUmumForm = computed(() => store.getters['loanConsolidation/getInformasiUmumForm'])

const informasiUmumForm = reactive({
    question_one: null,
    question_two: null,
    question_three: null,
})

const informasiUmumFormSchema = yup.object().shape({
    question_one: yup.string().nullable().required('Silahkan dipilih').oneOf(['yes', 'no'], 'Silahkan dipilih'),
    question_two: yup.string().nullable().required('Silahkan dipilih').oneOf(['yes', 'no'], 'Silahkan dipilih'),
    question_three: yup.string().nullable().required('Silahkan dipilih').oneOf(['yes', 'no'], 'Silahkan dipilih')
})

const submitHandler = () => {
    store.dispatch('loanConsolidation/setInformasiUmumForm', {...informasiUmumForm})
    return router.push({name:'Loan Consolidation - Registrasi - Data Diri'})
}

const prepopulateFormData = () => {
    informasiUmumForm.question_one = getInformasiUmumForm.value.question_one ?? null
    informasiUmumForm.question_two = getInformasiUmumForm.value.question_two ?? null
    informasiUmumForm.question_three = getInformasiUmumForm.value.question_three ?? null
}

const appliedProgramKBK = () => axios.post('/customer/applied-program', { applied_program: 'KBK' })

onMounted(() => {
    prepopulateFormData()
    appliedProgramKBK()

    if(lastLoanConsolidation.value.status === 404) return
    if(lastLoanConsolidation.value.status === 201 || lastLoanConsolidation.value.status === 202)
        return router.push({ name: 'Loan Consolidation - Registrasi - Daftar Pinjaman'})
    if(lastLoanConsolidation.value.status === 200) {
        const consolidationInitialResult = lastLoanConsolidation.value.data.data.Loan_Consolidation_Initial_Results__c ? lastLoanConsolidation.value.data.data.Loan_Consolidation_Initial_Results__c.toUpperCase() : null
        const isNotEligible = consolidationInitialResult ? consolidationInitialResult.includes('NOT ELIGIBLE') : null

        if(isNotEligible) return router.push({ name: 'Loan Consolidation - Registrasi - Qualification'})
        return router.push({ name: 'Loan Consolidation - Registrasi - Finish Page'})
    }

    return router.push({ name: 'Home'})
})
</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="router.push({name: 'Landing Page Loan Consolidation'})" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold text-amalan-black text-center">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full min-h-screen bg-amalan-white px-6 pb-24">
            <div class="w-full py-8 flex justify-between items-center">
                <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                    <span class="text-sm text-amalan-blue-1 font-bold">1</span>
                </div>
                <p class="flex-none text-amalan-blue-1 text-sm font-bold mx-1">Informasi Umum</p>
                <div class="bg-amalan-blue-1 h-px flex-grow"></div>
                <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-gray-4 mx-1">
                    <span class="text-sm font-bold text-amalan-gray-2">2</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3"></div>
                <div class="w-6 h-6 rounded-full bg-amalan-gray-4 flex justify-center items-center flex-none ml-1">
                    <span class="text-sm text-amalan-gray-2 font-bold">3</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3"></div>
                <div class="w-6 h-6 rounded-full bg-amalan-gray-4 flex justify-center items-center flex-none ml-1">
                    <span class="text-sm text-amalan-gray-2 font-bold">4</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3"></div>
                <div class="w-6 h-6 rounded-full bg-amalan-gray-4 flex justify-center items-center flex-none ml-1">
                    <span class="text-sm text-amalan-gray-2 font-bold">5</span>
                </div>
            </div>
            <p class="text-xs text-amalan-black text-center">Lengkapi pertanyaan berikut<br>sesuai dengan informasi yang Anda miliki</p>
            <Form
            :validation-schema="informasiUmumFormSchema"
            v-slot="{meta}"
            @submit="submitHandler"
            >
                <div class="w-full grid grid-cols-1 gap-y-3.5 py-8">
                    <div class="w-full">
                        <p class="text-sm text-amalan-blue-1 font-bold">Apakah Anda merasa gaji Anda habis untuk membayar cicilan setiap bulannya?</p>
                        <div class="w-full py-2 px-4 grid grid-cols-2 gap-x-3.5 border border-solid border-amalan-gray-4 rounded-lg mt-1.5">
                            <div @click="informasiUmumForm.question_one = 'yes'" class="w-full py-2 flex justify-start items-center gap-2 cursor-pointer">
                                <div class="w-4 h-4 p-0 rounded-full flex justify-center items-center" :class="[ informasiUmumForm.question_one == 'yes' ? 'bg-amalan-yellow-400' : 'bg-amalan-gray-5']">
                                    <CheckIcon class="w-3 h-3" :class="[ informasiUmumForm.question_one == 'yes' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]" />
                                </div>
                                <p class="text-amalan-black text-xs">Ya</p>
                                <Field name="question_one" type="radio" class="hidden" value="yes" :validateOnInput="true" v-model="informasiUmumForm.question_one" />
                            </div>
                            <div @click="informasiUmumForm.question_one = 'no'" class="w-full py-2 flex justify-start items-center gap-2 cursor-pointer">
                                <div class="w-4 h-4 p-0 rounded-full flex justify-center items-center" :class="[ informasiUmumForm.question_one == 'no' ? 'bg-amalan-yellow-400' : 'bg-amalan-gray-5']">
                                    <CheckIcon class="w-3 h-3" :class="[ informasiUmumForm.question_one == 'no' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]" />
                                </div>
                                <p class="text-amalan-black text-xs">Tidak</p>
                                <Field name="question_one" type="radio" class="hidden" value="no" :validateOnInput="true" v-model="informasiUmumForm.question_one" />
                            </div>
                        </div>
                    </div>
                    <div class="w-full">
                        <p class="text-sm text-amalan-blue-1 font-bold">Apakah Anda ingin tahu bagaimana cara untuk mengurangi cicilan bulanan hingga 80%?</p>
                        <div class="w-full py-2 px-4 grid grid-cols-2 gap-x-3.5 border border-solid border-amalan-gray-4 rounded-lg mt-1.5">
                            <div @click="informasiUmumForm.question_two = 'yes'" class="w-full py-2 flex justify-start items-center gap-2 cursor-pointer">
                                <div class="w-4 h-4 p-0 rounded-full flex justify-center items-center" :class="[ informasiUmumForm.question_two == 'yes' ? 'bg-amalan-yellow-400' : 'bg-amalan-gray-5']">
                                    <CheckIcon class="w-3 h-3" :class="[ informasiUmumForm.question_two == 'yes' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]" />
                                </div>
                                <p class="text-amalan-black text-xs">Ya</p>
                                <Field name="question_two" type="radio" class="hidden" value="yes" :validateOnInput="true" v-model="informasiUmumForm.question_two" />
                            </div>
                            <div @click="informasiUmumForm.question_two = 'no'" class="w-full py-2 flex justify-start items-center gap-2 cursor-pointer">
                                <div class="w-4 h-4 p-0 rounded-full flex justify-center items-center" :class="[ informasiUmumForm.question_two == 'no' ? 'bg-amalan-yellow-400' : 'bg-amalan-gray-5']">
                                    <CheckIcon class="w-3 h-3" :class="[ informasiUmumForm.question_two == 'no' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]" />
                                </div>
                                <p class="text-amalan-black text-xs">Tidak</p>
                                <Field name="question_two" type="radio" class="hidden" value="no" :validateOnInput="true" v-model="informasiUmumForm.question_two" />
                            </div>
                        </div>
                    </div>
                    <div class="w-full">
                        <p class="text-sm text-amalan-blue-1 font-bold">Apakah Anda membutuhkan tambahan dana untuk keperluan lainnya?</p>
                        <div class="w-full py-2 px-4 grid grid-cols-2 gap-x-3.5 border border-solid border-amalan-gray-4 rounded-lg mt-1.5">
                            <div @click="informasiUmumForm.question_three = 'yes'" class="w-full py-2 flex justify-start items-center gap-2 cursor-pointer">
                                <div class="w-4 h-4 p-0 rounded-full flex justify-center items-center" :class="[ informasiUmumForm.question_three == 'yes' ? 'bg-amalan-yellow-400' : 'bg-amalan-gray-5']">
                                    <CheckIcon class="w-3 h-3" :class="[ informasiUmumForm.question_three == 'yes' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]" />
                                </div>
                                <p class="text-amalan-black text-xs">Ya</p>
                                <Field name="question_three" type="radio" class="hidden" value="yes" :validateOnMount="true" :validateOnInput="true" v-model="informasiUmumForm.question_three" />
                            </div>
                            <div @click="informasiUmumForm.question_three = 'no'" class="w-full py-2 flex justify-start items-center gap-2 cursor-pointer">
                                <div class="w-4 h-4 p-0 rounded-full flex justify-center items-center" :class="[ informasiUmumForm.question_three == 'no' ? 'bg-amalan-yellow-400' : 'bg-amalan-gray-5']">
                                    <CheckIcon class="w-3 h-3" :class="[ informasiUmumForm.question_three == 'no' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]" />
                                </div>
                                <p class="text-amalan-black text-xs">Tidak</p>
                                <Field name="question_three" type="radio" class="hidden" value="no" :validateOnMount="true" :validateOnInput="true" v-model="informasiUmumForm.question_three" />
                            </div>
                        </div>
                    </div>
                </div>
                <button type="submit" class="w-full mt-8 rounded-amalan-lg font-bold text-center text-sm p-3" :class="[ meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!meta.valid">Selanjutnya</button>
            </Form>
        </div>
    </div>
</template>