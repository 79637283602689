const getBrowser = () => {
    const userAgent = navigator.userAgent
    let browserName = null
            
    if(userAgent.match(/chrome|chromium|crios/i)){
        if(userAgent.match(/opr\//i)){
            browserName = "opera"
        }else{
            browserName = "chrome"
        }
    }else if(userAgent.match(/firefox|fxios/i)){
        browserName = "firefox"
    }  else if(userAgent.match(/safari/i)){
        browserName = "safari"
    }else if(userAgent.match(/opr\//i)){
        browserName = "opera"
    } else if(userAgent.match(/edg/i)){
        browserName = "edge"
    }else{
        browserName = null
    }

    return browserName
}

export default getBrowser