import amalansignupApi from "@/axios/amalansignup";

const state = () => ({
    opportunities: {
        is_fetched: false,
        is_fetching: false,
        data: [],
        amount: null
    },
    update_signup_poa_status: {
        is_fetching: false,
        status: null,
        data: null
    }
});

const getters = {
    getOpportunities(state) {
        return state.opportunities;
    },
    getUpdateSignupPoa(state) {
        return state.update_signup_poa_status;
    },
};

const actions = {
    async requestOpportunities({state, commit}){
        try {
            state.opportunities.is_fetching = true
            const {status, data} = await amalansignupApi.get('loan-management/opportunity/stage-signed-up')
            state.opportunities.is_fetching = false
            state.opportunities.is_fetched = true
            commit('setOpportunities', {status, data})
        } catch (error) {
            state.opportunities.is_fetching = false
            return commit('setOpportunities', {status: error.response.status ?? 404, data: []})
        }
    },
    async requestUpdateSignupPoa({state, commit}){
        try {
            state.update_signup_poa_status.is_fetching = true
            const {status, data} = await amalansignupApi.get('loan-management/last-update-signup-poa')
            state.update_signup_poa_status.is_fetching = false
            commit('setUpdateSignupPoa', {status, data})
        } catch (error) {
            state.update_signup_poa_status.is_fetching = false
            return commit('setUpdateSignupPoa', {status: error.response.status ?? 404, data: null})
        }
    },
};

const mutations = {
    setOpportunities(state, payload) {
        const opportunities = payload.data.data ?? []
        state.opportunities.data = opportunities
        state.opportunities.amount = opportunities.reduce((totalAmount, opportunity) => totalAmount + (opportunity.Estimated_Outstanding_Amount__c ?? 0), 0)
    },
    setUpdateSignupPoa(state, payload){
        state.update_signup_poa_status.status = payload.status
        state.update_signup_poa_status.data = payload.data
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
