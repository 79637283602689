import { useCookies } from '@vueuse/integrations/useCookies'

export const getPartnerId = () => {
    const { get } = useCookies('partnero-partner-id')
    const result = get('partnero-partner-id')
    return result
}

export const removePartnerId = () => {
    const { remove } = useCookies('partnero-partner-id')
    remove('partnero-partner-id', {path: "/"})
}