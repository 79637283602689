<script setup>
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onMounted, ref, inject } from 'vue'
import NavbarWithBackV2 from '@/components/NavbarWithBackV2.vue'
import opportunityHelper from '@/helpers/opportunity-helper'
import OpportunityCard from '@/components/Card/OpportunityCard.vue'

const router = useRouter()
const route = useRoute()
const store = useStore()
const filters = inject('Filters')

const negotiationResults = computed(() => store.getters['negotiationResult/getNegotiationResultHistory'])
const negotiationResultConfig = computed(() => store.getters['negotiationResult/getConfig'])

const opportunity = ref(null)

const goToNegotiationResultDetail = (Id) => router.push({ name: 'Negotiation Process - Result - History Detail', params: { opportunityId: route.params.opportunityId, negotiationId: Id } })

const findOpportunity = () => {
    const opport = negotiationResults.value.data.find((value) => value.Id == route.params.opportunityId)
    if(!opport) return router.push({ name: 'Negotiation Process - Result - History' })
    return opportunity.value = opport
}

const findClassConfig = (status) => {
    const result = negotiationResultConfig.value.find((value) => value.status.toUpperCase() === status.toUpperCase())
    if(!result) return ''
    return result.class
}

const findTextConfig = (status) => {
    const result = negotiationResultConfig.value.find((value) => value.status.toUpperCase() === status.toUpperCase())
    if(!result) return ''
    return result.text.toUpperCase()
}

const constructConfirmationDate = (status, date) => {
    if(status == 'Overdue') return 'Tidak Dikonfirmasi'
    return 'Dikonfirmasi ' + filters.formatDateOnlyDate(date)
}

onMounted(() => {
    if(!route.params.opportunityId) return router.push({ name: 'Negotiation Process - Result - History' })
    findOpportunity()
})
</script>

<template>
    <div class="min-h-screen bg-amalan-white">
        <NavbarWithBackV2 :title="route.meta.title" @back-button="router.push({ name: 'Negotiation Process - Result - History' })" />
        <div v-if="opportunity" class="w-full pt-8 pb-20 px-6 bg-amalan-white">
            <OpportunityCard
                :with-action="false"
                :bank-name="opportunity.Name ? opportunityHelper.getBankName(opportunity.Name, opportunity.Loan_Type_2__c) : '-'"
                :loan-type="opportunity.Loan_Type_2__c ? opportunityHelper.getLoanTitle(opportunity.Loan_Type_2__c) : '-'"
                :outstanding="opportunity.Estimated_Outstanding_Amount__c ? $filters.format_number(opportunity.Estimated_Outstanding_Amount__c) : '-'"
                :image-source="require('@/assets/img/bank-lender-icon/' + opportunityHelper.getIcon(opportunity.Name, opportunity.Loan_Type_2__c))"
            />
            <p class="text-center my-8 text-amalan-black text-xs">Berikut riwayat persetujuan hasil negosiasi pinjaman<br>Anda yang telah dikonfirmasi</p>
            <div v-for="(history, index) in opportunity.Opportunity_Approval__r.records" :key="index" class="w-full mt-4 p-4 rounded-lg border border-amalan-gray-4 bg-amalan-white">
                <div class="w-full flex justify-between items-center">
                    <p class="text-sm font-bold" :class="findClassConfig(history.Customer_Feedback__c)">{{ findTextConfig(history.Customer_Feedback__c) }}</p>
                    <p class="text-xs">{{ constructConfirmationDate(history.Customer_Feedback__c, history.Customer_Feedback_Timestamp__c) }}</p>
                </div>
                <button @click="goToNegotiationResultDetail(history.Id)" class="w-full mt-4 py-1.5 text-center shadow-amalan-md border border-amalan-blue-1 rounded-amalan-lg text-amalan-blue-1 text-xs font-bold hover:bg-amalan-gray-5">Lihat Selengkapnya</button>
            </div>
        </div>
    </div>
</template>