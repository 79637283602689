<script setup>
import { XIcon } from '@heroicons/vue/solid'
import { defineProps, defineEmits } from 'vue'

defineProps({
    visible: Boolean,
    title: String,
    content: String
})

const emit = defineEmits(['onClose'])

const closeButton = () => emit('onClose')

</script>

<template>
    <Transition name="fade-fast">
        <div v-show="visible" class="absolute left-0 right-0 top-14 bottom-0 bg-black bg-opacity-50 p-6 flex justify-center items-center">
            <div class="w-full py-5 px-4 bg-amalan-white rounded-lg -mt-10">
                <div class="relative pb-2.5 border-b border-amalan-gray-3">
                    <p class="text-amalan-blue-1 font-bold text-sm" v-html="title"></p>
                    <XIcon class="absolute top-0 right-0 w-4 text-amalan-gray-1 cursor-pointer" @click="closeButton" />
                </div>
                <p class="mt-4 text-amalan-black text-xs" v-html="content"></p>
            </div>
        </div>
    </Transition>
</template>

<style scoped>
.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.3s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.3s ease
}
</style>