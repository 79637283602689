<script setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from "vue-router"
import setCookies from '@/composables/setCookies'

const router = useRouter()
const route = useRoute()
const showPage = ref(false)
const hideLewati = ref(false)
const firstStep = ref(true)
const secondStep = ref(false)
const thirdStep = ref(false)
const currentPosition = ref(1)
const isLoading = ref(false)

const nextStep = () => {
    if(currentPosition.value == 1){
        hideLewati.value = true
        secondStep.value = true
        firstStep.value = false
        thirdStep.value = false
        currentPosition.value = 2
    }else if(currentPosition.value == 2){
        hideLewati.value = true
        thirdStep.value = true
        firstStep.value = false
        secondStep.value = false
        currentPosition.value = 3
    }else{
        isLoading.value = true
        router.push({ name : 'Login' })
    }
}

const skip = () => {
    isLoading.value = true
    router.push({ name : 'Login' })
}

onMounted(() => {
    localStorage.setItem('from-onboarding-go-to', 'Sign Up Onboarding')
    
    const queryParams = route.query
    setCookies.setUtmCookies(queryParams)

    if(queryParams.reminder){
        if(queryParams.reminder === 'email' || queryParams.reminder === 'whatsapp'){
            isLoading.value = true
            router.push({ path: '/login', query: queryParams })
        }
    }

    setTimeout(() => {
        showPage.value = true
    }, 500)
})
</script>

<template>
    <div class="bg-amalan-white text-amalan-black min-h-screen">
        <Transition name="fade-fast">
            <div v-show="showPage" class="w-full h-800 relative">
                <div class="w-full bg-amalan-white-200 h-500 relative overflow-hidden">
                    <div class="justify-center flex pt-13">
                        <img class="h-12" src="@/assets/img/logo-amalan.png">
                    </div>
                    <Transition name="slide">
                        <div v-show="firstStep" class="w-full absolute justify-center flex pt-9 xs:pt-11 ssm:pt-8">
                            <img class="w-11/12 xs:w-10/12" src="@/assets/img/signup/onboarding/first-onboarding-banner.png">
                        </div>
                    </Transition>
                    <Transition name="slide">
                        <div v-show="secondStep" class="w-full absolute justify-center flex pt-24 xs:pt-20">
                            <img class="w-10/12 ssm:w-9/12" src="@/assets/img/signup/onboarding/second-onboarding-banner.png">
                        </div>
                    </Transition>
                    <Transition name="slide">
                        <div v-show="thirdStep" class="w-full absolute justify-center flex pt-24 xs:pt-20 ssm:pt-16">
                            <img class="w-10/12" src="@/assets/img/signup/onboarding/third-onboarding-banner.png">
                        </div>
                    </Transition>
                </div>
                <div class="w-full absolute bottom-0 z-3 h-500 overflow-hidden">
                    <Transition name="slide-100">
                        <img v-show="firstStep" src="@/assets/img/signup/onboarding/first-onboarding-bg.svg" class="w-full absolute">
                    </Transition>
                    <Transition name="slide-100">
                        <img v-show="secondStep" src="@/assets/img/signup/onboarding/second-onboarding-bg.svg" class="w-full absolute">
                    </Transition>
                    <Transition name="slide-100">
                        <img v-show="thirdStep" src="@/assets/img/signup/onboarding/third-onboarding-bg.svg" class="w-full absolute">
                    </Transition>
                    <Transition name="slide-100">
                        <div v-show="firstStep" class="absolute top-20 ssm:top-24 w-full h-10 bg-transparent">
                            <img src="@/assets/img/signup/onboarding/first-onboarding-decoration.svg" class="absolute top-0 left-10 w-6">
                            <img src="@/assets/img/signup/onboarding/first-onboarding-decoration.svg" class="absolute bottom-4 right-16 w-6">
                        </div>
                    </Transition>
                    <Transition name="slide">
                        <div v-show="thirdStep" class="absolute inset-x-0 mx-auto top-10 w-10/12 h-20 bg-transparent">
                            <img src="@/assets/img/signup/onboarding/third-onboarding-decoration-1.svg" class="absolute top-1 ssm:top-4 sm:top-8 left-14 xs:left-16 ssm:left-20 sm:left-22 w-12 ssm:w-14">
                            <img src="@/assets/img/signup/onboarding/third-onboarding-decoration-2.svg" class="absolute top-8 xs:top-10 sm:top-16 inset-x-0 mx-auto w-10 ssm:w-12">
                            <img src="@/assets/img/signup/onboarding/third-onboarding-decoration-3.svg" class="absolute bottom-2 right-12 xs:right-14 ssm:right-16 sm:right-20 ssm:-bottom-4 sm:-bottom-8 w-12 ssm:w-14">
                        </div>
                    </Transition>
                    <Transition name="slide-100">
                        <div v-show="firstStep" class="w-full absolute top-36 ssm:top-40 sm:top-44 h-full px-6">
                            <p class="text-center font-bold text-base text-amalan-black">Sambut hari bahagia Anda<br>bebas dari utang bersama amalan!</p>
                            <p class="text-center text-xs text-amalan-black mt-3.5">amalan dengan senang hati dan siap untuk<br>membantu masalah utang Anda!</p>
                        </div>
                    </Transition>
                    <Transition name="slide-100">
                        <div v-show="secondStep" class="w-full absolute top-36 ssm:top-40 sm:top-44 h-full px-6">
                            <p class="text-center font-bold text-base text-amalan-black">Persiapkan e-KTP<br>dan Foto Swafoto Anda!</p>
                            <p class="text-center text-xs text-amalan-black mt-3.5">Cukup dengan unggah foto e-KTP dan Swafoto<br>Anda untuk melakukan Verifikasi Data Diri!</p>
                        </div>
                    </Transition>
                    <Transition name="slide-100">
                        <div v-show="thirdStep" class="w-full absolute top-36 ssm:top-40 sm:top-44 h-full px-6">
                            <p class="text-center font-bold text-base text-amalan-black">Jangan Lupa Cek Kembali<br>Data Pinjaman Anda!</p>
                            <p class="text-center text-xs text-amalan-black mt-3.5">amalan akan menjamin keamanan<br>data diri, data pinjaman, dan privasi Anda!</p>
                        </div>
                    </Transition>
                    <div class="w-full absolute bottom-9">
                        <Transition name="fade-fast">
                            <div v-if="firstStep" class="w-full flex justify-center absolute">
                                <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            </div>
                        </Transition>
                        <Transition name="fade-fast">
                            <div v-if="secondStep" class="w-full flex justify-center absolute">
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                                <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            </div>
                        </Transition>
                        <Transition name="fade-fast">
                            <div v-if="thirdStep" class="w-full flex justify-center absolute">
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                                <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                            </div>
                        </Transition>
                        <div class="w-full px-6">
                            <div @click="nextStep" class="w-full mt-14 py-3 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg cursor-pointer">
                                <p class="text-amalan-white text-sm font-bold text-center">{{ currentPosition == 3 ? 'Mulai Sekarang' : 'Selanjutnya' }}</p>
                            </div>
                            <div class="w-full h-5 mt-4 flex justify-center items-center">
                                <Transition name="fade-fast">
                                    <div v-if="!hideLewati" @click="skip" class="text-amalan-blue-1 hover:text-amalan-blue-2 text-sm font-bold underline cursor-pointer">Lewati</div>
                                </Transition>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
        <loading-overlay v-if="isLoading" />
    </div>
</template>

<style scoped>
.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.8s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.8s ease
}

.slide-enter-active,
.slide-leave-active,
.slide-100-enter-active,
.slide-100-leave-active {
    transition: transform 0.6s ease-in-out;
}

.slide-enter-from {
    transform: translate(110%, 0);
}

.slide-leave-to {
    transform: translate(-110%, 0);
}

.slide-100-enter-from {
    transform: translate(100%, 0);
}

.slide-100-leave-to {
    transform: translate(-100%, 0);
}
</style>