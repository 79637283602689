<script setup>
import { useStore } from 'vuex'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { ref, computed, onMounted, inject } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import HelloSign from 'hellosign-embedded'

const store = useStore()
const router = useRouter()
const route = useRoute()
const swal = inject('$swal')

const signUpStatus = computed(() => store.getters['contact/getSignUpStatus'])
const signUp = computed(() => store.getters['contact/getSignUp'])
const HS_CLIENT_ID = computed(() => process.env.VUE_APP_HELLOSIGN_CLIENT_ID)
const ENV_MODE = computed(() => process.env.VUE_APP_ENVIRONMENT_MODE)

const urlHelloSign = ref(null)
const isLoading = ref(false)
const isFinish = ref(false)

const swalErrorReload = swal.mixin({
    customClass: {
        confirmButton: 'mx-2 py-3 w-34 text-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-blue-1 hover:bg-amalan-blue-2 text-xs font-bold text-amalan-white',
        cancelButton: 'absolute -top-3 -right-3'
    },
    buttonsStyling: false,
    width: '340px',
    allowOutsideClick: false,
    reverseButtons: true,
    title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
    html: '<p class="text-center text-xs mt-2">Maaf, terjadi kesalahan saat memuat dokumen, klik tombol dibawah untuk memperbaharui!</p>',
    showCancelButton: false,
    confirmButtonText: 'Perbaharui'
})

const HS_CLIENT = new HelloSign({clientId: HS_CLIENT_ID.value})

const checkDigitalSignStatus = () => {
    isLoading.value = true
    setTimeout(() => {
        store.dispatch('contact/requestSignUpStatus').then(() => {
            isLoading.value = false
            if(signUpStatus.value == 404){
                router.push('/sign-up/onboarding')
            }else if(signUpStatus.value == 201){
                if(signUp.value && signUp.value.data && signUp.value.data.url_contract){
                    urlHelloSign.value = signUp.value.data.url_contract
                    showSigningFrame()
                }
            }else{
                router.push('/sign-up/pembayaran')
            }
        })
    }, 3000)
}

const showSigningFrame = () => {
    isLoading.value = true
    const decodedURL = decodeURIComponent(urlHelloSign.value)
    const firstExplode = decodedURL.split('sign_url=')
    const secondExplode = firstExplode[1].split('&payment_url=')
    const domElement = document.getElementById('hellosign-embed-container')

    const debug = ENV_MODE.value === 'production' ? false : true
    const testMode = ENV_MODE.value === 'production' ? false : true

    HS_CLIENT.open(secondExplode[0], {
        locale: HelloSign.locales.ID_ID,
        container: domElement,
        allowCancel: false,
        debug,
        testMode
    })
}

HS_CLIENT.on('finish', () => isFinish.value = true)

HS_CLIENT.on('error', () => {
    isLoading.value = false
    HS_CLIENT.close()
    swalErrorReload.fire().then((result) => {
        if (result.isConfirmed){
            window.location.reload()
        }
    })
});

HS_CLIENT.on('ready', () => isLoading.value = false)

onMounted(() => {
    if(signUpStatus.value == 200){
        router.push({ name: 'Konfirmasi Layanan'})
    }else if(signUpStatus.value == 202){
        router.push('/sign-up/pembayaran')
    }else if(signUpStatus.value == 404){
        router.push('/sign-up/onboarding')
    }

    if(signUp.value && signUp.value.data && signUp.value.data.url_contract){
        urlHelloSign.value = signUp.value.data.url_contract
        showSigningFrame()
    }
})

</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="router.push({ name: 'Home' })" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold text-amalan-black text-center">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full min-h-screen bg-amalan-white px-6 pb-24">
            <div class="w-full py-8 flex justify-between items-center">
                <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                    <span class="text-sm text-amalan-blue-1 font-bold">1</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3 mx-1"></div>
                <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400">
                    <span class="text-sm font-bold text-amalan-blue-1">2</span>
                </div>
                <p class="flex-none text-amalan-blue-1 text-sm font-bold ml-1">Tanda Tangan</p>
                <div class="bg-amalan-blue-1 h-px flex-grow mx-1"></div>
                <div class="w-6 h-6 rounded-full bg-amalan-gray-4 flex justify-center items-center flex-none ml-1">
                    <span class="text-sm text-amalan-gray-2 font-bold">3</span>
                </div>
            </div>
            <div class="w-full rounded-lg border border-amalan-gray-4 bg-amalan-white p-4">
                <template v-if="isFinish">
                    <p class="text-sm text-amalan-black text-center pt-4 pb-8 px-6 sm:px-8">Silahkan tekan tombol dibawah untuk proses berikutnya, sistem akan melakukan pengecekan pada dokumen yang telah Anda tanda tangani.</p>
                    <button @click="checkDigitalSignStatus" type="button" class="w-full rounded-amalan-lg font-bold text-center text-sm p-3 text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md">Selanjutnya</button>
                </template>
                <template v-else>
                    <p class="text-sm text-amalan-black text-center pt-4 pb-8 px-6 sm:px-8">Silahkan tandatangani dokumen dibawah ini!</p>
                    <div class="w-full h-500" id="hellosign-embed-container"></div>
                </template>
            </div>
            <loading-overlay v-if="isLoading" />
        </div>
    </div>
</template>