<script setup>
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import amalansignupApi from "@/axios/amalansignup"
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { inject, ref, computed, onMounted } from 'vue'

const store = useStore()
const router = useRouter()
const route = useRoute()
const swal = inject("$swal")

const signUpStatus = computed(() => store.getters['contact/getSignUpStatus'])
const signUp = computed(() => store.getters['contact/getSignUp'])

const isLoading = ref(false)
const isPaymentFrame = ref(false)

const swalErrorReload = swal.mixin({
    customClass: {
        confirmButton: 'mx-2 py-3 w-34 text-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-blue-1 hover:bg-amalan-blue-2 text-xs font-bold text-amalan-white',
        cancelButton: 'absolute -top-3 -right-3'
    },
    buttonsStyling: false,
    width: '340px',
    allowOutsideClick: false,
    reverseButtons: true,
    title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
    html: '<p class="text-center text-xs mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
    showCancelButton: false,
    confirmButtonText: 'Perbaharui'
})

const checkSignProcess = () => {
    isLoading.value = true
    isPaymentFrame.value = false
    setTimeout(() => {
        store.dispatch('contact/requestSignUpStatus').then(() => {
            if(signUpStatus.value == 200){
                store.dispatch('contact/requestContact').then(() => {
                    isLoading.value = false
                    router.push('/sign-up/konfirmasi')
                })
            }else if(signUpStatus.value == 202){
                if(signUp.value.data && signUp.value.data.invoiceUrl){
                    isLoading.value = false
                }else{
                    reqNewInvoice()
                }
            }else{
                router.push('/sign-up/onboarding')
            }
        })
    }, 2000);
}

const reqNewInvoice = () => {
    isLoading.value = true
    amalansignupApi.post('salesforce/contact/xendit')
    .then(() => {
        store.dispatch('contact/requestSignUpStatus').then(() => {
            if(signUpStatus.value == 200){
                store.dispatch('contact/requestContact').then(() => {
                    router.push('/sign-up/konfirmasi')
                })
            }else{
                isLoading.value = false
            }
        })
    })
    .catch(() => {
        isLoading.value = false
        swalErrorReload.fire().then((result) => {
            if (result.isConfirmed){
                window.location.reload()
            }
        })
    })
}

const showPaymentFrame = () => isPaymentFrame.value = true

onMounted(() => {
    if(signUpStatus.value == 200){
        router.push({ name: 'Konfirmasi Layanan'})
    }else if(signUpStatus.value == 201){
        router.push('/sign-up/tanda-tangan')
    }else if(signUpStatus.value == 404){
        router.push('/sign-up/onboarding')
    }

    checkSignProcess()
})
</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="router.push({ name: 'Home' })" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold text-amalan-black text-center">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full pt-8 px-6 flex justify-between items-center">
            <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                <span class="text-sm text-amalan-blue-1 font-bold">1</span>
            </div>
            <div class="bg-amalan-blue-1 h-px flex-none w-3 mx-1"></div>
            <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400">
                <span class="text-sm font-bold text-amalan-blue-1">2</span>
            </div>
            <div class="bg-amalan-blue-1 h-px flex-grow mx-1"></div>
            <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                <span class="text-sm text-amalan-blue-1 font-bold">3</span>
            </div>
            <p class="flex-none text-amalan-blue-1 text-sm font-bold ml-1">Pembayaran Registrasi</p>
        </div>
        <div v-if="isLoading" class="mt-40 w-full px-4">
            <loading-data-without-margin />
        </div>
        <div v-else class="w-full min-h-screen bg-amalan-white px-6 mt-8">
            <div class="w-full bg-amalan-white border border-amalan-gray-4 p-4 rounded-lg">
                <p class="text-center text-base text-amalan-blue-1 font-bold">Ringkasan Pembayaran</p>
                <div class="w-full h-px bg-amalan-blue-1 my-4"></div>
                <div class="grid grid-cols-12 gap-y-1 text-xs text-amalan-black">
                    <div class="col-span-4">Pembayaran</div>
                    <div class="col-span-8">: Deposit Registrasi</div>
                    <div class="col-span-4">Harga</div>
                    <div class="col-span-8">: {{ signUp.data ? signUp.data.invoiceAmount ? $filters.format_number(signUp.data.invoiceAmount) : 'Rp 0' : 'Rp 0' }}</div>
                    <div class="col-span-4">Potongan</div>
                    <div class="col-span-8">: Rp 0</div>
                </div>
                <div class="w-full h-px bg-amalan-blue-1 my-4"></div>
                <div class="grid grid-cols-12 gap-y-1 text-xs text-amalan-black">
                    <div class="col-span-4">Total</div>
                    <div class="col-span-8">: {{ signUp.data ? signUp.data.invoiceAmount ? $filters.format_number(signUp.data.invoiceAmount) : 'Rp 0' : 'Rp 0' }}</div>
                </div>
                <button @click="showPaymentFrame" type="button" class="w-full mt-8 rounded-amalan-lg font-bold text-center text-sm p-3 text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md">Pilih Metode Pembayaran</button>
            </div>
        </div>
        <div v-if="isPaymentFrame" class="fixed inset-0 z-50 bg-amalan-blue-7 min-h-screen w-screen flex items-center justify-center">
            <div class="max-w-sceen h-full overflow-visible">
                <iframe class="w-screen h-5/6 border-b-4 bg-amalan-white" :src="signUp.data.invoiceUrl" frameborder="0" allow="autoplay fullscreen picture-in-picture"></iframe>
                <div class="absolute bottom-0 pb-10 w-full flex justify-center bg-amalan-blue-7 px-4">
                    <button class="border text-amalan-white w-full bg-amalan-blue-1 border-amalan-blue-1 hover:bg-amalan-blue-2 hover:border-amalan-blue-2 rounded-amalan-lg py-3 text-sm font-bold shadow-amalan-md" @click="checkSignProcess">
                        Konfirmasi Pembayaran
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>