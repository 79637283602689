<script setup>
import { ref, reactive, computed, onMounted, watch } from "vue"
import { useStore } from 'vuex'
import { Form, Field } from "vee-validate"
import * as yup from "yup"
import axios from "@/axios"
import { ChevronLeftIcon, CheckIcon } from '@heroicons/vue/outline'
import moment from 'moment'
import TermsConditions from '@/components/TermsConditions.vue'
import { useRoute, useRouter } from 'vue-router'
import useModalPopUp from '@/composables/modalPopUp'
import DoneBanner from '@/components/icon/DoneBanner'

const router = useRouter()
const route = useRoute()
const store = useStore()
const modalPopUp = useModalPopUp()

const dataTitle = ['Bpk', 'Ibu']
const dataGender = [{ code: 'M', description: 'Laki-laki' },{ code: 'F', description: 'Perempuan' }]
const minDate = moment().subtract(100, 'years').format('YYYY-MM-DD')
const maxDate = moment().subtract(17, 'years').format('YYYY-MM-DD')

const dataUser = computed(() => store.getters['user/getUser'])
const idCardUser = computed(() => store.getters['user/getIdCard'])
const termAndConditionStatus = computed(() => store.getters['globalState/getTermAndConditionStatus'])

const currentStep = ref(2)
const formValues = reactive({})
const isLoading = ref(false)
const dataOccupation = ref([])
const dataReligion = ref([])
const dataEducation = ref([])
const dataMarital = ref([])
const dataProvince = ref([])
const dataCity = ref([])
const dataDistrict = ref([])
const dataVillage = ref([])
const formValuesPreview = ref({
    title: null,
    nik: null,
    name: null,
    email: null,
    gender: null,
    birthplace: null,
    birthdate: null,
    street: null,
    rt: null,
    rw: null,
    religion: null,
    education: null,
    marital: null,
    occupation: null,
    village: null,
    district: null,
    city: null,
    province: null,
    postalCode: null
})
const isFirstFormValid = ref(false)
const isSecondFormValid = ref(false)
const autoRedirectTimeout = ref(null)

const updateForm = computed(() => schemas[currentStep.value])

const schemas = [
    yup.object({
        title: yup.string().nullable().required('Silahkan pilih title').oneOf(dataTitle, 'Silahkan pilih title'),
        nik: yup.string().nullable().required('NIK tidak boleh kosong')
        .matches(/^[0-9]+$/, "NIK harus berupa angka").length(16, "NIK harus 16 digit"),
        name: yup.string().nullable().required('Nama tidak boleh kosong').max(50, "Maksimal 50 karakter"),
        email: yup.string().nullable().required('Email tidak boleh kosong')
        .email('Email tidak valid').max(255, "Maksimal 255 karakter"),
        birthplace: yup.string().nullable().required('Tempat lahir tidak boleh kosong').max(50, "Maksimal 50 karakter"),
        birthdate: yup.date().nullable().required('Silahkan pilih tanggal lahir').min(minDate,"Pengguna maksimal berumur 100 tahun").max(maxDate,"Pastikan tanggal lahir sesuai KTP"),
        gender: yup.string().nullable().required('Silahkan pilih jenis kelamin').oneOf(['M','F'], 'Silahkan pilih jenis kelamin'),
        religionId: yup.string().nullable().required('Silahkan pilih agama'),
        educationId: yup.string().nullable().required('Silahkan pilih pendidikan'),
        occupationId: yup.string().nullable().required('Silahkan pilih pekerjaan'),
        maritalId: yup.string().nullable().required('Silahkan pilih status perkawinan')
    }),
    yup.object({
        street: yup.string().nullable().required('Alamat tidak boleh kosong').max(255, "Maksimal 255 karakter"),
        rt: yup.string().nullable().required('RT tidak boleh kosong').matches(/^[0-9]+$/, "RT harus berupa angka").max(5, "Maksimal 5 karakter"),
        rw: yup.string().nullable().required('RW tidak boleh kosong').matches(/^[0-9]+$/, "RW harus berupa angka").max(5, "Maksimal 5 karakter"),
        provinceId: yup.string().nullable().required('Silahkan pilih provinsi'),
        cityId: yup.string().nullable().required('Silahkan pilih kota'),
        districtId: yup.string().nullable().required('Silahkan pilih kecamatan'),
        villageId: yup.string().nullable().required('Silahkan pilih kelurahan'),
        postalCode: yup.string().nullable().required('Kode pos tidak boleh kosong')
        .matches(/^[0-9]+$/, "Kode pos harus berupa angka").max(5, "Maksimal 5 karakter")
    }),
    yup.object({
        terms: yup.bool().required('Silahkan dicentang').equals([true], 'Silahkan dicentang'),
        personalDataAgreement: yup.bool().required('Silahkan dicentang').equals([true], 'Silahkan dicentang')
    })
]

axios.get('/educations').then(resp => {
    dataEducation.value = resp.data.data
    formValues.educationId = dataUser.value.education.id
}).catch(err => {
    if (err.status !== 401){
        modalPopUp.swalErrorReload.fire().then((result) => {
            if (result.isConfirmed){
                window.location.reload()
            }
        })
    }
})

axios.get('/religions').then(resp => {
    dataReligion.value = resp.data.data
    formValues.religionId = dataUser.value.religion.id
}).catch(err => {
    if (err.status !== 401){
        modalPopUp.swalErrorReload.fire().then((result) => {
            if (result.isConfirmed){
                window.location.reload()
            }
        })
    }
})

setTimeout(() => {
    axios.get('/occupations').then(resp => {
        dataOccupation.value = resp.data.data
        formValues.occupationId = dataUser.value.occupation.id
    }).catch(err => {
        if (err.status !== 401){
            modalPopUp.swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload()
                }
            })
        }
    })

    axios.get('/marital-statuses').then(resp => {
        dataMarital.value = resp.data.data
        formValues.maritalId = dataUser.value.marital.id
    }).catch(err => {
        if (err.status !== 401){
            modalPopUp.swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload()
                }
            })
        }
    })
}, 2000)

setTimeout(() => {
    axios.get('/regions/provinces').then(resp => {
        dataProvince.value = resp.data.data
        formValues.provinceId = dataUser.value.address.province.id
    }).catch(err => {
        if (err.status !== 401){
            modalPopUp.swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload()
                }
            })
        }
    })

    if (dataUser.value.address.province.id !== null){
        axios.get('/regions/cities?province_id=' + dataUser.value.address.province.id)
        .then(resp => {
            dataCity.value = resp.data.data
            formValues.cityId = dataUser.value.address.city.id
        }).catch(err => {
            if (err.status !== 401){
                modalPopUp.swalErrorReload.fire().then((result) => {
                    if (result.isConfirmed){
                        window.location.reload()
                    }
                })
            }
        })
    }
}, 4000)

setTimeout(() => {
    if (dataUser.value.address.city.id !== null){
        axios.get('/regions/districts?city_id=' + dataUser.value.address.city.id)
        .then(resp => {
            dataDistrict.value = resp.data.data
            formValues.districtId = dataUser.value.address.district.id
        }).catch(err => {
            if (err.status !== 401){
                modalPopUp.swalErrorReload.fire().then((result) => {
                    if (result.isConfirmed){
                        window.location.reload()
                    }
                })
            }
        })
    }

    if (dataUser.value.address.district.id !== null){
        axios.get('/regions/villages?district_id=' + dataUser.value.address.district.id)
        .then(resp => {
            dataVillage.value = resp.data.data
            formValues.villageId = dataUser.value.address.village.id
        }).catch(err => {
            if (err.status !== 401){
                modalPopUp.swalErrorReload.fire().then((result) => {
                    if (result.isConfirmed){
                        window.location.reload()
                    }
                })
            }
        })
    }
}, 6000)

const getCity = () => {
    isLoading.value = true
    formValues.cityId = null
    axios.get('/regions/cities?province_id=' + formValues.provinceId).then(resp => {
        dataCity.value = resp.data.data
        isLoading.value = false
    }).catch(err => {
        isLoading.value = false
        if (err.status !== 401){
            modalPopUp.swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload()
                }
            })
        }
    })
}

const getDistrict = () => {
    isLoading.value = true
    formValues.districtId = null
    axios.get('/regions/districts?city_id=' + formValues.cityId).then(resp => {
        dataDistrict.value = resp.data.data
        isLoading.value = false
    }).catch(err => {
        isLoading.value = false
        if (err.status !== 401){
            modalPopUp.swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload()
                }
            })
        }
    })
}

const getVillage = () => {
    isLoading.value = true
    formValues.villageId = null
    axios.get('/regions/villages?district_id=' + formValues.districtId).then(resp => {
        dataVillage.value = resp.data.data
        isLoading.value = false
    }).catch(err => {
        isLoading.value = false
        if (err.status !== 401){
            modalPopUp.swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload()
                }
            })
        }
    })
}

const termsChecked = () => formValues.terms = !formValues.terms

const personalDataAgreementChecked = () => formValues.personalDataAgreement = !formValues.personalDataAgreement

const nextStep = (values) => {
    if (currentStep.value === 2) {
        let additional_data = {
            phone: dataUser.value.phone_number,
            name: formValues.name,
            rt_rw: formValues.rt + '_' + formValues.rw,
            productStage: route.meta.creditCheckupProductKYC ? 'credit-checkup' : null
        }
        Object.assign(formValues,additional_data);            
        
        modalPopUp.swalConfirmWithIcon.fire({
            html: '<p class="text-center text-xs text-amalan-black mt-2">Anda yakin data yang diinput sudah benar?<br>Jika sudah, silahkan dikonfirmasi untuk<br>melanjutkan ke proses berikutnya!</p>',
            showCancelButton: true,
            confirmButtonText: 'Konfirmasi',
            cancelButtonText: 'Batalkan',
        }).then((result) => {
            if (result.isConfirmed){
                isLoading.value = true;
                axios.put('/customer/update-with-ktp', formValues).then(resp => {
                    if(resp.status == 200){
                        store.dispatch('contact/setContactToActive')
                        store.dispatch('user/storeUser').then(() => {
                            setTimeout(() => {
                                isLoading.value = false
                                if(route.meta.creditScoreKYC) return router.push({ name: 'credit checkup - credit score checkout' })
                                return currentStep.value = 3
                            }, 1000)
                        })
                    }else{
                        isLoading.value = false;
                        modalPopUp.swalError.fire({
                            html: '<p class="text-center text-xs text-amalan-black mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                            showCancelButton: true,
                            confirmButtonText: 'Bantuan',
                            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                        }).then((result) => {
                            if (result.isConfirmed){
                                return window.location = 'https://wa.me/622150933150?text=Mohon bantuan saya gagal terus dalam proses KYC - lengkapi data di web.amalan.com';
                            }
                        });
                    }
                }).catch((err) => {
                    isLoading.value = false;
                    if(err.response.status == 400 || err.response.status == 422 ){
                        if(err.response.data.message.includes('customers_email_unique')){
                            modalPopUp.swalError.fire({
                                html: '<p class="text-center text-xs text-amalan-black mt-2">Maaf, email yang kamu masukkan telah digunakan, pastikan email tersebut adalah email anda atau gunakan email lain.</p>',
                                showCancelButton: true,
                                showConfirmButton: false,
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            });
                        } else if(err.response.data.message.includes('id_cards_number_unique')){
                            modalPopUp.swalError.fire({
                                html: '<p class="text-center text-xs mt-2">Maaf, NIK yang kamu masukkan sudah terdaftar sebelumnya. Silahkan tekan tombol bantuan dibawah ini atau hubungi konsultan Anda.</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    return window.location = 'https://wa.me/622150933150?text=Mohon bantuan saya tidak dapat melanjutkan proses karena NIK yang saya masukkan sudah terdaftar di akun lain.';
                                }
                            })
                        } else {
                            modalPopUp.swalError.fire({
                                html: '<p class="text-center text-xs text-amalan-black mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                                showCancelButton: true,
                                confirmButtonText: 'Bantuan',
                                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                            }).then((result) => {
                                if (result.isConfirmed){
                                    return window.location = 'https://wa.me/622150933150?text=Mohon bantuan saya gagal terus dalam proses KYC - lengkapi data di web.amalan.com';
                                }
                            });
                        }
                    }else{
                        modalPopUp.swalError.fire({
                            html: '<p class="text-center text-xs text-amalan-black mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                            showCancelButton: true,
                            confirmButtonText: 'Bantuan',
                            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                        }).then((result) => {
                            if (result.isConfirmed){
                                return window.location = 'https://wa.me/622150933150?text=Mohon bantuan saya gagal terus dalam proses KYC - lengkapi data di web.amalan.com';
                            }
                        });
                    }
                });
            }
        });
        return;
    }

    Object.assign(formValues, values);
    currentStep.value = 2;
}

const backNavigation = () => {
    if (currentStep.value >= 2) {
        localStorage.removeItem('from-onboarding-go-to')
        if(route.meta.creditScoreKYC) return router.push({ name: 'Score' })
        return router.push({ name: 'Home'})
    }

    return currentStep.value = 2
}

const showTermsAndCondition = () => store.dispatch('globalState/changeTermAndConditionStatus')

const genderDescription = () => {
    const gender = dataGender.find(({ code }) => code === formValues.gender)
    return gender.description
}

const occupationName = () => {
    const occupation = dataOccupation.value.find(({ id }) => id === formValues.occupationId)
    return occupation.name
}

const religionName = () => {
    const religion = dataReligion.value.find(({ id }) => id === formValues.religionId)
    return religion.name
}

const educationName = () => {
    const education = dataEducation.value.find(({ id }) => id === formValues.educationId)
    return education.name
}

const maritalName = () => {
    const marital = dataMarital.value.find(({ id }) => id === formValues.maritalId)
    return marital.name
}

const provinceName = () => {
    const province = dataProvince.value.find(({ id }) => id === formValues.provinceId)
    return province.name
}

const cityName = () => {
    const city = dataCity.value.find(({ id }) => id === formValues.cityId)
    return city.name
}

const districtName = () => {
    const district = dataDistrict.value.find(({ id }) => id === formValues.districtId)
    return district.name
}

const villageName = () => {
    const village = dataVillage.value.find(({ id }) => id === formValues.villageId)
    return village.name
}

watch(formValues, () => {
    formValuesPreview.value.title = formValues.title || null
    formValuesPreview.value.nik = formValues.nik || null
    formValuesPreview.value.name = formValues.name || null
    formValuesPreview.value.email = formValues.email || null
    formValuesPreview.value.gender = formValues.gender ? genderDescription() : null
    formValuesPreview.value.birthplace = formValues.birthplace || null
    formValuesPreview.value.birthdate = formValues.birthdate || null
    formValuesPreview.value.religion = formValues.religionId ? religionName() : null
    formValuesPreview.value.education = formValues.educationId ? educationName() : null
    formValuesPreview.value.marital = formValues.maritalId ? maritalName() : null
    formValuesPreview.value.occupation = formValues.occupationId ? occupationName() : null
    formValuesPreview.value.street = formValues.street || null
    formValuesPreview.value.rt = formValues.rt || null
    formValuesPreview.value.rw = formValues.rw || null
    formValuesPreview.value.village = formValues.villageId ? villageName() : null
    formValuesPreview.value.district = formValues.districtId ? districtName() : null
    formValuesPreview.value.city = formValues.cityId ? cityName() : null
    formValuesPreview.value.province = formValues.provinceId ? provinceName() : null
    formValuesPreview.value.postalCode = formValues.postalCode || null

    revalidateForm()
}, {
    deep: true
})

const revalidateForm = async () => {
    const firstFormData = {
        title: formValuesPreview.value.title,
        nik: formValuesPreview.value.nik,
        name: formValuesPreview.value.name,
        email: formValuesPreview.value.email,
        birthplace: formValuesPreview.value.birthplace,
        birthdate: formValuesPreview.value.birthdate,
        gender: formValuesPreview.value.gender === 'Perempuan' ? 'F' : 'M',
        religionId: formValuesPreview.value.religion,
        educationId: formValuesPreview.value.education,
        occupationId: formValuesPreview.value.occupation,
        maritalId: formValuesPreview.value.marital
    }

    const secondFormData = {
        street: formValuesPreview.value.street,
        rt: formValuesPreview.value.rt,
        rw: formValuesPreview.value.rw,
        provinceId: formValuesPreview.value.province,
        cityId: formValuesPreview.value.city,
        districtId: formValuesPreview.value.district,
        villageId: formValuesPreview.value.village,
        postalCode: formValuesPreview.value.postalCode
    }

    isFirstFormValid.value = await schemas[0].isValid(firstFormData)
    isSecondFormValid.value = await schemas[1].isValid(secondFormData)
    return
}

const goToFirstForm = () => currentStep.value = 0
const goToSecondForm = () => currentStep.value = 1

watch(() => currentStep.value, () => {
    if(currentStep.value !== 3) return
    return autoRedirectTimeout.value = setTimeout(() => router.push({ name: 'Home' }), 3000)
})

watch(() => idCardUser.value, () => formValues.nik = idCardUser.value ? idCardUser.value.number : null)

const redirectToHome = () => {
    if(autoRedirectTimeout.value) clearTimeout(autoRedirectTimeout.value)
    router.push({ name: 'Home' })
}

onMounted(() => {
    store.dispatch('user/requestIdCard')
    formValues.nik = idCardUser.value ? idCardUser.value.number : null
    formValues.title = dataUser.value.title
    formValues.name = (dataUser.value.first_name || '') + ' ' + (dataUser.value.last_name || '')
    formValues.email = dataUser.value.email
    formValues.gender = dataUser.value.gender
    formValues.birthplace = dataUser.value.birthplace
    formValues.birthdate = dataUser.value.birthdate
    formValues.street = dataUser.value.address.street
    formValues.rt = dataUser.value.address.rt
    formValues.rw = dataUser.value.address.rw
    formValues.postalCode = dataUser.value.address.postal_code
    formValues.terms = route.meta.creditScoreKYC ? false : true
    formValues.personalDataAgreement = false
})

</script>

<template>
    <div>
        <TermsConditions />
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <div v-if="currentStep === 3" class="flex items-center justify-center w-6 h-8"></div>
                    <button v-else @click="backNavigation" class="flex items-center justify-center w-6 h-8" :class="{ 'invisible' : termAndConditionStatus }">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div v-if="route.meta.creditScoreKYC" class="pb-10 pt-6 w-full max-w-screen-md mx-auto">
            <div class="flex items-center">
                <div class="flex items-center text-amalan-blue-1 relative">
                    <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                        <img src="@/assets/img/ktp-active.svg" class="w-7 h-7">
                    </div>
                    <div class="absolute -top-2 -ml-10 text-center mt-16 w-32 text-xs font-bold text-amalan-blue-1"><p>Verifikasi<br>Data</p></div>
                </div>
                <div class="flex-auto border-t-2 border-amalan-gray-3"></div>
                <div class="flex items-center text-amalan-gray-2 relative">
                    <div class="rounded-full h-12 w-12 border-2 bg-amalan-gray-5 border-amalan-gray-5 flex justify-center items-center">
                        <img src="@/assets/img/credit-checkup/pembayaran-layanan.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-amalan-gray-2"><p>Pembayaran<br>Layanan</p></div>
                </div>
                <div class="flex-auto border-t-2 border-amalan-gray-3"></div>
                <div class="flex items-center text-amalan-gray-2 relative">
                    <div class="rounded-full h-12 w-12 border-2 bg-amalan-gray-5 border-amalan-gray-5 flex justify-center items-center">
                        <img src="@/assets/img/credit-checkup/credit-score.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-amalan-gray-2"><p>Credit<br>Score</p></div>
                </div>
            </div>
        </div>
        <div v-if="currentStep === 3" class="w-full min-h-screen px-6 -mt-14 flex flex-col justify-center items-center">
            <DoneBanner />
            <p class="text-center text-xs text-amalan-black mt-7">Verifikasi data Anda telah sukses dilakukan.<br>Selanjutnya, silahkan lengkapi formulir pembelian<br>Produk <i>Credit Checkup</i>!</p>
            <button @click="redirectToHome" class="w-full flex items-center justify-center py-3 px-2 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg mt-7">
                <span class="text-amalan-white text-sm font-bold">
                    Lanjutkan Pembelian Produk
                </span>
            </button>
        </div>
        <div v-else class="bg-amalan-white px-6 pb-16 text-amalan-black" :class="[termAndConditionStatus ? 'overflow-hidden h-screen' : 'min-h-screen']">
            <Form @submit="nextStep" :validation-schema="updateForm" v-slot="{ handleSubmit, errors, meta }">
                <template v-if="currentStep === 0">
                    <div class="w-full pt-6">
                        <div class="mt-3.5">
                            <label class="text-sm font-bold">Gelar</label>
                            <div class="flex relative w-full">
                                <Field name="title" as="select" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none cursor-pointer" :class="{ 'border-amalan-red': errors.title }" :validateOnMount="true" v-model="formValues.title">
                                    <option v-for="(value, key) in dataTitle" :key="key" :value="value">{{ value }}</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="errors.title" class="mt-1 text-2xs text-amalan-red">{{errors.title}}</div>
                        </div>
                        <div class="mt-3.5">
                            <label class="text-sm font-bold">NIK</label>
                            <Field placeholder="NIK" name="nik" v-model="formValues.nik" :validateOnInput="true" :validateOnMount="true" type="text" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none" :class="{ 'border-amalan-red': errors.nik }" />
                            <div v-if="errors.nik" class="mt-1 text-2xs text-amalan-red">{{errors.nik}}</div>
                        </div>
                        <div class="mt-3.5">
                            <label class="text-sm font-bold">Nama Lengkap</label>
                            <Field placeholder="Nama Lengkap" name="name" v-model="formValues.name" :validateOnInput="true" :validateOnMount="true" type="text" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none" :class="{ 'border-amalan-red': errors.name }" />
                            <div v-if="errors.name" class="mt-1 text-2xs text-amalan-red">{{errors.name}}</div>
                        </div>
                        <div class="mt-3.5">
                            <label class="text-sm font-bold">Email</label>
                            <Field placeholder="Email" name="email" v-model="formValues.email" :validateOnInput="true" :validateOnMount="true" type="text" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none" :class="{ 'border-amalan-red': errors.email }" />
                            <div v-if="errors.email" class="mt-1 text-2xs text-amalan-red">Silahkan isi sesuai dengan alamat email yang digunakan.</div>
                        </div>
                        <div class="mt-3.5 grid grid-cols-2 gap-x-4">
                            <div>
                                <label class="text-sm font-bold">Tempat Lahir</label>
                                <Field placeholder="Tempat Lahir" name="birthplace" v-model="formValues.birthplace" :validateOnInput="true" :validateOnMount="true" type="text" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none" :class="{ 'border-amalan-red': errors.birthplace }" />
                                <div v-if="errors.birthplace" class="mt-1 text-2xs text-amalan-red">{{errors.birthplace}}</div>
                            </div>
                            <div>
                                <label class="text-sm font-bold">Tanggal Lahir</label>
                                <Field name="birthdate" v-model="formValues.birthdate" :validateOnInput="true" :validateOnMount="true" type="date" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none pl-4 pr-3" :class="{ 'border-amalan-red': errors.birthdate }" />
                                <div v-if="errors.birthdate" class="mt-1 text-2xs text-amalan-red">{{errors.birthdate}}</div>
                            </div>
                        </div>
                        <div class="mt-3.5 grid grid-cols-2 gap-x-4">
                            <div>
                                <label class="text-sm font-bold">Jenis Kelamin</label>
                                <div class="flex relative w-full">
                                    <Field name="gender" as="select" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none cursor-pointer" :class="{ 'border-amalan-red': errors.gender }" :validateOnMount="true" v-model="formValues.gender">
                                        <option v-for="gender in dataGender" :key="gender.key" :value="gender.code">{{ gender.description }}</option>
                                    </Field>
                                    <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                        <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div v-if="errors.gender" class="mt-1 text-2xs text-amalan-red">{{errors.gender}}</div>
                            </div>
                            <div>
                                <label class="text-sm font-bold">Agama</label>
                                <div class="flex relative w-full">
                                    <Field name="religionId" as="select" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none cursor-pointer" :class="{ 'border-amalan-red': errors.religionId }" :validateOnMount="true" v-model="formValues.religionId">
                                        <option v-for="religion in dataReligion" :key="religion.key" :value="religion.id">{{ religion.name }}</option>
                                    </Field>
                                    <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                        <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div v-if="errors.religionId" class="mt-1 text-2xs text-amalan-red">{{errors.religionId}}</div>
                            </div>
                        </div>
                        <div class="mt-3.5 grid grid-cols-2 gap-x-4">
                            <div>
                                <label class="text-sm font-bold">Pendidikan</label>
                                <div class="flex relative w-full">
                                    <Field name="educationId" as="select" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none cursor-pointer" :class="{ 'border-amalan-red': errors.educationId }" :validateOnMount="true" v-model="formValues.educationId">
                                        <option v-for="education in dataEducation" :key="education.key" :value="education.id">{{ education.name }}</option>
                                    </Field>
                                    <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                        <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div v-if="errors.educationId" class="mt-1 text-2xs text-amalan-red">{{errors.educationId}}</div>
                            </div>
                            <div>
                                <label class="text-sm font-bold">Status Perkawinan</label>
                                <div class="flex relative w-full">
                                    <Field name="maritalId" as="select" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none cursor-pointer" :class="{ 'border-amalan-red': errors.maritalId }" :validateOnMount="true" v-model="formValues.maritalId">
                                        <option v-for="marital in dataMarital" :key="marital.key" :value="marital.id">{{ marital.name }}</option>
                                    </Field>
                                    <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                        <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div v-if="errors.maritalId" class="mt-1 text-2xs text-amalan-red">{{errors.maritalId}}</div>
                            </div>
                        </div>
                        <div class="mt-3.5">
                            <label class="text-sm font-bold">Pekerjaan</label>
                            <div class="flex relative w-full">
                                <Field name="occupationId" as="select" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none cursor-pointer" :class="{ 'border-amalan-red': errors.occupationId }" :validateOnMount="true" v-model="formValues.occupationId">
                                    <option v-for="occupation in dataOccupation" :key="occupation.key" :value="occupation.id">{{ occupation.name }}</option>
                                </Field>
                                <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                    <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="errors.occupationId" class="mt-1 text-2xs text-amalan-red">{{errors.occupationId}}</div>
                        </div>
                        <div class="mt-16">
                            <button type="submit" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!meta.valid" @submit="handleSubmit">Simpan</button>
                        </div>
                    </div>
                </template>

                <template v-if="currentStep === 1">
                    <div class="mt-3.5">
                        <label class="text-sm font-bold">Alamat</label>
                        <Field placeholder="Alamat" name="street" v-model="formValues.street" :validateOnInput="true" :validateOnMount="true" type="text" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none" :class="{ 'border-amalan-red': errors.street }" />
                        <div v-if="errors.street" class="mt-1 text-2xs text-amalan-red">{{errors.street}}</div>
                    </div>
                    <div class="mt-3.5 grid grid-cols-2 gap-x-4">
                        <div>
                            <label class="text-sm font-bold">RT</label>
                            <Field placeholder="RT" name="rt" v-model="formValues.rt" type="text" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none" :class="{ 'border-amalan-red': errors.rt }" :validateOnInput="true" :validateOnMount="true"/>
                            <div v-if="errors.rt" class="mt-1 text-2xs text-amalan-red">{{errors.rt}}</div>
                        </div>
                        <div>
                            <label class="text-sm font-bold">RW</label>
                            <Field placeholder="RW" name="rw" v-model="formValues.rw" type="text" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none" :class="{ 'border-amalan-red': errors.rw }" :validateOnInput="true" :validateOnMount="true"/>
                            <div v-if="errors.rw" class="mt-1 text-2xs text-amalan-red">{{errors.rw}}</div>
                        </div>
                    </div>
                    <div class="mt-3.5">
                        <label class="text-sm font-bold">Kode Pos</label>
                        <Field placeholder="Kode Pos" name="postalCode" v-model="formValues.postalCode" type="text" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none" :class="{ 'border-amalan-red': errors.postalCode }" :validateOnInput="true" :validateOnMount="true"/>
                        <div v-if="errors.postalCode" class="mt-1 text-2xs text-amalan-red">{{errors.postalCode}}</div>
                    </div>
                    <div class="mt-3.5">
                        <label class="text-sm font-bold">Provinsi</label>
                        <div class="flex relative w-full">
                            <Field name="provinceId" as="select" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none cursor-pointer" :class="{ 'border-amalan-red': errors.provinceId }" :validateOnMount="true" v-model="formValues.provinceId" @change="getCity">
                                <option v-for="province in dataProvince" :key="province.key" :value="province.id">{{ province.name }}</option>
                            </Field>
                            <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                </svg>
                            </div>
                        </div>
                        <div v-if="errors.provinceId" class="mt-1 text-2xs text-amalan-red">{{errors.provinceId}}</div>
                    </div>
                    <div class="mt-3.5">
                        <label class="text-sm font-bold">Kota</label>
                        <div class="flex relative w-full">
                            <Field name="cityId" as="select" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none cursor-pointer" :class="{ 'border-amalan-red': errors.cityId }" :validateOnMount="true" v-model="formValues.cityId" @change="getDistrict">
                                <option v-for="city in dataCity" :key="city.key" :value="city.id">{{ city.name }}</option>
                            </Field>
                            <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                </svg>
                            </div>
                        </div>
                        <div v-if="errors.cityId" class="mt-1 text-2xs text-amalan-red">{{errors.cityId}}</div>
                    </div>
                    <div class="mt-3.5">
                        <label class="text-sm font-bold">Kecamatan</label>
                        <div class="flex relative w-full">
                            <Field name="districtId" as="select" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none cursor-pointer" :class="{ 'border-amalan-red': errors.districtId }" :validateOnMount="true" v-model="formValues.districtId" @change="getVillage">
                                <option v-for="district in dataDistrict" :key="district.key" :value="district.id">{{ district.name }}</option>
                            </Field>
                            <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                </svg>
                            </div>
                        </div>
                        <div v-if="errors.districtId" class="mt-1 text-2xs text-amalan-red">{{errors.districtId}}</div>
                    </div>
                    <div class="mt-3.5">
                        <label class="text-sm font-bold">Kelurahan</label>
                        <div class="flex relative w-full">
                            <Field name="villageId" as="select" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none cursor-pointer" :class="{ 'border-amalan-red': errors.villageId }" :validateOnMount="true" v-model="formValues.villageId">
                                <option v-for="village in dataVillage" :key="village.key" :value="village.id">{{ village.name }}</option>
                            </Field>
                            <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                </svg>
                            </div>
                        </div>
                        <div v-if="errors.villageId" class="mt-1 text-2xs text-amalan-red">{{errors.villageId}}</div>
                    </div>
                    <div class="mt-16">
                        <button type="submit" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!meta.valid" @submit="handleSubmit">Simpan</button>
                    </div>
                </template>

                <template v-if="currentStep === 2">
                    <div class="w-full py-8 flex justify-between items-center">
                        <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                            <span class="text-sm text-amalan-blue-1 font-bold">1</span>
                        </div>
                        <div class="bg-amalan-blue-1 h-px flex-none w-3 mx-1"></div>
                        <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400">
                            <span class="text-sm font-bold text-amalan-blue-1">2</span>
                        </div>
                        <div class="bg-amalan-blue-1 h-px flex-grow mx-1"></div>
                        <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                            <span class="text-sm text-amalan-blue-1 font-bold">3</span>
                        </div>
                        <p class="flex-none text-amalan-blue-1 text-sm font-bold ml-1">Cek Data e-KTP</p>
                    </div>
                    <div class="w-full">
                        <p class="text-center text-amalan-black text-xs">Pastikan data Anda sesuai dengan data e-KTP,<br>silahkan ubah jika terdapat data yang keliru.</p>
                        <div class="w-full mt-7 px-4 py-4 border box-border rounded-lg" :class="[ isFirstFormValid ? 'bg-amalan-white border-amalan-gray-4' : 'bg-amalan-red-light border-amalan-red' ]">
                            <div class="w-full flex justify-between items-center">
                                <p class="text-amalan-blue-1 text-base font-bold">Data Diri</p>
                                <div @click="goToFirstForm" class="w-6 h-6 rounded-lg shadow-amalan-md border mr-1 flex justify-center items-center cursor-pointer" :class="[ isFirstFormValid ? 'bg-amalan-white border-amalan-blue-1 hover:border-amalan-blue-2 hover:bg-amalan-gray-5' : 'bg-amalan-blue-1 border-amalan-blue-1 hover:border-amalan-blue-2 hover:bg-amalan-blue-2']">
                                    <img v-if="isFirstFormValid" src="@/assets/img/pencil-primary-icon.svg" class="ml-px">
                                    <img v-else src="@/assets/img/pencil-secondary-icon.svg" class="ml-px">
                                </div>
                            </div>
                            <div class="border-t border-amalan-blue-1 mt-3.5 pt-3.5 grid grid-cols-10 gap-y-2.5 text-amalan-black text-xs">
                                <div class="font-bold col-span-4">Nama</div>
                                <div class="col-span-6">: {{ formValuesPreview.title ? formValuesPreview.title === 'Bpk' ? 'Bapak' : 'Ibu' : '' }} {{ formValuesPreview.name || '' }}</div>
                                <div class="font-bold col-span-4">NIK</div>
                                <div class="col-span-6">: {{ formValuesPreview.nik || '' }}</div>
                                <div class="font-bold col-span-4">Email</div>
                                <div class="col-span-6">: {{ formValuesPreview.email || '' }}</div>
                                <div class="font-bold col-span-4">Jenis Kelamin</div>
                                <div class="col-span-6">: {{ formValuesPreview.gender || '' }}</div>
                                <div class="font-bold col-span-4">Tempat/Tgl Lahir</div>
                                <div class="col-span-6">: {{ formValuesPreview.birthplace || '' }}{{ formValuesPreview.birthdate ? '/' + $filters.formatDateOnlyDate(formValuesPreview.birthdate) : '' }}</div>
                                <div class="font-bold col-span-4">Agama</div>
                                <div class="col-span-6">: {{ formValuesPreview.religion || '' }}</div>
                                <div class="font-bold col-span-4">Status</div>
                                <div class="col-span-6">: {{ formValuesPreview.marital || '' }}</div>
                                <div class="font-bold col-span-4">Pekerjaan</div>
                                <div class="col-span-6">: {{ formValuesPreview.occupation || '' }}</div>
                                <div class="font-bold col-span-4">Pendidikan</div>
                                <div class="col-span-6">: {{ formValuesPreview.education || '' }}</div>
                            </div>
                        </div>
                        <p v-if="!isFirstFormValid" class="text-amalan-red text-2xs mt-1">Lengkapi data yang dibutuhkan pada Data Diri Anda</p>
                        <div class="w-full mt-3.5 px-4 py-4 border box-border rounded-lg" :class="[ isSecondFormValid ? 'bg-amalan-white border-amalan-gray-4' : 'bg-amalan-red-light border-amalan-red' ]">
                            <div class="w-full flex justify-between items-center">
                                <p class="text-amalan-blue-1 text-base font-bold">Alamat di e-KTP</p>
                                <div @click="goToSecondForm" class="w-6 h-6 rounded-lg shadow-amalan-md border mr-1 flex justify-center items-center cursor-pointer" :class="[ isSecondFormValid ? 'bg-amalan-white border-amalan-blue-1 hover:border-amalan-blue-2 hover:bg-amalan-gray-5' : 'bg-amalan-blue-1 border-amalan-blue-1 hover:border-amalan-blue-2 hover:bg-amalan-blue-2']">
                                    <img v-if="isSecondFormValid" src="@/assets/img/pencil-primary-icon.svg" class="ml-px">
                                    <img v-else src="@/assets/img/pencil-secondary-icon.svg" class="ml-px">
                                </div>
                            </div>
                            <div class="border-t border-amalan-blue-1 mt-3.5 pt-3.5 grid grid-cols-10 gap-y-2.5 text-amalan-black text-xs">
                                <div class="font-bold col-span-4">Alamat</div>
                                <div class="col-span-6">: {{ formValuesPreview.street || '' }}</div>
                                <div class="font-bold col-span-4">RT/RW</div>
                                <div class="col-span-6">: {{ formValuesPreview.rt || '' }}{{ formValuesPreview.rw ? '/' + formValuesPreview.rw : '' }}</div>
                                <div class="font-bold col-span-4">Kelurahan</div>
                                <div class="col-span-6">: {{ formValuesPreview.village || '' }}</div>
                                <div class="font-bold col-span-4">Kecamatan</div>
                                <div class="col-span-6">: {{ formValuesPreview.district || '' }}</div>
                                <div class="font-bold col-span-4">Kota</div>
                                <div class="col-span-6">: {{ formValuesPreview.city || '' }}</div>
                                <div class="font-bold col-span-4">Provinsi</div>
                                <div class="col-span-6">: {{ formValuesPreview.province || '' }}</div>
                                <div class="font-bold col-span-4">Kode Pos</div>
                                <div class="col-span-6">: {{ formValuesPreview.postalCode || '' }}</div>
                            </div>
                        </div>
                        <p v-if="!isSecondFormValid" class="text-amalan-red text-2xs mt-1">Lengkapi data yang dibutuhkan pada Alamat Anda</p>
                        <div class="px-4 pt-2 flex justify-between items-start" :class="[ route.meta.creditScoreKYC ? 'block' : 'hidden' ]">
                            <label class="font-medium text-xs inline-flex" for="terms">
                                <label class="h-4 w-4 border-2 border-gray-400 rounded mr-2 p-0 cursor-pointer" :class="{ 'border-red-600': errors.terms, 'bg-amalan-yellow-400 border-amalan-yellow-400' : formValues.terms }" @click="termsChecked">
                                    <CheckIcon v-if="formValues.terms" class="w-3 h-3 mx-auto text-amalan-blue-1" />
                                    <div v-else class="w-3 h-3 mx-auto bg-transparent"></div>
                                </label>
                                <Field name="terms" v-model="formValues.terms" type="checkbox" class="hidden" :validateOnMount="true" :validateOnInput="true" />
                            </label>
                            <span class="text-xs cursor-pointer"><span @click="termsChecked">Saya menyetujui seluruh </span><span class="underline text-blue-700" @click="showTermsAndCondition">syarat dan ketentuan</span><span @click="termsChecked"> yang berlaku di <b>amalan international.</b></span></span>
                        </div>
                        <div v-if="errors.terms" class="text-xs text-red-600 px-4">*{{errors.terms}}</div>
                        <div class="pt-8 flex justify-between items-start">
                            <label class="text-xs inline-flex" for="personalDataAgreement">
                                <label class="h-4 w-4 border mr-2 cursor-pointer flex justify-center items-center" :class="[ errors.personalDataAgreement ? 'border-amalan-red bg-amalan-white': 'bg-amalan-yellow-400 border-amalan-yellow-400' ]"  @click="personalDataAgreementChecked">
                                    <CheckIcon v-if="formValues.personalDataAgreement" class="w-4 text-amalan-blue-1" />
                                </label>
                                <Field name="personalDataAgreement" v-model="formValues.personalDataAgreement" type="checkbox" class="hidden" :validateOnMount="true" :validateOnInput="true" />
                            </label>
                            <span class="text-xs cursor-pointer select-none" @click="personalDataAgreementChecked">Pengguna dengan ini menyetujui untuk memberikan data pengguna kepada amalan dan amalan akan menjamin kerahasiaan serta keamanan data tersebut.</span>
                        </div>
                        <div v-if="errors.personalDataAgreement" class="text-2xs text-amalan-red px-6 mt-1">*{{errors.personalDataAgreement}}</div>
                    </div>
                    <div class="mt-16">
                        <button type="submit" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ formValues.terms && formValues.personalDataAgreement && isFirstFormValid && isSecondFormValid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!formValues.terms || !formValues.personalDataAgreement || !isFirstFormValid || !isSecondFormValid">Konfirmasi</button>
                    </div>
                </template>
            </Form>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>
