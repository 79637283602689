import axios from "../axios"

const sentGCLID = (encodedURI) => {
    const gclid = getParam('gclid', decodeURIComponent(encodedURI))
    if(gclid) axios.post('/customer/utm-gclid', {gclid})
}

const getParam = (params, url) => {
    const match = RegExp('[?&]' + params + '=([^&]*)').exec(url)
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

export default {
    sentGCLID
}