export const feeDescription = {
    registrationFee: 'Biaya ini merupakan tambahan tagihan  yang harus Anda bayarkan pada saat Anda bergabung dengan amalan.',
    successFee: 'Biaya ini dibayarkan secara terpisah untuk masing-masing utang ketika Anda dan penyedia pinjaman telah menyetujui program pelunasan.'
}

export const benefitConfig = {
    amalanProtect: {
        name_text: 'amalanPROTECT',
        description: `amalanPROTECT akan menangani <i>debt collector</i> yang melakukan penagihan secara tak beretika dengan melaporkannya kepada manajemen bank terkait, dan ke OJK jika perlu.`
    },
    slikReview: {
        name_text: 'Pengecekan SLIK',
        description: `Anda dapat mengirimkan laporan SLIK yang sudah Anda unduh ke analis SLIK kami, yang kemudian akan meninjau laporan tersebut bersama Anda untuk menemukan jika terdapat kesalahan data yang dapat mempengaruhi skor kredit Anda.`
    },
    slikCorrection: {
        name_text: 'Koreksi SLIK',
        description: `Kami akan meminta bank untuk memperbaiki kekeliruan data SLIK Anda. Walau memang memerlukan waktu, proses ini sangat penting bagi Anda untuk mengoreksi laporan SLIK dan meningkatkan skor kredit Anda.`
    },
    financialCheckup: {
        name_text: '<i>Debt Diagnostics</i>',
        description: `Program diagnostik keuangan kami akan membantu Anda merencanakan jalan keluar dari masalah utang Anda secara lebih baik sekaligus mempersiapkan masa depan keuangan Anda yang lebih cerah.`
    },
    cvReview: {
        name_text: '<i>Review</i> CV',
        description: `Spesialis HR kami dapat membantu meninjau kualitas CV Anda. Dengan bitu, Anda bisa meningkatkan peluang untuk melangkah lebih jauh di lamaran kerja Anda selanjutnya.`
    },
    linkedinReview: {
        name_text: '<i>Review</i> LinkedIn',
        description: `<i>Recruiter</i> kerap melakukan pencarian kandidat melalui Linkedin dan juga memeriksa informasi CV dengan profil Linkedin Anda. Kami dapat meninjau profil Linkedin Anda untuk memastikan profil Anda meningkatkan peluang Anda mendapatkan perkerjaan baru yang lebih baik.`
    },
    secretaryService: {
        name_text: '<i>Secretary Service</i>',
        description: `Biarkan amalan menangani semua komunikasi perbankan Anda dengan bank dan <i>debt collector</i>. Kami dapat menjawab telepon dan <i>WhatsApp</i> untuk Anda, serta menerima surat/laporan tagihan untuk Anda.`,
    },
    dedicatedSeniorConsultant: {
        name_text: 'Konsultan Eksklusif',
        description: `Kami akan menugaskan salah satu konsultan senior kami, yang terpercaya dan berpengalaman, sebagai kontak utama Anda untuk memberikan dukungan terbaik sampai semua pinjaman Anda diselesaikan.`
    },
}