<template>
    <div class="fixed inset-0 z-50 bg-gray-900 bg-opacity-75 flex items-center justify-center p-3">
        <div class="bg-white max-w-lg rounded-lg overflow-hidden px-6 py-4 space-y-4">
            <div class="flex justify-center">
                <span class="inline-flex items-center bg-opacity-75 rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; display: block; shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <circle cx="50" cy="50" r="31" stroke-width="7" stroke="#ffe700" stroke-dasharray="48.69468613064179 48.69468613064179" fill="none" stroke-linecap="round">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.0526315789473684s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
                        </circle>
                    </svg>
                </span>
            </div>
            <div class="flex flex-col items-center space-y-2">
                <span class="font-semibold text-gray-500 text-sm">Mohon Tunggu...</span>
            </div>
        </div>
    </div>
</template>