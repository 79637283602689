<template>
    <div class="justify-center flex">
        <div class="w-full max-w-lg shadow-xl min-h-screen h-auto">
            <Body />
            <div class="py-4">
                <p class="text-sm text-center"> © {{ new Date().getFullYear() }} All rights reserved. <span class="text-gray-600">PT amalan International Indonesia.</span></p>
            </div>
        </div>
    </div>
</template>

<script>
import Body from '../Body';

export default {
    components: {
        Body,
    },
}
</script>