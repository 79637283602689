import axios from "../../axios";

const state = () => ({
    services : {
        done: [],
        pending: []
    },
    all_services: {
        isFetching: false,
        data: [],
    },
    latestService : null,
    service_statuses: [
        {
            name: 'CC_waiting for payment',
            text: 'Pending',
            class: 'text-amalan-blue-light'
        },
        {
            name: 'CC_waiting for service',
            text: 'Diproses',
            class: 'text-amalan-orange'
        },
        {
            name: 'CC_done',
            text: 'Berhasil',
            class: 'text-amalan-green-light'
        },
        {
            name: 'CC_expired',
            text: 'Kadaluwarsa',
            class: 'text-amalan-red'
        },
        {
            name: 'CC_cancel',
            text: 'Dibatalkan',
            class: 'text-amalan-red'
        },
    ]
});

const getters = {
    getServices(state) {
        return state.services;
    },
    getServiceStatuses(state) {
        return state.service_statuses;
    },
    getAllServices(state) {
        return state.all_services;
    },
    getLatestService(state){
        return state.latestService;
    }
};

const actions = {
    async requestServices({ commit, state }) {
        state.all_services.isFetching = true;
        axios.get('/customer/services')
        .then( resp => {
            state.all_services.isFetching = false;
            if(resp.status == 200){
                commit("requestServices", resp.data.data);
            }
        })
    },
    async requestLatestService({ dispatch, commit, rootGetters }){
        axios.get('/customer/services/latest')
        .then( resp => {
            if(resp.status == 200){
                commit("requestLatestService", resp.data.data);
                dispatch('pendingUserActivity/setPendingActivityData', {
                    userStage: rootGetters['user/getUserStage'],
                    signUpStatus: rootGetters['contact/getSignUpStatus'],
                    lastAmalanProtect: rootGetters['formAmalanProtect/getLastProtect'],
                    latestService: rootGetters['services/getLatestService'],
                    timeToDocCollection: rootGetters['docCollection/getTimeToDocCollection'],
                    billCompleted: rootGetters['docCollection/getbillStatus'],
                    docCompleted: rootGetters['docCollection/getDocStatus'],
                    poaDocument: rootGetters['user/getDocumentPoa'],
                    lastAdditionalSuf: rootGetters['additionalSUF/getLastAdditionalSuf'],
                    lastUpgradePackage: rootGetters['upgradePricingPackage/getLastUpgradePackage'],
                    negotiationResultApprovalFlag: rootGetters['negotiationResult/getNegotiationResultApprovalFlag']
                }, {
                    root: true 
                })
            }
        })
    }
};

const mutations = {
    requestServices(state, payload) {
        if(payload.length > 0){
            state.services.pending = []
            state.services.done = []
            for(let i in payload){
                if(payload[i].service_stage.name == "CC_waiting for payment"){
                    state.services.pending.push(payload[i])
                }else{
                    state.services.done.push(payload[i])
                }
            }

            state.all_services.data = payload ?? [];
        }
    },
    requestLatestService(state, payload){
        state.latestService = payload;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
