<script setup>
import { defineProps, defineEmits } from 'vue'
import DoneBanner from '@/components/icon/DoneBanner'

defineProps({
    visible: Boolean,
    content: String
})

const emit = defineEmits(['onClose'])

const closeModal = () => emit('onClose')

</script>

<template>
    <Transition name="fade-fast">
        <div v-show="visible" class="absolute left-0 right-0 top-14 bottom-0 bg-black bg-opacity-50 p-6 flex justify-center items-center">
            <div class="w-full py-8 px-4 bg-amalan-white rounded-lg -mt-10 cursor-pointer" @click="closeModal">
                <div class="w-full flex justify-center items-center mt-6">
                    <DoneBanner />
                </div>
                <p class="mt-8 text-amalan-black text-xs text-center" v-html="content"></p>
            </div>
        </div>
    </Transition>
</template>

<style scoped>
.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.3s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.3s ease
}
</style>