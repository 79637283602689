<script setup>
import { ChevronRightIcon } from '@heroicons/vue/solid'
import RDPCardMini from '../Akun/RDP/Components/RDPCardMini'
import LoanProgressWidget from '../Akun/ManajemenPinjaman/LoanProgress/Components/WidgetMini'
import { useRouter } from 'vue-router'
import { ref, onMounted, computed, inject, watch } from 'vue'
import { useStore } from 'vuex'
import axios from '@/axios'

const router = useRouter()
const store = useStore()
const isLoadingOverlay = ref(false)
const swal = inject('$swal')

const getterUser = computed(() => store.getters['user/getUser'])
const getOCRStatus = computed(() => store.getters['user/getOCRStatus'])
const canSignup = computed(() => store.getters['customCondition/getCanSignup'])
const canSignupMessage = computed(() => store.getters['customCondition/getMessage'])
const signUpStatus = computed(() => store.getters['contact/getSignUpStatus'])
const billCompleted = computed(() => store.getters['docCollection/getbillStatus'])
const docCompleted = computed(() => store.getters['docCollection/getDocStatus'])
const timeToDocCollection = computed(() => store.getters['docCollection/getTimeToDocCollection'])
const rdpData = computed(() => store.getters['RDPManagement/getRdpData'])
const notificationData = computed(() => store.getters['pendingUserActivity/getPendingActivityData'])
const notificationDataFetching = computed(() => store.getters['pendingUserActivity/getFetchStatus'])
const loanProgressFetchStatus = computed(() => store.getters['loanProgress/getOpportunitiesFetchStatus'])

const swalError = swal.mixin({
    customClass: {
        confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600',
        cancelButton: 'absolute -top-3 -right-3'
    },
    buttonsStyling: false,
    title: '<img src="/img/alert-error-icon.svg" class="w-12 h-12 mx-auto">',
    width: '340px',
    allowOutsideClick: false,
    reverseButtons: true,
})

const swalModalConfirm = swal.mixin({
    customClass: {
        confirmButton: 'bg-blue-900 text-white mx-2 py-2 px-20 rounded-lg border border-blue-900 text-sm font-semibold hover:bg-blue-700 hover:border-blue-700',
        cancelButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-700 hover:border-blue-700'
    },
    buttonsStyling: false,
    title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
    width: '340px',
    allowOutsideClick: false,
    reverseButtons: true,
})

watch(canSignup, (newVal) => {
    checkCanSignup(newVal)
})

watch(signUpStatus, (statusCode) => {
    if(statusCode !== 200) return

    if((timeToDocCollection.value == true && !billCompleted.value) || timeToDocCollection.value == true && !docCompleted.value){
        if(localStorage.getItem('modal-doc-collection')) return

        swalModalConfirm.fire({
            html: '<p class="text-center text-sm mt-2">Anda belum melengkapi dokumen Lembar Tagihan pada <b>Daftar Pinjaman</b> dan Dokumen Wajib pada <b>Daftar Dokumen</b></p>',
            showCancelButton: false,
            confirmButtonText: 'Unggah Sekarang',
        }).then((result) => {
            if (result.isConfirmed){
                localStorage.setItem('modal-doc-collection', true);
                router.push({ name: 'doc collection - lembar tagihan' })
            }
        })
    }
})

const goTo = (path) => {
    isLoadingOverlay.value = true

    if(getOCRStatus.value !== true && path === '/sign-up/onboarding') localStorage.setItem('from-onboarding-go-to', 'Sign Up Onboarding')
    if(getOCRStatus.value !== true && path === '/amalan-protect/data-pinjaman') localStorage.setItem('from-onboarding-go-to', 'amalan protect - data pinjaman')
    router.push(path)
}

const goToExternal = (url) => window.location = url

const fromOnboardingTo = () => {
    const isFromOnboarding = localStorage.getItem('from-onboarding-go-to')
    if(!isFromOnboarding) return

    isLoadingOverlay.value = true
    let waitForOCRStatusDone = false
    switch (isFromOnboarding) {
        case 'lead qualification - daftar pinjaman':
            waitForOCRStatusDone = false
            break
        case 'Sign Up Onboarding':
            waitForOCRStatusDone = true
            break
        case 'rdp opening - document poa':
            waitForOCRStatusDone = true
            break
        case 'amalan protect - data pinjaman':
            waitForOCRStatusDone = true
            break
        case 'Catalog List':
            if(localStorage.getItem('ccu-product-destination')){
                const applied_program = localStorage.getItem('ccu-product-destination').replaceAll('-', ' ')
                // localStorage.removeItem('ccu-product-destination')
                axios.post('/customer/applied-program', { applied_program })
            }

            waitForOCRStatusDone = true
            break
        default:
            waitForOCRStatusDone = false
            break
    }

    if(waitForOCRStatusDone){
        if(getOCRStatus.value == true) localStorage.removeItem('from-onboarding-go-to')
        router.push({ name : isFromOnboarding })
    }else{
        localStorage.removeItem('from-onboarding-go-to')
        router.push({ name : isFromOnboarding })
    }
}

const getName = () => {
    if(getterUser.value.first_name.includes('+62')) return getterUser.value.phone_number.replace('+62', '0')
    const firstName = getterUser.value.first_name ? getterUser.value.first_name.trim() : ""
    const lastName = getterUser.value.last_name ? getterUser.value.last_name.trim() : ""
    return (firstName + " " + lastName).trim()
}

const checkCanSignup = (value) => {
    if(value == null || value) return

    isLoadingOverlay.value = false
    switch (canSignupMessage.value) {
        case 'default':
            swalError.fire({
                html: '<p class="text-center text-sm mt-2">Maaf, sepertinya Anda pengguna baru. Untuk menggunakan layanan Keringanan Pinjaman Full Service, Anda diharuskan menghubungi tim kami terlebih dahulu. <br><br>Silahkan tekan tombol bantuan dibawah ini.</p>',
                showCancelButton: true,
                confirmButtonText: 'Bantuan',
                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
            }).then((result) => {
                if (result.isConfirmed){
                    window.location = 'https://wa.me/622150933150?text=' + 
                    'Mohon bantuan saya pengguna baru dan saya ingin mendaftar layanan Keringanan Pinjaman Full Service di web.amalan.com'
                    return
                }
            });
            break;
        case 'package':
            swalError.fire({
                html: '<p class="text-center text-sm mt-2">Maaf, sepertinya Anda belum memilih Paket Layanan Keringanan Pinjaman Full Service dari amalan.<br><br> Silahkan klik tombol dibawah untuk mendapatkan bantuan dari konsultan kami.</p>',
                showCancelButton: true,
                confirmButtonText: 'Bantuan',
                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
            }).then((result) => {
                if (result.isConfirmed){
                    window.location = 'https://wa.me/622150933150?text=' + 
                    'Mohon bantuan saya pengguna baru belum memilih Paket dan saya ingin mendaftar layanan Keringanan Pinjaman Full Service di web.amalan.com'
                    return
                }
            });
            break;
    
        default:
            break;
    }

    store.dispatch('customCondition/changeCanSignup', { canSignup: null, message: 'default' })
}

onMounted(() => {
    store.dispatch('docCollection/checkTimeToDocumentCollection')
    fromOnboardingTo()
    checkCanSignup(canSignup.value)
    store.dispatch('pendingUserActivity/initialPendingActivityData')
})

</script>

<template>
    <div class="w-full bg-amalan-white min-h-screen pb-24">
        <div class="w-full h-full home-gradient-color rounded-b-amalan-sm py-8 px-6" style="min-height: 316px;">
            <div class="w-full flex justify-between items-center mb-3">
                <div class="text-amalan-black">
                    <p class="text-sm">Halo,</p>
                    <p class="text-lg font-bold">{{ getName() }}✨</p>
                </div>
                <img src="@/assets/img/logo-amalan.png" class="h-10">
            </div>

            <loading-data-without-margin v-if="notificationDataFetching === 'FETCHING'" />
            <template v-else>
                <template v-if="(notificationData.length === 0) && (rdpData.isExist === 'NOT_FOUND' || rdpData.isExist === 'ERROR')">
                    <div class="w-full h-44 mt-4 bg-amalan-white rounded-xl flex justify-center items-center relative">
                        <div class="w-9 h-9 flex-none rounded-full bg-amalan-yellow-400 flex justify-center items-center border-4 border-amalan-blue-7 left-2.5 top-4 absolute">
                            <div class="absolute w-1.5 h-1.5 rounded-full bg-amalan-red top-1.5 right-2"></div>
                            <img src="@/assets/img/lonceng.svg" class="w-3.5">
                        </div>
                        <p class="text-xs text-amalan-black text-center">Anda belum memiliki<br>notifikasi pengingat aktivitas untuk saat ini</p>
                    </div>
                </template>
                <template v-else>
                    <div v-if="(notificationData.length > 0)" class="w-full bg-amalan-white shadow-amalan-sm rounded-xl py-4 mt-4 hover:bg-amalan-gray-5 cursor-pointer" @click="goTo(notificationData[0].link)">
                        <div class="flex justify-between items-center overflow-hidden">
                            <div class="w-9 h-9 flex-none rounded-full bg-amalan-yellow-400 flex justify-center items-center border-4 border-amalan-blue-7 ml-2.5 mr-2.5 relative">
                                <div class="absolute w-1.5 h-1.5 rounded-full bg-amalan-red top-1.5 right-2"></div>
                                <img src="@/assets/img/lonceng.svg" class="w-3.5">
                            </div>
                            <div class="flex-grow">
                                <p class="text-xs text-amalan-black">{{ notificationData[0].body }}</p>
                                <p class="text-xs text-amalan-blue-1 font-bold">{{ notificationData[0].title }}</p>
                            </div>
                            <div class="flex-none flex justify-center items-center w-14">
                                <button class="border border-amalan-blue-1 bg-amalan-white rounded-lg shadow-amalan-md w-6 h-6 flex justify-center items-center">
                                    <ChevronRightIcon class="h-5 text-amalan-blue-1" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-else class="w-full bg-amalan-white shadow-amalan-sm rounded-xl py-4 mt-4">
                        <div class="flex justify-between items-center overflow-hidden">
                            <div class="w-9 h-9 flex-none rounded-full bg-amalan-yellow-400 flex justify-center items-center border-4 border-amalan-blue-7 ml-2.5">
                                <img src="@/assets/img/lonceng.svg" class="w-3.5">
                            </div>
                            <div class="flex-grow px-4">
                                <p class="text-xs text-amalan-black">Anda belum memiliki notifikasi pengingat aktivitas untuk saat ini</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="rdpData.isExist === 'FOUND'" class="w-full mt-4">
                        <RDPCardMini :card-type="rdpData.cardType" :account-number="rdpData.accountNumber" :full-name="rdpData.fullName" :route-back="'widget'" />
                    </div>
                    <div v-if="signUpStatus === 200 && !loanProgressFetchStatus" class="w-full mt-4">
                        <LoanProgressWidget />
                    </div>

                    <div v-if="rdpData.isExist !== 'FOUND' && signUpStatus !== 200" class="w-full h-20 mt-4 bg-amalan-white rounded-xl flex justify-center items-center">
                        <p class="text-xs text-amalan-black text-center">Anda belum memiliki<br>notifikasi pengingat aktivitas lainnya</p>
                    </div>
                </template>
            </template>
        </div>
        <div class="w-full py-8 px-6">
            <div class="w-full flex justify-between items-center">
                <p class="text-base font-bold text-amalan-blue-1">Layanan amalan</p>
                <div class="flex-grow h-px bg-amalan-blue-1 ml-2"></div>
            </div>
            <div @click="goTo('/sign-up/onboarding')" class="w-full px-5 py-5 flex justify-between items-center bg-amalan-white shadow-amalan-sm rounded-lg hover:bg-amalan-gray-5 mt-4 cursor-pointer">
                <div class="w-14 flex-none flex justify-center">
                    <img src="@/assets/img/beranda/signup-new.svg" class="w-14">
                </div>
                <div class="ml-3 flex-grow">
                    <p class="text-xs font-bold text-amalan-blue-1">Program Keringanan Utang</p>
                    <p class="text-xs text-amalan-black">Solusi keringanan pinjaman terbaik sesuai kebutuhan Anda.</p>
                </div>
            </div>
            <div @click="goTo('/amalan-protect/data-pinjaman')" class="w-full px-5 py-5 flex justify-between items-center bg-amalan-white shadow-amalan-sm rounded-lg hover:bg-amalan-gray-5 mt-4 cursor-pointer">
                <div class="w-14 flex-none flex justify-center">
                    <img src="@/assets/img/beranda/amalan-protect-new.svg" class="w-14">
                </div>
                <div class="ml-3 flex-grow">
                    <p class="text-xs font-bold text-amalan-blue-1">amalanPROTECT</p>
                    <p class="text-xs text-amalan-black">Atasi perilaku Debt Collector yang tidak sesuai dengan aturan.</p>
                </div>
            </div>
            <div @click="goTo('/credit-checkup/home')" class="w-full px-5 py-5 flex justify-between items-center bg-amalan-white shadow-amalan-sm rounded-lg hover:bg-amalan-gray-5 mt-4 cursor-pointer">
                <div class="w-14 flex-none flex justify-center">
                    <img src="@/assets/img/beranda/credit-checkup-new.svg" class="w-14">
                </div>
                <div class="ml-3 flex-grow">
                    <p class="text-xs font-bold text-amalan-blue-1">Credit Score, Report, & Checkup</p>
                    <p class="text-xs text-amalan-black">Cek analisa keuangan dan peluang pinjaman Anda segera.</p>
                </div>
            </div>
            <div @click="goToExternal('https://lp.amalan.com/id-kredit-bijak-karyawan/')" class="w-full px-5 py-5 flex justify-between items-center bg-amalan-white shadow-amalan-sm rounded-lg hover:bg-amalan-gray-5 mt-4 cursor-pointer">
                <div class="w-14 flex-none flex justify-center">
                    <img src="@/assets/img/beranda/kbk.svg" class="w-14">
                </div>
                <div class="ml-3 flex-grow">
                    <p class="text-xs font-bold text-amalan-blue-1">Kredit Bijak Karyawan</p>
                    <p class="text-xs text-amalan-black">Solusi baru untuk lunasi pinjaman bagi karyawan bijak.</p>
                </div>
            </div>
        </div>
        <p class="text-center text-2xs text-amalan-gray-1">©{{ new Date().getFullYear() }} PT amalan international indonesia</p>
        <loading-overlay v-if="isLoadingOverlay" />
    </div>
</template>