<script setup>
import NavbarWithBackV2 from '@/components/NavbarWithBackV2.vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { ref, computed, onMounted } from 'vue'
import opportunityHelper from '@/helpers/opportunity-helper'
import OpportunityCard from '@/components/Card/OpportunityCard.vue'
import ThanksModalWithMarkOption from '@/components/Modal/ThanksModalWithMarkOption.vue'
import apiMediationService from "@/axios/mediationService"
import { CheckIcon } from '@heroicons/vue/solid'
import useModalPopUp from '@/composables/modalPopUp'

const router = useRouter()
const route = useRoute()
const store = useStore()
const modalPopUp = useModalPopUp()

const targetPelunasanForm = computed(() => store.getters['targetPelunasan/getTargetPelunasanForm'])
const targetPelunasanFormReason = computed(() => store.getters['targetPelunasan/getTargetPelunasanFormReason'])

const showModal = ref(false)
const terms = ref(false)
const isLoading = ref(false)
const autoCloseModalTOInstance = ref(null)

const closeThanksModal = () => {
    if(autoCloseModalTOInstance.value){
        clearTimeout(autoCloseModalTOInstance.value)
        autoCloseModalTOInstance.value = null
    }

    return router.push({ name: 'Loan Management - Home' })
}

const autoCloseModal = () => {
    const autoCloseModalTO = setTimeout(() => {
        showModal.value = false
        router.push({ name: 'Negotiation Process - Home' })
    }, 7000);

    autoCloseModalTOInstance.value = autoCloseModalTO
}

const submit = async () => {
    if(!terms.value) return
    isLoading.value = true

    const submission = targetPelunasanForm.value.reason.map((value) => {
        if(value === 'optional') return targetPelunasanForm.value.optional
        return value
    })

    try {
        await apiMediationService.post('api/negotiation-approval', {
            newIdOpportunity: targetPelunasanForm.value.selectedOpportunity.Id ?? null,
            replaceIdOpportunity: targetPelunasanForm.value.canceledOpportunity.Id ?? undefined,
            submission: submission.join(';')
        })

        store.dispatch('targetPelunasan/setCurrentTargetPelunasan')
        store.dispatch('targetPelunasan/setNewTargetPelunasan')
        store.dispatch('targetPelunasan/resetTargetPelunasanForm')
        isLoading.value = false
        showModal.value = true
        autoCloseModal()
    } catch {
        isLoading.value = false
        modalPopUp.swalError.fire({
            html: '<p class="text-center text-xs mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        }).then((result) => {
            if(!result.isConfirmed) return
            return window.location = 'https://wa.me/622150933150?text=' + 
            'Mohon bantuan saya gagal terus untuk mengirim data pengajuan target pelunasan pada proses negosiasi di web.amalan.com'
        })
    }
}

const termsChecked = () => terms.value = !terms.value

onMounted(() => {
    if(targetPelunasanForm.value.isCompleted) return
    return router.push({name: 'Negotiation Process - Target Pelunasan - Home'})
})

</script>

<template>
    <div class="min-h-screen bg-amalan-white relative">
        <NavbarWithBackV2 :title="route.meta.title" @back-button="router.go(-1)" />
        <div class="w-full pt-8 pb-20 px-6 bg-amalan-white">
            <p class="text-center text-amalan-black text-xs sm:text-sm">Pastikan data berikut sesuai dan silahkan ubah<br>jika terdapat data yang keliru.</p>
            <div class="w-full mt-8 p-4 border border-amalan-gray-4 rounded-lg text-amalan-black">
                <div class="w-full flex justify-between items-center pb-3.5 border-b border-amalan-blue-1">
                    <p class="text-amalan-blue-1 font-bold text-base">Detail Perubahan</p>
                    <router-link :to="{ name : 'Negotiation Process - Target Pelunasan - Add', params: { opportunityId: targetPelunasanForm.canceledOpportunity?.Id ?? null } }" class="w-6 h-6 rounded-lg shadow-amalan-md border mr-1 flex justify-center items-center cursor-pointer bg-amalan-white border-amalan-blue-1 hover:bg-amalan-gray-5">
                        <img src="@/assets/img/pencil-primary-icon.svg" class="ml-px">
                    </router-link>
                </div>
                <template v-if="targetPelunasanForm.isCompleted">
                    <template v-if="targetPelunasanForm.canceledOpportunity.Id">
                        <p class="text-xs font-bold mt-3.5 mb-1">Target Pelunasan Lama</p>
                        <OpportunityCard
                            :bank-name="targetPelunasanForm.canceledOpportunity.Name ? opportunityHelper.getBankName(targetPelunasanForm.canceledOpportunity.Name, targetPelunasanForm.canceledOpportunity.Loan_Type_2__c) : '-'"
                            :loan-type="targetPelunasanForm.canceledOpportunity.Loan_Type_2__c ? opportunityHelper.getLoanTitle(targetPelunasanForm.canceledOpportunity.Loan_Type_2__c) : '-'"
                            :outstanding="targetPelunasanForm.canceledOpportunity.Estimated_Outstanding_Amount__c ? $filters.format_number(targetPelunasanForm.canceledOpportunity.Estimated_Outstanding_Amount__c) : '-'"
                            :image-source="targetPelunasanForm.canceledOpportunity.Name && targetPelunasanForm.canceledOpportunity.Loan_Type_2__c ? require('@/assets/img/bank-lender-icon/' + opportunityHelper.getIcon(targetPelunasanForm.canceledOpportunity.Name, targetPelunasanForm.canceledOpportunity.Loan_Type_2__c)) : ''"
                        />
                    </template>
                    <p v-if="targetPelunasanForm.canceledOpportunity.Id" class="text-xs font-bold mt-3.5 mb-1">Target Pelunasan Baru</p>
                    <p v-else class="text-xs font-bold mt-3.5 mb-1">Pinjaman yang Dipilih</p>
                    <OpportunityCard
                        :bank-name="targetPelunasanForm.selectedOpportunity.Name ? opportunityHelper.getBankName(targetPelunasanForm.selectedOpportunity.Name, targetPelunasanForm.selectedOpportunity.Loan_Type_2__c) : '-'"
                        :loan-type="targetPelunasanForm.selectedOpportunity.Loan_Type_2__c ? opportunityHelper.getLoanTitle(targetPelunasanForm.selectedOpportunity.Loan_Type_2__c) : '-'"
                        :outstanding="targetPelunasanForm.selectedOpportunity.Estimated_Outstanding_Amount__c ? $filters.format_number(targetPelunasanForm.selectedOpportunity.Estimated_Outstanding_Amount__c) : '-'"
                        :image-source="targetPelunasanForm.selectedOpportunity.Name && targetPelunasanForm.selectedOpportunity.Loan_Type_2__c ? require('@/assets/img/bank-lender-icon/' + opportunityHelper.getIcon(targetPelunasanForm.selectedOpportunity.Name, targetPelunasanForm.selectedOpportunity.Loan_Type_2__c)) : ''"
                    />
                    <p class="text-xs font-bold mt-3.5 mb-1">Alasan Perubahan</p>
                    <div class="w-full p-4 border border-amalan-gray-4 rounded-lg grid grid-cols-1 gap-2">
                        <p v-for="(value, index) in targetPelunasanFormReason" :key="index" class="text-xs">{{ index + 1 }}. {{ value }}</p>
                        <p v-if="targetPelunasanForm.optional && targetPelunasanForm.reason.findIndex((value) => value === 'optional') >= 0" class="text-xs">{{ targetPelunasanFormReason.length + 1 }}. {{ targetPelunasanForm.optional }}</p>
                    </div>
                </template>
            </div>
            <div class="flex justify-between items-start mt-8 relative">
                    <label class="font-medium text-xs inline-flex" for="terms">
                        <label class="h-4 w-4 border-2 rounded-sm mr-2.5 p-0 cursor-pointer" :class="[ terms ? 'bg-amalan-yellow-400 border-amalan-yellow-400' : 'border-amalan-gray-4 bg-amalan-gray-4']" @click="termsChecked">
                            <CheckIcon class="w-4 h-4 -mt-0.5 -ml-px" :class="[ terms ? 'text-amalan-blue-1' : 'text-amalan-gray-4' ]" />
                        </label>
                    </label>
                    <p @click="termsChecked" class="text-xs cursor-pointer">Pengguna dengan ini sadar untuk meminta perubahan target pelunasan sesuai dengan data penjelasan yang ada.</p>
                    <span v-if="!terms" class="text-amalan-red text-4xs absolute -top-3 left-0">*centang disini</span>
                </div>
            <button @click="submit" class="w-full py-3 mt-11 text-center text-sm font-bold rounded-amalan-lg" :class="[ terms ? 'cursor-pointer text-amalan-white bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed']" :disabled="!terms">Kirim</button>
        </div>
        <ThanksModalWithMarkOption :visible="showModal" content="Pengajuan Anda akan segera kami proses.<br>Anda dapat melakukan pengecekan berkala pada halaman menu Manajemen Pinjaman.<br><br>Jika terjadi kendala, Anda dapat menghubungi konsultan kami." animation-mark="check" :with-button="true" button-text="Menu Manajemen Pinjaman" @action="closeThanksModal" />
        <loading-overlay v-if="isLoading" />
    </div>
</template>