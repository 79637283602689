<script setup>
import { onMounted, ref } from 'vue'
import PendingTab from './Tab/Pending.vue'
import DoneTab from './Tab/Done.vue'

const showPending = ref(true)
const showDone = ref(false)
const screenWidth = ref(null)

const animatedTabStyle = ref({
    width: '0px',
    height: '0px',
    transform: 'translateX(0%)'
})

const defineAnimatedTabStyle = () => {
    const rdpNavTab = document.getElementById('navigation-tab')
    animatedTabStyle.value.height = rdpNavTab.offsetHeight + 'px'
    animatedTabStyle.value.width = rdpNavTab.offsetWidth + 'px'
}

const selectPending = () => {
    showPending.value = true
    showDone.value = false
    animatedTabStyle.value.transform = 'translateX(0%)'
}

const selectDone = () => {
    showDone.value = true
    showPending.value = false
    animatedTabStyle.value.transform = screenWidth.value >= 420 ? 'translateX(107%)' : 'translateX(110%)'
}

onMounted(() => {
    screenWidth.value = screen.width
    defineAnimatedTabStyle()
})
</script>

<template>
    <div class="w-full bg-amalan-white min-h-screen pb-24 px-4">
        <div class="w-full px-2">
            <p class="text-lg py-4 font-bold text-center text-amalan-black">Aktivitas</p>
            <div class="w-full py-1 px-1.5 bg-amalan-blue-7 rounded-amalan-lg">
                <div class="w-full relative rounded-amalan-lg bg-transparent navigation-tab-wrapper">
                    <div class="rounded-amalan-lg absolute bg-amalan-white shadow-amalan-md cursor-pointer z-1 transition-transform ease-in-out duration-200" :style="animatedTabStyle"></div>
                    <div class="w-full h-full inset-0 rounded-amalan-lg absolute grid grid-cols-2 gap-x-4 z-10">
                        <div id="navigation-tab" @click="selectPending" class="flex justify-center items-center rounded-amalan-lg cursor-pointer" :class="{ 'hover:bg-amalan-gray-5': !showPending }">
                            <p class="text-sm text-amalan-blue-1 transition-all ease-in-out duration-300" :class="[ showPending ? 'font-bold' : 'font-normal' ]">Dalam Proses</p>
                        </div>
                        <div @click="selectDone" class="flex justify-center items-center rounded-amalan-lg cursor-pointer" :class="{ 'hover:bg-amalan-gray-5': !showDone }">
                            <p class="text-sm text-amalan-blue-1 transition-all ease-in-out duration-300" :class="[ showDone ? 'font-bold' : 'font-normal' ]">Selesai</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Transition name="fade-fast">
            <PendingTab v-show="showPending" />
        </Transition>
        <Transition name="fade-fast">
            <DoneTab v-show="showDone" />
        </Transition>
    </div>
</template>

<style scoped>
.navigation-tab-wrapper{
    height: 30px;
}

.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.2s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.2s ease
}
</style>