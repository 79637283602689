<template>
    <div class="pt-4 min-h-screen">
        <div class="mx-4">
            <div class="mt-2 pb-12 w-full">
                <div class="flex items-center">
                    <div class="flex items-center text-blue-900 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/signup/data-pinjaman-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Data<br>Pinjaman</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-blue-900"></div>
                    <div class="flex items-center text-blue-900 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/protect/data-pelanggaran-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Data<br>Pelanggaran</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-blue-900"></div>
                    <div class="flex items-center text-blue-900 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/signup/tanda-tangan-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Tanda<br>Tangan</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-blue-900"></div>
                    <div class="flex items-center text-blue-900 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/signup/pembayaran-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Pembayaran<br>Layanan</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-blue-900"></div>
                    <div class="flex items-center text-blue-900 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/verified-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Konfirmasi<br>Layanan</p></div>
                    </div>
                </div>
            </div>
            <div class="mt-4 pb-14 w-full">
                <div class="px-4 mt-32 sm:px-6 text-center flex flex-col">
                    <div class="w-14 h-14 rounded-full bg-amalan-yellow-400 flex justify-center items-center mx-auto mb-4">
                        <CheckIcon class="w-12 h-12 text-blue-900"/>
                    </div>
                    <p class="mt-1 mb-6 text-sm sm:text-md">
                        Pemesanan layanan Anda telah selesai dilakukan. Terima kasih telah mempercayai <b>amalan international</b>. <br><br>Selanjutnya konsultan kami akan segera menghubungi Anda untuk proses selanjutnya.
                    </p>
                    <router-link to='/home' class="mx-auto rounded-lg font-medium border border-solid cursor-pointer text-center text-base py-2 px-4 text-white bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700">Kembali ke Beranda</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/solid';

export default {
    components: {
        CheckIcon
    },
}
</script>