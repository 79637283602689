import CCUFormOCR from '@/views/CreditCheckup/FormOCR.vue'
import CCUFormUpdate from '@/views/CreditCheckup/FormUpdate.vue'
import CCUCreditScore from '@/views/CreditCheckup/CreditScore.vue'
import CCUCreditScoreCheckout from '@/views/CreditCheckup/CreditScoreCheckout.vue'
import CCUBookKonsultasi from '@/views/CreditCheckup/BookKonsultasi.vue'
import CCUCatalogList from '@/views/CreditCheckup/CatalogList.vue'
import CCUCatalogSummary from '@/views/CreditCheckup/CatalogSummary.vue'
import CCUCheckoutSummary from '@/views/CreditCheckup/CheckoutSummary.vue'
import CCUKonfirmasiLayanan from '@/views/CreditCheckup/KonfirmasiLayanan.vue'
import CCUHome from '@/views/CreditCheckup/Home.vue'
import CCUFaq from '@/views/CreditCheckup/FAQ.vue'
import CCUHistory from '@/views/CreditCheckup/History.vue'
import CCUShow from '@/views/CreditCheckup/Show.vue'

export default [
    {
        path: '/credit-checkup/onboarding-1',
        name: 'credit checkup - onboarding 1',
        component: CCUFormOCR,
        meta: {
            name: 'credit checkup - onboarding 1',
            isAuth: true,
            title: 'Credit Score',
            kycRedirectTo: 'credit checkup',
            creditScoreKYC: true,
            excludeKYC: true
        }
    },
    {
        path: '/credit-checkup/onboarding-2',
        name: 'credit checkup - onboarding 2',
        component: CCUFormUpdate,
        meta: {
            name: 'credit checkup - onboarding 2',
            isAuth: true,
            title: 'Credit Score',
            kycRedirectTo: 'credit checkup',
            creditScoreKYC: true,
            excludeKYC: true
        }
    },
    {
        path: '/credit-checkup/credit-score',
        name: 'credit checkup - credit score',
        component: CCUCreditScore,
        meta: {
            name: 'credit checkup - credit score',
            isAuth: true,
            title: 'Credit Score',
            kycRedirectTo: 'credit checkup',
            creditScoreKYC: true,
            excludeKYC: true
        }
    },
    {
        path: '/credit-checkup/credit-score-checkout',
        name: 'credit checkup - credit score checkout',
        component: CCUCreditScoreCheckout,
        meta: {
            name: 'credit checkup - credit score checkout',
            isAuth: true,
            title: 'Credit Score',
            kycRedirectTo: 'credit checkup',
            creditScoreKYC: true,
            excludeKYC: true
        }
    },
    {
        path: '/credit-checkup/product/onboarding-1',
        name: 'credit checkup product - onboarding 1',
        component: CCUFormOCR,
        meta: {
            name: 'credit checkup product - onboarding 1',
            isAuth: true,
            title: 'Verifikasi Data',
            kycRedirectTo: 'credit checkup product',
            creditCheckupProductKYC: true,
            excludeKYC: true
        }
    },
    {
        path: '/credit-checkup/product/onboarding-2',
        name: 'credit checkup product - onboarding 2',
        component: CCUFormUpdate,
        meta: {
            name: 'credit checkup product - onboarding 2',
            isAuth: true,
            title: 'Verifikasi Data',
            kycRedirectTo: 'credit checkup product',
            creditCheckupProductKYC: true,
            excludeKYC: true
        }
    },
    {
        path: '/credit-checkup/checkout-summary/:id',
        name: 'Ringkasan Booking Layanan',
        component: CCUCheckoutSummary,
        meta: {
            name: 'Ringkasan Booking Layanan',
            isAuth: true,
            title: 'Credit Score, Report, & Checkup',
            kycRedirectTo: 'credit checkup product',
            creditCheckupProductKYC: true,
            excludeKYC: true
        }
    },
    {
        path: '/credit-checkup/book-consultation',
        name: 'Book Konsultasi',
        component: CCUBookKonsultasi,
        meta: {
            name: 'Book Konsultasi',
            isAuth: true,
            title: 'Credit Score, Report, & Checkup',
            kycRedirectTo: 'credit checkup product',
            creditCheckupProductKYC: true,
            excludeKYC: true
        }
    },
    {
        path: '/credit-checkup/catalog-list',
        name: 'Catalog List',
        component: CCUCatalogList,
        meta: {
            name: 'Catalog List',
            isAuth: true,
            title: 'Credit Score, Report, & Checkup',
            kycRedirectTo: 'credit checkup product',
            creditCheckupProductKYC: true,
            excludeKYC: true
        }
    },
    {
        path: '/credit-checkup/home',
        name: 'Credit Checkup - Home',
        component: CCUHome,
        meta: {
            name: 'Credit Checkup - Home',
            isAuth: true,
            title: 'Credit Score, Report, & Checkup',
            excludeKYC: true
        }
    },
    {
        path: '/credit-checkup/faq',
        name: 'Credit Checkup - Faq',
        component: CCUFaq,
        meta: {
            name: 'Credit Checkup - Faq',
            isAuth: true,
            title: 'Credit Score, Report, & Checkup',
            excludeKYC: true
        }
    },
    {
        path: '/credit-checkup/history',
        name: 'Credit Checkup - History',
        component: CCUHistory,
        meta: {
            name: 'Credit Checkup - History',
            isAuth: true,
            title: 'Credit Score, Report, & Checkup',
            kycRedirectTo: 'credit checkup product',
            creditCheckupProductKYC: true,
            excludeKYC: true
        }
    },
    {
        path: '/credit-checkup/show/:id',
        name: 'Credit Checkup - Show',
        component: CCUShow,
        meta: {
            name: 'Credit Checkup - Show',
            isAuth: true,
            title: 'Credit Score, Report, & Checkup',
            kycRedirectTo: 'credit checkup product',
            creditCheckupProductKYC: true,
            excludeKYC: true
        }
    },
    {
        path: '/credit-checkup/catalog-list/:id',
        name: 'Catalog Summary',
        component: CCUCatalogSummary,
        meta: {
            name: 'Catalog Summary',
            isAuth: true,
            title: 'Credit Score, Report, & Checkup',
            kycRedirectTo: 'credit checkup product',
            creditCheckupProductKYC: true,
            excludeKYC: true
        }
    },
    {
        path: '/credit-checkup/konfirmasi-layanan/:catalog',
        name: 'credit checkup - konfirmasi layanan',
        component: CCUKonfirmasiLayanan,
        meta: {
            name: 'credit checkup - konfirmasi layanan',
            isAuth: true,
            title: 'Credit Score, Report, & Checkup',
            kycRedirectTo: 'credit checkup product',
            creditCheckupProductKYC: true,
            excludeKYC: true
        }
    }
];