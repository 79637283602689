import axios from "../../axios"

const state = () => ({
    CBResult: null,
    CBScore: null,
    CBStatus: null,
    serviceData: null,
    applicationPurpose: null,
    scoreData: [
        {
            name: 'Sangat Buruk',
            min: 0,
            max: 319,
            distance: '0-319',
            color: 'credit-score-100',
            content: 'Anda memiliki <b>Credit Score</b> dalam kisaran antara <b>0 hingga 319</b>. Hal ini dianggap <b>Sangat Buruk</b> dalam istilah industri yang spesifik. Konsumen dengan skor ini berada dibawah rata-rata dengan peluang pengajuan pinjaman akan ditolak karena beberapa hal terkait histori kredit.',
            detail: 'Skor ini berada dibawah rata-rata dengan peluang pengajuan pinjaman akan ditolak karena beberapa hal terkait histori kredit.',
            styleBarScale: 0.000385,
            styleIndicatorScale: 0.00041
        },
        {
            name: 'Cukup',
            min: 320,
            max: 519,
            distance: '320-519',
            color: 'credit-score-200',
            content: 'Anda memiliki <b>Credit Score</b> dalam kisaran antara <b>320 hingga 519</b>. Hal ini dianggap <b>Cukup</b> dalam istilah industri yang spesifik, sehingga Anda memiliki ruang untuk memperbaikinya. Konsumen dengan skor dalam kisaran ini biasanya memiliki peluang kecil untuk disetujui pengajuan kartu kredit atau pinjaman. Namun, masih ada beberapa lembaga pemberi pinjaman yang masih memberikan pinjaman.',
            detail: 'Skor kredit ini biasanya memiliki peluang kecil untuk disetujui pengajuan kartu kredit atau pinjaman. Namun, masih ada beberapa lembaga pemberi pinjaman yang masih memberikan pinjaman.',
            styleBarScale: 0.000445,
            styleIndicatorScale: 0.00046
        },
        {
            name: 'Baik',
            min: 520,
            max: 578,
            distance: '520-578',
            color: 'credit-score-300',
            content: 'Anda memiliki <b>Credit Score</b> dalam kisaran antara <b>520 hingga 578</b>. Hal ini dianggap <b>Baik</b> dalam istilah industri yang spesifik, sehingga Anda memiliki ruang untuk memperbaikinya. Konsumen dengan skor ini masih memiliki kesempatan untuk disetujui untuk sebagian pengajuan kartu kredit dan beberapa pinjaman dengan bunga yang tinggi.',
            detail: 'Anda masih memiliki kesempatan untuk disetujui untuk sebagian pengajuan kartu kredit dan beberapa pinjaman dengan bunga yang tinggi.',
            styleBarScale: 0.000515,
            styleIndicatorScale: 0.00053
        },
        {
            name: 'Sangat Baik',
            min: 579,
            max: 596,
            distance: '579-596',
            color: 'credit-score-400',
            content: 'Anda memiliki <b>Credit Score</b> dalam kisaran antara <b>579 hingga 596</b>. Hal ini dianggap <b>Sangat Baik</b> dalam istilah industri yang spesifik, sehingga Anda memiliki ruang untuk memperbaikinya. Konsumen dengan skor ini berpeluang tinggi untuk disetujui pengajuan kartu kredit atau pinjaman dengan limit yang relatif besar tergantung kondisi keuangan dan hasil analisa dari pihak pemberi pinjaman.',
            detail: 'Skor ini berpeluang tinggi untuk disetujui pengajuan kartu kredit atau pinjaman dengan limit yang relatif besar tergantung kondisi keuangan dan hasil analisa dari pihak pemberi pinjaman.',
            styleBarScale: 0.000605,
            styleIndicatorScale: 0.00062
        },
        {
            name: 'Luar Biasa',
            min: 597,
            max: 9999,
            distance: '>597',
            color: 'credit-score-500',
            content: 'Anda memiliki <b>Credit Score</b> dalam kisaran <b>>597</b>. Hal ini dianggap <b>Luar Biasa</b> dalam istilah industri yang spesifik. Konsumen dengan skor ini diatas rata-rata dengan peluang besar untuk pengajuan kartu kredit dan pinjaman dengan bunga rendah, dan mendapatkan limit pinjaman yang tinggi.',
            detail: 'Skor ini diatas rata-rata dengan peluang besar untuk pengajuan kartu kredit dan pinjaman dengan bunga rendah, dan mendapatkan limit pinjaman yang tinggi.',
            styleBarScale: 0.000655,
            styleIndicatorScale: 0.00066
        }
    ],
    scoreInterpretation: [
        {
            name: 'Very High Risk',
            min: 150,
            max: 267,
            risk: 'A',
            color: 'bg-credit-score-100',
            styleBarScale: 0.000385,
            styleIndicatorScale: 0.00041
        },
        {
            name: 'Very High Risk',
            min: 268,
            max: 319,
            risk: 'B',
            color: 'bg-credit-score-200',
            styleBarScale: 0.000415,
            styleIndicatorScale: 0.00044

        },
        {
            name: 'High Risk',
            min: 320,
            max: 379,
            risk: 'C',
            color: 'bg-credit-score-300',
            styleBarScale: 0.000445,
            styleIndicatorScale: 0.00046
        },
        {
            name: 'High Risk',
            min: 380,
            max: 519,
            risk: 'D',
            color: 'bg-credit-score-400',
            styleBarScale: 0.000480,
            styleIndicatorScale: 0.00050
        },
        {
            name: 'Medium Risk',
            min: 520,
            max: 545,
            risk: 'E',
            color: 'bg-credit-score-500',
            styleBarScale: 0.000515,
            styleIndicatorScale: 0.00053
        },
        {
            name: 'Medium-Low Risk',
            min: 546,
            max: 550,
            risk: 'F',
            color: 'bg-credit-score-600',
            styleBarScale: 0.000545,
            styleIndicatorScale: 0.00056
        },
        {
            name: 'Medium-Low Risk',
            min: 551,
            max: 561,
            risk: 'G',
            color: 'bg-credit-score-700',
            styleBarScale: 0.000575,
            styleIndicatorScale: 0.00059
        },
        {
            name: 'Medium-Low Risk',
            min: 562,
            max: 578,
            risk: 'H',
            color: 'bg-credit-score-800',
            styleBarScale: 0.000605,
            styleIndicatorScale: 0.00062
        },
        {
            name: 'Low Risk',
            min: 579,
            max: 596,
            risk: 'I',
            color: 'bg-credit-score-900',
            styleBarScale: 0.000625,
            styleIndicatorScale: 0.00063
        },
        {
            name: 'Very Low Risk',
            min: 597,
            max: 9999999,
            risk: 'J',
            color: 'bg-credit-score-1000',
            styleBarScale: 0.000655,
            styleIndicatorScale: 0.00066
        }
    ],
});

const getters = {
    getCBResult(state) {
        return state.CBResult;
    },
    getCBScore(state) {
        return state.CBScore;
    },
    getCBStatus(state) {
        return state.CBStatus;
    },
    getServiceData(state) {
        return state.serviceData;
    },
    getScoreData(state) {
        return state.scoreData;
    },
    getScoreInterpretation(state) {
        return state.scoreInterpretation;
    },
    getApplicationPurposeData(state) {
        return state.applicationPurpose;
    }
};

const actions = {
    async requestCBResult({ commit }) {
        axios.get('/clik/latest-credit-score')
        .then((resp) => {
            commit("requestCBResult", { status: 'found', data: resp.data.data })
        })
        .catch(() => {
            commit("requestCBResult", { status: 'not found', data: null })
        })
    },
    requestCBResultSync({ commit }) {
        return axios.get('/clik/latest-credit-score')
        .then((resp) => {
            commit("requestCBResult", { status: 'found', data: resp.data.data })
        })
        .catch(() => {
            commit("requestCBResult", { status: 'not found', data: null })
        })
    },
    async requestApplicationPurpose({ commit }) {
        try {
            const result = await axios.get('clik/application-purpose')
            commit('setApplicationPurpose', result.data.data)
        } catch {
            commit('setApplicationPurpose', [])
        }
    }
};

const mutations = {
    requestCBResult(state, payload) {
        if(payload.status === 'found'){
            state.CBResult = payload.data.cb_result
            state.CBScore = payload.data.cb_score
            state.serviceData = payload.data.service
            state.CBStatus = 'found'
        }else{
            state.CBResult = null
            state.CBScore = null
            state.serviceData = null
            state.CBStatus = 'not found'
        }
    },
    setApplicationPurpose(state, payload){
        state.applicationPurpose = payload
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
