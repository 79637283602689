<script setup>
import { ChevronDownIcon } from '@heroicons/vue/solid'
import { ref, reactive, inject, onMounted } from 'vue'
import moment from 'moment'
import apiRdpManagement from "@/axios/rdpManagement"
import store from '../../../../store';

const swal = inject('$swal')
const monthList = ref(['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'])
const yearList = ref([])
const typeList = ref(['Semua', 'Debit', 'Kredit'])
const showMonthList = ref(false)
const showYearList = ref(false)
const showTypeList = ref(false)
const isLoading = ref(false)
const mutationData = ref([])
const filteredMutationData = ref([])
const isLoadingOverlay = ref(false)
const isLoadingCetakMutasi = ref(false)

const getYearList = () => {
    for (let index = 0; index <= 2; index++) {
        yearList.value.push(moment().subtract(index, 'years').format('YYYY'))
    }
}

const selectedFilters = reactive({
    month: monthList.value[moment().format('M') - 1],
    year: yearList.value[0],
    type: typeList.value[0]
})

const toggleMonthList = () => {
    showMonthList.value = !showMonthList.value
    showYearList.value = false
    showTypeList.value = false
}
const toggleYearList = () => {
    showYearList.value = !showYearList.value
    showMonthList.value = false
    showTypeList.value = false
}
const toggleTypeList = () => {
    showTypeList.value = !showTypeList.value
    showYearList.value = false
    showMonthList.value = false
}

const setMonth = (index) => selectedFilters.month = monthList.value[index]
const setYear = (index) => selectedFilters.year = yearList.value[index]
const setType = (index) => {
    selectedFilters.type = typeList.value[index]
    filterMutationByType()
}

const swalConfirm = swal.mixin({
    customClass: {
        confirmButton: 'mx-2 py-3 w-34 text-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-blue-1 hover:bg-amalan-blue-2 text-xs font-bold text-amalan-white',
        cancelButton: 'mx-2 py-3 w-34 text-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-white hover:bg-amalan-gray-5 text-xs font-bold text-amalan-blue-1'
    },
    title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
    buttonsStyling: false,
    width: '340px',
    allowOutsideClick: false,
    reverseButtons: true,
})

const swalErrorReload = swal.mixin({
    customClass: {
        confirmButton: 'bg-amalan-blue-1 mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-white text-sm font-semibold hover:bg-amalan-blue-2 hover:border-amalan-blue-2'
    },
    buttonsStyling: false,
    title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
    html: '<p class="text-center text-sm mt-2">Maaf, terjadi kesalahan dalam pengambilan informasi!</p>',
    showCancelButton: false,
    confirmButtonText: 'Reload',
    width: '340px',
    allowOutsideClick: false,
})

const cetakMutasiBtn = () => {
    swalConfirm.fire({
        html: '<p class="text-center text-xs mt-2 text-amalan-black">Apakah Anda ingin mengunduh mutasi rekening?<br><br>Mutasi Rekening akan dikirimkan dalam format PDF ke Email dan WhatsApp Anda.</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Unduh',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if (result.isConfirmed){
            isLoadingCetakMutasi.value = true
            const indexOfMonth = () => {
                let indexMonth = monthList.value.indexOf(selectedFilters.month) + 1
                if(indexMonth < 10) indexMonth = '0' + indexMonth
                return indexMonth
            }

            const startOfMonth = selectedFilters.year + '-' + indexOfMonth() + '-01'
            const endOfMonth = moment(startOfMonth).endOf('month').format('YYYY-MM-DD')
            const payload = {
                from_date: startOfMonth,
                to_date: endOfMonth
            }

            apiRdpManagement.post('/pdf/mutasi', payload)
            .then(() => {
                isLoadingCetakMutasi.value = false
            })
            .catch(() => {
                isLoadingCetakMutasi.value = false
            })

            return
        }

        return
    })
}

const perbaruiMutasiBtn = () => {
    swalConfirm.fire({
        html: '<p class="text-center text-xs mt-2 text-amalan-black">Fitur ini hanya dapat digunakan 3 kali sehari, pastikan Anda sudah melakukan transaksi ke RDP Anda untuk melihat informasi mutasi terbaru terkait RDP Anda!</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Perbaharui',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if (result.isConfirmed){
            isLoadingOverlay.value = true
            apiRdpManagement.get('/bni/pull')
            .then(() => {
                isLoadingOverlay.value = false
                store.dispatch('RDPManagement/requestRdpData')
                searchMutation()
            })
            .catch((err) => {
                isLoadingOverlay.value = false
                if(err.response.status === 429){
                    swalConfirm.fire({
                        html: '<p class="text-center text-xs mt-2 text-amalan-black">Maaf, fitur ini hanya dapat digunakan 3 kali sehari.<br>Anda telah menggunakannya pada hari ini.<br>Silahkan coba lagi di esok hari.</p>',
                        showCancelButton: false,
                        confirmButtonText: 'Kembali',
                        cancelButtonText: 'Batal',
                    })
                }else{
                    swalErrorReload.fire().then((result) => {
                        if(!result.isConfirmed) return
                        window.location.reload()
                        return
                    })
                }
            })
            
            return
        }

        return
    })
}

const searchMutation = () => {
    isLoading.value = true
    const indexOfMonth = () => {
        let indexMonth = monthList.value.indexOf(selectedFilters.month) + 1
        if(indexMonth < 10) indexMonth = '0' + indexMonth
        return indexMonth
    }

    const startOfMonth = selectedFilters.year + '-' + indexOfMonth() + '-01'
    const endOfMonth = moment(startOfMonth).endOf('month').format('YYYY-MM-DD')
    const payload = {
        from_date: startOfMonth,
        to_date: endOfMonth
    }
    
    apiRdpManagement.post('/bni/account-statement', payload)
    .then((res) => {
        isLoading.value = false
        setMutationData(res.data.data)
    })
    .catch(() => {
        isLoading.value = false
        swalErrorReload.fire().then((result) => {
            if(!result.isConfirmed) return
            window.location.reload()
            return
        })
    })
}

const setMutationData = (data) => {
    try {
        const mutations = data.data
        if(mutations.length === 0) throw new Error('No data found')

        const mapData = mutations.map((mutation) => {
            return {
                description: (`${mutation.description} ${mutation.narative36}`).trim(),
                type: mutation.debit && mutation.debit > 0 ? 'DEBIT' : 'KREDIT',
                amount: mutation.debit && mutation.debit > 0 ? parseFloat(mutation.debit) : parseFloat(mutation.credit),
                timeStamp: mutation.transactionTimestamp
            }
        })

        mapData.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp))
        mutationData.value = mapData
        filterMutationByType()
    } catch (error) {
        mutationData.value = []
        filterMutationByType()
    }
}

const filterMutationByType = () => {
    if(mutationData.value.length == 0) return filteredMutationData.value = []

    filteredMutationData.value = mutationData.value.filter((elem) => {
        if(selectedFilters.type.toUpperCase() === 'SEMUA') return elem
        return elem.type === selectedFilters.type.toUpperCase()
    })
}

const formatDateTime = (dateTime) => moment(dateTime).format('DD-MM-YYYY | HH:mm:ss')

onMounted(() => {
    getYearList()
    setYear(0)
    searchMutation()
})

</script>

<template>
    <div class="w-full h-100 mt-3 px-4 border border-solid border-amalan-gray-4 rounded-lg box-border bg-amalan-white relative">
        <div class="py-4 flex justify-center items-center">
            <div @click="toggleMonthList" class="px-2 h-6 w-24 flex-grow flex justify-between items-center border border-solid border-amalan-gray-3 rounded-lg box-border cursor-pointer relative">
                <p class="font-semibold text-xs text-amalan-black">{{ selectedFilters.month }}</p>
                <ChevronDownIcon class="text-amalan-black h-6 -m-2 transition-transform ease-in-out duration-200 transform" :class="[ showMonthList ? 'rotate-180' : 'rotate-0' ]" />
                <Transition name="fade-fast">
                    <div v-show="showMonthList" class="absolute top-6 left-0 inset-x-0 border border-solid bg-amalan-white border-amalan-gray-3 rounded-lg box-border max-h-28 text-amalan-black p-0.5 overflow-y-scroll overflow-x-hidden scrollbar">
                        <div class="w-full rounded-lg p-1 text-xs font-semibold hover:bg-amalan-gray-5" v-for="(month, index) in monthList" :key="index" @click="setMonth(index)">{{ month }}</div>
                    </div>
                </Transition>
            </div>
            <div @click="toggleYearList" class="px-2 h-6 ml-2 w-20 flex-grow-0 flex justify-between items-center border border-solid border-amalan-gray-3 rounded-lg box-border cursor-pointer relative">
                <p class="font-semibold text-xs text-amalan-black">{{ selectedFilters.year }}</p>
                <ChevronDownIcon class="text-amalan-black h-6 -m-2 transition-transform ease-in-out duration-200 transform" :class="[ showYearList ? 'rotate-180' : 'rotate-0' ]" />
                <Transition name="fade-fast">
                    <div v-show="showYearList" class="absolute top-6 left-0 inset-x-0 border border-solid bg-amalan-white border-amalan-gray-3 rounded-lg box-border max-h-28 text-amalan-black p-0.5">
                        <div class="w-full rounded-lg p-1 text-xs font-semibold hover:bg-amalan-gray-5" v-for="(year, index) in yearList" :key="index" @click="setYear(index)">{{ year }}</div>
                    </div>
                </Transition>
            </div>
            <div @click="toggleTypeList" class="px-2 h-6 ml-2 w-24 flex-grow-0 flex justify-between items-center border border-solid border-amalan-gray-3 rounded-lg box-border cursor-pointer relative">
                <p class="font-semibold text-xs text-amalan-black">{{ selectedFilters.type }}</p>
                <ChevronDownIcon class="text-amalan-black h-6 -m-2 transition-transform ease-in-out duration-200 transform" :class="[ showTypeList ? 'rotate-180' : 'rotate-0' ]" />
                <Transition name="fade-fast">
                    <div v-show="showTypeList" class="absolute top-6 left-0 inset-x-0 border border-solid bg-amalan-white border-amalan-gray-3 rounded-lg box-border max-h-28 text-amalan-black p-0.5">
                        <div class="w-full rounded-lg p-1 text-xs font-semibold hover:bg-amalan-gray-5" v-for="(type, index) in typeList" :key="index" @click="setType(index)">{{ type }}</div>
                    </div>
                </Transition>
            </div>
            <div @click="searchMutation" class="w-7 h-6 ml-2 flex-none flex justify-center items-center shadow-amalan-md bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-lg cursor-pointer">
                <img src="@/assets/img/rdp/search-icon.svg" class="h-3">
            </div>
        </div>
        <div class="w-full py-4 border-t-2 border-solid border-amalan-gray-4 h-3/4 overflow-y-scroll scrollbar-hide">
            <loading-data-without-margin v-if="isLoading" class="pt-28" />
            <template v-else>
                <div v-if="(filteredMutationData.length > 0)" class="w-full grid grid-cols-1 gap-y-3">
                    <div v-for="(mutation, index) in filteredMutationData" class="w-full grid grid-cols-5 gap-x-3" :key="index">
                        <div class="col-span-3 text-left">
                            <p class="text-2xs text-amalan-gray-2">{{ mutation.timeStamp ? formatDateTime(mutation.timeStamp) : '' }}</p>
                            <p class="text-xs font-bold text-amalan-black">{{ mutation.description ? mutation.description : '' }}</p>
                        </div>
                        <div class="col-span-2 text-right">
                            <p class="text-2xs text-amalan-gray-2">{{ mutation.type ? mutation.type : '' }}</p>
                            <p class="text-xs font-bold text-amalan-black">{{ mutation.type === 'KREDIT' ? '+' : '-' }} {{ mutation.amount ? $filters.format_number(mutation.amount) : '' }}</p>
                        </div>
                    </div>
                </div>
                <div v-else class="w-full h-full flex justify-center items-center">
                    <p class="text-xs text-amalan-black text-center">Maaf, Anda tidak memiliki riwayat transaksi<br>pada periode yang dipilih</p>
                </div>
            </template>
        </div>
        <div class="w-full h-12 absolute bottom-0 inset-x-0 bg-amalan-white rounded-b-lg border-t-2 border-amalan-gray-4 px-4 grid grid-cols-2 gap-x-4 items-center">
            <div @click="perbaruiMutasiBtn" class="w-full h-7 flex justify-center items-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-white hover:bg-amalan-gray-5 cursor-pointer">
                <p class="text-xs font-bold text-amalan-blue-1">Perbaharui Mutasi</p>
            </div>
            <div @click="cetakMutasiBtn" class="w-full h-7 flex justify-center items-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-blue-1 hover:bg-amalan-blue-2 cursor-pointer">
                <p class="text-xs font-bold text-amalan-white">Unduh Mutasi</p>
            </div>
        </div>
        <loading-overlay v-if="isLoadingOverlay" />
        <loading-overlay-custom-message message="Mohon tunggu<br>Mutasi Rekening akan segera dikirim<br>ke Email dan WhatsApp Anda" v-if="isLoadingCetakMutasi" />
    </div>
</template>

<style scoped>
.scrollbar::-webkit-scrollbar {
    width: 12px;
}

.scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #E4E5E6;
    border: 4px solid transparent;
    border-radius: 100px;
    background-clip: content-box;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.2s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.2s ease
}
</style>