
<script setup>
import { onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import moment from 'moment'

const store = useStore()
const router = useRouter()
const route = useRoute()

const userData = computed(() => store.getters['user/getUser'])
const lastLoanConsolidation = computed(() => store.getters['loanConsolidation/getLastLoanConsolidation'])
const getInformasiUmumForm = computed(() => store.getters['loanConsolidation/getInformasiUmumForm'])
const getDataDiriForm = computed(() => store.getters['loanConsolidation/getDataDiriForm'])
const signUpStatus = computed(() => store.getters['contact/getSignUpStatus'])

const DataDiriForm = reactive({
    name: null,
    email: null,
    birthdate: null
})

const minDate = moment().subtract(100, 'years').format('YYYY-MM-DD')
const maxDate = moment().subtract(17, 'years').format('YYYY-MM-DD')

const DataDiriFormSchema = yup.object().shape({
    name: yup.string().nullable().required('Mohon diisi').max(50, "Maksimal 50 karakter"),
    email: yup.string().nullable().required('Mohon diisi').email('Email tidak valid').max(255, "Maksimal 255 karakter"),
    birthdate: yup.date().nullable().required('Silahkan pilih tanggal lahir').min(minDate,"Pengguna maksimal berumur 100 tahun").max(maxDate,"Pastikan tanggal lahir sesuai KTP")
})

const prepopulateFormData = () => {
    DataDiriForm.email = getDataDiriForm.value.email ?? userData.value.email ?? null
    DataDiriForm.name = getDataDiriForm.value.name ?? (userData.value.first_name || '') + ' ' + (userData.value.last_name || '')
    DataDiriForm.birthdate = getDataDiriForm.value.birthdate ?? userData.value.birthdate ?? null
}

const submitHandler = () => {
    store.dispatch('loanConsolidation/setDataDiriForm', {...DataDiriForm})
    return router.push({name:'Loan Consolidation - Registrasi - Data Pekerjaan'})
}

onMounted(() => {
    prepopulateFormData()

    if(lastLoanConsolidation.value.status === 404){
        if(getInformasiUmumForm.value.isValid) return
        return router.push({ name: 'Loan Consolidation - Registrasi - Informasi Umum'})
    }

    if(lastLoanConsolidation.value.status === 201 || lastLoanConsolidation.value.status === 202)
        return router.push({ name: 'Loan Consolidation - Registrasi - Daftar Pinjaman'})
    if(lastLoanConsolidation.value.status === 200) {
        const consolidationInitialResult = lastLoanConsolidation.value.data.data.Loan_Consolidation_Initial_Results__c ? lastLoanConsolidation.value.data.data.Loan_Consolidation_Initial_Results__c.toUpperCase() : null
        const isNotEligible = consolidationInitialResult ? consolidationInitialResult.includes('NOT ELIGIBLE') : null

        if(isNotEligible) return router.push({ name: 'Loan Consolidation - Registrasi - Qualification'})
        return router.push({ name: 'Loan Consolidation - Registrasi - Finish Page'})
    }

    return router.push({ name: 'Home'})
})
</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="router.push({name: 'Loan Consolidation - Registrasi - Informasi Umum'})" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold text-amalan-black text-center">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full min-h-screen bg-amalan-white px-6 pb-24">
            <div class="w-full py-8 flex justify-between items-center">
                <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                    <span class="text-sm text-amalan-blue-1 font-bold">1</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3 mx-1"></div>
                <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400">
                    <span class="text-sm font-bold text-amalan-blue-1">2</span>
                </div>
                <p class="flex-none text-amalan-blue-1 text-sm font-bold ml-1">Data Diri</p>
                <div class="bg-amalan-blue-1 h-px flex-grow mx-1"></div>
                <div class="w-6 h-6 rounded-full bg-amalan-gray-4 flex justify-center items-center flex-none ml-1">
                    <span class="text-sm text-amalan-gray-2 font-bold">3</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3"></div>
                <div class="w-6 h-6 rounded-full bg-amalan-gray-4 flex justify-center items-center flex-none ml-1">
                    <span class="text-sm text-amalan-gray-2 font-bold">4</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3"></div>
                <div class="w-6 h-6 rounded-full bg-amalan-gray-4 flex justify-center items-center flex-none ml-1">
                    <span class="text-sm text-amalan-gray-2 font-bold">5</span>
                </div>
            </div>
            <p class="text-xs text-amalan-black text-center">Lengkapi pertanyaan berikut<br>sesuai dengan informasi yang Anda miliki</p>
            <Form
            :validation-schema="DataDiriFormSchema"
            v-slot="{ meta, errors }"
            @submit="submitHandler"
            >
                <div class="w-full grid grid-cols-1 gap-y-3.5 py-8 text-amalan-black">
                    <div v-if="signUpStatus === 200" class="w-full">
                        <label class="text-amalan-blue-1 font-bold text-sm">Nama</label>
                        <div class="w-full pl-4 pr-3 py-2.5 mt-1 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed flex justify-between items-center">
                            <p class="text-amalan-gray-2 text-xs">{{ DataDiriForm.name }}</p>
                            <Field name="name" v-model="DataDiriForm.name" type="text" class="hidden" />
                        </div>
                    </div>
                    <div v-else class="w-full">
                        <label class="text-sm text-amalan-blue-1 font-bold">Nama</label>
                        <Field placeholder="Nama" name="name" v-model="DataDiriForm.name" :validateOnInput="true" type="text" class="block w-full text-xs px-4 py-2.5 mt-1.5 bg-amalan-white border rounded-lg appearance-none focus:outline-none" :class="[errors.name ? 'border-amalan-red' : 'border-amalan-gray-4']" />
                        <div v-if="errors.name" class="mt-1 text-2xs text-amalan-red">{{errors.name}}</div>
                    </div>
                    <div v-if="signUpStatus === 200" class="w-full">
                        <label class="text-amalan-blue-1 font-bold text-sm">Email</label>
                        <div class="w-full pl-4 pr-3 py-2.5 mt-1 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed flex justify-between items-center">
                            <p class="text-amalan-gray-2 text-xs">{{ DataDiriForm.email }}</p>
                            <Field name="email" v-model="DataDiriForm.email" type="text" class="hidden" />
                        </div>
                    </div>
                    <div v-else class="w-full">
                        <label class="text-sm text-amalan-blue-1 font-bold">Email</label>
                        <Field placeholder="Email" name="email" v-model="DataDiriForm.email" :validateOnInput="true" type="text" class="block w-full text-xs px-4 py-2.5 mt-1.5 bg-amalan-white border rounded-lg appearance-none focus:outline-none" :class="[errors.email ? 'border-amalan-red' : 'border-amalan-gray-4']" />
                        <div v-if="errors.email" class="mt-1 text-2xs text-amalan-red">Silahkan isi sesuai dengan alamat email yang digunakan.</div>
                    </div>
                    <div v-if="signUpStatus === 200" class="w-full">
                        <label class="text-amalan-blue-1 font-bold text-sm">Tanggal Lahir</label>
                        <div class="w-full pl-4 pr-3 py-2.5 mt-1 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed flex justify-between items-center">
                            <p class="text-amalan-gray-2 text-xs">{{ DataDiriForm.birthdate }}</p>
                            <Field name="birthdate" v-model="DataDiriForm.birthdate" type="date" class="hidden" />
                        </div>
                    </div>
                    <div v-else class="w-full">
                        <label class="text-sm text-amalan-blue-1 font-bold">Tanggal Lahir</label>
                        <Field name="birthdate" v-model="DataDiriForm.birthdate" :validateOnInput="true" type="date" class="block w-full text-xs px-4 py-2.5 mt-1.5 bg-amalan-white border rounded-lg appearance-none focus:outline-none" :class="[errors.birthdate ? 'border-amalan-red' : 'border-amalan-gray-4']" />
                        <div v-if="errors.birthdate" class="mt-1 text-2xs text-amalan-red">{{errors.birthdate}}</div>
                    </div>
                </div>
                <button type="submit" class="w-full mt-8 rounded-amalan-lg font-bold text-center text-sm p-3" :class="[ meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!meta.valid">Selanjutnya</button>
            </Form>
        </div>
    </div>
</template>