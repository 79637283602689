<template>
    <div class="pt-4 min-h-screen h-auto relative">
        <div class="mt-2 pb-40 w-full px-4">
            <div class="mb-4 rounded-lg px-2 py-2" :class="[ statusCode == 200 ? 'bg-blue-100' : 'bg-amalan-yellow-100']" v-if="statusCode == 201 || statusCode == 202 || statusCode == 200">
                <template v-if="statusCode == 200">
                    <div class="w-full bg-white rounded-lg py-2 px-2">
                        <div class="flex justify-between my-1">
                            <p class="text-xs text-gray-800">Reference ID <br><span class="text-sm font-bold">{{ bankId }}</span></p>
                            <p class="text-xs text-gray-800 text-right">Status Pembayaran <br><span class="text-sm font-bold">Paid</span></p>
                        </div>
                        <div v-if="dataPelanggaran" class="flex justify-between my-1">
                            <p class="text-xs text-gray-800">Harga <br><span class="text-sm font-bold">{{ $filters.format_number(dataPelanggaran.Payment_Amount__c ?? 0) }}</span></p>
                            <p class="text-xs text-gray-800 text-right">Status Layanan <br><span class="text-sm font-bold">{{ dataPelanggaran.Amalan_Protect_Approval_Status__c }}</span></p>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="w-full flex justify-center mt-2">
                        <div class="bg-white inline-block py-1 px-1 rounded-full">
                            <img src="/img/exclamation-icon.svg">
                        </div>
                    </div>
                    <div v-if="statusCode == 201" class="w-full">
                        <p class="text-sm py-4 text-center">
                            Anda belum melakukan tanda tangan pada <span class="font-bold">Kontrak Perjanjian amalanProtect</span>
                        </p>
                        <router-link :to="'/amalan-protect/tanda-tangan/' + bankId" class="text-center bg-blue-900 hover:bg-blue-700 font-semibold justify-center w-full block mb-2 rounded-md text-white text-sm py-2">
                            Tanda Tangan Sekarang
                        </router-link>
                    </div>
                    <div v-else class="w-full">
                        <p class="text-sm py-4 text-center">
                            Anda belum melakukan pembayaran pada <span class="font-bold">Pembelian layanan amalanProtect</span>
                        </p>
                        <router-link :to="'/amalan-protect/pembayaran/' + bankId" class="text-center bg-blue-900 hover:bg-blue-700 font-semibold justify-center w-full block mb-2 rounded-md text-white text-sm py-2">
                            Bayar Sekarang
                        </router-link>
                    </div>
                </template>
            </div>
            <div class="mb-4" v-if="dataPinjaman">
                <div class="w-full rounded-lg px-4 py-3 mt-6 border border-solid bg-white">
                    <div class="flex items-center">
                        <!-- <div class="w-16 h-16 rounded-full flex justify-center items-center border-2 border-amalan-yellow-400 bg-white">
                            <img :src="require('@/assets/img/signup/icon-jenis-pinjaman/' + getIcon(dataPinjaman.Loan_Type__c))">
                        </div> -->
                        <div class="w-full">
                            <p class="text-sm font-bold my-0">#{{ dataPinjaman.Name }}</p>
                            <p class="text-xs font-semibold my-0">{{ dataPinjaman.Loan_Type__c }}</p>
                            <p class="text-xs font-semibold my-0">{{ dataPinjaman.Account_Number__c }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-4" v-if="dataPelanggaran">
                <div class="w-full rounded-lg px-4 py-3 border border-solid bg-white">
                    <div class="flex justify-between items-center mb-4">
                        <span class="text-base font-bold text-blue-900">Informasi Debt Collector</span>
                    </div>
                    <div class="my-2 border-b-2 border-solid pb-2">
                        <h1 class="font-bold text-sm">Nama</h1>
                        <h1 class="text-sm">{{ dataPelanggaran.Debt_Collector_Name__c }}</h1>
                    </div>
                    <div class="my-2 border-b-2 border-solid pb-2">
                        <h1 class="font-bold text-sm">Nomor HP</h1>
                        <h1 class="text-sm">{{ dataPelanggaran.Debt_Collector_Phone__c }}</h1>
                    </div>
                    <div class="my-2 border-b-2 border-solid pb-2">
                        <h1 class="font-bold text-sm">Nomor Identitas</h1>
                        <h1 class="text-sm">{{ dataPelanggaran.Debt_Collector_ID__c }}</h1>
                    </div>
                    <div class="my-2 border-b-2 border-solid pb-2">
                        <h1 class="font-bold text-sm">Tanggal Kunjungan</h1>
                        <h1 class="text-sm">{{ dataPelanggaran.Date_of_Interruption__c }}</h1>
                    </div>
                    <div class="my-2 border-b-2 border-solid pb-2">
                        <h1 class="font-bold text-sm">Alamat Kunjungan</h1>
                        <h1 class="text-sm">{{ dataPelanggaran.Interruption_Location__c }}</h1>
                    </div>
                    <div class="my-2 border-b-2 border-solid pb-2">
                        <h1 class="font-bold text-sm">Bentuk Pelanggaran</h1>
                        <h1 class="text-sm">{{ dataPelanggaran.Interruption_that_Occurs__c }}</h1>
                    </div>
                    <div class="my-2">
                        <h1 class="font-bold text-sm">Kronologi Pelanggaran</h1>
                        <h1 class="text-sm">{{ dataPelanggaran.Interruption_Chronology__c }}</h1>
                    </div>
                </div>
                <div v-if="docPendukung" class="w-full rounded-lg px-4 py-3 mt-4 border border-solid bg-white flex justify-between items-center">
                    <span class="text-base text-blue-900 font-bold">
                        Dokumen Pendukung
                        <span class="text-sm text-blue-900 font-normal">({{ docPendukung }})</span>
                    </span>
                </div>
            </div>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>

<script>
import amalansignupApi from "@/axios/amalansignup";
import { listLoanType as listIcon } from '@/constants/LoanConstant'

export default {
    data(){
        return {
            isLoading: false,
            dataPinjaman: null,
            dataPelanggaran: null,
            statusCode: null,
            docPendukung: null,
            bankId: this.$route.params.idBank,
            swalErrorReload: this.$swal.mixin({
                    customClass: {
                        confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                    },
                    buttonsStyling: false,
                    title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                    showCancelButton: false,
                    confirmButtonText: 'Perbaharui',
                    width: '340px',
                    allowOutsideClick: false,
            }),
        }
    },
    methods: {
        getIcon(loanType){
            let loanIcon = listIcon.filter(elem => elem.title == loanType);
            if (loanIcon[0]) return loanIcon[0].icon
        },
        getDataPinjaman(){
            this.isLoading = true;
            amalansignupApi.get('salesforce/amalan-protects/bank-complaints/' + this.bankId)
            .then((resp) => {
                this.isLoading = false;
                this.dataPinjaman = resp.data.data
                this.getDataPelanggaran();
            })
            .catch(() => {
                this.isLoading = false;
                this.swalErrorReload.fire().then((result) => {
                    if (result.isConfirmed){
                        window.location.reload();
                    }
                });
            })
        },
        getDataPelanggaran(){
            this.isLoading = true;
            amalansignupApi.get('salesforce/amalan-protects/bank-complaints/' + this.bankId + '/detail')
            .then((resp) => {
                this.isLoading = false;
                this.dataPelanggaran = resp.data.data
                if(this.dataPelanggaran.Proof_Document_1__c){
                    this.docPendukung = '1'
                }

                if(this.dataPelanggaran.Proof_Document_2__c){
                    this.docPendukung = '1,2'
                }

                if(this.dataPelanggaran.Proof_Document_3__c){
                    this.docPendukung = '1,2,3'
                }
            })
            .catch(() => {
                this.isLoading = false;
                this.swalErrorReload.fire().then((result) => {
                    if (result.isConfirmed){
                        window.location.reload();
                    }
                });
            })
        }
    },
    created(){
        this.isLoading = true;
        amalansignupApi.get('salesforce/amalan-protects/bank-complaints/' + this.bankId + '/check-status')
        .then((resp) => {
            this.statusCode = resp.status
            this.isLoading = false;
            this.getDataPinjaman();
        })
        .catch(() => {
            this.isLoading = false;
            this.swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        })
        
    },
}
</script>