import axios from "axios";
import store from "../store";
import router from "../router";
import vm from "../main";
import stytchApi from "./stytch";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.get['Accept'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';

axios.interceptors.request.use((config) => {
    if(!localStorage.getItem('accessToken')) return config;

    config.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem('accessToken');
    return config;
});

axios.interceptors.response.use(function(response) {
    return response
}, async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        if(localStorage.getItem("refreshToken")){
            const params = {
                access_token: localStorage.getItem("accessToken"),
                refresh_token: localStorage.getItem("refreshToken")
            }
    
            return stytchApi.post('refresh-token', params)
            .then(resp => {
                store.dispatch("auth/login", resp.data);
                localStorage.setItem("accessToken", resp.data.session_token);
                localStorage.setItem("refreshToken", resp.data.session_refresh_token);
                error.config.headers[
                    "Authorization"
                ] = `Bearer ${resp.data.session_token}`;
    
                return axios(error.config);
            })
            .catch(err => {
                console.log(err)
                store.dispatch("auth/logout");
                const swalErrorReload = vm.$swal.mixin({
                    customClass: {
                        confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                    },
                    buttonsStyling: false,
                    title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
                    html: '<p class="text-center text-sm mt-2">Maaf, sepertinya sesi Anda sudah habis. Silahkan login kembali!</p>',
                    showCancelButton: false,
                    confirmButtonText: 'Login Disini',
                    width: '340px',
                    allowOutsideClick: false,
                })
    
                swalErrorReload.fire().then((result) => {
                    if (result.isConfirmed){
                        window.location.reload();
                    }
                });
                router.push({ name: 'Login'});
            });
        }else{
            await store.dispatch("auth/logout");
            const swalErrorReload = vm.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, sepertinya sesi Anda sudah habis. Silahkan login kembali!</p>',
                showCancelButton: false,
                confirmButtonText: 'Login Disini',
                width: '340px',
                allowOutsideClick: false,
            })

            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
            router.push({ name: 'Login'});
        }
    }else{
        return Promise.reject(error);
    }
})

export default axios;
