import amalansignupApi from "@/axios/amalansignup"

const state = () => ({
    packages: null,
    amount_paid: 0,
    payment_page_data: {
        isTouched: false,
        current_package: null,
        new_package: null,
        payment_amount: null,
        discount: null,
        payment_expiration_date: null,
        payment_link: null
    },
    last_upgrade_package: {
        isFetching: false,
        status: null,
        data: null
    },
    history_upgrade_package: {
        pending: [],
        done: []
    }
})

const getters = {
    getPackageData(state) {
        return state.packages
    },
    getSignUpFeeAmountPaid(state) {
        return state.amount_paid
    },
    getPaymentPageData(state) {
        return state.payment_page_data
    },
    getLastUpgradePackage(state) {
        return state.last_upgrade_package
    },
    getLastUpgradePackageFetchStatus(state) {
        return state.last_upgrade_package.isFetching
    },
    getLastUpgradePackageStatus(state) {
        return state.last_upgrade_package.status
    },
    getHistoryUpgradePackage(state) {
        return state.history_upgrade_package
    }
}

const actions = {
    setPackageData({ commit }, payload) {
        commit('commitPackageData', payload)
    },
    resetPackageData({ commit }) {
        commit('resetPackageData')
    },
    setPaymentPageData({ commit }, payload) {
        commit('commitPaymentPageData', payload)
    },
    resetPaymentPageData({ commit }) {
        commit('commitResetPaymentPageData')
    },
    async requestLastUpgradePackage({state, dispatch, commit, rootGetters}){
        try {
            state.last_upgrade_package.isFetching = true
            const {status, data} = await amalansignupApi.get('loan-management/last-upgrade-package')
            state.last_upgrade_package.isFetching = false
            commit('setLastUpgradePackage', {status, data})
            dispatch('pendingUserActivity/setPendingActivityData', {
                userStage: rootGetters['user/getUserStage'],
                signUpStatus: rootGetters['contact/getSignUpStatus'],
                lastAmalanProtect: rootGetters['formAmalanProtect/getLastProtect'],
                latestService: rootGetters['services/getLatestService'],
                timeToDocCollection: rootGetters['docCollection/getTimeToDocCollection'],
                billCompleted: rootGetters['docCollection/getbillStatus'],
                docCompleted: rootGetters['docCollection/getDocStatus'],
                poaDocument: rootGetters['user/getDocumentPoa'],
                lastAdditionalSuf: rootGetters['additionalSUF/getLastAdditionalSuf'],
                lastUpgradePackage: rootGetters['upgradePricingPackage/getLastUpgradePackage'],
                negotiationResultApprovalFlag: rootGetters['negotiationResult/getNegotiationResultApprovalFlag']
            }, {root: true})
            return
        } catch (error) {
            state.last_upgrade_package.isFetching = false
            return commit('setLastUpgradePackage', {status: error.response.status ?? 404, data: null})
        }
    },
    async requestPurchaseHistory({ commit }) {
        try {
            const {status, data} = await amalansignupApi.get('loan-management/history-upgrade-package')
            return commit('setPurchaseHistory', {status, data: data?.data})
        } catch (error) {
            return commit('setPurchaseHistory', {status: error.response.status ?? 404, data: null})
        }
    },
}

const mutations = {
    commitPackageData(state, payload) {
        if(!payload.data.packages) return
        
        const data = payload.data.packages
        const packageList = Object.keys(data).map(value => {
            const pricingPackages = data[value]
            const services = Object.keys(pricingPackages.services).map(pricingPackage => {
                return {
                    service_name: pricingPackage,
                    ...pricingPackages.services[pricingPackage]
                }
            })

            const ribbon = value === 'gold_package' ? 'BEST SELLER' : value === 'diamond_package' ? 'BEST DEAL' : null

            return {
                package_name: value,
                ribbon,
                ...pricingPackages,
                services
            } 
        })

        const userPackageIndex = packageList.findIndex(value => value.package_name === payload.userPackage)
        packageList.splice(0, (userPackageIndex < 0 ? 0 : userPackageIndex) + 1)
        
        //remove diamond package if outstanding amount less than Rp 20,000,000
        // const diamondPackageIndex = packageList.findIndex(value => value.package_name === 'diamond_package')
        // if(diamondPackageIndex >= 0 && payload.totalOutstandingAmount < 20000000) packageList.splice(diamondPackageIndex, 1)
        
        //remove platinum package if outstanding amount less than Rp 50,000,000
        // const platinumPackageIndex = packageList.findIndex(value => value.package_name === 'platinum_package')
        // if(platinumPackageIndex >= 0 && payload.totalOutstandingAmount < 50000000) packageList.splice(platinumPackageIndex, 1)

        state.amount_paid = payload.data.total_amount_paid ?? 0
        state.packages = packageList
    },
    resetPackageData(state){
        state.packages = null
    },
    commitPaymentPageData(state, payload){
        state.payment_page_data.isTouched = true
        state.payment_page_data.current_package = payload.current_package ?? null
        state.payment_page_data.new_package = payload.new_package ?? null
        state.payment_page_data.payment_amount = payload.payment_amount ?? null
        state.payment_page_data.discount = 0
        state.payment_page_data.payment_expiration_date = payload.payment_expiration_date ?? null
        state.payment_page_data.payment_link = payload.payment_link ?? null
    },
    commitResetPaymentPageData(state){
        state.payment_page_data.isTouched = false
        state.payment_page_data.current_package = null
        state.payment_page_data.new_package = null
        state.payment_page_data.payment_amount = null
        state.payment_page_data.discount = null
        state.payment_page_data.payment_expiration_date = null
        state.payment_page_data.payment_link = null
    },
    setLastUpgradePackage(state, payload){
        state.last_upgrade_package.status = payload.status
        state.last_upgrade_package.data = payload.data?.data
    },
    setPurchaseHistory(state, payload){
        if(!payload.data) return
        if(payload.data.length === 0) return

        state.history_upgrade_package.pending = []
        state.history_upgrade_package.done = []

        const reversedData = payload.data.reverse()

        const tempPending = []
        const tempDone = []
        reversedData.forEach((value) => {
            if(!value.Pricing_Package_Payment__r) return tempPending.push(value)
            if(value.Pricing_Package_Payment__r.records.length === 0) return tempPending.push(value)
            if(value.Pricing_Package_Payment__r.records[0].Payment_Status__c === 'Open') return tempPending.push(value)
            return tempDone.push(value)
        })

        state.history_upgrade_package.pending = tempPending
        state.history_upgrade_package.done = tempDone
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
