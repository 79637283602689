<script setup>
import NavbarWithBackV2 from '@/components/NavbarWithBackV2.vue'
import CollapsableOpportunityList from '@/components/List/CollapsableOpportunityList.vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onMounted, ref, reactive, watch } from 'vue'
import opportunityHelper from '@/helpers/opportunity-helper'
import OpportunityCard from '@/components/Card/OpportunityCard.vue'
import useModalPopUp from '@/composables/modalPopUp'
import * as yup from 'yup'
import { Form, Field } from 'vee-validate'
import { CheckIcon } from '@heroicons/vue/solid'

const router = useRouter()
const route = useRoute()
const store = useStore()
const modalPopUp = useModalPopUp()

const targetPelunasanForm = computed(() => store.getters['targetPelunasan/getTargetPelunasanForm'])
const currentTargetPelunasan = computed(() => store.getters['targetPelunasan/getCurrentTargetPelunasan'])
const alasanPengajuanOption = computed(() => store.getters['targetPelunasan/getAlasanPengajuan'])
const newTargetPelunasan = computed(() => store.getters['targetPelunasan/getNewTargetPelunasan'])

const currentTarget = ref({
    Id: null,
    Name: null,
    Loan_Type_2__c: null,
    Estimated_Outstanding_Amount__c: null
})
const newTarget = ref({
    Id: null,
    Name: null,
    Loan_Type_2__c: null,
    Estimated_Outstanding_Amount__c: null
})
const formAdd = reactive({
    canceledOpportunityId: null,
    selectedOpportunityId: null,
    reason: [],
    optional: null
})
const isOptionalValidated = ref(false)
const withOptional = ref(false)
const optionalErrorMessage = ref(null)
const showOpportunityList = ref(false)
const selectOpportFirst = ref(false)

const formSchema = yup.object().shape({
    selectedOpportunityId: yup.string().nullable().required('Pilih target pelunasan Anda'),
    reason: yup.array().min(1, 'Pilih minimal satu alasan').required('Pilih minimal satu alasan'),
    optional: yup.string().nullable()
})

watch(() => formAdd.reason, () => {
    if(formAdd.reason.includes('optional')){
        withOptional.value = true
        return validateOptional()
    }
    
    withOptional.value = false
    return validateOptional()
})

watch(() => formAdd.optional, () => validateOptional())

const validateOptional = () => {
    if(!withOptional.value){
        isOptionalValidated.value = true
        return optionalErrorMessage.value = null
    }

    if(!formAdd.optional || formAdd.optional == '' || formAdd.optional.length < 10){
        isOptionalValidated.value = false
        return optionalErrorMessage.value = '*Minimal 10 karakter'
    }
    
    if(formAdd.optional && formAdd.optional.length > 50){
        isOptionalValidated.value = false
        return optionalErrorMessage.value = '*Maksimal 50 karakter'
    }
    
    isOptionalValidated.value = true
    return optionalErrorMessage.value = null
}

const findCurrentTargetPelunasan = () => {
    if(!route.params.opportunityId) return
    const result = currentTargetPelunasan.value.data.find((value) => value.Id == route.params.opportunityId)
    if(!result) return
    if(
        result.Case_Progress__c.toUpperCase().includes('AMALAN RECOMMENDATION') || 
        result.Case_Progress__c.toUpperCase().includes('CUSTOMER APPROVAL')
    ) return router.push({ name: 'Negotiation Process - Target Pelunasan - Home' })

    formAdd.canceledOpportunityId = currentTarget.value.Id = result.Id
    currentTarget.value.Name = result.Name
    currentTarget.value.Loan_Type_2__c = result.Loan_Type_2__c
    currentTarget.value.Estimated_Outstanding_Amount__c = result.Estimated_Outstanding_Amount__c
}

const findNewTargetPelunasan = () => {
    const result = newTargetPelunasan.value.data.find((value) => value.Id == formAdd.selectedOpportunityId)
    if(!result) return

    newTarget.value.Id = result.Id
    newTarget.value.Name = result.Name
    newTarget.value.Loan_Type_2__c = result.Loan_Type_2__c
    newTarget.value.Estimated_Outstanding_Amount__c = result.Estimated_Outstanding_Amount__c
}

const backButton = () => {
    if(newTargetPelunasan.value.data.length === 0) return router.push({ name: 'Negotiation Process - Target Pelunasan - Home' })
    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs text-amalan-black mt-2">Proses Pengajuan Perubahan Target Pelunasan Anda belum selesai.<br><br>Anda yakin ingin keluar dari halaman ini?</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Konfirmasi',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if(!result.isConfirmed) return

        store.dispatch('targetPelunasan/resetTargetPelunasanForm')
        return router.push({ name: 'Negotiation Process - Target Pelunasan - Home' })
    })
}

const openOpportunityList = () => {
    if(newTargetPelunasan.value.data.length === 0) return
    return showOpportunityList.value = true
}

const closeOpportunityList = () => showOpportunityList.value = false

const selectOpportunity = (value) => {
    formAdd.selectedOpportunityId = value
    selectOpportFirst.value = false
    findNewTargetPelunasan()
}

const preventToSelect = () => {
    if(newTargetPelunasan.value.data.length === 0) return
    return selectOpportFirst.value = true
}

const popUpAPOffer = async () => {
    const result = await modalPopUp.swalConfirmVerticalWithIcon.fire({
        html: '<p class="text-center text-xs text-amalan-black mt-2">Perubahan target pelunasan Anda didasari oleh tindakan <i>debt collector</i> tidak beretika.<br><br>Apakah Anda tertarik mencoba layanan <b>amalanPROTECT</b> atau <b>amalanPROTECT Hotline</b> untuk perlindungan <i>debt collector</i> tidak beretika dari amalan?</p>',
        showCancelButton: true,
        confirmButtonText: 'Lanjutkan Proses Pengajuan',
        cancelButtonText: 'Pelajari Lebih Lanjut',
    })
    
    if(result.isConfirmed) return false
    return true
}

const popUpGoToAP = async () => {
    const result = await modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs text-amalan-black mt-2">Anda akan diarahkan ke halaman layanan <b>amalanPROTECT</b> dan <b>amalanPROTECT Hotline</b> untuk informasi lebih detail.<br><br>Data yang Anda masukkan tidak akan tersimpan, apakah Anda yakin ingin melanjutkan?</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Lanjutkan',
        cancelButtonText: 'Tidak, Batalkan',
    })
    
    if(!result.isConfirmed) return
    return window.open('https://lp.amalan.com/id-amalanprotect/', '_blank')
}

const formAddSubmit = async () => {
    await store.dispatch('targetPelunasan/setTargetPelunasanForm', {
        canceledOpportunity: currentTarget.value ?? null,
        selectedOpportunity: newTarget.value ?? null,
        reason: formAdd.reason ?? [],
        optional: formAdd.optional ?? null
    })

    const withAPOffer = checkForUsingAPOffer()

    if(withAPOffer){
        const APOffer = await popUpAPOffer()
        if(APOffer) return popUpGoToAP() 
    }

    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs text-amalan-black mt-2">Pengajuan Target Pelunasan ini akan dilakukan peninjauan terlebih dahulu berdasarkan estimasi dan saldo RDP Anda.<br><br>Apakah Anda yakin ingin melakukan<br>pengajuan target pelunasan baru?</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Konfirmasi',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if(!result.isConfirmed) return
        return router.push({name: 'Negotiation Process - Target Pelunasan - Checkout'})
    })
}

const checkForUsingAPOffer = () => {
    let result = false
    formAdd.reason.every((a) => {
        const data = alasanPengajuanOption.value.data.find((b) => b.value == a)
        if(!data) return true
        if(!data.amalan_protect) return true
        result = data.amalan_protect
        return false
    })

    return result
}

const setFormDataFromState = () => {
    formAdd.canceledOpportunityId = currentTarget.value.Id = targetPelunasanForm.value.canceledOpportunity.Id ?? null
    currentTarget.value.Name = targetPelunasanForm.value.canceledOpportunity.Name ?? null
    currentTarget.value.Loan_Type_2__c = targetPelunasanForm.value.canceledOpportunity.Loan_Type_2__c ?? null
    currentTarget.value.Estimated_Outstanding_Amount__c = targetPelunasanForm.value.canceledOpportunity.Estimated_Outstanding_Amount__c ?? null
    formAdd.selectedOpportunityId = newTarget.value.Id = targetPelunasanForm.value.selectedOpportunity.Id ?? null
    newTarget.value.Name = targetPelunasanForm.value.selectedOpportunity.Name ?? null
    newTarget.value.Loan_Type_2__c = targetPelunasanForm.value.selectedOpportunity.Loan_Type_2__c ?? null
    newTarget.value.Estimated_Outstanding_Amount__c = targetPelunasanForm.value.selectedOpportunity.Estimated_Outstanding_Amount__c ?? null
    formAdd.reason = targetPelunasanForm.value.reason,
    formAdd.optional = targetPelunasanForm.value.optional
}

onMounted(() => {
    if(!newTargetPelunasan.value.isFetched) return router.push({ name: 'Negotiation Process - Target Pelunasan - Home' })
    if(targetPelunasanForm.value.isCompleted) return setFormDataFromState()

    findCurrentTargetPelunasan()
})
</script>

<template>
    <div class="min-h-screen bg-amalan-white relative">
        <NavbarWithBackV2 :title="route.meta.title" @back-button="backButton" />
        <div class="w-full pt-8 pb-20 px-6 bg-amalan-white text-amalan-black">
            <template v-if="route.params.opportunityId && currentTarget.Id">
                <p class="text-center text-amalan-blue-1 font-bold text-sm">Target Pelunasan Saat Ini</p>
                <div class="w-full mt-1 mb-3.5">
                    <OpportunityCard
                        :bank-name="currentTarget.Name ? opportunityHelper.getBankName(currentTarget.Name, currentTarget.Loan_Type_2__c) : '-'"
                        :loan-type="currentTarget.Loan_Type_2__c ? opportunityHelper.getLoanTitle(currentTarget.Loan_Type_2__c) : '-'"
                        :outstanding="currentTarget.Estimated_Outstanding_Amount__c ? $filters.format_number(currentTarget.Estimated_Outstanding_Amount__c) : '-'"
                        :image-source="require('@/assets/img/bank-lender-icon/' + opportunityHelper.getIcon(currentTarget.Name, currentTarget.Loan_Type_2__c))"
                    />
                </div>
            </template>
            <Form
                @submit="formAddSubmit"
                :validation-schema="formSchema"
                v-slot="{ errors, meta }"
            >
                <p class="text-center text-amalan-blue-1 font-bold text-sm">Pilih Target Pelunasan Baru</p>
                <div @click="openOpportunityList" class="w-full p-4 mt-1 rounded-lg bg-amalan-gray-5" :class="[ newTargetPelunasan.data.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer' ]">
                    <div v-if="newTargetPelunasan.data.length === 0" class="w-full rounded-lg border bg-amalan-white border-amalan-gray-4 px-4 py-9">
                        <p class="text-xs text-amalan-black text-center">Mohon maaf, tidak terdapat data pinjaman yang dapat dipilih</p>
                    </div>
                    <OpportunityCard
                        v-else-if="newTarget.Id"
                        :bank-name="newTarget.Name ? opportunityHelper.getBankName(newTarget.Name, newTarget.Loan_Type_2__c) : '-'"
                        :loan-type="newTarget.Loan_Type_2__c ? opportunityHelper.getLoanTitle(newTarget.Loan_Type_2__c) : '-'"
                        :outstanding="newTarget.Estimated_Outstanding_Amount__c ? $filters.format_number(newTarget.Estimated_Outstanding_Amount__c) : '-'"
                        :image-source="newTarget.Name && newTarget.Loan_Type_2__c ? require('@/assets/img/bank-lender-icon/' + opportunityHelper.getIcon(newTarget.Name, newTarget.Loan_Type_2__c)) : ''"
                    />
                    <div v-else class="w-full rounded-lg border bg-amalan-white border-amalan-gray-4 px-4 py-9">
                        <p class="text-xs text-amalan-black text-center">Pilih target pelunasan Anda</p>
                    </div>
                    <Field name="selectedOpportunityId" :validateOnInput="true" :validateOnMount="true" v-model="formAdd.selectedOpportunityId" type="text" class="hidden" />
                    <button type="button" class="w-full mt-4 py-1.5 text-center border rounded-amalan-lg text-xs font-bold" :class="[ newTargetPelunasan.data.length === 0 ? 'bg-amalan-gray-4 border-amalan-gray-4 text-amalan-gray-2 cursor-not-allowed' : !formAdd.selectedOpportunityId ? 'text-amalan-white bg-amalan-blue-1 border-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-blue-1 bg-amalan-white border-amalan-blue-1 hover:bg-amalan-gray-5 shadow-amalan-md' ]">Pilih Data Pinjaman</button>
                </div>
                <p v-if="selectOpportFirst" class="text-2xs mt-1 text-amalan-red">*Pilih target pelunasan Anda terlebih dahulu</p>
                <p class="text-center text-amalan-blue-1 font-bold text-sm mt-3.5">Alasan {{ route.params.opportunityId && currentTarget.Id ? 'Perubahan' : 'Pengajuan' }}</p>
                <div class="w-full p-4 mt-1 rounded-lg bg-amalan-white border border-amalan-gray-3 grid grid-cols-1 gap-y-3.5 relative">
                    <div v-if="errors.selectedOpportunityId" @click="preventToSelect" class="absolute bg-transparent inset-0" :class="[ newTargetPelunasan.data.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer' ]"></div>
                    <template v-for="(alasan_pengajuan, index) in alasanPengajuanOption.data" :key="index">
                        <label :for="alasan_pengajuan.value" class="text-xs flex items-center">
                            <label class="h-4 w-4 rounded-full mr-2 cursor-pointer flex justify-center items-center" :for="alasan_pengajuan.value" :class="formAdd.reason && formAdd.reason.includes(alasan_pengajuan.value) ? 'bg-amalan-yellow-400' : 'bg-amalan-gray-5'">
                                <CheckIcon class="w-3 h-3" :class="formAdd.reason && formAdd.reason.includes(alasan_pengajuan.value) ? 'text-amalan-blue-1' : 'text-amalan-gray-2'"/>
                            </label>
                            <Field name="reason" :validateOnInput="true" :validateOnMount="true" :value="alasan_pengajuan.value" v-model="formAdd.reason" type="checkbox" class="hidden" :id="alasan_pengajuan.value" />
                            <span class="cursor-pointer text-xs">{{ alasan_pengajuan.value }}</span>
                        </label>
                    </template>
                    <label for="optional" class="text-xs flex items-start">
                        <div class="w-20 h-7 flex items-center">
                            <label class="h-4 w-4 rounded-full mr-2 cursor-pointer flex justify-center items-center" for="optional" :class="formAdd.reason && formAdd.reason.includes('optional') ? 'bg-amalan-yellow-400' : 'bg-amalan-gray-5'">
                                <CheckIcon class="w-3 h-3" :class="formAdd.reason && formAdd.reason.includes('optional') ? 'text-amalan-blue-1' : 'text-amalan-gray-2'"/>
                            </label>
                            <Field name="reason" :validateOnInput="true" :validateOnMount="true" :value="'optional'" v-model="formAdd.reason" type="checkbox" class="hidden" :id="'optional'" />
                            <span class="cursor-pointer text-xs">Lainnya</span>
                        </div>
                        <div class="ml-2 w-full">
                            <Field type="text" name="optional" v-model="formAdd.optional" :validateOnInput="true" class="h-7 w-full bg-amalan-white border rounded-md focus:border-amalan-blue-2 focus:outline-none appearance-none px-2 text-xs" :class="[ withOptional ? !isOptionalValidated ? 'border-amalan-red' : 'border-amalan-gray-4' : 'border-amalan-gray-4' ]"></Field>
                            <span v-if="withOptional && optionalErrorMessage" class="text-2xs text-amalan-red">{{ optionalErrorMessage }}</span>
                        </div>
                    </label>
                </div>
                <p v-if="errors.reason && newTargetPelunasan.data.length > 0" class="text-2xs text-amalan-blue-3 mt-1">*{{ errors.reason }}</p>
                <button type="submit" class="w-full py-3 mt-11 text-center text-sm font-bold rounded-amalan-lg" :class="[ withOptional ? isOptionalValidated && meta.valid ? 'cursor-pointer text-amalan-white bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' : meta.valid ? 'cursor-pointer text-amalan-white bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed']" :disabled="withOptional ? isOptionalValidated && meta.valid ? false : true : meta.valid ? false : true">Kirim</button>
            </Form>
        </div>
        <CollapsableOpportunityList
            :is-visible="showOpportunityList"
            :is-fetching="newTargetPelunasan.isFetching"
            :opportunity-list="newTargetPelunasan.data"
            :title="'Pilih Data Pinjaman'"
            @on-close="closeOpportunityList"
            @on-select="selectOpportunity"
        />
    </div>
</template>