<script setup>
import { defineProps, defineEmits } from 'vue'
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

import checkMarkLottie from '@/assets/lottie/check-mark.json'

defineProps({
    visible: Boolean,
    content: String
})

const emit = defineEmits(['onClose'])

const closeModal = () => emit('onClose')

</script>

<template>
    <Transition name="fade-fast">
        <div v-show="visible" class="absolute left-0 right-0 top-14 bottom-0 bg-black bg-opacity-50 p-6 flex justify-center cursor-default" @click="closeModal">
            <div class="w-full py-8 px-4 bg-amalan-white rounded-lg mt-48 h-48">
                <div class="w-full flex justify-center items-center">
                    <Vue3Lottie
                        v-if="visible"
                        :animationData="checkMarkLottie"
                        :height="60"
                        :width="60"
                        :loop="false"
                        :speed="0.7"
                    />
                </div>
                <p class="mt-8 text-amalan-black text-xs text-center" v-html="content"></p>
            </div>
        </div>
    </Transition>
</template>

<style scoped>
.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.3s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.3s ease
}
</style>