import { inject } from "vue"

export default function(){
    const swal = inject("$swal")

    const swalConfirmWithIcon = swal.mixin({
        customClass: {
            confirmButton: 'mx-2 py-3 w-34 text-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-blue-1 hover:bg-amalan-blue-2 text-xs font-bold text-amalan-white',
            cancelButton: 'mx-2 py-3 w-34 text-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-white hover:bg-amalan-gray-5 text-xs font-bold text-amalan-blue-1'
        },
        title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
        buttonsStyling: false,
        width: '340px',
        allowOutsideClick: false,
        reverseButtons: true,
    })

    const swalConfirmBtnFullWithIcon = swal.mixin({
        customClass: {
            actions: 'flex-col gap-y-2',
            confirmButton: 'mx-2 py-3 px-1 w-64 text-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-blue-1 hover:bg-amalan-blue-2 text-xs font-bold text-amalan-white',
            cancelButton: 'mx-2 py-3 px-1 w-64 text-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-white hover:bg-amalan-gray-5 text-xs font-bold text-amalan-blue-1'
        },
        title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
        buttonsStyling: false,
        width: '340px',
        allowOutsideClick: false,
        reverseButtons: true,
        showCancelButton: false
    })
    
    const swalConfirmVerticalWithIcon = swal.mixin({
        customClass: {
            actions: 'flex-col gap-3.5',
            confirmButton: 'py-3 w-72 text-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-blue-1 hover:bg-amalan-blue-2 text-xs font-bold text-amalan-white',
            cancelButton: 'py-3 w-72 text-center border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md bg-amalan-white hover:bg-amalan-gray-5 text-xs font-bold text-amalan-blue-1'
        },
        title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
        buttonsStyling: false,
        width: '340px',
        allowOutsideClick: false,
        reverseButtons: false,
    })

    const swalConfirm = swal.mixin({
        customClass: {
            confirmButton: 'bg-amalan-blue-1 mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-amalan-white text-sm font-semibold hover:bg-blue-900 hover:border-blue-900',
            cancelButton: 'bg-amalan-white mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-amalan-blue-1 text-sm font-semibold hover:bg-amalan-red hover:border-amalan-red hover:text-amalan-white'
        },
        buttonsStyling: false,
        width: '340px',
        allowOutsideClick: false,
        reverseButtons: true,
    })

    const swalError = swal.mixin({
        customClass: {
            confirmButton: 'bg-amalan-blue-1 mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-white text-sm font-semibold hover:bg-blue-900 hover:border-blue-900',
            cancelButton: 'absolute -top-3 -right-3'
        },
        buttonsStyling: false,
        title: '<img src="/img/alert-error-icon.svg" class="w-12 h-12 mx-auto">',
        width: '340px',
        allowOutsideClick: false,
        reverseButtons: true,
    })

    const swalErrorExclamation = swal.mixin({
        customClass: {
            confirmButton: 'bg-amalan-blue-1 mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-white text-sm font-semibold hover:bg-blue-900 hover:border-blue-900',
            cancelButton: 'absolute -top-4 -right-4'
        },
        buttonsStyling: false,
        title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
        width: '340px',
        allowOutsideClick: false,
        reverseButtons: true,
    })

    const swalErrorReload = swal.mixin({
        customClass: {
            confirmButton: 'bg-amalan-blue-1 mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-white text-sm font-semibold hover:bg-blue-900 hover:border-blue-900'
        },
        buttonsStyling: false,
        title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
        html: '<p class="text-center text-sm mt-2">Maaf, terjadi kesalahan dalam pengambilan informasi!</p>',
        showCancelButton: false,
        confirmButtonText: 'Reload',
        width: '340px',
        allowOutsideClick: false,
    })

    const swalCameraError = swal.mixin({
        customClass: {
            confirmButton: 'bg-amalan-blue-1 mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-amalan-white text-sm font-semibold hover:bg-blue-900 hover:border-blue-900',
            cancelButton: 'bg-amalan-white mx-2 py-2 px-5 rounded-lg border border-amalan-blue-1 text-amalan-blue-1 text-sm font-semibold hover:bg-amalan-red hover:border-amalan-red hover:text-amalan-white'
        },
        buttonsStyling: false,
        title: '<img src="/img/camera-error-icon.svg" class="w-12 h-12 mx-auto">',
        width: '340px',
        allowOutsideClick: false,
        reverseButtons: true,
    })

    return {
        swalConfirm,
        swalConfirmWithIcon,
        swalError,
        swalErrorReload,
        swalConfirmVerticalWithIcon,
        swalConfirmBtnFullWithIcon,
        swalErrorExclamation,
        swalCameraError
    }
}
