<script setup>
import { defineProps, defineEmits } from 'vue'
import { ChevronLeftIcon } from '@heroicons/vue/outline'

const props = defineProps({
    title: String
})

const emit = defineEmits(['backButton'])

const backButtonAction = () => emit('backButton')

</script>

<template>
    <nav class="w-full bg-amalan-blue-7">
        <div class="px-4 py-3 mx-auto">
            <div class="flex items-center justify-between">
                <button @click="backButtonAction" class="flex items-center justify-center w-6 h-8">
                    <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                </button>
                <span class="text-base font-bold text-amalan-black">{{ props.title || 'undefined' }}</span>
                <div class="flex items-center">
                    <div class="w-5 h-5 bg-transparent"></div>
                </div>
            </div>
        </div>
    </nav>
</template>