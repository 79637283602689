<template>
    <div class="pt-4 min-h-screen h-auto relative">
        <div class="mt-2 pb-12 w-full px-4">
            <div class="flex items-center">
                <div class="flex items-center text-blue-900 relative">
                    <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                        <img src="@/assets/img/signup/data-pinjaman-active.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Data<br>Pinjaman</p></div>
                </div>
                <div class="flex-auto border-t-2 border-blue-900"></div>
                <div class="flex items-center text-blue-900 relative">
                    <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                        <img src="@/assets/img/protect/data-pelanggaran-active.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Data<br>Pelanggaran</p></div>
                </div>
                <div class="flex-auto border-t-2 border-blue-900"></div>
                <div class="flex items-center text-blue-900 relative">
                    <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                        <img src="@/assets/img/signup/tanda-tangan-active.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Tanda<br>Tangan</p></div>
                </div>
                <div class="flex-auto border-t-2 border-gray-300"></div>
                <div class="flex items-center text-gray-500 relative">
                    <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                        <img src="@/assets/img/signup/pembayaran.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Pembayaran<br>Layanan</p></div>
                </div>
                <div class="flex-auto border-t-2 border-gray-300"></div>
                <div class="flex items-center text-gray-500 relative">
                    <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                        <img src="@/assets/img/verified.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Konfirmasi<br>Layanan</p></div>
                </div>
            </div>
        </div>
        <div class="mt-6 pb-40 w-full px-4">
            <div class="w-full rounded-md shadow-md border border-gray-50 p-2">
                <a v-if="urlHelloSign" :href="urlHelloSign" target="_blank" class="py-3 px-4 text-center mb-4 block text-sm font-bold bg-blue-900 border border-blue-900 text-white rounded-lg hover:bg-blue-700 hover:border-blue-700" @click="switchButton">
                    Tanda Tangan Disini
                </a>
                <button v-else type="button" class="py-3 px-4 mx-auto mb-4 block text-sm font-bold bg-blue-900 border border-blue-900 text-white rounded-lg hover:bg-blue-700 hover:border-blue-700" @click="checkDigitalSignStatus">
                    Konfirmasi Tanda Tangan
                </button>
                <p class="text-sm font-semibold">Silahkan tekan tombol diatas, Anda akan diarahkan ke jendela baru untuk melakukan tanda tangan. <br><br>Jika Anda sudah tanda tangan maka Anda dapat melanjutkan ke langkah berikutnya melalui tombol dibawah kanan atau tombol Konfirmasi diatas.</p>
            </div>
        </div>
        <div class="absolute bottom-0 mb-28 w-full px-4">
            <button type="button" @click="checkDigitalSignStatus" class="h-10 rounded-lg font-medium border border-solid cursor-pointer text-center text-base py-1 px-1 text-white border-blue-900 bg-blue-900 hover:bg-blue-700 hover:border-blue-700 float-right"><ChevronRightIcon class="h-full" /></button>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ChevronRightIcon } from '@heroicons/vue/solid';

export default {
    components:{
        ChevronRightIcon
    },
    data(){
        return {
            isLoading: false,
            urlHelloSign: null,
            swalConfirm: this.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600',
                    cancelButton: 'bg-white mx-2 py-2 px-5 rounded-lg border border-blue-900 text-blue-900 text-sm font-semibold hover:bg-red-400 hover:border-red-400 hover:text-white'
                },
                buttonsStyling: false,
                width: '340px',
                allowOutsideClick: false,
                reverseButtons: true,
            }),
            swalError: this.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600',
                    cancelButton: 'absolute -top-3 -right-3'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-icon.svg" class="w-12 h-12 mx-auto">',
                width: '340px',
                allowOutsideClick: false,
                reverseButtons: true,
            }),
            swalErrorReload: this.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })
        }
    },
    computed: {
        ...mapGetters('formAmalanProtect',{
            protectStatus:'getProtectStatus',
            protect:'getProtect',
        })
    },
    methods:{
        checkDigitalSignStatus(){
            this.isLoading = true;
            setTimeout(() => {
                this.$store.dispatch('formAmalanProtect/requestProtectStatus', this.$route.params.idBank).then(() => {
                    this.isLoading = false;
                    if(this.protectStatus == 404){
                        this.$router.push('/amalan-protect/data-pinjaman');
                    }else if(this.protectStatus == 201){
                        if(this.protect && this.protect.data && this.protect.data.url){
                            this.urlHelloSign = this.protect.data.url
                        }
                    }else{
                        this.$router.push('/amalan-protect/pembayaran/' + this.$route.params.idBank);
                    }
                });
            }, 5000)
        },
        switchButton(){
            setTimeout(() => {
                this.urlHelloSign = null;
            }, 1000);
        },
    },
    created(){
        this.checkDigitalSignStatus();
    }
}
</script>