<script setup>
import { defineProps, defineEmits, ref, computed, watch } from 'vue'
import { onClickOutside } from '@vueuse/core'
import opportunityHelper from '@/helpers/opportunity-helper'
import OpportunityCard from '@/components/Card/OpportunityCard.vue'
import LoadingDataWithoutMargin from '@/components/LoadingDataWithoutMargin.vue'

const props = defineProps({
    isVisible: Boolean,
    isFetching: Boolean,
    title: String,
    opportunityList: Array
})

const emit = defineEmits(['onClose', 'onSelect'])

const parentComponent = ref(null)
const isShow = computed(() => props.isVisible ? true : false)
const listShow = ref(false)

watch(() => isShow.value, () => isShow.value ? setTimeout(() => listShow.value = true, 150) : listShow.value = false)

onClickOutside(parentComponent, () => {
    if(!isShow.value) return
    listShow.value = false
    setTimeout(() => emit('onClose'), 150)
})

const onSelect = (value) => {
    listShow.value = false
    setTimeout(() => emit('onClose'), 150)
    emit('onSelect', value)
}

</script>

<template>
    <Transition name="fade-fast">
        <div v-show="isShow" class="fixed inset-0 z-30">
            <div class="w-full bg-black bg-opacity-50 min-h-screen max-w-lg mx-auto relative">
                <Transition name="bottom-to-top">
                    <div v-show="listShow" ref="parentComponent" class="w-full bg-amalan-white px-6 pt-3.5 pb-6 rounded-t-2xl absolute bottom-0" style="height: 550px;">
                        <div class="w-16 h-1 rounded-amalan-lg bg-amalan-gray-6 mx-auto"></div>
                        <p class="my-3.5 text-amalan-black text-sm font-bold">{{ props.title ? props.title : 'undefined' }}</p>
                        <div v-if="props.isFetching" class="py-32"><LoadingDataWithoutMargin /></div>
                        <div v-if="props.opportunityList && props.opportunityList.length > 0" class="w-full pr-1.5 pb-2 grid grid-cols-1 auto-rows-min gap-y-3.5 overflow-y-auto scrollbar h-full max-h-100">
                            <OpportunityCard
                                v-for="(opportunity, index) in props.opportunityList"
                                :key="index"
                                :with-action="true"
                                :bank-name="opportunity.Name ? opportunityHelper.getBankName(opportunity.Name, opportunity.Loan_Type_2__c) : '-'"
                                :loan-type="opportunity.Loan_Type_2__c ? opportunityHelper.getLoanTitle(opportunity.Loan_Type_2__c) : '-'"
                                :outstanding="opportunity.Estimated_Outstanding_Amount__c ? $filters.format_number(opportunity.Estimated_Outstanding_Amount__c) : '-'"
                                :image-source="require('@/assets/img/bank-lender-icon/' + opportunityHelper.getIcon(opportunity.Name, opportunity.Loan_Type_2__c))"
                                @on-action="onSelect(opportunity.Id)"
                            />
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    </Transition>
</template>

<style scoped>
.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.3s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.3s ease
}

.bottom-to-top-enter-active,
.bottom-to-top-leave-active {
    transition: all 0.3s ease;
    bottom: 0px;
}

.bottom-to-top-enter-from,
.bottom-to-top-leave-to {
    bottom: -550px;
    transition: all 0.3s ease
}

.scrollbar::-webkit-scrollbar {
    width: 9px;
    height: 20px;
}

.scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #FFFFFF;
    border: 1px solid #BBBBBC
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #BBBBBC;
    border: 3px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #989A9B;
}
</style>