<script setup>
import { useRouter, useRoute } from 'vue-router'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { computed, inject, onMounted } from 'vue'
import { useStore } from 'vuex'
import CreditCheckupIcon from '@/components/icon/CreditCheckup.vue'
import CreditScoreIcon from '@/components/icon/CreditScore.vue'
import CreditReportIcon from '@/components/icon/CreditReport.vue'

const router = useRouter()
const route = useRoute()
const store = useStore()
const filters = inject('Filters')

const allServices = computed(() => store.getters['services/getAllServices'])
const serviceStatuses = computed(() => store.getters['services/getServiceStatuses'])

const constructOrderStatus = (status) => {
    const serviceStatus = serviceStatuses.value.find((v) => v.name.toLowerCase() == status.toLowerCase())
    if(!serviceStatus) return `: <span>-</span>`
    return `: <span class="${serviceStatus.class}">${serviceStatus.text}</span>`
}

onMounted(() => {
    if (allServices.value.data.length > 0) return
    store.dispatch('services/requestServices')
})
</script>

<template>
    <div class="min-h-screen bg-amalan-white relative">
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="() => { router.push({ name : 'Credit Checkup - Home' }) }" class="flex items-center mt-0">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold mt-0 text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full pt-8 pb-20 px-6 bg-amalan-white">
            <p class="text-center text-amalan-black text-xs">Anda dapat melihat riwayat pembelian<br>layanan Credit Checkup Product Anda</p>
            <template v-if="!allServices.isFetching">
                <div v-if="allServices.data.length > 0" class="w-full mt-8 grid grid-cols-1 gap-3.5">
                    <div v-for="(data, index) in allServices.data" :key="index" class="w-full bg-amalan-gray-5 rounded-lg">
                        <div class="w-full p-4 flex gap-x-2">
                            <div class="w-16 bg-amalan-blue-2 rounded-lg flex justify-center items-center">
                                <CreditScoreIcon width="60" v-if="data.service_catalog.name == 'Credit Score'" :iconColor="'active'" />
                                <CreditReportIcon width="60" v-else-if="data.service_catalog.name == 'Credit Report'" :iconColor="'active'" />
                                <CreditCheckupIcon v-else width="60" :iconColor="'active'" />
                            </div>
                            <div class="flex-grow py-1">
                                <p class="text-sm font-bold text-amalan-blue-1">Layanan {{ data.service_catalog.name || '-' }}</p>
                                <div class="grid grid-cols-2 gap-x-0.5 text-amalan-black text-2xs mt-2">
                                    <p>Tanggal Pembelian</p>
                                    <p>: {{ data.created_at ? filters.formatDateOnlyDate(data.created_at) : '-' }}</p>
                                    <p>Status Pembelian</p>
                                    <p class="font-bold" v-html="constructOrderStatus(data.service_stage.name)"></p>
                                </div>
                            </div>
                        </div>
                        <div class="w-full p-4 border-t border-amalan-white">
                            <router-link v-if="data.service_stage.name == 'CC_waiting for service' || data.service_stage.name == 'CC_done'" :to="{ name: 'Credit Checkup - Show', params: { id: data.id }, query: { catalog_name: data.service_catalog.name } }" class="block w-full py-1.5 text-center shadow-amalan-md border border-amalan-blue-1 rounded-amalan-lg text-xs font-bold text-amalan-blue-1 bg-amalan-white hover:bg-amalan-gray-5">Lihat Hasil Perhitungan Kredit</router-link>
                            <router-link v-else-if="data.service_stage.name == 'CC_waiting for payment'" :to="{ name: 'Ringkasan Booking Layanan', params: { id: data.id } }" class="block w-full py-1.5 text-center shadow-amalan-md border border-amalan-blue-1 rounded-amalan-lg text-xs font-bold text-amalan-blue-1 bg-amalan-white hover:bg-amalan-gray-5">Lanjutkan Proses Pembayaran</router-link>
                            <router-link v-else :to="{ name: 'Catalog List' }" class="block w-full py-1.5 text-center shadow-amalan-md border border-amalan-blue-1 rounded-amalan-lg text-xs font-bold text-amalan-blue-1 bg-amalan-white hover:bg-amalan-gray-5">Lakukan Pembelian Layanan</router-link>
                        </div>
                    </div>
                </div>
                <div v-else class="w-full px-6 flex flex-col justify-center items-center pt-34">
                    <img src="@/assets/img/clipboard-v2.svg" class="w-40 mx-auto">
                    <p class="text-center text-xs mt-4">Mohon maaf, Anda belum memiliki riwayat pembelian layanan Credit Checkup Product</p>
                </div>
            </template>
            <loading-data-without-margin v-else class="mt-32" />
        </div>
    </div>
</template>