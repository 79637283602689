<script setup>
import { computed, reactive, onMounted, inject } from 'vue'
import { CheckIcon } from '@heroicons/vue/solid'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import CreditCheckupIcon from '@/components/icon/CreditCheckup.vue'
import CreditScoreIcon from '@/components/icon/CreditScore.vue'
import CreditReportIcon from '@/components/icon/CreditReport.vue'
import StarDecorationIcon from '@/components/icon/StarDecoration.vue'
import { eventName, mixpanelPushEvent } from '@/composables/mixpanel'

const dummyCreditCheckup = ['Laporan Credit Score', 'Laporan Credit Report', 'Laporan Credit Checkup Statement', 'Analisa Uji Kelayakan Kredit', 'Konsultasi dengan Credit Advisor amalan', 'Bekerjasama dengan PT CLIK sebagai LPIP berijin OJK']

const store = useStore()
const router = useRouter()
const route = useRoute()
const filters = inject('Filters')

const catalogs = computed(() => store.getters['catalog/getCatalog'])
const dataUser = computed(() => store.getters['user/getUser'])
const applicationPurposeDatas = computed(() => store.getters['creditCheckup/getApplicationPurposeData'])

const selectedCatalog = reactive({
    serviceId: null,
    serviceName: null
})

// watch(() => catalogs.value, () => {
//     if(!catalogs.value) return
//     return checkProductDestination()
// })

const setSelectedCatalog = (serviceId, serviceName) => {
    selectedCatalog.serviceId = serviceId
    selectedCatalog.serviceName = serviceName
    return goToCatalog()
}

const goToCatalog = () => {
    if(selectedCatalog.serviceName){
        if(selectedCatalog.serviceName === 'Credit Checkup'){
            router.push({ name: 'Book Konsultasi' })
        }else{
            const payload = {
                serviceCatalogId: selectedCatalog.serviceId,
                email: dataUser.value.email,
                fullName: dataUser.value.first_name + ' ' + dataUser.value.last_name,
                gender: dataUser.value.gender,
                phone: dataUser.value.phone_number
            }

            store.dispatch('bookingData/setNonBookedData', payload)
            router.push({ name: 'Catalog Summary', params: { id: payload.serviceCatalogId } })
        }
    }
}

// const checkProductDestination = () => {
//     if(localStorage.getItem('ccu-product-destination')){
//         const mapCatalogs = catalogs.value.map((elem) => {
//             return {
//                 serviceCatalogId: elem.serviceCatalogId,
//                 serviceName: elem.serviceName.toLowerCase().replace(' ', '-'),
//             }
//         })
        
//         const productDestination = localStorage.getItem('ccu-product-destination')
//         const findCatalog = mapCatalogs.filter((elem) => elem.serviceName == productDestination)
//         if(findCatalog[0]){
//             const catalog = catalogs.value.filter((elem) => elem.serviceCatalogId == findCatalog[0].serviceCatalogId)
//             setSelectedCatalog(catalog[0].serviceCatalogId, catalog[0].serviceName)
//             goToCatalog()
//         }
//     }
// }

onMounted(() => {
    store.dispatch('bookingData/resetNonBookedData')

    if(!applicationPurposeDatas.value) store.dispatch('creditCheckup/requestApplicationPurpose')

    if(localStorage.getItem('ccu-product-destination')){
        mixpanelPushEvent(eventName.CC_CATALOG, {
            applied_program: localStorage.getItem('ccu-product-destination')
        })
    }

    // if(catalogs.value) checkProductDestination()
})
</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="() => { router.push({ name : 'Credit Checkup - Home' }) }" class="flex items-center mt-0">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold mt-0 text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="pt-8 relative bg-amalan-white text-amalan-black min-h-screen pb-24">
            <div class="w-full px-8">
                <h1 class="text-center text-amalan-blue-1 font-bold text-base">Pilih Layanan</h1>
                <p class="text-center text-xs mt-1">Anda dapat memilih layanan yang sesuai<br>dengan kebutuhan Anda</p>
                <div v-if="catalogs" class="w-full grid grid-cols-1 gap-y-3.5 mt-8">
                    <div class="w-full shadow-amalan-sm rounded-lg pb-4 cursor-pointer" v-for="catalog in catalogs" :key="catalog.serviceCatalogId" @click="setSelectedCatalog(catalog.serviceCatalogId, catalog.serviceName)" :class="[ catalog.serviceName == 'Credit Report' ? 'bg-amalan-yellow-100 border border-amalan-blue-1' : 'bg-amalan-white']">
                        <div class="py-3 px-4 flex justify-center items-center rounded-t-lg relative" :class="selectedCatalog.serviceId ? selectedCatalog.serviceId == catalog.serviceCatalogId ? 'bg-amalan-blue-1 hover:bg-amalan-blue-2' : 'bg-amalan-gray-5' : 'bg-amalan-blue-1 hover:bg-amalan-blue-2'">
                            <img v-if="catalog.serviceName == 'Credit Report'" src="@/assets/img/credit-report/penawaran-terbatas.svg" class="absolute -bottom-6 -right-1" />
                            <StarDecorationIcon v-if="catalog.serviceName == 'Credit Checkup'" class="absolute right-0 top-0" :iconColor="selectedCatalog.serviceId ? selectedCatalog.serviceId == catalog.serviceCatalogId ? 'active' : 'pasive' : 'active'" />
                            <CreditScoreIcon width="100" height="60" v-if="catalog.serviceName == 'Credit Score'" :iconColor="selectedCatalog.serviceId ? selectedCatalog.serviceId == catalog.serviceCatalogId ? 'active' : 'pasive' : 'active'" />
                            <CreditReportIcon width="100" height="60" v-else-if="catalog.serviceName == 'Credit Report'" :iconColor="selectedCatalog.serviceId ? selectedCatalog.serviceId == catalog.serviceCatalogId ? 'active' : 'pasive' : 'active'" />
                            <CreditCheckupIcon v-else class="w-28" :iconColor="selectedCatalog.serviceId ? selectedCatalog.serviceId == catalog.serviceCatalogId ? 'active' : 'pasive' : 'active'" />
                            <div class="sm:ml-2 text-center w-36" :class="selectedCatalog.serviceId ? selectedCatalog.serviceId == catalog.serviceCatalogId ? 'text-amalan-white' : 'text-amalan-gray-2' : 'text-amalan-white'">
                                <h3 class="text-xs">{{ catalog.serviceName == 'Credit Report' ? 'Credit Score & Report' : catalog.serviceName }}</h3>
                                <h1 v-if="catalog.status || catalog.serviceName == 'Credit Report'" class="text-xs font-semibold line-through -mb-1" style="text-decoration-color: #E41C1C; text-decoration-thickness: 2px;">{{ filters.format_number(catalog.serviceName == 'Credit Report' ? 100000 : catalog.basePrice) }}</h1>
                                <h1 class="text-1xl font-bold" :class="selectedCatalog.serviceId ? selectedCatalog.serviceId == catalog.serviceCatalogId ? 'text-amalan-yellow-400' : 'text-amalan-gray-2' : 'text-amalan-yellow-400'">{{ filters.format_number(catalog.totalPrice) }}</h1>
                            </div>
                        </div>
                        <div class="px-5 pt-3.5">
                            <ul>
                                <li class="flex items-center py-1" v-for="(value, key) in catalog.serviceCatalogDescription" :key="key">
                                    <CheckIcon class="h-4 w-4 flex-none" :class="selectedCatalog.serviceId ? selectedCatalog.serviceId == catalog.serviceCatalogId ? 'text-amalan-blue-2' : 'text-amalan-gray-3' : 'text-amalan-blue-2'"/>
                                    <div class="text-xs ml-2" :class="selectedCatalog.serviceId ? selectedCatalog.serviceId == catalog.serviceCatalogId ? 'text-amalan-black' : 'text-amalan-gray-2' : 'text-amalan-black'">{{ value }}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="w-full bg-amalan-white shadow-amalan-sm rounded-lg pb-4 cursor-not-allowed">
                        <div class="py-3 px-4 flex justify-center items-center rounded-t-lg relative bg-amalan-gray-4">
                            <img src="@/assets/img/credit-checkup/segera-hadir.svg" class="absolute -bottom-6 -right-1" />
                            <StarDecorationIcon class="absolute right-0 top-0" :iconColor="'pasive'" />
                            <CreditCheckupIcon class="w-28" :iconColor="'pasive'" />
                            <div class="sm:ml-2 text-center w-36 text-amalan-gray-2">
                                <h3 class="text-xs">Credit Checkup</h3>
                                <h1 class="text-1xl font-bold">{{ filters.format_number(300000) }}</h1>
                            </div>
                        </div>
                        <div class="px-5 pt-3.5">
                            <ul>
                                <li v-for="(value, index) in dummyCreditCheckup" class="flex items-center py-1" :key="index">
                                    <CheckIcon class="h-4 w-4 text-amalan-gray-3 flex-none"/>
                                    <div class="text-xs ml-2 text-amalan-gray-2">{{ value }}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <loading-data-without-margin v-else class="pt-40" />
            </div>
        </div>
        <!-- <div class="w-full mt-24 px-4 pb-24">
            <button type="button" class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-1 float-right" :class="[ selectedCatalog.serviceId ? 'text-amalan-white bg-amalan-blue-1 border-amalan-blue-1 cursor-pointer hover:bg-blue-900 hover:border-blue-900' : 'text-amalan-gray-3 bg-amalan-gray-5 border-amalan-gray-5 cursor-not-allowed' ]" :disabled="!selectedCatalog.serviceId" @click="goToCatalog"><ChevronRightIcon class="h-full" /></button>
        </div> -->
    </div>
</template>