<script setup>
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import axios from "../../axios"

const router = useRouter()
const route = useRoute()
const store = useStore()
const profilePicture = computed(() => store.getters['user/getProfilePicture'])
const cropper = ref('cropper')
const fileInput = ref(null)
const fileToBeUpload = ref(null)
const FILE_SIZE = 5120 * 1024
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"]
const STEP_INIT = {
    index: 'index',
    crop: 'crop',
    preview: 'preview' 
}
const step = ref(STEP_INIT.index)
const isLoading = ref(false)

const selectFile = () => document.getElementById('file-input').click()
const fileInputChange = (e) => {
    if(e.target.files.length < 1) return
    if(!SUPPORTED_FORMATS.includes(e.target.files[0].type)) return
    if(e.target.files[0].size > FILE_SIZE) return

    isLoading.value = true
    const reader = new FileReader()
    reader.onload = (e) => {
        fileInput.value = e.target.result
        fileToBeUpload.value = null
        step.value = STEP_INIT.crop
        isLoading.value = false
    }
    
    reader.readAsDataURL(e.target.files[0])
    e.target.value = ''
}

const cropBtn = () => {
    fileToBeUpload.value = cropper.value.getCroppedCanvas()
    fileInput.value = null
    step.value = STEP_INIT.preview
}

const getFileToBeUpload = () => {
    return fileToBeUpload.value.toDataURL()
}

const uploadFile = () => {
    isLoading.value = true
    fileToBeUpload.value
    .toBlob((blob) => {
        let data = new FormData()
        data.append('image', blob, 'profile-picture.png')
        axios.post('/customer/profile/picture', data,{
            headers: {
                'Accept': 'multipart/form-data',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(() => {
            store.dispatch('user/setProfilePicture')
            step.value = STEP_INIT.index
            fileInput.value = null
            fileToBeUpload.value = null
            isLoading.value = false
        })
        .catch(() => {
            step.value = STEP_INIT.index
            fileInput.value = null
            fileToBeUpload.value = null
            isLoading.value = false
        })
    })
}

</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="router.go(-1)" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full min-h-screen bg-amalan-black flex flex-col justify-center items-center">
            <template v-if="step === 'index'">
                <div class="w-80 h-80 bg-amalan-white rounded-lg overflow-hidden">
                    <img :src="profilePicture ? profilePicture : require('@/assets/img/logo.png')" :class="[ profilePicture ? 'w-full h-full' : 'w-full h-full px-5 py-5']">
                </div>
                <div @click="selectFile" class="flex justify-center items-center w-24 h-7 bg-amalan-blue-1 hover:bg-amalan-blue-2 border border-amalan-white rounded-amalan-lg mt-8 cursor-pointer">
                    <img src="@/assets/img/camera-icon.svg" class="h-3">
                    <span class="text-xs font-bold text-amalan-white ml-2">Ubah</span>
                </div>
            </template>
            <template v-if="step === 'crop'">
                <div v-if="fileInput" class="w-full px-4 flex flex-col items-center">
                    <VueCropper 
                        ref="cropper"
                        :src="fileInput"
                        :aspect-ratio="1"
                        :containerStyle="{'max-width': '400px', 'max-height': '400px', 'overflow': 'hidden'}"
                        :imgStyle="{'object-fit': 'cover'}"
                    />
                    <div @click="cropBtn" class="flex justify-center items-center w-24 h-7 bg-amalan-blue-1 hover:bg-amalan-blue-2 border border-amalan-white rounded-amalan-lg mt-8 cursor-pointer">
                        <span class="text-xs font-bold text-amalan-white">Potong</span>
                    </div>
                </div>
            </template>
            <template v-if="step === 'preview' && fileToBeUpload">
                <div class="w-80 bg-amalan-white rounded-lg">
                    <div class="w-full py-3 rounded-t-lg bg-amalan-blue-7">
                        <p class="text-center font-bold text-base text-amalan-black">Pratinjau</p>
                    </div>
                    <div class="w-full py-4 flex justify-center items-center">
                        <div class="w-52 h-52 rounded overflow-hidden">
                            <img id="file-preview" :src="getFileToBeUpload()" class="w-52 h-52 object-cover">
                        </div>
                    </div>
                    <div class="w-full pb-4 px-4 rounded-b-lg grid grid-cols-2 gap-x-4 justify-items-center">
                        <div @click="selectFile" class="w-full h-11 flex justify-center items-center rounded-amalan-lg border border-amalan-blue-1 bg-amalan-white hover:bg-amalan-gray-5 cursor-pointer">
                            <p class="text-amalan-blue-1 text-sm font-bold">Pilih Kembali</p>
                        </div>
                        <div @click="uploadFile" class="w-full h-11 flex justify-center items-center rounded-amalan-lg border border-amalan-blue-1 bg-amalan-blue-1 hover:bg-amalan-blue-2 cursor-pointer">
                            <p class="text-amalan-white text-sm font-bold">Unggah</p>
                        </div>
                    </div>
                </div>
            </template>
            <input id="file-input" type="file" class="hidden" @change="fileInputChange">
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>