<template>
    <Transition name="fade-fast">
        <div class="w-full max-w-lg absolute shadow-xl z-40" v-show="isShow">
            <nav class="bg-blue-100 w-full max-w-lg absolute z-50">
                <div class="px-4 py-4 mx-auto">
                    <div class="flex items-center justify-between">
                        <button @click="close" class="flex items-center mt-0">
                            <ChevronLeftIcon class="h-5 w-5"/>
                        </button>
                        <span class="text-sm font-bold mt-0">{{ $route.meta.title }}</span>
                        <div class="flex items-center mt-0">
                            <div class="w-5 h-5 bg-transparent"></div>
                        </div>
                    </div>
                </div>
            </nav>
            <div class="bg-black bg-opacity-60 backdrop-filter backdrop-blur-sm max-w-lg w-full h-screen px-6 fixed flex items-center">
                <div class="gradient-background rounded-xl shadow-amalan-lg relative w-full">
                    <div class="w-7 h-7 rounded-full shadow-amalan-lg bg-white absolute z-50 -top-3 -right-2 flex justify-center items-center cursor-pointer hover:bg-gray-100" @click="close">
                        <XIcon class="w-4 text-blue-900" />
                    </div>
                    <div class="w-full max-w-lg rounded-xl relative overflow-hidden pl-1 pr-8 cursor-pointer" @click="redirectUrl">
                        <div class="w-32 h-32 opacity-70 rounded-full bg-amalan-yellow-400 bg-opacity-50 absolute -bottom-16 -right-16"></div>
                        <div class="w-full grid grid-cols-3 pt-2">
                            <img src="@/assets/img/promotion/credit-score-banner.png" class="w-full">
                            <div class="col-span-2 flex flex-col justify-between pb-4 pt-2">
                                <div class="w-full text-xs text-amalan-blue-1">
                                    <div class="flex justify-start mt-1">
                                        <div>Apakah Anda ingin mengetahui</div>
                                    </div>
                                    <div class="flex justify-start -mt-1">
                                        <div class="font-bold uppercase text-lg">CREDIT SCORE</div>
                                        <img src="@/assets/img/promotion/paper-plane-1.svg" class="ml-1">
                                    </div>
                                    <div class="flex justify-start -mt-1">
                                        <div>yang Anda miliki?</div>
                                    </div>
                                </div>
                                <div class="w-full text-2xs text-amalan-blue-1">
                                    <p>Klik <span class="italic font-bold">banner</span> untuk mengetahui <br><span class="italic">Credit Score</span> Anda!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import { XIcon } from '@heroicons/vue/solid'
import { mapGetters } from 'vuex'
import { ChevronLeftIcon } from '@heroicons/vue/solid'

export default {
    components: {
        XIcon,
        ChevronLeftIcon
    },
    computed: {
        ...mapGetters('globalState',{
            isShow:'getCreditScoreBanner'
        }),
        ...mapGetters('leadQualification',{
            contactData: 'getContact'
        })
    },
    mounted(){
        if(!this.contactData.Status_of_Lead__c || this.contactData.Status_of_Lead__c !== 'Qualified'){
            setTimeout(() => {
                this.$store.dispatch('globalState/changeCreditScoreBanner')
            }, 500);
        }
    },
    methods: {
        close() {
            this.$store.dispatch('globalState/changeCreditScoreBanner')
        },
        redirectUrl(){
            this.$store.dispatch('globalState/changeCreditScoreBanner')
            localStorage.setItem('CreditScoreFromProduct', 'lead_qualification')
            this.$router.push({ name: 'credit checkup - credit score checkout' })
        }
    }
}
</script>

<style scoped>
.gradient-background{
    background: rgb(251,232,85);
    background: linear-gradient(141deg, rgba(251,232,85,1) 0%, rgba(252,252,252,1) 40%, rgba(252,252,252,1) 85%);
}
.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.5s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.5s ease
}
</style>