<template>
    <div class="flex justify-center">
        <div class="w-full max-w-lg shadow-xl min-h-screen h-auto bg-amalan-white">
            <Body />
        </div>
    </div>
</template>

<script>
import Body from '../Body';

export default {
    components: {
        Body
    },
};
</script>