const validateData = (key, ExpiryDateTime) => {
    const payload = localStorage.getItem(key)
    if(!payload) return null

    const data = JSON.parse(payload)
    if(ExpiryDateTime > data.expiry){
        localStorage.removeItem(key)
        return null
    }

    return data.value
}

const setData = (key, data) => {
    return localStorage.setItem(key, JSON.stringify(data))
}

export default {
    validateData,
    setData
}