<script setup>
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import axios from "@/axios"
import { onMounted, ref, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import fbPixelEventApi from '@/composables/fbPixelEventApi'
import { eventName, mixpanelPushEvent } from '@/composables/mixpanel'

const router = useRouter()
const route = useRoute()
const store = useStore()
const filters = inject("Filters")

const isLoading = ref(false)
const dataService = ref(null)
const isPaymentPage = ref(false)
const paymentUrl = ref(null)
const paymentExpiration = ref(null)
const freePrice = ref(false)
const catalog = ref('')

const onCheckPayment = () => {
    isPaymentPage.value = false
    isLoading.value = true
    axios.get('services/payment/check?serviceId=' + dataService.value.id)
    .then(resp => {
        isLoading.value = false
        if(!resp.data.data.isPaid) return
        store.dispatch('services/requestServices')
        fbPixelEventApi.sentPurchase(dataService.value.id)
        return router.push({ name: 'Credit Checkup - Show', params: { id: route.params.id}, query: { catalog_name: catalog.value } })
    })
    .catch(() => isLoading.value = false)
}

const nextButton = () => {
    if(!freePrice.value) return isPaymentPage.value = true
    store.dispatch('services/requestServices')
    fbPixelEventApi.sentPurchase(dataService.value.id)
    return router.push({ name: 'Credit Checkup - Show', params: { id: route.params.id}, query: { catalog_name: catalog.value } })
}

const getPaymentData = () => {
    isLoading.value = true
    axios.get('/customer/services/' + route.params.id)
    .then(resp => {
        isLoading.value = false
        dataService.value = resp.data.data
        fbPixelEventApi.sentCheckout(dataService.value.id)
        const additionalData = JSON.parse(resp.data.data.additional_data)
        catalog.value = dataService.value.service_catalog.name ?? ''
        
        if(dataService.value.price > 0){
            paymentUrl.value = additionalData.cc_xendit[0].invoice_url
            paymentExpiration.value = additionalData.cc_xendit[0].expired_at
            return freePrice.value = false
        }
        
        paymentUrl.value = null
        paymentExpiration.value = null
        return freePrice.value = true
    })
    .catch(() => {
        isLoading.value = false
        return router.push({ name : 'Catalog List' })
    })
}

onMounted(() => {
    getPaymentData()

    if(localStorage.getItem('ccu-product-destination')){
        mixpanelPushEvent(eventName.CC_PAYMENT, {
            applied_program: localStorage.getItem('ccu-product-destination')
        })
    }
})
</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="router.push({ name: 'Aktivitas' })" class="flex items-center mt-0">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold mt-0 text-amalan-black">{{ catalog }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="min-h-screen bg-amalan-white px-5">
            <div class="w-full py-8 flex justify-between items-center">
                <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                    <span class="text-sm text-amalan-blue-1 font-bold">1</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3 mx-1"></div>
                <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400">
                    <span class="text-sm font-bold text-amalan-blue-1">2</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-grow mx-1"></div>
                <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                    <span class="text-sm text-amalan-blue-1 font-bold">3</span>
                </div>
                <p class="flex-none text-amalan-blue-1 text-sm font-bold ml-1">Pembayaran Layanan</p>
            </div>
            <template v-if="dataService">
                <div class="w-full rounded-lg border p-4 border-amalan-gray-4 bg-amalan-white">
                    <div class="w-full pb-3.5 border-b border-amalan-blue-1">
                        <p class="text-amalan-blue-1 text-base font-bold text-center">Ringkasan Pembayaran</p>
                    </div>
                    <div class="w-full bg-amalan-yellow-100 rounded-lg py-2 mt-3.5 text-amalan-black">
                        <p v-if="dataService.promo_id" class="font-bold text-xs text-center uppercase">Diskon {{ dataService.promo.discount_amount }}% DARI {{ dataService.promo.description }}</p>
                        <p v-else class="font-bold text-xs text-center uppercase">-</p>
                    </div>
                    <div class="w-full mt-3.5 grid grid-cols-5 text-amalan-black text-xs gap-y-1.5">
                        <div class="col-span-2">Layanan</div>
                        <div class="col-span-3">: {{ dataService.service_catalog.name || '-' }}</div>
                        <div class="col-span-2">Harga</div>
                        <div class="col-span-3">: {{ filters.format_number(dataService.service_catalog.base_price || 0) }}</div>
                        <div class="col-span-2">Potongan</div>
                        <div class="col-span-3">: {{ dataService.promo_id ? filters.format_number((dataService.service_catalog.base_price || 0) - (dataService.price || 0)) + ' (potongan ' + dataService.promo.discount_amount + '%)' : 'Rp 0' }}</div>
                        <div class="col-span-5 my-2.5 border-t border-dashed border-amalan-gray-3"></div>
                        <div class="col-span-2">Total Biaya</div>
                        <div class="col-span-3">: {{ filters.format_number(dataService.price || 0) }}</div>
                    </div>
                    <button @click="nextButton" type="button" class="w-full mt-8 rounded-amalan-lg font-bold border text-center text-sm p-3 bg-amalan-blue-1 hover:bg-amalan-blue-2 text-amalan-white cursor-pointer shadow-amalan-md">{{ freePrice ? 'Lanjutkan' : 'Pilih Metode Pembayaran' }}</button>
                </div>
                <div class="w-full bg-amalan-red-light rounded-lg py-1.5 mt-3.5 text-amalan-red">
                    <p class="font-bold text-xs text-center">BATAS PEMBAYARAN<br>{{ filters.formatDateTimePaymentExpiration((paymentExpiration || filters.dateTimeNow())) }} WIB | <span class="text-amalan-black">({{ filters.diffDateOnHours((paymentExpiration || filters.dateTimeNow()), filters.dateTimeNow()) || '1' }} JAM)</span></p>
                </div>
            </template>
            <loading-data-without-margin v-else class="pt-40" />
            <div v-if="isPaymentPage" class="fixed inset-0 z-50 bg-amalan-blue-7 overflow-y-visible">
                <iframe class="w-screen h-5/6 border-b-4 bg-amalan-white" :src="paymentUrl" frameborder="0" allow="autoplay fullscreen picture-in-picture"></iframe>
                <div class="fixed bottom-8 inset-x-0 px-4">
                    <button class="border text-amalan-white w-full px-4 bg-amalan-blue-1 border-amalan-blue-1 hover:bg-amalan-blue-2 hover:border-amalan-blue-2 rounded-amalan-lg py-3 text-sm font-bold shadow-amalan-md" @click="onCheckPayment">
                        Konfirmasi Pembayaran
                    </button>
                </div>
            </div>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>
