
<script setup>
import { onMounted, ref, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { CheckIcon } from '@heroicons/vue/solid'
import CurrencyInput from "@/components/CurrencyInputV2.vue"
import Multiselect from '@vueform/multiselect'
import { multiselectClass, multiselectClassDisabled, multiselectSearchClass } from '@/constants/Multiselect'

const store = useStore()
const router = useRouter()
const route = useRoute()

const lastLoanConsolidation = computed(() => store.getters['loanConsolidation/getLastLoanConsolidation'])
const getInformasiUmumForm = computed(() => store.getters['loanConsolidation/getInformasiUmumForm'])
const getDataDiriForm = computed(() => store.getters['loanConsolidation/getDataDiriForm'])
const getDataPekerjaanForm = computed(() => store.getters['loanConsolidation/getDataPekerjaanForm'])
const NumOfEmployeeConfigData = computed(() => store.getters['loanConsolidation/getNumOfEmployeeConfigData'])
const EmployerTypeConfigData = computed(() => {
    const data = store.getters['loanConsolidation/getEmployerTypeConfigData']
    const result = data.map((v) => { return { value: v.value, label: v.name } })
    return result
})
const EmployerConfigData = computed(() => {
    const data = store.getters['loanConsolidation/getEmployerConfigData']
    const result = data.map((v) => {
        return {
            value: v.Id,
            label: v.Name,
            employerType: v.Employer_Type__c,
            newOption: false
        }
    })

    return result
})

const CustomEmployerData = ref([])
const MergeEmployerData = computed(() => [...CustomEmployerData.value, ...EmployerConfigData.value])

const DataPekerjaanForm = reactive({
    employer: null,
    employer_name: null,
    employer_type: null,
    num_of_employee: null,
    employment_contract_type: null,
    income: null
})
const multiselectEmployerComponent = ref(null)
const employerSearchedText = ref('')
const disabledEmployerTypeMultiselect = ref(true)

const DataPekerjaanFormSchema = yup.object().shape({
    employer: yup.string().nullable().required('Silahkan pilih salah satu').max(90).matches(/^[@&\-'",/()._ a-zA-Z0-9]+$/, "Mengandung karakter yang tidak diperbolehkan"),
    employer_name: yup.string().nullable().max(80, 'Maksimal 80 karakter').matches(/^[@&\-'",/(). a-zA-Z0-9]+$/, "Mengandung karakter yang tidak diperbolehkan"),
    employer_type: yup.string().nullable().required('Silahkan pilih salah satu'),
    num_of_employee: yup.string().nullable().required('Silahkan pilih salah satu'),
    employment_contract_type: yup.string().nullable().required('Silahkan dipilih').oneOf(['yes', 'no'], 'Silahkan dipilih'),
    income: yup.number('Masukan dalam bentuk angka').nullable().required('Mohon diisi').max(999999999999999)
})

const asyncMergeEmployerData = async () => MergeEmployerData.value

const submitHandler = () => {
    store.dispatch('loanConsolidation/setDataPekerjaanForm', {...DataPekerjaanForm})
    return router.push({name:'Loan Consolidation - Registrasi - Data Aset'})
}

const onEmployerSearch = (query) => employerSearchedText.value = query ?? ''

const freeTextEmployerHandler = () => {
    if(employerSearchedText.value.match(/^[@&\-'",/(). a-zA-Z0-9]+$/)){
        const newData = { value: 'Others_' + employerSearchedText.value, label: employerSearchedText.value, newOption: true }
        CustomEmployerData.value.push(newData)
        multiselectEmployerComponent.value.refreshOptions(() => multiselectEmployerComponent.value.select(newData))
    }

    DataPekerjaanForm.employer = 'Others_' + employerSearchedText.value
    DataPekerjaanForm.employer_name = employerSearchedText.value
    DataPekerjaanForm.employer_type = null
    disabledEmployerTypeMultiselect.value = false
    multiselectEmployerComponent.value.close()
}

const onChangeEmployerHandler = (value) => {
    if(value) return
    DataPekerjaanForm.employer_type = null
    return disabledEmployerTypeMultiselect.value = true
}

const onSelectEmployerHandler = (_value, option) => {
    DataPekerjaanForm.employer_name = option.label
    if(option.newOption) {
        DataPekerjaanForm.employer_type = getDataPekerjaanForm.value.employer_type ?? null
        return disabledEmployerTypeMultiselect.value = false
    }
    DataPekerjaanForm.employer_type = option.employerType
    return disabledEmployerTypeMultiselect.value = true
}

const prepopulateFormData = () => {    
    if(getDataPekerjaanForm.value.employer && getDataPekerjaanForm.value.employer.includes('Others')){
        const newData = { value: 'Others_' + getDataPekerjaanForm.value.employer_name, label: getDataPekerjaanForm.value.employer_name, newOption: true }
        CustomEmployerData.value.push(newData)
        multiselectEmployerComponent.value.refreshOptions(() => multiselectEmployerComponent.value.select(newData))
    }

    DataPekerjaanForm.employer = getDataPekerjaanForm.value.employer ? getDataPekerjaanForm.value.employer.includes('Others') ? 'Others_' + getDataPekerjaanForm.value.employer_name : getDataPekerjaanForm.value.employer : null
    DataPekerjaanForm.employer_name = getDataPekerjaanForm.value.employer_name ?? null
    DataPekerjaanForm.employer_type = getDataPekerjaanForm.value.employer_type ?? null
    DataPekerjaanForm.num_of_employee = getDataPekerjaanForm.value.num_of_employee ?? null
    DataPekerjaanForm.income = getDataPekerjaanForm.value.income ?? null
    DataPekerjaanForm.employment_contract_type = getDataPekerjaanForm.value.employment_contract_type ?? null
}

onMounted(() => {
    prepopulateFormData()

    if(lastLoanConsolidation.value.status === 404){
        if(!getInformasiUmumForm.value.isValid) return router.push({ name: 'Loan Consolidation - Registrasi - Informasi Umum'})
        if(!getDataDiriForm.value.isValid) return router.push({ name: 'Loan Consolidation - Registrasi - Data Diri'})
        return
    }

    if(lastLoanConsolidation.value.status === 201 || lastLoanConsolidation.value.status === 202)
        return router.push({ name: 'Loan Consolidation - Registrasi - Daftar Pinjaman'})
    if(lastLoanConsolidation.value.status === 200) {
        const consolidationInitialResult = lastLoanConsolidation.value.data.data.Loan_Consolidation_Initial_Results__c ? lastLoanConsolidation.value.data.data.Loan_Consolidation_Initial_Results__c.toUpperCase() : null
        const isNotEligible = consolidationInitialResult ? consolidationInitialResult.includes('NOT ELIGIBLE') : null

        if(isNotEligible) return router.push({ name: 'Loan Consolidation - Registrasi - Qualification'})
        return router.push({ name: 'Loan Consolidation - Registrasi - Finish Page'})
    }

    return router.push({ name: 'Home'})
})
</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="router.push({name: 'Loan Consolidation - Registrasi - Data Diri'})" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold text-amalan-black text-center">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full min-h-screen bg-amalan-white px-6 pb-24">
            <div class="w-full py-8 flex justify-between items-center">
                <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                    <span class="text-sm text-amalan-blue-1 font-bold">1</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3 mx-1"></div>
                <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400">
                    <span class="text-sm font-bold text-amalan-blue-1">2</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3 mx-1"></div>
                <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400">
                    <span class="text-sm font-bold text-amalan-blue-1">3</span>
                </div>
                <p class="flex-none text-amalan-blue-1 text-sm font-bold ml-1">Data Pekerjaan</p>
                <div class="bg-amalan-blue-1 h-px flex-grow mx-1"></div>
                <div class="w-6 h-6 rounded-full bg-amalan-gray-4 flex justify-center items-center flex-none ml-1">
                    <span class="text-sm text-amalan-gray-2 font-bold">4</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3"></div>
                <div class="w-6 h-6 rounded-full bg-amalan-gray-4 flex justify-center items-center flex-none ml-1">
                    <span class="text-sm text-amalan-gray-2 font-bold">5</span>
                </div>
            </div>
            <p class="text-xs text-amalan-black text-center">Lengkapi pertanyaan berikut<br>sesuai dengan informasi yang Anda miliki</p>
            <Form
            :validation-schema="DataPekerjaanFormSchema"
            v-slot="{ meta, errors }"
            @submit="submitHandler"
            >
                <div class="w-full grid grid-cols-1 gap-y-3.5 py-8 text-amalan-black">
                    <div class="w-full">
                        <label class="text-sm text-amalan-blue-1 font-bold">Dimana Anda Bekerja?</label>
                        <div class="flex items-center relative w-full mt-1.5">
                            <div class="w-full border rounded-lg py-1" :class="[ errors.employer_name ? 'border-amalan-red' : 'border-amalan-gray-4']">
                                <Multiselect
                                    ref="multiselectEmployerComponent"
                                    v-model="DataPekerjaanForm.employer"
                                    :noResultsText="'Data tidak ditemukan.'"
                                    :noOptionsText="'Ketik untuk menampilkan hasil'"
                                    :searchable="true"
                                    :options="asyncMergeEmployerData"
                                    :classes="multiselectSearchClass"
                                    :infinite="true"
                                    :limit="150"
                                    :resolveOnLoad="getDataPekerjaanForm.employer ? true : false"
                                    :minChars="1"
                                    :delay="0"
                                    placeholder="Ketik Di Sini"
                                    @search-change="onEmployerSearch"
                                    @change="onChangeEmployerHandler"
                                    @select="onSelectEmployerHandler"
                                >
                                    <template v-slot:option="{ option }">{{ option.label }}</template>
                                    <template v-slot:noresults>
                                        <div class="w-full py-2.5 px-4 text-xs text-amalan-gray-3">Perusahaan tidak ditemukan</div>
                                        <button @click="freeTextEmployerHandler" class="block w-full py-2.5 px-4 text-center bg-amalan-gray-5 text-xs font-bold text-amalan-blue-1">+ Tambahkan {{ employerSearchedText }}</button>
                                    </template>
                                </Multiselect>
                            </div>
                            <Field name="employer" type="text" class="hidden" :validateOnInput="true" v-model="DataPekerjaanForm.employer" />
                            <Field name="employer_name" type="text" class="hidden" :validateOnInput="true" v-model="DataPekerjaanForm.employer_name" />
                        </div>
                        <div v-if="errors.employer_name" class="mt-0.5 text-amalan-red text-2xs">{{errors.employer_name}}</div>
                    </div>
                    <div class="w-full">
                        <label class="text-sm text-amalan-blue-1 font-bold">Apa jenis perusahaan tempat Anda bekerja?</label>
                        <div class="flex items-center relative w-full mt-1.5">
                            <div class="w-full border rounded-lg py-1" :class="[ errors.employer_type && !disabledEmployerTypeMultiselect ? 'border-amalan-red' : 'border-amalan-gray-4', disabledEmployerTypeMultiselect ? 'bg-amalan-gray-5' : 'bg-amalan-white']">
                                <Multiselect
                                    ref="multiselect"
                                    v-model="DataPekerjaanForm.employer_type"
                                    :noResultsText="'Data tidak ditemukan.'"
                                    :noOptionsText="'Sedang memuat data...'"
                                    :searchable="true"
                                    :options="EmployerTypeConfigData"
                                    :classes="disabledEmployerTypeMultiselect ? multiselectClassDisabled : multiselectClass"
                                    placeholder="Pilih Salah Satu"
                                    :disabled="disabledEmployerTypeMultiselect"
                                />
                            </div>
                            <Field name="employer_type" type="text" class="hidden" :validateOnInput="true" v-model="DataPekerjaanForm.employer_type" />
                        </div>
                        <div v-if="errors.employer_type && !disabledEmployerTypeMultiselect" class="mt-0.5 text-amalan-red text-2xs">{{errors.employer_type}}</div>
                    </div>
                    <div class="w-full">
                        <label class="text-sm text-amalan-blue-1 font-bold">Berapa jumlah karyawan di perusahaan tempat Anda bekerja?</label>
                        <div class="flex items-center relative w-full mt-1.5">
                            <div class="w-full border rounded-lg py-1" :class="[ errors.num_of_employee ? 'border-amalan-red' : 'border-amalan-gray-4']">
                                <Multiselect
                                    ref="multiselect"
                                    v-model="DataPekerjaanForm.num_of_employee"
                                    :noResultsText="'Data tidak ditemukan.'"
                                    :noOptionsText="'Sedang memuat data...'"
                                    :searchable="true"
                                    :options="NumOfEmployeeConfigData"
                                    :classes="multiselectClass"
                                    placeholder="Pilih Salah Satu"
                                />
                            </div>
                            <Field name="num_of_employee" type="text" class="hidden" :validateOnInput="true" v-model="DataPekerjaanForm.num_of_employee" />
                        </div>
                        <div v-if="errors.num_of_employee" class="mt-0.5 text-amalan-red text-2xs">{{errors.num_of_employee}}</div>
                    </div>
                    <div class="w-full">
                        <label class="text-sm text-amalan-blue-1 font-bold">Berapa gaji Anda per bulan?</label>
                        <div class="flex flex-col relative w-full mt-1.5">
                            <CurrencyInput 
                            v-model="DataPekerjaanForm.income" 
                            :placeholder="'Rp'"
                            :propsClass="errors.income ? 'border-amalan-red rounded-lg' : 'border-amalan-gray-4 rounded-lg'"
                            :options="{
                                currency: 'IDR',
                                currencyDisplay: 'narrowSymbol',
                                hideCurrencySymbolOnFocus: false,
                                hideGroupingSeparatorOnFocus: false,
                                hideNegligibleDecimalDigitsOnFocus: false,
                                autoDecimalDigits: false,
                                autoSign: false,
                                useGrouping: true,
                                accountingSign: true
                            }" />
                            <Field name="income" type="text" :validateOnInput="true" class="hidden" v-model="DataPekerjaanForm.income" />
                            <div v-if="errors.income" class="mt-0.5 text-amalan-red text-2xs">{{errors.income}}</div>
                        </div>
                    </div>
                    <div class="w-full">
                        <p class="text-sm text-amalan-blue-1 font-bold">Apakah Anda merupakan pekerja tetap?</p>
                        <div class="w-full py-2 px-4 grid grid-cols-2 gap-x-3.5 border border-solid border-amalan-gray-4 rounded-lg mt-1.5">
                            <div @click="DataPekerjaanForm.employment_contract_type = 'yes'" class="w-full py-2 flex justify-start items-center gap-2 cursor-pointer">
                                <div class="w-4 h-4 p-0 rounded-full flex justify-center items-center" :class="[ DataPekerjaanForm.employment_contract_type == 'yes' ? 'bg-amalan-yellow-400' : 'bg-amalan-gray-5']">
                                    <CheckIcon class="w-3 h-3" :class="[ DataPekerjaanForm.employment_contract_type == 'yes' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]" />
                                </div>
                                <p class="text-amalan-black text-xs">Ya</p>
                                <Field name="employment_contract_type" type="radio" class="hidden" value="yes" :validateOnInput="true" v-model="DataPekerjaanForm.employment_contract_type" />
                            </div>
                            <div @click="DataPekerjaanForm.employment_contract_type = 'no'" class="w-full py-2 flex justify-start items-center gap-2 cursor-pointer">
                                <div class="w-4 h-4 p-0 rounded-full flex justify-center items-center" :class="[ DataPekerjaanForm.employment_contract_type == 'no' ? 'bg-amalan-yellow-400' : 'bg-amalan-gray-5']">
                                    <CheckIcon class="w-3 h-3" :class="[ DataPekerjaanForm.employment_contract_type == 'no' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]" />
                                </div>
                                <p class="text-amalan-black text-xs">Tidak</p>
                                <Field name="employment_contract_type" type="radio" class="hidden" value="no" :validateOnInput="true" v-model="DataPekerjaanForm.employment_contract_type" />
                            </div>
                        </div>
                    </div>
                </div>
                <button type="submit" class="w-full mt-8 rounded-amalan-lg font-bold text-center text-sm p-3" :class="[ meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!meta.valid">Selanjutnya</button>
            </Form>
        </div>
    </div>
</template>