<script setup>
import { useRouter, useRoute } from 'vue-router'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { onMounted } from 'vue'

const router = useRouter()
const route = useRoute()

const MenuConfig = [
    {
        routeName: 'Catalog List',
        title: 'Pembelian Layanan',
        text: 'Anda dapat melakukan pembelian layanan Credit Score<br>atau Credit Report, dan/atau Credit Checkup',
        active: true
    },
    {
        routeName: 'Credit Checkup - History',
        title: 'Riwayat Pembelian Layanan',
        text: 'Anda dapat melihat riwayat pembelian layanan<br>Credit Score atau Credit Report, dan/atau Credit Checkup',
        active: true
    },
    {
        routeName: 'Credit Checkup - Faq',
        title: 'Pertanyaan Terkait Layanan',
        text: 'Dapatkan informasi terkait layanan Credit Score<br>atau Credit Report, dan/atau Credit Checkup',
        active: true
    }
]

onMounted(() => {
    const ccuServiceId = localStorage.getItem('credit-checkup-service-id')
    if(!ccuServiceId) return

    localStorage.removeItem('credit-checkup-service-id')
    return router.push({ name : 'Credit Checkup - Show', params: {id: ccuServiceId}})
})
</script>

<template>
    <div class="min-h-screen bg-amalan-white relative">
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="() => { router.push({ name : 'Home' }) }" class="flex items-center mt-0">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold mt-0 text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full pt-8 pb-20 px-6 bg-amalan-white">
            <p class="text-center text-amalan-black text-xs">Anda dapat melakukan pembelian,<br>melihat riwayat pembelian, atau pertanyaan terkait layanan riwayat kredit.</p>
            <div class="w-full mt-8 grid grid-cols-1 gap-4">
                <router-link v-for="(menu, index) in MenuConfig" :key="index" :to="{ name: menu.routeName }" class="w-full rounded-lg text-sm relative" :class="[ !menu.active ? 'cursor-not-allowed bg-amalan-gray-4 text-amalan-gray-2' : 'shadow-amalan-sm bg-amalan-blue-1 hover:bg-amalan-blue-2 text-amalan-white cursor-pointer' ]">
                    <div class="w-full relative px-4 py-6 rounded-lg overflow-hidden">
                        <p class="font-bold" :class="[!menu.active ? 'text-amalan-gray-2' : 'text-amalan-yellow-400']" v-html="menu.title"></p>
                        <p class="mt-1 text-2xs leading-normal" v-html="menu.text"></p>
                        <div class="absolute -bottom-9 -right-9 w-22 h-22 rounded-full bg-transparent border border-amalan-yellow-400 opacity-30 flex justify-center items-center">
                            <div class="w-20 h-20 rounded-full bg-amalan-white bg-opacity-50"></div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>