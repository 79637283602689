import amalansignupApi from "../../axios/amalansignup";
import vm from "../../main";

const PACKAGE_LIST = ['DIAMOND', 'PLATINUM', 'GOLD', 'SILVER', 'BASIC']

const state = () => ({
    contact : null,
    user_pricing_package: null,
    signUpStatus: {
        status: null,
        data: null
    }
});

const getters = {
    getContact(state) {
        return state.contact;
    },
    getSignUpStatus(state) {
        return state.signUpStatus.status;
    },
    getSignUp(state) {
        return state.signUpStatus;
    },
    getUserPricingPackage(state) {
        return state.user_pricing_package;
    }
};

const actions = {
    requestContact({ commit }) {
        return amalansignupApi.get('salesforce/contact')
        .then( resp => {
            if(resp.status == 200){
                commit("requestContact", resp.data);
            }
        })
        .catch(() => {
            const swalErrorReload = vm.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })

            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        })
    },
    requestSignUpStatus({ commit }) {
        return amalansignupApi.get('salesforce/contact/last-signup')
        .then( resp => {
            commit("requestSignUpStatus", resp);
        })
        .catch(err => {
            if(err.response.status == 404){
                commit("requestSignUpStatus", err.response);
            }else{
                const swalErrorReload = vm.$swal.mixin({
                    customClass: {
                        confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                    },
                    buttonsStyling: false,
                    title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                    showCancelButton: false,
                    confirmButtonText: 'Perbaharui',
                    width: '340px',
                    allowOutsideClick: false,
                })
    
                swalErrorReload.fire().then((result) => {
                    if (result.isConfirmed){
                        window.location.reload();
                    }
                });
            }
        })
    },
    setContactToActive({ state }) {
        if(state.contact && state.contact.ACTIVE_INACTIVE__c !== 'ACTIVE'){
            const payload = {
                object: 'Contact',
                id: state.contact.Id,
                fields: {
                    ACTIVE_INACTIVE__c : 'ACTIVE'
                }
            }
    
            amalansignupApi.post('salesforce/general/update-data-by-object', payload)
        }
    }
};

const mutations = {
    requestContact(state, payload) {
        state.contact = payload.data;
        state.user_pricing_package = setPricingPackage(payload.data);
    },
    requestSignUpStatus(state, payload) {
        state.signUpStatus.status = payload.status;
        if(payload.status !== 404){
            state.signUpStatus.data = payload.data.data;
        }
    }
};

const setPricingPackage = (userSf) => {
    const pricingPackage = userSf.Pricing_Package__c ? userSf.Pricing_Package__c.toUpperCase() : null
    if(!pricingPackage) return PACKAGE_LIST[PACKAGE_LIST.length - 1]

    let cardType = null
    PACKAGE_LIST.every((packageList) => {
        if(!pricingPackage.includes(packageList)) return true
        cardType = packageList
        return false
    })

    if(!cardType) cardType = PACKAGE_LIST[PACKAGE_LIST.length - 1]
    return cardType
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
