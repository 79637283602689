import apiMediationService from "@/axios/mediationService"

const state = () => ({
    config: [
        {
            status: 'Approved',
            class: 'text-amalan-green-medium',
            stamp: 'disetujui.png',
            text: 'disetujui'
        },
        {
            status: 'Rejected',
            class: 'text-amalan-red',
            stamp: 'ditolak.png',
            text: 'ditolak'
        },
        {
            status: 'Overdue',
            class: 'text-amalan-orange',
            stamp: 'kedaluwarsa.png',
            text: 'kedaluwarsa'
        },
    ],
    negotiation_result: {
        approval: {
            isFetching: true,
            isFetched: false,
            data: []
        },
        history: {
            isFetching: true,
            isFetched: false,
            data: []
        }
    }
})

const getters = {
    getConfig(state) {
        return state.config
    },
    getNegotiationResultHistory(state) {
        return state.negotiation_result.history
    },
    getNegotiationResultApproval(state) {
        return state.negotiation_result.approval
    },
    getNegotiationResultApprovalFlag(state){
        if(!state.negotiation_result.approval.data) return false
        const approvalData = [...state.negotiation_result.approval.data]
        return approvalData.length > 0 ? true : false
    }
}

const actions = {
    async setNegotiationResultApproval({ commit, state, dispatch, rootGetters }){
        state.negotiation_result.approval.isFetching = true
        state.negotiation_result.approval.data = []
        try {
            const result = await apiMediationService.get('api/customer-approval')
            commit('setNegotiationResultApproval', result.data.data)
            dispatch('pendingUserActivity/setPendingActivityData', {
                userStage: rootGetters['user/getUserStage'],
                signUpStatus: rootGetters['contact/getSignUpStatus'],
                lastAmalanProtect: rootGetters['formAmalanProtect/getLastProtect'],
                latestService: rootGetters['services/getLatestService'],
                timeToDocCollection: rootGetters['docCollection/getTimeToDocCollection'],
                billCompleted: rootGetters['docCollection/getbillStatus'],
                docCompleted: rootGetters['docCollection/getDocStatus'],
                poaDocument: rootGetters['user/getDocumentPoa'],
                lastAdditionalSuf: rootGetters['additionalSUF/getLastAdditionalSuf'],
                lastUpgradePackage: rootGetters['upgradePricingPackage/getLastUpgradePackage'],
                negotiationResultApprovalFlag: rootGetters['negotiationResult/getNegotiationResultApprovalFlag']
            }, {
                root: true 
            })
        } catch {
            commit('setNegotiationResultApproval', [])
        }
    },
    async setNegotiationResultHistory({ commit, state }){
        state.negotiation_result.history.isFetching = true
        state.negotiation_result.history.data = []
        try {
            const result = await apiMediationService.get('api/customer-approval/history')
            commit('setNegotiationResultHistory', result.data.data)
        } catch {
            commit('setNegotiationResultHistory', [])
        }
    }
}

const mutations = {
    setNegotiationResultApproval(state, payload){
        state.negotiation_result.approval.isFetching = false
        state.negotiation_result.approval.isFetched = true
        state.negotiation_result.approval.data = payload
    },
    setNegotiationResultHistory(state, payload){
        state.negotiation_result.history.isFetching = false
        state.negotiation_result.history.isFetched = true
        state.negotiation_result.history.data = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
