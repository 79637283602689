import axios from "../axios"

const setUtmCookies = (payload) => {
    // const queryParams = Object.fromEntries(Object.entries(payload).filter(([key]) => key.includes('utm')))
    const queryParams = payload
    const hostName = window.location.hostname == 'localhost' ? window.location.hostname : "." + window.location.hostname
    const domainName = hostName.replace("www.", "")

    if(Object.keys(queryParams).length){
        const date = new Date()
        date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000))
        let expires = "expires=" + date.toUTCString()

        Object.keys(queryParams).forEach(function(key) {
            document.cookie = `${key}=${queryParams[key]};${expires};domain=${domainName};path=/`
        })

        localStorage.setItem('query_params_cookies', JSON.stringify(queryParams))
    }
}

const checkCookies = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
}

const sendCookies = async () => {
    const cookies = document.cookie.split(';').reduce(
    (cookies, cookie) => {
        const [name, val] = cookie.split('=').map(c => c.trim())
        cookies[name] = val
        return cookies
    }, {})

    axios.post('/customer/utm-salesforce', cookies)
}

const sendQueryParams = async () => {
    const queryParams = localStorage.getItem('query_params_cookies') ? JSON.parse(localStorage.getItem('query_params_cookies')) : null
    
    if(queryParams) axios.post('/customer/utm-amazync', queryParams)
}

const setDefaultUtmParams = (queryParams, utmValue) => {
    const utmData = ['utm_campaign', 'utm_content', 'utm_medium', 'utm_source', 'utm_term']
    const hostName = window.location.hostname == 'localhost' ? window.location.hostname : "." + window.location.hostname
    const domainName = hostName.replace("www.", "")

    const date = new Date()
    date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000))
    const expires = "expires=" + date.toUTCString()

    setTimeout(() => utmData.forEach(v => document.cookie = `${v}=${utmValue};${expires};domain=${domainName};path=/`), 1000)

    localStorage.setItem('query_params_cookies', JSON.stringify({
        ...queryParams,
        utm_campaign: utmValue,
        utm_content: utmValue,
        utm_medium: utmValue,
        utm_source: utmValue,
        utm_term: utmValue
    }))
}

export default {
    setUtmCookies,
    checkCookies,
    sendCookies,
    sendQueryParams,
    setDefaultUtmParams
}