<template>
    <div>
        <nav class="bg-blue-100">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="$router.push({ name : 'lead qualification - daftar pinjaman' })" class="flex items-center mt-0">
                        <ChevronLeftIcon class="h-5 w-5"/>
                    </button>
                    <span class="text-sm font-bold mt-0">{{ $route.meta.title }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="pt-4 min-h-screen h-auto relative">
            <div class="mt-2 pb-12 w-full px-4">
                <div class="flex items-center">
                    <div class="flex items-center text-blue-900 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/signup/data-pinjaman-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Daftar<br>Pinjaman</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-blue-900"></div>
                    <div class="flex items-center text-blue-900 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/lead-qualification/informasi-tambahan-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Informasi<br>Tambahan</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-gray-300"></div>
                    <div class="flex items-center text-gray-500 relative">
                        <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                            <img src="@/assets/img/lead-qualification/kemampuan-menabung.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Estimasi<br>Keringanan</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-gray-300"></div>
                    <div class="flex items-center text-gray-500 relative">
                        <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                            <img src="@/assets/img/verified.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Registrasi<br>Selesai</p></div>
                    </div>
                </div>
            </div>
            <template v-if="dataFinancialHardships.length > 0 && dataContact">
                <Form
                @submit="formUpdateSubmit"
                :validation-schema="formSchema"
                v-slot="{ errors, meta }"
                >
                    <div class="mt-6 pb-48 w-full px-4">
                        <h1 class="text-center mt-4 text-blue-900 font-bold text-sm sm:text-base underline">Data Pengguna</h1>
                        <div class="my-4">
                            <label class="font-bold text-sm">Nama Lengkap</label>
                            <div class="flex items-center relative w-full">
                                <Field type="text" name="name" v-model="formUpdate.name" :validateOnInput="true" :validateOnMount="true" autofocus placeholder="Nama Lengkap" class="w-full bg-amalan-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none px-4 py-2 text-sm" :class="[ errors.name ? 'border-red-600' : 'border-gray-200' ]"></Field>
                            </div>
                            <div v-if="errors.name" class="mt-1 text-sm text-red-600">*{{ errors.name }}</div>
                        </div>
                        <div class="my-4">
                            <label class="font-bold text-sm">Email</label>
                            <div class="flex items-center relative w-full">
                                <Field type="text" name="email" v-model="formUpdate.email" :validateOnInput="true" :validateOnMount="true" placeholder="Ketik email Anda disini" class="w-full bg-amalan-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none px-4 py-2 text-sm" :class="[ errors.email ? 'border-red-600' : 'border-gray-200' ]"></Field>
                            </div>
                            <div v-if="errors.email" class="mt-1 text-sm text-red-600">*{{ errors.email }}</div>
                        </div>
                        <h1 class="text-center mt-8 mb-4 text-blue-900 font-bold text-sm sm:text-base underline">Alasan Kesulitan Membayar Pinjaman</h1>
                        <div class="w-full rounded-lg px-4 py-1 border border-solid bg-amalan-white">
                            <template v-for="(fh, key) in dataFinancialHardships" :key="key">
                                <label :for="fh.name" class="font-semibold text-xs flex items-center my-3">
                                    <label class="h-4 w-4 rounded-full mr-2 cursor-pointer flex justify-center items-center" :for="fh.name" :class="formUpdate.financialHardship && formUpdate.financialHardship.includes(fh.value) ? 'bg-amalan-yellow-400' : 'bg-gray-200'">
                                        <CheckIcon class="w-3 h-3" :class="formUpdate.financialHardship && formUpdate.financialHardship.includes(fh.value) ? 'text-blue-900' : 'text-gray-500'"/>
                                    </label>
                                    <Field name="financialHardship" :validateOnInput="true" :validateOnMount="true" :value="fh.value" v-model="formUpdate.financialHardship" type="checkbox" class="hidden" :id="fh.name" />
                                    <span class="cursor-pointer text-sm">{{ fh.name }}</span>
                                </label>
                            </template>
                            <label for="optional" class="font-semibold text-xs flex items-start mb-3 -mt-1">
                                <div class="w-20 h-7 flex items-center">
                                    <label class="h-4 w-4 rounded-full mr-2 cursor-pointer flex justify-center items-center" for="optional" :class="formUpdate.financialHardship && formUpdate.financialHardship.includes('optional') ? 'bg-amalan-yellow-400' : 'bg-gray-200'">
                                        <CheckIcon class="w-3 h-3" :class="formUpdate.financialHardship && formUpdate.financialHardship.includes('optional') ? 'text-blue-900' : 'text-gray-500'"/>
                                    </label>
                                    <Field name="financialHardship" :validateOnInput="true" :validateOnMount="true" :value="'optional'" v-model="formUpdate.financialHardship" type="checkbox" class="hidden" :id="'optional'" />
                                    <span class="cursor-pointer text-sm">Lainnya</span>
                                </div>
                                <div class="ml-2 w-full">
                                    <Field type="text" name="optional" v-model="formUpdate.optional" :validateOnInput="true" class="h-7 w-full bg-amalan-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none px-2 text-sm" :class="[ withOptional ? !isOptionalValidated ? 'border-red-600' : 'border-gray-200' : 'border-gray-200' ]"></Field>
                                    <span v-if="withOptional && optionalErrorMessage" class="text-xs sm:text-sm text-red-600">{{ optionalErrorMessage }}</span>
                                </div>
                            </label>
                        </div>
                        <div v-if="errors.financialHardship" class="text-xs sm:text-sm text-red-600">*{{errors.financialHardship}}</div>
                        <!-- <Field as="textarea" name="financialHardshipDetail" class="mt-4 w-full px-4 py-4 text-sm border border-solid rounded-lg focus:border-blue-900 focus:outline-none" rows="10" placeholder="Jelaskan kesulitan yang Anda alami dengan rinci" :validateOnInput="true" :validateOnMount="true" v-model="formUpdate.financialHardshipDetail"></Field>
                        <div v-if="errors.financialHardshipDetail" class="text-xs sm:text-sm text-red-600 -mt-1">*{{errors.financialHardshipDetail}}</div> -->
                    </div>
                    <div class="absolute bottom-0 mb-28 w-full px-4">
                        <router-link to="/lead-qualification/daftar-pinjaman" class="h-10 rounded-lg font-medium border border-solid cursor-pointer text-center text-base py-1 px-1 text-blue-900 border-blue-900 hover:bg-blue-900 hover:text-white float-left"><ChevronLeftIcon class="h-full" /></router-link>
                        <button type="submit" class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-1 float-right" :class="[ withOptional ? isOptionalValidated && meta.valid ? 'cursor-pointer text-white bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700' : 'text-gray-400 bg-gray-200 border-gray-200 cursor-not-allowed' : meta.valid ? 'cursor-pointer text-white bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700' : 'text-gray-400 bg-gray-200 border-gray-200 cursor-not-allowed']" :disabled="withOptional ? isOptionalValidated && meta.valid ? false : true : meta.valid ? false : true"><ChevronRightIcon class="h-full" /></button>
                    </div>
                </Form>
            </template>
            <template v-else>
                <div class="mt-6 pb-40 w-full px-4">
                    <loading-data class="mt-20" />
                </div>
            </template>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>

<script>
import { ChevronRightIcon, ChevronLeftIcon, CheckIcon } from '@heroicons/vue/solid';
import { defineComponent, computed, reactive, inject, ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import * as yup from 'yup';
import { Form, Field } from 'vee-validate';
import router from "@/router";
import axios from "../../axios"
import amalansignupApi from "@/axios/amalansignup"
import { mixpanelPushEvent, eventName } from '@/composables/mixpanel'

export default defineComponent({
    data() {
        return {
        }
    },
    components:{
        ChevronRightIcon,
        ChevronLeftIcon,
        CheckIcon,
        Form,
        Field
    },
    mounted(){
        if(!this.amountLoan){
            this.$router.push({ name: 'lead qualification - daftar pinjaman' })
        }
        this.formUpdate.financialHardship = this.dataFormUpdateContact.financialHardship ?? []
        // this.formUpdate.financialHardshipDetail = this.dataFormUpdateContact.financialHardshipDetail ?? null
        this.formUpdate.optional = this.dataFormUpdateContact.optional ?? null
        this.formUpdate.email = this.userData.email ?? null
        this.formUpdate.name = (this.userData.first_name || '') + ' ' + (this.userData.last_name || '')

        mixpanelPushEvent(eventName.FINANCIAL_HARDSHIP)
    },
    setup(){
        const store = useStore()
        const swal = inject("$swal")
        const dataFinancialHardships = computed(() => store.getters['leadQualification/getFinancialHardship'])
        const dataFormUpdateContact = computed(() => store.getters['leadQualification/getFormUpdateContact'])
        const dataContact = computed(() => store.getters['leadQualification/getContact'])
        const userData = computed(() => store.getters['user/getUser'])
        const amountLoan = computed(() => store.getters['leadQualification/getAmountLoan'])
        const formUpdate = reactive({
            financialHardship: [],
            // financialHardshipDetail: null,
            optional: null,
            email: null,
            name: null
        });
        let isOptionalValidated = ref(false)
        let withOptional = ref(false)
        let optionalErrorMessage = ref(null)
        let isLoading = ref(false)

        const formSchema = yup.object().shape({
            financialHardship: yup.array().min(1, 'Mohon pilih satu atau lebih.').required('Mohon pilih satu atau lebih.'),
            // financialHardshipDetail: yup.string().nullable().required('Tidak boleh kosong').min(100, "Minimal 100 karakter").max(2000, "Karakter melebihi batas yang diperbolehkan"),
            optional: yup.string().nullable(),
            name: yup.string().nullable().required('Nama tidak boleh kosong').max(50, "Maksimal 50 karakter"),
            email: yup.string().nullable().required('Email tidak boleh kosong').email('Email tidak valid').max(255, "Maksimal 255 karakter")
        });

        const swalError = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600',
                cancelButton: 'absolute -top-3 -right-3'
            },
            buttonsStyling: false,
            title: '<img src="/img/alert-error-icon.svg" class="w-12 h-12 mx-auto">',
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        watch(() => formUpdate.financialHardship, function() {
            if(formUpdate.financialHardship.includes('optional')){
                withOptional.value = true
                validateOptional()
            }else{
                withOptional.value = false
                validateOptional()
            }
        });

        watch(() => formUpdate.optional, function() {
            validateOptional()
        });

        function validateOptional(){
            if(withOptional.value === true){
                if(!formUpdate.optional || formUpdate.optional == '' || formUpdate.optional.length < 10){
                    isOptionalValidated.value = false
                    optionalErrorMessage.value = '*Minimal 10 karakter'
                }else if(formUpdate.optional && formUpdate.optional.length > 50){
                    isOptionalValidated.value = false
                    optionalErrorMessage.value = '*Maksimal 50 karakter'
                }else{
                    isOptionalValidated.value = true
                    optionalErrorMessage.value = null
                }
            }else{
                isOptionalValidated.value = true
                optionalErrorMessage.value = null
            }
        }

        function formUpdateSubmit(values){
            isLoading.value = true
            let data = {
                email: values.email,
                name: values.name
            }
            axios.put('/customer/update/email', data)
            .then(() => {
                store.dispatch('user/storeUser')
                store.dispatch('leadQualification/setFormUpdateContact', values)
                router.push({ name : 'lead qualification - kemampuan menabung' })
            }).catch((err) => {
                isLoading.value = false;
                if(err.response.status == 400 || err.response.status == 422 ){
                    if(err.response.data.message.includes('customers_email_unique')){
                        swalError.fire({
                            html: '<p class="text-center text-sm mt-2">Maaf, email yang kamu masukkan telah digunakan, pastikan email tersebut adalah email anda atau gunakan email lain.</p>',
                            showCancelButton: true,
                            showConfirmButton: false,
                            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                        });
                    } else {
                        swalError.fire({
                            html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                            showCancelButton: true,
                            confirmButtonText: 'Bantuan',
                            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                        }).then((result) => {
                            if (result.isConfirmed){
                                window.location = 'https://wa.me/622150933150?text=' + 
                                'Mohon bantuan saya gagal terus dalam pengisian informasi tambahan pada proses Registrasi Program Keringanan Utang di web.amalan.com';
                                return;
                            }
                        });
                    }
                }else{
                    swalError.fire({
                        html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                        showCancelButton: true,
                        confirmButtonText: 'Bantuan',
                        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                    }).then((result) => {
                        if (result.isConfirmed){
                            window.location = 'https://wa.me/622150933150?text=' + 
                            'Mohon bantuan saya gagal terus dalam pengisian informasi tambahan pada proses Registrasi Program Keringanan Utang di web.amalan.com';
                            return;
                        }
                    });
                }
            });
        }

        const sentQualifiedAlert = () => amalansignupApi.get('salesforce/lead-qualification/check-qualified')

        onMounted(() => {
            sentQualifiedAlert()
        })

        return {
            dataFinancialHardships,
            formUpdate,
            formSchema,
            formUpdateSubmit,
            dataFormUpdateContact,
            dataContact,
            userData,
            isLoading,
            amountLoan,
            isOptionalValidated,
            optionalErrorMessage,
            withOptional
        }
    }
})
</script>