<script setup>
import { defineProps, defineEmits } from 'vue'
import OpportunityCard from '@/components/Card/OpportunityCard.vue'

const props = defineProps({
    title: String,
    description: String,
    bankName: String,
    imageSource: String,
    loanType: String,
    outstanding: String,
    buttonText: String,
    isPrimary: Boolean,
    isPriority: Boolean
})

const emit = defineEmits(['onAction'])

const buttonClick = () => emit('onAction')

</script>

<template>
    <div class="w-full p-4 rounded-lg border border-amalan-gray-4 bg-amalan-white text-amalan-black relative">
        <div v-if="props.isPriority" class="w-4 h-4 bg-amalan-red rounded-full absolute -top-2 -right-2"></div>
        <p class="text-sm font-bold text-amalan-blue-1 text-center">{{ props.title }}</p>
        <p class="text-xs text-center mb-4" v-html="props.description"></p>
        <OpportunityCard
            :with-action="false"
            :bank-name="props.bankName"
            :loan-type="props.loanType"
            :outstanding="props.outstanding"
            :image-source="props.imageSource"
        />
        <button @click="buttonClick" class="w-full mt-4 py-1.5 text-center shadow-amalan-md border border-amalan-blue-1 rounded-amalan-lg text-xs font-bold" :class="[ props.isPrimary ? 'text-amalan-white bg-amalan-blue-1 hover:bg-amalan-blue-2' : 'text-amalan-blue-1 bg-amalan-white hover:bg-amalan-gray-5' ]">{{ props.buttonText }}</button>
    </div>
</template>
