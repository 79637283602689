import { createRouter, createWebHistory } from 'vue-router'
import store from "../store"
import NProgress from 'nprogress'
import '../../node_modules/nprogress/nprogress.css'

//composable analytics
import { mixpanelTrackPageView } from '@/composables/mixpanel'

import FullPage from '../components/layouts/FullPage.vue'
import PageWithNavbarToolbar from '../components/layouts/PageWithNavbarToolbar.vue'
import PageWithBackNavbarToolbar from '../components/layouts/PageWithBackNavbarToolbar.vue'
import PageWithBackNavbar from '../components/layouts/PageWithBackNavbar.vue'
import PageWithCustomBackNavbar from '../components/layouts/PageWithCustomBackNavbar.vue'
import FullWidth from '../components/layouts/FullWidth.vue'
import LandingPage from '../views/LandingPage.vue'
import Login from '../views/Login.vue'
import Promo from "../views/Promo.vue"
import NotFound from "../views/404.vue"
import ErrorLoad from "../views/500.vue"
import MaintenanceMode from "../views/MaintenanceMode.vue"
import Home from '../views/Home/Home.vue'
import Akun from '../views/Akun/Akun.vue'
import ProfilPengguna from '../views/Akun/ProfilPengguna.vue'
import ProfilePicture from '../views/Akun/ProfilePicture.vue'
import DaftarPinjaman from '../views/Akun/DaftarPinjaman.vue'
import FormOCR from '../views/Akun/FormOCR.vue'
import FormUpdate from '../views/Akun/FormUpdate.vue'
import Score from '../views/Score/Score.vue'
import Pesan from '../views/Pesan/Pesan.vue'
import SelfService from '../views/SelfService/underconstruction.vue'
import Pembelian from '../views/Pembelian/Pembelian.vue'
import Aktivitas from '../views/Pembelian/Aktivitas.vue'
import SignatureVerification from '../views/DigitalSignature/SignatureVerification.vue'
import WebViewFrame from '../views/WebView/Frame.vue'

//landing page group routes
import LandingPageRoutes from './routes/landing-page'

// signup group routes
import SignupRoutes from './routes/signup'

// amalan protect
import LandingPageAmalanProtect from '../views/LandingPageAmalanProtect.vue'
import DataPinjaman from '../views/protect/DataPinjaman.vue'
import DataPelanggaran from '../views/protect/DataPelanggaran.vue'
import LihatDokumenPelanggaran from '../views/protect/LihatDokumenPelanggaran.vue'
import TandaTanganAmalanProtect from '../views/protect/TandaTangan.vue'
import PembayaranAmalanProtect from '../views/protect/PembayaranLayanan.vue'
import KonfirmasiAmalanProtect from '../views/protect/KonfirmasiLayanan.vue'
import DetailAmalanProtect from '../views/protect/Detail.vue'
// end amalan protect

//document collection
import LembarTagihanDocCollection from '../views/docCollection/LembarTagihan.vue'
import DokumenTagihanDocCollection from '../views/docCollection/DokumenTagihan.vue'
import DokumenWajibDocCollection from '../views/docCollection/DokumenWajib.vue'
import UnggahSelesaiDocCollection from '../views/docCollection/UnggahSelesai.vue'
import DaftarDokumen from '../views/Akun/DaftarDokumen.vue'
//end document collection

// lead qualification group routes
import LeadQualificationRoutes from './routes/lead-qualification'

// loan consolidation group routes
import LoanConsolidationRoutes from './routes/loan-consolidation'

//credit checkup group routes
import CreditCheckupRoutes from './routes/credit-checkup'

//rdp group routes
import RDPRoutes from './routes/rdp'

//loan management group routes
import LoanManagementRoutes from './routes/loan-management'

//negotiation process group routes
import NegotiationProcessRoutes from './routes/negotiation-process'

const routes = [
    {
        path: '',
        component: FullPage,
        children: [
            {
                path: '',
                name: 'Landing Page',
                redirect: '/login',
                component: LandingPage,
                meta: {
                    isAuth: false,
                    name: 'Landing Page',
                    title: ''
                }
            },
            {
                path: '/amalan-protect-onboarding',
                name: 'Landing Page Amalan Protect',
                component: LandingPageAmalanProtect,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Amalan Protect',
                    title: ''
                }
            },
            {
                path: '/login',
                name: 'Login',
                component: Login,
                meta: {
                    isAuth: false,
                    name: 'Login',
                    title: ''
                }
            },
        ]
    },
    {
        path: '',
        component: FullWidth,
        children: [
            {
                path: '/pdf-verification',
                name: 'Signature Verification',
                component: SignatureVerification,
                meta: {
                    isAuth: false,
                    name: 'Signature Verification',
                    title: ''
                }
            },
        ]
    },
    ...LandingPageRoutes, //landing page group routes
    {
        path: '/',
        component: PageWithNavbarToolbar,
        children: [
            {
                path: '/home',
                name: 'Home',
                component: Home,
                meta: {
                    name: 'Home',
                    isAuth: true,
                    title: 'Home',
                    excludeKYC: true
                }
            },
            {
                path: '/akun',
                name: 'Akun',
                component: Akun,
                meta: {
                    name: 'Akun',
                    isAuth: true,
                    title: 'Akun',
                    excludeKYC: true
                }
            },
            {
                path: '/score',
                name: 'Score',
                component: Score,
                meta: {
                    name: 'Score',
                    isAuth: true,
                    title: 'Score',
                    excludeKYC: true
                }
            },
            {
                path: '/pesan',
                name: 'Pesan',
                component: Pesan,
                meta: {
                    name: 'Pesan',
                    isAuth: true,
                    title: 'Pesan',
                    excludeKYC: true
                }
            },
            {
                path: '/pembelian',
                name: 'Pembelian',
                component: Pembelian,
                meta: {
                    name: 'Pembelian',
                    isAuth: true,
                    title: 'Pembelian'
                }
            },
            {
                path: '/aktivitas',
                name: 'Aktivitas',
                component: Aktivitas,
                meta: {
                    name: 'Aktivitas',
                    isAuth: true,
                    title: 'Aktivitas'
                }
            }
        ]
    },
    {
        path: '/',
        component: PageWithBackNavbarToolbar,
        children: [
            {
                path: '/akun/loan-list',
                name: 'Daftar Pinjaman',
                component: DaftarPinjaman,
                meta: {
                    name: 'Daftar Pinjaman',
                    isAuth: true,
                    title: 'Daftar Pinjaman'
                }
            },
            {
                path: '/akun/dokumen-list',
                name: 'Daftar Dokumen',
                component: DaftarDokumen,
                meta: {
                    name: 'Daftar Dokumen',
                    isAuth: true,
                    title: 'Daftar Dokumen'
                }
            },
        ]
    },
    {
        path: '/',
        component: PageWithBackNavbar,
        children: [
            //amalan protect
            {
                path: '/amalan-protect/data-pinjaman',
                name: 'amalan protect - data pinjaman',
                component: DataPinjaman,
                meta: {
                    name: 'amalan protect - data pinjaman',
                    isAuth: true,
                    amalanProtect: true,
                    title: 'amalanProtect'
                }
            },
            {
                path: '/amalan-protect/data-pelanggaran',
                name: 'amalan protect - data pelanggaran',
                component: DataPelanggaran,
                meta: {
                    name: 'amalan protect - data pelanggaran',
                    isAuth: true,
                    amalanProtect: true,
                    title: 'amalanProtect'
                }
            },
            {
                path: '/amalan-protect/dokumen-pelanggaran',
                name: 'amalan protect - dokumen pelanggaran',
                component: LihatDokumenPelanggaran,
                meta: {
                    name: 'amalan protect - dokumen pelanggaran',
                    isAuth: true,
                    amalanProtect: true,
                    title: 'amalanProtect'
                }
            },
            {
                path: '/amalan-protect/tanda-tangan/:idBank',
                name: 'amalan protect - tanda tangan',
                component: TandaTanganAmalanProtect,
                meta: {
                    name: 'amalan protect - tanda tangan',
                    isAuth: true,
                    amalanProtect: true,
                    title: 'amalanProtect'
                }
            },
            {
                path: '/amalan-protect/pembayaran/:idBank',
                name: 'amalan protect - pembayaran',
                component: PembayaranAmalanProtect,
                meta: {
                    name: 'amalan protect - pembayaran',
                    isAuth: true,
                    amalanProtect: true,
                    title: 'amalanProtect'
                }
            },
            {
                path: '/amalan-protect/:idBank/detail',
                name: 'amalan protect - detail',
                component: DetailAmalanProtect,
                meta: {
                    name: 'amalan protect - detail',
                    isAuth: true,
                    amalanProtect: true,
                    title: 'amalanProtect'
                }
            },
            {
                path: '/amalan-protect/konfirmasi',
                name: 'amalan protect - konfirmasi',
                component: KonfirmasiAmalanProtect,
                meta: {
                    name: 'amalan protect - konfirmasi',
                    isAuth: true,
                    amalanProtect: true,
                    title: 'amalanProtect'
                }
            },
            //end amalan protect

            //document collection
            {
                path: '/doc-collection/lembar-tagihan',
                name: 'doc collection - lembar tagihan',
                component: LembarTagihanDocCollection,
                meta: {
                    name: 'doc collection - lembar tagihan',
                    isAuth: true,
                    title: 'Lembar Tagihan'
                }
            },
            {
                path: '/doc-collection/dokumen-wajib',
                name: 'doc collection - dokumen wajib',
                component: DokumenWajibDocCollection,
                meta: {
                    name: 'doc collection - dokumen wajib',
                    isAuth: true,
                    title: 'Dokumen Lainnya'
                }
            },
            {
                path: '/doc-collection/unggah-selesai',
                name: 'doc collection - unggah selesai',
                component: UnggahSelesaiDocCollection,
                meta: {
                    name: 'doc collection - unggah selesai',
                    isAuth: true,
                    title: 'Unggah Selesai'
                }
            },
            //end document collection

            {
                path: '/self-service',
                name: 'Self Service',
                component: SelfService,
                meta: {
                    name: 'Self Service',
                    isAuth: true,
                    title: 'Self Service'
                }
            },

            // service temporary unavailable
            // {
            //     path: '/rdp/document-poa',
            //     name: 'rdp opening - document poa',
            //     component: SelfService,
            //     meta: {
            //         name: 'rdp opening - document poa',
            //         isAuth: true,
            //         title: 'Surat Kuasa RDP',
            //     }
            // },
            {
                path: '/credit-checkup-service',
                name: 'Credit Checkup Service',
                component: SelfService,
                meta: {
                    name: 'Credit Checkup Service',
                    isAuth: true,
                    title: 'Credit Checkup & Report',
                    excludeKYC: true
                }
            },
            // service temporary unavailable
        ]
    },
    {
        path: '/',
        component: PageWithCustomBackNavbar,
        children: [
            ...LeadQualificationRoutes, //lead qualification group routes
            ...LoanConsolidationRoutes, //loan consolidation group routes
            ...CreditCheckupRoutes, //credit checkup group routes
            ...RDPRoutes, //rdp group routes

            //profile
            {
                path: '/akun/profil',
                name: 'Profil Pengguna',
                component: ProfilPengguna,
                meta: {
                    name: 'Profil Pengguna',
                    isAuth: true,
                    title: 'Profil Pengguna',
                    excludeKYC: true
                }
            },
            {
                path: '/akun/profil/edit',
                name: 'profile picture - edit',
                component: ProfilePicture,
                meta: {
                    name: 'profile picture - edit',
                    isAuth: true,
                    title: 'Foto Profil',
                    excludeKYC: true
                }
            },
            //end profile

            //web view frame
            {
                path: '/web-view-frame',
                name: 'Web View Frame',
                component: WebViewFrame
            },
            //end web view frame

            //Basic KYC
            {
                path: '/onboarding-1',
                name: 'On Boarding - Scan KTP',
                component : FormOCR,
                meta: {
                    name: 'ONBOARDING1',
                    isAuth: true,
                    title: 'Verifikasi Data'
                }
            },
            {
                path: '/onboarding-2',
                name: 'On Boarding - Lengkapi Data',
                component : FormUpdate,
                meta: {
                    name: 'ONBOARDING2',
                    isAuth: true,
                    title: 'Verifikasi Data'
                }
            },
            //end Basic KYC

            ...SignupRoutes, //signup group routes
            ...LoanManagementRoutes, //loan management group routes
            ...NegotiationProcessRoutes, //negotiation process group routes

            {
                path: '/doc-collection/lembar-tagihan/:opportunityId',
                name: 'doc collection - lembar tagihan detail',
                component: DokumenTagihanDocCollection,
                meta: {
                    name: 'doc collection - lembar tagihan detail',
                    isAuth: true,
                    title: 'Lembar Tagihan'
                }
            }
        ]
    },
    {
        path: '',
        component: FullPage,
        children: [
            {
                path: '/promo',
                name: 'Promo',
                component: Promo,
                meta: {
                    isAuth: false,
                    title: ''
                }
            }  ,
            {
                path: '/data-error',
                name: 'ErrorLoad',
                component: ErrorLoad,
                meta: {
                    isAuth: false,
                    title: ''
                }
            },        
            {
                path: '/maintenance',
                name: 'MaintenanceMode',
                component: MaintenanceMode,
                meta: {
                    isAuth: false,
                    title: ''
                }
            },        
            {
                path: '/:pathMatch(.*)*',
                name: 'NotFound',
                component: NotFound,
                meta: {
                    isAuth: false,
                    title: ''
                }
            }        
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// router.afterEach(async (to) => {
//     const params = window.location.search.substr(1);
//     if(!params){
//         const query = { someParam: 'some-value', newValue: 'new-value' };
//         router.replace({ query });
//     }
// })
router.afterEach(() => {
    NProgress.done()

    mixpanelTrackPageView()
})

router.beforeEach(async (to, from, next) => {
    NProgress.configure({ showSpinner: false });
    NProgress.start();

    // if(!localStorage.getItem('amalan-developer-team-mode')){
    //     if (to.name == 'MaintenanceMode'){
    //         return next()
    //     }else{
    //         return next({ name: 'MaintenanceMode'})
    //     }
    // }

    if (to.meta.isAuth) {
        if (!localStorage.getItem('accessToken')){
            return next({ name: 'Login'});
        }

        if (store.getters['user/getUserId'] == null){
            const response = await store.dispatch('user/storeUser');
            if(response == 500){
                return next('/data-error');
            }
            store.dispatch('docCollection/requestOpportunities');
            store.dispatch('docCollection/requestDocWajib');
            store.dispatch('creditCheckup/requestCBResult');
            store.dispatch('bookingData/requestAvailableDate');
            store.dispatch('catalog/requestCatalog')
            store.dispatch('user/setProfilePicture')

            if(!store.getters['loanProgress/getTouchedStatus'] && !store.getters['loanProgress/getOpportunitiesFetchStatus']){
                store.dispatch('loanProgress/fetchData')
                store.dispatch('additionalSUF/requestLastInstallmentPayment')
                store.dispatch('additionalSUF/requestLastAdditionalSuf')
                store.dispatch('upgradePricingPackage/requestLastUpgradePackage')
            }

            if(!store.getters['negotiationResult/getNegotiationResultApproval'].isFetched){
                store.dispatch('negotiationResult/setNegotiationResultApproval')
            }

            if(!store.getters['negotiationResult/getNegotiationResultHistory'].isFetched){
                store.dispatch('negotiationResult/setNegotiationResultHistory')
            }

            if(!store.getters['targetPelunasan/getCurrentTargetPelunasan'].isFetched){
                store.dispatch('targetPelunasan/setCurrentTargetPelunasan')
            }

            await store.dispatch('contact/requestContact');
            await store.dispatch('RDPManagement/requestRdpData')
        }

        // credit checkup service temporary unavailable
        // if(to.path.includes('/credit-checkup/')){
        //     return next({ name: 'Credit Checkup Service'})
        // }
        // credit checkup service temporary unavailable

        if(!to.meta.excludeKYC){
            if (store.getters['user/getOCRStatus'] == false){
                if (store.getters['user/getCompareStatus'] == true){
                    await store.dispatch('user/requestIdCard')
                    if(to.meta.name == 'ONBOARDING2'){
                        return next();
                    }else{
                        return next('/onboarding-2');
                    }
                } else {
                    if(to.meta.name == 'ONBOARDING1'){
                        return next();
                    }else{
                        return next('/onboarding-1');
                    }
                }
            } else {
                if (to.meta.name == 'ONBOARDING1' || to.meta.name == 'ONBOARDING2'){
                    return next({ name: 'Home'});
                } else if (to.meta.name == 'Ringkasan Booking Layanan') {
                    return next({ name: 'Home'});
                }
            }
        }

        if(to.meta.amalanSignUp){
            if(store.getters['opportunities/getOpportunities'] == null){
                store.dispatch('leadQualification/requestProductType')
                store.dispatch('leadQualification/requestCCType')
                await store.dispatch('opportunities/requestOpportunities');
                await store.dispatch('contact/requestSignUpStatus');
                await store.dispatch('contact/requestContact');
            }

            if(store.getters['contact/getContact'].isIntegrationUserOwner == true || store.getters['opportunities/getAmountLoan'] == null || store.getters['contact/getContact'].Send_Signup_Link_Timestamp__c == null || store.getters['contact/getContact'].Financial_Hardships__c == null){
                await store.dispatch('customCondition/changeCanSignup', { canSignup: false, message: 'default' });
                return next({ name: 'Home'});
            }

            if(store.getters['contact/getContact'].Pricing_Package__c == null){
                await store.dispatch('customCondition/changeCanSignup', { canSignup: false, message: 'package' });
                return next({ name: 'Home'});
            }
        }

        if(to.meta.amalanProtect){
            if(localStorage.getItem('dataPinjaman')){
                await store.dispatch('formAmalanProtect/storeDataPinjaman', JSON.parse(localStorage.getItem('dataPinjaman')));
            }

            if(localStorage.getItem('dataPelanggaran')){
                await store.dispatch('formAmalanProtect/storeDataPelanggaran', JSON.parse(localStorage.getItem('dataPelanggaran')));
            }

            if(localStorage.getItem('docPendukung')){
                await store.dispatch('formAmalanProtect/storeDocPendukung', JSON.parse(localStorage.getItem('docPendukung')));
            }

            if(localStorage.getItem('tempDocPendukung')){
                await store.dispatch('formAmalanProtect/storeTempDocPendukung', JSON.parse(localStorage.getItem('tempDocPendukung')));
            }
        }

        if(to.meta.leadQualification === true){
            if(!store.getters['leadQualification/getFinancialHardship'].length > 0){
                await store.dispatch('leadQualification/requestFinancialHardship')
            }

            if(store.getters['leadQualification/getContact'] == null){
                await store.dispatch('leadQualification/prepopulateContactData')
                await store.dispatch('contact/requestSignUpStatus')
                
                if(store.getters['contact/getSignUpStatus'] !== 404){
                    return next({ name: 'Sign Up Onboarding'})
                }
            }

            store.dispatch('leadQualification/requestOpportunities')
            store.dispatch('leadQualification/requestProductType')
            store.dispatch('leadQualification/requestCCType')
        }

        if(to.meta.creditScoreKYC === true){
            window.location = 'https://lp.amalan.com/id-credit-report/'
            return next({ name:'Home' })
        }

        if(to.meta.name == 'Book Konsultasi'){
            return next({ name:'Home' })
        }

        if(to.meta.name == 'credit checkup - credit score checkout'){
            await store.dispatch('user/requestIdCard')
        }

        if(to.meta.creditScoreKYC === true || to.meta.creditCheckupProductKYC === true){
            if (store.getters['user/getKYCBypassStatus'] == true){
                if(to.meta.name === to.meta.kycRedirectTo + ' - onboarding 1'){
                    return next();
                }else{
                    return next({ name: to.meta.kycRedirectTo + ' - onboarding 1'});
                }
            } else {
                if(store.getters['user/getOCRStatus'] == false){
                    if(store.getters['user/getCompareStatus'] == false){
                        if (to.meta.name === to.meta.kycRedirectTo + ' - onboarding 1'){
                            return next()
                        } else {
                            return next({ name: to.meta.kycRedirectTo + ' - onboarding 1'})
                        }
                    }else{
                        if (to.meta.name === to.meta.kycRedirectTo + ' - onboarding 2'){
                            return next()
                        } else {
                            return next({ name: to.meta.kycRedirectTo + ' - onboarding 2'})
                        }
                    }
                }else{
                    if (to.meta.name === to.meta.kycRedirectTo + ' - onboarding 1' || to.meta.name === to.meta.kycRedirectTo + ' - onboarding 2'){
                        return next({ name: 'Home'})
                    }else{
                        return next()
                    }
                }
            }
        }

        if(to.meta.rdpOpeningKYC === true){
            if(store.getters['user/getCheckIdStatus'] !== true){
                if (to.meta.name === to.meta.kycRedirectTo + ' - onboarding 1'){
                    return next()
                } else {
                    return next({ name: to.meta.kycRedirectTo + ' - onboarding 1'})
                }
            }else{
                if(store.getters['user/getOCRStatus'] === false){
                    if (to.meta.name === to.meta.kycRedirectTo + ' - onboarding 2'){
                        await store.dispatch('user/requestIdCard')
                        return next()
                    } else {
                        return next({ name: to.meta.kycRedirectTo + ' - onboarding 2'})
                    }
                }else{
                    if (to.meta.name === to.meta.kycRedirectTo + ' - onboarding 1' || to.meta.name === to.meta.kycRedirectTo + ' - onboarding 2'){
                        return next({ name: 'Home'})
                    }else{
                        return next()
                    }
                }
            }
        }

        if(to.meta.loanManagement === true){
            if(store.getters['contact/getSignUpStatus'] === null){
                await store.dispatch('contact/requestSignUpStatus')
            }

            if(to.meta.mustSignup && store.getters['contact/getSignUpStatus'] !== 200){
                return next({ name: 'Loan Management - Home'});
            }

            if(to.meta.mustWaitLastTransactionData && store.getters['upgradePricingPackage/getLastUpgradePackageFetchStatus'] || store.getters['additionalSUF/getLastAdditionalSufFetchStatus'] || store.getters['additionalSUF/getLastInstallmentPaymentFetchStatus']){
                return next({ name: 'Loan Management - Home'})
            }

            if(store.getters['leadQualification/getProductType'].length === 0 || store.getters['leadQualification/getCCType'].length === 0){
                store.dispatch('leadQualification/requestProductType')
                store.dispatch('leadQualification/requestCCType')
            }
        }

        if(to.meta.negotiationProcess === true){
            if(store.getters['contact/getSignUpStatus'] === null){
                await store.dispatch('contact/requestSignUpStatus')
            }

            if(to.meta.mustSignup && store.getters['contact/getSignUpStatus'] !== 200){
                return next({ name: 'Negotiation Process - Home'});
            }
        }
      
        if(to.meta.loanConsolidation){
            if(store.getters['contact/getSignUpStatus'] == null){
                await store.dispatch('contact/requestSignUpStatus')
            }

            const lastLoanConsolidation = store.getters['loanConsolidation/getLastLoanConsolidation']
            if(!lastLoanConsolidation.status) await store.dispatch('loanConsolidation/requestLastLoanConsolidation')

            if(store.getters['loanConsolidation/getOpportunities'] == null){
                store.dispatch('leadQualification/requestProductType')
                store.dispatch('leadQualification/requestCCType')
                store.dispatch('loanConsolidation/requestOpportunities');
                store.dispatch('loanConsolidation/requestEmployerData');
            }
        }

        return next();
    }else if (to.name == 'Login'){
        if (localStorage.getItem('accessToken')){
            return next({ name: 'Home'});
        }
    }

    return next();
})

export default router
