<template>
    <input ref="inputRef" type="text" :placeholder="placeholder" class="block w-full px-4 py-2.5 text-xs border focus:outline-none appearance-none" :class="[propsClass, disabled ? 'text-amalan-gray-2 bg-amalan-gray-5' : 'text-amalan-black bg-amalan-white']" :disabled="disabled" />
</template>

<script>
import { watch } from 'vue'
import { useCurrencyInput } from "vue-currency-input";

export default {
    name: "CurrencyInput2",
    props: {
        propsClass: String,
        modelValue: Number,
        options: Object,
        placeholder: String,
        disabled: Boolean
    },
    setup(props) {
        const { inputRef, setValue } = useCurrencyInput(props.options);

        watch(
            () => props.modelValue,
            (value) => {
                setValue(value)
            }
        )

        return { inputRef };
    },
};
</script>
