<script setup>
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onMounted, ref, inject, watch } from 'vue'
import NavbarWithBackV2 from '@/components/NavbarWithBackV2.vue'
import opportunityHelper from '@/helpers/opportunity-helper'
import TableDiskon from './Components/TableDiskon.vue'
import TableCicilan from './Components/TableCicilan.vue'
import TableDiskonCicilan from './Components/TableDiskonCicilan.vue'
import useModalPopUp from '@/composables/modalPopUp'
import ThanksModalWithMarkOption from '@/components/Modal/ThanksModalWithMarkOption.vue'
import apiMediationService from "@/axios/mediationService"

const router = useRouter()
const route = useRoute()
const store = useStore()
const modalPopUp = useModalPopUp()
const filters = inject('Filters')

const thanksModalOptions = {
    approved: {
        mark: 'check',
        content: 'Persetujuan Anda akan segera kami proses.<br>Anda dapat melakukan pengecekan berkala pada halaman menu Manajemen Pinjaman.<br><br>Jika terjadi kendala, Anda dapat menghubungi konsultan kami.',
        withButton: true
    },
    rejected: {
        mark: 'x',
        content: 'Anda tidak menyetujui hasil negosiasi yang telah didapatkan. Tim Mediasi kami akan menghubungi Anda segera untuk proses selanjutnya.<br><br>Jika terjadi kendala, Anda dapat menghubungi konsultan kami.',
        withButton: false
    },
}

const negotiationResults = computed(() => store.getters['negotiationResult/getNegotiationResultApproval'])
const getterUser = computed(() => store.getters['user/getUser'])

const isLoading = ref(false)
const showThanksModal = ref(false)
const thanksModalConfig = ref(thanksModalOptions.approved)
const autoCloseModalTOInstance = ref(null)
const negotiationResult = ref(null)

const rejectedReasonFormData = ref({
    show: false,
    reason: null
})

watch(negotiationResults, () => findNegotiationResult(), {deep: true})

const findNegotiationResult = () => {
    const result = negotiationResults.value.data.find((value) => {
        const a = value.Id == route.params.negotiationId
        if(a) return a
        return value.Opportunity__r.Id == route.params.negotiationId
    })

    if(!result && negotiationResult.value) return
    if(!result && !negotiationResult.value) return router.push({ name: 'Negotiation Process - Result - Home' })
    return negotiationResult.value = result
}

const getName = () => {
    const firstName = getterUser.value.first_name ? getterUser.value.first_name.trim() : ""
    const lastName = getterUser.value.last_name ? getterUser.value.last_name.trim() : ""
    return (firstName.toUpperCase() + " " + lastName.toUpperCase()).trim()
}

const getLoanType = (loanType) => {
    const result = opportunityHelper.getLoanTitle(loanType)
    if(!result) return '-'
    if(result.toUpperCase() == 'PINJAMAN ONLINE') return 'Pinjaman <span class="italic">Online</span>'
    return result
}

const getProgramTypeDescription = (restructuringType) => {
    const value = restructuringType ? restructuringType.toUpperCase() : ''
    if(value === 'DISCOUNT') return 'Diskon'
    if(value === 'RESCHEDULE') return 'Cicilan Jangka Panjang'
    return 'Diskon dengan Cicilan'
}

const createDueDateDescription = (due_date) => {
    if(!due_date) return '-'

    const result = filters.diffDateOnDays(filters.dateNow(), filters.dateTimeFormatBasic(due_date)) == 0
        ? `pukul ${filters.dateTimeFormatOnlyHourMinute(due_date)} WIB (HARI INI)`
        : filters.dateLongDayAndDMY(due_date)

    return result
}

const backButton = () => {
    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs mt-2">Anda belum menyetujui hasil negosiasi.<br><br>Anda yakin ingin keluar?</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Konfirmasi',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if(!result.isConfirmed) return
        return router.push({ name: 'Negotiation Process - Result - Home' })
    })
}

const onApproved = () => {
    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs mt-2">Anda menyetujui hasil negosiasi yang didapatkan. Perubahan ini akan disimpan dan pelunasan pinjaman Anda akan segera diproses.</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Konfirmasi',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if(!result.isConfirmed) return
        sentApprovalForm('approve')
    })
}

const onRejected = () => {
    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs mt-2">Anda yakin tidak menyetujui hasil negosiasi yang telah didapatkan?<br><br>Hasil ini merupakan penawaran terbaik yang didapatkan sesuai dengan peluang keringanan pinjaman Anda.</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Konfirmasi',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if(!result.isConfirmed) return
        return showRejectedReasonForm()
    })
}

const closeThanksModal = () => {
    if(autoCloseModalTOInstance.value){
        clearTimeout(autoCloseModalTOInstance.value)
        autoCloseModalTOInstance.value = null
    }

    if(thanksModalConfig.value.mark !== 'check') return router.push({ name: 'Negotiation Process - Home' })
    return router.push({ name: 'Loan Management - Home' })
}

const autoCloseModal = () => {
    const autoCloseModalTO = setTimeout(() => {
        showThanksModal.value = false
        router.push({ name: 'Negotiation Process - Home' })
    }, 7000);

    autoCloseModalTOInstance.value = autoCloseModalTO
}

const showRejectedReasonForm = () => rejectedReasonFormData.value.show = true

const hideRejectedReasonForm = () => rejectedReasonFormData.value.show = false

const rejectedReasonFormSubmit = () => {
    hideRejectedReasonForm()
    const customer_comment = rejectedReasonFormData.value.reason.replaceAll("\n", ' ')
    sentApprovalForm('reject', customer_comment)
}

const sentApprovalForm = async (approval_type, customer_comment = undefined) => {
    isLoading.value = true
    try {
        const result = await apiMediationService.post('api/customer-approval', {
            approval_type,
            opportunity_approval_id: negotiationResult.value.Id,
            customer_comment
        })

        await store.dispatch('negotiationResult/setNegotiationResultApproval')
        store.dispatch('targetPelunasan/setCurrentTargetPelunasan')
        isLoading.value = false
        
        if(result.data.data.status == 'expired'){
            return modalPopUp.swalError.fire({
                html: '<p class="text-center text-xs mt-2">Maaf, sepertinya Anda sudah melewati batas waktu untuk melakukan konfirmasi.</p>',
                showCancelButton: false,
                confirmButtonText: 'Kembali'
            }).then(() => {
                return router.push({ name: 'Negotiation Process - Result - Home' })
            })
        }

        thanksModalConfig.value = approval_type === 'approve' ? thanksModalOptions.approved : thanksModalOptions.rejected
        showThanksModal.value = true
        autoCloseModal()
    } catch {
        isLoading.value = false
        modalPopUp.swalError.fire({
            html: '<p class="text-center text-xs mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        }).then((result) => {
            if(!result.isConfirmed) return
            return window.location = 'https://wa.me/622150933150?text=' + 
            'Mohon bantuan saya gagal terus untuk menyetujui atau menolak hasil negosiasi pada proses negosiasi di web.amalan.com'
        })
    }
}

onMounted(() => {
    if(!route.params.negotiationId) return router.push({ name: 'Negotiation Process - Result - Home' })
    if(negotiationResults.value.isFetched) findNegotiationResult()
})
</script>

<template>
    <div class="min-h-screen bg-amalan-white relative">
        <NavbarWithBackV2 :title="route.meta.title" @back-button="backButton" />
        <div class="w-full pt-8 pb-20 px-6 bg-amalan-white text-amalan-black">
            <p class="text-sm text-amalan-blue-1 font-bold underline uppercase text-center">HASIL NEGOSIASI PINJAMAN ANDA</p>
            <template v-if="negotiationResult">
                <p class="text-xs text-justify mt-8">Kepada Yth Bapak/Ibu {{ getName() }}<br><br>Terima kasih telah mempercayakan masalah utang Anda kepada amalan. Berikut adalah hasil negosiasi yang kami dapatkan:</p>
                <table class="w-full mt-3.5 text-xs">
                    <body>
                        <tr>
                            <td class="w-28 align-top text-left">Institusi Pinjaman</td>
                            <td class="w-2 align-top text-left">:</td>
                            <td>{{ negotiationResult.Opportunity__r.Name && negotiationResult.Opportunity__r.Loan_Type_2__c ? opportunityHelper.getBankName(negotiationResult.Opportunity__r.Name, negotiationResult.Opportunity__r.Loan_Type_2__c) : '-' }}</td>
                        </tr>
                        <tr>
                            <td class="w-28 align-top text-left">Jenis Pinjaman</td>
                            <td class="w-2 align-top text-left">:</td>
                            <td v-html="negotiationResult.Opportunity__r.Loan_Type_2__c ? getLoanType(negotiationResult.Opportunity__r.Loan_Type_2__c) : '-'"></td>
                        </tr>
                        <tr>
                            <td class="w-28 align-top text-left">Nomor Pinjaman</td>
                            <td class="w-2 align-top text-left">:</td>
                            <td>{{ negotiationResult.Opportunity__r.Account_Number__c || '-' }}</td>
                        </tr>
                        <tr>
                            <td class="w-28 align-top text-left">Jenis Program</td>
                            <td class="w-2 align-top text-left">:</td>
                            <td>{{ getProgramTypeDescription(negotiationResult.Restructuring_Type__c) }}</td>
                        </tr>
                        <tr>
                            <td class="w-28 align-top text-left">Tenor Program</td>
                            <td class="w-2 align-top text-left">:</td>
                            <td>{{ negotiationResult.Number_of_Payments__c || '-'  }}</td>
                        </tr>
                    </body>
                </table>
                <div class="w-full mt-3.5 px-4">
                    <TableDiskon
                        v-if="negotiationResult.Restructuring_Type__c.toUpperCase() === 'DISCOUNT'"
                        :amalan-success-fee="$filters.format_number_without_symbol(negotiationResult.Success_fee_before_discount__c)"
                        :amalan-discount="$filters.format_number_without_symbol(negotiationResult.Success_fee_discount__c)"
                        :amalan-total-success-fee="$filters.format_number_without_symbol(negotiationResult.Success_fee_after_discount__c)"
                        :amalan-total-fee="$filters.format_number_without_symbol(negotiationResult.First_Payment_Amount__c + negotiationResult.Success_fee_after_discount__c)"
                        :tagihan-terakhir="$filters.format_number_without_symbol(negotiationResult.Last_Outstanding_Amount__c)"
                        :discount="$filters.format_number_without_symbol(negotiationResult.Discounted_Amount__c)"
                        :total-tagihan="$filters.format_number_without_symbol(negotiationResult.First_Payment_Amount__c)"
                        :total-savings="$filters.format_number_without_symbol(negotiationResult.Last_Outstanding_Amount__c - (negotiationResult.First_Payment_Amount__c + negotiationResult.Success_fee_after_discount__c))"
                    />
                    <TableCicilan
                        v-else-if="negotiationResult.Restructuring_Type__c.toUpperCase() === 'RESCHEDULE'"
                        :amalan-success-fee="$filters.format_number_without_symbol(negotiationResult.Success_fee_before_discount__c)"
                        :amalan-discount="$filters.format_number_without_symbol(negotiationResult.Success_fee_discount__c)"
                        :amalan-total-success-fee="$filters.format_number_without_symbol(negotiationResult.Success_fee_after_discount__c)"
                        :amalan-total-fee="$filters.format_number_without_symbol(negotiationResult.First_Payment_Amount__c + negotiationResult.Success_fee_after_discount__c)"
                        :tagihan-terakhir="$filters.format_number_without_symbol(negotiationResult.Last_Outstanding_Amount__c)"
                        :cicilan-lama="$filters.format_number_without_symbol(negotiationResult.Opportunity__r.Old_Installment__c)"
                        :cicilan-baru="$filters.format_number_without_symbol(negotiationResult.Subsequent_Payments_Amount__c)"
                        :uang-muka="$filters.format_number_without_symbol(negotiationResult.First_Payment_Amount__c)"
                        :total-savings="$filters.format_number_without_symbol(negotiationResult.Opportunity__r.Old_Installment__c - negotiationResult.Subsequent_Payments_Amount__c)"
                    />
                    <TableDiskonCicilan
                        v-else
                        :amalan-success-fee="$filters.format_number_without_symbol(negotiationResult.Success_fee_before_discount__c)"
                        :amalan-discount="$filters.format_number_without_symbol(negotiationResult.Success_fee_discount__c)"
                        :amalan-total-success-fee="$filters.format_number_without_symbol(negotiationResult.Success_fee_after_discount__c)"
                        :amalan-total-fee="$filters.format_number_without_symbol(negotiationResult.First_Payment_Amount__c + negotiationResult.Success_fee_after_discount__c)"
                        :tagihan-terakhir="$filters.format_number_without_symbol(negotiationResult.Last_Outstanding_Amount__c)"
                        :discount="$filters.format_number_without_symbol(negotiationResult.Discounted_Amount__c)"
                        :tagihan-pertama="$filters.format_number_without_symbol(negotiationResult.First_Payment_Amount__c)"
                        :tagihan-berikutnya="$filters.format_number_without_symbol(negotiationResult.Subsequent_Payments_Amount__c)"
                        :total-savings="$filters.format_number_without_symbol(negotiationResult.Last_Outstanding_Amount__c - (negotiationResult.First_Payment_Amount__c + negotiationResult.Success_fee_after_discount__c))"
                    />
                </div>
                <p class="mt-3.5 text-justify text-xs">Kami <span class="uppercase font-bold" :class="[ negotiationResult.is_Recommended__c ? 'text-amalan-green-light' : 'text-amalan-red' ]">{{ negotiationResult.is_Recommended__c ? 'MEREKOMENDASIKAN' : 'TIDAK MEREKOMENDASIKAN' }}</span> Anda untuk menyetujui hasil negosiasi di atas. Silakan konfirmasi dengan klik tombol di bawah ini sebelum <span class="font-bold">{{ createDueDateDescription(negotiationResult.Customer_Feedback_Due_Date__c) }}.</span></p>
                <div class="w-full mt-8 px-4 grid grid-cols-2 gap-x-4">
                    <button @click="onRejected" class="w-full py-3 text-center text-sm font-bold bg-amalan-white text-amalan-blue-1 border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md hover:bg-amalan-gray-5">Menolak</button>
                    <button @click="onApproved" class="w-full py-3 text-center text-sm font-bold bg-amalan-blue-1 text-amalan-white border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md hover:bg-amalan-blue-2">Ya, Menyetujui</button>
                </div>
                <p class="text-xs mt-8"><span class="font-bold">Langkah Selanjutnya</span><br>Setelah Anda melakukan konfirmasi hasil negosiasi, mediator kami akan membantu mempersiapkan dokumen yang diperlukan untuk proses keringanan utang Anda. Proses pembayaran akan dilakukan melalui Rekening Dana Pelunasan (RDP) setelah administrasi dokumen selesai.<br><br>Jika Anda membutuhkan bantuan, silakan hubungi mediator kami, <span class="font-bold">{{ negotiationResult.Owner?.Name ? negotiationResult.Owner.Name + ',' : '' }}</span> melalui nomor 021 50933141 (Telepon dan WhatsApp)</p>
            </template>
        </div>

        <ThanksModalWithMarkOption :visible="showThanksModal" :content="thanksModalConfig.content" :animation-mark="thanksModalConfig.mark" :with-button="thanksModalConfig.withButton" :button-text="'Menu Manajemen Pinjaman'" @action="closeThanksModal" />

        <Transition name="fade-fast">
            <div v-show="rejectedReasonFormData.show" class="fixed inset-0 z-50">
                <div class="w-full bg-black bg-opacity-50 p-6 min-h-screen max-w-lg mx-auto flex justify-center items-center">
                    <div class="w-full py-8 px-4 bg-amalan-white rounded-lg -mt-10">
                        <p class="text-amalan-blue-1 text-sm font-bold text-center">Alasan Penolakan Hasil Negosiasi</p>
                        <textarea v-model="rejectedReasonFormData.reason" rows="6" class="w-full mt-10 p-2 border border-amalan-gray-4 bg-amalan-gray-5 rounded-lg text-xs text-amalan-black" placeholder="Jelaskan alasan Anda terhadap penolakan hasil negosiasi yang telah didapatkan"></textarea>
                        <div class="w-full mt-10 px-4 grid grid-cols-2 gap-x-4">
                            <button @click="hideRejectedReasonForm" class="w-full py-3 text-center text-sm font-bold bg-amalan-white text-amalan-blue-1 border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md hover:bg-amalan-gray-5">Batal</button>
                            <button @click="rejectedReasonFormSubmit" class="w-full py-3 text-center text-sm font-bold rounded-amalan-lg" :class="[ rejectedReasonFormData.reason ? 'border shadow-amalan-md bg-amalan-blue-1 text-amalan-white border-amalan-blue-1 hover:bg-amalan-blue-2' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="rejectedReasonFormData.reason ? false : true">Kirim</button>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>

        <loading-overlay v-if="!negotiationResults.isFetched || isLoading" />
    </div>
</template>

<style scoped>
.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.3s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.3s ease
}
</style>