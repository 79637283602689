<template>
    <div class="pt-4 min-h-screen h-auto relative">
        <div class="mt-4 pb-40 w-full px-4">
            <div v-for="(value, index) in tempDocPendukung" :key="index" class="w-full rounded-lg border border-gray-300 mt-4 mb-2">
                <div class="w-full h-1/6 px-4 py-2 border-b border-gray-300">
                    <span class="text-sm">Dokumen Pendukung {{ index + 1 }}</span>
                </div>
                <div class="w-full h-5/6 flex justify-center items-center px-2 py-2">
                    <img v-if="tempDocPendukung[index].nameFileTypePratinjau == 'gambar' && tempDocPendukung[index].urlPratinjau" :src="tempDocPendukung[index].urlPratinjau" class="w-4/6">
                    <video v-else-if="tempDocPendukung[index].nameFileTypePratinjau == 'video' && tempDocPendukung[index].urlPratinjau" :src="tempDocPendukung[index].urlPratinjau" class="w-full" controls></video>
                    <audio v-else-if="tempDocPendukung[index].nameFileTypePratinjau == 'audio' && tempDocPendukung[index].urlPratinjau" :src="tempDocPendukung[index].urlPratinjau" class="w-full" controls></audio>
                    <template v-else-if="tempDocPendukung[index].nameFileTypePratinjau == 'pdf'">
                        <div class="w-40">
                            <img src="@/assets/img/protect/thumbnail-pdf.png" class="w-full">
                            <p class="text-center text-sm px-2 overflow-scroll">{{ tempDocPendukung[index].fileName }}</p>
                        </div>
                    </template>
                    <template v-else>
                        <div class="w-40">
                            <img src="@/assets/img/protect/thumbnail-pdf.png" class="w-full">
                            <p class="text-center text-sm px-2 overflow-scroll">{{ tempDocPendukung[index].fileName }}</p>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default{
    computed: {
        ...mapGetters('formAmalanProtect',{
            docPendukung:'getDocPendukung',
            tempDocPendukung:'getTempDocPendukung',
        })
    },
    created(){
        if(!this.docPendukung || !this.tempDocPendukung){
            this.$router.push('/amalan-protect/data-pelanggaran')
        }
    }
}
</script>