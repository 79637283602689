import amalansignupApi from "@/axios/amalansignup";

const state = () => ({
    opportunities: null,
    amountLoan: null,
    employerConfigData: {
        employer: [],
        employerType: [],
        numberOfEmployee: [],
        houseValue: []
    },
    InformasiUmumForm: {
        isValid: false,
        question_one: null,
        question_two: null,
        question_three: null
    },
    DataDiriForm: {
        isValid: false,
        name: null,
        email: null,
        birthdate: null
    },
    DataPekerjaanForm: {
        isValid: false,
        employer: null,
        employer_name: null,
        employer_type: null,
        num_of_employee: null,
        employment_contract_type: null,
        income: null
    },
    DataAsetForm: {
        isValid: false,
        has_house: null,
        house_is_a_mortgage: null,
        mortgage_remaining_balance: null,
        original_mortgage_disbursed_amount: null,
        mortgage_disbursement_date: null,
        mortgage_monthly_installment: null,
        estimated_house_value: null,
        estimated_house_value_source: null
    },
    lastLoanConsolidation: {
        status: null,
        data: null
    }
});

const getters = {
    getOpportunities(state) {
        return state.opportunities;
    },
    getAmountLoan(state) {
        return state.amountLoan;
    },
    getInformasiUmumForm(state) {
        return state.InformasiUmumForm;
    },
    getDataDiriForm(state) {
        return state.DataDiriForm;
    },
    getDataPekerjaanForm(state) {
        return state.DataPekerjaanForm;
    },
    getDataAsetForm(state) {
        return state.DataAsetForm;
    },
    getEmployerConfigData(state) {
        return state.employerConfigData.employer;
    },
    getEmployerTypeConfigData(state) {
        return state.employerConfigData.employerType;
    },
    getNumOfEmployeeConfigData(state) {
        return state.employerConfigData.numberOfEmployee;
    },
    getHouseValueConfigData(state) {
        return state.employerConfigData.houseValue.map((v) => {
            return {
                label: v.name,
                value: v.value
            }
        });
    },
    getLastLoanConsolidation(state) {
        return state.lastLoanConsolidation;
    }
};

const actions = {
    async requestOpportunities({ commit }) {
        try {
            const response = await amalansignupApi.get('loan-consolidation/list-opportunity')
            commit("requestOpportunities", response.data)
            return true
        } catch {
            commit("requestOpportunities", {data: null})
            return false
        }
    },
    async requestEmployerData({ commit }) {
        try {
            const response = await amalansignupApi.get('loan-consolidation/employer-information')
            commit("requestEmployerData", response.data)
            return true
        } catch {
            return false
        }
    },
    async requestLastLoanConsolidation({commit}){
        try {
            const response = await amalansignupApi.get('loan-consolidation/last-refinancing-status')
            commit('requestLastLoanConsolidation', {
                status: response.status,
                data: response.data
            })
            return true
        } catch (error) {
            commit('requestLastLoanConsolidation', {
                status: error.response.status,
                data: error.response.data
            })
            return false
        }
    },
    setInformasiUmumForm({commit}, payload){
        commit("setInformasiUmumForm", payload)
    },
    setDataDiriForm({commit}, payload){
        commit("setDataDiriForm", payload)
    },
    setDataPekerjaanForm({commit}, payload){
        commit("setDataPekerjaanForm", payload)
    },
    setDataAsetForm({commit}, payload){
        commit("setDataAsetForm", payload)
    }
};

const mutations = {
    requestOpportunities(state, payload) {
        const data = payload.data
        if(!data) return state.opportunities = []

        state.opportunities = data
        state.amountLoan = data.reduce((accumulate, v) => accumulate + (v.Estimated_Outstanding_Amount__c ?? 0),0)
    },
    requestEmployerData(state, payload){
        state.employerConfigData.employer = payload.data.employer ?? []
        state.employerConfigData.employerType = payload.data.employer_type ?? []
        state.employerConfigData.numberOfEmployee = payload.data.number_employee ?? []
        state.employerConfigData.houseValue = payload.data.house_value_type ?? []
    },
    setInformasiUmumForm(state, payload){
        state.InformasiUmumForm.isValid = true
        state.InformasiUmumForm.question_one = payload.question_one
        state.InformasiUmumForm.question_two = payload.question_two
        state.InformasiUmumForm.question_three = payload.question_three
    },
    setDataDiriForm(state, payload){
        state.DataDiriForm.isValid = true
        state.DataDiriForm.name = payload.name
        state.DataDiriForm.email = payload.email
        state.DataDiriForm.birthdate = payload.birthdate
    },
    setDataPekerjaanForm(state, payload){
        state.DataPekerjaanForm.isValid = true
        state.DataPekerjaanForm.employer = payload.employer
        state.DataPekerjaanForm.employer_name = payload.employer_name
        state.DataPekerjaanForm.employer_type = payload.employer_type
        state.DataPekerjaanForm.num_of_employee = payload.num_of_employee
        state.DataPekerjaanForm.employment_contract_type = payload.employment_contract_type
        state.DataPekerjaanForm.income = payload.income
    },
    setDataAsetForm(state, payload){
        state.DataAsetForm.isValid = true
        state.DataAsetForm.has_house = payload.has_house
        state.DataAsetForm.house_is_a_mortgage = payload.house_is_a_mortgage
        state.DataAsetForm.mortgage_remaining_balance = payload.mortgage_remaining_balance
        state.DataAsetForm.original_mortgage_disbursed_amount = payload.original_mortgage_disbursed_amount
        state.DataAsetForm.mortgage_disbursement_date = payload.mortgage_disbursement_date
        state.DataAsetForm.mortgage_monthly_installment = payload.mortgage_monthly_installment
        state.DataAsetForm.estimated_house_value = payload.estimated_house_value
        state.DataAsetForm.estimated_house_value_source = payload.estimated_house_value_source
    },
    requestLastLoanConsolidation(state, {status, data}){
        state.lastLoanConsolidation.status = status ? status : 400
        state.lastLoanConsolidation.data = data ?? null
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
