import NegotiationProcessHome from '@/views/Akun/ProsesNegosiasi/Home.vue'
import NegotiationProcessFAQ from '@/views/Akun/ProsesNegosiasi/FAQ.vue'
import NegotiationProcessTargetPelunasanHome from '@/views/Akun/ProsesNegosiasi/TargetPelunasan/Home.vue'
import NegotiationProcessTargetPelunasanAdd from '@/views/Akun/ProsesNegosiasi/TargetPelunasan/Add.vue'
import NegotiationProcessTargetPelunasanCheckout from '@/views/Akun/ProsesNegosiasi/TargetPelunasan/Checkout.vue'
import NegotiationProcessResultHome from '@/views/Akun/ProsesNegosiasi/PersetujuanHasilNegosiasi/Home.vue'
import NegotiationProcessResultDetail from '@/views/Akun/ProsesNegosiasi/PersetujuanHasilNegosiasi/Detail.vue'
import NegotiationProcessResultHistory from '@/views/Akun/ProsesNegosiasi/PersetujuanHasilNegosiasi/History/Home.vue'
import NegotiationProcessResultHistoryShow from '@/views/Akun/ProsesNegosiasi/PersetujuanHasilNegosiasi/History/Show.vue'
import NegotiationProcessResultHistoryDetail from '@/views/Akun/ProsesNegosiasi/PersetujuanHasilNegosiasi/History/Detail.vue'

export default [
    {
        path: '/negotiation-process/home',
        name: 'Negotiation Process - Home',
        component: NegotiationProcessHome,
        meta: {
            name: 'Negotiation Process - Home',
            isAuth: true,
            title: 'Proses Negosiasi',
            negotiationProcess: true
        }
    },
    {
        path: '/negotiation-process/faq',
        name: 'Negotiation Process - FAQ',
        component: NegotiationProcessFAQ,
        meta: {
            name: 'Negotiation Process - FAQ',
            isAuth: true,
            title: 'Proses Negosiasi',
            negotiationProcess: true
        }
    },
    {
        path: '/negotiation-process/target-pelunasan',
        name: 'Negotiation Process - Target Pelunasan - Home',
        component: NegotiationProcessTargetPelunasanHome,
        meta: {
            name: 'Negotiation Process - Target Pelunasan - Home',
            isAuth: true,
            title: 'Proses Negosiasi',
            negotiationProcess: true,
            mustSignup: true
        }
    },
    {
        path: '/negotiation-process/target-pelunasan/add/:opportunityId?',
        name: 'Negotiation Process - Target Pelunasan - Add',
        component: NegotiationProcessTargetPelunasanAdd,
        meta: {
            name: 'Negotiation Process - Target Pelunasan - Add',
            isAuth: true,
            title: 'Proses Negosiasi',
            negotiationProcess: true,
            mustSignup: true
        }
    },
    {
        path: '/negotiation-process/target-pelunasan/checkout',
        name: 'Negotiation Process - Target Pelunasan - Checkout',
        component: NegotiationProcessTargetPelunasanCheckout,
        meta: {
            name: 'Negotiation Process - Target Pelunasan - Checkout',
            isAuth: true,
            title: 'Proses Negosiasi',
            negotiationProcess: true,
            mustSignup: true
        }
    },
    {
        path: '/negotiation-process/result',
        name: 'Negotiation Process - Result - Home',
        component: NegotiationProcessResultHome,
        meta: {
            name: 'Negotiation Process - Result - Home',
            isAuth: true,
            title: 'Proses Negosiasi',
            negotiationProcess: true,
            mustSignup: true
        }
    },
    {
        path: '/negotiation-process/result/detail/:negotiationId',
        name: 'Negotiation Process - Result - Detail',
        component: NegotiationProcessResultDetail,
        meta: {
            name: 'Negotiation Process - Result - Detail',
            isAuth: true,
            title: 'Proses Negosiasi',
            negotiationProcess: true,
            mustSignup: true
        }
    },
    {
        path: '/negotiation-process/result/history',
        name: 'Negotiation Process - Result - History',
        component: NegotiationProcessResultHistory,
        meta: {
            name: 'Negotiation Process - Result - History',
            isAuth: true,
            title: 'Proses Negosiasi',
            negotiationProcess: true,
            mustSignup: true
        }
    },
    {
        path: '/negotiation-process/result/history/:opportunityId',
        name: 'Negotiation Process - Result - History Show',
        component: NegotiationProcessResultHistoryShow,
        meta: {
            name: 'Negotiation Process - Result - History Show',
            isAuth: true,
            title: 'Proses Negosiasi',
            negotiationProcess: true,
            mustSignup: true
        }
    },
    {
        path: '/negotiation-process/result/history/:opportunityId/detail/:negotiationId',
        name: 'Negotiation Process - Result - History Detail',
        component: NegotiationProcessResultHistoryDetail,
        meta: {
            name: 'Negotiation Process - Result - History Detail',
            isAuth: true,
            title: 'Proses Negosiasi',
            negotiationProcess: true,
            mustSignup: true
        }
    },
];