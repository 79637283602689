<script setup>
import { useStore } from 'vuex'
import { ref, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import DoneBanner from '@/components/icon/DoneBanner'

const store = useStore()
const router = useRouter()
const route = useRoute()

const isPremium = ref(false)

const updateSignupPoa = computed(() => store.getters['updateSignupPoa/getUpdateSignupPoa'])
const contact = computed(() => store.getters['contact/getContact'])

onMounted(() => {
    if(updateSignupPoa.value.status !== 200) return router.push({ name: 'Update Signup - Data Utang' })

    if(!contact.value.Pricing_Package__c) return isPremium.value = false
    const contactPackage = contact.value.Pricing_Package__c.toLowerCase()
    if(contactPackage == 'basic' || contactPackage == 'pay as you go') return isPremium.value = false
    return isPremium.value = true
})
</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <div class="flex items-center justify-center w-6 h-8"></div>
                    <span class="text-sm font-bold text-amalan-black text-center">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full min-h-screen px-6 -mt-14 flex flex-col justify-center items-center">
            <DoneBanner />
            <p v-if="isPremium" class="text-center text-xs text-amalan-black mt-7">Terima kasih telah mempercayai<br><b>PT. amalan international Indonesia</b>.<br><br> Selanjutnya konsultan kami akan segera<br> menghubungi Anda untuk proses selanjutnya.</p>
            <p v-else class="text-center text-xs text-amalan-black mt-7">Sign Up Anda telah selesai dilakukan.<br>Terima kasih telah mempercayai<br><b>PT. amalan international Indonesia</b>.<br><br> Selanjutnya konsultan kami akan segera<br> menghubungi Anda untuk proses selanjutnya.</p>
            <router-link :to="{ name: 'Home'}" class="w-full flex items-center justify-center py-3 px-2 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg mt-7">
                <span class="text-amalan-white text-sm font-bold">
                    Kembali ke Beranda
                </span>
            </router-link>
        </div>
    </div>
</template>