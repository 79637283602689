import { createStore } from "vuex";
import auth from './modules/auth';
import user from './modules/user';
import catalog from './modules/catalog';
import promo from './modules/promo';
import simplybook from "./modules/simplybook";
import services from "./modules/services";
import contact from "./modules/contact";
import opportunities from "./modules/opportunities";
import customCondition from "./modules/customCondition";
import formAmalanProtect from "./modules/formAmalanProtect";
import docCollection from "./modules/docCollection";
import leadQualification from "./modules/leadQualification";
import creditCheckup from "./modules/creditCheckup";
import globalState from "./modules/globalState";
import bookingData from "./modules/bookingData";
import RDPManagement from "./modules/RDPManagement";
import pendingUserActivity from "./modules/pendingUserActivity";
import upgradePricingPackage from "./modules/upgradePricingPackage";
import SavingActivity from "./modules/SavingActivity";
import loanProgress from "./modules/loanProgress";
import additionalSUF from "./modules/additionalSUF";
import negotiationResult from "./modules/negotiationResult";
import targetPelunasan from "./modules/targetPelunasan";
import faq from "./modules/faq";
import updateSignupPoa from "./modules/updateSignupPoa";
import loanConsolidation from "./modules/loanConsolidation";

const store = createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        user,
        catalog,
        promo,
        simplybook,
        services,
        contact,
        opportunities,
        customCondition,
        formAmalanProtect,
        docCollection,
        leadQualification,
        creditCheckup,
        globalState,
        bookingData,
        RDPManagement,
        pendingUserActivity,
        upgradePricingPackage,
        SavingActivity,
        loanProgress,
        additionalSUF,
        negotiationResult,
        targetPelunasan,
        faq,
        updateSignupPoa,
        loanConsolidation
    },
});

export default store;