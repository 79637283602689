<script setup>
import { XIcon } from '@heroicons/vue/solid'
import { defineProps, defineEmits, ref } from 'vue'

const props = defineProps({
    visible: Boolean
})

const emit = defineEmits(['onClose'])
const isVisible = ref(false)

setTimeout(() => {
    isVisible.value = props.visible
}, 1000);

const closeButton = () => {
    isVisible.value = false
    emit('onClose')
}

</script>

<template>
    <div class="w-full max-w-lg absolute top-0 flex justify-center">
        <div class="w-5/6 py-10 px-4 flex justify-center items-center bg-amalan-white rounded-b-xl relative z-20 transform transition-all duration-500" :class="[ isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-full' ]">
            <div @click="closeButton" class="w-8 h-8 absolute -bottom-4 mx-auto -inset-x-0 bg-amalan-blue-1 rounded-full border border-amalan-white shadow-amalan-lg flex justify-center items-center cursor-pointer"><XIcon class="w-5 text-amalan-white"/></div>
            <img src="@/assets/img/recommendation-browser-icon.svg" class="w-20">
            <div class="text-amalan-black pl-2">
                <p class="text-xs">Hai, Sobat amalan!</p>
                <p class="text-xs mt-2">Kami menyarankan Anda untuk menggunakan browser <b>Chrome</b>, <b>Firefox</b>, atau <b>Safari</b> versi terbaru untuk mengakses situs kami.</p>
            </div>
        </div>
        <div class="w-full max-w-lg min-h-screen fixed z-10 bg-black bg-opacity-60 transition-opacity duration-500" :class="[ isVisible ? 'opacity-100' : 'opacity-0' ]"></div>
    </div>
</template>