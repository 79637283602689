<template>
    <div class="pt-4 min-h-screen h-auto relative">
        <div class="mt-2 pb-12 w-full px-4">
            <div class="flex items-center">
                <div class="flex items-center text-blue-900 relative">
                    <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                        <img src="@/assets/img/signup/data-pinjaman-active.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Data<br>Pinjaman</p></div>
                </div>
                <div class="flex-auto border-t-2 border-gray-300"></div>
                <div class="flex items-center text-gray-500 relative">
                    <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                        <img src="@/assets/img/protect/data-pelanggaran.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Data<br>Pelanggaran</p></div>
                </div>
                <div class="flex-auto border-t-2 border-gray-300"></div>
                <div class="flex items-center text-gray-500 relative">
                    <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                        <img src="@/assets/img/signup/tanda-tangan.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Tanda<br>Tangan</p></div>
                </div>
                <div class="flex-auto border-t-2 border-gray-300"></div>
                <div class="flex items-center text-gray-500 relative">
                    <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                        <img src="@/assets/img/signup/pembayaran.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Pembayaran<br>Layanan</p></div>
                </div>
                <div class="flex-auto border-t-2 border-gray-300"></div>
                <div class="flex items-center text-gray-500 relative">
                    <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                        <img src="@/assets/img/verified.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Konfirmasi<br>Layanan</p></div>
                </div>
            </div>
        </div>
        <Form
            @submit="formSubmit"
            :validation-schema="formDataPinjamanSchema"
            v-slot="{ errors, meta }"
            >
            <div class="mt-6 pb-40 w-full px-4">
                <div class="my-2">
                    <label class="text-gray-700 font-bold text-sm">Nama Bank/Institusi</label>
                    <div class="flex relative w-full">
                        <div class="w-full box-border border rounded-md" :class="[ errors.ref_bank_name ? 'border-red-600' : 'border-gray-200']">
                            <Multiselect
                            v-model="formDataPinjaman.dataBank"
                            ref="multiselect"
                            :noResultsText="'Data tidak ditemukan.'"
                            :noOptionsText="'Data tidak ada.'"
                            :searchable="true"
                            :options="dataLenders"
                            :classes="multiselectClass"
                            />
                        </div>
                        <Field name="ref_bank_name" type="text" class="hidden" :validateOnMount="true" :validateOnInput="true" v-model="formDataPinjaman.dataBank" />
                    </div>
                    <div v-if="errors.ref_bank_name" class="mt-1 text-sm text-red-600">*{{errors.ref_bank_name}}</div>
                </div>
                <div class="my-2">
                    <label class="text-gray-700 font-bold text-sm">Nomor Pinjaman</label>
                    <Field name="accountNumber" :validateOnInput="true" type="text" class="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none pl-4 pr-8 hover:border-gray-400" :class="{ 'border-red-600': errors.accountNumber }" v-model="formDataPinjaman.noPinjaman" />
                    <div v-if="errors.accountNumber" class="mt-1 text-sm text-red-600">*{{errors.accountNumber}}</div>
                    <p class="text-xs pt-1 pl-1">Atau Anda dapat memasukkan "x" sebanyak delapan kali apabila anda tidak tahu nomor pinjaman anda.</p>
                </div>
                <div class="my-2">
                    <label class="text-gray-700 font-bold text-sm">Jenis Pinjaman</label>
                    <div class="flex relative w-full">
                        <Field name="loanType" as="select" class="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none pl-4 pr-8 cursor-pointer hover:border-gray-400" :class="{ 'border-red-600': errors.loanType }" :validateOnMount="true" :validateOnInput="true" v-model="formDataPinjaman.loanType">
                            <option v-for="loanType in dataLoanTypes" :key="loanType.key" :value="loanType.name">{{ loanType.name }}</option>
                        </Field>
                        <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2 text-gray-700">
                            <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                            </svg>
                        </div>
                    </div>
                    <div v-if="errors.loanType" class="mt-1 text-sm text-red-600">*{{errors.loanType}}</div>
                </div>
            </div>
            <div class="absolute bottom-0 mb-28 w-full px-4">
                <button type="submit" class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-1 text-white float-right" :class="[ meta.valid ? 'cursor-pointer bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700' : 'bg-gray-300 border-gray-300 cursor-not-allowed' ]" :disabled="!meta.valid"><ChevronRightIcon class="h-full" /></button>
            </div>
        </Form>
    </div>
</template>

<script>
import { Form, Field } from 'vee-validate';
import { ChevronRightIcon } from '@heroicons/vue/solid';
import { defineComponent, ref, inject, computed, reactive } from 'vue';
import Multiselect from '@vueform/multiselect'
import * as yup from 'yup';
import amalansignupApi from "@/axios/amalansignup";
import { useStore } from 'vuex';
import router from "@/router";

export default defineComponent({
    data() {
        return {
            multiselectClass: {
                container: 'relative mx-auto w-full flex items-center justify-end cursor-pointer rounded-md bg-white text-base leading-snug outline-none',
                containerOpen: 'rounded-b-none',
                containerOpenTop: 'rounded-t-none',
                singleLabel: 'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border',
                singleLabelText: 'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
                search: 'w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5',
                caret: 'bg-multiselect-caret bg-center bg-no-repeat w-6 h-6 py-px box-content mr-2 relative z-10 opacity-80 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none text-blue-900',
                caretOpen: 'rotate-180 pointer-events-auto',
                clear: 'pr-2 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80',
                clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
                dropdown: 'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-md shadow-lg',
                dropdownTop: '-translate-y-full top-px bottom-auto flex-col-reverse rounded-md shadow-lg',
                dropdownHidden: 'hidden',
                options: 'flex flex-col p-0 m-0 list-none',
                optionsTop: 'flex-col-reverse',
                option: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-1 px-3',
                optionPointed: 'text-white bg-blue-500',
                noOptions: 'py-2 px-3 text-gray-600 bg-white text-center',
                noResults: 'py-2 px-3 text-gray-600 bg-white text-center',
                spacer: 'h-10 py-px box-content',
            },
        }
    },
    components:{
        Form,
        Field,
        ChevronRightIcon,
        Multiselect,
    },
    mounted(){
        if(this.formDataPinjaman.dataBank){
            const label = this.formDataPinjaman.dataBank.split(';');
            this.$refs.multiselect.select({value: this.formDataPinjaman.dataBank, label: label[1]});
        }
    },
    setup() {
        const store = useStore();
        const formData = computed(() => store.getters['formAmalanProtect/getDataPinjaman']);
        const swal = inject("$swal");
        const formDataPinjaman = reactive({
            dataBank: formData.value ? formData.value.ref_bank_name : null,
            noPinjaman: formData.value ? formData.value.accountNumber : null,
            loanType: formData.value ? formData.value.loanType : null,
        });
        let dataLoanTypes = ref([]);
        let dataLenders = reactive([]);

        const formDataPinjamanSchema = yup.object().shape({
            ref_bank_name: yup.string().nullable().required('Silahkan pilih nama bank/institusi'),
            accountNumber: yup.string().nullable().required('Nomor pinjaman tidak boleh kosong').matches(/^[0-9x]+$/, "Nomor pinjaman harus berupa angka"),
            loanType: yup.string().nullable().required('Silahkan pilih jenis pinjaman'),
        });

        const swalErrorReload = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
            },
            buttonsStyling: false,
            title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
            html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
            showCancelButton: false,
            confirmButtonText: 'Perbaharui',
            width: '340px',
            allowOutsideClick: false,
        })

        function formSubmit(values) {
            store.dispatch('formAmalanProtect/storeDataPinjaman', values).then(() => {
                localStorage.setItem('dataPinjaman', JSON.stringify(values));
                router.push('/amalan-protect/data-pelanggaran')
            });
        }

        amalansignupApi.get('salesforce/loan-types').then(resp => {
            dataLoanTypes.value = resp.data.data;
        }).catch(() => {
            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        });

        amalansignupApi.get('salesforce/amalan-protects/bank-contacts').then(resp => {
            const respLenders = resp.data.data.records;
            for (let i in respLenders){
                dataLenders.push({ value: respLenders[i].Id + ';' + respLenders[i].Name, label: respLenders[i].Name});
            }
        }).catch(() => {
            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        });

        return {
            formDataPinjamanSchema,
            formSubmit,
            formDataPinjaman,
            formData,
            dataLoanTypes,
            dataLenders
        };
    },
})
</script>