<template>
    <div class="min-h-screen bg-white">
        <div v-if="totalHutang" class="w-full px-4">
            <div class="my-6">
                <template v-for="(dataOpp, index) in dataOpportunities" :key="index">
                    <div class="w-full rounded-lg px-4 py-3 mt-4 border border-solid" :class="[ !showMenuDocCollection ? 'bg-white' :dataOpp.Bill_Statement_1__c ? 'bg-white' : 'bg-red-50 border-red-500' ]">
                        <div class="flex items-center">
                            <div class="w-16 h-16 rounded-full flex justify-center items-center border-2 border-amalan-yellow-400">
                                <img :src="require('@/assets/img/signup/icon-jenis-pinjaman/' + getIcon(dataOpp.Loan_Type_2__c))">
                            </div>
                            <div class="ml-4">
                                <p class="text-sm sm:text-base font-bold my-0">{{ dataOpp.Name ? getBankName(dataOpp.Name, dataOpp.Loan_Type_2__c) : '-' }}</p>
                                <p class="text-xs sm:text-sm font-semibold my-0">{{ getLoanName(dataOpp.Loan_Type_2__c) }}</p>
                                <p class="text-xs sm:text-sm font-semibold my-0">{{ dataOpp.Account_Number__c }}</p>
                            </div>
                        </div>
                        <div class="flex justify-between items-center mt-2">
                            <p class="text-xs sm:text-sm">
                                <span class="font-bold">Jumlah Pinjaman</span><br>
                                {{ dataOpp.Estimated_Outstanding_Amount__c ? $filters.format_number(dataOpp.Estimated_Outstanding_Amount__c) : '-' }}
                            </p>
                            <p class="text-xs sm:text-sm text-right">
                                <span class="font-bold">Cicilan Perbulan</span><br>
                                {{ dataOpp.Old_Installment__c ? $filters.format_number(dataOpp.Old_Installment__c) : '-' }}
                            </p>
                        </div>
                        <router-link v-if="showMenuDocCollection" :to="{ name : 'doc collection - lembar tagihan detail', params: { opportunityId: dataOpp.Id }, query: { AkunPage: true } }" class="rounded-lg block w-full font-semibold text-xs text-center mt-3 py-1 cursor-pointer border border-blue-900" :class="[ dataOpp.Bill_Statement_1__c ? 'text-blue-900 bg-white hover:bg-blue-900 hover:text-white' : 'text-white bg-blue-900 hover:bg-blue-700 hover:border-blue-700' ]">
                            {{ dataOpp.Bill_Statement_1__c ? 'Lihat' : 'Unggah' }} Lembar Tagihan
                        </router-link>
                    </div>
                    <div v-if="showMenuDocCollection && !dataOpp.Bill_Statement_1__c" class="text-xs text-red-600">*Lengkapi Lembar Tagihan Anda</div>
                </template>
            </div>
            <div class="mt-6 mb-12">
                <h1 class="text-blue-900 text-sm font-bold">Alasan Kesulitan Membayar Pinjaman</h1>
                <template v-for="(fh, key) in dataFinancialHardships" :key="key">
                    <p v-if="financialHardship && financialHardship.includes(fh.value)" class="text-sm pl-2 my-2"><span class="w-4 h-4 bg-amalan-yellow-400 inline-flex justify-center items-center rounded-full mr-2"><CheckIcon class="w-3 h-3" /></span> {{ fh.name }}</p>
                </template>
            </div>
            <div class="mt-6 mb-36 w-full py-3 text-sm px-4 rounded-lg border border-solid flex justify-between">
                <span class="font-bold text-blue-900">Total Pinjaman</span>
                <span class="font-semibold text-blue-900">{{ totalHutang ? $filters.format_number(totalHutang) : '-' }}</span>
            </div>
        </div>
        <div v-if="!totalHutang && !isLoading" class="w-full h-screen m-auto px-4 flex flex-col justify-center align-middle">
            <img src="@/assets/img/clipboard.svg" class="w-2/6 -mt-36 mx-auto">
            <p class="text-center text-sm sm:text-base mt-6">Aduh, ternyata Anda belum punya daftar pinjaman :( Yuk, registrasikan daftar pinjaman Anda dengan layanan <b>Keringanan Pinjaman Full Service</b> dari amalan!</p>
            <router-link to="/sign-up/onboarding" class="w-3/6 mx-auto flex items-center justify-center py-3 bg-blue-900 hover:bg-blue-500 rounded-lg mt-6">
                <span class="text-white text-sm sm:text-base">
                    Registrasi Sekarang
                </span>
            </router-link>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import amalansignupApi from "@/axios/amalansignup";
import { CheckIcon } from '@heroicons/vue/solid'
import { listLoanType as listIcon } from '@/constants/LoanConstant'

export default {
    components: {
        CheckIcon
    },
    data(){
        return {
            isLoading: false,
            dataLoanTypes: [],
            dataFinancialHardships: [],
            financialHardship: null,
            timeToDocCollection: null,
            showMenuDocCollection: false,
            swalErrorReload: this.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })
        }
    },
    computed: {
        ...mapGetters('docCollection',{
            dataOpportunities:'getOpportunities',
            totalHutang:'getAmountLoan',
        }),
        ...mapGetters('contact',{
            dataContact:'getContact'
        }),
        ...mapGetters('contact',{
            signUpStatus: 'getSignUpStatus'
        }),
    },
    methods: {
        getIcon(loanType){
            let loanIcon = listIcon.filter(elem => elem.name == loanType);
            if (loanIcon[0]) return loanIcon[0].icon
        },
        getLoanName(oldLoan){
            let allLoan = this.dataLoanTypes;
            let newLoan = allLoan.filter(elem => elem.value == oldLoan);
            if (newLoan[0]) return newLoan[0].name
        },
        getBankName(loanName, loanType){
            return loanName.replace(" " + loanType, "")
        }
    },
    created(){
        this.timeToDocCollection = true;

        if(this.signUpStatus == null){
            this.isLoading = true;
            this.$store.dispatch('contact/requestSignUpStatus').then(() => {
                this.isLoading = false;
                if(this.signUpStatus == 200 && this.timeToDocCollection == true){
                    this.showMenuDocCollection = true;
                }else{
                    this.showMenuDocCollection = false;
                }
            });
        }else{
            if(this.signUpStatus == 200 && this.timeToDocCollection == true){
                this.showMenuDocCollection = true;
            }else{
                this.showMenuDocCollection = false;
            }
        }

        this.isLoading = true;
        amalansignupApi.get('salesforce/loan-types').then(resp => {
            this.dataLoanTypes = resp.data.data;
            amalansignupApi.get('salesforce/financial-hardships').then(resp => {
                this.dataFinancialHardships = resp.data.data;
                this.$store.dispatch('contact/requestContact').then(() => {
                    if(this.dataContact.Financial_Hardships__c !== null){
                        const splitFinancialHardships = this.dataContact.Financial_Hardships__c.split(';');
                        this.financialHardship = splitFinancialHardships;
                    }
                })

                if(!this.totalHutang){
                    this.$store.dispatch('docCollection/requestOpportunities').then(() => {
                        this.isLoading = false;
                    })
                }

                this.isLoading = false;
            }).catch(() => {
                this.swalErrorReload.fire().then((result) => {
                    if (result.isConfirmed){
                        window.location.reload();
                    }
                });
            });
        }).catch(() => {
            this.swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        });
    }
}
</script>