
<script setup>
import { onMounted, ref, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { CheckIcon } from '@heroicons/vue/solid'
import CurrencyInput from "@/components/CurrencyInputV2.vue"
import Multiselect from '@vueform/multiselect'
import { multiselectClass } from '@/constants/Multiselect'
import amalansignupApi from "@/axios/amalansignup"
import axios from "@/axios"
import useModalPopUp from '@/composables/modalPopUp'
import moment from 'moment'

const store = useStore()
const router = useRouter()
const route = useRoute()
const swal = useModalPopUp()

const lastLoanConsolidation = computed(() => store.getters['loanConsolidation/getLastLoanConsolidation'])
const getInformasiUmumForm = computed(() => store.getters['loanConsolidation/getInformasiUmumForm'])
const getDataDiriForm = computed(() => store.getters['loanConsolidation/getDataDiriForm'])
const getDataPekerjaanForm = computed(() => store.getters['loanConsolidation/getDataPekerjaanForm'])
const getDataAsetForm = computed(() => store.getters['loanConsolidation/getDataAsetForm'])
const HouseValueConfigData = computed(() => store.getters['loanConsolidation/getHouseValueConfigData'])

const DataAsetForm = reactive({
    has_house: null,
    house_is_a_mortgage: null,
    mortgage_remaining_balance: null,
    original_mortgage_disbursed_amount: null,
    mortgage_disbursement_date: null,
    mortgage_monthly_installment: null,
    estimated_house_value: null,
    estimated_house_value_source: null
})
const isLoading = ref(false)
const loadingCounterData = ref({
    startAt: 0,
    endAt: 11
})
const isHasHouse = ref(false)
const houseIsMortgage = ref(false)

const todayDate = moment().format('YYYY-MM-DD')

const DataAsetFormSchema = yup.object().shape({
    has_house: yup.string().nullable().required('Silahkan dipilih').oneOf(['yes', 'no'], 'Silahkan dipilih'),
    house_is_a_mortgage: yup.string().nullable().required('Silahkan dipilih').oneOf(['yes', 'no'], 'Silahkan dipilih'),
    mortgage_remaining_balance: yup.number('Masukan dalam bentuk angka').nullable().required('Mohon diisi').max(999999999999999),
    original_mortgage_disbursed_amount: yup.number('Masukan dalam bentuk angka').nullable().required('Mohon diisi').max(999999999999999),
    mortgage_disbursement_date: yup.date().nullable().required('Silahkan pilih tanggal'),
    mortgage_monthly_installment: yup.number('Masukan dalam bentuk angka').nullable().required('Mohon diisi').max(999999999999999),
    estimated_house_value: yup.number('Masukan dalam bentuk angka').nullable().required('Mohon diisi').max(999999999999999),
    estimated_house_value_source: yup.string().nullable().required('Silahkan pilih salah satu')
})

const timeOut = async (ms = 1000) => new Promise((resolve) => setTimeout(() => resolve(), ms))

const submitHandler = () => {
    isLoading.value = true
    store.dispatch('loanConsolidation/setDataAsetForm', {...DataAsetForm})
    axios.put('/customer/update/email', {
        name: getDataDiriForm.value.name,
        email: getDataDiriForm.value.email,
        birthdate: getDataDiriForm.value.birthdate
    }).then(async () => {
        loadingCounterData.value.startAt = 11
        loadingCounterData.value.endAt = 28
        store.dispatch('user/storeUser')
        const submittedResult = await submitRefinancing()
        if (!submittedResult) return isLoading.value = false

        loadingCounterData.value.startAt = 28
        loadingCounterData.value.endAt = 45
        await store.dispatch('loanConsolidation/requestLastLoanConsolidation')

        loadingCounterData.value.startAt = 45
        loadingCounterData.value.endAt = 78
        await timeOut(3500)

        loadingCounterData.value.startAt = 78
        loadingCounterData.value.endAt = 94
        await timeOut(3000)

        loadingCounterData.value.startAt = 94
        loadingCounterData.value.endAt = 100
        await timeOut(2500)
        isLoading.value = false
        return router.push({name:'Loan Consolidation - Registrasi - Qualification'})
    }).catch((err) => {
        isLoading.value = false
        loadingCounterData.value.startAt = 0
        loadingCounterData.value.endAt = 11
        if(err.response.data?.message?.includes('customers_email_unique')){
            return swal.swalError.fire({
                html: '<p class="text-center text-sm mt-2 text-amalan-black">Maaf, email yang kamu masukkan telah digunakan, pastikan email tersebut adalah email anda atau gunakan email lain.</p>',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
            }).then(() => router.push({ name: 'Loan Consolidation - Registrasi - Data Diri'}))
        }

        return swal.swalError.fire({
            html: '<p class="text-center text-sm mt-2 text-amalan-black">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        }).then((result) => {
            if (!result.isConfirmed) return
            return window.location = 'https://wa.me/622150933150?text=Mohon bantuan saya gagal terus dalam pengisian Data Diri pada proses Registrasi Kredit Bijak di web.amalan.com'
        })
    })
}

const submitRefinancing = async () => {
    try {
        const isHasHouse = getDataAsetForm.value.has_house === 'yes' ? true : false
        const houseIsMortgage = getDataAsetForm.value.house_is_a_mortgage === 'yes' ? true : false
        const payload = {
            employer_id : getDataPekerjaanForm.value.employer.includes('Others') ? getDataPekerjaanForm.value.employer.split('_')[0] : getDataPekerjaanForm.value.employer,
            employer_name : getDataPekerjaanForm.value.employer_name,
            employer_type : getDataPekerjaanForm.value.employer_type,
            has_a_house : isHasHouse,
            estimated_house_value : isHasHouse ? getDataAsetForm.value.estimated_house_value : 0,
            contract_type : getDataPekerjaanForm.value.employment_contract_type === 'yes' ? 'Permanent' : 'Non Permanent',
            num_of_employee : getDataPekerjaanForm.value.num_of_employee,
            income : getDataPekerjaanForm.value.income,
            house_is_mortgage : houseIsMortgage,
            estimated_house_value_source : isHasHouse ? getDataAsetForm.value.estimated_house_value_source : null,
            remaining_balance : houseIsMortgage ? getDataAsetForm.value.mortgage_remaining_balance : null,
            original_disbursed_amount : houseIsMortgage ? getDataAsetForm.value.original_mortgage_disbursed_amount : null,
            disbursement_date : houseIsMortgage ? getDataAsetForm.value.mortgage_disbursement_date : null,
            monthly_installment : houseIsMortgage ? getDataAsetForm.value.mortgage_monthly_installment : null
        }

        await amalansignupApi.post('loan-consolidation/store-refinancing', payload)
        return true
    } catch {
        loadingCounterData.value.startAt = 0
        loadingCounterData.value.endAt = 11
        swal.swalError.fire({
            html: '<p class="text-center text-sm mt-2 text-amalan-black">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        }).then((result) => {
            if (!result.isConfirmed) return
            return window.location = 'https://wa.me/622150933150?text=Mohon bantuan saya gagal terus dalam pengisian Data Pekerjaan pada proses Registrasi Kredit Bijak di web.amalan.com'
        })

        return false
    }
}

const prepopulateFormData = () => {
    DataAsetForm.has_house = getDataAsetForm.value.has_house ?? null
    DataAsetForm.estimated_house_value = getDataAsetForm.value.estimated_house_value ?? null
    DataAsetForm.house_is_a_mortgage = getDataAsetForm.value.house_is_a_mortgage ?? null
    DataAsetForm.mortgage_remaining_balance = getDataAsetForm.value.mortgage_remaining_balance ?? null
    DataAsetForm.original_mortgage_disbursed_amount = getDataAsetForm.value.original_mortgage_disbursed_amount ?? null
    DataAsetForm.mortgage_disbursement_date = getDataAsetForm.value.mortgage_disbursement_date ?? null
    DataAsetForm.mortgage_monthly_installment = getDataAsetForm.value.mortgage_monthly_installment ?? null
    DataAsetForm.estimated_house_value_source = getDataAsetForm.value.estimated_house_value_source ?? null

    if(DataAsetForm.has_house === 'yes') isHasHouse.value = true
    if(DataAsetForm.has_house === 'no') isHasHouse.value = false
    if(DataAsetForm.house_is_a_mortgage === 'yes') houseIsMortgage.value = true
    if(DataAsetForm.house_is_a_mortgage === 'no') houseIsMortgage.value = false
}

const setHasHouse = (value) => {
    DataAsetForm.has_house = value

    if(value === 'no'){
        isHasHouse.value = false
        DataAsetForm.estimated_house_value = 0
        DataAsetForm.estimated_house_value_source = 'none'
        setHouseIsMortgage('no')
    }else{
        isHasHouse.value = true
        DataAsetForm.estimated_house_value = DataAsetForm.estimated_house_value === 0 ? null : DataAsetForm.estimated_house_value
        DataAsetForm.estimated_house_value_source = null
    }
}

const setHouseIsMortgage = (value) => {
    DataAsetForm.house_is_a_mortgage = value
    houseIsMortgage.value = value === 'yes' ? true : false

    if(value === 'no'){
        DataAsetForm.mortgage_disbursement_date = DataAsetForm.mortgage_disbursement_date ?? todayDate
        DataAsetForm.mortgage_remaining_balance = DataAsetForm.mortgage_remaining_balance ?? 0
        DataAsetForm.original_mortgage_disbursed_amount = DataAsetForm.original_mortgage_disbursed_amount ?? 0
        DataAsetForm.mortgage_monthly_installment = DataAsetForm.mortgage_monthly_installment ?? 0
    }else{
        DataAsetForm.mortgage_disbursement_date = DataAsetForm.mortgage_disbursement_date === todayDate ? null : DataAsetForm.mortgage_disbursement_date
        DataAsetForm.mortgage_remaining_balance = DataAsetForm.mortgage_remaining_balance === 0 ? null : DataAsetForm.mortgage_remaining_balance
        DataAsetForm.original_mortgage_disbursed_amount = DataAsetForm.original_mortgage_disbursed_amount === 0 ? null : DataAsetForm.original_mortgage_disbursed_amount
        DataAsetForm.mortgage_monthly_installment = DataAsetForm.mortgage_monthly_installment === 0 ? null : DataAsetForm.mortgage_monthly_installment
    }
}

onMounted(() => {
    prepopulateFormData()

    if(lastLoanConsolidation.value.status === 404){
        if(!getInformasiUmumForm.value.isValid) return router.push({ name: 'Loan Consolidation - Registrasi - Informasi Umum'})
        if(!getDataDiriForm.value.isValid) return router.push({ name: 'Loan Consolidation - Registrasi - Data Diri'})
        if(!getDataPekerjaanForm.value.isValid) return router.push({ name: 'Loan Consolidation - Registrasi - Data Pekerjaan'})
        return
    }

    if(lastLoanConsolidation.value.status === 201 || lastLoanConsolidation.value.status === 202)
        return router.push({ name: 'Loan Consolidation - Registrasi - Daftar Pinjaman'})
    if(lastLoanConsolidation.value.status === 200) {
        const consolidationInitialResult = lastLoanConsolidation.value.data.data.Loan_Consolidation_Initial_Results__c ? lastLoanConsolidation.value.data.data.Loan_Consolidation_Initial_Results__c.toUpperCase() : null
        const isNotEligible = consolidationInitialResult ? consolidationInitialResult.includes('NOT ELIGIBLE') : null

        if(isNotEligible) return router.push({ name: 'Loan Consolidation - Registrasi - Qualification'})
        return router.push({ name: 'Loan Consolidation - Registrasi - Finish Page'})
    }

    return router.push({ name: 'Home'})
})
</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="router.push({name: 'Loan Consolidation - Registrasi - Data Pekerjaan'})" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold text-amalan-black text-center">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full min-h-screen bg-amalan-white px-6 pb-24">
            <div class="w-full py-8 flex justify-between items-center">
                <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                    <span class="text-sm text-amalan-blue-1 font-bold">1</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3 mx-1"></div>
                <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400">
                    <span class="text-sm font-bold text-amalan-blue-1">2</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3 mx-1"></div>
                <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400">
                    <span class="text-sm font-bold text-amalan-blue-1">3</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3 mx-1"></div>
                <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400">
                    <span class="text-sm font-bold text-amalan-blue-1">4</span>
                </div>
                <p class="flex-none text-amalan-blue-1 text-sm font-bold ml-1">Informasi Aset</p>
                <div class="bg-amalan-blue-1 h-px flex-grow mx-1"></div>
                <div class="w-6 h-6 rounded-full bg-amalan-gray-4 flex justify-center items-center flex-none ml-1">
                    <span class="text-sm text-amalan-gray-2 font-bold">5</span>
                </div>
            </div>
            <p class="text-xs text-amalan-black text-center">Jika Anda memiliki rumah, Anda berpotensi<br>untuk mendapatkan solusi yang lebih baik.<br>Mohon lengkapi pertanyaan berikut.</p>
            <Form
            :validation-schema="DataAsetFormSchema"
            v-slot="{ meta, errors }"
            @submit="submitHandler"
            >
                <div class="w-full grid grid-cols-1 gap-y-3.5 py-8 text-amalan-black">
                    <div class="w-full">
                        <p class="text-sm text-amalan-blue-1 font-bold">Apakah Anda memiliki rumah?</p>
                        <div class="w-full py-2 px-4 grid grid-cols-2 gap-x-3.5 border border-solid border-amalan-gray-4 rounded-lg mt-1.5">
                            <div @click="setHasHouse('yes')" class="w-full py-2 flex justify-start items-center gap-2 cursor-pointer">
                                <div class="w-4 h-4 p-0 rounded-full flex justify-center items-center" :class="[ DataAsetForm.has_house == 'yes' ? 'bg-amalan-yellow-400' : 'bg-amalan-gray-5']">
                                    <CheckIcon class="w-3 h-3" :class="[ DataAsetForm.has_house == 'yes' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]" />
                                </div>
                                <p class="text-amalan-black text-xs">Ya</p>
                                <Field name="has_house" type="radio" class="hidden" value="yes" :validateOnInput="true" v-model="DataAsetForm.has_house" />
                            </div>
                            <div @click="setHasHouse('no')" class="w-full py-2 flex justify-start items-center gap-2 cursor-pointer">
                                <div class="w-4 h-4 p-0 rounded-full flex justify-center items-center" :class="[ DataAsetForm.has_house == 'no' ? 'bg-amalan-yellow-400' : 'bg-amalan-gray-5']">
                                    <CheckIcon class="w-3 h-3" :class="[ DataAsetForm.has_house == 'no' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]" />
                                </div>
                                <p class="text-amalan-black text-xs">Tidak</p>
                                <Field name="has_house" type="radio" class="hidden" value="no" :validateOnInput="true" v-model="DataAsetForm.has_house" />
                            </div>
                        </div>
                    </div>
                    <div class="w-full" :class="{ 'hidden': !isHasHouse }">
                        <p class="text-sm text-amalan-blue-1 font-bold">Apakah rumah Anda masih dalam KPR?</p>
                        <div class="w-full py-2 px-4 grid grid-cols-2 gap-x-3.5 border border-solid border-amalan-gray-4 rounded-lg mt-1.5">
                            <div @click="setHouseIsMortgage('yes')" class="w-full py-2 flex justify-start items-center gap-2 cursor-pointer">
                                <div class="w-4 h-4 p-0 rounded-full flex justify-center items-center" :class="[ DataAsetForm.house_is_a_mortgage == 'yes' ? 'bg-amalan-yellow-400' : 'bg-amalan-gray-5']">
                                    <CheckIcon class="w-3 h-3" :class="[ DataAsetForm.house_is_a_mortgage == 'yes' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]" />
                                </div>
                                <p class="text-amalan-black text-xs">Ya</p>
                                <Field name="house_is_a_mortgage" type="radio" class="hidden" value="yes" :validateOnInput="true" v-model="DataAsetForm.house_is_a_mortgage" />
                            </div>
                            <div @click="setHouseIsMortgage('no')" class="w-full py-2 flex justify-start items-center gap-2 cursor-pointer">
                                <div class="w-4 h-4 p-0 rounded-full flex justify-center items-center" :class="[ DataAsetForm.house_is_a_mortgage == 'no' ? 'bg-amalan-yellow-400' : 'bg-amalan-gray-5']">
                                    <CheckIcon class="w-3 h-3" :class="[ DataAsetForm.house_is_a_mortgage == 'no' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]" />
                                </div>
                                <p class="text-amalan-black text-xs">Tidak</p>
                                <Field name="house_is_a_mortgage" type="radio" class="hidden" value="no" :validateOnInput="true" v-model="DataAsetForm.house_is_a_mortgage" />
                            </div>
                        </div>
                    </div>
                    <div class="w-full" :class="{ 'hidden': !houseIsMortgage }">
                        <label class="text-sm text-amalan-blue-1 font-bold">Berapa sisa saldo KPR Anda?</label>
                        <div class="flex flex-col relative w-full mt-1.5">
                            <CurrencyInput 
                            v-model="DataAsetForm.mortgage_remaining_balance" 
                            :placeholder="'Rp'"
                            :disabled="!houseIsMortgage"
                            :propsClass="errors.mortgage_remaining_balance ? 'border-amalan-red rounded-lg' : 'border-amalan-gray-4 rounded-lg'"
                            :options="{
                                currency: 'IDR',
                                currencyDisplay: 'narrowSymbol',
                                hideCurrencySymbolOnFocus: false,
                                hideGroupingSeparatorOnFocus: false,
                                hideNegligibleDecimalDigitsOnFocus: false,
                                autoDecimalDigits: false,
                                autoSign: false,
                                useGrouping: true,
                                accountingSign: true
                            }" />
                            <Field name="mortgage_remaining_balance" type="text" :validateOnInput="true" class="hidden" v-model="DataAsetForm.mortgage_remaining_balance" />
                            <div v-if="errors.mortgage_remaining_balance" class="mt-0.5 text-amalan-red text-2xs">{{errors.mortgage_remaining_balance}}</div>
                        </div>
                    </div>
                    <div class="w-full" :class="{ 'hidden': !houseIsMortgage }">
                        <label class="text-sm text-amalan-blue-1 font-bold">Berapa saldo awal KPR Anda?</label>
                        <div class="flex flex-col relative w-full mt-1.5">
                            <CurrencyInput 
                            v-model="DataAsetForm.original_mortgage_disbursed_amount" 
                            :placeholder="'Rp'"
                            :disabled="!houseIsMortgage"
                            :propsClass="errors.original_mortgage_disbursed_amount ? 'border-amalan-red rounded-lg' : 'border-amalan-gray-4 rounded-lg'"
                            :options="{
                                currency: 'IDR',
                                currencyDisplay: 'narrowSymbol',
                                hideCurrencySymbolOnFocus: false,
                                hideGroupingSeparatorOnFocus: false,
                                hideNegligibleDecimalDigitsOnFocus: false,
                                autoDecimalDigits: false,
                                autoSign: false,
                                useGrouping: true,
                                accountingSign: true
                            }" />
                            <Field name="original_mortgage_disbursed_amount" type="text" :validateOnInput="true" class="hidden" v-model="DataAsetForm.original_mortgage_disbursed_amount" />
                            <div v-if="errors.original_mortgage_disbursed_amount" class="mt-0.5 text-amalan-red text-2xs">{{errors.original_mortgage_disbursed_amount}}</div>
                        </div>
                    </div>
                    <div class="w-full" :class="{ 'hidden': !houseIsMortgage }">
                        <label class="text-sm text-amalan-blue-1 font-bold">Kapan KPR Anda dimulai?</label>
                        <Field name="mortgage_disbursement_date" v-model="DataAsetForm.mortgage_disbursement_date" :validateOnInput="true" type="date" class="block w-full text-xs px-4 py-2.5 mt-1.5 bg-amalan-white border rounded-lg appearance-none focus:outline-none" :class="[errors.mortgage_disbursement_date ? 'border-amalan-red' : 'border-amalan-gray-4']" :disabled="!houseIsMortgage" />
                        <div v-if="errors.mortgage_disbursement_date" class="mt-1 text-2xs text-amalan-red">{{errors.mortgage_disbursement_date}}</div>
                    </div>
                    <div class="w-full" :class="{ 'hidden': !houseIsMortgage }">
                        <label class="text-sm text-amalan-blue-1 font-bold">Berapa cicilan KPR Anda per bulan?</label>
                        <div class="flex flex-col relative w-full mt-1.5">
                            <CurrencyInput 
                            v-model="DataAsetForm.mortgage_monthly_installment" 
                            :placeholder="'Rp'"
                            :disabled="!houseIsMortgage"
                            :propsClass="errors.mortgage_monthly_installment ? 'border-amalan-red rounded-lg' : 'border-amalan-gray-4 rounded-lg'"
                            :options="{
                                currency: 'IDR',
                                currencyDisplay: 'narrowSymbol',
                                hideCurrencySymbolOnFocus: false,
                                hideGroupingSeparatorOnFocus: false,
                                hideNegligibleDecimalDigitsOnFocus: false,
                                autoDecimalDigits: false,
                                autoSign: false,
                                useGrouping: true,
                                accountingSign: true
                            }" />
                            <Field name="mortgage_monthly_installment" type="text" :validateOnInput="true" class="hidden" v-model="DataAsetForm.mortgage_monthly_installment" />
                            <div v-if="errors.mortgage_monthly_installment" class="mt-0.5 text-amalan-red text-2xs">{{errors.mortgage_monthly_installment}}</div>
                        </div>
                    </div>
                    <div class="w-full" :class="{ 'hidden': !isHasHouse }">
                        <label class="text-sm text-amalan-blue-1 font-bold">Berapa kisaran harga rumah Anda?</label>
                        <div class="flex flex-col relative w-full mt-1.5">
                            <CurrencyInput 
                            v-model="DataAsetForm.estimated_house_value" 
                            :placeholder="'Rp'"
                            :propsClass="errors.estimated_house_value ? 'border-amalan-red rounded-lg' : 'border-amalan-gray-4 rounded-lg'"
                            :options="{
                                currency: 'IDR',
                                currencyDisplay: 'narrowSymbol',
                                hideCurrencySymbolOnFocus: false,
                                hideGroupingSeparatorOnFocus: false,
                                hideNegligibleDecimalDigitsOnFocus: false,
                                autoDecimalDigits: false,
                                autoSign: false,
                                useGrouping: true,
                                accountingSign: true
                            }" />
                            <Field name="estimated_house_value" type="text" :validateOnInput="true" class="hidden" v-model="DataAsetForm.estimated_house_value" />
                            <div v-if="errors.estimated_house_value" class="mt-0.5 text-amalan-red text-2xs">{{errors.estimated_house_value}}</div>
                        </div>
                    </div>
                    <div class="w-full" :class="{ 'hidden': !isHasHouse }">
                        <label class="text-sm text-amalan-blue-1 font-bold">Bagaimana Anda menentukan harga rumah Anda?</label>
                        <div class="flex items-center relative w-full mt-1.5">
                            <div class="w-full border rounded-lg py-1" :class="[ errors.estimated_house_value_source ? 'border-amalan-red' : 'border-amalan-gray-4' ]">
                                <Multiselect
                                    ref="multiselect"
                                    v-model="DataAsetForm.estimated_house_value_source"
                                    :noResultsText="'Data tidak ditemukan.'"
                                    :noOptionsText="'Sedang memuat data...'"
                                    :searchable="true"
                                    :options="HouseValueConfigData"
                                    :classes="multiselectClass"
                                    placeholder="Pilih Salah Satu"
                                />
                            </div>
                            <Field name="estimated_house_value_source" type="text" class="hidden" :validateOnInput="true" v-model="DataAsetForm.estimated_house_value_source" />
                        </div>
                        <div v-if="errors.estimated_house_value_source" class="mt-0.5 text-amalan-red text-2xs">{{errors.estimated_house_value_source}}</div>
                    </div>
                </div>
                <button type="submit" class="w-full mt-8 rounded-amalan-lg font-bold text-center text-sm p-3" :class="[ meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!meta.valid">Selanjutnya</button>
            </Form>
            <loading-overlay-with-progress v-if="isLoading" :counter-start-at="loadingCounterData.startAt" :counter-end-at="loadingCounterData.endAt" />
        </div>
    </div>
</template>