<script setup>
import NavbarWithBackV2 from '@/components/NavbarWithBackV2.vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, ref, onMounted } from 'vue'
import opportunityHelper from '@/helpers/opportunity-helper'
import OpportunityCard from '@/components/Card/OpportunityCard.vue'
import ThanksModalWithMarkOption from '@/components/Modal/ThanksModalWithMarkOption.vue'

const router = useRouter()
const route = useRoute()
const store = useStore()

const modalOptions = {
    amalan_recomendation: {
        mark: 'x',
        content: 'Mohon maaf, hasil negosiasi Anda sudah didapatkan dan sedang dalam peninjauan oleh tim kami.<br><br>Anda tidak diperkenankan untuk mengubah target pelunasan saat ini',
        withButton: true,
        buttonText: 'Kembali'
    },
    customer_approval: {
        mark: 'x',
        content: 'Mohon maaf, hasil negosiasi Anda sudah didapatkan untuk data pinjaman Ini.<br><br>Jika Anda ingin mengubah target pelunasan, silahkan Anda konfirmasi terlebih dahulu melalui tombol dibawah ini.',
        withButton: true,
        buttonText: 'Lihat Hasil Negosiasi'
    },
}

const currentTargetPelunasan = computed(() => store.getters['targetPelunasan/getCurrentTargetPelunasan'])
const newTargetPelunasan = computed(() => store.getters['targetPelunasan/getNewTargetPelunasan'])
const alasanPengajuanOption = computed(() => store.getters['targetPelunasan/getAlasanPengajuan'])

const showModal = ref(false)
const modalConfig = ref(modalOptions.amalan_recomendation)
const selectedId = ref(null)

const replaceCurrentTargetPelunasan = (Id, case_progress) => {
    if(case_progress.toUpperCase().includes('AMALAN RECOMMENDATION')){
        modalConfig.value = modalOptions.amalan_recomendation
        selectedId.value = null
        return showModal.value = true
    }
    
    if(case_progress.toUpperCase().includes('CUSTOMER APPROVAL')){
        modalConfig.value = modalOptions.customer_approval
        selectedId.value = Id
        return showModal.value = true
    }

    return router.push({ name: 'Negotiation Process - Target Pelunasan - Add', params: { opportunityId: Id } })
}

const goToAddTargetPelunasan = () => router.push({ name: 'Negotiation Process - Target Pelunasan - Add' })

const modalOnAction = () => {
    showModal.value = false
    if (
        modalConfig.value.buttonText !== 'Kembali' &&
        selectedId.value
    ) router.push({ name: 'Negotiation Process - Result - Detail', params: { negotiationId: selectedId.value } })
}

onMounted(() => {
    if(!newTargetPelunasan.value.isFetched) store.dispatch('targetPelunasan/setNewTargetPelunasan')
    if(alasanPengajuanOption.value.data.length === 0) store.dispatch('targetPelunasan/setAlasanPengajuan')
    store.dispatch('targetPelunasan/resetTargetPelunasanForm')

    const approvalId = localStorage.getItem('negotiation-approval-id')
    if(!approvalId) return

    localStorage.removeItem('negotiation-approval-id')
    return router.push({ name: 'Negotiation Process - Result - Detail', params: { negotiationId: approvalId } })
})
</script>

<template>
    <div class="min-h-screen bg-amalan-white relative">
        <NavbarWithBackV2 :title="route.meta.title" @back-button="router.push({ name: 'Negotiation Process - Home' })" />
        <div class="w-full pt-8 pb-20 px-6 bg-amalan-white">
            <p class="text-center text-amalan-blue-1 font-bold text-sm">Target Pelunasan Saat Ini</p>
            <p class="text-center text-amalan-black text-xs sm:text-sm mt-1">Anda dapat mengubah target pelunasan saat ini<br>dengan memilih salah satu data di bawah</p>
            <div v-if="currentTargetPelunasan.data.length > 0" class="w-full grid grid-cols-1 gap-y-3.5 mt-3.5">
                <OpportunityCard
                    v-for="(data, index) in currentTargetPelunasan.data"
                    :key="index"
                    :with-action="true"
                    :bank-name="data.Name ? opportunityHelper.getBankName(data.Name, data.Loan_Type_2__c) : '-'"
                    :loan-type="data.Loan_Type_2__c ? opportunityHelper.getLoanTitle(data.Loan_Type_2__c) : '-'"
                    :outstanding="data.Estimated_Outstanding_Amount__c ? $filters.format_number(data.Estimated_Outstanding_Amount__c) : '-'"
                    :image-source="require('@/assets/img/bank-lender-icon/' + opportunityHelper.getIcon(data.Name, data.Loan_Type_2__c))"
                    @on-action="replaceCurrentTargetPelunasan(data.Id, data.Case_Progress__c)"
                />
            </div>
            <div v-else class="w-full px-6 flex flex-col justify-center items-center pt-22">
                <img src="@/assets/img/clipboard-v2.svg" class="w-40 mx-auto">
                <p class="text-center text-xs mt-4">Mohon maaf, saat ini Anda belum memiliki<br>target pelunasan yang aktif.</p>
            </div>
            <div class="w-full h-px flex justify-center items-center text-xs bg-amalan-gray-3 text-amalan-gray-3 my-8">
                <div class="bg-amalan-white px-2 uppercase">ATAU</div>
            </div>
            <button @click="goToAddTargetPelunasan" class="w-full py-3 text-center text-sm font-bold bg-amalan-blue-1 text-amalan-white border border-amalan-blue-1 rounded-amalan-lg shadow-amalan-md hover:bg-amalan-blue-2">Ajukan Target Pelunasan Baru</button>
            <div class="w-full flex justify-center items-center mt-8">
                <span @click="router.push({name: 'Loan Management - Loan Progress - Home'})" class="font-bold text-sm text-amalan-blue-1 py-1 px-2.5 underline cursor-pointer">Lihat Progres Pengajuan</span>
            </div>
        </div>
        <ThanksModalWithMarkOption :visible="showModal" :content="modalConfig.content" :animation-mark="modalConfig.mark" :with-button="modalConfig.withButton" :button-text="modalConfig.buttonText" @action="modalOnAction" />
        <loading-overlay v-if="currentTargetPelunasan.isFetching || newTargetPelunasan.isFetching" />
    </div>
</template>