import axios from "../../axios";

const state = () => ({
    availableDate : null,
    availableProviders : null,
    dataProviders: null,
    bookedData: {
        providerId: null,
        providerName: null,
        providerEmail: null,
        serviceId: null,
        startDate: null,
        startTime: null,
        startTimeIndex: null,
        selectedIndex: null,
        selectedProvidersIndex: null
    }
});

const getters = {
    getAvailableDate(state) {
        return state.availableDate
    },
    getBookedData(state) {
        return state.bookedData
    },
    getAvailableProviders(state){
        return state.availableProviders
    },
    getDataProviders(state){
        return state.dataProviders
    }
};

const actions = {
    async requestAvailableDate({ commit }) {
        axios.get('/simplybook/services')
        .then((resp) => {
            let serviceId = ''
            for (let i in resp.data.data.data){
                if(resp.data.data.data[i].name === 'Credit Checkup'){
                    serviceId = resp.data.data.data[i].id
                }
            }
            commit("setServiceId", serviceId)
            axios.get(`/simplybook/providers?service_id=${serviceId}`)
            .then((resp) => {
                commit("requestdataProviders", resp.data.data.data)
            })
            axios.get(`/simplybook/slots?days=20&service_id=${serviceId}`)
            .then((resp) => {
                commit("requestAvailableDate", resp.data.data)
            })
        })
    },
    requestAvailableProviders({ commit }, payload){
        commit("requestAvailableProviders", payload)
    },
    setStartDate({ commit }, payload){
        commit("setStartDate", payload)
    },
    setStartTime({ commit }, payload){
        commit("setStartTime", payload)
    },
    setSelectedProviders({ commit }, payload){
        commit("setSelectedProviders", payload)
    },
    removeBookedTimeAndProviders({ commit }){
        commit("removeBookedTimeAndProviders")
    },
};

const mutations = {
    requestAvailableDate(state, payload) {
        state.availableDate = payload
    },
    requestdataProviders(state, payload) {
        state.dataProviders = payload
    },
    requestAvailableProviders(state, payload){
        state.availableProviders = payload
    },
    setServiceId(state, payload){
        state.bookedData.serviceId = payload
    },
    setStartDate(state, payload){
        state.bookedData.startDate = payload.startDate
        state.bookedData.selectedIndex = payload.selectedIndex
    },
    setSelectedProviders(state, payload){
        state.bookedData.providerId = payload.providerId
        state.bookedData.providerName = payload.providerName
        state.bookedData.providerEmail = payload.providerEmail
        state.bookedData.selectedProvidersIndex = payload.selectedProvidersIndex
    },
    setStartTime(state, payload){
        state.bookedData.startTime = payload.startTime
        state.bookedData.startTimeIndex = payload.startTimeIndex
    },
    removeBookedTimeAndProviders(state){
        state.bookedData.providerId = null
        state.bookedData.providerName = null
        state.bookedData.providerEmail = null
        state.bookedData.selectedProvidersIndex = null
        state.bookedData.startTime = null
        state.bookedData.startTimeIndex = null
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
