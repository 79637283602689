<template>
    <div>
        <loading-overlay />
    </div>
</template>

<script>

export default {
    beforeMount(){
        localStorage.setItem('document-collection', true);

        this.$router.push({ name: 'Login' });
    }
}
</script>