<template>
    <div></div>
</template>
<script>
export default {
    created () {
        // if(this.$route.query.referral){
        //     const payload = {
        //         promoType: 'referral',
        //         promoCode: this.$route.query.referral
        //     };
        //     this.$store.dispatch('promo/savePromo', payload);
        // }
        
        // this.$router.push({name : 'Home'});
    },
}
</script>