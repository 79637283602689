import documentApi from "../../axios/docCollection";
import vm from "../../main";

const state = () => ({
    opportunities: null,
    amountLoan: null,
    billStatements: null,
    billCompleted: null,
    docWajib: null,
    docCompleted: null,
    docCompletedFile: null,
    listDokumenWajib: {
        slipGaji: null,
        slipGajiFile: null,
        inability: null,
        inabilityFile: null,
        resign: null,
        resignFile: null
    },
    timeToDocCollection: true
});

const getters = {
    getOpportunities(state) {
        return state.opportunities;
    },
    getAmountLoan(state) {
        return state.amountLoan;
    },
    getTotalAmountLoan(state) {
        if(!state.opportunities) return 0
        let result = 0
        state.opportunities.forEach((value) => result += value.Estimated_Outstanding_Amount__c)
        return result
    },
    getbillStatements(state) {
        return state.billStatements;
    },
    getbillStatus(state) {
        return state.billCompleted;
    },
    getDocWajib(state) {
        return state.docWajib;
    },
    getDocStatus(state) {
        return state.docCompleted;
    },
    getDocStatusFile(state) {
        return state.docCompletedFile;
    },
    getAllDocStatus(state) {
        return state.listDokumenWajib;
    },
    getTimeToDocCollection(state) {
        return state.timeToDocCollection;
    }
};

const actions = {
    requestOpportunities({ commit }) {
        return documentApi.get('salesforce/opportunities')
        .then( resp => {
            commit("requestOpportunities", resp.data);
            commit("calculateAmountLoan", resp.data.data);
        })
        .catch(() => {
            const swalErrorReload = vm.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })

            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        })
    },
    requestListBillStatement({ commit }, payload) {
        return documentApi.get('salesforce/opportunities/' + payload + '/bill-statements')
        .then( resp => {
            commit("resetListBillStatement");
            commit("requestListBillStatement", resp.data);
        })
        .catch(() => {
            commit("resetListBillStatement");
            const swalErrorReload = vm.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })

            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        })
    },
    requestDocWajib({ commit }) {
        commit("resetDocWajib");
        return documentApi.get('salesforce/contacts/documents')
        .then( resp => {
            commit("requestDocWajib", resp.data);
        })
        .catch(() => {
            const swalErrorReload = vm.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })

            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        })
    },
    checkTimeToDocumentCollection({ commit }){
        commit("checkTimeToDocumentCollection");
    },
    editOpportunityState({ commit }, payload) {
        commit("editOpportunityState", payload)
    }
};

const mutations = {
    requestOpportunities(state, payload){
        const data = payload.data;
        if(data && data.length > 0){
            let unfilledData = [];
            let filledData = [];
            for(let i in data){
                if(data[i].Bill_Statement_1__c){
                    filledData.push(data[i]);
                }else{
                    unfilledData.push(data[i]);
                }
            }

            state.billCompleted = unfilledData.length > 0 ? false : true;
            Array.prototype.push.apply(unfilledData,filledData);   
            state.opportunities = unfilledData;
        }
    },
    calculateAmountLoan(state, payload) {
        let initialLoan = 0;
        for (let i in payload){
            if(payload[i].Estimated_Outstanding_Amount__c){
                initialLoan += payload[i].Estimated_Outstanding_Amount__c;
            }
        }
        if(initialLoan > 0){
            state.amountLoan = initialLoan;
        }else{
            state.amountLoan = null;
        }
    },
    requestListBillStatement(state, payload){
        state.billStatements = payload.data;
        state.billStatements.reverse();
    },
    resetListBillStatement(state){
        state.billStatements = [];
    },
    requestDocWajib(state, payload){
        let data = payload.data;
        state.docWajib = data;
        for(let i in data){
            if(data[i].Document_Type__c == 'Family Card'){
                state.docCompleted = true;
                state.docCompletedFile = data[i];
            }else if(data[i].Document_Type__c == 'Pay Slip'){
                state.listDokumenWajib.slipGaji = true;
                state.listDokumenWajib.slipGajiFile = data[i];
            }else if(data[i].Document_Type__c == 'Certificate Inability'){
                state.listDokumenWajib.inability = true;
                state.listDokumenWajib.inabilityFile = data[i];
            }else if(data[i].Document_Type__c == 'Resign Letter'){
                state.listDokumenWajib.resign = true;
                state.listDokumenWajib.resignFile = data[i];
            }
        }

        if(state.docCompleted !== true){
            state.docCompleted = false;
        }
        if(state.listDokumenWajib.slipGaji !== true){
            state.listDokumenWajib.slipGaji = false;
        }
        if(state.listDokumenWajib.inability !== true){
            state.listDokumenWajib.inability = false;
        }
        if(state.listDokumenWajib.resign !== true){
            state.listDokumenWajib.resign = false;
        }
    },
    resetDocWajib(state){
        state.docWajib = null;
        state.docCompleted = null;
        state.docCompletedFile = null;
        state.listDokumenWajib.slipGaji = null;
        state.listDokumenWajib.slipGajiFile = null;
        state.listDokumenWajib.inability = null;
        state.listDokumenWajib.inabilityFile = null;
        state.listDokumenWajib.resign = null;
        state.listDokumenWajib.resignFile = null;
    },
    checkTimeToDocumentCollection(state){
        // state.timeToDocCollection = localStorage.getItem('document-collection') ? true : false
        state.timeToDocCollection = true
    },
    editOpportunityState(state, payload){
        const findOpportDataIndex = state.opportunities.findIndex((value) => value.Id === payload.opportId)
        if(findOpportDataIndex < 0) return

        const element = state.opportunities.splice(findOpportDataIndex, 1)
        element[0].Last_Estimated_Outstanding_Amount__c = element[0].Last_Estimated_Outstanding_Amount__c ?? element[0].Estimated_Outstanding_Amount__c
        element[0].Estimated_Outstanding_Amount__c = payload.outstandingAmount
        element[0].Month_Overdue_input_by_Lead__c = payload.overdue
        element[0].Last_Payment_Date__c = payload.lastPaymentDate
        element[0].Account_Number__c = payload.accNumber
        state.opportunities.unshift(element[0])
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
