<script setup>
import { defineProps, ref } from 'vue'
import { vElementHover } from '@vueuse/components'
import { onClickOutside } from '@vueuse/core'

defineProps({
    parentTag: String,
    parentClass: String,
    parentStyle: String,
    title: String,
    content: String
})

const parentComponent = ref(null)
const isShow = ref(false)
const isHover = ref(false)

const toggleTooltip = () => isShow.value = !isShow.value

const onHover = (state) => {
    if(state) return isHover.value = true
    return isHover.value = false
}

onClickOutside(parentComponent, () => isShow.value = false)

</script>

<template>
    <component :is="parentTag" :class="parentClass" :style="parentStyle" @click="toggleTooltip" v-element-hover="onHover" ref="parentComponent">
        <Transition name="fade-fast">
            <div v-show="isShow || isHover" class="absolute bg-transparent z-10 bottom-13">
                <div class="py-1 pl-1 pr-4 bg-amalan-white text-amalan-black shadow-amalan-sm rounded triangle">
                    <p class="text-3xs font-bold">{{ title }}</p>
                    <p class="text-3xs">{{ content }}</p>
                </div>
            </div>
        </Transition>
    </component>
</template>

<style scoped>
.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.3s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.3s ease
}

.triangle {
    position: relative;
    box-shadow: 0px 0px 6px 0 rgba(0, 0, 0, 0.2);
}

.triangle::after{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: -10px;
    left: 10%;
    box-sizing: border-box;

    border-style: solid;
    border-width: 5px;
    border-color: transparent transparent #FCFCFC #FCFCFC;
    
    transform-origin: 0 0;
    transform: rotate(-45deg);
    
    box-shadow: -3px 3px 6px 0 rgba(0, 0, 0, 0.15);
}
</style>