<template>
    <div class="pt-4 min-h-screen h-auto relative">
        <div class="mt-2 pb-12 w-full px-4">
            <div class="flex items-center">
                <div class="flex items-center text-blue-900 relative">
                    <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                        <img src="@/assets/img/signup/data-pinjaman-active.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Data<br>Pinjaman</p></div>
                </div>
                <div class="flex-auto border-t-2 border-blue-900"></div>
                <div class="flex items-center text-blue-900 relative">
                    <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                        <img src="@/assets/img/protect/data-pelanggaran-active.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Data<br>Pelanggaran</p></div>
                </div>
                <div class="flex-auto border-t-2 border-blue-900"></div>
                <div class="flex items-center text-blue-900 relative">
                    <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                        <img src="@/assets/img/signup/tanda-tangan-active.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Tanda<br>Tangan</p></div>
                </div>
                <div class="flex-auto border-t-2 border-blue-900"></div>
                <div class="flex items-center text-blue-900 relative">
                    <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                        <img src="@/assets/img/signup/pembayaran-active.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Pembayaran<br>Layanan</p></div>
                </div>
                <div class="flex-auto border-t-2 border-gray-300"></div>
                <div class="flex items-center text-gray-500 relative">
                    <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                        <img src="@/assets/img/verified.svg" class="w-6 h-6">
                    </div>
                    <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Konfirmasi<br>Layanan</p></div>
                </div>
            </div>
        </div>
        <loading-overlay v-if="isLoading" />
        <div v-if="isPaymentPage" class="fixed inset-0 z-50 bg-blue-100 min-h-screen w-screen flex items-center justify-center">
            <div class="max-w-sceen h-full overflow-visible">
                <iframe class="w-screen h-5/6 border-b-4 bg-white" :src="protect.data.url" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                <div class="absolute bottom-0 pb-10 w-full flex justify-center bg-blue-100">
                    <button class="border text-white px-6 bg-red-500 border-red-500 hover:bg-red-600 hover:border-red-600 rounded-lg py-2 text-sm font-semibold" @click="checkSignProcess">
                        Konfirmasi Pembayaran
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import amalansignupApi from "@/axios/amalansignup";

export default {
    data(){
        return {
            isLoading: false,
            isPaymentPage: false,
            swalErrorReload: this.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })
        }
    },
    computed: {
        ...mapGetters('formAmalanProtect',{
            protectStatus:'getProtectStatus',
            protect:'getProtect',
        })
    },
    methods: {
        checkSignProcess(){
            this.isLoading = true;
            this.isPaymentPage = false;
            this.$store.dispatch('formAmalanProtect/requestProtectStatus', this.$route.params.idBank).then(() => {
                if(this.protectStatus == 200){
                    this.isLoading = false;
                    this.$router.push('/amalan-protect/konfirmasi');
                }else if(this.protectStatus == 202){
                    if(this.protect.data && this.protect.data.url){
                        this.isLoading = false;
                        this.isPaymentPage = true;
                    }else{
                        this.reqNewInvoice();
                    }
                }else if(this.protectStatus == 201){
                    this.$router.push('/amalan-protect/tanda-tangan/' + this.$route.params.idBank);
                }else{
                    this.$router.push('/amalan-protect/data-pinjaman');
                }
            });
        },
        reqNewInvoice(){
            this.isLoading = true;
            let data = {
                bankComplaintId: this.$route.params.idBank
            }
            amalansignupApi.post('salesforce/amalan-protects/xendit', data)
            .then((resp) => {
                this.$store.dispatch('formAmalanProtect/requestProtectStatus', this.$route.params.idBank).then(() => {
                    if(this.protectStatus == 200){
                        this.$router.push('/amalan-protect/konfirmasi');
                    }

                    if(resp.status == 200){
                        this.$router.push('/amalan-protect/konfirmasi');
                    }

                    if(resp.status == 201){
                        this.isLoading = false;
                        this.isPaymentPage = true;
                    }
                    
                });
            })
            .catch(() => {
                this.isLoading = false;
                this.swalErrorReload.fire().then((result) => {
                    if (result.isConfirmed){
                        window.location.reload();
                    }
                });
            })
        }
    },
    created(){
        this.checkSignProcess();
    }
}
</script>