import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "./axios"
import VueAxios from "vue-axios"
import VueSweetalert2 from "vue-sweetalert2"
import VueGtag from "vue-gtag";
import { createGtm } from '@gtm-support/vue-gtm';

import LoadingOverlay from './components/LoadingOverlay.vue'
import LoadingOverlayWithMessage from './components/LoadingOverlayWithMessage.vue'
import LoadingOverlayCheckId from './components/LoadingOverlayCheckId.vue'
import LoadingOverlayCustomMessage from './components/LoadingOverlayCustomMessage.vue'
import LoadingData from './components/LoadingData.vue'
import LoadingDataWithoutMargin from './components/LoadingDataWithoutMargin.vue'
import LoadingOverlayWithProgress from './components/LoadingOverlayWithProgress.vue'
import moment from 'moment'
require("moment/min/locales.min")
moment.locale('id')

import dateFormatHelper from './helpers/date-format-helper'
import numberFormatHelper from './helpers/number-format-helper'

import './assets/css/index.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'viewerjs/dist/viewer.css'

const app = createApp(App);

app.config.globalProperties.$filters = {
    ...numberFormatHelper,
    ...dateFormatHelper
}

app.config.globalProperties.$amazyncKey=process.env.VUE_APP_AMAZYNC_PRIVATE_KEY

app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.use(VueGtag, {
    config: { id: process.env.VUE_APP_GA_TRACKER_ID },
    onBeforeTrack(){
        (function(funnel) {
            var deferredEvents = [];
            window.funnelytics = {
                events: {trigger: function (name, attributes, callback, opts) {deferredEvents.push({name: name, attributes: attributes, callback: callback, opts: opts});}}
            };
            var insert = document.getElementsByTagName('script')[0],script = document.createElement('script');
            script.addEventListener('load', function() {
                window.funnelytics.init(funnel, false, deferredEvents);
            });
            script.src = 'https://cdn.funnelytics.io/track.js';
            script.type = 'text/javascript';
            script.async = true;insert.parentNode.insertBefore(script, insert);
        })('407f1b69-d700-4acf-b83c-f76c2a7cf4ec');
    }
}, router);

app.use(
    createGtm({
        id: 'GTM-5DSS4VN',
        defer: false,
        compatibility: false,
        enabled: true,
        debug: false,
        loadScript: true,
        vueRouter: router,
        trackOnNextTick: false,
    }),
);

app.component('LoadingOverlay', LoadingOverlay);
app.component('LoadingOverlayWithMessage', LoadingOverlayWithMessage);
app.component('LoadingOverlayCheckId', LoadingOverlayCheckId);
app.component('LoadingData', LoadingData);
app.component('LoadingDataWithoutMargin', LoadingDataWithoutMargin);
app.component('LoadingOverlayCustomMessage', LoadingOverlayCustomMessage);
app.component('LoadingOverlayWithProgress', LoadingOverlayWithProgress);

app.provide('Filters', app.config.globalProperties.$filters)

const vm = app.mount("#app");

export default vm;
