<script setup>
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { onMounted } from 'vue'
import DoneBanner from '@/components/icon/DoneBanner'
import { useChangeCase } from '@vueuse/integrations/useChangeCase'

const router = useRouter()
const route = useRoute()
const store = useStore()

const productName = useChangeCase(route.params.catalog.replace('-', ' '), 'capitalCase')

onMounted(() => {
    store.dispatch('creditCheckup/requestCBResult')
    store.dispatch('catalog/requestCatalog')
})
</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <div class="h-5 w-5"></div>
                    <span class="text-sm font-bold mt-0 text-amalan-black">{{ productName }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full min-h-screen px-6 -mt-14 flex flex-col justify-center items-center">
            <DoneBanner />
            <p class="text-center text-xs text-amalan-black mt-7">Pembelian produk <i>{{ productName }}</i> Anda<br>telah berhasil dilakukan.<br><br>Silahkan cek WhatApp atau Email Anda.<br>Dokumen <i>{{ productName }}</i> Anda akan dikirim<br>dengan format PDF.</p>
            <button @click="router.push({ name: 'Home' })" class="w-full flex items-center justify-center py-3 px-2 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg mt-7">
                <span class="text-amalan-white text-sm font-bold">
                    Kembali ke Beranda
                </span>
            </button>
        </div>
        <!-- <div class="pt-4 min-h-screen h-auto relative bg-amalan-white">
            <div class="mt-2 pb-12 w-full px-4">
                <div class="flex items-center">
                    <div class="flex items-center text-amalan-blue-1 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/credit-checkup/booking-date-active.svg" class="w-6 h-6">
                        </div>
                        <div v-if="route.params.catalog == 'credit-checkup'" class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-amalan-blue-1"><p>Booking<br>Konsultasi</p></div>
                        <div v-else class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-amalan-blue-1"><p>Ringkasan<br>Layanan</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-amalan-blue-1"></div>
                    <div class="flex items-center text-amalan-blue-1 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/credit-checkup/pembayaran-layanan-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-amalan-blue-1"><p>Pembayaran<br>Layanan</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-amalan-blue-1"></div>
                    <div class="flex items-center text-amalan-blue-1 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/verified-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-amalan-blue-1"><p>Konfirmasi<br>Layanan</p></div>
                    </div>
                </div>
            </div>
            <div class="w-full px-4 pt-16 pb-6 text-center">
                <img src="@/assets/img/lead-qualification/qualified-icon.svg" class="mx-auto">
                <p v-if="route.params.catalog == 'credit-checkup'" class="my-8 text-sm text-amalan-black">
                    Pembelian layananmu sudah sukses dilakukan. Terima kasih telah mempercayai amalan international. <br><br> Selanjutnya konsultan kami akan segera menghubungi Anda untuk proses berikutnya.
                </p>
                <p v-else class="my-8 text-sm text-amalan-black">
                    Pembelian layananmu sudah sukses dilakukan. Terima kasih telah mempercayai amalan international. <br><br> Selanjutnya kami akan mengirimkan Credit Score Anda melalui Email.
                </p>
                <router-link to='/home' class="rounded-lg font-semibold text-sm cursor-pointer text-center py-3 px-4 text-amalan-white bg-amalan-blue-1 hover:bg-blue-900">Kembali ke Beranda</router-link>
            </div>
        </div> -->
    </div>
</template>