export const listLoanType = [
    {
        name: 'Credit Card',
        icon: 'icon-kartu-kredit.svg',
        title: 'Kartu Kredit',
        desc: 'Semua Kartu Kredit yang diterbitkan oleh Bank di Indonesia.'
    },
    {
        name: 'KTA',
        icon: 'icon-kta.svg',
        title: 'Kredit Tanpa Anggunan (KTA)',
        desc: 'Semua kredit tanpa jaminan yang dikeluarkan oleh Bank di Indonesia.'
    },
    {
        name: 'Online Loan',
        icon: 'icon-pinjol.svg',
        title: 'Pinjaman Online',
        desc: 'Semua produk pinjaman online yang terdaftar dan diawasi oleh OJK.'
    },
    {
        name: 'Mortgage',
        icon: 'icon-kpr.svg',
        title: 'Kredit Kepemilikan Rumah (KPR)',
        desc: 'Pinjaman KPR yang dikeluarkan oleh Bank baik subsidi maupun non subsidi.'
    },
    {
        name: 'House Certificate Loan',
        icon: 'icon-kmg-rumah.svg',
        title: 'KMG - Sertifikat Rumah',
        desc: 'Kredit dengan jaminan sertifikat rumah, tanah, atau apartemen.'
    },
    {
        name: 'KMG',
        icon: 'icon-kmg-non-rumah.svg',
        title: 'KMG - Sertifikat Lainnya',
        desc: 'Kredit dengan jaminan sertifikat lainnya seperti SK Pegawai dan lainnya.'
    },
    {
        name: 'Car Loan',
        icon: 'icon-kkb.svg',
        title: 'Kredit Kepemilikan Kendaraan',
        desc: 'Semua jenis kredit kendaraan baik yang disediakan Bank atau Leasing.'
    },
    {
        name: 'KUR',
        icon: 'icon-kur.svg',
        title: 'Kredit Usaha Rakyat (KUR)',
        desc: 'Semua jenis pinjaman KUR yang disediakan oleh Bank.'
    },
    {
        name: 'Other Loan',
        icon: 'icon-pinjaman-lainnya.svg',
        title: 'Pinjaman Lainnya',
        desc: 'Semua jenis pinjaman lainnya baik yang terdaftar di OJK ataupun tidak.'
    },
    {
        name: 'Office Loan',
        icon: 'icon-pinjaman-kantor.svg',
        title: 'Pinjaman Kantor',
        desc: 'Semua jenis pinjaman kantor baik yang disediakan oleh Bank atau bukan Bank.'
    }
]

export const listLoanTypeLQ = [
    {
        name: 'Credit Card',
        icon: 'icon-kartu-kredit.svg',
        title: 'Kartu Kredit',
        desc: 'Semua Kartu Kredit yang diterbitkan oleh Bank di Indonesia.'
    },
    {
        name: 'KTA',
        icon: 'icon-kta.svg',
        title: 'Kredit Tanpa Anggunan (KTA)',
        desc: 'Semua kredit tanpa jaminan yang dikeluarkan oleh Bank di Indonesia.'
    },
    {
        name: 'Online Loan',
        icon: 'icon-pinjol.svg',
        title: 'Pinjaman Online',
        desc: 'Semua produk pinjaman online yang terdaftar dan diawasi oleh OJK.'
    },
    {
        name: 'Mortgage',
        icon: 'icon-kpr.svg',
        title: 'Kredit Kepemilikan Rumah (KPR)',
        desc: 'Pinjaman KPR yang dikeluarkan oleh Bank baik subsidi maupun non subsidi.'
    },
    {
        name: 'House Certificate Loan',
        icon: 'icon-kmg-rumah.svg',
        title: 'KMG - Sertifikat Rumah',
        desc: 'Kredit dengan jaminan sertifikat rumah, tanah, atau apartemen.'
    },
    {
        name: 'KMG',
        icon: 'icon-kmg-non-rumah.svg',
        title: 'KMG - Sertifikat Lainnya',
        desc: 'Kredit dengan jaminan sertifikat lainnya seperti SK Pegawai dan lainnya.'
    },
    {
        name: 'Car Loan',
        icon: 'icon-kkb.svg',
        title: 'Kredit Kepemilikan Kendaraan',
        desc: 'Semua jenis kredit kendaraan baik yang disediakan Bank atau Leasing.'
    },
    {
        name: 'KUR',
        icon: 'icon-kur.svg',
        title: 'Kredit Usaha Rakyat (KUR)',
        desc: 'Semua jenis pinjaman KUR yang disediakan oleh Bank.'
    }
]

export const listLoanTypePKU = [
    {
        name: 'Credit Card',
        icon: 'icon-kartu-kredit.svg',
        title: 'Kartu Kredit',
        desc: 'Semua Kartu Kredit yang diterbitkan oleh Bank di Indonesia.'
    },
    {
        name: 'KTA',
        icon: 'icon-kta.svg',
        title: 'Kredit Tanpa Anggunan (KTA)',
        desc: 'Semua kredit tanpa jaminan yang dikeluarkan oleh Bank di Indonesia.'
    },
    {
        name: 'Online Loan',
        icon: 'icon-pinjol.svg',
        title: 'Pinjaman Online',
        desc: 'Semua produk pinjaman online yang terdaftar dan diawasi oleh OJK.'
    },
    {
        name: 'Mortgage',
        icon: 'icon-kpr.svg',
        title: 'Kredit Kepemilikan Rumah (KPR)',
        desc: 'Pinjaman KPR yang dikeluarkan oleh Bank baik subsidi maupun non subsidi.'
    },
    {
        name: 'House Certificate Loan',
        icon: 'icon-kmg-rumah.svg',
        title: 'KMG - Sertifikat Rumah',
        desc: 'Kredit dengan jaminan sertifikat rumah, tanah, atau apartemen.'
    },
    {
        name: 'KMG',
        icon: 'icon-kmg-non-rumah.svg',
        title: 'KMG - Sertifikat Lainnya',
        desc: 'Kredit dengan jaminan sertifikat lainnya seperti SK Pegawai dan lainnya.'
    }
]

export const iconBankLender = [
    {
        "name": "360 kredi",
        "icon": "360 Kredi.png"
    },
    {
        "name": "adakami",
        "icon": "AdaKami.png"
    },
    {
        "name": "adamodal",
        "icon": "AdaModal.png"
    },
    {
        "name": "adapundi",
        "icon": "AdaPundi.png"
    },
    {
        "name": "adira",
        "icon": "Adira.png"
    },
    {
        "name": "aeon",
        "icon": "Aeon.png"
    },
    {
        "name": "akseleran",
        "icon": "Akseleran.png"
    },
    {
        "name": "aktivaku",
        "icon": "Aktivaku.png"
    },
    {
        "name": "akulaku",
        "icon": "Akulaku.png"
    },
    {
        "name": "alami",
        "icon": "Alami.png"
    },
    {
        "name": "amartha",
        "icon": "amartha.png"
    },
    {
        "name": "ammana.id",
        "icon": "Ammana.id.png"
    },
    {
        "name": "anz",
        "icon": "ANZ.png"
    },
    {
        "name": "bank artha graha",
        "icon": "Bank Artha Graha.png"
    },
    {
        "name": "asetku",
        "icon": "Asetku.png"
    },
    {
        "name": "avantee",
        "icon": "Avantee.png"
    },
    {
        "name": "awantunai",
        "icon": "AwanTunai.png"
    },
    {
        "name": "bangkok bank pcl",
        "icon": "Bangkok Bank PCL.png"
    },
    {
        "name": "bank aceh syariah",
        "icon": "Bank Aceh Syariah.png"
    },
    {
        "name": "bank aladin",
        "icon": "Bank Aladin.png"
    },
    {
        "name": "bank amar",
        "icon": "Bank Amar.png"
    },
    {
        "name": "bank banten",
        "icon": "Bank Banten.png"
    },
    {
        "name": "bank bjb",
        "icon": "Bank BJB.png"
    },
    {
        "name": "bank bumi arta",
        "icon": "Bank Bumi Arta.png"
    },
    {
        "name": "bank capital",
        "icon": "Bank Capital.png"
    },
    {
        "name": "bank fama internasional",
        "icon": "Bank Fama Internasional.png"
    },
    {
        "name": "bank ibk",
        "icon": "Bank IBK.png"
    },
    {
        "name": "bank jago",
        "icon": "Bank Jago.png"
    },
    {
        "name": "bank jasa jakarta",
        "icon": "Bank Jasa Jakarta.png"
    },
    {
        "name": "bank jateng",
        "icon": "Bank Jateng.png"
    },
    {
        "name": "bank jatim",
        "icon": "Bank Jatim.png"
    },
    {
        "name": "bank kalbar",
        "icon": "Bank Kalbar.png"
    },
    {
        "name": "bank kalsel",
        "icon": "Bank Kalsel.png"
    },
    {
        "name": "bank kalteng",
        "icon": "Bank Kalteng.png"
    },
    {
        "name": "bank kaltimtara",
        "icon": "Bank Kaltimtara.png"
    },
    {
        "name": "bank lampung",
        "icon": "Bank Lampung.png"
    },
    {
        "name": "bank maluku malut",
        "icon": "Bank Maluku Malut.png"
    },
    {
        "name": "bank neo commerce",
        "icon": "Bank Neo Commerce.png"
    },
    {
        "name": "bank ntb syariah",
        "icon": "Bank NTB Syariah.png"
    },
    {
        "name": "bank ntt",
        "icon": "Bank NTT.png"
    },
    {
        "name": "bank of america",
        "icon": "Bank Of America.png"
    },
    {
        "name": "bank of china",
        "icon": "Bank Of China.png"
    },
    {
        "name": "bank of india",
        "icon": "Bank of India.png"
    },
    {
        "name": "bank papua",
        "icon": "Bank Papua.png"
    },
    {
        "name": "bank raya",
        "icon": "Bank Raya.png"
    },
    {
        "name": "bank riau kepri",
        "icon": "Bank Riau Kepri.png"
    },
    {
        "name": "bank sulselbar",
        "icon": "Bank Sulselbar.png"
    },
    {
        "name": "bank sulteng",
        "icon": "Bank Sulteng.png"
    },
    {
        "name": "bank sultra",
        "icon": "Bank Sultra.png"
    },
    {
        "name": "bank sulutgo",
        "icon": "Bank SulutGo.png"
    },
    {
        "name": "bank sumsel",
        "icon": "Bank Sumsel.png"
    },
    {
        "name": "bank sumut",
        "icon": "Bank Sumut.png"
    },
    {
        "name": "bantusaku",
        "icon": "BantuSaku.png"
    },
    {
        "name": "batumbu",
        "icon": "Batumbu.png"
    },
    {
        "name": "bca",
        "icon": "BCA.png"
    },
    {
        "name": "bca syariah",
        "icon": "BCA Syariah.png"
    },
    {
        "name": "bfi",
        "icon": "BFI.png"
    },
    {
        "name": "bank bjb syariah",
        "icon": "Bank BJB Syariah.png"
    },
    {
        "name": "blu by bca",
        "icon": "blu by BCA.png"
    },
    {
        "name": "bni",
        "icon": "BNI.png"
    },
    {
        "name": "bnp paribas",
        "icon": "BNP Paribas.png"
    },
    {
        "name": "boost",
        "icon": "Boost.png"
    },
    {
        "name": "bpd bali",
        "icon": "BPD Bali.png"
    },
    {
        "name": "bank bengkulu",
        "icon": "Bank Bengkulu.png"
    },
    {
        "name": "bpd diy",
        "icon": "BPD DIY.png"
    },
    {
        "name": "bank dki",
        "icon": "Bank DKI.png"
    },
    {
        "name": "bank jambi",
        "icon": "Bank Jambi.png"
    },
    {
        "name": "bri",
        "icon": "BRI.png"
    },
    {
        "name": "bsi",
        "icon": "BSI.png"
    },
    {
        "name": "bank btn",
        "icon": "Bank BTN.png"
    },
    {
        "name": "bank btpn syariah",
        "icon": "Bank BTPN Syariah.png"
    },
    {
        "name": "cairin",
        "icon": "Cairin.png"
    },
    {
        "name": "cashcepat",
        "icon": "Cashcepat.png"
    },
    {
        "name": "china construction bank",
        "icon": "China Construction Bank.png"
    },
    {
        "name": "cicil",
        "icon": "Cicil.png"
    },
    {
        "name": "cimb niaga",
        "icon": "CIMB Niaga.png"
    },
    {
        "name": "citibank",
        "icon": "Citibank.png"
    },
    {
        "name": "commonwealth",
        "icon": "Commonwealth.png"
    },
    {
        "name": "crowde",
        "icon": "Crowde.png"
    },
    {
        "name": "crowdo",
        "icon": "Crowdo.png"
    },
    {
        "name": "ctbc indonesia",
        "icon": "CTBC Indonesia.png"
    },
    {
        "name": "dana syariah",
        "icon": "Dana Syariah.png"
    },
    {
        "name": "danabagus",
        "icon": "DanaBagus.png"
    },
    {
        "name": "danabijak",
        "icon": "DanaBijak.png"
    },
    {
        "name": "danacita",
        "icon": "Danacita.png"
    },
    {
        "name": "danafix",
        "icon": "Danafix.png"
    },
    {
        "name": "danai.id",
        "icon": "Danai.id.png"
    },
    {
        "name": "danain",
        "icon": "Danain.png"
    },
    {
        "name": "danakini",
        "icon": "Danakini.png"
    },
    {
        "name": "danamas",
        "icon": "Danamas.png"
    },
    {
        "name": "danamerdeka",
        "icon": "DanaMerdeka.png"
    },
    {
        "name": "danamon",
        "icon": "Danamon.png"
    },
    {
        "name": "danarupiah",
        "icon": "DanaRupiah.png"
    },
    {
        "name": "dbs",
        "icon": "DBS.png"
    },
    {
        "name": "deutsche bank",
        "icon": "Deutsche Bank.png"
    },
    {
        "name": "dhanapala",
        "icon": "Dhanapala.png"
    },
    {
        "name": "doeku",
        "icon": "Doeku.png"
    },
    {
        "name": "dompet kilat",
        "icon": "Dompet Kilat.png"
    },
    {
        "name": "duha syariah",
        "icon": "Duha Syariah.png"
    },
    {
        "name": "dumi",
        "icon": "Dumi.png"
    },
    {
        "name": "easycash",
        "icon": "Easycash.png"
    },
    {
        "name": "edufund",
        "icon": "Edufund.png"
    },
    {
        "name": "esta kapital fintek",
        "icon": "Esta Kapital Fintek.png"
    },
    {
        "name": "ethis",
        "icon": "Ethis.png"
    },
    {
        "name": "fif",
        "icon": "FIF.png"
    },
    {
        "name": "findaya",
        "icon": "Findaya.png"
    },
    {
        "name": "finmas",
        "icon": "Finmas.png"
    },
    {
        "name": "finplus",
        "icon": "FinPlus.png"
    },
    {
        "name": "fintag",
        "icon": "Fintag.png"
    },
    {
        "name": "gandengtangan",
        "icon": "GandengTangan.png"
    },
    {
        "name": "bank ganesha",
        "icon": "Bank Ganesha.png"
    },
    {
        "name": "gradana",
        "icon": "Gradana.png"
    },
    {
        "name": "homecredit",
        "icon": "Homecredit.png"
    },
    {
        "name": "hsbc",
        "icon": "HSBC.png"
    },
    {
        "name": "icbc",
        "icon": "ICBC.png"
    },
    {
        "name": "igrow",
        "icon": "iGrow.png"
    },
    {
        "name": "iki modal",
        "icon": "IKI Modal.png"
    },
    {
        "name": "bank ina",
        "icon": "Bank Ina.png"
    },
    {
        "name": "bank index",
        "icon": "Bank Index.png"
    },
    {
        "name": "indodana",
        "icon": "Indodana.png"
    },
    {
        "name": "indofund.id",
        "icon": "Indofund.id.png"
    },
    {
        "name": "indosaku",
        "icon": "Indosaku.png"
    },
    {
        "name": "investree",
        "icon": "Investree.png"
    },
    {
        "name": "invoila",
        "icon": "Invoila.png"
    },
    {
        "name": "ivoji",
        "icon": "Ivoji.png"
    },
    {
        "name": "j trust",
        "icon": "J Trust.png"
    },
    {
        "name": "jembatan emas",
        "icon": "Jembatan Emas.png"
    },
    {
        "name": "jenius",
        "icon": "Jenius.png"
    },
    {
        "name": "jp morgan chase bank",
        "icon": "JP Morgan Chase Bank.png"
    },
    {
        "name": "julo",
        "icon": "Julo.png"
    },
    {
        "name": "kawancicil",
        "icon": "KawanCicil.png"
    },
    {
        "name": "kb bukopin",
        "icon": "KB Bukopin.png"
    },
    {
        "name": "kb bukopin syariah",
        "icon": "KB Bukopin Syariah.png"
    },
    {
        "name": "keb hana bank",
        "icon": "KEB Hana Bank.png"
    },
    {
        "name": "klik kami",
        "icon": "Klik Kami.png"
    },
    {
        "name": "klika2c",
        "icon": "KlikA2C.png"
    },
    {
        "name": "klikcair",
        "icon": "KlikCair.png"
    },
    {
        "name": "klikumkm",
        "icon": "KlikUMKM.png"
    },
    {
        "name": "koinp2p",
        "icon": "KoinP2P.png"
    },
    {
        "name": "komunal",
        "icon": "Komunal.png"
    },
    {
        "name": "kredifazz",
        "icon": "KrediFazz.png"
    },
    {
        "name": "kredinesia",
        "icon": "Kredinesia.png"
    },
    {
        "name": "kredit pintar",
        "icon": "Kredit Pintar.png"
    },
    {
        "name": "kredito",
        "icon": "Kredito.png"
    },
    {
        "name": "kreditpro",
        "icon": "Kreditpro.png"
    },
    {
        "name": "kredivo",
        "icon": "Kredivo.png"
    },
    {
        "name": "krom bank",
        "icon": "Krom Bank.png"
    },
    {
        "name": "kta kilat",
        "icon": "KTA Kilat.png"
    },
    {
        "name": "lahan sikam",
        "icon": "Lahan Sikam.png"
    },
    {
        "name": "lumbungdana",
        "icon": "LumbungDana.png"
    },
    {
        "name": "mandiri",
        "icon": "Mandiri.png"
    },
    {
        "name": "mandiri taspen",
        "icon": "Mandiri Taspen.png"
    },
    {
        "name": "bank maspion",
        "icon": "Bank Maspion.png"
    },
    {
        "name": "maucash",
        "icon": "Maucash.png"
    },
    {
        "name": "mayapada",
        "icon": "Mayapada.png"
    },
    {
        "name": "maybank",
        "icon": "Maybank.png"
    },
    {
        "name": "bank mayora",
        "icon": "Bank Mayora.png"
    },
    {
        "name": "bank mega",
        "icon": "Bank Mega.png"
    },
    {
        "name": "bank mega syariah",
        "icon": "Bank Mega Syariah.png"
    },
    {
        "name": "mekar",
        "icon": "Mekar.png"
    },
    {
        "name": "bank mestika dharma",
        "icon": "Bank Mestika Dharma.png"
    },
    {
        "name": "bank mizuho",
        "icon": "Bank Mizuho.png"
    },
    {
        "name": "mnc",
        "icon": "MNC.png"
    },
    {
        "name": "modal nasional",
        "icon": "Modal Nasional.png"
    },
    {
        "name": "modalku",
        "icon": "Modalku.png"
    },
    {
        "name": "modalrakyat",
        "icon": "ModalRakyat.png"
    },
    {
        "name": "bank muamalat",
        "icon": "Bank Muamalat.png"
    },
    {
        "name": "mufg bank",
        "icon": "MUFG Bank.png"
    },
    {
        "name": "bank mas",
        "icon": "Bank MAS.png"
    },
    {
        "name": "bank nobu",
        "icon": "Bank Nobu.png"
    },
    {
        "name": "ocbc",
        "icon": "OCBC.png"
    },
    {
        "name": "ok! bank",
        "icon": "OK Bank.png"
    },
    {
        "name": "panin",
        "icon": "Panin.png"
    },
    {
        "name": "panin dubai syariah",
        "icon": "Panin Dubai Syariah.png"
    },
    {
        "name": "papitupi syariah",
        "icon": "Papitupi Syariah.png"
    },
    {
        "name": "permata",
        "icon": "Permata.png"
    },
    {
        "name": "pinjam gampang",
        "icon": "Pinjam Gampang.png"
    },
    {
        "name": "pinjam modal",
        "icon": "Pinjam Modal.png"
    },
    {
        "name": "pinjam yuk",
        "icon": "Pinjam Yuk.png"
    },
    {
        "name": "pinjamango",
        "icon": "PinjamanGO.png"
    },
    {
        "name": "pinjamduit",
        "icon": "PinjamDuit.png"
    },
    {
        "name": "pinjamwinwin",
        "icon": "Pinjamwinwin.png"
    },
    {
        "name": "pintek",
        "icon": "Pintek.png"
    },
    {
        "name": "pohondana",
        "icon": "PohonDana.png"
    },
    {
        "name": "prima master bank",
        "icon": "Prima Master Bank.png"
    },
    {
        "name": "qazwa.id",
        "icon": "Qazwa.id.png"
    },
    {
        "name": "qnb",
        "icon": "QNB.png"
    },
    {
        "name": "bank resona perdania",
        "icon": "Bank Resona Perdania.png"
    },
    {
        "name": "restock.id",
        "icon": "Restock.ID.png"
    },
    {
        "name": "ringan",
        "icon": "Ringan.png"
    },
    {
        "name": "rupiah cepat",
        "icon": "Rupiah Cepat.png"
    },
    {
        "name": "bank sampoerna",
        "icon": "Bank Sampoerna.png"
    },
    {
        "name": "samakita",
        "icon": "SamaKita.png"
    },
    {
        "name": "samir",
        "icon": "Samir.png"
    },
    {
        "name": "sanders one stop solution",
        "icon": "Sanders One Stop Solution.png"
    },
    {
        "name": "bank sbi",
        "icon": "Bank SBI.png"
    },
    {
        "name": "seabank",
        "icon": "SeaBank.png"
    },
    {
        "name": "bank shinhan",
        "icon": "Bank Shinhan.png"
    },
    {
        "name": "bank sinarmas",
        "icon": "Bank Sinarmas.png"
    },
    {
        "name": "singa",
        "icon": "Singa.png"
    },
    {
        "name": "solusiku",
        "icon": "Solusiku.png"
    },
    {
        "name": "spaylater",
        "icon": "SPayLater.png"
    },
    {
        "name": "standard chartered",
        "icon": "Standard Chartered.png"
    },
    {
        "name": "tanifund",
        "icon": "TaniFund.png"
    },
    {
        "name": "taralite",
        "icon": "Taralite.png"
    },
    {
        "name": "toko modal",
        "icon": "Toko Modal.png"
    },
    {
        "name": "trustiq",
        "icon": "TrustIQ.png"
    },
    {
        "name": "uangme",
        "icon": "UangMe.png"
    },
    {
        "name": "uatas",
        "icon": "Uatas.png"
    },
    {
        "name": "uku",
        "icon": "UKU.png"
    },
    {
        "name": "uob",
        "icon": "UOB.png"
    },
    {
        "name": "bank victoria",
        "icon": "Bank Victoria.png"
    },
    {
        "name": "bank victoria syariah",
        "icon": "Bank Victoria Syariah.png"
    },
    {
        "name": "bank woori saudara",
        "icon": "Bank Woori Saudara.png"
    },
    {
        "name": "koinworks",
        "icon": "Koinworks.png"
    },
    {
        "name": "spinjam",
        "icon": "SPinjam.png"
    },
    {
        "name": "tunaiku",
        "icon": "Tunaiku.png"
    },
    {
        "name": "allo bank",
        "icon": "Allo Bank.png"
    },
    {
        "name": "sahabat finansial keluarga",
        "icon": "Sahabat Finansial Keluarga.png"
    },
    {
        "name": "lentera dana nusantara",
        "icon": "Lentera Dana Nusantara.png"
    },
    {
        "name": "bank nagari",
        "icon": "Bank Nagari.png"
    },
    {
        "name": "commbank",
        "icon": "Commbank.png"
    },
    {
        "name": "digibank by dbs",
        "icon": "Digibank by DBS.png"
    },
    {
        "name": "line bank by hana bank",
        "icon": "LINE Bank by Hana Bank.png"
    },
    {
        "name": "ucan by qnb",
        "icon": "UCan by QNB.png"
    },
    {
        "name": "ceria by bri",
        "icon": "Ceria by BRI.png"
    },
    {
        "name": "pinang by raya",
        "icon": "Pinang by Raya.png"
    },
    {
        "name": "bank btpn",
        "icon": "Bank BTPN.png"
    },
    {
        "name": "asiatic finance",
        "icon": "Asiatic Finance.png"
    },
    {
        "name": "cimb niaga finance",
        "icon": "CIMB Niaga Finance.png"
    },
    {
        "name": "smart multi finance",
        "icon": "Smart Multi Finance.png"
    },
    {
        "name": "karunia multifinance",
        "icon": "Karunia Multifinance.png"
    },
    {
        "name": "traveloka paylater",
        "icon": "Traveloka PayLater.png"
    },
    {
        "name": "ccm finance",
        "icon": "CCM Finance.png"
    },
    {
        "name": "emas persada finance",
        "icon": "Emas Persada Finance.png"
    },
    {
        "name": "ab simas finance",
        "icon": "AB Simas Finance.png"
    },
    {
        "name": "gratama finance",
        "icon": "Gratama Finance.png"
    },
    {
        "name": "artha prima finance",
        "icon": "Artha Prima Finance.png"
    },
    {
        "name": "asia multidana",
        "icon": "Asia Multidana.png"
    },
    {
        "name": "acc",
        "icon": "ACC.png"
    },
    {
        "name": "spektra",
        "icon": "Spektra.png"
    },
    {
        "name": "batara finance",
        "icon": "Batara Finance.png"
    },
    {
        "name": "woori finance",
        "icon": "Woori Finance.png"
    },
    {
        "name": "bca finance",
        "icon": "BCA Finance.png"
    },
    {
        "name": "bsa finance",
        "icon": "BSA Finance.png"
    },
    {
        "name": "bima finance",
        "icon": "Bima Finance.png"
    },
    {
        "name": "bintang mandiri finance",
        "icon": "Bintang Mandiri Finance.png"
    },
    {
        "name": "bni multifinance",
        "icon": "BNI Multifinance.png"
    },
    {
        "name": "bosowa multifinance",
        "icon": "Bosowa Multifinance.png"
    },
    {
        "name": "bri finance",
        "icon": "BRI Finance.png"
    },
    {
        "name": "buana finance",
        "icon": "Buana Finance.png"
    },
    {
        "name": "kb bukopin finance",
        "icon": "KB Bukopin Finance.png"
    },
    {
        "name": "capella multidana",
        "icon": "Capella Multidana.png"
    },
    {
        "name": "central java power",
        "icon": "Central Java Power.png"
    },
    {
        "name": "bca multi finance",
        "icon": "BCA Multi Finance.png"
    },
    {
        "name": "csul finance",
        "icon": "CSUL Finance.png"
    },
    {
        "name": "ciptadana multifinance",
        "icon": "Ciptadana Multifinance.png"
    },
    {
        "name": "clipan finance",
        "icon": "Clipan Finance.png"
    },
    {
        "name": "daindo finance",
        "icon": "Daindo Finance.png"
    },
    {
        "name": "dana unico finance",
        "icon": "Dana Unico Finance.png"
    },
    {
        "name": "danareksa finance",
        "icon": "Danareksa Finance.png"
    },
    {
        "name": "proline finance",
        "icon": "Proline Finance.png"
    },
    {
        "name": "dipo star finance",
        "icon": "Dipo Star Finance.png"
    },
    {
        "name": "equity finance",
        "icon": "Equity Finance.png"
    },
    {
        "name": "kreditplus",
        "icon": "Kreditplus.png"
    },
    {
        "name": "hewlett-packard finance",
        "icon": "Hewlett-Packard Finance.png"
    },
    {
        "name": "mnc leasing",
        "icon": "MNC Leasing.png"
    },
    {
        "name": "itc finance",
        "icon": "ITC Finance.png"
    },
    {
        "name": "iaf multi finance",
        "icon": "IAF Multi Finance.png"
    },
    {
        "name": "fuji finance",
        "icon": "Fuji Finance.png"
    },
    {
        "name": "karya technik multifinance",
        "icon": "Karya Technik Multifinance.png"
    },
    {
        "name": "kresna reksa finance",
        "icon": "Kresna Reksa Finance.png"
    },
    {
        "name": "lotte capital",
        "icon": "Lotte Capital.png"
    },
    {
        "name": "mega central finance",
        "icon": "Mega Central Finance.png"
    },
    {
        "name": "atome",
        "icon": "Atome.png"
    },
    {
        "name": "mega finance",
        "icon": "Mega Finance.png"
    },
    {
        "name": "jaccs mpm finance",
        "icon": "JACCS MPM Finance.png"
    },
    {
        "name": "orico balimor finance",
        "icon": "Orico Balimor Finance.png"
    },
    {
        "name": "mnc finance",
        "icon": "MNC Finance.png"
    },
    {
        "name": "mutiara finance",
        "icon": "Mutiara Finance.png"
    },
    {
        "name": "reliance finance",
        "icon": "Reliance Finance.png"
    },
    {
        "name": "orix finance",
        "icon": "Orix Finance.png"
    },
    {
        "name": "permata finance",
        "icon": "Permata Finance.png"
    },
    {
        "name": "ppa finance",
        "icon": "PPA Finance.png"
    },
    {
        "name": "pratama finance",
        "icon": "Pratama Finance.png"
    },
    {
        "name": "pro car finance",
        "icon": "Pro Car Finance.png"
    },
    {
        "name": "rabana investindo",
        "icon": "Rabana Investindo.png"
    },
    {
        "name": "multifinance anak bangsa",
        "icon": "Multifinance Anak Bangsa.png"
    },
    {
        "name": "resona finance",
        "icon": "Resona Finance.png"
    },
    {
        "name": "sms finance",
        "icon": "SMS Finance.png"
    },
    {
        "name": "suzuki finance",
        "icon": "Suzuki Finance.png"
    },
    {
        "name": "shinhan finance",
        "icon": "Shinhan Finance.png"
    },
    {
        "name": "sembrani finance",
        "icon": "Sembrani Finance.png"
    },
    {
        "name": "true finance",
        "icon": "TRUE Finance.png"
    },
    {
        "name": "trust finance",
        "icon": "Trust Finance.png"
    },
    {
        "name": "varia intra finance",
        "icon": "Varia Intra Finance.png"
    },
    {
        "name": "mizuho leasing",
        "icon": "Mizuho Leasing.png"
    },
    {
        "name": "pann maritime finance",
        "icon": "PANN Maritime Finance.png"
    },
    {
        "name": "iifinance",
        "icon": "IIFinance.png"
    },
    {
        "name": "hino finance",
        "icon": "Hino Finance.png"
    },
    {
        "name": "hexa finance",
        "icon": "Hexa Finance.png"
    },
    {
        "name": "bess finance",
        "icon": "Bess Finance.png"
    },
    {
        "name": "reksa finance",
        "icon": "Reksa Finance.png"
    },
    {
        "name": "mandala finance",
        "icon": "Mandala Finance.png"
    },
    {
        "name": "cat financial",
        "icon": "Cat Financial.png"
    },
    {
        "name": "mandiri tunas finance",
        "icon": "Mandiri Tunas Finance.png"
    },
    {
        "name": "emperor finance",
        "icon": "Emperor Finance.png"
    },
    {
        "name": "ifs capital",
        "icon": "IFS Capital.png"
    },
    {
        "name": "komatsu astra finance",
        "icon": "Komatsu Astra Finance.png"
    },
    {
        "name": "mitsui leasing",
        "icon": "Mitsui Leasing.png"
    },
    {
        "name": "globalindo multi finance",
        "icon": "Globalindo Multi Finance.png"
    },
    {
        "name": "sanf",
        "icon": "SANF.png"
    },
    {
        "name": "sunindo kb finance",
        "icon": "Sunindo KB Finance.png"
    },
    {
        "name": "clemont finance",
        "icon": "Clemont Finance.png"
    },
    {
        "name": "sme finance",
        "icon": "SME Finance.png"
    },
    {
        "name": "mega auto finance",
        "icon": "Mega Auto Finance.png"
    },
    {
        "name": "nsc finance",
        "icon": "NSC Finance.png"
    },
    {
        "name": "rindang sejahtera finance",
        "icon": "Rindang Sejahtera Finance.png"
    },
    {
        "name": "sinarmas multifinance",
        "icon": "Sinarmas Multifinance.png"
    },
    {
        "name": "tez capital and finance",
        "icon": "Tez Capital and Finance.png"
    },
    {
        "name": "maybank finance",
        "icon": "Maybank Finance.png"
    },
    {
        "name": "astrido finance",
        "icon": "Astrido Finance.png"
    },
    {
        "name": "sarana global finance indonesia",
        "icon": "Sarana Global Finance Indonesia.png"
    },
    {
        "name": "mandiri utama finance",
        "icon": "Mandiri Utama Finance.png"
    },
    {
        "name": "saison indonesia",
        "icon": "Saison Indonesia.png"
    },
    {
        "name": "sinarmas hana finance",
        "icon": "Sinarmas Hana Finance.png"
    },
    {
        "name": "mitra dana top finance",
        "icon": "Mitra Dana Top Finance.png"
    },
    {
        "name": "mitsubishi hc capital and finance indonesia",
        "icon": "Mitsubishi HC Capital and Finance Indonesia.png"
    },
    {
        "name": "paramitra multifinance",
        "icon": "Paramitra Multifinance.png"
    },
    {
        "name": "koexim mandiri finance",
        "icon": "Koexim Mandiri Finance.png"
    },
    {
        "name": "tempo utama finance",
        "icon": "Tempo Utama Finance.png"
    },
    {
        "name": "kdb tifa finance",
        "icon": "KDB Tifa Finance.png"
    },
    {
        "name": "oto multiartha",
        "icon": "Oto Multiartha.png"
    },
    {
        "name": "takari kokoh sejahtera",
        "icon": "Takari Kokoh Sejahtera.png"
    },
    {
        "name": "indomobil finance",
        "icon": "Indomobil Finance.png"
    },
    {
        "name": "baf",
        "icon": "BAF.png"
    },
    {
        "name": "beta finance",
        "icon": "Beta Finance.png"
    },
    {
        "name": "buana multidana",
        "icon": "Buana Multidana.png"
    },
    {
        "name": "jto finance",
        "icon": "JTO Finance.png"
    },
    {
        "name": "bot finance",
        "icon": "BOT Finance.png"
    },
    {
        "name": "fortuna multi finance",
        "icon": "Fortuna Multi Finance.png"
    },
    {
        "name": "smfl leasing",
        "icon": "SMFL Leasing.png"
    },
    {
        "name": "pool advista finance, tbk",
        "icon": "Pool Advista Finance, Tbk.png"
    },
    {
        "name": "triprima multifinance",
        "icon": "Triprima Multifinance.png"
    },
    {
        "name": "chailease finance",
        "icon": "Chailease Finance.png"
    },
    {
        "name": "arthaasia finance",
        "icon": "Arthaasia Finance.png"
    },
    {
        "name": "woka finance",
        "icon": "Woka Finance.png"
    },
    {
        "name": "aditama finance",
        "icon": "Aditama Finance.png"
    },
    {
        "name": "transpacific finance",
        "icon": "Transpacific Finance.png"
    },
    {
        "name": "hasjrat multifinance",
        "icon": "Hasjrat Multifinance.png"
    },
    {
        "name": "wom finance",
        "icon": "WOM Finance.png"
    },
    {
        "name": "taf",
        "icon": "TAF.png"
    },
    {
        "name": "topas multi finance",
        "icon": "Topas Multi Finance.png"
    },
    {
        "name": "nissan finance",
        "icon": "Nissan Finance.png"
    },
    {
        "name": "oto finance",
        "icon": "OTO Finance.png"
    },
    {
        "name": "pacific multi finance",
        "icon": "Pacific Multi Finance.png"
    },
    {
        "name": "wuling finance",
        "icon": "Wuling Finance.png"
    },
    {
        "name": "tokyo century",
        "icon": "Tokyo Century.png"
    },
    {
        "name": "anadana finance",
        "icon": "Anadana Finance.png"
    },
    {
        "name": "radana finance",
        "icon": "Radana Finance.png"
    },
    {
        "name": "shakti top finance",
        "icon": "Shakti Top Finance.png"
    },
    {
        "name": "mas finance",
        "icon": "MAS Finance.png"
    },
    {
        "name": "armada finance",
        "icon": "Armada Finance.png"
    },
    {
        "name": "multindo auto finance",
        "icon": "Multindo Auto Finance.png"
    },
    {
        "name": "abc finance",
        "icon": "ABC Finance.png"
    },
    {
        "name": "satyadhika bakti multifinance",
        "icon": "Satyadhika Bakti Multifinance.png"
    },
    {
        "name": "alif multifinance syariah",
        "icon": "Alif Multifinance Syariah.png"
    },
    {
        "name": "citifin multifinance syariah",
        "icon": "CITIFIN Multifinance Syariah.png"
    },
    {
        "name": "trihamas finance syariah",
        "icon": "Trihamas Finance Syariah.png"
    },
    {
        "name": "amitra",
        "icon": "Amitra.png"
    },
    {
        "name": "tokopedia",
        "icon": "Tokopedia.png"
    },
    {
        "name": "ovo",
        "icon": "OVO.png"
    },
    {
        "name": "gopaylater by findaya",
        "icon": "Gopaylater by Findaya.png"
    },
    {
        "name": "blibli paylater",
        "icon": "Blibli PayLater.png"
    },
    {
        "name": "lazpaylater",
        "icon": "LazPayLater.png"
    },
    {
        "name": "bukalapak paylater",
        "icon": "Bukalapak PayLater.png"
    }
]