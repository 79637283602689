<script setup>
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, inject } from 'vue'
import NavbarWithBackV2 from '@/components/NavbarWithBackV2.vue'
import NegotiationResultCard from '../Components/NegotiationResultCard.vue'
import opportunityHelper from '@/helpers/opportunity-helper'

const router = useRouter()
const route = useRoute()
const store = useStore()
const filters = inject('Filters')

const negotiationResults = computed(() => store.getters['negotiationResult/getNegotiationResultHistory'])

const showDetailOpportunity = (Id) => router.push({ name: 'Negotiation Process - Result - History Show', params: { opportunityId: Id } })

const createDescription = (data) => {
    if(!data) return 'Dikonfirmasi -'
    if(data.records.length == 0) return 'Dikonfirmasi -'
    if(!data.records[0].Customer_Feedback_Timestamp__c) return 'Tidak Dikonfirmasi'
    return 'Dikonfirmasi ' + filters.formatDateOnlyDate(data.records[0].Customer_Feedback_Timestamp__c)
}

</script>

<template>
    <div class="min-h-screen bg-amalan-white relative">
        <NavbarWithBackV2 :title="route.meta.title" @back-button="router.push({ name: 'Negotiation Process - Home' })" />
        <div class="w-full pt-8 pb-20 px-6 bg-amalan-white">
            <p class="text-center text-amalan-black text-xs sm:text-sm">Anda dapat melihat riwayat persetujuan<br>hasil negosiasi pinjaman Anda</p>
            <div v-if="negotiationResults.data.length > 0" class="w-full mt-8 grid grid-cols-1 gap-y-4">
                <NegotiationResultCard
                    v-for="(data, index) in negotiationResults.data" :key="index"
                    :title="'Hasil Negosiasi'"
                    :description="createDescription(data.Opportunity_Approval__r)"
                    :bank-name="data.Name ? opportunityHelper.getBankName(data.Name, data.Loan_Type_2__c) : '-'"
                    :image-source="require('@/assets/img/bank-lender-icon/' + opportunityHelper.getIcon(data.Name, data.Loan_Type_2__c))"
                    :loan-type="data.Loan_Type_2__c ? opportunityHelper.getLoanTitle(data.Loan_Type_2__c) : '-'"
                    :outstanding="data.Estimated_Outstanding_Amount__c ? $filters.format_number(data.Estimated_Outstanding_Amount__c) : '-'"
                    :button-text="'Lihat Selengkapnya'"
                    @on-action="showDetailOpportunity(data.Id)"
                />
            </div>
            <div v-else class="w-full px-6 flex flex-col justify-center items-center pt-34">
                <img src="@/assets/img/clipboard-v2.svg" class="w-40 mx-auto">
                <p class="text-center text-xs mt-4">Mohon maaf, Anda belum memiliki riwayat hasil negosiasi<br>yang telah dikonfirmasi.</p>
            </div>
        </div>
        <loading-overlay v-if="!negotiationResults.isFetched" />
    </div>
</template>