<script setup>
import { computed, ref, onMounted, reactive, inject, watch } from 'vue'
import { useStore } from 'vuex'
import useModalPopUp from '@/composables/modalPopUp'
import { listLoanType, iconBankLender } from '@/constants/LoanConstant'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { ChevronRightIcon } from '@heroicons/vue/solid'
import { useRouter, useRoute } from 'vue-router'
import amalansignupApi from '@/axios/amalansignup'
import { Form, Field } from 'vee-validate'
import * as yup from 'yup'
import Multiselect from '@vueform/multiselect'
import CurrencyInput from '@/components/CurrencyInputV2.vue'
import { multiselectClass, dataLamaTunggakan } from '@/constants/Multiselect'

const store = useStore()
const router = useRouter()
const route = useRoute()
const modalPopUp = useModalPopUp()
const filters = inject('Filters')

const PAGE_STEP_LIST = { listOpportunity: 'list-opportunity', editOpportunity: 'edit-opportunity' }

const dataOpportunities = computed(() => store.getters['docCollection/getOpportunities'])
const totalHutang = computed(() => store.getters['docCollection/getTotalAmountLoan'])
const dataContact = computed(() => store.getters['contact/getContact'])
const signUpStatus = computed(() => store.getters['contact/getSignUpStatus'])
const userPricingPackage = computed(() => store.getters['contact/getUserPricingPackage'])
const touchedOpportunities = computed(() => store.getters['additionalSUF/getTouchedOpportunities'])

const timeToDocCollection = ref(true)
const editMode = ref(route.query.editmode ? true : false)
const showMenuDocCollection = ref(false)
const userWithPricingPackage = ref(false)
const ableToGoNextPage = ref(touchedOpportunities.value.length > 0 ? true : false)
const isLoading = ref(false)
const pageStep = ref(PAGE_STEP_LIST.listOpportunity)
const formUpdateOpportunity = reactive({
    Id: null,
    outstandingAmount: null,
    overdue: null,
    bankName: null,
    loanType: null,
    accountNumber: null,
    lastPaymentDate: null
})

const formUbahOpportunitySchema = yup.object().shape({
    outstandingAmount: yup.number('Masukan dalam bentuk angka').nullable().required('Jumlah pinjaman tidak boleh kosong'),
    overdue: yup.string().nullable().required('Silahkan pilih lama tunggakan'),
    accountNumber: yup.string().nullable().max(25, 'Nomor pinjaman tidak boleh melebihi 25 karakter').transform((o, c) => o === "" ? null : c).matches(/^[0-9x]+$/, "Masukan dalam bentuk angka").nullable(),
    lastPaymentDate: yup.date().nullable().required('Silahkan pilih tanggal').max(filters.dateNow(),"Tanggal tidak boleh lebih dari hari ini"),
})

watch(touchedOpportunities, () => {
    if(touchedOpportunities.value.length === 0) return ableToGoNextPage.value = false
    return ableToGoNextPage.value = true
}, { deep: true })

watch(editMode, () => {
    checkLastAdditionalSuf()
})

const getIcon = (loanName, loanType) => {
    const lenderName = loanName.replace(" " + loanType, "").toLowerCase()
    const lenderData = iconBankLender.filter(elem => elem.name == lenderName)
    if (lenderData[0]) return lenderData[0].icon

    const loanTypeIcon = listLoanType.filter(elem => elem.name == loanType)
    if (loanTypeIcon[0]) return loanTypeIcon[0].icon
}

const getLoanTitle = (loanType) => {
    let loanTitle = listLoanType.filter(elem => elem.name == loanType)
    if (loanTitle[0]) return loanTitle[0].title
}

const getBankName = (loanName, loanType) => loanName.replace(" " + loanType, "")

const backButton = () => {
    if(pageStep.value === PAGE_STEP_LIST.editOpportunity) return pageStep.value = PAGE_STEP_LIST.listOpportunity
    if(!editMode.value) return router.push({ name: 'Loan Management - Home'})
    if(!ableToGoNextPage.value) return editMode.value = false

    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs mt-2">Perubahan Data Utang Anda belum disimpan.<br><br>Anda yakin ingin keluar dari halaman ini?</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Konfirmasi',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if(!result.isConfirmed) return
        store.dispatch('docCollection/requestOpportunities')
        store.dispatch('additionalSUF/removeTouchedOpportunities')
        return editMode.value = false
    })
}

const showDetailOpportunity = (value) => {
    if(editMode.value) return
    return router.push({ name: 'Loan Management - Loan List - Detail', params: { opportunityId: value }})
}

const editOpportunity = (data) => {
    formUpdateOpportunity.Id = data.Id
    formUpdateOpportunity.overdue = data.Month_Overdue_input_by_Lead__c ?? '0'
    formUpdateOpportunity.outstandingAmount = data.Estimated_Outstanding_Amount__c ?? 0
    formUpdateOpportunity.bankName = data.Name ? getBankName(data.Name, data.Loan_Type_2__c) : null
    formUpdateOpportunity.loanType = data.Loan_Type_2__c ? getLoanTitle(data.Loan_Type_2__c) : null
    formUpdateOpportunity.accountNumber = data.Account_Number__c
    formUpdateOpportunity.lastPaymentDate = data.Last_Payment_Date__c
    pageStep.value = PAGE_STEP_LIST.editOpportunity
}

const formUbahOpportunitySubmit = (values) => {
    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs mt-2">Data pinjaman ini tidak dapat dipulihkan kembali.<br><br>Apakah Anda yakin ingin mengubahnya?</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Ubah',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if(!result.isConfirmed) return
        store.dispatch('additionalSUF/setTouchedOpportunity', { Id: formUpdateOpportunity.Id, ...values })
        pageStep.value = PAGE_STEP_LIST.listOpportunity
    })
}

const getPaymentPreview = () => {
    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs mt-2">Perubahan pada data pinjaman Anda terdapat <b>Total Utang &#8805;50%</b> dari Total Utang sebelumnya.<br><br>Untuk melakukan perubahan ini Anda akan dikenakan biaya tambahan sesuai dengan biaya tambahan yang berlaku di amalan.<br><br>Apakah Anda yakin ingin mengubahnya?</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Ubah',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if(!result.isConfirmed) return
        isLoading.value = true
        amalansignupApi.post('loan-management/additional-suf-update', { data: touchedOpportunities.value })
        .then(async (res) => {
            if(!res.data.data.price){
                store.dispatch('additionalSUF/removeTouchedOpportunities')
                await store.dispatch('docCollection/requestOpportunities')
                isLoading.value = false
                return editMode.value = false
            }

            store.dispatch('additionalSUF/setPaymentData', {
                page_type : 'Update',
                pricing_package_name : userPricingPackage.value,
                payment_purpose : res.data.data.payment_purpose,
                payment_amount : res.data.data.price,
                discount : res.data.data.discount,
                payment_expiration_date : res.data.data.payment_expiration_date,
                payment_link : null,
                total_outstanding : res.data.data.total_outstanding
            })
            isLoading.value = false
            return router.push({ name: 'Loan Management - Additional Suf - Payment Page' })
        })
        .catch(() => {
            isLoading.value = false
            modalPopUp.swalError.fire({
                html: '<p class="text-center text-xs mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                showCancelButton: true,
                confirmButtonText: 'Bantuan',
                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
            }).then((result) => {
                if(!result.isConfirmed) return
                return window.location = 'https://wa.me/622150933150?text=' + 
                'Mohon bantuan saya gagal terus untuk mengirim data pinjaman ke server pada halaman loan management di web.amalan.com'
            })
        })
    })
}

const checkLastAdditionalSuf = () => {
    if(!editMode.value) return
    store.dispatch('additionalSUF/removeNewOpportunities')
    store.dispatch('pendingUserActivity/checkPendingAdditionalSuf')
}

onMounted(() => {
    if(signUpStatus.value === 200 && timeToDocCollection.value === true) showMenuDocCollection.value = true
    checkLastAdditionalSuf()
    store.dispatch('additionalSUF/storeTouchedOpportToDocCollectionState')
    userWithPricingPackage.value = dataContact.value.Pricing_Package__c && dataContact.value.Pricing_Package__c.toUpperCase() !== 'PAY AS YOU GO' ? true : false

    //if edit mode true and opport Id is exist in query param, go to opportunity edit page
    if(route.query.editmode && route.query.opportId){
        const findOpport = dataOpportunities.value.find((v) => v.Id == route.query.opportId)
        if(findOpport) editOpportunity(findOpport)
    }
})

</script>

<template>
    <div class="min-h-screen bg-amalan-white">
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="backButton" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div v-if="dataOpportunities && dataOpportunities.length > 0" class="w-full px-6 pt-8 pb-40 bg-amalan-white">
            <template v-if="pageStep === PAGE_STEP_LIST.listOpportunity">
                <p v-if="editMode" class="text-center text-amalan-black text-xs sm:text-sm mb-8">Anda dapat mengubah data pinjaman Anda disini!</p>
                <p v-else class="text-center text-amalan-black text-xs sm:text-sm mb-8">Anda dapat mengubah atau menambahkan data pinjaman Anda disini!</p>
                <template v-for="(dataOpp, index) in dataOpportunities" :key="index">
                    <div v-if="touchedOpportunities.length > 0 && index == touchedOpportunities.length" class="h-px w-full bg-amalan-gray-3 mt-3.5"></div>
                    <div 
                        class="w-full mt-3.5 rounded-lg border p-4 flex justify-between items-center"
                        :class="[ 
                            showMenuDocCollection && !dataOpp.Bill_Statement_1__c ? editMode ? dataOpp.Last_Estimated_Outstanding_Amount__c ? 'border-amalan-gray-4 bg-amalan-yellow-100' : 'border-amalan-gray-4 bg-amalan-white' : 'bg-red-50 border-amalan-red cursor-pointer' : editMode ? dataOpp.Last_Estimated_Outstanding_Amount__c ? 'border-amalan-gray-4 bg-amalan-yellow-100' : 'border-amalan-gray-4 bg-amalan-white' : 'border-amalan-gray-4 bg-amalan-white hover:bg-amalan-gray-5 cursor-pointer' ]"
                        @click="showDetailOpportunity(dataOpp.Id)"
                    >
                        <div class="flex-none w-14 h-14 rounded-lg flex justify-center items-center">
                            <img :src="require('@/assets/img/bank-lender-icon/' + getIcon(dataOpp.Name, dataOpp.Loan_Type_2__c))" class="w-14">
                        </div>
                        <div class="flex-grow px-2.5 text-amalan-black">
                            <p class="text-sm text-left font-bold">{{ dataOpp.Name ? getBankName(dataOpp.Name, dataOpp.Loan_Type_2__c) : '-' }}</p>
                            <p class="text-xs mt-0.5">{{ dataOpp.Loan_Type_2__c ? getLoanTitle(dataOpp.Loan_Type_2__c) : '-' }}</p>
                            <p v-if="dataOpp.Last_Estimated_Outstanding_Amount__c" class="text-xs mt-1"><span class="text-2xs italic">{{ $filters.format_number(dataOpp.Last_Estimated_Outstanding_Amount__c) }}</span> {{ dataOpp.Estimated_Outstanding_Amount__c ? '→ ' + $filters.format_number(dataOpp.Estimated_Outstanding_Amount__c) : '-' }}</p>
                            <p v-else class="text-xs mt-1">{{ dataOpp.Estimated_Outstanding_Amount__c ? $filters.format_number(dataOpp.Estimated_Outstanding_Amount__c) : '-' }}</p>
                        </div>
                        <div class="flex-none w-6 h-14 flex justify-center items-center">
                            <button v-if="editMode" @click="editOpportunity(dataOpp)" class="border border-amalan-blue-1 rounded-lg w-6 h-6 flex justify-center items-center shadow-amalan-md bg-amalan-white hover:bg-amalan-gray-5">
                                <img src="@/assets/img/pencil-primary-icon.svg" class="ml-px">
                            </button>
                            <button v-else class="border border-amalan-blue-1 rounded-lg w-6 h-6 flex justify-center items-center shadow-amalan-md bg-amalan-white">
                                <ChevronRightIcon class="h-5 text-amalan-blue-1" />
                            </button>
                        </div>
                    </div>
                    <div v-if="!editMode && showMenuDocCollection && !dataOpp.Bill_Statement_1__c" class="text-xs text-red-600">*Lengkapi Lembar Tagihan Anda</div>
                </template>
                <div v-if="touchedOpportunities.length == 0" class="w-full py-3 mt-3.5 border-t border-b border-amalan-gray-4 flex justify-between items-center">
                    <span class="font-bold text-sm text-amalan-blue-1">Total Utang</span>
                    <span class="text-sm text-amalan-blue-1">{{ totalHutang ? $filters.format_number(totalHutang) : '-' }}</span>
                </div>
                <div v-if="editMode" class="mt-14">
                    <template v-if="touchedOpportunities.length == 0">
                        <p v-if="userWithPricingPackage" class="text-center text-amalan-black text-xs">Jika perubahan data pinjaman dengan <b>Total Utang &#8805;50%</b><br>dari Data Utang sebelumnya maka akan dikenakan<br>biaya tambahan</p>
                        <button @click="getPaymentPreview" type="button" class="w-full mt-3.5 rounded-amalan-lg font-bold border text-center text-sm p-3" :class="[ ableToGoNextPage ? 'bg-amalan-blue-1 hover:bg-amalan-blue-2 text-amalan-white cursor-pointer shadow-amalan-md' : 'cursor-not-allowed bg-amalan-gray-4 text-amalan-gray-2' ]" :disabled="!ableToGoNextPage">Selanjutnya</button>
                    </template>
                    <div v-else class="fixed bg-amalan-white shadow-amalan-sm-up bottom-0 inset-x-0 mx-auto max-w-lg py-4 px-6">
                        <div class="w-full flex justify-between items-center">
                            <span class="font-bold text-sm text-amalan-blue-1">Total Utang</span>
                            <span class="text-sm text-amalan-blue-1">{{ totalHutang ? $filters.format_number(totalHutang) : '-' }}</span>
                        </div>
                        <button @click="getPaymentPreview" type="button" class="w-full mt-3.5 rounded-amalan-lg font-bold border text-center text-sm p-3" :class="[ ableToGoNextPage ? 'bg-amalan-blue-1 hover:bg-amalan-blue-2 text-amalan-white cursor-pointer shadow-amalan-md' : 'cursor-not-allowed bg-amalan-gray-4 text-amalan-gray-2' ]" :disabled="!ableToGoNextPage">Selanjutnya</button>
                    </div>
                </div>
                <div v-else class="mt-14">
                    <button @click="editMode = true" type="button" class="w-full rounded-amalan-lg font-bold border text-center text-sm p-3 text-amalan-blue-1 cursor-pointer shadow-amalan-md bg-amalan-white border-amalan-blue-1 hover:bg-amalan-gray-5 flex justify-center items-center"><img src="@/assets/img/pencil-primary-icon.svg" class="mr-1.5"> Ubah Data Pinjaman</button>
                    <button @click="router.push({ name: 'Loan Management - Loan List - Add Loan' })" type="button" class="w-full mt-3.5 rounded-amalan-lg font-bold border text-center text-sm p-3 text-amalan-blue-1 cursor-pointer shadow-amalan-md bg-amalan-white border-amalan-blue-1 hover:bg-amalan-gray-5">+ Tambahkan Data Pinjaman</button>
                </div>
            </template>
            <template v-else>
                <Form
                    @submit="formUbahOpportunitySubmit"
                    :validation-schema="formUbahOpportunitySchema"
                    v-slot="{ errors, meta }"
                >
                    <div class="mb-3.5">
                        <label class="text-amalan-blue-1 font-bold text-sm">Nama Bank/Institusi</label>
                        <div class="w-full text-xs px-4 py-3 mt-1 text-amalan-black bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ formUpdateOpportunity.bankName ?? '-' }}</div>
                    </div>
                    <div class="mb-3.5">
                        <label class="text-amalan-blue-1 font-bold text-sm">Jenis Pinjaman</label>
                        <div class="w-full text-xs px-4 py-3 mt-1 text-amalan-black bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ formUpdateOpportunity.loanType ?? '-' }}</div>
                    </div>
                    <div class="mb-3.5">
                        <label class="text-amalan-blue-1 font-bold text-sm">Total Utang</label>
                        <div class="flex flex-col relative w-full mt-1">
                            <CurrencyInput 
                            v-model="formUpdateOpportunity.outstandingAmount" 
                            :propsClass="errors.outstandingAmount ? 'border-amalan-red rounded-lg' : 'border-amalan-gray-4 rounded-lg'"
                            :options="{
                                currency: 'IDR',
                                currencyDisplay: 'narrowSymbol',
                                hideCurrencySymbolOnFocus: false,
                                hideGroupingSeparatorOnFocus: false,
                                hideNegligibleDecimalDigitsOnFocus: false,
                                autoDecimalDigits: false,
                                autoSign: false,
                                useGrouping: true,
                                accountingSign: true
                            }" />
                            <Field name="outstandingAmount" type="text" :validateOnMount="true" :validateOnInput="true" class="hidden" v-model="formUpdateOpportunity.outstandingAmount" />
                            <div v-if="errors.outstandingAmount" class="mt-0.5 text-amalan-red text-2xs">{{errors.outstandingAmount}}</div>
                        </div>
                    </div>
                    <div class="mb-3.5 grid grid-cols-2 gap-x-4">
                        <div>
                            <label class="text-amalan-blue-1 font-bold text-sm">Lama Tunggakan</label>
                            <div class="flex items-center relative w-full mt-1">
                                <div class="w-full border rounded-lg py-1" :class="[ errors.overdue ? 'border-amalan-red' : 'border-amalan-gray-4']">
                                    <Multiselect
                                    ref="multiselect"
                                    v-model="formUpdateOpportunity.overdue"
                                    :noResultsText="'Data tidak ditemukan.'"
                                    :noOptionsText="'Sedang memuat data...'"
                                    :searchable="true"
                                    :options="dataLamaTunggakan"
                                    :classes="multiselectClass"
                                    />
                                </div>
                                <Field name="overdue" type="text" class="hidden" :validateOnMount="true" v-model="formUpdateOpportunity.overdue" />
                            </div>
                            <div v-if="errors.overdue" class="mt-0.5 text-amalan-red text-2xs">{{errors.overdue}}</div>
                        </div>
                        <div>
                            <label class="text-amalan-blue-1 font-bold text-sm">Nomor Pinjaman</label>
                            <Field placeholder="Nomor Pinjaman" name="accountNumber" v-model="formUpdateOpportunity.accountNumber" type="text" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none" :class="{ 'border-amalan-red': errors.accountNumber }" :validateOnInput="true" :validateOnMount="true"/>
                            <div v-if="errors.accountNumber" class="mt-0.5 text-amalan-red text-2xs">{{errors.accountNumber}}</div>
                        </div>
                    </div>
                    <div class="mb-3.5">
                        <label class="text-amalan-blue-1 font-bold text-sm">Pembayaran Terakhir</label>
                        <Field name="lastPaymentDate" v-model="formUpdateOpportunity.lastPaymentDate" :validateOnInput="true" :validateOnMount="true" type="date" :max="filters.dateNow()" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none pl-4 pr-3" :class="{ 'border-amalan-red': errors.lastPaymentDate }" />
                        <div v-if="errors.lastPaymentDate" class="mt-1 text-2xs text-amalan-red">{{errors.lastPaymentDate}}</div>
                    </div>
                    <button type="submit" class="w-full mt-11 rounded-amalan-lg font-bold text-center text-sm p-3" :class="[ meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!meta.valid">Simpan</button>
                </Form>
            </template>
        </div>
        <div v-else class="w-full h-screen px-6 flex flex-col justify-center items-center">
            <img src="@/assets/img/clipboard-v2.svg" class="w-2/6 -mt-36 mx-auto">
            <p class="text-center text-sm mt-6">Anda belum punya daftar pinjaman. Yuk, registrasikan daftar pinjaman Anda dengan layanan <b>Keringanan Pinjaman Full Service</b> dari amalan!</p>
            <router-link to="/sign-up/onboarding" class="w-56 mt-3.5 rounded-amalan-lg font-bold border text-center text-sm p-3 text-amalan-white cursor-pointer shadow-amalan-md bg-amalan-blue-1 border-amalan-blue-1 hover:bg-amalan-blue-3 hover:border-amalan-blue-3">
                Registrasi Sekarang
            </router-link>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>