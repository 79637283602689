import moment from 'moment'
require("moment/min/locales.min")
moment.locale('id')

export default {
    formatDate(value) {
        return moment(String(value)).format('DD/MM/YYYY hh:mm')
    },
    formatDateOnlyDate(value){
        return moment(value).format('DD MMMM YYYY')
    },
    formatDay(value){
        return moment(String(value)).format('dddd');
    },
    addOneHour(value){
        return moment(value,'HH:mm:ss').add(1,'hours').format('HH:mm:ss');
    },
    formatDateCustom(value, format){
        return moment(String(value)).format(format);
    },
    formatHour(value){
        return moment(value, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss');
    },
    formatHourMinute(value){
        return moment(value,'HH:mm:ss').format('HH.mm');
    },
    formatHourMinuteAddOneHour(value){
        return moment(value,'HH:mm:ss').add(1,'hours').format('HH.mm');
    },
    formatDateTimeAmalanProtect(value) {
        return moment(String(value)).format('DD MMMM YYYY - hh:mm');
    },
    formatDateTimePaymentExpiration(value) {
        return moment(value).format('DD MMMM YYYY - HH:mm:ss');
    },
    dateNow(){
        return moment().format('YYYY-MM-DD');
    },
    dateTimeNow(){
        return moment().format('YYYY-MM-DD HH:mm:ss');
    },
    diffDateOnDays(date1, date2){
        const a = moment(date1,'YYYY-MM-DD');
        const b = moment(date2,'YYYY-MM-DD');
        const diffDays = b.diff(a, 'days');
        return diffDays
    },
    diffDateOnHours(date1, date2){
        const a = moment(moment(date1).format('YYYY-MM-DD HH:mm:ss'),'YYYY-MM-DD HH:mm:ss')
        const b = moment(moment(date2).format('YYYY-MM-DD HH:mm:ss'),'YYYY-MM-DD HH:mm:ss')
        const diff = b.diff(a, 'hours')
        return diff
    },
    getDaysInMonth(dateNow){
        return moment(dateNow).daysInMonth()
    },
    formatDateShortMonth(date){
        return moment(date).format('DD MMM YYYY')
    },
    addDaysFromSomeDay(date, number){
        return moment(date,'YYYY-MM-DD').add(number,'days').format('YYYY-MM-DD')
    },
    addMonthFromToday(number){
        return moment().add(number,'months').format('YYYY-MM-DD')
    },
    formatMonthAndYearOnly(date){
        return moment(date).format('MMMM YYYY')
    },
    formatMonthAndYearBetweenTwoDate(date1, date2){
        const dateStart = moment(date1)
        const dateEnd = moment(date2)
        const data = []

        while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
            let initial = {
                date: dateStart.format('YYYY-MM-01'),
                dateStart: dateStart.startOf('month').format('YYYY-MM-DD'),
                dateEnd: dateStart.endOf('month').format('YYYY-MM-DD'),
                dateFormated: dateStart.format('MMMM YYYY'),
                dateYearAndMonth: dateStart.format('YYYY-MM')
            }

            data.push(initial)
            dateStart.add(1,'month')
        }

        return data
    },
    getYearBetweenTwoDate(date1, date2){
        const yearStart = moment(date1)
        const yearEnd = moment(date2)
        const data = []

        while (moment(yearEnd).format('YYYY') >= moment(yearStart).format('YYYY')) {
            data.push(yearStart.format('YYYY'))
            yearStart.add(1,'year')
        }

        return data
    },
    reduceMonthFromSomeDay(date, number){
        return moment(date,'YYYY-MM-DD').subtract(number,'months').format('YYYY-MM-DD')
    },
    getDayOnNumberOnly(date){
        return moment(date).format('D')
    },
    dateFormatDayWithoutZero(date){
        return moment(date).format('YYYY-MM-D')
    },
    dateTimeFormatBasic(date){
        return moment(date).format('YYYY-MM-D HH:mm:ss')
    },
    dateFormatDayFirst(date){
        return moment(date).format('DD-MM-YYYY')
    },
    dateLongDayAndDMY(date){
        return moment(date).format('dddd, DD MMMM YYYY')
    },
    dateTimeFormatOnlyHourMinute(date){
        return moment(date).format('HH.mm')
    }
}