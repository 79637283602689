import amalanSignup from "@/axios/amalansignup"

const sentCheckout = (service_id) => {
    window.dataLayer?.push({
        event: 'Checkout',
        pixel_event_id: 'InitiateCheckout_' + service_id,
    })

    amalanSignup.post('/event-api/facebook', {
        event_name: 'InitiateCheckout', 
        service_id
    })
}

const sentPurchase = (service_id) => {
    window.dataLayer?.push({
        event: 'Purchase',
        pixel_event_id: 'Purchase_' + service_id,
    })

    amalanSignup.post('/event-api/facebook', {
        event_name: 'Purchase', 
        service_id
    })
}

export default {
    sentCheckout,
    sentPurchase
}