<script setup>
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { useRouter, useRoute } from 'vue-router'
import { onMounted, ref } from 'vue'

const router = useRouter()
const route = useRoute()
const title = ref('')
const urlSource = ref(null)

const setTitle = () => title.value = route.query.title || ''
const setUrlSource = () => {
    const decodedUrl = route.query.to ? decodeURIComponent(route.query.to) : null

    if(!decodedUrl) return router.go(-1)
    urlSource.value = decodedUrl
}

onMounted(() => {
    window.scrollTo(0, 0)

    setTitle()
    setUrlSource()
})
</script>

<template>
    <div class="min-h-screen bg-amalan-white">
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="router.go(-1)" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold text-amalan-black">{{ title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <iframe v-if="urlSource" :src="urlSource" class="w-full min-h-screen"></iframe>
    </div>
</template>