<template>
    <input ref="inputRef" type="text" class="block w-full px-4 py-3 mt-1 text-xs text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none pl-4 pr-8" :class="propsClass" />
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
    name: "CurrencyInput2",
    props: {
        propsClass: String,
        modelValue: Number,
        options: Object,
    },
    setup(props) {
        const { inputRef } = useCurrencyInput(props.options);

        return { inputRef };
    },
};
</script>
