<script setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from "vue-router"
import setCookies from '@/composables/setCookies'

const router = useRouter()
const route = useRoute()
const showPage = ref(false)
const hideLewati = ref(false)
const firstStep = ref(true)
const secondStep = ref(false)
const thirdStep = ref(false)
const currentPosition = ref(1)
const isLoading = ref(false)

const nextStep = () => {
    if(currentPosition.value == 1){
        hideLewati.value = true
        secondStep.value = true
        firstStep.value = false
        thirdStep.value = false
        currentPosition.value = 2
    }else if(currentPosition.value == 2){
        hideLewati.value = true
        thirdStep.value = true
        firstStep.value = false
        secondStep.value = false
        currentPosition.value = 3
    }else{
        isLoading.value = true
        router.push({ name : 'Login' })
    }
}

const skip = () => {
    isLoading.value = true
    router.push({ name : 'Login' })
}

onMounted(() => {
    localStorage.setItem('from-onboarding-go-to', 'Loan Consolidation - Registrasi - Informasi Umum')
    
    const queryParams = route.query
    setCookies.setUtmCookies(queryParams)

    setTimeout(() => {
        showPage.value = true
    }, 500)
})
</script>

<template>
    <div class="bg-amalan-white text-amalan-black min-h-screen">
        <Transition name="fade-fast">
            <div v-show="showPage" class="w-full h-800 ssm:h-900 relative">
                <div class="w-full bg-amalan-white h-600 relative overflow-hidden">
                    <img class="h-12 absolute z-10 inset-x-0 mx-auto top-12" src="@/assets/img/logo-amalan.png">
                    <Transition name="slide">
                        <div v-show="firstStep" class="w-full absolute z-5 justify-center flex sssm:bottom-0">
                            <img class="w-full" src="@/assets/img/loan-consolidation/onboarding/banner-1.png">
                        </div>
                    </Transition>
                    <Transition name="slide">
                        <div v-show="secondStep" class="w-full absolute z-5 justify-center flex sssm:bottom-0">
                            <img class="w-full" src="@/assets/img/loan-consolidation/onboarding/banner-2.png">
                        </div>
                    </Transition>
                    <Transition name="slide">
                        <div v-show="thirdStep" class="w-full absolute z-5 justify-center flex sssm:bottom-0">
                            <img class="w-full" src="@/assets/img/loan-consolidation/onboarding/banner-3.png">
                        </div>
                    </Transition>
                </div>
                <div class="w-full absolute bottom-0 z-3 h-500 overflow-hidden">
                    <Transition name="slide-100">
                        <div v-show="firstStep" class="w-full absolute top-48 h-full px-6">
                            <p class="text-center font-bold text-base text-amalan-black">Kredit Bijak Untuk Karyawan Bijak</p>
                            <p class="text-center text-xs text-amalan-black mt-3.5">Membantu Anda menghilangkan beban berbagai<br>jenis utang yang memberatkan keuangan Anda</p>
                        </div>
                    </Transition>
                    <Transition name="slide-100">
                        <div v-show="secondStep" class="w-full absolute top-48 h-full px-6">
                            <p class="text-center font-bold text-base text-amalan-black">Lengkapi Informasi Data Diri,<br>Pekerjaan, dan Daftar Pinjaman</p>
                            <p class="text-center text-xs text-amalan-black mt-3.5">amalan akan menjamin keamanan data diri,<br>pekerjaan, dan privasi Anda!</p>
                        </div>
                    </Transition>
                    <Transition name="slide-100">
                        <div v-show="thirdStep" class="w-full absolute top-48 h-full px-6">
                            <p class="text-center font-bold text-base text-amalan-black">Cek Kembali Data Anda dan<br>Tunggu dengan Tenang!</p>
                            <p class="text-center text-xs text-amalan-black mt-3.5">Pengajuan Anda akan Ditinjau dan Konsultan<br>amalan akan segera menghubungi Anda!</p>
                        </div>
                    </Transition>
                    <div class="w-full absolute bottom-9">
                        <Transition name="fade-fast">
                            <div v-if="firstStep" class="w-full flex justify-center absolute">
                                <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            </div>
                        </Transition>
                        <Transition name="fade-fast">
                            <div v-if="secondStep" class="w-full flex justify-center absolute">
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                                <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            </div>
                        </Transition>
                        <Transition name="fade-fast">
                            <div v-if="thirdStep" class="w-full flex justify-center absolute">
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                                <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                                <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                            </div>
                        </Transition>
                        <div class="w-full px-6">
                            <div @click="nextStep" class="w-full mt-14 py-3 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg cursor-pointer">
                                <p class="text-amalan-white text-sm font-bold text-center">{{ currentPosition == 3 ? 'Mulai Sekarang' : 'Selanjutnya' }}</p>
                            </div>
                            <div class="w-full h-5 mt-4 flex justify-center items-center">
                                <Transition name="fade-fast">
                                    <div v-if="!hideLewati" @click="skip" class="text-amalan-blue-1 hover:text-amalan-blue-2 text-sm font-bold underline cursor-pointer">Lewati</div>
                                </Transition>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
        <loading-overlay v-if="isLoading" />
    </div>
</template>

<style scoped>
.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.8s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.8s ease
}

.slide-enter-active,
.slide-leave-active,
.slide-100-enter-active,
.slide-100-leave-active {
    transition: transform 0.6s ease-in-out;
}

.slide-enter-from {
    transform: translate(110%, 0);
}

.slide-leave-to {
    transform: translate(-110%, 0);
}

.slide-100-enter-from {
    transform: translate(100%, 0);
}

.slide-100-leave-to {
    transform: translate(-100%, 0);
}
</style>