const state = () => ({
    showTermAndCondition: false,
    cekAjaNewRekening: false,
    amalanTiktokAds: false,
    creditScoreBanner: false,
    preventUserFromHistoryBack: false
});

const getters = {
    getTermAndConditionStatus(state) {
        return state.showTermAndCondition;
    },
    getCekAjaNewRekening(state) {
        return state.cekAjaNewRekening;
    },
    getAmalanTiktokAds(state) {
        return state.amalanTiktokAds;
    },
    getCreditScoreBanner(state) {
        return state.creditScoreBanner;
    },
    getPreventUserFromHistoryBack(state) {
        return state.preventUserFromHistoryBack;
    },
};

const actions = {
    changeTermAndConditionStatus({ commit }) {
        commit("changeTermAndConditionStatus")
    },
    changeCekAjaNewRekening({ commit }) {
        commit("changeCekAjaNewRekening")
    },
    changeAmalanTiktokAds({ commit }) {
        commit("changeAmalanTiktokAds")
    },
    changeCreditScoreBanner({ commit }) {
        commit("changeCreditScoreBanner")
    },
    setPreventUserFromHistoryBack({ commit }, payload) {
        commit("setPreventUserFromHistoryBack", payload)
    },
};

const mutations = {
    changeTermAndConditionStatus(state) {
        state.showTermAndCondition = state.showTermAndCondition ? false : true
    },
    changeCekAjaNewRekening(state) {
        state.cekAjaNewRekening = state.cekAjaNewRekening ? false : true
    },
    changeAmalanTiktokAds(state) {
        state.amalanTiktokAds = state.amalanTiktokAds ? false : true
    },
    changeCreditScoreBanner(state) {
        state.creditScoreBanner = state.creditScoreBanner ? false : true
    },
    setPreventUserFromHistoryBack(state, payload) {
        state.preventUserFromHistoryBack = payload.value
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
