import amalansignupApi from "../../axios/amalansignup";
import vm from "../../main";

const state = () => ({
    dataPinjaman: null,
    dataPelanggaran: null,
    docPendukung: null,
    tempDocPendukung: null,
    protectStatus: {
        status: null,
        data: null
    },
    protectLast: {
        Id: null,
        status: null,
        data: null,
        dateTime: null
    },
    purchaseList: {
        pending: [],
        done: []
    }
});

const getters = {
    getDataPinjaman: (state) => {
        return state.dataPinjaman;
    },
    getDataPelanggaran: (state) => {
        return state.dataPelanggaran;
    },
    getDocPendukung: (state) => {
        return state.docPendukung;
    },
    getTempDocPendukung: (state) => {
        return state.tempDocPendukung;
    },
    getProtectStatus(state) {
        return state.protectStatus.status;
    },
    getProtect(state) {
        return state.protectStatus;
    },
    getLastProtect(state) {
        return state.protectLast;
    },
    getPurchaseList(state) {
        return state.purchaseList;
    },
};

const actions = {
    storeDataPinjaman({ commit }, payload) {
        return commit("storeDataPinjaman", payload);
    },
    storeDataPelanggaran({ commit }, payload) {
        return commit("storeDataPelanggaran", payload);
    },
    storeDocPendukung({ commit }, payload) {
        return commit("storeDocPendukung", payload);
    },
    storeTempDocPendukung({ commit }, payload) {
        return commit("storeTempDocPendukung", payload);
    },
    removeDocPendukung({ commit }) {
        return commit("removeDocPendukung");
    },
    removeAll({ commit }) {
        return commit("removeAll");
    },
    requestProtectStatus({ commit }, payload) {
        return amalansignupApi.get('salesforce/amalan-protects/bank-complaints/' + payload + '/check-status')
        .then( resp => {
            commit("requestProtectStatus", resp);
        })
        .catch(err => {
            if(err.response.status == 404){
                commit("requestProtectStatus", err.response);
            }else{
                const swalErrorReload = vm.$swal.mixin({
                    customClass: {
                        confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                    },
                    buttonsStyling: false,
                    title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                    showCancelButton: false,
                    confirmButtonText: 'Perbaharui',
                    width: '340px',
                    allowOutsideClick: false,
                })
    
                swalErrorReload.fire().then((result) => {
                    if (result.isConfirmed){
                        window.location.reload();
                    }
                });
            }
        })
    },
    requestPurchaseList({ commit }) {
        return amalansignupApi.get('salesforce/amalan-protects/bank-complaints')
        .then( resp => {
            commit("requestPurchaseList", resp);
        })
        .catch(() => {
            const swalErrorReload = vm.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })

            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        })
    },
    async requestProtectLast({ dispatch, commit, rootGetters }) {
        amalansignupApi.get('salesforce/amalan-protects/bank-complaints/last-complaint/bank')
        .then( resp => {
            if(resp.data.data.totalSize && resp.data.data.totalSize > 0){
                let getId = resp.data.data.records[0].Id;
                let dateTime = resp.data.data.records[0].CreatedDate;
                amalansignupApi.get('salesforce/amalan-protects/bank-complaints/' + getId + '/check-status')
                .then( resp => {
                    commit("requestProtectLastId", getId);
                    commit("requestProtectLastDateTime", dateTime);
                    commit("requestProtectLast", resp);
                    dispatch('pendingUserActivity/setPendingActivityData', {
                        userStage: rootGetters['user/getUserStage'],
                        signUpStatus: rootGetters['contact/getSignUpStatus'],
                        lastAmalanProtect: rootGetters['formAmalanProtect/getLastProtect'],
                        latestService: rootGetters['services/getLatestService'],
                        timeToDocCollection: rootGetters['docCollection/getTimeToDocCollection'],
                        billCompleted: rootGetters['docCollection/getbillStatus'],
                        docCompleted: rootGetters['docCollection/getDocStatus'],
                        poaDocument: rootGetters['user/getDocumentPoa'],
                        lastAdditionalSuf: rootGetters['additionalSUF/getLastAdditionalSuf'],
                        lastUpgradePackage: rootGetters['upgradePricingPackage/getLastUpgradePackage'],
                        negotiationResultApprovalFlag: rootGetters['negotiationResult/getNegotiationResultApprovalFlag']
                    }, {
                        root: true 
                    })
                })
                .catch(err => {
                    if(err.response.status == 404){
                        commit("requestProtectLast", err.response);
                    }else{
                        const swalErrorReload = vm.$swal.mixin({
                            customClass: {
                                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                            },
                            buttonsStyling: false,
                            title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                            html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                            showCancelButton: false,
                            confirmButtonText: 'Perbaharui',
                            width: '340px',
                            allowOutsideClick: false,
                        })
            
                        swalErrorReload.fire().then((result) => {
                            if (result.isConfirmed){
                                window.location.reload();
                            }
                        });
                    }
                })
            }
        })
        .catch(() => {
            const swalErrorReload = vm.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })

            swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        })
    },
};

const mutations = {
    storeDataPinjaman(state, payload) {
        state.dataPinjaman = payload;
    },
    storeDataPelanggaran(state, payload) {
        state.dataPelanggaran = payload;
    },
    storeDocPendukung(state, payload) {
        state.docPendukung = payload;
    },
    storeTempDocPendukung(state, payload) {
        state.tempDocPendukung = payload;
    },
    removeDocPendukung(state) {
        state.docPendukung = null;
        state.tempDocPendukung = null;
    },
    removeAll(state) {
        state.dataPinjaman = null;
        state.dataPelanggaran = null;
        state.docPendukung = null;
        state.tempDocPendukung = null;
    },
    requestProtectStatus(state, payload) {
        state.protectStatus.status = payload.status;
        if(payload.status !== 404){
            state.protectStatus.data = payload.data.data;
        }
    },
    requestProtectLast(state, payload) {
        state.protectLast.status = payload.status;
        if(payload.status !== 404){
            state.protectLast.data = payload.data.data;
        }
    },
    requestProtectLastId(state, payload){
        state.protectLast.Id = payload
    },
    requestProtectLastDateTime(state, payload){
        state.protectLast.dateTime = payload
    },
    requestPurchaseList(state, payload){
        if(payload.data.data.totalSize && payload.data.data.totalSize > 0){
            const recordData = payload.data.data.records;
            let pendingData = [];
            let doneData = [];
            for(let i in recordData){
                if(recordData[i].Paid__c == true){
                    doneData.push(recordData[i]);
                }else{
                    pendingData.push(recordData[i]);
                }
            }

            state.purchaseList.pending = pendingData;
            state.purchaseList.done = doneData;
        }else{
            state.purchaseList.pending = [];
            state.purchaseList.done = [];
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
