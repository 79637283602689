<script setup>
import { computed } from 'vue'
import { ChevronRightIcon } from '@heroicons/vue/solid'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const router = useRouter()
const store = useStore()

const dataOpportunities = computed(() => store.getters['loanProgress/getOpportunitiesData'])
const outstandingAmount = computed(() => store.getters['loanProgress/getOutstandingAmount'])

</script>

<template>
    <div @click="router.push({ name: 'Loan Management - Home', query: { from: 'widget' } })" class="w-full bg-amalan-blue-1 px-4 py-4 rounded-xl shadow-amalan-sm relative overflow-hidden hover:bg-amalan-blue-2 cursor-pointer">
        <div class="absolute z-1 -bottom-9 -right-9 w-22 h-22 rounded-full bg-transparent border border-amalan-yellow-400 opacity-30 flex justify-center items-center">
            <div class="w-20 h-20 rounded-full bg-amalan-white bg-opacity-50"></div>
        </div>
        <div v-if="dataOpportunities.length > 0" class="flex justify-between items-center">
            <div class="flex-grow mr-5">
                <div class="w-full">
                    <div class="w-full bg-amalan-gray-5 h-2.5 rounded-amalan-lg overflow-hidden">
                        <table :style="{ width: ((dataOpportunities[2].outstanding_amount/outstandingAmount) * 100) + '%' }">
                            <tr class="h-2.5 bg-gradient-to-r from-amalan-blue-4 to-amalan-yellow-400 cursor-pointer">
                                <td v-for="(opportunity, index) in dataOpportunities[2].data" :key="index" :class="index !== (dataOpportunities[2].data.length - 1) ? 'border-r-2 border-amalan-gray-5' : ''" :style="'width: ' + ((opportunity.Estimated_Outstanding_Amount__c/dataOpportunities[2].outstanding_amount) * 100) + '%'"></td>
                            </tr>
                        </table>
                    </div>
                    <p class="w-full mt-1.5 text-amalan-white text-3xs"><span class="font-bold text-2xs">{{ $filters.format_number(dataOpportunities[2].outstanding_amount) }}</span> telah lunas dari Total {{ $filters.format_number(outstandingAmount) }}</p>
                </div>
                <div class="w-full flex justify-start items-center mt-4">
                    <div class="w-4 h-4 bg-amalan-yellow-400 rounded-full flex justify-center items-center">
                        <span class="text-amalan-black text-2xs font-bold">{{ dataOpportunities[2].data.length }}</span>
                    </div>
                    <span class="text-amalan-white text-3xs ml-1">Lunas</span>
                    <div class="w-4 h-4 bg-amalan-yellow-400 rounded-full flex justify-center items-center ml-2">
                        <span class="text-amalan-black text-2xs font-bold">{{ dataOpportunities[0].data.length }}</span>
                    </div>
                    <span class="text-amalan-white text-3xs ml-1">{{ dataOpportunities[0].group }}</span>
                    <div class="w-4 h-4 bg-amalan-yellow-400 rounded-full flex justify-center items-center ml-2">
                        <span class="text-amalan-black text-2xs font-bold">{{ dataOpportunities[1].data.length }}</span>
                    </div>
                    <span class="text-amalan-white text-3xs ml-1">{{ dataOpportunities[1].group }}</span>
                </div>
            </div>
            <div class="flex-none w-6 h-6 relative">
                <button class="border border-amalan-white rounded-lg shadow-amalan-md w-6 h-6 flex justify-center items-center bg-amalan-blue-1 absolute z-2">
                    <ChevronRightIcon class="h-5 text-amalan-white" />
                </button>
            </div>
        </div>
    </div>
</template>