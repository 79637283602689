export const format_number_compact = (value) => {
    if (typeof value !== "number") {
        return value;
    }

    var formatter = new Intl.NumberFormat('id-ID', {
        currency: 'IDR',
        minimumFractionDigits: 0,
        notation: 'compact'
    });

    return formatter.format(value);
}