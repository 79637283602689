<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
    withAction: Boolean,
    bankName: String,
    imageSource: String,
    loanType: String,
    outstanding: String
})

const emit = defineEmits(['onAction'])

const buttonClick = () => emit('onAction')

</script>

<template>
    <button @click="buttonClick" class="w-full rounded-lg border bg-amalan-white border-amalan-gray-4 p-4 flex justify-between items-center" :class="[ props.withAction ? 'hover:bg-amalan-gray-5 cursor-pointer focus:bg-amalan-yellow-100 focus:border-amalan-yellow-400' : 'cursor-default' ]">
        <div class="flex-none w-14 h-14 rounded-lg flex justify-center items-center">
            <img :src="props.imageSource" class="w-14">
        </div>
        <div class="flex-grow px-2.5 text-left">
            <p class="text-sm font-bold">{{ props.bankName }}</p>
            <p class="text-xs mt-0.5">{{ props.loanType }}</p>
            <p class="text-xs mt-1">{{ props.outstanding }}</p>
        </div>
    </button>
</template>