<script setup>
import { computed, onMounted, ref } from 'vue'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import amalansignupApi from "@/axios/amalansignup"
import VuePdfEmbed from 'vue-pdf-embed'
import { CheckIcon } from '@heroicons/vue/solid'
import useModalPopUp from '@/composables/modalPopUp'
import DoneBanner from '@/components/icon/DoneBanner'

const store = useStore()
const router = useRouter()
const route = useRoute()
const modalPopUp = useModalPopUp()

const isLoading = ref(true)
const getDataLoading = ref(false)
const sendDataLoading = ref(false)
const userAgreement = ref(false)
const pdfContainer = ref(null)
const pdfContainerWidth = ref(500)
const zoomIn = ref(true)
const dataUser = computed(() => store.getters['user/getUser'])
const dataDocumentPoa = computed(() => store.getters['user/getDocumentPoa'])
const rdpData = computed(() => store.getters['RDPManagement/getRdpData'])
const fileDownloadBaseUrl = process.env.VUE_APP_SIGNUP_SERVICE_BASE_URL
const prevDiff = ref(-1)
const containerValue = ref(0)

const requestDocumentPoa = () => {
    getDataLoading.value = true
    amalansignupApi.get('customers/document-poa')
    .then((resp) => {
        getDataLoading.value = false
        store.dispatch('user/requestDocumentPoa', resp.data.data)
        const requestInfo = JSON.parse(resp.data.data.request_info)
        if(requestInfo.rdp_preform_done_at){
            if(dataUser.value.email_verified_at) return
            router.push({ name : 'rdp opening - email verification' })
        }else{
            router.push({ name : 'rdp opening - preform' })
        }
    })
    .catch((err) => {
        getDataLoading.value = false
        if(err.response.status !== 404){
            modalPopUp.swalErrorReload.fire().then((result) => {
                if (result.isConfirmed){
                    window.location.reload();
                }
            });
        }
    })
}

const pdfContainerClick = () => {
    if(zoomIn.value){
        pdfContainerWidth.value = 800
        zoomIn.value = !zoomIn.value
    }else{
        pdfContainerWidth.value = 500
        zoomIn.value = !zoomIn.value
    }
}

const handleDocumentRender = () => {
    isLoading.value = false
    const vueEmbedClass = document.querySelector(".vue-pdf-embed")
    const child = vueEmbedClass.children
    for (let i = 0; i < (child.length - 1); i++) {
        child[i].style.borderBottom = '10px solid #E4E5E6'
    }

    vueEmbedClass.ontouchstart = touchStartHandler
    vueEmbedClass.ontouchmove = touchMoveHandler
    vueEmbedClass.ontouchcancel = touchEndHandler
    vueEmbedClass.ontouchend = touchEndHandler
}

const touchMoveHandler = (event) => {
    if (event.touches.length > 1) {
        const currDiff = Math.abs(event.touches[0].clientX - event.touches[1].clientX)

        if (prevDiff.value > 0) {
            if (currDiff > prevDiff.value) {
                containerValue.value += 1
                if(containerValue.value === 10) pdfContainerWidth.value = 600
                if(containerValue.value === 20) pdfContainerWidth.value = 800
            }
            if (currDiff < prevDiff.value) {
                containerValue.value -= 1
                if(containerValue.value === -10) pdfContainerWidth.value = 500
                if(containerValue.value === -20) pdfContainerWidth.value = 300
            }
        }

        prevDiff.value = currDiff;
    }
}

const touchStartHandler = () => {
    prevDiff.value = -1
    containerValue.value = 0
}

const touchEndHandler = () => {
    prevDiff.value = -1
    containerValue.value = 0
}

const postSignature = () => {
    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs text-amalan-black mt-2">Apakah Anda menyetujui isi Surat Kuasa ini untuk melakukan pembukaan Rekening Dana Pelunasan sebagai rekening tabungan pelunasan utang Anda di amalan?</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Konfirmasi',
        cancelButtonText: 'Batal',
    }).then((result) => {
        if (result.isConfirmed){
            sendDataLoading.value = true
            amalansignupApi.post('vida/eSignPerform')
            .then(() => {
                sendDataLoading.value = false
                requestDocumentPoa()
            })
            .catch(() => {
                sendDataLoading.value = false
                modalPopUp.swalError.fire({
                    html: '<p class="text-center text-xs text-amalan-black mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                }).then((result) => {
                    if (result.isConfirmed){
                        window.location = 'https://wa.me/622150933150?text=' + 
                        'Mohon bantuan saya gagal terus untuk menyetujui surat kuasa pada proses pembukaan RDP di web.amalan.com'
                        return
                    }
                })
            })
        }
    })
}

const userAgreementChecked = () => {
    userAgreement.value = !userAgreement.value
}

onMounted(() => {
    if(rdpData.value.isExist === 'FOUND') return router.push({ name : 'rdp - home' })
    requestDocumentPoa()
})

</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="router.push({ name : 'Home' })" class="flex items-center mt-0">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold mt-0 text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <loading-overlay v-if="getDataLoading" />
        <div v-else class="w-full px-6 pb-6 text-amalan-black">
            <template v-if="dataDocumentPoa">
                <div v-if="dataDocumentPoa.status == 0" class="min-h-screen pb-10">
                    <div class="w-full py-8 flex justify-between items-center">
                        <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                            <span class="text-sm text-amalan-blue-1 font-bold">1</span>
                        </div>
                        <div class="bg-amalan-blue-1 h-px flex-none w-3"></div>
                        <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-yellow-400 mx-1">
                            <span class="text-sm font-bold text-amalan-blue-1">2</span>
                        </div>
                        <div class="bg-amalan-blue-1 h-px flex-grow"></div>
                        <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none ml-1">
                            <span class="text-sm text-amalan-blue-1 font-bold">3</span>
                        </div>
                        <p class="flex-none text-amalan-blue-1 text-sm font-bold mx-1">Tanda Tangan</p>
                    </div>
                    <div class="px-2 py-2 rounded-xl border border-amalan-gray-4 h-screen-85 bg-amalan-gray-4">
                        <div class="bg-amalan-gray-4 h-full overflow-y-scroll scrollbar pr-1" :class="isLoading ? 'flex justify-center items-center' : ''">
                            <VuePdfEmbed
                                ref="pdfContainer"
                                :style="zoomIn ? 'cursor: -webkit-zoom-in' : 'cursor: -webkit-zoom-out'"
                                :width="pdfContainerWidth"
                                :source="fileDownloadBaseUrl + 'download-public-file-gcs?publicUrl=' + encodeURIComponent(dataDocumentPoa.doc_preview_url_public)"
                                @click="pdfContainerClick"
                                @rendered="handleDocumentRender"
                            />
                            <loading-data-without-margin v-if="isLoading" />
                        </div>
                    </div>
                    <template v-if="!isLoading">
                        <div class="px-4 pt-2 flex justify-between items-start mt-2">
                            <label class="font-medium text-xs inline-flex" for="userAgreement">
                                <label class="h-4 w-4 border-2 border-gray-400 rounded mr-2 p-0 cursor-pointer" :class="[ userAgreement ? 'bg-amalan-yellow-400 border-amalan-yellow-400' : 'border-red-600']" @click="userAgreementChecked">
                                    <CheckIcon v-if="userAgreement" class="w-3 h-3 mx-auto text-amalan-blue-1" />
                                    <div v-else class="w-3 h-3 mx-auto bg-transparent"></div>
                                </label>
                                <input name="userAgreement" v-model="userAgreement" type="checkbox" class="hidden">
                            </label>
                            <p class="text-xs cursor-pointer"><span @click="userAgreementChecked">Pengguna dengan ini menyetujui untuk memberikan data pengguna kepada amalan dan VIDA sebagai partner dari amalan International untuk verifikasi identitas dalam rangka pembukaan rekening. Lebih lanjut mengenai kebijakan privasi dan penyelenggaraan sertifikat elektronik oleh VIDA dapat diakses melalui</span> <a href="https://repo.vida.id" class="text-amalan-blue-3" target="_blank">https://repo.vida.id</a></p>
                        </div>
                        <div v-if="!userAgreement" class="text-xs text-red-600 px-4">*Silahkan dicentang</div>
                        <div class="w-full flex justify-center mt-8">
                            <button type="button" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ userAgreement ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!userAgreement" @click="postSignature">Setuju</button>
                        </div>
                    </template>
                </div>
                <div v-else class="w-full min-h-screen -mt-14 flex flex-col justify-center items-center">
                    <DoneBanner />
                    <p class="text-center text-xs text-amalan-black mt-7">Proses Pembukaan Rekening Dana Pelunasan (RDP)<br>Anda telah selesai dilakukan. <br><br>Selanjutnya konsultan kami akan segera<br>menghubungi Anda untuk proses berikutnya.</p>
                    <router-link to='/home' class="w-full flex items-center justify-center py-3 px-2 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg mt-7 text-amalan-white text-sm font-bold">Kembali ke Beranda</router-link>
                </div>
            </template>
            <div v-else class="mt-32">
                <img src="@/assets/img/clipboard.svg" class="w-2/6 mx-auto">
                <p class="text-center text-sm mt-8">Halo, sobat amalan.<br>Mohon maaf, sepertinya Pembukaan Rekening Dana Pelunasan (RDP) Anda masih dalam proses.<br>Silahkan ditunggu!</p>
            </div>
        </div>
        <loading-overlay v-if="sendDataLoading" />
    </div>
</template>

<style scoped>
.scrollbar::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

.scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #FFFFFF;
    border: 1px solid #BBBBBC
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #BBBBBC;
    border: 3px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #989A9B;
}
</style>