import stytchApi from "../../axios/stytch";

const state = () => ({
    accessToken : null,
    refreshToken : null,
    loginStatus : "false",
});

const getters = {
    getLoginStatus(state) {
        return state.loginStatus;
    },
    getAccessToken(state) {
        return state.accessToken;
    },
    getRefreshToken(state) {
        return state.refreshToken;
    }
};

const actions = {
    async login({ commit }, payload) {
        commit("storeToken", payload);
        commit("setLoginStatus", "true");
    },
    logout({ commit }) {
        const params = {
            access_token: localStorage.getItem("accessToken"),
            refresh_token: localStorage.getItem("refreshToken")
        }

        commit("removeToken");
        commit("setLoginStatus", "false");
        return stytchApi.post('revoke-token', params)
        .then(() => {
            return true
        })
        .catch(() => {
            return true
        })
    }
};

const mutations = {
    storeToken(state, payload) {
        state.accessToken = payload.session_token;
        localStorage.setItem("accessToken", payload.session_token);
        if(payload.session_refresh_token){
            state.refreshToken = payload.session_refresh_token;
            localStorage.setItem("refreshToken", payload.session_refresh_token);
        }
    },
    removeToken(state) {
        if(localStorage.getItem("refreshToken")){
            localStorage.removeItem("refreshToken");
        }
        localStorage.removeItem("accessToken");
        state.accessToken = null;
        state.refreshToken = null;
    },
    setLoginStatus(state, value) {
        state.loginStatus = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
