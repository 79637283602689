<script setup>
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { ArrowSmLeftIcon, ArrowSmRightIcon, ChevronDownIcon, SearchIcon } from '@heroicons/vue/solid'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { ref, onMounted, inject, computed, watch } from 'vue'
import LineChart from '@/components/Chart/LineChart.vue'
import moment from 'moment'
import BasicTooltip from '@/components/Tooltip/BasicTooltip.vue'
import { onClickOutside } from '@vueuse/core'

const router = useRouter()
const route = useRoute()
const store = useStore()
const filters = inject("Filters")

const rdpData = computed(() => store.getters['RDPManagement/getRdpData'])
const selectedFilter = computed(() => store.getters['SavingActivity/getFilterData'])
const fetchStatus = computed(() => store.getters['SavingActivity/getFetchStatus'])
const chartOption = computed(() => store.getters['SavingActivity/getChartConfigData'])
const outstandingAmount = computed(() => store.getters['loanProgress/getOutstandingAmount'])
const dataOpportunities = computed(() => store.getters['loanProgress/getOpportunitiesData'])
const opportunitiesFetchStatus = computed(() => store.getters['loanProgress/getOpportunitiesFetchStatus'])
const unpaidOutstandingAmount = computed(() => store.getters['loanProgress/getUnpaidOutstandingAmount'])
const savingSeriesData = computed(() => store.getters['SavingActivity/getSavingSeriesData'])

const chartInformation = ref({
    year: selectedFilter.value.year
})

const filterConfig = ref({
    type: ['Bulanan', 'Tahunan'],
    yearList: []
})

const showFilterType = ref(false)
const showFilterYear = ref(false)
const typeOptionComponent = ref(null)
const yearOptionComponent = ref(null)
const averageSavingByActivity = ref({
    date: null,
    average_saving: null
})
const averageSavingByAmalan = ref({
    date: null,
    average_saving: null
})

watch(unpaidOutstandingAmount, () => {
    setEstimationBySaving()
    setEstimationByAmalan()
})

watch(savingSeriesData, () => setEstimationBySaving())

watch(outstandingAmount, () => getStatements())

const toggleFilter = (value) => {
    if(fetchStatus.value !== 'DONE') return

    switch (value) {
        case 'type':
            showFilterType.value = !showFilterType.value
            showFilterYear.value = false
            break;
    
        default:
            showFilterYear.value = !showFilterYear.value
            showFilterType.value = false
            break;
    }
}

const setFilter = (filterType, data) => store.dispatch('SavingActivity/changeFilterData', {filterType, data})

const getStatements = () => {
    if(fetchStatus.value !== 'DONE') return

    let from_date = selectedFilter.value.year + '-01-01'
    let to_date = selectedFilter.value.year + '-12-31'
    if(selectedFilter.value.year === moment().format('YYYY')) to_date = moment().format('YYYY-MM-DD')
    
    chartInformation.value.year = selectedFilter.value.year
    if(selectedFilter.value.type !== 'Tahunan') return store.dispatch('SavingActivity/getAccountStatement', {from_date, to_date})

    chartInformation.value.year = filterConfig.value.yearList[0] + ' - ' + selectedFilter.value.year
    return store.dispatch('SavingActivity/getAccountStatementYearly')
}

const defineFilterConfig = () => {
    const monthYearList = filters.getYearBetweenTwoDate(
        rdpData.value.createdDate,
        filters.dateTimeNow()
    )

    setFilter('year', moment().format('YYYY'))

    if(monthYearList.length === 0) return filterConfig.value.yearList = [selectedFilter.value.year]
    if(monthYearList.length > 12) return filterConfig.value.yearList = monthYearList.slice(-12)
    return filterConfig.value.yearList = monthYearList
}

const getBankName = (loanName, loanType) => loanName.replace(" " + loanType, "")

onClickOutside(typeOptionComponent, () => showFilterType.value = false)
onClickOutside(yearOptionComponent, () => showFilterYear.value = false)

const setEstimationBySaving = () => {
    if(savingSeriesData.value.length === 0) {
        averageSavingByActivity.value.date = null
        averageSavingByActivity.value.average_saving = null
        return
    }

    const devidedBy = selectedFilter.value.type === 'Tahunan' ? savingSeriesData.value.length * 12 : savingSeriesData.value.length
    const savingAverage = Math.round(savingSeriesData.value[savingSeriesData.value.length - 1] / devidedBy)
    const months = Math.round(unpaidOutstandingAmount.value / savingAverage)

    averageSavingByActivity.value.date = filters.formatMonthAndYearOnly(filters.addMonthFromToday(months < 1 ? 1 : months))
    averageSavingByActivity.value.average_saving = savingAverage
    return
}

const setEstimationByAmalan = () => {
    averageSavingByAmalan.value.average_saving = Math.round((5/100) * outstandingAmount.value)
    const countMonthUntilPaid = unpaidOutstandingAmount.value ? Math.ceil(unpaidOutstandingAmount.value / averageSavingByAmalan.value.average_saving) : null
    averageSavingByAmalan.value.date = countMonthUntilPaid ? filters.formatMonthAndYearOnly(filters.addMonthFromToday(countMonthUntilPaid < 1 ? 1 : countMonthUntilPaid)) : null
    return
}

const switchTab = (next = false) => {
    if(selectedFilter.value.type !== 'Bulanan') return
    if(fetchStatus.value !== 'DONE') return

    const currentIndex = filterConfig.value.yearList.findIndex((value) => value === selectedFilter.value.year)
    const nextIndex = next ? currentIndex + 1 : currentIndex - 1
    if(nextIndex < 0 || nextIndex > (filterConfig.value.yearList.length - 1)) return

    setFilter('year', filterConfig.value.yearList[nextIndex])
    return getStatements()
}

onMounted(() => {
    if(rdpData.value.isExist !== 'FOUND') return

    defineFilterConfig()
    store.dispatch('SavingActivity/defineCacheChartSeries', filterConfig.value.yearList)
    getStatements()
    setEstimationBySaving()
    setEstimationByAmalan()
})

</script>

<template>
    <div class="min-h-screen bg-amalan-white">
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="router.go(-1)" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div v-if="(rdpData.isExist === 'FOUND')" class="w-full py-8 px-6 bg-amalan-white">
            <p class="text-center text-amalan-black text-xs sm:text-sm">Halaman ini menampilkan Aktivitas Tabungan RDP, Estimasi Pelunasan, dan Progres Pelunasan.</p>
            <div class="w-full mt-8 p-4 bg-amalan-white border border-amalan-gray-4 rounded-lg relative">
                <div class="w-full flex items-center justify-between border-b border-amalan-gray-4 pb-2">
                    <button class="w-8 h-8 flex items-center justify-center bg-amalan-white hover:bg-amalan-gray-5 rounded-lg" :class="[ selectedFilter.type !== 'Bulanan' || fetchStatus !== 'DONE' || filterConfig.yearList.indexOf(selectedFilter.year) < 1 ? 'cursor-not-allowed text-amalan-gray-3' : 'cursor-pointer text-amalan-blue-1' ]" @click="switchTab()">
                        <ArrowSmLeftIcon class="w-5/6" />
                    </button>
                    <p class="text-amalan-blue-1 text-sm font-bold text-center">Aktivitas Tabungan RDP</p>
                    <button class="w-8 h-8 flex items-center justify-center bg-amalan-white hover:bg-amalan-gray-5 rounded-lg" :class="[ selectedFilter.type !== 'Bulanan' || fetchStatus !== 'DONE' || filterConfig.yearList.indexOf(selectedFilter.year) >= (filterConfig.yearList.length - 1) ? 'cursor-not-allowed text-amalan-gray-3' : 'cursor-pointer text-amalan-blue-1' ]" @click="switchTab(true)">
                        <ArrowSmRightIcon class="w-5/6" />
                    </button>
                </div>
                <div class="py-4 flex justify-center items-center">
                    <div @click="toggleFilter('type')" class="px-2 h-6 flex-grow flex justify-between items-center border border-solid border-amalan-gray-3 rounded-lg box-border cursor-pointer relative" ref="typeOptionComponent">
                        <p class="font-semibold text-xs text-amalan-black">{{ selectedFilter.type }}</p>
                        <ChevronDownIcon class="text-amalan-black h-6 -m-2 transition-transform ease-in-out duration-200 transform" :class="[ showFilterType ? 'rotate-180' : 'rotate-0' ]" />
                        <Transition name="fade-fast">
                            <div v-show="showFilterType" class="absolute z-10 top-6 left-0 inset-x-0 border border-solid bg-amalan-white border-amalan-gray-3 rounded-lg box-border max-h-28 text-amalan-black p-0.5">
                                <div class="w-full rounded-lg p-1 text-xs font-semibold hover:bg-amalan-gray-5" v-for="(type, index) in filterConfig.type" :key="index" @click="setFilter('type', type)">{{ type }}</div>
                            </div>
                        </Transition>
                    </div>
                    <div @click="toggleFilter('year')" class="px-2 h-6 ml-2 flex-grow flex justify-between items-center border border-solid border-amalan-gray-3 rounded-lg box-border cursor-pointer relative" ref="yearOptionComponent">
                        <p class="font-semibold text-xs text-amalan-black">{{ selectedFilter.year }}</p>
                        <ChevronDownIcon class="text-amalan-black h-6 -m-2 transition-transform ease-in-out duration-200 transform" :class="[ showFilterYear ? 'rotate-180' : 'rotate-0' ]" />
                        <Transition name="fade-fast">
                            <div v-show="showFilterYear" class="absolute z-10 top-6 left-0 inset-x-0 border border-solid bg-amalan-white border-amalan-gray-3 rounded-lg box-border max-h-28 text-amalan-black p-0.5 overflow-y-scroll scrollbar">
                                <div class="w-full rounded-lg p-1 text-xs font-semibold hover:bg-amalan-gray-5" v-for="(year, index) in filterConfig.yearList" :key="index" @click="setFilter('year', year)">{{ year }}</div>
                            </div>
                        </Transition>
                    </div>
                    <div @click="getStatements()" class="w-7 h-6 ml-2 flex-none flex justify-center items-center shadow-amalan-md bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-lg cursor-pointer">
                        <SearchIcon class="w-4 text-amalan-white" />
                    </div>
                </div>
                <template v-if="fetchStatus === 'DONE'">
                    <LineChart class="w-full h-64" :chart-option="chartOption" />
                </template>
                <div v-else class="w-full h-64 flex justify-center items-center">
                    <loading-data-without-margin />
                </div>
                <p class="text-center font-bold text-3xs text-amalan-black">Tahun {{ chartInformation.year }}</p>
                <div class="flex justify-center items-center gap-x-4 text-amalan-black mt-4">
                    <div class="flex items-center gap-x-1">
                        <div class="w-2 h-2 bg-credit-score-500"></div>
                        <span class="text-3xs">Aktivitas Tabungan</span>
                    </div>
                    <div class="flex items-center gap-x-1">
                        <div class="w-2 h-2 bg-credit-score-100 bg-opacity-50"></div>
                        <span class="text-3xs">Batas Tabungan Ideal</span>
                    </div>
                </div>
            </div>
            <div class="w-full mt-4 p-4 bg-amalan-white border border-amalan-gray-4 rounded-lg">
                <div class="w-full flex items-center justify-center border-b border-amalan-gray-4 pb-2">
                    <p class="text-amalan-blue-1 text-sm font-bold text-center">Tabel Estimasi Pelunasan</p>
                </div>
                <div class="mt-3.5 grid grid-cols-5 border border-amalan-gray-4 rounded-lg">
                    <div class="col-span-5 text-center p-1.5 text-amalan-blue-1 text-xs font-bold">Berdasarkan Aktivitas Tabungan</div>
                    <div class="col-span-3 p-1.5 text-amalan-black text-xs border-t border-amalan-gray-4">Rata-rata Tabungan Perbulan</div>
                    <div class="col-span-2 p-1.5 text-amalan-black text-xs border-t border-amalan-gray-4">: {{ averageSavingByActivity.average_saving ? $filters.format_number(averageSavingByActivity.average_saving) : '-' }}</div>
                    <div class="col-span-3 p-1.5 text-amalan-black text-xs border-t border-amalan-gray-4">Estimasi Pelunasan</div>
                    <div class="col-span-2 p-1.5 text-amalan-black text-xs border-t border-amalan-gray-4">: {{ averageSavingByActivity.date ? averageSavingByActivity.date : '-' }}</div>
                </div>
                <div class="mt-3.5 grid grid-cols-5 border border-amalan-gray-4 rounded-lg">
                    <div class="col-span-5 text-center p-1.5 text-amalan-blue-1 text-xs font-bold">Berdasarkan Estimasi amalan</div>
                    <div class="col-span-3 p-1.5 text-amalan-black text-xs border-t border-amalan-gray-4">Rata-rata Tabungan Perbulan</div>
                    <div class="col-span-2 p-1.5 text-amalan-black text-xs border-t border-amalan-gray-4">: {{ averageSavingByAmalan.average_saving ? $filters.format_number(averageSavingByAmalan.average_saving) : '-' }}</div>
                    <div class="col-span-3 p-1.5 text-amalan-black text-xs border-t border-amalan-gray-4">Estimasi Pelunasan</div>
                    <div class="col-span-2 p-1.5 text-amalan-black text-xs border-t border-amalan-gray-4">: {{ averageSavingByAmalan.date ? averageSavingByAmalan.date : '-' }}</div>
                </div>
            </div>
            <div class="w-full mt-4 p-4 bg-amalan-white border border-amalan-gray-4 rounded-lg">
                <div class="w-full flex items-center justify-center border-b border-amalan-gray-4 pb-2">
                    <p class="text-amalan-blue-1 text-sm font-bold text-center">Progres Pelunasan</p>
                </div>
                <div v-if="!opportunitiesFetchStatus && dataOpportunities.length > 0" class="w-full pt-6 bg-amalan-white relative">
                    <div class="w-full bg-amalan-gray-5 h-5 rounded-amalan-lg overflow-hidden">
                        <table :style="{ width: ((dataOpportunities[2].outstanding_amount/outstandingAmount) * 100) + '%' }">
                            <tr class="h-5 bg-gradient-to-r from-amalan-blue-4 to-amalan-yellow-400 cursor-pointer">
                                <BasicTooltip 
                                    v-for="(opportunity, index) in dataOpportunities[2].data"
                                    :key="index"
                                    :parent-tag="'td'"
                                    :parent-class="index !== (dataOpportunities[2].data.length - 1) ? 'border-r-2 border-amalan-gray-5' : ''"
                                    :parent-style="'width: ' + ((opportunity.Estimated_Outstanding_Amount__c/dataOpportunities[2].outstanding_amount) * 100) + '%'"
                                    :title="getBankName(opportunity.Name, opportunity.Loan_Type_2__c)"
                                    :content="$filters.format_number(opportunity.Estimated_Outstanding_Amount__c ?? 0)"
                                />
                            </tr>
                        </table>
                    </div>
                    <p class="w-full mt-1.5 text-amalan-black text-2xs text-center"><span class="font-bold text-xs">{{ $filters.format_number(dataOpportunities[2].outstanding_amount) }}</span> telah lunas dari Total {{ $filters.format_number(outstandingAmount) }}</p>
                </div>
                <LoadingDataWithoutMargin v-else />
            </div>
        </div>
        <div v-else class="w-full min-h-screen px-6 flex flex-col justify-center items-center">
            <img src="@/assets/img/clipboard-v2.svg" class="w-2/6">
            <p class="text-center text-xs text-amalan-black mt-6">Anda belum melakukan Registrasi Pembukaan<br><b>Rekening Dana Pelunasan (RDP)</b>.<br>Silahkan melakukan registrasi terlebih dahulu.</p>
            <router-link to="/rdp" class="flex items-center justify-center py-3 px-10 bg-amalan-blue-1 hover:bg-amalan-blue-2 rounded-amalan-lg mt-14">
                <span class="text-amalan-white text-sm font-bold">
                    Lakukan Pembukaan RDP Sekarang
                </span>
            </router-link>
        </div>
    </div>
</template>

<style scoped>
.scrollbar::-webkit-scrollbar {
    width: 12px;
}

.scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #E4E5E6;
    border: 4px solid transparent;
    border-radius: 100px;
    background-clip: content-box;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.2s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.2s ease
}
</style>