<template>
    <div class="w-full max-w-lg absolute shadow-xl bg-white z-50 top-13" v-if="termAndConditionStatus">
        <div class="bg-black bg-opacity-50 w-full h-screen py-12 px-6">
            <div class="h-full bg-white rounded-xl shadow-amalan-lg pl-4 pr-1 py-8 relative">
                <div class="w-8 h-8 rounded-full shadow-amalan-lg bg-white absolute -top-3 -right-2 flex justify-center items-center cursor-pointer hover:bg-gray-100" @click="close">
                    <XIcon class="w-5 text-blue-900" />
                </div>
                <div class="bg-white h-full overflow-y-scroll scrollbar pr-1">
                    <p class="font-bold text-center text-sm">Syarat dan Ketentuan <br> Layanan <i>Credit Checkup</i> <br> PT. amalan international Indonesia</p>
                    <div class="mt-6">
                        <p class="font-bold text-center text-sm">PASAL 1 <br> KETENTUAN UMUM</p>
                        <ul class="list-decimal ml-3 mt-2 text-xs leading-normal">
                            <li><i>Credit Checkup</i> adalah jasa yang diberikan berupa layanan <i>review</i> dan konsultasi kondisi keuangan <b>Klien</b> berdasarkan Data Kredit <b>Klien</b>.</li>
                            <li>Data Kredit adalah riwayat kredit dan <i>credit score</i> yang didapatkan dari Lembaga Informasi Keuangan yang terdaftar dan diawasi oleh Otoritas Jasa Keuangan yang bekerja sama dengan <b>amalan</b>.</li>
                            <li>Biaya Layanan (apabila ada) adalah keseluruhan komponen biaya yang wajib dibayarkan oleh <b>Klien</b> kepada <b>amalan</b> sebagaimana disepakati.</li>
                        </ul>
                    </div>
                    <div class="mt-6">
                        <p class="font-bold text-center text-sm">PASAL 2 <br> BIAYA LAYANAN</p>
                        <ul class="list-decimal ml-3 mt-2 text-xs leading-normal">
                            <li>Biaya Layanan (apabila ada) adalah biaya yang wajib dibayarkan oleh <b>Klien</b> kepada <b>amalan</b> sebagai kompensasi atas layanan yang telah diberikan <b>amalan</b>.</li>
                            <li><b>Klien</b> wajib membayarkan Biaya Layanan sebagai bukti kerjasama dengan <b>amalan</b> yang jumlahnya diatur sesuai dengan paket yang dikehendaki oleh <b>Klien</b> selambat-lambatnya 24 (dua puluh empat) jam sejak dilakukannya <i>booking</i> oleh <b>Klien</b> atau setelah diterbitkannya faktur Biaya Layanan.</li>
                            <li><b>amalan</b> akan mengkonfirmasi terlebih dahulu kepada <b>Klien</b> sehubungan dengan persetujuan terkait biaya yang timbul atas Layanan yang diberikan dan jadwal konsultasi.</li>
                        </ul>
                    </div>
                    <div class="mt-6">
                        <p class="font-bold text-center text-sm">PASAL 3 <br> TATA CARA DAN PROSES LAYANAN</p>
                        <ul class="list-decimal ml-3 mt-2 text-xs leading-normal">
                            <li>Layanan <i>Credit Checkup</i> diberikan setelah <b>Klien</b> memberikan konfirmasi persetujuan pemberian Layanan <i>Credit Checkup</i> melalui <i>e-mail</i> dan/atau <i>WhatsApp</i> yang telah disampaikan oleh <b>amalan</b> dan telah dilakukannya <i>booking</i> Layanan dengan memperhatikan ketersediaan Konsultan <b>amalan</b>.</li>
                            <li><b>amalan</b> akan melakukan pemrosesan data diri <b>Klien</b> kepada Lembaga Informasi Keuangan untuk mendapatkan Data Kredit <b>Klien</b> setelah mendapatkan persetujuan sebagaimana dimaksud pada butir 1 (satu) di atas.</li>
                            <li>Waktu pemberian konsultasi Layanan <i>Credit Checkup</i>, berdasarkan jadwal yang telah ditetapkan oleh <b>Klien</b> melalui proses <i>booking</i> sebagaimana dimaksud pada butir 1 (satu) di atas.</li>
                            <li><b>amalan</b> akan memberikan <i>Credit Report</i> kepada <b>Klien</b> selama-lamanya 24 (dua puluh empat) jam kerja setelah dilakukannya konsultasi.</li>
                            <li><b>Klien</b> dapat melakukan perubahan jadwal konsultasi Layanan <i>Credit Checkup</i> sebagaimana dimaksud pada butir 3 (tiga) di atas maksimal 1 (satu) kali dengan cara merubah jadwal <i>booking</i> melalui <i>e-mail</i><br> contact@amalan.com dan/atau WhatsApp +6221 5093 3150, 3 (tiga) jam sebelum dengan memperhatikan ketersediaan waktu Konsultan <b>amalan</b>.</li>
                        </ul>
                    </div>
                    <div class="mt-6">
                        <p class="font-bold text-center text-sm">PASAL 4 <br> PERSETUJUAN DAN KUASA</p>
                        <p class="mt-2 leading-normal text-xs">Untuk keperluan Layanan <i>Credit Checkup</i>, <b>Klien</b> memberikan persetujuan dan kuasa kepada <b>amalan</b> dan dengan ini <b>amalan</b> menerima kuasa yang diberikan untuk melakukan tindakan sebagai berikut:</p>
                        <ul class="list-decimal ml-3 mt-1 text-xs leading-normal">
                            <li>Menentukan jenis Informasi dan Data Pribadi yang diperlukan untuk keperluan layanan <i>Credit Checkup</i>;</li>
                            <li>Memberikan Informasi dan Data Pribadi <b>Klien</b> sebagaiamana diperlukan untuk layanan <i>Credit Checkup</i> kepada pihak ketiga;</li>
                            <li>Melakukan pembaruan Informasi, Data Pribadi dan Data Kredit untuk keperluan Layanan; dan</li>
                            <li>Menerima dan menyimpan hasil <i>Credit Checkup</i> dan/atau Data Kredit yang diberikan oleh pihak ketiga.</li>
                        </ul>
                    </div>
                    <div class="mt-6">
                        <p class="font-bold text-center text-sm">PASAL 5 <br> LAIN-LAIN</p>
                        <p class="mt-2 leading-normal text-xs"><b>amalan</b> berhak dan dapat merubah Ketentuan dan Syarat Layanan <i>Credit Checkup</i> baik sebagian maupun seluruhnya dengan memberitahukan perubahan yang dilakukan tersebut kepada <b>Klien</b>.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { XIcon } from '@heroicons/vue/solid'
import { mapGetters } from 'vuex'

export default {
    components: {
        XIcon
    },
    computed: {
        ...mapGetters('globalState',{
            termAndConditionStatus:'getTermAndConditionStatus'
        }),
    },
    methods: {
        close() {
            this.$store.dispatch('globalState/changeTermAndConditionStatus')
        }
    }
}
</script>

<style scoped>
.scrollbar::-webkit-scrollbar {
    width: 12px;
    height: 20px;
}

.scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #FFFFFF;
    border: 1px solid #BBBBBC
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #BBBBBC;
    border: 3px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #989A9B;
}
</style>