<script setup>
import { ref, inject, reactive, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import axios from "@/axios"
import { useRoute, useRouter } from "vue-router"
import { Form, Field } from 'vee-validate'
import * as yup from 'yup'
import { PlusIcon } from '@heroicons/vue/solid'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import SampleKtp from '@/views/Akun/SampleImageKtpV2.vue'
import SampleSelfie from '@/views/Akun/SampleImageSelfieV2.vue'
import moment from 'moment'
import Camera from "simple-vue-camera"
import getBrowser from "@/composables/getBrowserInfo"
import localStorageExpiry from "@/composables/localStorageExpiry"
import SelfieGuideMask from '@/components/icon/SelfieGuideMask.vue'
import KtpGuideMask from '@/components/icon/KtpGuideMask.vue'
import amalansignupApi from "@/axios/amalansignup"
import useModalPopUp from '@/composables/modalPopUp'

const store = useStore()
const router = useRouter()
const route = useRoute()
const modalPopUp = useModalPopUp()

const FILE_SIZE = 49152 * 1024
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png"
]
const dataTitle = ['Bpk', 'Ibu']
const dataGender = [{ code: 'M', description: 'Laki-laki' },{ code: 'F', description: 'Perempuan' }]
const minDate = () => moment().subtract(100, 'years').format('YYYY-MM-DD')
const maxDate = () => moment().subtract(17, 'years').format('YYYY-MM-DD')
const errorMessageConfig = [
    {
        message: 'Face not detected',
        text: 'Maaf, Pastikan anda mengunggah foto yang mengandung wajah.',
        whatsapp_message: 'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, Pastikan anda mengunggah foto yang mengandung wajah.',
        retake_picture: true
    },
    {
        message: 'KTP not detected',
        text: 'Maaf, Pastikan anda mengunggah foto e-KTP pada tempat yang sudah disediakan.',
        whatsapp_message: 'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, Pastikan anda mengunggah foto e-KTP pada tempat yang sudah disediakan.',
        retake_picture: true
    },
    {
        message: 'threshold',
        text: 'Maaf, Sepertinya skor kecocokan pada foto e-KTP dan Swafoto anda tidak memenuhi persyaratan.',
        whatsapp_message: 'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, Sepertinya skor kecocokan pada foto e-KTP dan Swafoto anda tidak memenuhi persyaratan.',
        retake_picture: true
    },
    {
        message: 'id_cards_number_unique',
        text: 'Maaf, Data KTP Anda terdeteksi sudah terdaftar sebelumnya. Silahkan tekan tombol bantuan dibawah ini atau hubungi konsultan Anda.',
        whatsapp_message: 'Mohon bantuan saya tidak dapat melanjutkan proses karena KTP saya sudah terdaftar di akun lain.',
        retake_picture: true
    },
    {
        message: 'Face liveness is not passed',
        text: 'Maaf, Pastikan anda mengunggah foto yang mengandung wajah asli.',
        whatsapp_message: 'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, Pastikan anda mengunggah foto yang mengandung wajah asli.',
        retake_picture: true
    },
    {
        message: 'Id check is not passed caused by NIK',
        text: 'Maaf, Pastikan NIK yang anda masukan sesuai dengan KTP anda.',
        whatsapp_message: 'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, Pastikan NIK yang anda masukan sesuai dengan KTP anda.',
        retake_picture: false
    },
    {
        message: 'Id check is not passed caused by NAME',
        text: 'Maaf, Pastikan nama lengkap yang anda masukan sesuai dengan KTP anda.',
        whatsapp_message: 'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, Pastikan nama lengkap yang anda masukan sesuai dengan KTP anda.',
        retake_picture: false
    },
    {
        message: 'Id check is not passed caused by BIRTH_DATE',
        text: 'Maaf, Pastikan tanggal lahir yang anda masukan sesuai dengan KTP anda.',
        whatsapp_message: 'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, Pastikan tanggal lahir yang anda masukan sesuai dengan KTP anda.',
        retake_picture: false
    },
    {
        message: 'Id check is not passed caused by BIRTH_PLACE',
        text: 'Maaf, Pastikan tempat lahir yang anda masukan sesuai dengan KTP anda.',
        whatsapp_message: 'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, Pastikan tempat lahir yang anda masukan sesuai dengan KTP anda.',
        retake_picture: false
    },
    {
        message: 'registered cif cannot continue the process',
        text: 'Maaf, pembukaan RDP Anda gagal karena NIK sudah pernah terdaftar sebelumnya di BNI. Klik tombol di bawah untuk menghubungi konsultan kami.',
        whatsapp_message: 'Halo amalan, Mohon bantuan saya gagal membuka RDP dengan keterangan NIK sudah terdaftar di BNI. Terima kasih.',
        retake_picture: false
    },
    {
        message: 'customers_email_unique',
        text: 'Maaf, email yang kamu masukkan telah digunakan, pastikan email tersebut adalah email anda atau gunakan email lain.',
        whatsapp_message: 'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, email yang kamu masukkan telah digunakan',
        retake_picture: false
    },
    {
        message: 'Email is used by another NIK',
        text: 'Alamat Email yang Anda masukan sudah digunakan di NIK yang lain, silahkan masukan Email yang berbeda!',
        whatsapp_message: 'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Alamat Email yang Anda masukan sudah digunakan di NIK yang lain',
        retake_picture: false
    },
    {
        message: 'Phone is used by another NIK',
        text: 'Nomor HP yang Anda masukan sudah digunakan di NIK yang lain, silahkan tekan tombol bantuan dibawah untuk mendapatkan bantuan dari kami!',
        whatsapp_message: 'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Nomor HP yang Anda masukan sudah digunakan di NIK yang lain',
        retake_picture: false
    },
    {
        message: 'Phone and email is used by another NIK',
        text: 'Nomor HP dan Email yang Anda masukan sudah digunakan di NIK yang lain, silahkan tekan tombol bantuan dibawah untuk mendapatkan bantuan dari kami!',
        whatsapp_message: 'Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Nomor HP dan Email yang Anda masukan sudah digunakan di NIK yang lain',
        retake_picture: false
    },
]

const dataProvince = ref([])
const dataCity = ref([])
const dataDistrict = ref([])
const dataVillage = ref([])
const formValues = reactive({
    img_ktp: null,
    img_selfie: null,
    birthdate: null,
    birthplace: null,
    name: null,
    nik: null,
    title: null,
    email: null,
    gender: null,
    street: null,
    rt: null,
    rw: null,
    villageId: null,
    districtId: null,
    cityId: null,
    provinceId: null,
    postalCode: null
})
const postalCodeValid = ref(false)
const currentStep = ref(0)
const dataUser = computed(() => store.getters['user/getUser'])
const idCardUser = computed(() => store.getters['user/getIdCard'])
const filters = inject("Filters")
const isLoading = ref(false)
const getDataLoading = ref(false)
const sampleKtp = ref(false)
const sampleSelfie = ref(false)
const showKtpPreview = ref(false)
const showSelfiePreview = ref(false)
const isMobile = ref(false)
const cameraForKtp = ref(null)
const cameraForSelfie = ref(null)
const cameraErrorMessage = ref(null)
const cameraLoading = ref(false)
const takePictureKTP = ref(false)
const takePictureSelfie = ref(false)
const browserName = ref(null)
const GuideMasking = ref({
    selfie: {
        width: 318,
        height: 435,
        multiplyWith: 1.368
    },
    ktp: {
        width: 318,
        height: 260,
        multiplyWith: 0.818
    }
})
const constraintsSelfieCamera = () => {
    return {
        video: {
            width: isMobile.value ? 1472 : 1080,
            height: isMobile.value ? 1080 : 1430
        }
    }
}
const resolutionKtpCamera = () => {
    return { 
        width: isMobile.value ? 1080 : 1080,
        height: isMobile.value ? 1472 : 1430
    }
}
const pictureTaken = ref({
    fileKTP: {
        blob: null,
        objectURL: null
    },
    fileSelfie: {
        blob: null,
        objectURL: null
    }
})

watch(() => idCardUser.value, () => formValues.nik = idCardUser.value ? idCardUser.value.number : null)

const schemas = [
    yup.object({
        ktp: yup.mixed().required("Pilih file gambar.")
        .test(
            "fileSize",
            "Ukuran file maksimal 48 MB.",
            (value) => {
                if (value) return value.size <= FILE_SIZE
            }
        )
        .test(
            "fileFormat",
            "Mohon pilih file gambar.",
            (value) => {
                if (value) return SUPPORTED_FORMATS.includes(value.type)
            }
        ),
    }),
    yup.object({
        selfie: yup.mixed().required("Pilih file gambar.")
        .test(
            "fileSize",
            "Ukuran file maksimal 48 MB.",
            (value) => {
                if (value) return value.size <= FILE_SIZE
            }
        )
        .test(
            "fileFormat",
            "Mohon pilih file gambar.",
            (value) => {
                if (value) return SUPPORTED_FORMATS.includes(value.type)
            }
        )
    }),
    yup.object({
        title: yup.string().nullable().required('Silahkan pilih title').oneOf(dataTitle, 'Silahkan pilih title'),
        nik: yup.string().nullable().required('NIK tidak boleh kosong').matches(/^[0-9]+$/, "NIK harus berupa angka").length(16, "NIK harus 16 digit"),
        name: yup.string().nullable().required('Nama lengkap tidak boleh kosong').max(50, "Maksimal 50 karakter"),
        email: yup.string().nullable().required('Silahkan isi sesuai alamat email yang kamu gunakan ya!').email('Email tidak valid').max(255, "Maksimal 255 karakter"),
        birthplace: yup.string().nullable().required('Tempat lahir tidak boleh kosong').max(30, "Maksimal 30 karakter"),
        birthdate: yup.date().nullable().required('Silahkan pilih tanggal lahir').min(minDate(),"Pengguna maksimal berumur 100 tahun").max(maxDate(),"Pastikan tanggal lahir sesuai KTP"),
        gender: yup.string().nullable().required('Silahkan pilih jenis kelamin').oneOf(['M','F'], 'Silahkan pilih jenis kelamin'),
    }),
    yup.object({
        street: yup.string().nullable().required('Alamat tidak boleh kosong').max(255, "Maksimal 255 karakter"),
        rt: yup.string().nullable().required('RT tidak boleh kosong').matches(/^[0-9]+$/, "RT harus berupa angka").max(5, "Maksimal 5 karakter"),
        rw: yup.string().nullable().required('RW tidak boleh kosong').matches(/^[0-9]+$/, "RW harus berupa angka").max(5, "Maksimal 5 karakter"),
        provinceId: yup.string().nullable().required('Silahkan pilih provinsi'),
        cityId: yup.string().nullable().required('Silahkan pilih kota'),
        districtId: yup.string().nullable().required('Silahkan pilih kecamatan'),
        villageId: yup.string().nullable().required('Silahkan pilih kelurahan'),
        postalCode: yup.string().nullable().required('Kode pos tidak boleh kosong')
        .matches(/^[0-9]+$/, "Kode pos harus berupa angka").max(5, "Maksimal 5 karakter")
    })
]

const updateForm = computed(() => schemas[currentStep.value])

setTimeout(() => {
    axios.get('/regions/provinces')
    .then((resp) => {
        dataProvince.value = resp.data.data
        formValues.provinceId = dataUser.value.address.province.id
    })
    .catch(() => modalPopUp.swalErrorReload.fire().then(() => window.location.reload()))

    if (dataUser.value.address.province.id !== null){
        axios.get('/regions/cities?province_id=' + dataUser.value.address.province.id)
        .then((resp) => {
            dataCity.value = resp.data.data
            formValues.cityId = dataUser.value.address.city.id
        })
        .catch(() => modalPopUp.swalErrorReload.fire().then(() => window.location.reload()))
    }
}, 1000)

setTimeout(() => {
    if (dataUser.value.address.city.id !== null){
        axios.get('/regions/districts?city_id=' + dataUser.value.address.city.id)
        .then((resp) => {
            dataDistrict.value = resp.data.data
            formValues.districtId = dataUser.value.address.district.id
        })
        .catch(() => modalPopUp.swalErrorReload.fire().then(() => window.location.reload()))
    }

    if (dataUser.value.address.district.id !== null){
        axios.get('/regions/villages?district_id=' + dataUser.value.address.district.id)
        .then((resp) => {
            dataVillage.value = resp.data.data
            formValues.villageId = dataUser.value.address.village.id
        })
        .catch(() => modalPopUp.swalErrorReload.fire().then(() => window.location.reload()))
    }
}, 3000)

const getCity = () => {
    getDataLoading.value = true
    formValues.cityId = null
    axios.get('/regions/cities?province_id=' + formValues.provinceId)
    .then((resp) => {
        dataCity.value = resp.data.data
        getDataLoading.value = false
    })
    .catch(() => {
        getDataLoading.value = false
        modalPopUp.swalErrorReload.fire().then(() => window.location.reload())
    })
}

const getDistrict = () => {
    getDataLoading.value = true
    formValues.districtId = null
    axios.get('/regions/districts?city_id=' + formValues.cityId)
    .then((resp) => {
        dataDistrict.value = resp.data.data
        getDataLoading.value = false
    })
    .catch(() => {
        getDataLoading.value = false
        modalPopUp.swalErrorReload.fire().then(() => window.location.reload())
    })
}

const getVillage = () => {
    getDataLoading.value = true
    formValues.villageId = null
    axios.get('/regions/villages?district_id=' + formValues.districtId)
    .then((resp) => {
        dataVillage.value = resp.data.data
        getDataLoading.value = false
    })
    .catch(() => {
        getDataLoading.value = false
        modalPopUp.swalErrorReload.fire().then(() => window.location.reload())
    })
}

const validatePostalCode = () => {
    axios.get('/postal-code/' + formValues.postalCode)
    .then(() => postalCodeValid.value = true)
    .catch(() => postalCodeValid.value = false)
}

watch(() => formValues.postalCode, () => {
    postalCodeValid.value = false
    if(formValues.postalCode) if(formValues.postalCode.length === 5) validatePostalCode()
})

const nextStep = (values) => {
    if (currentStep.value === 3) {
        if (localStorageExpiry.validateData('fr-limit', moment().format('YYYY-MM-DD HH:mm:ss'))) {
            return modalPopUp.swalError.fire({
                html: '<p class="text-center text-xs text-amalan-black mt-2">Mohon maaf Pembukaan Rekening Dana Pelunasan untuk hari ini telah mencapai batas permintaan. Silakan coba lagi besok!<br><br>Atau tekan tombol bantuan dibawah untuk info lebih lanjut!</p>',
                showCancelButton: true,
                confirmButtonText: 'Bantuan',
                cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
            }).then((result) => {
                if (!result.isConfirmed) return
                return window.location = 'https://wa.me/622150933150?text=Halo. Proses Pembukaan Rekening Dana Pelunasan (RDP) Saya terhenti karena mencapai batas permintaan. Mohon bantuannya.'
            })
        }

        Object.assign(formValues, values)
        return submitData()
    }

    Object.assign(formValues, values)
    return currentStep.value++
}

const submitData = () => {
    isLoading.value = true

    const data = new FormData()
    data.append('img_ktp', formValues.img_ktp)
    data.append('img_selfie', formValues.img_selfie)
    data.append('title', formValues.title)
    data.append('name', formValues.name.toUpperCase())
    data.append('nik', formValues.nik)
    data.append('email', formValues.email)
    data.append('birthplace', formValues.birthplace.toUpperCase())
    data.append('birthdate', filters.dateFormatDayFirst(formValues.birthdate))
    data.append('rt_rw', formValues.rt + '_' + formValues.rw)
    data.append('gender', formValues.gender)
    data.append('street', formValues.street)
    data.append('postalCode', formValues.postalCode)
    data.append('provinceId', formValues.provinceId)
    data.append('cityId', formValues.cityId)
    data.append('districtId', formValues.districtId)
    data.append('villageId', formValues.villageId)

    const configData = {
        headers: {
            'Accept': 'multipart/form-data',
            'Content-Type': 'multipart/form-data'
        }
    }

    axios.post('/bni/kyc', data, configData)
    .then(() => store.dispatch('user/storeUser').then(() => router.push({ name: 'rdp opening - onboarding 2' })))
    .catch((err) => {
        isLoading.value = false
        if(err.response.status == 400 || err.response.status == 422 ) return matchErrorByMessage(err.response.data.message)
        retakePicture()

        return modalPopUp.swalError.fire({
            html: '<p class="text-center text-xs text-amalan-black mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        }).then((result) => {
            if (!result.isConfirmed) return
            return window.location = 'https://wa.me/622150933150?text=Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya'
        })
    })
}

const matchErrorByMessage = (message) => {
    const messageData = errorMessageConfig.find((data) => data.message.includes(message))

    if(messageData){
        if(messageData.retake_picture) retakePicture()
    
        return modalPopUp.swalError.fire({
            html: `<p class="text-center text-xs text-amalan-black mt-2">${messageData.text}</p>`,
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        }).then((result) => {
            if (!result.isConfirmed) return
            return window.location = 'https://wa.me/622150933150?text=' + messageData.whatsapp_message
        })
    }

    if(message.includes('FR lmit reached, please try again next day')){
        const payload = {
            value: 'FR lmit reached, please try again next day',
            expiry: moment().format('YYYY-MM-DD') + ' 23:59:59'
        }
        localStorageExpiry.setData('fr-limit', payload)
        
        return modalPopUp.swalError.fire({
            html: '<p class="text-center text-xs text-amalan-black mt-2">Mohon maaf Pembukaan Rekening Dana Pelunasan untuk hari ini telah mencapai batas permintaan. Silakan coba lagi besok!<br><br>Atau tekan tombol bantuan dibawah untuk info lebih lanjut!</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
        }).then((result) => {
            if (!result.isConfirmed) return
            return window.location = 'https://wa.me/622150933150?text=Halo. Proses Pembukaan Rekening Dana Pelunasan (RDP) Saya terhenti karena mencapai batas permintaan. Mohon bantuannya.'
        })
    }

    retakePicture()

    return modalPopUp.swalError.fire({
        html: '<p class="text-center text-xs text-amalan-black mt-2">Maaf, Pastikan anda mengunggah foto e-KTP dan Swafoto sesuai contoh dan pastikan tidak mengunggah di tempat yang tertukar serta pastikan Nama, NIK, Tanggal Lahir, Tempat Lahir sesuai dengan KTP anda.</p>',
        showCancelButton: true,
        confirmButtonText: 'Bantuan',
        cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
    }).then((result) => {
        if (!result.isConfirmed) return
        return window.location = 'https://wa.me/622150933150?text=Mohon bantuan tidak dapat menyelesaikan proses KYC untuk pembukaan RDP di web.amalan.com dengan pesan, Maaf, Pastikan anda mengunggah foto e-KTP dan Swafoto sesuai contoh dan pastikan tidak mengunggah di tempat yang tertukar serta pastikan Nama, NIK, Tanggal Lahir, Tempat Lahir sesuai dengan KTP anda.'
    })
}

const retakePicture = () => {
    pictureTaken.value.fileKTP.objectURL = null
    pictureTaken.value.fileSelfie.objectURL = null
    formValues.img_ktp = null
    formValues.img_selfie = null
    currentStep.value = 0
}

const snapshotKTP = async () => {
    const cameraContainer = document.getElementById('camera-container')
    const customWidth = cameraContainer.offsetWidth
    const customHeight = cameraContainer.offsetHeight

    const blob = await cameraForKtp.value.snapshot(
        { 
            width: isMobile.value ? browserName.value == 'firefox' ? customWidth * 2 : 1472 : 1430,
            height: isMobile.value ? browserName.value == 'firefox' ? customHeight * 2 : 1080 : 1080
        },
        "image/png",
        0.5
    )

    pictureTaken.value.fileKTP.blob = blob
    pictureTaken.value.fileKTP.objectURL = URL.createObjectURL(blob)
    showKtpPreview.value = true
}

const snapshotSelfie = async () => {
    const cameraContainer = document.getElementById('camera-container')
    const customWidth = cameraContainer.offsetWidth
    const customHeight = cameraContainer.offsetHeight

    const blob = await cameraForSelfie.value.snapshot(
        { 
            width: isMobile.value ? browserName.value == 'firefox' ? customWidth * 2 : 1080 : 1430, 
            height: isMobile.value ? browserName.value == 'firefox' ? customHeight * 2 : 1472 : 1080
        },
        "image/png",
        0.5
    )

    pictureTaken.value.fileSelfie.blob = blob
    pictureTaken.value.fileSelfie.objectURL = URL.createObjectURL(blob)
    showSelfiePreview.value = true
}

const errorCallbackCamera = (error) => {
    cameraLoading.value = false
    callErrorPopUp(error)
}

const loadingCamera = () => {
    cameraLoading.value = true
}

const startedCamera = () => {
    const cameraContainer = document.getElementById('camera-container')
    GuideMasking.value.selfie.width = cameraContainer.offsetWidth
    GuideMasking.value.selfie.height = Math.round(cameraContainer.offsetWidth * GuideMasking.value.selfie.multiplyWith)
    GuideMasking.value.ktp.width = cameraContainer.offsetWidth
    GuideMasking.value.ktp.height = Math.round(cameraContainer.offsetWidth * GuideMasking.value.ktp.multiplyWith)
    cameraLoading.value = false
}

const showKTPCamera = () => {
    takePictureKTP.value = true
    cameraLoading.value = true
    setTimeout(() => {
        cameraForKtp.value.start()
    }, 1000);
}

const showSelfieCamera = () => {
    takePictureSelfie.value = true
    cameraLoading.value = true
    setTimeout(() => {
        cameraForSelfie.value.start()
    }, 1000);
}

const blobToFIle = (blobFile, fileName) => {
    blobFile.lastModifiedDate = new Date()
    blobFile.name = fileName
    return blobFile
}

const unggahKtp = () => {
    takePictureKTP.value = false
    showKtpPreview.value = false
    formValues.img_ktp = blobToFIle(pictureTaken.value.fileKTP.blob, 'ktp.png')
}

const unggahSelfie = () => {
    takePictureSelfie.value = false
    showSelfiePreview.value = false
    formValues.img_selfie = blobToFIle(pictureTaken.value.fileSelfie.blob, 'selfie.png')
}

const ktpInputClick = (e) => { 
    if(formValues.img_ktp) return e.preventDefault()
    if(sampleKtp.value == false) return sampleKtp.value = true
    setTimeout(() => sampleKtp.value = true, 500)
    return sampleKtp.value = false
}

const selfieInputClick = (e) => { 
    if(formValues.img_selfie) return e.preventDefault()
    if(sampleSelfie.value == false) return sampleSelfie.value = true
    setTimeout(() => sampleSelfie.value = true, 500)
    return sampleSelfie.value = false
}

const backNavigation = () => {
    if(currentStep.value === 0) return router.push({ name: 'Home'})
    return currentStep.value--
}

const sampleKtpBack = () => sampleKtp.value = false

const sampleSelfieBack = () => sampleSelfie.value = false

const callErrorPopUp = (msg) => {
    cameraErrorMessage.value = msg
    if(msg == 'camera-not-supported'){
        modalPopUp.swalCameraError.fire({
            html: '<p class="text-center text-sm mt-2"><span class="font-bold text-base text-amalan-blue-1">Tidak bisa menggunakan kamera</span><br><br>Browser Anda mungkin tidak memiliki akses ke kamera. Untuk mengatasi masalah ini, buka Pengaturan sistem</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: 'Muat Ulang',
        }).then((result) => {
            if (!result.isConfirmed) return window.location.reload()
            return window.location = 'https://wa.me/622150933150?text=Mohon bantuan Browser Saya mungkin tidak memiliki akses ke kamera'
        })
    }else{
        modalPopUp.swalCameraError.fire({
            html: '<p class="text-center text-sm mt-2"><span class="font-bold text-base text-amalan-blue-1">Izinkan aplikasi menggunakan kamera</span><br><br>Aplikasi memerlukan akses ke kamera Anda agar dapat mengambil foto</p>',
            showCancelButton: true,
            confirmButtonText: 'Bantuan',
            cancelButtonText: 'Muat Ulang',
        }).then((result) => {
            if (!result.isConfirmed) return window.location.reload()
            return window.location = 'https://wa.me/622150933150?text=Mohon bantuan Aplikasi mungkin tidak memiliki akses ke kamera'
        })
    }
}

const requestDocumentPoa = () => {
    getDataLoading.value = true
    amalansignupApi.get('customers/document-poa')
    .then(() => {
        getDataLoading.value = false
    })
    .catch(() => {
        getDataLoading.value = false
        if(localStorage.getItem('rdp-opening')) localStorage.removeItem('rdp-opening')
        router.push({ name : 'Home' })
    })
}

onMounted(() => {
    store.dispatch('user/requestIdCard')
    requestDocumentPoa()
    formValues.title = dataUser.value.title
    formValues.name = ((dataUser.value.first_name || '') + ' ' + (dataUser.value.last_name || '')).trim()
    formValues.birthplace = dataUser.value.birthplace
    formValues.birthdate = dataUser.value.birthdate
    formValues.email = dataUser.value.email
    formValues.gender = dataUser.value.gender
    formValues.street = dataUser.value.address.street
    formValues.rt = dataUser.value.address.rt
    formValues.rw = dataUser.value.address.rw
    formValues.postalCode = dataUser.value.address.postal_code

    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
        isMobile.value = window.matchMedia("only screen and (max-width: 480px)").matches
        if('userAgent' in navigator){
            browserName.value = getBrowser()
        }
    }else{
        callErrorPopUp('camera-not-supported')
    }
})
</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="backNavigation" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-base font-bold text-amalan-black">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="relative bg-amalan-white min-h-screen">
            <SampleKtp :changeVisibility="sampleKtp" @on-close="showKTPCamera" @on-back="sampleKtpBack" />
            <SampleSelfie :changeVisibility="sampleSelfie" @on-close="showSelfieCamera" @on-back="sampleSelfieBack" />
            <div class="mx-5 bg-amalan-white">
                <div class="w-full py-8 flex justify-between items-center">
                    <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                        <span class="text-sm text-amalan-blue-1 font-bold">1</span>
                    </div>
                    <p v-if="currentStep === 0" class="flex-none text-amalan-blue-1 text-sm font-bold mx-1">Foto e-KTP</p>
                    <div class="bg-amalan-blue-1 h-px" :class="[currentStep === 0 ? 'flex-grow' : 'flex-none w-3 mx-1']"></div>
                    <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none" :class="[currentStep === 0 ? 'bg-amalan-gray-4 mx-1' : 'bg-amalan-yellow-400']">
                        <span class="text-sm font-bold" :class="[currentStep === 0 ? 'text-amalan-gray-2' : 'text-amalan-blue-1']">2</span>
                    </div>
                    <p v-if="currentStep >= 1" class="flex-none text-amalan-blue-1 text-sm font-bold mx-1">Foto Swafoto</p>
                    <div class="bg-amalan-blue-1 h-px" :class="[currentStep >= 1 ? 'flex-grow' : 'flex-none w-3']"></div>
                    <div class="w-6 h-6 rounded-full bg-amalan-gray-4 flex justify-center items-center flex-none ml-1">
                        <span class="text-sm text-amalan-gray-2 font-bold">3</span>
                    </div>
                </div>
                <div class="w-full pb-12">
                    <Form @submit="nextStep" :validation-schema="updateForm" v-slot="{ handleSubmit, errors, meta }">
                        <template v-if="currentStep === 0">
                            <div class="w-full text-amalan-black">
                                <p v-if="formValues.img_ktp" class="mt-1 text-xs sm:text-sm text-center">Foto <b>e-KTP</b> Anda sudah berhasil di unggah!</p>
                                <p v-else class="mt-1 text-xs sm:text-sm text-center">
                                    Ambil foto <b>e-KTP</b> Anda untuk verifikasi data
                                </p>
                            </div>
                            <div v-if="takePictureKTP && !cameraErrorMessage" class="my-4 overflow-hidden rounded-lg isolate">
                                <camera
                                    :autoplay="false"
                                    :resolution="resolutionKtpCamera()"
                                    ref="cameraForKtp"
                                    @error="errorCallbackCamera"
                                    @loading="loadingCamera"
                                    @started="startedCamera"
                                >
                                    <loading-data-without-margin v-if="cameraLoading" class="mt-20" />
                                    <template v-else>
                                        <KtpGuideMask v-if="isMobile" :width="GuideMasking.ktp.width" :height="GuideMasking.ktp.height" />
                                        <div class="bg-amalan-black bg-opacity-70 w-full h-16 flex justify-center items-center bottom-0 absolute">
                                            <div class="w-14 h-14 bg-amalan-gray-3 border-4 border-amalan-white rounded-full flex justify-center items-center cursor-pointer" @click="snapshotKTP"></div>
                                        </div>
                                    </template>
                                </camera>
                            </div>
                            <div v-else class="w-full mt-3.5">
                                <label class="block rounded-xl h-56 py-16 border-2 border-dashed border-amalan-gray-3 hover:bg-amalan-gray-5 relative" :class="[ formValues.img_ktp ? 'cursor-default' : 'cursor-pointer' ]" @click="ktpInputClick">
                                    <div v-if="pictureTaken.fileKTP.objectURL" class="absolute bg-amalan-white w-full h-full top-0 rounded-xl p-2 flex justify-center items-center">
                                        <img :src="pictureTaken.fileKTP.objectURL" class="max-h-full">
                                    </div>
                                    <div class="h-14 w-14 rounded-full bg-amalan-blue-1 flex justify-center items-center mx-auto">
                                        <PlusIcon class="text-amalan-white h-10 w-10"/>
                                    </div>
                                    <p class="text-center mt-4 text-sm text-amalan-black">Ambil foto <b>e-KTP</b> Anda disini!</p>
                                </label>
                                <Field type="file" id="ktpInput" name="ktp" class="hidden" v-model="formValues.img_ktp" />
                                <div v-if="errors.ktp" class="mt-1 mb-4 text-sm text-red-600">*{{errors.ktp}}</div>
                            </div>
                        </template>

                        <template v-if="currentStep === 1">
                            <div class="w-full text-amalan-black">
                                <p v-if="formValues.img_selfie" class="mt-1 text-xs sm:text-sm text-center">Foto <b>Swafoto</b> Anda sudah berhasil di unggah!</p>
                                <p v-else class="mt-1 text-xs sm:text-sm text-center">
                                    Ambil foto <b>Swafoto</b> Anda untuk verifikasi data
                                </p>
                            </div>
                            <div v-if="takePictureSelfie && !cameraErrorMessage" class="my-4 overflow-hidden rounded-lg isolate">
                                <camera
                                    :autoplay="false"
                                    :constraints="constraintsSelfieCamera()"
                                    :facingMode="'user'"
                                    ref="cameraForSelfie"
                                    @error="errorCallbackCamera"
                                    @loading="loadingCamera"
                                    @started="startedCamera"
                                >
                                    <loading-data-without-margin v-if="cameraLoading" class="mt-20" />
                                    <template v-else>
                                        <SelfieGuideMask v-if="isMobile" :width="GuideMasking.selfie.width" :height="GuideMasking.selfie.height" />
                                        <div class="bg-amalan-black bg-opacity-70 w-full h-16 flex justify-center items-center bottom-0 absolute">
                                            <div class="w-14 h-14 bg-amalan-gray-3 border-4 border-amalan-white rounded-full flex justify-center items-center cursor-pointer" @click="snapshotSelfie"></div>
                                        </div>
                                    </template>
                                </camera>
                            </div>
                            <div v-else class="w-full mt-3.5">
                                <label class="block rounded-xl h-56 py-16 border-2 border-dashed border-amalan-gray-3 hover:bg-amalan-gray-5 relative" :class="[ formValues.img_selfie ? 'cursor-default' : 'cursor-pointer' ]" @click="selfieInputClick">
                                    <div v-if="pictureTaken.fileSelfie.objectURL" class="absolute bg-amalan-white w-full h-full top-0 rounded-xl p-2 flex justify-center items-center">
                                        <img :src="pictureTaken.fileSelfie.objectURL" class="max-h-full">
                                    </div>
                                    <div class="h-14 w-14 rounded-full bg-amalan-blue-1 flex justify-center items-center mx-auto">
                                        <PlusIcon class="text-amalan-white h-10 w-10"/>
                                    </div>
                                    <p class="text-center mt-4 text-sm text-amalan-black">Ambil <b>Swafoto</b> Anda disini!</p>
                                </label>
                                <Field type="file" id="selfieInput" name="selfie" class="hidden" v-model="formValues.img_selfie" />
                                <div v-if="errors.selfie" class="mt-1 mb-4 text-sm text-red-600">*{{errors.selfie}}</div>
                            </div>
                        </template>

                        <template v-if="currentStep === 2">
                            <div class="grid grid-cols-1 gap-y-3.5">
                                <div>
                                    <label class="text-amalan-black font-bold text-sm">Gelar</label>
                                    <div class="flex relative w-full">
                                        <Field name="title" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.title ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.title">
                                            <option v-for="(value, key) in dataTitle" :key="key" :value="value">{{ value }}</option>
                                        </Field>
                                        <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-3 text-amalan-black">
                                            <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div v-if="errors.title" class="mt-1 text-2xs text-amalan-red">*{{errors.title}}</div>
                                </div>
                                <div>
                                    <label class="text-amalan-black font-bold text-sm">NIK</label>
                                    <Field name="nik" v-model="formValues.nik" :validateOnInput="true" :validateOnMount="true" type="tel" maxlength="16" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.nik ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                                    <div v-if="errors.nik" class="mt-1 text-2xs text-amalan-red">*{{errors.nik}}</div>
                                </div>
                                <div>
                                    <label class="text-amalan-black font-bold text-sm">Nama Lengkap</label>
                                    <Field name="name" v-model="formValues.name" :validateOnInput="true" :validateOnMount="true" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none uppercase" :class="[ errors.name ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                                    <div v-if="errors.name" class="mt-1 text-2xs text-amalan-red">*{{errors.name}}</div>
                                    <div v-else class="mt-1 text-2xs text-amalan-blue-3">Isi sesuai dengan data KTP Anda</div>
                                </div>
                                <div>
                                    <label class="text-amalan-black font-bold text-sm">Email</label>
                                    <Field name="email" v-model="formValues.email" :validateOnInput="true" :validateOnMount="true" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.email ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                                    <div v-if="errors.email" class="mt-1 text-2xs text-amalan-red">*{{errors.email}}</div>
                                    <div v-else class="mt-1 text-2xs text-amalan-blue-3">Silahkan isi sesuai dengan alamat email yang digunakan.</div>
                                </div>
                                <div class="grid grid-cols-2 gap-x-3.5">
                                    <div>
                                        <label class="text-amalan-black font-bold text-sm">Tempat Lahir</label>
                                        <Field name="birthplace" v-model="formValues.birthplace" :validateOnInput="true" :validateOnMount="true" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none uppercase" :class="[ errors.birthplace ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                                        <div v-if="errors.birthplace" class="mt-1 text-2xs text-amalan-red">*{{errors.birthplace}}</div>
                                    </div>
                                    <div>
                                        <label class="text-amalan-black font-bold text-sm">Tanggal Lahir</label>
                                        <Field name="birthdate" v-model="formValues.birthdate" :validateOnInput="true" :validateOnMount="true" type="date" :min="minDate()" :max="maxDate()" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.birthdate ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                                        <div v-if="errors.birthdate" class="mt-1 text-2xs text-amalan-red">*{{errors.birthdate}}</div>
                                    </div>
                                </div>
                                <div>
                                    <label class="text-amalan-black font-bold text-sm">Jenis Kelamin</label>
                                    <div class="flex relative w-full">
                                        <Field name="gender" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.gender ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.gender">
                                            <option v-for="(gender, key) in dataGender" :key="key" :value="gender.code">{{ gender.description }}</option>
                                        </Field>
                                        <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-3 text-amalan-black">
                                            <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div v-if="errors.gender" class="mt-1 text-2xs text-amalan-red">*{{errors.gender}}</div>
                                </div>
                            </div>
                        </template>

                        <template v-if="currentStep === 3">
                            <div class="grid grid-cols-1 gap-y-3.5">
                                <div>
                                    <label class="text-amalan-black font-bold text-sm">Alamat</label>
                                    <Field placeholder="Alamat" name="street" v-model="formValues.street" :validateOnInput="true" :validateOnMount="true" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.street ? 'border-amalan-red' : 'border-amalan-gray-4' ]" />
                                    <div v-if="errors.street" class="mt-1 text-2xs text-amalan-red">{{errors.street}}</div>
                                </div>
                                <div class="grid grid-cols-2 gap-x-3.5">
                                    <div>
                                        <label class="text-amalan-black font-bold text-sm">RT</label>
                                        <Field placeholder="RT" name="rt" v-model="formValues.rt" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.rt ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnInput="true" :validateOnMount="true"/>
                                        <div v-if="errors.rt" class="mt-1 text-2xs text-amalan-red">{{errors.rt}}</div>
                                    </div>
                                    <div>
                                        <label class="text-amalan-black font-bold text-sm">RW</label>
                                        <Field placeholder="RW" name="rw" v-model="formValues.rw" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.rw ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnInput="true" :validateOnMount="true"/>
                                        <div v-if="errors.rw" class="mt-1 text-2xs text-amalan-red">{{errors.rw}}</div>
                                    </div>
                                </div>
                                <div>
                                    <label class="text-amalan-black font-bold text-sm">Kode Pos</label>
                                    <Field placeholder="Kode Pos" name="postalCode" v-model="formValues.postalCode" type="text" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none" :class="[ errors.postalCode || !postalCodeValid ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnInput="true" :validateOnMount="true"/>
                                    <div v-if="errors.postalCode" class="mt-1 text-2xs text-amalan-red">{{errors.postalCode}}</div>
                                    <div class="mt-1 text-2xs" :class="[ postalCodeValid ? 'text-amalan-green' : 'text-amalan-red' ]">{{ postalCodeValid ? 'Kode Pos valid' : '*Kode Pos tidak valid' }}</div>
                                </div>
                                <div>
                                    <label class="text-amalan-black font-bold text-sm">Provinsi</label>
                                    <div class="flex relative w-full">
                                        <Field name="provinceId" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.provinceId ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.provinceId" @change="getCity">
                                            <option v-for="province in dataProvince" :key="province.key" :value="province.id">{{ province.name }}</option>
                                        </Field>
                                        <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                            <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div v-if="errors.provinceId" class="mt-1 text-2xs text-amalan-red">{{errors.provinceId}}</div>
                                </div>
                                <div>
                                    <label class="text-amalan-black font-bold text-sm">Kota/Kabupaten</label>
                                    <div class="flex relative w-full">
                                        <Field name="cityId" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.cityId ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.cityId" @change="getDistrict">
                                            <option v-for="city in dataCity" :key="city.key" :value="city.id">{{ city.name }}</option>
                                        </Field>
                                        <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                            <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div v-if="errors.cityId" class="mt-1 text-2xs text-amalan-red">{{errors.cityId}}</div>
                                </div>
                                <div>
                                    <label class="text-amalan-black font-bold text-sm">Kecamatan</label>
                                    <div class="flex relative w-full">
                                        <Field name="districtId" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.districtId ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.districtId" @change="getVillage">
                                            <option v-for="district in dataDistrict" :key="district.key" :value="district.id">{{ district.name }}</option>
                                        </Field>
                                        <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                            <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div v-if="errors.districtId" class="mt-1 text-2xs text-amalan-red">{{errors.districtId}}</div>
                                </div>
                                <div>
                                    <label class="text-amalan-black font-bold text-sm">Kelurahan</label>
                                    <div class="flex relative w-full">
                                        <Field name="villageId" as="select" class="block w-full px-4 py-3 text-xs mt-1 text-amalan-black bg-amalan-white border-2 rounded-xl focus:outline-none appearance-none cursor-pointer" :class="[ errors.villageId ? 'border-amalan-red' : 'border-amalan-gray-4' ]" :validateOnMount="true" v-model="formValues.villageId">
                                            <option v-for="village in dataVillage" :key="village.key" :value="village.id">{{ village.name }}</option>
                                        </Field>
                                        <div class="pointer-events-none absolute inset-y-0 mt-2 right-0 flex items-center px-2">
                                            <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div v-if="errors.villageId" class="mt-1 text-2xs text-amalan-red">{{errors.villageId}}</div>
                                </div>
                            </div>
                        </template>

                        <div class="mt-16">
                            <button v-if="currentStep === 0" @submit="handleSubmit" type="submit" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ formValues.img_ktp ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!formValues.img_ktp">Selanjutnya</button>
                            <button v-if="currentStep === 1" @submit="handleSubmit" type="submit" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ formValues.img_selfie ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!formValues.img_selfie">Selanjutnya</button>
                            <button v-if="currentStep === 2" @submit="handleSubmit" type="submit" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!meta.valid">Selanjutnya</button>
                            <button v-if="currentStep === 3" type="submit" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ meta.valid && postalCodeValid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!meta.valid || !postalCodeValid">Selanjutnya</button>
                        </div>
                    </Form>
                </div>
            </div>
            <template v-if="showKtpPreview">
                <div class="fixed inset-0 z-50 bg-black bg-opacity-60 grid grid-cols-1 p-5 sm:p-10 overflow-y-auto">
                    <div class="bg-amalan-white rounded-lg w-full sm:w-8/12 max-w-lg place-self-center">
                        <div class="bg-amalan-blue-7 rounded-t-lg py-3"> 
                            <p class="text-center text-base font-bold text-amalan-black">Pratinjau</p>
                        </div>
                        <div class="w-full p-4">
                            <img :src="pictureTaken.fileKTP.objectURL" class="w-full sm:w-8/12 sm:mx-auto">
                        </div>
                        <div class="w-full grid grid-cols-2 gap-x-4 px-4 pb-4">
                            <button @click="showKtpPreview = !showKtpPreview" type="button" class="w-full shadow-amalan-md rounded-amalan-lg font-bold text-center text-sm py-3 text-amalan-blue-1 border border-amalan-blue-1 cursor-pointer bg-amalan-white hover:bg-amalan-gray-5">Pilih Kembali</button>
                            <button @click="unggahKtp" type="button" class="w-full shadow-amalan-md rounded-amalan-lg font-bold text-center text-sm py-3 text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2">Unggah</button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="showSelfiePreview">
                <div class="fixed inset-0 z-50 bg-black bg-opacity-60 grid grid-cols-1 p-5 sm:p-10 overflow-y-auto">
                    <div class="bg-amalan-white rounded-lg w-full sm:w-8/12 max-w-lg place-self-center">
                        <div class="bg-amalan-blue-7 rounded-t-lg py-3"> 
                            <p class="text-center text-base font-bold text-amalan-black">Pratinjau</p>
                        </div>
                        <div class="w-full p-4">
                            <img :src="pictureTaken.fileSelfie.objectURL" class="w-10/12 sm:w-8/12 mx-auto">
                        </div>
                        <div class="w-full grid grid-cols-2 gap-x-4 px-4 pb-4">
                            <button @click="showSelfiePreview = !showSelfiePreview" type="button" class="w-full shadow-amalan-md rounded-amalan-lg font-bold text-center text-sm py-3 text-amalan-blue-1 border border-amalan-blue-1 cursor-pointer bg-amalan-white hover:bg-amalan-gray-5">Pilih Kembali</button>
                            <button @click="unggahSelfie" type="button" class="w-full shadow-amalan-md rounded-amalan-lg font-bold text-center text-sm py-3 text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2">Unggah</button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <loading-overlay-check-id v-if="isLoading" />
        <loading-overlay v-if="getDataLoading" />
    </div>
</template>