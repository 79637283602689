
<script setup>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { listLoanType, iconBankLender } from '@/constants/LoanConstant'
import useModalPopUp from '@/composables/modalPopUp'

const store = useStore()
const router = useRouter()
const route = useRoute()
const modalPopUp = useModalPopUp()

const updateSignupPoa = computed(() => store.getters['updateSignupPoa/getUpdateSignupPoa'])
const dataOpportunities = computed(() => store.getters['updateSignupPoa/getOpportunities'])

const getLoanTitle = (loanType) => {
    const loanTitle = listLoanType.filter(elem => elem.name == loanType)
    if (loanTitle[0]) return loanTitle[0].title
}

const getLoanIcon = (loanName, loanType) => {
    const lenderName = loanName.replace(" " + loanType, "").toLowerCase()
    const lenderData = iconBankLender.filter(elem => elem.name == lenderName)
    if (lenderData[0]) return lenderData[0].icon

    const loanTypeIcon = listLoanType.filter(elem => elem.name == loanType)
    if (loanTypeIcon[0]) return loanTypeIcon[0].icon
}

const getBankName = (loanName, loanType) => {
    return loanName.replace(" " + loanType, "")
}

const nextPage = () => {
    modalPopUp.swalConfirmWithIcon.fire({
        html: '<p class="text-center text-xs mt-2">Pastikan data yang Anda masukkan sudah sesuai!<br><br>Setelah ini <b>amalan</b> akan segera memproses Dokumen Perjanjian Kerjasama.</p>',
        showCancelButton: true,
        confirmButtonText: 'Konfirmasi',
        cancelButtonText: 'Batal',
    }).then(async (result) => {
        if (!result.isConfirmed) return
        await store.dispatch('updateSignupPoa/requestUpdateSignupPoa')
        if (updateSignupPoa.value.status === 203) return router.push({ name: 'Update Signup - Tanda Tangan' })
        return router.push({ name: 'Update Signup - Finish Page' })
    })
}

const goToLoanManagement = () => router.push({ name: 'Loan Management - Loan List - Home' })

onMounted(() => {
    if(dataOpportunities.value.is_fetched) return
    store.dispatch('updateSignupPoa/requestOpportunities')
})
</script>

<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-3 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="router.go(-1)" class="flex items-center justify-center w-6 h-8">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold text-amalan-black text-center">{{ route.meta.title }}</span>
                    <div class="flex items-center">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full min-h-screen bg-amalan-white px-6 pb-24">
            <div class="w-full py-8 flex justify-between items-center">
                <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                    <span class="text-sm text-amalan-blue-1 font-bold">1</span>
                </div>
                <p class="flex-none text-amalan-blue-1 text-sm font-bold mx-1">Data Pinjaman</p>
                <div class="bg-amalan-blue-1 h-px flex-grow"></div>
                <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none bg-amalan-gray-4 mx-1">
                    <span class="text-sm font-bold text-amalan-gray-2">2</span>
                </div>
                <div class="bg-amalan-blue-1 h-px flex-none w-3"></div>
                <div class="w-6 h-6 rounded-full bg-amalan-gray-4 flex justify-center items-center flex-none ml-1">
                    <span class="text-sm text-amalan-gray-2 font-bold">3</span>
                </div>
            </div>
            <template v-if="dataOpportunities.data.length > 0">
                <template v-for="(dataOpp, index) in dataOpportunities.data" :key="index">
                    <div class="w-full mb-3.5 rounded-lg border border-amalan-gray-4 bg-amalan-white p-4 flex justify-between items-center">
                        <div class="flex-none w-14 h-14 rounded-lg flex justify-center items-center">
                            <img :src="require('@/assets/img/bank-lender-icon/' + getLoanIcon(dataOpp.Name, dataOpp.Loan_Type_2__c))" class="w-14">
                        </div>
                        <div class="flex-grow px-2.5 text-amalan-black">
                            <p class="text-sm text-left font-bold">{{ dataOpp.Name ? getBankName(dataOpp.Name, dataOpp.Loan_Type_2__c) : '-' }}</p>
                            <p class="text-xs mt-0.5">{{ dataOpp.Loan_Type_2__c ? getLoanTitle(dataOpp.Loan_Type_2__c) : '-' }}</p>
                            <p class="text-xs mt-1">{{ dataOpp.Estimated_Outstanding_Amount__c ? $filters.format_number(dataOpp.Estimated_Outstanding_Amount__c) : '-' }}</p>
                        </div>
                    </div>
                </template>
            </template>
            <template v-else>
                <div class="w-full rounded-lg bg-amalan-gray-5 px-4 py-9 mb-3.5">
                    <p class="text-xs text-center">Belum ada data pinjaman yang ditemukan</p>
                </div>
            </template>
            <div class="w-full py-3 border-t border-b border-amalan-gray-4 flex justify-between items-center">
                <span class="font-bold text-sm text-amalan-blue-1">Total Utang</span>
                <span class="text-sm text-amalan-blue-1">{{ dataOpportunities.amount ? $filters.format_number(dataOpportunities.amount) : '-' }}</span>
            </div>
            <div class="mt-16">
                <button @click="goToLoanManagement" type="button" class="w-full rounded-amalan-lg font-bold border text-center text-sm p-3 text-amalan-blue-1 cursor-pointer shadow-amalan-md bg-amalan-white border-amalan-blue-1 hover:bg-amalan-gray-5">+ Tambahkan Data Pinjaman</button>
                <button @click="nextPage" type="button" class="w-full mt-3.5 rounded-amalan-lg font-bold text-center text-sm p-3" :class="[ dataOpportunities.amount ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!dataOpportunities.amount">Selanjutnya</button>
            </div>
            <loading-overlay v-if="dataOpportunities.is_fetching || updateSignupPoa.is_fetching" />
        </div>
    </div>
</template>