<template>
    <svg :width="width" :height="width" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26 0H0L56 56V30L26 0Z" :fill="iconColor == 'active' ? '#FFE700' : '#989A9B'"/>
    <path d="M37.5988 17.5527C37.8293 17.4815 38.0453 17.6975 37.9741 17.928L37.0246 20.9984C36.9971 21.0872 37.0124 21.1838 37.066 21.2599L38.9178 23.8866C39.0568 24.0837 38.9181 24.356 38.6769 24.3594L35.4634 24.4052C35.3703 24.4065 35.2832 24.4509 35.2275 24.5254L33.3015 27.0983C33.157 27.2914 32.8552 27.2436 32.7774 27.0153L31.7408 23.9732C31.7108 23.8851 31.6417 23.816 31.5536 23.786L28.5115 22.7494C28.2832 22.6716 28.2354 22.3698 28.4285 22.2252L31.0014 20.2993C31.0759 20.2436 31.1203 20.1564 31.1216 20.0634L31.1674 16.8499C31.1708 16.6087 31.443 16.47 31.6402 16.609L34.2669 18.4608C34.343 18.5144 34.4395 18.5297 34.5284 18.5022L37.5988 17.5527Z" fill="#FCFCFC"/>
    <path d="M50.5988 29.6948C50.8293 29.6235 51.0453 29.8396 50.9741 30.07L50.0246 33.1405C49.9971 33.2293 50.0124 33.3259 50.066 33.4019L51.9178 36.0287C52.0568 36.2258 51.9181 36.4981 51.6769 36.5015L48.4634 36.5473C48.3703 36.5486 48.2832 36.593 48.2275 36.6675L46.3015 39.2404C46.157 39.4335 45.8552 39.3857 45.7774 39.1574L44.7408 36.1153C44.7108 36.0272 44.6417 35.9581 44.5536 35.9281L41.5115 34.8915C41.2832 34.8137 41.2354 34.5119 41.4285 34.3673L44.0014 32.4414C44.0759 32.3857 44.1203 32.2985 44.1216 32.2055L44.1674 28.992C44.1708 28.7508 44.443 28.6121 44.6402 28.7511L47.2669 30.6029C47.343 30.6565 47.4395 30.6718 47.5284 30.6443L50.5988 29.6948Z" fill="#FCFCFC"/>
    <path d="M26.5988 5.41063C26.8293 5.33936 27.0453 5.55542 26.9741 5.78587L26.0246 8.85628C25.9971 8.94515 26.0124 9.04174 26.066 9.11777L27.9178 11.7445C28.0568 11.9416 27.9181 12.2139 27.6769 12.2173L24.4634 12.2631C24.3703 12.2644 24.2832 12.3088 24.2275 12.3833L22.3015 14.9562C22.157 15.1493 21.8552 15.1015 21.7774 14.8732L20.7408 11.8311C20.7108 11.743 20.6417 11.6739 20.5536 11.6439L17.5115 10.6073C17.2832 10.5295 17.2354 10.2277 17.4285 10.0831L20.0014 8.15723C20.0759 8.10148 20.1203 8.01435 20.1216 7.92133L20.1674 4.70779C20.1708 4.4666 20.443 4.32788 20.6402 4.46687L23.2669 6.3187C23.343 6.3723 23.4395 6.3876 23.5284 6.36011L26.5988 5.41063Z" fill="#FCFCFC"/>
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: [Number, String],
            default: 56
        },
        height: {
            type: [Number, String],
            default: 56
        },
        iconColor: {
            type: String,
            default: 'active'
        }
    }
}
</script>
