import mixpanel from 'mixpanel-browser'

export const eventName = {
    ONBOARDING: 'Onboarding',
    LOGIN: 'Login',
    OTP: 'OTP',
    LEAD: 'Lead',
    QUALIFIED_LEAD: 'Qualified Lead',
    FINANCIAL_HARDSHIP: 'Financial Hardship',
    SAVING_ABILITY: 'Saving Ability',
    FINISHED: 'Finished',
    CC_ONBOARDING: 'CC_ONBOARDING',
    CC_LOGIN: 'CC_LOGIN',
    CC_KYC: 'CC_KYC',
    CC_CATALOG: 'CC_CATALOG',
    CC_CONSENT: 'CC_CONSENT',
    CC_PAYMENT: 'CC_PAYMENT',
    CC_RESULT: 'CC_RESULT'

}

mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN || '8f7ac34b420424ea346a72791e131014', {
    debug: process.env.VUE_APP_ENVIRONMENT_MODE !== 'production' ? true : false,
    persistence: 'localStorage'
})

export const mixpanelSetIdentifier = (identifier) => mixpanel.identify(identifier)

export const mixpanelFlushData = () => mixpanel.reset()

export const mixpanelPushEvent = (eventName, data = undefined) => mixpanel.track(eventName, data)

export const mixpanelTrackPageView = () => mixpanel.track_pageview()

export default mixpanel