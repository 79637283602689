import FullPageWithoutFooter from '@/components/layouts/FullPageWithoutFooter.vue'
import LandingPageSignup from '@/views/LandingPageSignup.vue'
import LandingPageUpdateSignupPoa from '@/views/LandingPageUpdateSignupPoa.vue'

import LandingPageDocumentCollection from '@/views/LandingPageDocumentCollection.vue'
import LandingPageNegotiationProcess from '@/views/LandingPageNegotiationProcess.vue'

import LandingPageLeadQualification from '@/views/LandingPageLeadQualification.vue'
import LandingPageLeadQualificationForCekAja from '@/views/LandingPageLeadQualificationForCekAja.vue'

import LandingPageCreditCheckup from '@/views/LandingPageCCU.vue'
import LandingPageCreditScore from '@/views/LandingPageCCS.vue'
import LandingPageCreditReport from '@/views/LandingPageCCR.vue'
import LandingPageCreditScoreDashboard from '@/views/LandingPageCreditScoreDashboard.vue'

import LandingPageRdpHome from '@/views/LandingPageRdpHome.vue'
import LandingPageRdpOpening from '@/views/LandingPageRdpOpening.vue'

import LandingPageLoanConsolidation from '@/views/LandingPageLoanConsolidation.vue'

export default [
    {
        path: '',
        component: FullPageWithoutFooter,
        children: [
            {
                path: '/lead-qualification-onboarding',
                name: 'Landing Page Lead Qualification',
                component: LandingPageLeadQualification,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Lead Qualification',
                    title: ''
                }
            },
            {
                path: '/kredit-bijak-karyawan-onboarding',
                name: 'Landing Page Loan Consolidation',
                component: LandingPageLoanConsolidation,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Loan Consolidation',
                    title: ''
                }
            },
            {
                path: '/lead-qualification-onboarding-cek-aja',
                name: 'Landing Page Lead Qualification For CekAja',
                component: LandingPageLeadQualificationForCekAja,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Lead Qualification For CekAja',
                    title: ''
                }
            },
            {
                path: '/credit-checkup-onboarding',
                name: 'Landing Page Credit Checkup',
                component: LandingPageCreditCheckup,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Credit Checkup',
                    title: ''
                }
            },
            {
                path: '/credit-score-onboarding',
                name: 'Landing Page Credit Score',
                component: LandingPageCreditScore,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Credit Score',
                    title: ''
                }
            },
            {
                path: '/credit-report-onboarding',
                name: 'Landing Page Credit Report',
                component: LandingPageCreditReport,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Credit Report',
                    title: ''
                }
            },
            {
                path: '/credit-report-internal-onboarding',
                name: 'Landing Page Credit Report x Internal',
                component: LandingPageCreditReport,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Credit Report x Internal',
                    title: ''
                }
            },
            {
                path: '/credit-report-wagely-onboarding',
                name: 'Landing Page Credit Report x Wagely',
                component: LandingPageCreditReport,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Credit Report x Wagely',
                    title: ''
                }
            },
            {
                path: '/credit-report-collectius-onboarding',
                name: 'Landing Page Credit Report x Collectius',
                component: LandingPageCreditReport,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Credit Report x Collectius',
                    title: ''
                }
            },
            {
                path: '/credit-report-cekaja-onboarding',
                name: 'Landing Page Credit Report x Cekaja',
                component: LandingPageCreditReport,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Credit Report x Cekaja',
                    title: ''
                }
            },
            {
                path: '/credit-report-lamudi-onboarding',
                name: 'Landing Page Credit Report x Lamudi',
                component: LandingPageCreditReport,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Credit Report x Lamudi',
                    title: ''
                }
            },
            {
                path: '/credit-report-mcs-onboarding',
                name: 'Landing Page Credit Report x Mcs',
                component: LandingPageCreditReport,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Credit Report x Mcs',
                    title: ''
                }
            },
            {
                path: '/credit-report-uangme-onboarding',
                name: 'Landing Page Credit Report x Uangme',
                component: LandingPageCreditReport,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Credit Report x Uangme',
                    title: ''
                }
            },
            {
                path: '/credit-report-fintopia-onboarding',
                name: 'Landing Page Credit Report x Fintopia',
                component: LandingPageCreditReport,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Credit Report x Fintopia',
                    title: ''
                }
            },
            {
                path: '/credit-report-bav-onboarding',
                name: 'Landing Page Credit Report x Bav',
                component: LandingPageCreditReport,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Credit Report x Bav',
                    title: ''
                }
            },
            {
                path: '/rdp',
                name: 'Landing Page Rdp Opening',
                component: LandingPageRdpOpening,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Rdp Opening',
                    title: ''
                }
            },
            {
                path: '/document-collection',
                name: 'Landing Page Document Collection',
                component: LandingPageDocumentCollection,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Document Collection',
                    title: ''
                }
            },
            {
                path: '/negotiation-process',
                name: 'Landing Page Negotiation Process',
                component: LandingPageNegotiationProcess,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Negotiation Process',
                    title: ''
                }
            },
            {
                path: '/rdp-home',
                name: 'Landing Page RDP Home',
                component: LandingPageRdpHome,
                meta: {
                    isAuth: false,
                    name: 'Landing Page RDP Home',
                    title: ''
                }
            },
            {
                path: '/credit-score-dashboard',
                name: 'Landing Page Credit Score Dashboard',
                component: LandingPageCreditScoreDashboard,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Credit Score Dashboard',
                    title: ''
                }
            },
            {
                path: '/signup-onboarding',
                name: 'Landing Page Signup',
                component: LandingPageSignup,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Signup',
                    title: ''
                }
            },
            {
                path: '/update-poa-onboarding',
                name: 'Landing Page Update Signup Poa',
                component: LandingPageUpdateSignupPoa,
                meta: {
                    isAuth: false,
                    name: 'Landing Page Update Signup Poa',
                    title: ''
                }
            },
        ]
    }
];