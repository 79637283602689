<template>
    <div>
        <nav class="bg-blue-100">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="backButton" class="flex items-center mt-0">
                        <ChevronLeftIcon class="h-5 w-5"/>
                    </button>
                    <span class="text-sm font-bold mt-0">{{ $route.meta.title }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="pt-4 min-h-screen h-auto relative">
            <div class="mt-2 pb-12 w-full px-4" :class="{ 'hidden' : stepForm == 2 || stepForm == 3 || stepForm == 4 }">
                <div class="flex items-center">
                    <div class="flex items-center text-blue-900 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/signup/data-pinjaman-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-blue-900"><p>Daftar<br>Pinjaman</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-gray-300"></div>
                    <div class="flex items-center text-gray-500 relative">
                        <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                            <img src="@/assets/img/lead-qualification/informasi-tambahan.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Informasi<br>Tambahan</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-gray-300"></div>
                    <div class="flex items-center text-gray-500 relative">
                        <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                            <img src="@/assets/img/lead-qualification/kemampuan-menabung.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Estimasi<br>Keringanan</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-gray-300"></div>
                    <div class="flex items-center text-gray-500 relative">
                        <div class="rounded-full h-12 w-12 border-2 bg-gray-200 border-gray-200 flex justify-center items-center">
                            <img src="@/assets/img/verified.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-gray-500"><p>Registrasi<br>Selesai</p></div>
                    </div>
                </div>
            </div>
            <template v-if="stepForm == 1">
                <template v-if="dataOpportunities">
                    <div class="mt-6 w-full px-4 pb-16">
                        <template v-if="dataOpportunities.length > 0">
                            <div v-for="(dataOpp, index) in dataOpportunities" :key="index" class="w-full rounded-lg px-4 py-3 mt-4 border border-solid bg-amalan-white">
                                <div class="flex items-center">
                                    <div class="w-16 h-16 rounded-full flex justify-center items-center border-2 border-amalan-yellow-400 bg-amalan-white">
                                        <img :src="require('@/assets/img/signup/icon-jenis-pinjaman/' + getIcon(dataOpp.Loan_Type_2__c))">
                                    </div>
                                    <div class="ml-4">
                                        <p class="text-sm sm:text-base font-bold my-0">{{ dataOpp.Name ? getBankName(dataOpp.Name, dataOpp.Loan_Type_2__c) : '-' }}</p>
                                        <p class="text-xs sm:text-sm font-semibold my-0">{{ dataOpp.Loan_Type_2__c ? getLoanTitle(dataOpp.Loan_Type_2__c) : '-' }}{{ dataOpp.Loan_Type_2__c == 'Credit Card' ? ' - ' + dataOpp.Credit_Card_Type__c : '' }}</p>
                                    </div>
                                </div>
                                <div class="flex justify-between items-center mt-2">
                                    <p class="text-xs sm:text-sm">
                                        <span class="font-bold">Jumlah Pinjaman</span><br>
                                        {{ dataOpp.Estimated_Outstanding_Amount__c === null ? '-' : $filters.format_number(dataOpp.Estimated_Outstanding_Amount__c) }}
                                    </p>
                                    <button type="button" class="w-8 h-8 flex justify-center items-center rounded-full border border-blue-900 bg-transparent cursor-pointer hover:bg-amalan-white" @click="editOpportunity(dataOpp)"><img src="@/assets/img/signup/edit-icon.svg"></button>
                                </div>
                                <div class="flex justify-between items-center mt-2">
                                    <p class="text-xs sm:text-sm">
                                        <span class="font-bold">Lama Tunggakan</span><br>
                                        {{ dataOpp.Month_Overdue_input_by_Lead__c ? getOverdue(dataOpp.Month_Overdue_input_by_Lead__c) : '-' }}
                                    </p>
                                    <button type="button" class="w-8 h-8 flex justify-center items-center rounded-full border border-blue-900 bg-transparent cursor-pointer hover:bg-amalan-white" @click="deleteOpportunity(dataOpp.Id)"><img src="@/assets/img/signup/delete-icon.svg"></button>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="w-full h-44 rounded-lg px-4 py-3 mt-6 bg-amalan-white flex items-center justify-center">
                                <p class="text-center text-xs sm:text-sm">Belum ada data pinjaman yang ditemukan.</p>
                            </div>
                        </template>
                        <button type="button" class="w-full mt-8 py-3 text-sm font-bold cursor-pointer rounded-lg bg-blue-900 border border-blue-900 text-white hover:bg-blue-700 hover:border-blue-700" @click="goToLoanTypeList">
                            + Tambah Data Pinjaman
                        </button>
                        <p v-if="!dataOpportunities.length > 0" class="text-xs sm:text-sm w-full text-center text-amalan-text-indigo-600 my-2">
                            Anda dapat menambahkan data pinjaman Anda sesuai dengan jenis pinjaman yang tersedia.
                        </p>
                    </div>
                    <div class="absolute w-full px-4 bg-amalan-white h-28">
                        <!-- <button type="button" class="h-10 rounded-lg font-medium border border-solid cursor-pointer text-center text-base py-1 px-1 text-blue-900 border-blue-900 hover:bg-blue-900 hover:text-white float-left"><ChevronLeftIcon class="h-full" /></button> -->
                        <button type="button" class="h-10 rounded-lg font-medium border border-solid text-center text-base py-1 px-1 float-right" :class="[ dataOpportunities.length > 0 ? 'cursor-pointer hover:bg-blue-900 border-blue-900 text-blue-900 hover:text-white' : 'text-gray-400 bg-gray-200 border-gray-200 cursor-not-allowed']" :disabled="!dataOpportunities.length > 0" @click="nextForm"><ChevronRightIcon class="h-full" /></button>
                    </div>
                </template>
                <template v-else>
                    <div class="mt-6 pb-40 w-full px-4">
                        <loading-data class="mt-20" />
                    </div>
                </template>
            </template>
            <template v-if="stepForm == 2">
                <div class="mt-2 pb-40 w-full px-4">
                    <p class="text-center text-xs sm:text-sm">Pilih jenis pinjaman yang ingin Anda daftarkan dan dapatkan solusi sesuai dengan jenis pinjaman Anda!</p>
                    <div class="py-2">
                        <template v-if="dataProductType.length > 0">
                            <div v-for="(loanType, index) in listLoanTypeLQ" :key="index" class="w-full rounded-lg px-4 py-4 mt-4 shadow-amalan-lg bg-amalan-white cursor-pointer hover:bg-gray-50" @click="selectedLoanType(loanType.name)">
                                <div class="flex items-center">
                                    <div class="w-16 h-16 rounded-full flex justify-center items-center border-2 border-amalan-yellow-400 bg-amalan-white">
                                        <img :src="require('@/assets/img/signup/icon-jenis-pinjaman/' + loanType.icon)">
                                    </div>
                                    <div class="w-9/12 ml-4">
                                        <p class="text-sm sm:text-base font-bold text-blue-900 my-0">{{ loanType.title }}</p>
                                        <p class="text-xs sm:text-sm my-0">{{ loanType.desc }}</p>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <loading-data class="mt-20" />
                        </template>
                    </div>
                </div>
            </template>
            <template v-if="stepForm == 3">
                <div class="mt-2 pb-40 w-full px-4">
                    <Form
                        @submit="formHutangSubmit"
                        :validation-schema="formHutangSchema"
                        v-slot="{ errors, meta }"
                        >
                        <div class="my-4">
                            <label class="text-blue-900 font-bold text-sm">Nama Bank/Institusi</label>
                            <div class="flex items-center relative w-full">
                                <div class="w-full box-border border rounded-lg shadow-sm py-1" :class="[ errors.bankName ? 'border-red-600' : 'border-gray-200']">
                                    <Multiselect
                                    ref="multiselect"
                                    v-model="formUtang.bankName"
                                    :noResultsText="'Data tidak ditemukan.'"
                                    :noOptionsText="'Sedang memuat data...'"
                                    :searchable="true"
                                    :options="dataQualifiedLender"
                                    :classes="multiselectClass"
                                    />
                                </div>
                                <Field name="bankName" type="text" class="hidden" :validateOnMount="true" v-model="formUtang.bankName" />
                            </div>
                            <div v-if="errors.bankName" class="mt-1 text-sm text-red-600">*{{errors.bankName}}</div>
                        </div>
                        <template v-if="withCC">
                            <div class="my-4">
                                <label class="text-blue-900 font-bold text-sm">Jenis Kartu</label>
                                <div class="flex items-center relative w-full">
                                    <div class="w-full box-border border rounded-lg border-gray-200 shadow-sm py-1" :class="[ withCC && !formUtang.ccType ? 'border-red-600' : 'border-gray-200']">
                                        <Multiselect
                                        ref="multiselect"
                                        v-model="formUtang.ccType"
                                        :noResultsText="'Data tidak ditemukan.'"
                                        :noOptionsText="'Sedang memuat data...'"
                                        :searchable="true"
                                        :options="dataCCType"
                                        :classes="multiselectClass"
                                        />
                                    </div>
                                    <Field name="ccType" type="text" class="hidden" :validateOnMount="true" v-model="formUtang.ccType" />
                                </div>
                                <div v-if="withCC && !formUtang.ccType" class="mt-1 text-sm text-red-600">*Silahkan pilih jenis kartu</div>
                            </div>
                        </template>
                        <div class="my-4">
                            <label class="text-blue-900 font-bold text-sm">Jumlah Pinjaman</label>
                            <div class="flex flex-col relative w-full">
                                <CurrencyInput 
                                v-model="formUtang.outstandingAmount" 
                                :propsClass="errors.outstandingAmount ? 'border-red-600 rounded-lg shadow-sm' : 'border-gray-200 rounded-lg shadow-sm'"
                                :options="{
                                    currency: 'IDR',
                                    currencyDisplay: 'narrowSymbol',
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    hideNegligibleDecimalDigitsOnFocus: false,
                                    autoDecimalDigits: false,
                                    autoSign: false,
                                    useGrouping: true,
                                    accountingSign: true
                                }" />
                                <Field name="outstandingAmount" type="text" :validateOnMount="true" :validateOnInput="true" class="hidden" v-model="formUtang.outstandingAmount" />
                                <div v-if="errors.outstandingAmount" class="mt-1 text-sm text-red-600">*{{errors.outstandingAmount}}</div>
                            </div>
                        </div>
                        <div class="my-4">
                            <label class="text-blue-900 font-bold text-sm">Lama Tunggakan</label>
                            <div class="flex items-center relative w-full">
                                <div class="w-full box-border border rounded-lg shadow-sm py-1" :class="[ errors.overdue ? 'border-red-600' : 'border-gray-200']">
                                    <Multiselect
                                    ref="multiselect"
                                    v-model="formUtang.overdue"
                                    :noResultsText="'Data tidak ditemukan.'"
                                    :noOptionsText="'Sedang memuat data...'"
                                    :searchable="true"
                                    :options="dataLamaTunggakan"
                                    :classes="multiselectClass"
                                    />
                                </div>
                                <Field name="overdue" type="text" class="hidden" :validateOnMount="true" v-model="formUtang.overdue" />
                            </div>
                            <div v-if="errors.overdue" class="mt-1 text-sm text-red-600">*{{errors.overdue}}</div>
                        </div>
                        <button type="submit" class="w-full py-3 mt-12 text-sm font-bold text-white rounded-lg" :class="[ withCC ? meta.valid && formUtang.ccType ? 'cursor-pointer bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700' : 'bg-gray-300 border-gray-300 cursor-not-allowed' : meta.valid ? 'cursor-pointer bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700' : 'bg-gray-300 border-gray-300 cursor-not-allowed' ]" :disabled="withCC ? meta.valid && formUtang.ccType ? false : true : meta.valid ? false : true">Tambahkan</button>
                    </Form>
                </div>
            </template>
            <template v-if="stepForm == 4">
                <div class="mt-2 pb-40 w-full px-4">
                    <Form
                        @submit="formHutangUpdate"
                        :validation-schema="formHutangUpdateSchema"
                        v-slot="{ errors, meta }"
                        >
                        <template v-if="withCC">
                            <div class="my-4">
                                <label class="text-blue-900 font-bold text-sm">Jenis Kartu</label>
                                <div class="flex items-center relative w-full">
                                    <div class="w-full box-border border rounded-lg border-gray-200 shadow-sm py-1" :class="[ withCC && !formUpdateUtang.ccType ? 'border-red-600' : 'border-gray-200']">
                                        <Multiselect
                                        ref="multiselect"
                                        v-model="formUpdateUtang.ccType"
                                        :noResultsText="'Data tidak ditemukan.'"
                                        :noOptionsText="'Sedang memuat data...'"
                                        :searchable="true"
                                        :options="dataCCType"
                                        :classes="multiselectClass"
                                        />
                                    </div>
                                    <Field name="ccType" type="text" class="hidden" :validateOnMount="true" v-model="formUpdateUtang.ccType" />
                                </div>
                                <div v-if="withCC && !formUpdateUtang.ccType" class="mt-1 text-sm text-red-600">*Silahkan pilih jenis kartu</div>
                            </div>
                        </template>
                        <div class="my-4">
                            <label class="text-blue-900 font-bold text-sm">Jumlah Pinjaman</label>
                            <div class="flex flex-col relative w-full">
                                <CurrencyInput 
                                v-model="formUpdateUtang.outstandingAmount" 
                                :propsClass="errors.outstandingAmount ? 'border-red-600 rounded-lg shadow-sm' : 'border-gray-200 rounded-lg shadow-sm'"
                                :options="{
                                    currency: 'IDR',
                                    currencyDisplay: 'narrowSymbol',
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    hideNegligibleDecimalDigitsOnFocus: false,
                                    autoDecimalDigits: false,
                                    autoSign: false,
                                    useGrouping: true,
                                    accountingSign: true
                                }" />
                                <Field name="outstandingAmount" type="text" :validateOnMount="true" :validateOnInput="true" class="hidden" v-model="formUpdateUtang.outstandingAmount" />
                                <div v-if="errors.outstandingAmount" class="mt-1 text-sm text-red-600">*{{errors.outstandingAmount}}</div>
                            </div>
                        </div>
                        <div class="my-4">
                            <label class="text-blue-900 font-bold text-sm">Lama Tunggakan</label>
                            <div class="flex items-center relative w-full">
                                <div class="w-full box-border border rounded-lg shadow-sm py-1" :class="[ errors.overdue ? 'border-red-600' : 'border-gray-200']">
                                    <Multiselect
                                    ref="multiselect"
                                    v-model="formUpdateUtang.overdue"
                                    :noResultsText="'Data tidak ditemukan.'"
                                    :noOptionsText="'Sedang memuat data...'"
                                    :searchable="true"
                                    :options="dataLamaTunggakan"
                                    :classes="multiselectClass"
                                    />
                                </div>
                                <Field name="overdue" type="text" class="hidden" :validateOnMount="true" v-model="formUpdateUtang.overdue" />
                            </div>
                            <div v-if="errors.overdue" class="mt-1 text-sm text-red-600">*{{errors.overdue}}</div>
                        </div>
                        <button type="submit" class="w-full py-3 mt-12 text-sm font-bold text-white rounded-lg" :class="[ withCC ? meta.valid && formUpdateUtang.ccType ? 'cursor-pointer bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700' : 'bg-gray-300 border-gray-300 cursor-not-allowed' : meta.valid ? 'cursor-pointer bg-blue-900 border-blue-900 hover:bg-blue-700 hover:border-blue-700' : 'bg-gray-300 border-gray-300 cursor-not-allowed' ]" :disabled="withCC ? meta.valid && formUpdateUtang.ccType ? false : true : meta.valid ? false : true">Simpan</button>
                    </Form>
                </div>
            </template>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>

<script>
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/vue/solid';
import { defineComponent, inject, computed, reactive, ref, watch, onMounted, onBeforeUnmount } from 'vue';
import Multiselect from '@vueform/multiselect'
import CurrencyInput from "@/components/CurrencyInputV2.vue";
import router from "@/router";
import { useStore } from 'vuex';
import * as yup from 'yup';
import { Form, Field } from 'vee-validate';
import amalansignupApi from "@/axios/amalansignup";
import { listLoanType, listLoanTypeLQ } from '@/constants/LoanConstant'
import { multiselectClass, dataLamaTunggakan } from '@/constants/Multiselect'
import { sentTiktokEvent } from '../../composables/tiktokEventApi';
import { mixpanelSetIdentifier, mixpanelPushEvent, eventName } from '@/composables/mixpanel'
import axios from '@/axios'

export default defineComponent({
    components:{
        ChevronRightIcon,
        ChevronLeftIcon,
        Multiselect,
        CurrencyInput,
        Form,
        Field
    },
    methods: {
        getIcon(loanType){
            let loanIcon = listLoanType.filter(elem => elem.name == loanType)
            if (loanIcon[0]) return loanIcon[0].icon
        },
        getBankName(loanName, loanType){
            return loanName.replace(" " + loanType, "")
        },
        getOverdue(values){
            const findValues = dataLamaTunggakan.filter(elem => elem.value == values)
            if(findValues[0]) return findValues[0].label
        },
        getLoanTitle(loanType){
            let loanTitle = listLoanType.filter(elem => elem.name == loanType)
            if (loanTitle[0]) return loanTitle[0].title
        },
    },
    setup(){
        const store = useStore()
        const swal = inject("$swal")
        const dataOpportunities = computed(() => store.getters['leadQualification/getOpportunities'])
        const dataProductType = computed(() => store.getters['leadQualification/getProductType'])
        const dataQualifiedLender = computed(() => store.getters['leadQualification/getQualifiedLender'])
        const dataCCType = computed(() => store.getters['leadQualification/getCCType'])
        const amountLoan = computed(() => store.getters['leadQualification/getAmountLoan'])
        const userData = computed(() => store.getters['user/getUser'])
        const userIdentifier = computed(() => store.getters['user/getUserIdentifier'])
        const preventUserFromHistoryBack = computed(() => store.getters['globalState/getPreventUserFromHistoryBack'])
        const stepForm = ref(1)
        const withCC = ref(false)
        let isLoading = ref(false)

        watch(() => amountLoan.value, function() {
            if(amountLoan.value >= 10000000){
                window.dataLayer?.push({
                    event : 'Qualified_Loan',
                    Loanamount : amountLoan.value
                });

                const eventId = 'Qualified_Lead_' + userData.value.phone_number
                window.dataLayer?.push({
                    event: 'Qualified_Lead',
                    ttk_event_id: eventId,
                    ttk_amount_loan: amountLoan.value
                })

                sentTiktokEvent('AddToCart', eventId, {
                    phone_number: userData.value.phone_number,
                    email: userData.value.email || null
                }, {
                    value: amountLoan.value,
                    description: 'qualified amount'
                })

                mixpanelPushEvent(eventName.QUALIFIED_LEAD)
            }
        });

        onMounted(() => {
            window.dataLayer?.push({
                event: 'Loan_Submitted'
            });

            const eventId = 'Lead_Login_' + userData.value.phone_number
            window.dataLayer?.push({
                event: 'Lead_Login',
                ttk_event_id: eventId
            })

            sentTiktokEvent('Contact', eventId, {
                phone_number: userData.value.phone_number,
                email: userData.value.email || null
            })

            if(userIdentifier.value) mixpanelSetIdentifier(userIdentifier.value)
            mixpanelPushEvent(eventName.LEAD)

            appliedProgramDebt()

            store.dispatch('globalState/setPreventUserFromHistoryBack', {value: true})
        })

        onBeforeUnmount(() => {
            if(!preventUserFromHistoryBack.value) return
            if(stepForm.value === 1) popUpNavigateBack()
            return router.push({ name: 'lead qualification - daftar pinjaman'})
        })

        const formUtang = reactive({
            bankName: null,
            loanType: null,
            outstandingAmount: null,
            overdue: null,
            ccType: null,
        });

        const formUpdateUtang = reactive({
            Id: null,
            outstandingAmount: null,
            overdue: null,
            ccType: null,
        });

        const formHutangSchema = yup.object().shape({
            bankName: yup.string().nullable().required('Silahkan pilih nama bank/institusi'),
            outstandingAmount: yup.number('Masukan dalam bentuk angka').nullable().required('Jumlah pinjaman tidak boleh kosong'),
            overdue: yup.string().nullable().required('Silahkan pilih lama tunggakan'),
            ccType: yup.string().nullable(),
        });

        const formHutangUpdateSchema = yup.object().shape({
            outstandingAmount: yup.number('Masukan dalam bentuk angka').nullable().required('Jumlah pinjaman tidak boleh kosong'),
            overdue: yup.string().nullable().required('Silahkan pilih lama tunggakan'),
            ccType: yup.string().nullable(),
        });

        const swalConfirmDelete = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-700 hover:border-blue-700',
                cancelButton: 'bg-white mx-2 py-2 px-5 rounded-lg border border-blue-900 text-blue-900 text-sm font-semibold hover:bg-red-400 hover:border-red-400 hover:text-white'
            },
            buttonsStyling: false,
            title: '<img src="/img/exclamation-icon.svg" class="w-12 h-12 mx-auto">',
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        const swalConfirm = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600',
                cancelButton: 'bg-white mx-2 py-2 px-5 rounded-lg border border-blue-900 text-blue-900 text-sm font-semibold hover:bg-red-400 hover:border-red-400 hover:text-white'
            },
            buttonsStyling: false,
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        const swalErrorReload = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
            },
            buttonsStyling: false,
            title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
            html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
            showCancelButton: false,
            confirmButtonText: 'Perbaharui',
            width: '340px',
            allowOutsideClick: false,
        })

        const swalError = swal.mixin({
            customClass: {
                confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600',
                cancelButton: 'absolute -top-3 -right-3'
            },
            buttonsStyling: false,
            title: '<img src="/img/alert-error-icon.svg" class="w-12 h-12 mx-auto">',
            width: '340px',
            allowOutsideClick: false,
            reverseButtons: true,
        })

        const popUpNavigateBack = async () => {
            const result = await swalConfirmDelete.fire({
                html: '<p class="text-center text-sm mt-2">Anda belum menyelesaikan proses registrasi program keringanan utang Anda.<br><br>Apakah Anda yakin ingin keluar?</p>',
                showCancelButton: true,
                confirmButtonText: 'Ya, Konfirmasi',
                cancelButtonText: 'Batal',
            })

            if(!result.isConfirmed) return
            store.dispatch('globalState/setPreventUserFromHistoryBack', {value: false})
            return router.push({ name: 'Landing Page Lead Qualification' })
        }

        function deleteOpportunity(Id){
            swalConfirmDelete.fire({
                html: '<p class="text-center text-sm mt-2">Dokumen ini tidak dapat dipulihkan kembali<br><br>Apakah Anda yakin ingin menghapusnya?</p>',
                showCancelButton: true,
                confirmButtonText: 'Konfirmasi',
                cancelButtonText: 'Batalkan',
            }).then((result) => {
                if (result.isConfirmed){
                    isLoading.value = true;
                    amalansignupApi.get('salesforce/lead-qualification/opportunities/' + Id + '/delete')
                    .then(() => {
                        store.dispatch('leadQualification/requestOpportunitiesWithPromise').then(() => {
                            isLoading.value = false;
                        });
                    })
                    .catch(() => {
                        isLoading.value = false;
                        swalError.fire({
                            html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                            showCancelButton: true,
                            confirmButtonText: 'Bantuan',
                            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                        }).then((result) => {
                            if (result.isConfirmed){
                                window.location = 'https://wa.me/622150933150?text=' + 
                                'Mohon bantuan saya gagal terus untuk menghapus daftar pinjaman pada proses registrasi keringanan utang perbankan di web.amalan.com';
                                return;
                            }
                        });
                    })
                }
            });
        }

        function selectedLoanType(loanType){
            const allLoanType = dataProductType.value
            let productType = allLoanType.find(elem => elem.Name === loanType) ?? null
            if(productType){
                store.dispatch('leadQualification/requestQualifiedLender', productType.Id)
                formUtang.loanType = loanType
                formUtang.bankName = null
                formUtang.outstandingAmount = null
                formUtang.overdue = null
                formUtang.ccType = null
                withCC.value = loanType === 'Credit Card' ? true : false
                stepForm.value = 3
            }else{
                swalErrorReload.fire().then((result) => {
                    if (result.isConfirmed){
                        window.location.reload();
                    }
                });
            }
        }

        function formHutangSubmit(values){
            let data = {
                bankName : values.bankName,
                loanType : formUtang.loanType,
                outstandingAmount : values.outstandingAmount,
                overdue : values.overdue,
                ccType : withCC.value === true ? values.ccType : null,
            }
            isLoading.value = true
            amalansignupApi.post('salesforce/lead-qualification/opportunities', data)
            .then(() => {
                store.dispatch('leadQualification/requestOpportunitiesWithPromise').then(() => {
                    formUtang.bankName= null
                    formUtang.loanType= null
                    formUtang.outstandingAmount= null
                    formUtang.overdue= null
                    formUtang.ccType= null
                    isLoading.value = false
                    stepForm.value = 1
                    store.dispatch('leadQualification/resetQualifiedLender')
                });
            })
            .catch(() => {
                isLoading.value = false
                swalError.fire({
                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                }).then((result) => {
                    if (result.isConfirmed){
                        window.location = 'https://wa.me/622150933150?text=' + 
                        'Mohon bantuan saya gagal terus dalam pengisian daftar pinjaman pada proses registrasi keringanan utang perbankan di web.amalan.com';
                        return;
                    }
                });
            })
        }

        function editOpportunity(data){
            store.dispatch('leadQualification/resetQualifiedLender')
            const allLoanType = dataProductType.value
            let productType = allLoanType.find(elem => elem.Name === data.Loan_Type_2__c) ?? null
            if(productType){
                store.dispatch('leadQualification/requestQualifiedLender', productType.Id)

                formUpdateUtang.overdue = data.Month_Overdue_input_by_Lead__c ?? '0'
                formUpdateUtang.Id = data.Id ?? null
                formUpdateUtang.outstandingAmount = data.Estimated_Outstanding_Amount__c ?? 0
                formUpdateUtang.ccType = data.Credit_Card_Type__c ?? null
                withCC.value = data.Loan_Type_2__c === 'Credit Card' ? true : false
                stepForm.value = 4
            }else{
                swalErrorReload.fire().then((result) => {
                    if (result.isConfirmed){
                        window.location.reload();
                    }
                });
            }
        }

        function formHutangUpdate(values){
            let data = {
                bankName : values.bankName,
                outstandingAmount : values.outstandingAmount,
                overdue : values.overdue,
                ccType : withCC.value === true ? values.ccType : null,
            }
            isLoading.value = true
            amalansignupApi.put('salesforce/lead-qualification/opportunities/' + formUpdateUtang.Id, data)
            .then(() => {
                store.dispatch('leadQualification/requestOpportunitiesWithPromise').then(() => {
                    formUpdateUtang.Id= null
                    formUpdateUtang.outstandingAmount= null
                    formUpdateUtang.overdue= null
                    formUpdateUtang.ccType= null
                    isLoading.value = false
                    stepForm.value = 1
                    store.dispatch('leadQualification/resetQualifiedLender')
                });
            })
            .catch(() => {
                isLoading.value = false
                swalError.fire({
                    html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                }).then((result) => {
                    if (result.isConfirmed){
                        window.location = 'https://wa.me/622150933150?text=' + 
                        'Mohon bantuan saya gagal terus dalam melakukan edit data pinjaman pada proses registrasi keringanan utang perbankan di web.amalan.com';
                        return;
                    }
                });
            })
        }

        function nextForm(){
            swalConfirm.fire({
                html: '<p class="text-center text-sm mt-2">Apakah Anda sudah memasukan semua data pinjaman Anda? <br>Jika sudah, silahkan untuk dikonfirmasi agar data Anda segera diproses!</p>',
                showCancelButton: true,
                confirmButtonText: 'Konfirmasi',
                cancelButtonText: 'Batalkan',
            }).then((result) => {
                if (result.isConfirmed){
                    isLoading.value = true
                    amalansignupApi.put('salesforce/lead-qualification/update-stage', {
                        stageName : "lq-loan-succeed",
                        product : "lead_qualification"
                    })
                    .then(() => {
                        store.dispatch('globalState/setPreventUserFromHistoryBack', {value: false})
                        isLoading.value = false
                        router.push({ name : 'lead qualification - informasi tambahan' })
                    })
                    .catch(() => {
                        isLoading.value = false
                        swalError.fire({
                            html: '<p class="text-center text-sm mt-2">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                            showCancelButton: true,
                            confirmButtonText: 'Bantuan',
                            cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                        }).then((result) => {
                            if (result.isConfirmed){
                                window.location = 'https://wa.me/622150933150?text=' + 
                                'Mohon bantuan saya gagal terus untuk melanjutkan dari daftar pinjaman pada proses registrasi keringanan utang perbankan di web.amalan.com';
                                return;
                            }
                        });
                    })
                }
            });
        }

        function backButton(){
            if(stepForm.value === 1){
                router.push({ name : 'Landing Page Lead Qualification' })
            }else if(stepForm.value === 4){
                stepForm.value = 1
            }else{
                if(stepForm.value === 3){
                    store.dispatch('leadQualification/resetQualifiedLender')
                }
                stepForm.value--
            }
        }

        const goToLoanTypeList = () => {
            stepForm.value = 2
            window.scrollTo(0, 0)
        }

        const appliedProgramDebt = () => axios.post('/customer/applied-program', { applied_program: 'debt relief', recommended_program: 'debt relief' })

        return {
            deleteOpportunity,
            formHutangSubmit,
            dataProductType,
            selectedLoanType,
            formUtang,
            stepForm,
            withCC,
            dataQualifiedLender,
            backButton,
            dataCCType,
            formHutangSchema,
            isLoading,
            nextForm,
            dataOpportunities,
            editOpportunity,
            formUpdateUtang,
            formHutangUpdate,
            formHutangUpdateSchema,
            multiselectClass,
            listLoanTypeLQ,
            dataLamaTunggakan,
            goToLoanTypeList
        }
    }
})
</script>