<script setup>
import { defineProps, ref, inject } from 'vue'

const props = defineProps({
    cardType: String,
    balance: Number,
    fullName: String,
    accountNumber: String,
    createdDate: String
})

const filters = inject("Filters")
const balanceVisible = ref(false)
const displayBalance = ref((filters.format_number(props.balance)).replace(/[0-9]/g, '*'))

const CARD_TYPE = ref({
    PLATINUM: {
        displayCardType: 'Platinum',
        balanceColor: 'text-amalan-yellow-400',
        decorationImage: 'decoration-platinum.svg',
        firstDecorationClass: 'left-0 bottom-0 xs:-bottom-2 ssm:-bottom-4 sm:-bottom-6 w-full',
        secondDecorationClass: 'hidden',
        icon: 'platinum.svg'
    },
    GOLD: {
        displayCardType: 'Gold',
        balanceColor: 'text-amalan-white',
        decorationImage: 'decoration-gold.svg',
        firstDecorationClass: 'left-0 top-0',
        secondDecorationClass: 'bottom-0 right-0 transform -scale-x-100 -scale-y-100',
        icon: 'gold.svg'
    },
    SILVER: {
        displayCardType: 'Silver',
        balanceColor: 'text-amalan-white',
        decorationImage: 'decoration-silver.svg',
        firstDecorationClass: 'left-0 bottom-0',
        secondDecorationClass: 'bottom-0 right-0 transform -scale-x-100',
        icon: 'silver.svg'
    },
    BASIC: {
        displayCardType: 'Basic',
        balanceColor: 'text-amalan-white',
        decorationImage: 'decoration-basic.svg',
        firstDecorationClass: 'my-auto -inset-y-0 -left-12',
        secondDecorationClass: 'hidden',
        icon: 'basic.svg'
    },
    DIAMOND: {
        displayCardType: 'Diamond',
        balanceColor: 'text-amalan-yellow-400',
        decorationImage: 'decoration-diamond.svg',
        firstDecorationClass: 'left-0 top-0',
        secondDecorationClass: 'bottom-0 right-0 transform -scale-x-100 rotate-90',
        icon: 'diamond.svg'
    }
})

const toggleBalance = () => {
    if(balanceVisible.value){
        displayBalance.value = (filters.format_number(props.balance)).replace(/[0-9]/g, '*')
        balanceVisible.value = !balanceVisible.value
    }else{
        displayBalance.value = filters.format_number(props.balance)
        balanceVisible.value = !balanceVisible.value
    }
}

</script>

<template>
    <div class="w-full bg-amalan-blue-1 px-4 py-5 h-52 rounded-xl shadow-amalan-base relative overflow-hidden">
        <img :src="require('@/assets/img/rdp/kartu/' + CARD_TYPE[props.cardType].decorationImage)" class="absolute" :class="CARD_TYPE[props.cardType].firstDecorationClass">
        <img :src="require('@/assets/img/rdp/kartu/' + CARD_TYPE[props.cardType].decorationImage)" class="absolute" :class="CARD_TYPE[props.cardType].secondDecorationClass">
        <div class="flex justify-between items-center">
            <div class="flex items-center">
                <img :src="require('@/assets/img/rdp/kartu/' + CARD_TYPE[props.cardType].icon)" class="h-2.5">
                <span class="text-xs text-amalan-white ml-1 font-mono">{{ CARD_TYPE[props.cardType].displayCardType }}</span>
            </div>
            <img src="@/assets/img/logo-amalan.png" class="h-5">
        </div>
        <div class="py-7 text-center">
            <div class="flex justify-center items-center">
                <p class="text-xs text-amalan-white">Saldo Utama</p>
                <div @click="toggleBalance" class="ml-1 w-4 h-4 flex justify-center items-center cursor-pointer" :class="[ balanceVisible ? 'pt-0.5' : 'pt-1' ]">
                    <img v-show="balanceVisible" src="@/assets/img/rdp/kartu/eye-open.svg" class="w-4 white-drop-shadow">
                    <img v-show="!balanceVisible" src="@/assets/img/rdp/kartu/eye-close.svg" class="w-4 white-drop-shadow">
                </div>
            </div>
            <p @click="toggleBalance" class="font-extrabold cursor-pointer" :class="CARD_TYPE[props.cardType].balanceColor" style="font-size: min(6vw, 28px);">{{ displayBalance }}</p>
            <p class="text-2xs text-amalan-white">Pembukaan RDP : {{ $filters.formatDateOnlyDate(props.createdDate) }}</p>
        </div>
        <div class="flex justify-between items-center mt-1">
            <span class="text-xs text-amalan-white font-mono">{{ props.accountNumber }}</span>
            <span class="text-xs text-amalan-white font-mono">{{ props.fullName.toUpperCase() }}</span>
        </div>
    </div>
</template>