<script setup>
import { onMounted, ref, watch } from 'vue'
import moment from 'moment'

const endDateTime= new Date('2023-01-24 22:00:00').getTime() 
const currentTime = new Date(moment().format('YYYY-MM-DD HH:mm:ss')).getTime()
const diffTime = Math.floor(endDateTime / 1000) - Math.floor(currentTime / 1000)
const interval = 1000

const duration = ref(moment.duration(diffTime * 1000, 'milliseconds'))
const countDownDisplay = ref(null)
const intervalId = ref(null)

watch(() => duration.value, () => {
    if(duration.value <= 1) clearInterval(intervalId.value)
})

onMounted(() => {
    intervalId.value = setInterval(() => {
        duration.value = moment.duration(duration.value - interval, 'milliseconds')
        const hours = duration.value.hours() < 10 ? '0' + duration.value.hours() : duration.value.hours()
        const minutes = duration.value.minutes() < 10 ? '0' + duration.value.minutes() : duration.value.minutes()
        const seconds = duration.value.seconds() < 10 ? '0' + duration.value.seconds() : duration.value.seconds()

        if(duration.value <= 0){
            countDownDisplay.value = "00 : 00 : 00"
        }else{
            countDownDisplay.value = hours + " : " + minutes + " : " + seconds
        }
    }, interval)
})
</script>

<template>
    <div class="text-center min-h-screen flex justify-center flex-col">
        <div class="justify-center flex">
            <img src="../assets/img/maintenance-mode.png" class="w-4/6">
        </div>
        <p class="text-sm mt-8 px-4">Hai, sobat <b>amalan</b>! <br> Website <b>amalan</b> sedang dalam peningkatan kualitas. Silahkan kembali beberapa saat lagi!</p>
        <p class="w-full text-center mt-2 font-bold text-4xl text-amalan-blue-1">{{ countDownDisplay }}</p>
    </div>
</template>