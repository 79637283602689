<template>
    <input ref="inputRef" type="text" class="block w-full appearance-none focus:outline-none"/>
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";
import { onMounted } from 'vue'

export default {
    name: "CurrencyInputNoDecoration",
    props: {
        setFocus: Boolean,
        modelValue: Number,
        options: Object,
    },
    setup(props) {
        const { inputRef } = useCurrencyInput(props.options);
        
        onMounted(() => {
            if(props.setFocus){
                inputRef.value.focus()
            }
        })

        return { inputRef };
    },
};
</script>
