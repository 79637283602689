<script setup>
import { ref, inject, reactive, computed, onMounted, watch } from 'vue'
import { CheckIcon } from '@heroicons/vue/solid'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import TermsConditions from '@/components/TermsConditions.vue'
import axios from "@/axios"
import useModalPopUp from '@/composables/modalPopUp'
import { Form, Field } from "vee-validate"
import * as yup from "yup"
import Multiselect from '@vueform/multiselect'
import { multiselectClass } from '@/constants/Multiselect'
import { useWindowScroll } from '@vueuse/core'
import { useCookies } from '@vueuse/integrations/useCookies'
import { eventName, mixpanelPushEvent } from '@/composables/mixpanel'

const CookiesData = ['utm_campaign', 'utm_content', 'utm_medium', 'utm_source', 'utm_term']

const router = useRouter()
const route = useRoute()
const store = useStore()
const modalPopUp = useModalPopUp()
const filters = inject("Filters")
const windowPosition = useWindowScroll()

const termAndConditionStatus = computed(() => store.getters['globalState/getTermAndConditionStatus'])
const catalogs = computed(() => store.getters['catalog/getCatalog'])
const idCardUser = computed(() => store.getters['user/getIdCard'])
const motherMaidenUser = computed(() => store.getters['user/getMotherMaiden'])
const nonBookedData = computed(() => store.getters['bookingData/getNonBookedData'])
const dataUser = computed(() => store.getters['user/getUser'])
const applicationPurposeDatas = computed(() => store.getters['creditCheckup/getApplicationPurposeData'])

const isLoading = ref(false)
const currentStep = ref(0)
const formData = reactive({
    serviceCatalog: null,
    mother_maiden: null,
    application_purpose: 'Permintaan Laporan Kredit Pribadi',
    terms: true,
    personalDataAgreement: false
})

watch(() => motherMaidenUser.value, () => formData.mother_maiden = motherMaidenUser.value)

const formSchema = computed(() => formSchemas[currentStep.value])

const formSchemas = [
    yup.object({
        mother_maiden: yup.string().nullable().required('Isi sesuai data yang Anda miliki').max(50, "Maksimal 50 karakter"),
        application_purpose: yup.string().nullable().required('Silahkan pilih')
    }),
    yup.object({
        // terms: yup.bool().required('Silahkan dicentang').equals([true], 'Silahkan dicentang'),
        personalDataAgreement: yup.bool().required('Silahkan dicentang').equals([true], 'Silahkan dicentang')
    })
]

const formSubmit = (values) => {
    if(currentStep.value === 1){
        return modalPopUp.swalConfirmWithIcon.fire({
            html: '<p class="text-center text-xs mt-2 text-amalan-black">Anda yakin data yang diinput sudah benar?<br>Jika sudah, silahkan dikonfirmasi untuk<br>melanjutkan ke proses berikutnya!</p>',
            showCancelButton: true,
            confirmButtonText: 'Ya, Konfirmasi',
            cancelButtonText: 'Batal',
        }).then((result) => {
            if (!result.isConfirmed) return

            isLoading.value = true
            const cookies = getUTMCookies()
            const data = {
                promoType: localStorage.getItem('promoType') ?? '',
                promoCode: localStorage.getItem('promoCode') ?? '',
                serviceCatalogId : nonBookedData.value.serviceCatalogId,
                email: nonBookedData.value.email,
                fullName: nonBookedData.value.fullName,
                idNumber: idCardUser.value.number,
                gender: nonBookedData.value.gender,
                phone: nonBookedData.value.phone,
                startDate: null,
                motherMaiden: formData.mother_maiden,
                applicationPurpose: formData.application_purpose,
                utm: cookies ?? undefined
            }

            axios.post('/services/store', data)
            .then((resp) => {
                isLoading.value = false
                if(localStorage.getItem('promoType')) localStorage.removeItem('promoType')
                if(localStorage.getItem('promoCode')) localStorage.removeItem('promoCode')
                removeUTMCookies()
                store.dispatch('bookingData/resetNonBookedData')
                router.push('/credit-checkup/checkout-summary/' + resp.data.data.serviceId)
            })
            .catch(() => {
                isLoading.value = false
                modalPopUp.swalError.fire({
                    html: '<p class="text-center text-xs mt-2 text-amalan-black">Maaf, server gagal memperbaharui data Anda cukup tunggu sebentar dan silahkan ulangi kembali proses sebelumnya!<br><br>Atau klik tombol bantuan dibawah</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Bantuan',
                    cancelButtonText: '<img src="/img/x-icon.svg" class="w-8 h-8 rounded-full">',
                }).then((result) => {
                    if (result.isConfirmed){
                        return window.location = 'https://wa.me/622150933150?text=Mohon bantuan sistem gagal memproses Pembelian Layanan Credit Score di web.amalan.com';
                    }
                });
            })
        });
    }

    Object.assign(formData, values)
    windowPosition.y.value = 0
    currentStep.value = 1
}

const findCatalogService = () => {
    formData.serviceCatalog = catalogs.value.filter(elem => elem.serviceCatalogId == route.params.id)
    windowPosition.y.value = 0
    if(formData.serviceCatalog.length > 0) return
    return router.push({ name: 'Catalog List' })
}

const getUTMCookies = () => {
    const { get } = useCookies(CookiesData)
    const result = Object.fromEntries(CookiesData.map((v) => [v, get(v)]))
    const parseResult = JSON.parse(JSON.stringify(result))
    return Object.keys(parseResult).length > 0 ? parseResult : null
}

const removeUTMCookies = () => {
    const { remove } = useCookies(CookiesData)
    CookiesData.forEach((v) => remove(v, {path: "/"}))
}

// const termsChecked = () => formData.terms = !formData.terms

const personalDataAgreementChecked = () => formData.personalDataAgreement = !formData.personalDataAgreement

// const showTermsAndCondition = () => store.dispatch('globalState/changeTermAndConditionStatus')

const backNavigation = () => {
    if (currentStep.value === 0) return router.go(-1)
    windowPosition.y.value = 0
    return currentStep.value = 0
}

onMounted(() => {
    if(!nonBookedData.value.serviceCatalogId) return router.push({ name: 'Catalog List' })
    if(!catalogs.value) return router.push({ name: 'Catalog List' })

    if(!idCardUser.value) store.dispatch('user/requestIdCard')
    if(!motherMaidenUser.value) store.dispatch('user/requestMotherMaiden')

    if(!applicationPurposeDatas.value) store.dispatch('creditCheckup/requestApplicationPurpose')

    formData.mother_maiden = motherMaidenUser.value
    findCatalogService()

    if(localStorage.getItem('ccu-product-destination')){
        mixpanelPushEvent(eventName.CC_CONSENT, {
            applied_program: localStorage.getItem('ccu-product-destination')
        })
    }
})
</script>

<template>
    <div>
        <TermsConditions />
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="backNavigation" class="flex items-center mt-0" :class="{ 'invisible' : termAndConditionStatus }">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold mt-0 text-amalan-black">{{ formData.serviceCatalog && formData.serviceCatalog.length > 0 ? formData.serviceCatalog[0].serviceName : '-' }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="relative bg-amalan-white px-5" :class="[ termAndConditionStatus ? 'overflow-hidden h-screen' : 'min-h-screen h-auto' ]">
            <div class="w-full py-8 flex justify-between items-center">
                <div class="w-6 h-6 rounded-full bg-amalan-yellow-400 flex justify-center items-center flex-none">
                    <span class="text-sm text-amalan-blue-1 font-bold">1</span>
                </div>
                <p v-if="currentStep === 0" class="flex-none text-amalan-blue-1 text-sm font-bold mx-1">Lengkapi Data</p>
                <div class="bg-amalan-blue-1 h-px" :class="[currentStep === 0 ? 'flex-grow' : 'flex-none w-3 mx-1']"></div>
                <div class="w-6 h-6 rounded-full flex justify-center items-center flex-none" :class="[currentStep === 0 ? 'bg-amalan-gray-4 mx-1' : 'bg-amalan-yellow-400']">
                    <span class="text-sm font-bold" :class="[currentStep === 0 ? 'text-amalan-gray-2' : 'text-amalan-blue-1']">2</span>
                </div>
                <p v-if="currentStep === 1" class="flex-none text-amalan-blue-1 text-sm font-bold mx-1">Konfirmasi Data</p>
                <div class="bg-amalan-blue-1 h-px" :class="[currentStep === 1 ? 'flex-grow' : 'flex-none w-3']"></div>
                <div class="w-6 h-6 rounded-full bg-amalan-gray-4 flex justify-center items-center flex-none ml-1">
                    <span class="text-sm text-amalan-gray-2 font-bold">3</span>
                </div>
            </div>
            <div v-if="idCardUser && formData.serviceCatalog" class="w-full pb-24">
                <Form @submit="formSubmit" :validation-schema="formSchema" v-slot="{ handleSubmit, errors, meta }">
                    <template v-if="currentStep === 0">
                        <div class="grid grid-col-1 gap-y-3.5">
                            <div class="w-full">
                                <label class="text-amalan-black font-bold text-sm">Nama Lengkap</label>
                                <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ (dataUser.first_name || '') + ' ' + (dataUser.last_name || '') }}</div>
                            </div>
                            <div class="w-full">
                                <label class="text-amalan-black font-bold text-sm">NIK</label>
                                <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ idCardUser ? idCardUser.number : '-' }}</div>
                            </div>
                            <div class="w-full">
                                <label class="text-amalan-black font-bold text-sm">Jenis Kelamin</label>
                                <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ dataUser.gender == 'M' ? 'Laki-laki' : 'Perempuan' }}</div>
                            </div>
                            <div class="w-full grid grid-cols-2 gap-x-4">
                                <div>
                                    <label class="text-amalan-black font-bold text-sm">Tempat Lahir</label>
                                    <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ dataUser.birthplace || '-' }}</div>
                                </div>
                                <div>
                                    <label class="text-amalan-black font-bold text-sm">Tanggal Lahir</label>
                                    <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ dataUser.birthdate ? filters.formatDateOnlyDate(dataUser.birthdate) : '-' }}</div>
                                </div>
                            </div>
                            <div class="w-full">
                                <label class="text-amalan-black font-bold text-sm">Alamat di e-KTP</label>
                                <div class="w-full px-4 py-2.5 text-xs mt-1 text-amalan-gray-2 bg-amalan-gray-5 border border-amalan-gray-4 rounded-lg cursor-not-allowed">{{ `${dataUser.address.street || ''}, ${dataUser.address.village.name || ''}, ${dataUser.address.district.name || ''}, ${dataUser.address.city.name || ''}, ${dataUser.address.province.name || ''}, ${dataUser.address.postal_code || ''}` }}</div>
                            </div>
                            <div class="w-full">
                                <label class="text-amalan-black font-bold text-sm">Nama Ibu Kandung</label>
                                <Field placeholder="Nama Ibu Kandung" name="mother_maiden" v-model="formData.mother_maiden" :validateOnInput="true" :validateOnMount="true" type="text" class="block w-full text-xs px-4 py-3 mt-1 bg-amalan-white border border-amalan-gray-4 rounded-lg appearance-none focus:outline-none" />
                                <div v-if="errors.mother_maiden" class="mt-1 text-2xs text-amalan-blue-3">*{{ errors.mother_maiden }}</div>
                            </div>
                            <div class="w-full">
                                <label class="text-amalan-black font-bold text-sm">Tujuan Pengajuan</label>
                                <div class="flex items-center relative w-full mt-1">
                                    <div class="w-full border rounded-lg py-1" :class="[ errors.application_purpose ? 'border-amalan-red' : 'border-amalan-gray-4']">
                                        <Multiselect
                                        ref="multiselect"
                                        v-model="formData.application_purpose"
                                        :noResultsText="'Data tidak ditemukan.'"
                                        :noOptionsText="'Sedang memuat data...'"
                                        :searchable="true"
                                        :options="applicationPurposeDatas"
                                        :classes="multiselectClass"
                                        placeholder="Pilih Salah Satu"
                                        />
                                    </div>
                                    <Field name="application_purpose" type="text" class="hidden" :validateOnMount="true"  :validateOnInput="true" v-model="formData.application_purpose" />
                                </div>
                                <div v-if="errors.application_purpose" class="mt-0.5 text-amalan-red text-2xs">{{errors.application_purpose}}</div>
                            </div>
                        </div>
                        <div class="mt-16">
                            <button type="submit" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!meta.valid" @submit="handleSubmit">Selanjutnya</button>
                        </div>
                    </template>
                    <template v-if="currentStep === 1">
                        <p class="text-center text-amalan-black text-xs">Pastikan data berikut sesuai dan silahkan ubah<br>jika terdapat data yang keliru.</p>
                        <div class="w-full mt-3.5 p-4 border border-amalan-gray-4 rounded-lg text-amalan-black">
                            <div class="w-full flex justify-between items-center pb-3.5 border-b border-amalan-blue-1">
                                <p class="text-amalan-blue-1 font-bold text-base">Konfirmasi Data</p>
                                <button @click="backNavigation" class="w-6 h-6 rounded-lg shadow-amalan-md border mr-1 flex justify-center items-center cursor-pointer bg-amalan-white border-amalan-blue-1 hover:bg-amalan-gray-5">
                                    <img src="@/assets/img/pencil-primary-icon.svg" class="ml-px">
                                </button>
                            </div>
                            <div class="w-full mt-3.5 grid grid-cols-12 gap-y-2.5 gap-x-1 text-amalan-black text-xs">
                                <div class="col-span-5 flex justify-between items-start gap-x-1.5"><span class="font-bold">Nama Lengkap</span><span>:</span></div>
                                <div class="col-span-7">{{ (dataUser.first_name || '') + ' ' + (dataUser.last_name || '') }}</div>
                                <div class="col-span-5 flex justify-between items-start gap-x-1.5"><span class="font-bold">NIK</span><span>:</span></div>
                                <div class="col-span-7">{{ idCardUser ? idCardUser.number : '-' }}</div>
                                <div class="col-span-5 flex justify-between items-start gap-x-1.5"><span class="font-bold">Jenis Kelamin</span><span>:</span></div>
                                <div class="col-span-7">{{ dataUser.gender == 'M' ? 'Laki-laki' : 'Perempuan' }}</div>
                                <div class="col-span-5 flex justify-between items-start gap-x-1.5"><span class="font-bold">Tempat/ Tgl Lahir</span><span>:</span></div>
                                <div class="col-span-7">{{ (dataUser.birthplace || '-') + '/ ' + (dataUser.birthdate ? filters.formatDateOnlyDate(dataUser.birthdate) : '-') }}</div>
                                <div class="col-span-5 flex justify-between items-start gap-x-1.5"><span class="font-bold">Alamat di e-KTP</span><span>:</span></div>
                                <div class="col-span-7">{{ `${dataUser.address.street || ''}, ${dataUser.address.village.name || ''}, ${dataUser.address.district.name || ''}, ${dataUser.address.city.name || ''}, ${dataUser.address.province.name || ''}, ${dataUser.address.postal_code || ''}` }}</div>
                                <div class="col-span-5 flex justify-between items-start gap-x-1.5"><span class="font-bold">Nama Ibu Kandung</span><span>:</span></div>
                                <div class="col-span-7">{{ formData.mother_maiden || '-' }}</div>
                                <div class="col-span-5 flex justify-between items-start gap-x-1.5"><span class="font-bold">Tujuan Pengajuan</span><span>:</span></div>
                                <div class="col-span-7">{{ formData.application_purpose || '-' }}</div>
                            </div>
                        </div>
                        <div class="w-full mt-3.5">
                            <!-- <div class="justify-between items-start hidden">
                                <label class="font-medium text-xs inline-flex" for="terms">
                                    <label class="h-4 w-4 border-2 border-gray-400 rounded mr-2 p-0 cursor-pointer" :class="{ 'border-red-600': errors.terms, 'bg-amalan-yellow-400 border-amalan-yellow-400' : formData.terms }" @click="termsChecked">
                                        <CheckIcon v-if="formData.terms" class="w-3 h-3 mx-auto text-amalan-blue-1" />
                                        <div v-else class="w-3 h-3 mx-auto bg-transparent"></div>
                                    </label>
                                    <Field name="terms" v-model="formData.terms" type="checkbox" class="hidden" :validateOnMount="true" :validateOnInput="true" />
                                </label>
                                <span class="self-center text-xs">Saya menyetujui seluruh <span class="underline cursor-pointer text-blue-700" @click="showTermsAndCondition">syarat dan ketentuan</span> yang berlaku di <b>amalan international.</b></span>
                            </div>
                            <div v-if="errors.terms" class="text-xs text-red-600 px-4">*{{errors.terms}}</div> -->
                            <span class="text-4xs text-amalan-red pt-0.5 pb-1 px-1.5 rounded-amalan-4xs bg-amalan-yellow-300 triangle" :class="[errors.personalDataAgreement ? 'visible' : 'invisible']">*centang disini</span>
                            <div class="flex justify-between items-start mt-4">
                                <label class="text-xs inline-flex" for="personalDataAgreement">
                                    <label class="h-4 w-4 rounded-sm mr-2 cursor-pointer flex justify-center items-center border-2" :class="[ errors.personalDataAgreement ? 'border-amalan-gray-2 bg-amalan-white': 'bg-amalan-yellow-400 border-amalan-yellow-400' ]"  @click="personalDataAgreementChecked">
                                        <CheckIcon v-if="formData.personalDataAgreement" class="w-4 text-amalan-blue-1" />
                                    </label>
                                    <Field name="personalDataAgreement" v-model="formData.personalDataAgreement" type="checkbox" class="hidden" :validateOnMount="true" :validateOnInput="true" />
                                </label>
                                <div class="text-xs select-none leading-normal cursor-pointer" @click="personalDataAgreementChecked">
                                    <p>Saya dengan sadar memberikan persetujuan dan kuasa kepada amalan dan dengan ini amalan menerima kuasa yang diberikan untuk melakukan tindakan sebagai berikut:</p>
                                    <ul class="list-decimal ml-3">
                                        <li>Menentukan jenis Informasi dan Data Pribadi yang diperlukan untuk keperluan layanan <i>{{ formData.serviceCatalog && formData.serviceCatalog.length > 0 ? formData.serviceCatalog[0].serviceName : '-' }}</i>;</li>
                                        <li>Memberikan Informasi dan Data Pribadi Saya sebagaimana diperlukan untuk layanan <i>{{ formData.serviceCatalog && formData.serviceCatalog.length > 0 ? formData.serviceCatalog[0].serviceName : '-' }}</i> kepada pihak ketiga;</li>
                                        <li>Melakukan pembaruan Informasi, Data Pribadi dan Data Kredit untuk keperluan Layanan; dan</li>
                                        <li>Menerima dan menyimpan hasil <i>{{ formData.serviceCatalog && formData.serviceCatalog.length > 0 ? formData.serviceCatalog[0].serviceName : '-' }}</i> dan/atau Data Kredit yang diberikan pihak ketiga untuk keperluan Layanan yang dipilih oleh Saya.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="mt-16">
                            <button type="submit" class="w-full rounded-amalan-lg font-bold text-center text-sm py-3" :class="[ meta.valid ? 'text-amalan-white cursor-pointer bg-amalan-blue-1 hover:bg-amalan-blue-2 shadow-amalan-md' : 'text-amalan-gray-2 bg-amalan-gray-4 cursor-not-allowed' ]" :disabled="!meta.valid">Kirim</button>
                        </div>
                    </template>
                </Form>
            </div>
            <loading-data-without-margin v-else class="pt-40" />
            <loading-overlay v-if="isLoading" />
        </div>
    </div>
</template>

<style scoped>
.triangle {
    position: relative;
}

.triangle::after{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: -9px;
    left: 7%;
    box-sizing: border-box;

    border-style: solid;
    border-width: 5px;
    border-color: transparent transparent #FBE855 #FBE855;
    
    transform-origin: 0 0;
    transform: rotate(-45deg);
}
</style>