<template>
    <div class="bg-amalan-white min-h-screen">
        <Transition name="fade-fast">
            <div v-if="showPage" class="w-full h-800 mb-10">
                <div class="w-full h-5/6 relative">
                    <Transition name="fade-fast">
                        <div v-show="firstStep" class="absolute z-11">
                            <div class="justify-center flex pt-16">
                                <img class="h-16" src="../assets/img/logo-amalan.png">
                            </div>
                            <div class="w-full flex justify-center my-12">
                                <img src="@/assets/img/credit-checkup/first-onboarding.png" class="w-52">
                            </div>
                            <div class="px-10">
                                <p class="text-xs text-center text-amalan-black">Ketahui Riwayat Kredit dan Credit Score Anda sekarang melalui layanan <b>Credit Checkup</b> dari <b>amalan</b>.</p>
                                <p class="text-xs text-center text-amalan-black mt-8">Hanya dengan tiga langkah, Anda akan mendapatkan analisa finansial dan peluang pinjaman baru!</p>
                            </div>
                        </div>
                    </Transition>
                    <Transition name="fade-fast">
                        <div v-show="secondStep" class="absolute z-12">
                            <div class="relative w-full h-80 sm:h-96 mt-24">
                                <img class="absolute w-80 top-0 right-0 z-0" src="@/assets/img/credit-checkup/onboarding-decoration-1.svg">
                                <img src="@/assets/img/credit-checkup/second-onboarding.png" class="w-64 sm:w-80 absolute mx-auto inset-x-0 z-10 bottom-0">
                            </div>
                            <div class="px-10 mt-5">
                                <p class="text-center text-base font-bold text-amalan-blue-1">Verifikasi Data</p>
                                <p class="text-xs text-center text-amalan-black mt-1">Anda akan melakukan Verifikasi Data dengan mengunggah e-KTP dan Swafoto sesuai dengan ketentuan yang berlaku.</p>
                            </div>
                        </div>
                    </Transition>
                    <Transition name="fade-fast">
                        <div v-show="thirdStep" class="absolute z-13">
                            <div class="relative w-full h-80 sm:h-96 mt-24">
                                <img class="absolute w-full max-h-96 -top-8 right-0 z-0" src="@/assets/img/credit-checkup/onboarding-decoration-2.svg">
                                <img src="@/assets/img/credit-checkup/third-onboarding.png" class="w-64 sm:w-80 absolute mx-auto inset-x-0 z-10 bottom-6">
                            </div>
                            <div class="px-10 mt-5">
                                <p class="text-center text-base font-bold text-amalan-blue-1 hidden sm:block">Memilih Jadwal Konsultasi dan Konsultan</p>
                                <p class="text-center text-base font-bold text-amalan-blue-1 block sm:hidden">Memilih Jadwal Konsultasi <br> dan Konsultan</p>
                                <p class="text-xs text-center text-amalan-black mt-1">Setelah Verifikasi Data sukses, Anda akan diminta untuk memilih jadwal konsultasi dengan konsultan Credit Specialist dari amalan.</p>
                            </div>
                        </div>
                    </Transition>
                    <Transition name="fade-fast">
                        <div v-show="fourStep" class="absolute z-14">
                            <div class="relative w-full h-80 sm:h-96 mt-36 sm:mt-28">
                                <img class="absolute w-80 -top-10 left-0 z-0" src="@/assets/img/credit-checkup/onboarding-decoration-3.svg">
                                <div class="w-full grid grid-cols-5 px-6 relative">
                                    <img src="@/assets/img/credit-checkup/fourth-onboarding-1.png" class="col-span-2 order-2 w-32 sm:w-36 sm:mx-auto">
                                    <div class="col-span-3 flex flex-col items-start justify-center order-1 -mr-3">
                                        <p class="text-base font-bold text-amalan-blue-1">Pembayaran</p>
                                        <p class="text-xs mt-1 text-amalan-black">Selanjutnya Anda akan diberikan ringkasan Jadwal Konsultasi dan Detail Pembayaran dari pembelian layanan yang telah dilakukan.</p>
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-5 px-6 relative">
                                    <img src="@/assets/img/credit-checkup/fourth-onboarding-2.png" class="col-span-2 order-1 w-32 sm:w-36 sm:mx-auto">
                                    <div class="col-span-3 flex flex-col items-start justify-center order-2 -ml-6">
                                        <p class="text-base font-bold text-amalan-blue-1">Konfirmasi Layanan</p>
                                        <p class="text-xs mt-1 text-amalan-black">Jika pembayaran Anda sudah berhasil, maka layanan Anda akan terkonfirmasi sukses dan menunggu proses berikutnya.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
                <div class="w-full h-1/6 relative">
                    <Transition name="fade-fast">
                        <div v-if="firstStep" class="w-full flex justify-center absolute z-11">
                            <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                        </div>
                    </Transition>
                    <Transition name="fade-fast">
                        <div v-if="secondStep" class="w-full flex justify-center absolute z-12">
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                        </div>
                    </Transition>
                    <Transition name="fade-fast">
                        <div v-if="thirdStep" class="w-full flex justify-center absolute z-11">
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                        </div>
                    </Transition>
                    <Transition name="fade-fast">
                        <div v-if="fourStep" class="w-full flex justify-center absolute z-12">
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-2 h-2 bg-amalan-gray-4 rounded-full mx-1"></div>
                            <div class="w-6 h-2 bg-amalan-yellow-400 rounded-lg mx-1"></div>
                        </div>
                    </Transition>
                    <div class="w-full px-6 flex justify-between items-center flex-row-reverse absolute bottom-2">
                        <div type="button" @click="nextStep" class="text-amalan-white text-sm font-bold bg-amalan-blue-1 h-12 w-40 hover:bg-blue-900 rounded-lg flex items-center justify-center cursor-pointer"><span :class="{ 'ml-3' : currentPosition !== 4}">{{ currentPosition == 4 ? 'Mulai Sekarang' : 'Selanjutnya' }}</span><ChevronRightIcon class="h-8" v-if="currentPosition !== 4" /></div>
                        <Transition name="fade-fast"><span @click="skip" class="text-sm font-bold text-amalan-blue-1 cursor-pointer" v-if="!hideLewati">Lewati</span></Transition>
                    </div>
                </div>
            </div>
        </Transition>
        <loading-overlay v-if="isLoading" />
    </div>
</template>

<script>
import { ChevronRightIcon } from '@heroicons/vue/solid'
import { ref } from 'vue'
import router from "@/router"
import setCookies from '@/composables/setCookies'

export default {
    components:{
        ChevronRightIcon
    },
    mounted(){
        localStorage.setItem('from-onboarding-go-to', 'Catalog List')

        if(this.$route.query.referral){            
            localStorage.setItem("promoType", "referral")
            localStorage.setItem("promoCode", this.$route.query.referral)
        }

        // if(this.$route.query['product-destination']) localStorage.setItem("ccu-product-destination", this.$route.query['product-destination'])

        setCookies.setUtmCookies(this.$route.query)

        setTimeout(() => {
            this.showPage = true
        }, 500)
    },
    setup(){
        let showPage = ref(false)
        let hideLewati = ref(false)
        let firstStep = ref(true)
        let secondStep = ref(false)
        let thirdStep = ref(false)
        let fourStep = ref(false)
        let currentPosition = ref(1)
        const isLoading = ref(false)

        function nextStep(){
            if(currentPosition.value == 1){
                firstStep.value = false
                hideLewati.value = true
                secondStep.value = true
                thirdStep.value = false
                fourStep.value = false
                currentPosition.value = 2
            }else if(currentPosition.value == 2){
                firstStep.value = false
                hideLewati.value = true
                secondStep.value = false
                thirdStep.value = true
                fourStep.value = false
                currentPosition.value = 3
            }else if(currentPosition.value == 3){
                firstStep.value = false
                hideLewati.value = true
                secondStep.value = false
                thirdStep.value = false
                fourStep.value = true
                currentPosition.value = 4
            }else{
                isLoading.value = true
                router.push({ name : 'Login' })
            }
        }

        function skip(){
            isLoading.value = true
            router.push({ name : 'Login' })
        }

        return {
            showPage,
            hideLewati,
            nextStep,
            firstStep,
            secondStep,
            thirdStep,
            fourStep,
            skip,
            currentPosition,
            isLoading
        }
    }
}
</script>

<style scoped>
.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.8s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.8s ease
}
</style>