import DaftarPinjamanLQ from '@/views/LeadQualification/DaftarPinjaman.vue'
import InformasiTambahanLQ from '@/views/LeadQualification/InformasiTambahan.vue'
import KemampuanMenabungLQ from '@/views/LeadQualification/KemampuanMenabung.vue'
import RegistrasiSelesaiLQ from '@/views/LeadQualification/RegistrasiSelesai.vue'

export default [
    {
        path: '/lead-qualification/daftar-pinjaman',
        name: 'lead qualification - daftar pinjaman',
        component: DaftarPinjamanLQ,
        meta: {
            name: 'lead qualification - daftar pinjaman',
            isAuth: true,
            title: 'Registrasi Program Keringanan Utang',
            leadQualification: true,
            excludeKYC: true
        }
    },
    {
        path: '/lead-qualification/informasi-tambahan',
        name: 'lead qualification - informasi tambahan',
        component: InformasiTambahanLQ,
        meta: {
            name: 'lead qualification - informasi tambahan',
            isAuth: true,
            title: 'Registrasi Program Keringanan Utang',
            leadQualification: true,
            excludeKYC: true
        }
    },
    {
        path: '/lead-qualification/kemampuan-menabung',
        name: 'lead qualification - kemampuan menabung',
        component: KemampuanMenabungLQ,
        meta: {
            name: 'lead qualification - kemampuan menabung',
            isAuth: true,
            title: 'Registrasi Program Keringanan Utang',
            leadQualification: true,
            excludeKYC: true
        }
    },
    {
        path: '/lead-qualification/registrasi-selesai',
        name: 'lead qualification - registrasi selesai',
        component: RegistrasiSelesaiLQ,
        meta: {
            name: 'lead qualification - registrasi selesai',
            isAuth: true,
            title: 'Registrasi Program Keringanan Utang',
            leadQualification: true,
            excludeKYC: true
        }
    }
];