<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

onMounted(() => {
    localStorage.setItem('from-onboarding-go-to', 'rdp - home')

    router.push({ name : 'Login' })
})
</script>

<template>
    <div>
        <loading-overlay />
    </div>
</template>