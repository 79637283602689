<script setup>
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()

onMounted(() => {
    const serviceId = route.query.service_id ?? null
    localStorage.setItem('from-onboarding-go-to', serviceId ? 'Credit Checkup - Home' : 'Score')
    if(serviceId) localStorage.setItem('credit-checkup-service-id', serviceId)

    router.push({ name : 'Login' })
})
</script>

<template>
    <div>
        <loading-overlay />
    </div>
</template>