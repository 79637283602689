const state = () => ({
    promoType: null,
    promoCode: null
});

const actions = {
    async savePromo({ commit }, payload) {
        commit("savePromo", payload);
    },
    async removePromo({ commit }) {
        commit("removePromo");
    }
};

const mutations = {
    savePromo(state, payload) {
        state.promoType = payload.promoType;
        state.promoCode = payload.promoCode;
        localStorage.setItem("promoType", payload.promoType);
        localStorage.setItem("promoCode", payload.promoCode);
    },
    removePromo(state) {
        state.promoType = null;
        state.promoCode = null;
        localStorage.removeItem("promoType");
        localStorage.removeItem("promoCode");
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
