<script setup>
import NavbarWithBackV2 from '@/components/NavbarWithBackV2.vue'
import BasicCollapsable from '@/components/Collapsable/BasicCollapsable.vue'
import { useRouter, useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

const router = useRouter()
const route = useRoute()
const store = useStore()

const faqs = computed(() => store.getters['faq/getNegotiationProcess'])

onMounted(() => {
    if(faqs.value.length > 0) return 
    return router.push({ name: 'Negotiation Process - Home' })
})

</script>

<template>
    <div class="min-h-screen bg-amalan-white relative">
        <NavbarWithBackV2 :title="route.meta.title" @back-button="router.push({ name: 'Negotiation Process - Home' })" />
        <div class="w-full pt-8 pb-20 px-6 bg-amalan-white">
            <p class="text-center text-amalan-black text-xs sm:text-sm">Temukan jawaban dari pertanyaan Anda di halaman ini</p>
            <div class="w-full mt-8 grid grid-cols-1 gap-y-6">
                <BasicCollapsable v-for="(faq, index) in faqs" :key="index" :title="faq.question" :content="faq.answer" />
            </div>
        </div>
    </div>
</template>