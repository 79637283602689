import amalansignupApi from "@/axios/amalansignup"

const state = () => ({
    fetchStatus: false,
    isTouched: false,
    case_progress_data: [],
    request_ready_for_negotiation: [],
    opportunities: [],
    outstandingAmount: 0
})

const getters = {
    getOpportunitiesFetchStatus(state) {
        return state.fetchStatus
    },
    getTouchedStatus(state) {
        return state.isTouched
    },
    getOpportunitiesData(state) {
        return state.opportunities
    },
    getOutstandingAmount(state) {
        return state.outstandingAmount
    },
    getUnpaidOutstandingAmount(state) {
        if(state.opportunities.length === 0) return null
        return state.opportunities[0].outstanding_amount + state.opportunities[1].outstanding_amount
    },
    getRequestReadyForNegotiation(state) {
        return state.request_ready_for_negotiation
    }
}

const actions = {
    async fetchData({ commit, state }) {
        try {
            state.fetchStatus = true
            const opportunitiesData = await amalansignupApi.get('loan-management/opportunity')
            const caseProgressData = await amalansignupApi.get('loan-management/opportunity/case-progress')
            state.fetchStatus = false
            
            if(caseProgressData.status !== 200) return commit('resetOpportunities')
            commit('storeCaseProgress', caseProgressData.data.data)
            
            if(opportunitiesData.status !== 200) return commit('resetOpportunities')
            return commit('storeOpportunities', opportunitiesData.data.data)
        } catch (error) {
            state.fetchStatus = false
            console.log(error)
            return commit('resetOpportunities')
        }
    }
}

const mutations = {
    storeOpportunities(state, payload) {
        const remapWithCaseProgress = payload.map((opportunity) => {
            if(!opportunity.Case_Progress__c) return opportunity
    
            const current_case_progress_index = state.case_progress_data.findIndex((values) => opportunity.Case_Progress__c.includes(values.name))
            if(current_case_progress_index < 0) return opportunity
    
            const case_progress_data = state.case_progress_data.slice(0, current_case_progress_index)
            case_progress_data.reverse()

            return {
                ...opportunity,
                case_progress_data 
            }
        })

        const result = [
            {
                title: 'Pinjaman Dalam Proses',
                group: 'Target Pelunasan',
                outstanding_amount: 0,
                data: []
            },
            {
                title: 'Pinjaman Dalam Proses',
                group: 'Dalam Antrian',
                outstanding_amount: 0,
                data: []
            },
            {
                title: 'Pinjaman Selesai / Lunas',
                group: 'Selesai / Lunas',
                outstanding_amount: 0,
                data: []
            }
        ]

        remapWithCaseProgress.forEach((values) => {
            state.outstandingAmount += values.Estimated_Outstanding_Amount__c ?? 0
            
            if(values.StageName === 'Closed Won'){
                result[2].outstanding_amount += values.Estimated_Outstanding_Amount__c ?? 0
                return result[2].data.push(values)
            }

            if(!values.Case_Progress__c){
                result[1].outstanding_amount += values.Estimated_Outstanding_Amount__c ?? 0
                return result[1].data.push(values)
            }
            
            result[0].outstanding_amount += values.Estimated_Outstanding_Amount__c ?? 0
            return result[0].data.push(values)
        })

        state.isTouched = true
        state.opportunities = result
    },
    storeCaseProgress(state, payload) {
        state.request_ready_for_negotiation = payload.splice(0, 1)
        state.case_progress_data = payload.map((values) => {
            return {
                ...values,
                timestamp_object_key: `${values.name.replaceAll(' ', '_')}_Timestamp__c`
            }
        })
    },
    resetOpportunities(state){
        state.opportunities = [
            {
                title: 'Pinjaman Dalam Proses',
                group: 'Target Pelunasan',
                outstanding_amount: 0,
                data: []
            },
            {
                title: 'Pinjaman Dalam Proses',
                group: 'Dalam Antrian',
                outstanding_amount: 0,
                data: []
            },
            {
                title: 'Pinjaman Selesai / Lunas',
                group: 'Selesai / Lunas',
                outstanding_amount: 0,
                data: []
            }
        ]
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
