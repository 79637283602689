export const multiselectClass = {
    container: 'relative mx-auto w-full flex items-center justify-end cursor-pointer rounded-md bg-amalan-white text-xs leading-snug outline-none',
    containerOpen: 'rounded-b-none',
    containerOpenTop: 'rounded-t-none',
    singleLabel: 'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border',
    singleLabelText: 'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
    search: 'w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-xs font-sans bg-amalan-white rounded pl-3.5',
    caret: 'bg-multiselect-caret bg-center bg-no-repeat w-6 h-6 py-px box-content mr-2 relative z-10 opacity-80 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none text-blue-900',
    caretOpen: 'rotate-180 pointer-events-auto',
    clear: 'pr-2 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80',
    clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
    dropdown: 'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-amalan-white flex flex-col rounded-md shadow-lg',
    dropdownTop: '-translate-y-full top-px bottom-auto flex-col-reverse rounded-md shadow-lg',
    dropdownHidden: 'hidden',
    options: 'flex flex-col p-0 m-0 list-none',
    optionsTop: 'flex-col-reverse',
    option: 'flex items-center justify-start box-border text-left cursor-pointer text-xs leading-snug py-1 px-3',
    optionPointed: 'text-white bg-blue-500',
    noOptions: 'py-2 px-3 text-gray-600 bg-amalan-white text-center',
    noResults: 'py-2 px-3 text-gray-600 bg-amalan-white text-center',
    spacer: 'h-7 py-px box-content',
    placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-amalan-black text-xs'
}

export const multiselectSearchClass = {
    container: 'relative mx-auto w-full flex items-center justify-end cursor-pointer rounded-md bg-amalan-white text-xs leading-snug outline-none',
    containerOpen: 'rounded-b-none',
    containerOpenTop: 'rounded-t-none',
    singleLabel: 'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border',
    singleLabelText: 'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
    search: 'w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-xs font-sans bg-amalan-white rounded pl-3.5',
    caret: 'bg-multiselect-search bg-center bg-no-repeat w-6 h-6 py-px box-content mr-2 relative z-10 opacity-80 flex-shrink-0 flex-grow-0 pointer-events-none text-blue-900',
    caretOpen: 'pointer-events-auto',
    clear: 'pr-2 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80',
    clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
    dropdown: 'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-amalan-white flex flex-col rounded-md shadow-lg',
    dropdownTop: '-translate-y-full top-px bottom-auto flex-col-reverse rounded-md shadow-lg',
    dropdownHidden: 'hidden',
    options: 'flex flex-col p-0 m-0 list-none',
    optionsTop: 'flex-col-reverse',
    option: 'flex items-center justify-start box-border text-left cursor-pointer text-xs leading-snug py-1 px-3',
    optionPointed: 'text-white bg-blue-500',
    noOptions: 'py-2 px-3 text-gray-600 bg-amalan-white text-center',
    noResults: 'py-2 px-3 text-gray-600 bg-amalan-white text-center',
    spacer: 'h-7 py-px box-content',
    placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-amalan-black text-xs'
}

export const multiselectClassDisabled = {
    container: 'relative mx-auto w-full flex items-center justify-end cursor-pointer rounded-md text-xs leading-snug outline-none',
    containerOpen: 'rounded-b-none',
    containerOpenTop: 'rounded-t-none',
    singleLabel: 'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border',
    singleLabelText: 'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full text-amalan-gray-2',
    search: 'w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-xs font-sans bg-amalan-white rounded pl-3.5',
    caret: 'bg-multiselect-caret-disabled bg-center bg-no-repeat w-6 h-6 py-px box-content mr-2 relative z-10 opacity-80 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none text-blue-900',
    caretOpen: 'rotate-180 pointer-events-auto',
    clear: 'pr-2 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80',
    clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
    dropdown: 'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-amalan-white flex flex-col rounded-md shadow-lg',
    dropdownTop: '-translate-y-full top-px bottom-auto flex-col-reverse rounded-md shadow-lg',
    dropdownHidden: 'hidden',
    options: 'flex flex-col p-0 m-0 list-none',
    optionsTop: 'flex-col-reverse',
    option: 'flex items-center justify-start box-border text-left cursor-pointer text-xs leading-snug py-1 px-3',
    optionPointed: 'text-white bg-blue-500',
    noOptions: 'py-2 px-3 text-gray-600 bg-amalan-white text-center',
    noResults: 'py-2 px-3 text-gray-600 bg-amalan-white text-center',
    spacer: 'h-7 py-px box-content',
    placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-amalan-gray-2 text-xs',
    containerDisabled: 'cursor-not-allowed bg-amalan-gray-5',
}

export const dataLamaTunggakan = [
    {
        value: '0',
        label: '0 Bulan'
    },
    {
        value: '1-3',
        label: '1-3 Bulan'
    },
    {
        value: '4-6',
        label: '4-6 Bulan'
    },
    {
        value: '7-12',
        label: '7-12 Bulan'
    },
    {
        value: '>12',
        label: 'Lebih dari 12 Bulan'
    },
]