<script setup>
import { defineProps, ref } from 'vue'
import VuePdfEmbed from 'vue-pdf-embed'

defineProps({
    visible: Boolean,
    pdfFileSource: String //valid url or base64
})

const pdfContainer = ref(null)
const pdfContainerWidth = ref(500)
const zoomIn = ref(true)
const prevDiff = ref(-1)
const containerValue = ref(0)

const pdfContainerClick = () => {
    if(zoomIn.value){
        pdfContainerWidth.value = 800
        zoomIn.value = !zoomIn.value
    }else{
        pdfContainerWidth.value = 500
        zoomIn.value = !zoomIn.value
    }
}

const handleDocumentRender = () => {
    const vueEmbedClass = document.querySelector(".vue-pdf-embed")
    const child = vueEmbedClass.children
    for (let i = 0; i < (child.length - 1); i++) {
        child[i].style.borderBottom = '10px solid #E4E5E6'
    }

    vueEmbedClass.ontouchstart = touchStartHandler
    vueEmbedClass.ontouchmove = touchMoveHandler
    vueEmbedClass.ontouchcancel = touchEndHandler
    vueEmbedClass.ontouchend = touchEndHandler
}

const touchMoveHandler = (event) => {
    if (event.touches.length > 1) {
        const currDiff = Math.abs(event.touches[0].clientX - event.touches[1].clientX)

        if (prevDiff.value > 0) {
            if (currDiff > prevDiff.value) {
                containerValue.value += 1
                if(containerValue.value === 10) pdfContainerWidth.value = 600
                if(containerValue.value === 20) pdfContainerWidth.value = 800
            }
            if (currDiff < prevDiff.value) {
                containerValue.value -= 1
                if(containerValue.value === -10) pdfContainerWidth.value = 500
                if(containerValue.value === -20) pdfContainerWidth.value = 300
            }
        }

        prevDiff.value = currDiff;
    }
}

const touchStartHandler = () => {
    prevDiff.value = -1
    containerValue.value = 0
}

const touchEndHandler = () => {
    prevDiff.value = -1
    containerValue.value = 0
}

</script>

<template>
    <Transition name="fade-fast">
        <div v-show="visible" class="absolute left-0 right-0 top-14 bottom-0 bg-black bg-opacity-50 p-6 flex justify-center items-center">
            <div class="w-full pb-0 pt-16 px-6 bg-transparent absolute inset-0">
                <div class="bg-amalan-white h-full overflow-scroll scrollbar-hide">
                    <VuePdfEmbed
                        ref="pdfContainer"
                        :style="zoomIn ? 'cursor: -webkit-zoom-in' : 'cursor: -webkit-zoom-out'"
                        :width="pdfContainerWidth"
                        :source="pdfFileSource"
                        @click="pdfContainerClick"
                        @rendered="handleDocumentRender"
                    />
                </div>
            </div>
        </div>
    </Transition>
</template>

<style scoped>
.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.3s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.3s ease
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
</style>