<template>
    <Transition name="fade-fast">
        <div class="w-full max-w-lg absolute shadow-xl z-40" v-show="isShow">
            <nav class="bg-blue-100 w-full max-w-lg absolute z-50">
                <div class="px-4 py-4 mx-auto">
                    <div class="flex items-center justify-between">
                        <button @click="close" class="flex items-center mt-0">
                            <ChevronLeftIcon class="h-5 w-5"/>
                        </button>
                        <span class="text-sm font-bold mt-0">{{ $route.meta.title }}</span>
                        <div class="flex items-center mt-0">
                            <div class="w-5 h-5 bg-transparent"></div>
                        </div>
                    </div>
                </div>
            </nav>
            <div class="bg-black bg-opacity-60 backdrop-filter backdrop-blur-sm max-w-lg w-full h-screen px-6 fixed flex flex-col items-center justify-center">
                <div class="rounded-xl shadow-amalan-lg relative w-full bg-white">
                    <div class="w-7 h-7 rounded-full shadow-amalan-lg bg-white absolute z-50 -top-3 -right-2 flex justify-center items-center cursor-pointer hover:bg-gray-100" @click="close">
                        <XIcon class="w-4 text-blue-900" />
                    </div>
                    <div class="w-full rounded-xl overflow-hidden relative">
                        <video ref="videoDataTag" src="https://storage.googleapis.com/core.ayolunas.com/Assets/video/amalan_tiktok_ads.mp4" class="w-full" autoplay="autoplay" loop muted></video>
                        <div class="absolute bottom-3 right-3 bg-amalan-white w-6 h-6 rounded-md cursor-pointer flex justify-center items-center" @click="switchMuted">
                            <img v-if="isVideoMuted" :src="require('@/assets/img/volume-off-rounded.svg')" class="w-4">
                            <img v-else :src="require('@/assets/img/volume-up-rounded.svg')" class="w-4">
                        </div>
                    </div>
                </div>
                <div class="bg-amalan-white mt-8 py-1 px-4 rounded-amalan-lg border border-amalan-blue-1 cursor-pointer" @click="redirectUrl">
                    <p class="text-amalan-blue-1 text-xs font-bold">Tonton Cerita Sukses Lainnya</p>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import { XIcon } from '@heroicons/vue/solid'
import { mapGetters } from 'vuex'
import { ChevronLeftIcon } from '@heroicons/vue/solid'

export default {
    components: {
        XIcon,
        ChevronLeftIcon
    },
    data() {
        return {
            isVideoMuted: true
        }
    },
    computed: {
        ...mapGetters('globalState',{
            isShow:'getAmalanTiktokAds'
        }),
        ...mapGetters('leadQualification',{
            contactData: 'getContact'
        })
    },
    mounted(){
        if(this.contactData.Status_of_Lead__c && this.contactData.Status_of_Lead__c === 'Qualified'){
            setTimeout(() => {
                this.$store.dispatch('globalState/changeAmalanTiktokAds')
            }, 500);
        }
    },
    methods: {
        close() {
            this.$store.dispatch('globalState/changeAmalanTiktokAds')
            this.$refs.videoDataTag.pause()
        },
        redirectUrl(){
            this.$store.dispatch('globalState/changeAmalanTiktokAds')
            this.$refs.videoDataTag.pause()
            window.open('https://www.tiktok.com/@amalan.id', '_blank')
        },
        switchMuted(){
            this.$refs.videoDataTag.muted = !this.$refs.videoDataTag.muted
            this.isVideoMuted = this.$refs.videoDataTag.muted
        }
    }
}
</script>

<style scoped>
.fade-fast-enter-active,
.fade-fast-leave-active {
    transition: all 0.5s ease;
    opacity: 1;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
    opacity: 0;
    transition: all 0.5s ease
}
</style>