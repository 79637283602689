<template>
    <div class="flex justify-center">
        <div class="w-full max-w-lg shadow-xl min-h-screen h-auto">
            <Navbar class="hidden" />
            <div>
                <Body />
            </div>
            <Toolbar />
        </div>
    </div>
</template>

<script>
import Navbar from '../Navbar';
import Toolbar from '../Toolbar';
import Body from '../Body';

export default {
    components: {
        Body,
        Toolbar,
        Navbar
    },
};
</script>