<template>
    <div class="pt-4 min-h-screen flex items-center">
        <div class="w-full px-4 pb-24">
            <img src="@/assets/img/under-construction.png" class="w-3/5 mx-auto">
            <p class="text-center px-4 text-sm mt-6">Halaman ini sedang dalam pengembangan dan <b>amalan</b> akan segera hadir untukmu!</p>
        </div>
        <div class="h-28"></div>
    </div>
</template>

<script>
export default {
}
</script>