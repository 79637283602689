<template>
    <div class="text-center mt-5 pt-10 pb-44">
        <p class="text-7xl text-yellow-400 mt-2">500</p>
        <p class="text-xl text-red-600 mt-3">Opps, terjadi kesalahan.</p>
        <div class="justify-center flex">
            <img src="../assets/img/404.png" class="w-60">
        </div>
        <div class="pt-8">
            <router-link to="/home" type="button" class="text-sm text-white font-semibold border-2 p-3 rounded-lg bg-blue-700 hover:bg-blue-400 inline-flex items-center">
                <ArrowCircleLeftIcon class="w-10 h-10" />
                <span>HOME</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import { ArrowCircleLeftIcon } from '@heroicons/vue/solid';

export default {
    components: {
        ArrowCircleLeftIcon,
    }
}
</script>