<template>
    <div class="relative">
        <nav class="bg-blue-100">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="$router.push({ name: 'Home' })" class="flex items-center mt-0">
                        <ChevronLeftIcon class="h-5 w-5"/>
                    </button>
                    <span class="text-base font-bold mt-0">{{ $route.meta.title }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="w-full relative h-60">
            <div class="w-full h-60 bg-blue-100 absolute top-0 rounded-br-amalan-lg py-4">
                <div class="w-full flex justify-between items-center px-6 mb-5">
                    <img src="@/assets/img/logo-amalan.png" class="h-7">
                    <span class="text-xs">Credit Check: {{ CBResult ? userScoreData.name === 'Tidak Ditemukan' ? '-' : this.$filters.formatDateOnlyDate(CBResult.created_at) : '-' }}</span>
                </div>
                <div class="mx-auto w-9/12 h-40">
                    <div class="h-full flex justify-center items-center">
                        <div class="h-auto text-center mt-2 mb-5">
                            <div class="w-52 h-36 mb-1">
                                <div class="gauge-background">
                                <div class="gauge-a bg-gray-100"></div>
                                <div class="gauge-b-background-1 bg-blue-100"></div>
                                <div class="gauge-b-background-2 bg-white"></div>
                                <div class="gauge-b-background-3 bg-blue-100"></div>
                                <div class="gauge-b bg-white"></div>
                                <div class="gauge-c" :style="{'transform': 'rotate(' + styleBar + 'turn)'}" :class="'bg-' + userScoreData.color"></div>
                                <div class="gauge-c" :style="{'transform': 'rotate(' + gaugeDecoration[4].styleBar + 'turn)'}" :class="'bg-' + gaugeDecoration[4].color"></div>
                                <div class="gauge-c" :style="{'transform': 'rotate(' + gaugeDecoration[3].styleBar + 'turn)'}" :class="'bg-' + gaugeDecoration[3].color"></div>
                                <div class="gauge-c" :style="{'transform': 'rotate(' + gaugeDecoration[2].styleBar + 'turn)'}" :class="'bg-' + gaugeDecoration[2].color"></div>
                                <div class="gauge-c" :style="{'transform': 'rotate(' + gaugeDecoration[1].styleBar + 'turn)'}" :class="'bg-' + gaugeDecoration[1].color"></div>
                                <div class="gauge-c" :style="{'transform': 'rotate(' + gaugeDecoration[0].styleBar + 'turn)'}" :class="'bg-' + gaugeDecoration[0].color"></div>
                                <div class="gauge-d bg-amalan-gray-300" :style="{'transform': 'rotate(' + styleIndicator + 'turn)'}"></div>
                                <div class="gauge-data pt-3">
                                    <vue3-autocounter class="text-2xl font-bold" ref='counter' :startAmount='0' :endAmount='scoreRaw' :duration='1.3'/>
                                </div>
                                </div>
                                <div class="div-clearly h-2 bg-transparent absolute z-10 flex justify-between">
                                    <div class="transition-custom-div h-2 rounded-b-full w-5 ml-1" :class="'bg-' + gaugeDecoration[0].color"></div>
                                    <div class="h-2 w-40 bg-blue-100 flex justify-center">
                                        <div class="gauge-data-clearly h-2 rounded-b-full bg-white"></div>
                                    </div>
                                    <div class="transition-custom-div h-2 rounded-b-full w-5 mr-1 bg-gray-100"></div>
                                </div>
                            </div>
                            <div class="botton-line mx-auto h-0.5 bg-blue-900 mb-2"></div>
                            <span v-if="CBResult === 'not found'" class="text-lg font-bold text-blue-900">Cek Sekarang</span>
                            <span v-else class="text-lg font-bold text-blue-900">{{ userScoreData.name }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="!isLoading">
            <div class="w-full pt-4 pb-28 relative">
                <div class="w-full px-6 py-4" :class="[ userScoreData.name === 'Tidak Ditemukan' ? 'bg-red-100' : 'bg-blue-50' ]">
                    <p class="text-base text-blue-900 font-bold" v-if="CBStatus === 'found'">Credit Score Saya</p>
                    <p class="text-xs mt-1" v-html="userScoreData.content"></p>
                </div>
                <template v-if="CBStatus === 'found'">
                    <div class="w-full px-6 py-4 mt-1">
                        <p class="text-base text-blue-900 font-bold">Cara Mendapatkan Credit Score yang Ideal</p>
                        <p class="text-xs mt-1">Anda dapat meningkatkan Credit Score Anda dengan mengikuti beberapa langkah sederhana berikut:</p>
                        <ul class="list-disc text-xs mt-3 ml-5">
                            <li>Batasi penggunaan kartu kredit dibawah 30% dari limit yang tersedia</li>
                            <li>Menaikkan kredit limit kartu kredit</li>
                            <li>Bayar tagihan tepat waktu</li>
                            <li>Hindari melakukan pembayaran tagihan kartu kredit pada minimum payment</li>
                        </ul>
                        <p class="text-xs mt-3">Analisa kredit yang baik, penting untuk analisa keuangan Anda. Karena menentukan peluang untuk mendapatkan pengajuan pinjaman dan berapa banyak Anda akan membayar bunga.</p>
                        <p class="text-xs mt-3">Di bawah ini adalah uraian sederhana kisaran kredit yang berbeda yang digunakan oleh amalan dan CLIK*.</p>
                    </div>
                    <div class="w-full px-6 pt-2 pb-6">
                        <p class="text-base text-blue-900 font-bold mb-2">Nilai Credit Score</p>
                        <div class="w-full border-t-2 border-solid py-3 mt-1" v-for="(data, key) in scoreData" :key="key">
                            <div class="w-28 py-0.5 rounded-2xl flex justify-center" :class="'bg-' + data.color">
                                <span class="text-2xs" :class="[ data.name === 'Baik' ? 'text-black' : 'text-white' ]">{{ data.distance }} ({{ data.name }})</span>
                            </div>
                            <p class="text-xs mt-2">{{ data.detail }}</p>
                        </div>
                        <div class="w-full flex">
                            <p class="text-2xs text-gray-500">*</p>
                            <p class="text-2xs text-gray-500 ml-1">CLIK merupakan afiliasi dari CRIF Spa yang bekerjasama dengan perusahaan industri penyedia jasa teknologi dan layanan terkemuka lainnya di Indonesia yang merupakan partner amalan international.</p>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="w-full px-6 py-4 mt-3">
                        <p class="text-base text-blue-900 font-bold mb-2">Credit Score Anda tidak muncul?</p>
                        <div class="w-full py-2">
                            <div class="w-full rounded-lg shadow-amalan-lg">
                                <div class="w-full flex justify-between items-center py-3 px-4 cursor-pointer" @click="showFaq(1)">
                                    <p class="text-xs">Kenapa Credit Score saya tidak muncul?</p>
                                    <ChevronDownIcon class="h-6 text-blue-900" :class="[ firstFaq ? 'chevron-open' : 'chevron-close' ]" />
                                </div>
                                <Transition name='dropdown'>
                                    <div v-show="firstFaq" class="pb-3 px-4">
                                        <p class="text-xs border-t-2 pt-3">Credit Score Anda tidak muncul bisa saja disebabkan oleh data Anda yang tidak dapat diproses pada sistem kami, tidak terdapat riwayat kredit atau pinjaman yang pernah Anda miliki, atau proses verifikasi data Anda tidak berhasil.</p>
                                    </div>
                                </Transition>
                            </div>
                        </div>
                        <div class="w-full py-3">
                            <div class="w-full rounded-lg shadow-amalan-lg">
                                <div class="w-full flex justify-between items-center py-3 px-4 cursor-pointer" @click="showFaq(2)">
                                    <p class="text-xs">Bagaimana proses kerja Credit Score?</p>
                                    <ChevronDownIcon class="h-6 text-blue-900" :class="[ secondFaq ? 'chevron-open' : 'chevron-close' ]" />
                                </div>
                                <Transition name='dropdown'>
                                    <div v-show="secondFaq" class="pb-3 px-4">
                                        <p class="text-xs border-t-2 pt-3">amalan akan melakukan verifikasi atas data yang Anda sediakan, untuk setiap data yang sudah terverifikasi akan dikirimkan untuk diproses oleh lembaga biro kredit CLIK sebagai partner resmi amalan yang terdaftar dan diawasi oleh OJK untuk. CLIK akan menerbitkan laporan kredit Anda yang kemudian akan diproses oleh amalan menjadi informasi yang bermanfaat untuk Anda dalam mengambil keputusan finansial.</p>
                                    </div>
                                </Transition>
                            </div>
                        </div>
                        <div class="w-full py-2">
                            <div class="w-full rounded-lg shadow-amalan-lg">
                                <div class="w-full flex justify-between items-center py-3 px-4 cursor-pointer" @click="showFaq(3)">
                                    <p class="text-xs">Apa kegunaan dari Credit Score?</p>
                                    <ChevronDownIcon class="h-6 text-blue-900" :class="[ thirdFaq ? 'chevron-open' : 'chevron-close' ]" />
                                </div>
                                <Transition name='dropdown'>
                                    <div v-show="thirdFaq" class="pb-3 px-4">
                                        <p class="text-xs border-t-2 pt-3">Credit Score merupakan angka yang menggambarkan kondisi kredit Anda, yang umum digunakan oleh lembaga pemberi pinjaman seperti bank untuk mengukur kelayakan pinjaman kepada nasabah. Anda juga dapat meningkatkan kualitas perekonomian Anda dengan mengerti dan memperlajari laporan kredit Anda.</p>
                                    </div>
                                </Transition>
                            </div>
                        </div>
                    </div>
                </template>
                <div v-if="CBStatus === 'found'" class="fixed bottom-0 w-full max-w-lg h-24 shadow-amalan-lg sm:shadow-none sm:border-t bg-amalan-white text-center px-6">
                    <p class="text-xs mt-2 mb-3">Apakah Anda ingin mengetahui lebih lanjut tentang Credit Score Anda dan mendapatkan konsultasi gratis?</p>
                    <router-link :to="{ name: 'Landing Page Credit Checkup' }" class="py-2 px-8 bg-blue-900 text-white hover:bg-blue-700 rounded-lg mx-auto">Konsultasi Sekarang</router-link>
                </div>
            </div>
        </template>
        <loading-overlay v-if="isLoading" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ChevronLeftIcon, ChevronDownIcon } from '@heroicons/vue/solid'
import Vue3autocounter from 'vue3-autocounter'
// import axios from "../../axios"
import amalansignupApi from "@/axios/amalansignup"

export default {
    data(){
        return {
            scoreRaw: 0,
            userScoreData: {
                name: '-',
                distance: '-',
                color: '-',
                content: '-'
            },
            styleBar: '',
            styleIndicator: '',
            isLoading: false,
            tryToRequestToCLIK: true,
            firstFaq: false,
            secondFaq: false,
            thirdFaq: false,
            gaugeDecoration: [
                {
                    styleBar: '',
                    color: '-'
                },
                {
                    styleBar: '',
                    color: '-'
                },
                {
                    styleBar: '',
                    color: '-'
                },
                {
                    styleBar: '',
                    color: '-'
                },
                {
                    styleBar: '',
                    color: '-'
                },
            ]
        }
    },
    components:{
        ChevronLeftIcon,
        ChevronDownIcon,
        'vue3-autocounter': Vue3autocounter
    },
    computed: {
        ...mapGetters('creditCheckup',{
            CBResult: 'getCBResult',
            CBScore: 'getCBScore',
            CBStatus: 'getCBStatus',
            scoreData: 'getScoreData'
        }),
    },
    methods: {
        getCreditScore(){
            if(this.CBStatus == 'found'){
                this.isLoading = false
                if(this.CBScore.ScoreRaw === 'n/a'){
                    this.noDataFound()
                }else{
                    setTimeout(() => {
                        // this.scoreRaw = 850
                        this.scoreRaw = parseInt(this.CBScore.ScoreRaw)
                        let score = this.scoreRaw <= 50 ? 50 : this.scoreRaw >= 700 ? 700 : this.scoreRaw

                        this.styleBar = score * 0.000545
                        this.styleIndicator = score * 0.00056
    
                        for(let i in this.scoreData){
                            if(this.scoreRaw >= this.scoreData[i].min && this.scoreRaw <= this.scoreData[i].max){
                                this.userScoreData.name = this.scoreData[i].name
                                this.userScoreData.distance = this.scoreData[i].distance
                                this.userScoreData.color = this.scoreData[i].color
                                this.userScoreData.content = this.scoreData[i].content

                                this.styleBar = score * this.scoreData[i].styleBarScale
                                this.styleIndicator = score * this.scoreData[i].styleIndicatorScale
                                break
                            }

                            if(this.scoreRaw > this.scoreData[i].max){
                                this.gaugeDecoration[i].styleBar = this.scoreData[i].max * this.scoreData[i].styleBarScale
                                this.gaugeDecoration[i].color = this.scoreData[i].color
                            }
                        }

                        this.gaugeDecoration[0].color = 'credit-score-100'
                        this.$refs.counter.start()
                    }, 500)
                }
            }else{
                // if(this.tryToRequestToCLIK){
                //     this.isLoading = true
                //     axios.get('/clik/request-cb-result').then(() => {
                //         this.isLoading = false
                //         this.$store.dispatch('creditCheckup/requestCBResult')
                //         this.tryToRequestToCLIK = false
                //     }).catch(() => {
                //         this.isLoading = false
                //         this.$store.dispatch('creditCheckup/requestCBResult')
                //         this.tryToRequestToCLIK = false
                //         this.noDataFound()
                //     })
                // }else{
                this.isLoading = false
                this.noDataFound()
                // }
            }
        },
        noDataFound(){
            setTimeout(() => {
                this.scoreRaw = 0
                let score = 20
                this.styleBar = score * 0.000545
                this.styleIndicator = score * 0.00056

                this.userScoreData.name = 'Tidak Ditemukan'
                this.userScoreData.color = 'gray-100'
                this.userScoreData.content = 'Halo sobat amalan, sepertinya terjadi kesalahan pada pemrosesan data Anda dengan sistem kami, sehingga Credit Score Anda tidak dapat ditampilkan <br><br> <b>Silahkan tunggu</b> konsultan kami menghubungi Anda untuk mendapatkan <b>konsultasi gratis</b> dan informasi lebih lanjut.'

                this.$refs.counter.start()
            }, 500)
        },
        showFaq(value){
            if(value === 1){
                this.firstFaq = this.firstFaq ? false : true
                this.secondFaq = false
                this.thirdFaq = false
            }else if(value === 2){
                this.firstFaq = false
                this.secondFaq = this.secondFaq ? false : true
                this.thirdFaq = false
            }else if(value === 3){
                this.firstFaq = false
                this.secondFaq = false
                this.thirdFaq = this.thirdFaq ? false : true
            }
        }
    },
    watch: {
        CBStatus: {
            handler(){
                this.getCreditScore()
            }
        }
    },
    created(){
        if(!this.CBStatus){
            this.isLoading = true
        }else{
            this.getCreditScore()
        }

        if(localStorage.getItem('CreditScoreFromProduct')){
            amalansignupApi.put('salesforce/lead-qualification/update-stage', {
                stageName : "lq-credit-score",
                product : localStorage.getItem('CreditScoreFromProduct')
            })
            .then(() => {
                localStorage.removeItem('CreditScoreFromProduct')
            })
        }
    }
}
</script>

<style scoped>
.gauge-background {
    width: 200px;
    height: 100px;
    position: relative;
    top: 56%;
    left: 50%;
    overflow: hidden;
    text-align: center;
    transform: translate(-50%, -50%);
}

.gauge-a {
    z-index: 1;
    position: absolute;
    width: 200px;
    height: 100px;
    top: 0%;
    border-radius: 250px 250px 0px 0px;
}

.gauge-b {
    z-index: 4;
    position: absolute;
    width: 100px;
    height: 58px;
    top: 48px;
    margin-left: 49px;
    margin-right: auto;
    border-radius: 250px 250px 0px 0px;
}

.gauge-b-background-1 {
    z-index: 3;
    position: absolute;
    width: 160px;
    height: 120px;
    top: 20px;
    margin-left: 20px;
    margin-right: auto;
    border-radius: 250px 250px 0px 0px;
}

.gauge-b-background-2 {
    z-index: 3;
    position: absolute;
    width: 152px;
    height: 120px;
    top: 24px;
    margin-left: 24px;
    margin-right: auto;
    border-radius: 250px 250px 0px 0px;
}

.gauge-b-background-3 {
    z-index: 3;
    position: absolute;
    width: 148px;
    height: 120px;
    top: 26px;
    margin-left: 26px;
    margin-right: auto;
    border-radius: 250px 250px 0px 0px;
}

.gauge-c {
    z-index: 2;
    position: absolute;
    width: 200px;
    height: 100px;
    top: 100px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0px 0px 200px 200px;
    transform-origin: center top;
    transition: all 1.3s ease-in-out;
}

.gauge-d {
    z-index: 3;
    position: absolute;
    width: 200px;
    height: 6px;
    top: 100px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px 5px 5px 5px;
    transform-origin: center top;
    transition: all 1.3s ease-in-out;
}

.gauge-data {
    z-index: 4;
    position: absolute;
    width: 200px;
    height: 100px;
    top: 54px;
    margin-left: auto;
    margin-right: auto;
}

.div-clearly {
    margin-top: 30px;
    width: 208px;
}

.gauge-data-clearly {
    width: 100px;
    margin-right: 2px;
}

.transition-custom-div {
    transition: all 1.3s ease-in-out;
}

.botton-line {
    width: 95%;
}

.dropdown-enter-active,
.dropdown-leave-active {
    transition: all 0.5s ease;
    opacity: 1;
}

.dropdown-enter-from,
.dropdown-leave-to {
    opacity: 0;
    transition: all 0.2s ease;
}

.chevron-open {
    transform: rotate( -180deg );            
    transition: transform 0.3s ease;
}

.chevron-close {
    transform: rotate( -360deg );            
    transition: transform 0.3s ease;
}
</style>