<template>
    <div class="w-full min-h-screen">
        <Body />
    </div>
</template>

<script>
import Body from '../Body';

export default {
    components: {
        Body,
    },
}
</script>