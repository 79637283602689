import apiRdpManagement from "../../axios/rdpManagement"
import store from ".."
import moment from 'moment'

const RDP_DATA_STATUSES = { FETCH: 'FETCH', FOUND: 'FOUND', NOT_FOUND: 'NOT_FOUND', ERROR: 'ERROR' }
const PACKAGE_LIST = ['DIAMOND', 'PLATINUM', 'GOLD', 'SILVER', 'BASIC']
const PAYMENT_FETCH_STATUSES = { FETCH: 'FETCH', DONE: 'DONE' }
const PAYMENT_STATUSES = { pending: 'PENDING', onprogress: 'ONPROGRESS', done: 'DONE', cancelled: 'CANCELLED', failed: 'FAILED', waiting: 'WAITING', expired: 'EXPIRED' }

const state = () => ({
    rdpData: {
        isExist: RDP_DATA_STATUSES.FETCH,
        cardType: null,
        accountNumber: null,
        fullName: null,
        balance: null,
        createdDate: null,
        feedbackStatus: null
    },
    paymentData: {
        isFetching: PAYMENT_FETCH_STATUSES.FETCH,
        notification: false,
        data: []
    }
})

const getters = {
    getRdpExisting(state) {
        return state.rdpData.isExist
    },
    getRdpData(state) {
        return state.rdpData
    },
    paymentFetchStatus(state) {
        return state.paymentData.isFetching
    },
    paymentData(state) {
        return state.paymentData.data
    },
    paymentDataNotification(state) {
        return state.paymentData.notification
    }
}

const actions = {
    requestRdpData({ dispatch, commit }) {
        return apiRdpManagement.get('/bni')
        .then((res) => {
            commit("setRdpData", { data: res.data.data, status: RDP_DATA_STATUSES.FOUND })
            dispatch('requestLatestPaymentData')
            return
        })
        .catch((err) => {
            if(err.response && err.response.status == 404){
                commit('setRdpDataError', RDP_DATA_STATUSES.NOT_FOUND)
                return
            }
            
            commit('setRdpDataError', RDP_DATA_STATUSES.ERROR)
            return
        })
    },
    async requestLatestPaymentData({ commit, state }) {
        state.paymentData.isFetching = PAYMENT_FETCH_STATUSES.FETCH
        apiRdpManagement.get('/payment/latest?limit=20')
        .then((res) => {
            commit("setPaymentData", { data: res.data.data, status: PAYMENT_FETCH_STATUSES.DONE })
            return
        })
        .catch(() => {
            commit('setPaymentDataError', PAYMENT_FETCH_STATUSES.DONE)
            return
        })
    }
}

const mutations = {
    setRdpData(state, payload) {
        const getterUser = store.getters['user/getUser']
        const getterUserSf = store.getters['contact/getContact']
        state.rdpData.cardType = setPricingPackage(getterUserSf)
        state.rdpData.accountNumber = payload.data.account_number
        state.rdpData.fullName = getName(getterUser)
        state.rdpData.balance = payload.data.last_balance
        state.rdpData.createdDate = payload.data.created_at
        state.rdpData.feedbackStatus = payload.data.feedback_status
        state.rdpData.isExist = payload.status
    },
    setRdpDataError(state, payload) {
        state.rdpData.isExist = payload
    },
    setPaymentData(state, payload) {
        state.paymentData.notification = false
        state.paymentData.data = setPaymentGrouping(state, payload.data)
        state.paymentData.isFetching = payload.status
    },
    setPaymentDataError(state, payload) {
        state.paymentData.notification = false
        state.paymentData.isFetching = payload
        state.paymentData.data = []
    }
}

const setPricingPackage = (userSf) => {
    const pricingPackage = userSf.Pricing_Package__c ? userSf.Pricing_Package__c.toUpperCase() : null
    if(!pricingPackage) return PACKAGE_LIST[PACKAGE_LIST.length - 1]

    let cardType = null
    PACKAGE_LIST.every((packageList) => {
        if(!pricingPackage.includes(packageList)) return true
        cardType = packageList
        return false
    })

    if(!cardType) cardType = PACKAGE_LIST[PACKAGE_LIST.length - 1]
    return cardType
}

const getName = (getterUser) => {
    const firstName = getterUser.first_name ? getterUser.first_name.trim() : ""
    const lastName = getterUser.last_name ? getterUser.last_name.trim() : ""
    return (firstName + " " + lastName).trim()
}

const setPaymentGrouping = (state, payment) => {
    if(payment.length == 0) return []

    const dateNow = moment().format('YYYY-MM-DD')
    const dateSubstact = moment().subtract(10, 'months').format('YYYY-MM-DD')
    let result = []

    for (let index = 0; index < payment.length; index++) {
        if(!moment(payment[index].created_at, 'YYYY-MM-DD').isBetween(dateSubstact, dateNow, undefined, '[]')) continue
        if(payment[index].status == PAYMENT_STATUSES.failed || payment[index].status == PAYMENT_STATUSES.expired) continue
        if(payment[index].status == PAYMENT_STATUSES.cancelled && !payment[index].approver_2) continue

        const dataPayment = {
            beneficiary_name: payment[index].beneficiary_name,
            amount: payment[index].amount,
            remark: payment[index].remark,
            approver_1: payment[index].approver_1,
            approver_2: payment[index].approver_2,
            status: payment[index].status,
            processed_at: payment[index].processed_at,
            completed_at: payment[index].completed_at,
            created_at: payment[index].created_at,
            updated_at: payment[index].updated_at,
            approval_token: payment[index].approval_token
        }

        if(dataPayment.status === PAYMENT_STATUSES.pending && !dataPayment.approver_2) state.paymentData.notification = true

        const paymentGroup = createPaymentGroupBy(dataPayment.created_at)
        
        if(result.length == 0){
            const temp = { group: paymentGroup, data: [dataPayment] }
            result.push(temp)
            continue
        }

        const isGroupExist = result.findIndex(object => object.group === paymentGroup)
        if(isGroupExist < 0){
            const temp = { group: paymentGroup, data: [dataPayment] }
            result.push(temp)
            continue
        }

        result[isGroupExist].data.push(dataPayment)
    }

    return result
}

const createPaymentGroupBy = (dateTime) => {
    const today = moment().format('YYYY-MM-DD')
    const startOfThisMonth = moment().startOf('month').format('YYYY-MM-DD')
    const endOfThisMonth = moment().endOf('month').format('YYYY-MM-DD')

    if(moment(dateTime).format('YYYY-MM-DD') == today) return 'Hari Ini'
    if(moment(dateTime, 'YYYY-MM-DD').isBetween(startOfThisMonth, endOfThisMonth, undefined, '[]')) return 'Bulan Ini'
    return moment(dateTime).format('MMMM')
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
