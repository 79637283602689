<template>
    <nav class="bg-amalan-blue-7">
        <div class="px-4 py-3 mx-auto">
            <div class="flex items-center justify-between">
                <button @click="$router.go(-1)" class="flex items-center justify-center w-6 h-8">
                    <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                </button>
                <span class="text-base font-bold text-amalan-black">{{ $route.meta.title }}</span>
                <div class="flex items-center">
                    <div class="w-5 h-5 bg-transparent"></div>
                    <div class="relative hidden">
                        <button type="button" @blur="dropdownToggleBlur" @click="dropdownToggle" class="flex items-center focus:outline-none">
                            <div class="w-10 h-10 rounded-full">
                                <UserCircleIcon class="object-cover text-gray-500 hover:text-blue-900" />
                            </div>
                        </button>
                        <div class="absolute right-0 z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-md border-2" :class="{hidden : !dropdownShow}">
                            <router-link to="/akun" class="flex items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-200 transform hover:bg-gray-100">
                                <UserIcon class="w-5 h-5 mx-1" />
                                <span class="mx-1">
                                    Profil Saya
                                </span>
                            </router-link>
                            <a href="#" @click="logout" class="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-200 transform hover:bg-gray-100">
                                <LogoutIcon class="w-5 h-5 mx-1" />
                                <span class="mx-1">
                                    Sign Out
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import { UserCircleIcon } from '@heroicons/vue/solid';
import { UserIcon, LogoutIcon, ChevronLeftIcon } from '@heroicons/vue/outline';

export default {
    components: {
        UserCircleIcon,
        LogoutIcon,
        UserIcon,
        ChevronLeftIcon
    },
    data() {
        return {
            dropdownShow: false,
        }
    },
    computed: {
        ...mapGetters('user',{
            getterUser:'getUser'
        })
    },
    methods: {
        dropdownToggle(){
            this.dropdownShow = !this.dropdownShow
        },
        dropdownToggleBlur(){
            setTimeout(() => {
                if (this.dropdownShow){
                    this.dropdownShow = false;
                }
            }, 300);
        },
        logout: async function () {
            await this.$store.dispatch('auth/logout');

            window.location.href = '/login';
        }
    }
}
</script>