<script setup>
import { defineProps } from 'vue'
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers"
import { LineChart } from "echarts/charts"
import {
    TooltipComponent,
    GridComponent
} from "echarts/components"
import VChart from "vue-echarts"

const props = defineProps({
    class: String,
    chartOption: Object //refer to https://echarts.apache.org/en/option.html 
})

use([
    CanvasRenderer,
    LineChart,
    TooltipComponent,
    GridComponent
])
</script>

<template>
    <v-chart :class="props.class" :option="props.chartOption" />
</template>