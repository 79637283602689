<template>
    <div class="bg-blue-100 min-h-screen">
        <div class="justify-center flex pt-16 mb-14">
            <img class="h-16" src="../assets/img/logo-amalan.png">
        </div>
        <div class="rounded-tr-amalan-lg w-full min-h-screen bg-gray-50 pb-10">
            <div class="pt-12 px-6">
                <p class="text-sm leading-tight text-justify">Halo, Sobat <b>amalan</b> Untuk masuk ke dalam <b>amalan Super App</b>, Anda harus melengkapi beberapa data agar dapat menggunakan layanan dari <b>amalan international</b>. Jangan lupa persiapkan dokumen yang dibutuhkan.</p>
                <div class="mt-3 grid grid-cols-10">
                    <div>
                        <div class="rounded-full h-7 w-7 mx-auto border-2 border-teal-600 border-amalan-yellow-400 bg-amalan-yellow-400 text-center">
                            <span class="text-blue-900 font-bold">1</span>
                        </div>
                        <hr class="h-full w-1/12 mx-auto border-t-0 border-l-4 border-blue-900">
                    </div>
                    <div class="col-span-9 px-2 pb-4">
                        <h1 class="text-base font-bold text-blue-900">Masuk</h1>
                        <p class="text-sm text-justify leading-tight">Pada proses ini, Anda akan menginputkan nomor ponsel yang terintegrasi dengan WhatsApp atau alamat email agar mendapatkan kode OTP untuk registrasi atau masuk.</p>
                    </div>
                    <div>
                        <div class="rounded-full h-7 w-7 mx-auto border-2 border-teal-600 border-amalan-yellow-400 bg-amalan-yellow-400 text-center">
                            <span class="text-blue-900 font-bold">2</span>
                        </div>
                        <hr class="h-full w-1/12 mx-auto border-t-0 border-l-4 border-blue-900">
                    </div>
                    <div class="col-span-9 px-2 pb-4">
                        <h1 class="text-base font-bold text-blue-900">e-KTP & Swafoto</h1>
                        <p class="text-sm text-justify leading-tight">Anda akan diminta untuk mengunggah foto e-KTP dan Swafoto sebagai dokumen verifikasi data Anda.</p>
                    </div>
                    <div>
                        <div class="rounded-full h-7 w-7 mx-auto border-2 border-teal-600 border-amalan-yellow-400 bg-amalan-yellow-400 text-center">
                            <span class="text-blue-900 font-bold">3</span>
                        </div>
                        <hr class="h-full w-1/12 mx-auto border-t-0 border-l-4 border-blue-900">
                    </div>
                    <div class="col-span-9 px-2 pb-4">
                        <h1 class="text-base font-bold text-blue-900">Lengkapi Data</h1>
                        <p class="text-sm text-justify leading-tight">Pada langkah ini Anda akan diminta untuk melengkapi data sesuai dengan e-KTP yang sudah Anda unggah sebelumnya.</p>
                    </div>
                    <div>
                        <div class="rounded-full h-7 w-7 mx-auto border-2 border-teal-600 border-amalan-yellow-400 bg-amalan-yellow-400 text-center">
                            <span class="text-blue-900 font-bold">4</span>
                        </div>
                    </div>
                    <div class="col-span-9 px-2 pb-4">
                        <h1 class="text-base font-bold text-blue-900">Konfirmasi Data</h1>
                        <p class="text-sm text-justify leading-tight">Jika data Anda sudah lengkap, Anda dapat melakukan konfirmasi data untuk melanjutkan pembelian layanan dari amalan international.</p>
                    </div>
                </div>
                <router-link to="/login" class="mt-2 bg-blue-900 hover:bg-blue-600 w-full h-11 rounded-lg flex justify-center items-center">
                    <span class="text-white font-semibold">Mulai Sekarang</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>

export default {
}
</script>