import LoanManagementHome from '@/views/Akun/ManajemenPinjaman/Home.vue'
import LoanListHome from '@/views/Akun/ManajemenPinjaman/LoanList/Home.vue'
import LoanListAddLoan from '@/views/Akun/ManajemenPinjaman/LoanList/AddLoan.vue'
import LoanListDetail from '@/views/Akun/ManajemenPinjaman/LoanList/Detail.vue'
import AdditionalSufPaymentPage from '@/views/Akun/ManajemenPinjaman/LoanList/Payment.vue'
import AdditionalSufTandaTangan from '@/views/Akun/ManajemenPinjaman/LoanList/TandaTangan.vue'
import AdditionalSufFinishPage from '@/views/Akun/ManajemenPinjaman/LoanList/FinishPage.vue'
import LoanProgressHome from '@/views/Akun/ManajemenPinjaman/LoanProgress/Home.vue'
import UpgradePackageHome from '@/views/Akun/ManajemenPinjaman/UpgradePackage/Home.vue'
import UpgradePackagePaymentPage from '@/views/Akun/ManajemenPinjaman/UpgradePackage/Payment.vue'
import SavingActivityHome from '@/views/Akun/ManajemenPinjaman/SavingActivity/Home.vue'

export default [
    {
        path: '/loan-management/home',
        name: 'Loan Management - Home',
        component: LoanManagementHome,
        meta: {
            name: 'Loan Management - Home',
            isAuth: true,
            title: 'Manajemen Pinjaman',
            loanManagement: true
        }
    },
    {
        path: '/loan-management/additional-suf/payment',
        name: 'Loan Management - Additional Suf - Payment Page',
        component: AdditionalSufPaymentPage,
        meta: {
            name: 'Loan Management - Additional Suf - Payment Page',
            isAuth: true,
            title: 'Manajemen Pinjaman',
            loanManagement: true,
            mustSignup: true,
            mustWaitLastTransactionData: true
        }
    },
    {
        path: '/loan-management/additional-suf/sign',
        name: 'Loan Management - Additional Suf - Sign',
        component: AdditionalSufTandaTangan,
        meta: {
            name: 'Loan Management - Additional Suf - Sign',
            isAuth: true,
            title: 'Manajemen Pinjaman',
            loanManagement: true,
            mustSignup: true,
            mustWaitLastTransactionData: true
        }
    },
    {
        path: '/loan-management/additional-suf/finish',
        name: 'Loan Management - Additional Suf - Finish Page',
        component: AdditionalSufFinishPage,
        meta: {
            name: 'Loan Management - Additional Suf - Finish Page',
            isAuth: true,
            title: 'Manajemen Pinjaman',
            loanManagement: true,
            mustSignup: true,
            mustWaitLastTransactionData: true
        }
    },
    {
        path: '/loan-management/loan-list/home',
        name: 'Loan Management - Loan List - Home',
        component: LoanListHome,
        meta: {
            name: 'Loan Management - Loan List - Home',
            isAuth: true,
            title: 'Manajemen Pinjaman',
            loanManagement: true,
            mustSignup: true,
            mustWaitLastTransactionData: true
        }
    },
    {
        path: '/loan-management/loan-list/add',
        name: 'Loan Management - Loan List - Add Loan',
        component: LoanListAddLoan,
        meta: {
            name: 'Loan Management - Loan List - Add Loan',
            isAuth: true,
            title: 'Manajemen Pinjaman',
            loanManagement: true,
            mustSignup: true,
            mustWaitLastTransactionData: true
        }
    },
    {
        path: '/loan-management/loan-list/detail/:opportunityId',
        name: 'Loan Management - Loan List - Detail',
        component: LoanListDetail,
        meta: {
            name: 'Loan Management - Loan List - Detail',
            isAuth: true,
            title: 'Manajemen Pinjaman',
            loanManagement: true,
            mustSignup: true
        }
    },
    {
        path: '/loan-management/loan-progress/home',
        name: 'Loan Management - Loan Progress - Home',
        component: LoanProgressHome,
        meta: {
            name: 'Loan Management - Loan Progress - Home',
            isAuth: true,
            title: 'Manajemen Pinjaman',
            loanManagement: true,
            mustSignup: true
        }
    },
    {
        path: '/loan-management/upgrade-package/home',
        name: 'Loan Management - Upgrade Package - Home',
        component: UpgradePackageHome,
        meta: {
            name: 'Loan Management - Upgrade Package - Home',
            isAuth: true,
            title: 'Manajemen Pinjaman',
            loanManagement: true,
            mustSignup: true,
            mustWaitLastTransactionData: true
        }
    },
    {
        path: '/loan-management/upgrade-package/payment',
        name: 'Loan Management - Upgrade Package - Payment Page',
        component: UpgradePackagePaymentPage,
        meta: {
            name: 'Loan Management - Upgrade Package - Payment Page',
            isAuth: true,
            title: 'Manajemen Pinjaman',
            loanManagement: true,
            mustSignup: true,
            mustWaitLastTransactionData: true
        }
    },
    {
        path: '/loan-management/saving-activity/home',
        name: 'Loan Management - Saving Activity - Home',
        component: SavingActivityHome,
        meta: {
            name: 'Loan Management - Saving Activity - Home',
            isAuth: true,
            title: 'Manajemen Pinjaman',
            loanManagement: true
        }
    }
];