<template>
    <div>
        <nav class="bg-amalan-blue-7">
            <div class="px-4 py-4 mx-auto">
                <div class="flex items-center justify-between">
                    <button @click="$router.push({ name: 'Home' })" class="flex items-center mt-0">
                        <ChevronLeftIcon class="h-5 w-5 text-amalan-black"/>
                    </button>
                    <span class="text-sm font-bold mt-0 text-amalan-black">{{ $route.meta.title }}</span>
                    <div class="flex items-center mt-0">
                        <div class="w-5 h-5 bg-transparent"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div v-if="dataService" class="pt-4 min-h-screen h-auto relative bg-amalan-white">
            <div class="mt-2 pb-12 w-full px-4">
                <div class="flex items-center">
                    <div class="flex items-center text-amalan-blue-1 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/credit-checkup/booking-date-active.svg" class="w-6 h-6">
                        </div>
                        <div v-if="dataService.service_catalog.name == 'Credit Checkup'" class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-amalan-blue-1"><p>Booking<br>Konsultasi</p></div>
                        <div v-else class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-amalan-blue-1"><p>Ringkasan<br>Layanan</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-amalan-blue-1"></div>
                    <div class="flex items-center text-amalan-blue-1 relative">
                        <div class="rounded-full h-12 w-12 border-2 border-amalan-yellow-400 bg-amalan-yellow-400 flex justify-center items-center">
                            <img src="@/assets/img/credit-checkup/pembayaran-layanan-active.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-bold text-amalan-blue-1"><p>Pembayaran<br>Layanan</p></div>
                    </div>
                    <div class="flex-auto border-t-2 border-amalan-gray-3"></div>
                    <div class="flex items-center text-amalan-gray-2 relative">
                        <div class="rounded-full h-12 w-12 border-2 bg-amalan-gray-5 border-amalan-gray-5 flex justify-center items-center">
                            <img src="@/assets/img/verified.svg" class="w-6 h-6">
                        </div>
                        <div class="absolute top-0 -ml-10 text-center mt-14 w-32 text-xs text-amalan-gray-2"><p>Konfirmasi<br>Layanan</p></div>
                    </div>
                </div>
            </div>
            <div class="w-full mt-6 px-4">
                <div class="bg-amalan-white shadow-amalan-md rounded-xl px-4 py-4">
                    <p class="text-base font-bold text-amalan-blue-1 text-center">Ringkasan Pembayaran</p>
                    <p class="w-full text-xs font-bold text-amalan-blue-1 mt-4 py-2 bg-amalan-yellow-100 rounded-lg text-center uppercase" v-if="dataService.promo_id">Diskon {{ dataService.promo.discount_amount }}% DARI {{ dataService.promo.description }}</p>
                    <div class="grid grid-cols-12 gap-y-1 text-xs mt-4">
                        <div class="font-bold col-span-4 text-amalan-black">Layanan</div>
                        <div class="col-span-8 flex text-amalan-black">
                            <span class="mr-1">:</span> 
                            <span>{{ dataService.service_catalog.name }}</span>
                        </div>
                        <div class="font-bold col-span-4 text-amalan-black">Harga</div>
                        <div class="col-span-8 flex text-amalan-black">
                            <span class="mr-1">:</span> 
                            <span>{{ $filters.format_number(dataService.service_catalog.base_price) }}</span>
                        </div>
                        <div class="font-bold col-span-4 text-amalan-black">Potongan</div>
                        <div class="col-span-8 flex text-amalan-black">
                            <span class="mr-1">:</span> 
                            <span>{{ dataService.promo_id ? $filters.format_number(dataService.service_catalog.base_price - dataService.price) + ' (potongan ' + dataService.promo.discount_amount + '%)' : 'Rp 0' }}</span>
                        </div>
                    </div>
                    <div class="h-px w-full bg-amalan-gray-4 my-4"></div>
                    <div class="grid grid-cols-12 text-xs">
                        <div class="font-bold col-span-4 text-amalan-black">Total</div>
                        <div class="col-span-8 flex text-amalan-black">
                            <span class="mr-1">:</span> 
                            <span>{{ $filters.format_number(dataService.price) }}</span>
                        </div>
                    </div>
                </div>
                <button type="button" class="w-full rounded-lg font-bold border border-solid text-center text-sm py-3 mt-8 text-amalan-white bg-amalan-blue-1 border-amalan-blue-1 cursor-pointer hover:bg-blue-900 hover:border-blue-900" @click="nextButton">{{ freePrice ? 'Lanjutkan' : 'Pilih Metode Pembayaran' }}</button>
                <div v-if="isPaymentPage" class="fixed inset-0 z-50 bg-amalan-white min-h-screen w-screen flex items-center justify-center">
                    <div class="max-w-sceen h-full overflow-visible">
                        <iframe class="w-screen h-5/6 border-b-4 bg-amalan-white" :src="paymentUrl" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                        <div class="absolute bottom-0 pb-10 w-full flex justify-center bg-amalan-white">
                            <button class="border text-amalan-white px-6 bg-amalan-blue-1 border-amalan-blue-1 hover:bg-blue-900 hover:border-blue-900 rounded-lg py-2 text-sm font-semibold" @click="onCheckPayment">Konfirmasi Pembayaran</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <loading-overlay v-if="isLoading" />
    </div>
</template>

<script>
import { ChevronLeftIcon } from '@heroicons/vue/solid'
import axios from "../../axios"
import { mapGetters } from 'vuex'

export default {
    components: {
        ChevronLeftIcon
    },
    data(){
        return {
            isLoading: true,
            dataService: null,
            isPaymentPage: false,
            paymentUrl: null,
            freePrice: false,
            catalog: 'credit-checkup',
            swalErrorReload: this.$swal.mixin({
                customClass: {
                    confirmButton: 'bg-blue-900 mx-2 py-2 px-5 rounded-lg border border-blue-900 text-white text-sm font-semibold hover:bg-blue-600 hover:border-blue-600'
                },
                buttonsStyling: false,
                title: '<img src="/img/alert-error-reload-icon.svg" class="w-12 h-12 mx-auto">',
                html: '<p class="text-center text-sm mt-2">Maaf, server gagal mengambil data, klik tombol dibawah untuk memperbaharui!</p>',
                showCancelButton: false,
                confirmButtonText: 'Perbaharui',
                width: '340px',
                allowOutsideClick: false,
            })
        }
    },
    computed: {
        ...mapGetters('creditCheckup',{
            CBStatus: 'getCBStatus'
        }),
        ...mapGetters('user',{
            dataUser: 'getUser',
            idCardUser: 'getIdCard'
        }),
        ...mapGetters('catalog',{
            catalogs: 'getCatalog'
        }),
    },
    methods: {
        onCheckPayment(){
            this.isPaymentPage = false
            this.isLoading = true
            this.axios.get('services/payment/check?serviceId=' + this.dataService.id)
            .then(resp => {
                this.isLoading = false
                if(resp.data.data.isPaid){
                    this.$store.dispatch('creditCheckup/requestCBResultSync').then(() => {
                        this.$router.push({ name: 'credit checkup - credit score' })
                    })
                }
            }).catch(() => {
                this.isLoading = false
            });
        },
        nextButton(){
            if(this.freePrice){
                this.$store.dispatch('creditCheckup/requestCBResultSync').then(() => {
                    this.$router.push({ name: 'credit checkup - credit score' })
                })
            }else{
                this.isPaymentPage = true
            }
        },
        checkCBStatus(){
            if(this.CBStatus == 'found'){
                this.$router.push({ name: 'credit checkup - credit score' })
            }else{
                this.createService()
            }
        },
        createService(){
            this.isLoading = true
            const catalog = this.catalogs.filter(elem => elem.serviceName == 'Credit Score')
            let data = {
                promoType: localStorage.getItem('promoType') ?? '',
                promoCode: localStorage.getItem('promoCode') ?? '',
                serviceCatalogId : catalog[0].serviceCatalogId,
                email: this.dataUser.email,
                fullName: this.dataUser.first_name + ' ' + this.dataUser.last_name,
                idNumber: this.idCardUser.number,
                gender: this.dataUser.gender,
                phone: this.dataUser.phone_number,
                startDate: null
            }

            axios.post('/services/store', data)
            .then((resp) => {
                if(localStorage.getItem('promoType')){
                    localStorage.removeItem('promoType')
                }
                if(localStorage.getItem('promoCode')){
                    localStorage.removeItem('promoCode')
                }
                
                this.getService(resp.data.data.serviceId)
            })
            .catch(() => {
                this.isLoading = false
                this.swalErrorReload.fire().then((result) => {
                    if (result.isConfirmed){
                        window.location.reload();
                    }
                });
            })
        },
        getService(serviceId){
            axios.get('/customer/services/' + serviceId)
            .then((resp) => {
                this.isLoading = false
                this.dataService = resp.data.data
                const additionalData = JSON.parse(resp.data.data.additional_data)
                this.catalog = this.dataService.service_catalog.name == 'Credit Checkup' ? 'credit-checkup' : 'credit-score'
                if(this.dataService.price > 0){
                    this.freePrice = false
                    this.paymentUrl = additionalData.cc_xendit[0].invoice_url
                }else{
                    this.freePrice = true
                    this.paymentUrl = null
                }
            }).catch(() => {
                this.isLoading = false
                this.$router.push({ name : 'Catalog List' })
            });
        }
    },
    created(){
        this.isLoading = true
        this.checkCBStatus()
    }
}
</script>
