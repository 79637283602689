<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const notificationData = computed(() => store.getters['pendingUserActivity/getPendingActivityData'])

</script>

<template>
    <section class="block fixed inset-x-0 mx-auto w-full max-w-lg bottom-0 z-10 bg-amalan-white shadow-amalan-md">
		<div class="flex justify-between">
			<router-link to="/home" class="w-full hover:text-amalan-blue-1 hover:bg-amalan-gray-5 justify-center inline-block text-center pt-3 pb-2" :class="[ $route.name == 'Home' ? 'text-amalan-blue-1 bg-amalan-gray-5' : 'text-amalan-gray-3']">
				<img v-if="$route.name == 'Home'" src="../assets/img/home-active.svg" class="h-6 w-6 inline-block mb-1">
				<img v-else src="../assets/img/home.svg" class="h-6 w-6 inline-block mb-1">
				<span class="block text-xs" :class="[ $route.name == 'Home' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]">Beranda</span>
			</router-link>
			<!-- <router-link to="/pembelian" class="w-full hover:text-amalan-blue-1 hover:bg-amalan-gray-5 justify-center inline-block text-center pt-3 pb-2" :class="[ $route.name == 'Pembelian' ? 'text-amalan-blue-1 bg-amalan-gray-5' : 'text-amalan-gray-3']">
				<img v-if="$route.name == 'Pembelian'" src="../assets/img/riwayat-active.svg" class="h-6 w-6 inline-block mb-1">
				<img v-else src="../assets/img/riwayat.svg" class="h-6 w-6 inline-block mb-1">
				<span class="block text-xs" :class="[ $route.name == 'Pembelian' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]">Riwayat</span>
			</router-link> -->
			<router-link to="/aktivitas" class="w-full hover:text-amalan-blue-1 hover:bg-amalan-gray-5 justify-center inline-block text-center pt-3 pb-2" :class="[ $route.name == 'Aktivitas' ? 'text-amalan-blue-1 bg-amalan-gray-5' : 'text-amalan-gray-3']">
				<div class="relative inline-block">
					<div v-if="($route.name !== 'Aktivitas' && notificationData.length > 0)" class="absolute h-2 w-2 bg-amalan-red rounded-full top-0 right-0"></div>
					<img v-if="$route.name == 'Aktivitas'" src="../assets/img/riwayat-active.svg" class="h-6 w-6 inline-block mb-1">
					<img v-else src="../assets/img/riwayat.svg" class="h-6 w-6 inline-block mb-1">
				</div>
				<span class="block text-xs" :class="[ $route.name == 'Aktivitas' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]">Aktivitas</span>
			</router-link>
			<router-link to="/score" class="w-full hover:text-amalan-blue-1 hover:bg-amalan-gray-5 justify-center inline-block text-center pt-3 pb-2" :class="[ $route.name == 'Score' ? 'text-amalan-blue-1 bg-amalan-gray-5' : 'text-amalan-gray-3']">
				<img v-if="$route.name == 'Score'" src="../assets/img/score-active.svg" class="h-6 w-6 inline-block mb-1">
				<img v-else src="../assets/img/score.svg" class="h-6 w-6 inline-block mb-1">
				<span class="block text-xs" :class="[ $route.name == 'Score' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]">Score</span>
			</router-link>
			<router-link to="/pesan" class="w-full hover:text-amalan-blue-1 hover:bg-amalan-gray-5 justify-center inline-block text-center pt-3 pb-2" :class="[ $route.name == 'Pesan' ? 'text-amalan-blue-1 bg-amalan-gray-5' : 'text-amalan-gray-3']">
				<img v-if="$route.name == 'Pesan'" src="../assets/img/pesan-active.svg" class="h-6 w-6 inline-block mb-1">
				<img v-else src="../assets/img/pesan.svg" class="h-6 w-6 inline-block mb-1">
				<span class="block text-xs" :class="[ $route.name == 'Pesan' ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]">Pesan</span>
			</router-link>
			<router-link to="/akun" class="w-full hover:text-amalan-blue-1 hover:bg-amalan-gray-5 justify-center inline-block text-center pt-3 pb-2" :class="[ $route.path.includes('akun') ? 'text-amalan-blue-1 bg-amalan-gray-5' : 'text-amalan-gray-3']">
				<img v-if="$route.path.includes('akun')" src="../assets/img/akun-active.svg" class="h-6 w-6 inline-block mb-1">
				<img v-else src="../assets/img/akun.svg" class="h-6 w-6 inline-block mb-1">
				<span class="block text-xs" :class="[ $route.path.includes('akun') ? 'text-amalan-blue-1' : 'text-amalan-gray-2' ]">Akun</span>
			</router-link>
		</div>
	</section>
</template>