<script setup>
import { defineProps, ref } from 'vue'
import { ChevronRightIcon } from '@heroicons/vue/solid'
import { useRouter } from 'vue-router'

const router = useRouter()
const props = defineProps({
    cardType: String,
    fullName: String,
    accountNumber: String,
    routeBack: String
})

const CARD_TYPE = ref({
    PLATINUM: {
        displayCardType: 'Platinum',
        decorationImage: 'decoration-platinum-mini.svg',
        firstDecorationClass: 'left-0 bottom-0 xs:-bottom-2 ssm:-bottom-4 sm:-bottom-6 w-full',
        secondDecorationClass: 'hidden',
        icon: 'platinum.svg'
    },
    GOLD: {
        displayCardType: 'Gold',
        decorationImage: 'decoration-gold-mini.svg',
        firstDecorationClass: 'left-0 top-0',
        secondDecorationClass: 'hidden',
        icon: 'gold.svg'
    },
    SILVER: {
        displayCardType: 'Silver',
        decorationImage: 'decoration-silver-mini.svg',
        firstDecorationClass: 'left-0 -bottom-9',
        secondDecorationClass: '-bottom-9 right-0 transform -scale-x-100',
        icon: 'silver.svg'
    },
    BASIC: {
        displayCardType: 'Basic',
        decorationImage: 'decoration-basic-mini.svg',
        firstDecorationClass: 'my-auto -inset-y-0 -left-12',
        secondDecorationClass: 'hidden',
        icon: 'basic.svg'
    },
    DIAMOND: {
        displayCardType: 'Diamond',
        decorationImage: 'decoration-diamond-mini.svg',
        firstDecorationClass: 'left-0 top-0',
        secondDecorationClass: 'hidden',
        icon: 'diamond.svg'
    }
})

</script>

<template>
    <div @click="router.push({ name: 'rdp - home', query: { from: routeBack ?? undefined } })" class="w-full bg-amalan-blue-1 px-4 py-4 rounded-xl shadow-amalan-sm relative overflow-hidden hover:bg-amalan-blue-2 cursor-pointer">
        <img :src="require('@/assets/img/rdp/kartu/' + CARD_TYPE[props.cardType].decorationImage)" class="absolute z-1" :class="CARD_TYPE[props.cardType].firstDecorationClass">
        <img :src="require('@/assets/img/rdp/kartu/' + CARD_TYPE[props.cardType].decorationImage)" class="absolute z-1" :class="CARD_TYPE[props.cardType].secondDecorationClass">
        <div class="flex justify-between items-center">
            <div class="flex-grow mr-2">
                <div class="flex items-center">
                    <img :src="require('@/assets/img/rdp/kartu/' + CARD_TYPE[props.cardType].icon)" class="h-2.5">
                    <span class="text-xs text-amalan-white ml-1 font-mono">{{ CARD_TYPE[props.cardType].displayCardType }}</span>
                </div>
                <p class="font-mono text-amalan-white text-xs my-2">Rekening Dana Pelunasan</p>
                <p class="font-mono text-amalan-white text-xs">{{ props.fullName.toUpperCase() }} | {{ props.accountNumber }}</p>
            </div>
            <div class="flex-none w-6 h-6 relative">
                <button class="border border-amalan-white rounded-lg shadow-amalan-md w-6 h-6 flex justify-center items-center bg-amalan-blue-1 absolute z-2">
                    <ChevronRightIcon class="h-5 text-amalan-white" />
                </button>
            </div>
        </div>
    </div>
</template>