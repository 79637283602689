import apiMediationService from "@/axios/mediationService"
import axios from "@/axios"

const state = () => ({
    negotiation_process: [],
    credit_checkup: []
})

const getters = {
    getNegotiationProcess(state) {
        return state.negotiation_process
    },
    getCreditCheckup(state) {
        return state.credit_checkup
    }
}

const actions = {
    async setNegotiationProcess({ commit }){
        try {
            const result = await apiMediationService.get('api/faq/negotiation-process')
            commit('setNegotiationProcess', result.data.data)
        } catch {
            commit('setNegotiationProcess', [])
        }
    },
    async setCreditCheckup({ commit }){
        try {
            const result = await axios.get('services/faq')
            commit('setCreditCheckup', result.data.data)
        } catch {
            commit('setCreditCheckup', [])
        }
    }
}

const mutations = {
    setNegotiationProcess(state, payload){
        state.negotiation_process = payload
    },
    setCreditCheckup(state, payload){
        state.credit_checkup = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
