<template>
    <div class="flex justify-center">
        <div class="w-full max-w-lg shadow-xl min-h-screen h-auto">
            <NavbarWithBack />
            <div>
                <Body />
            </div>
        </div>
    </div>
</template>

<script>
import NavbarWithBack from '../NavbarWithBack';
import Body from '../Body';

export default {
    components: {
        Body,
        NavbarWithBack
    },
};
</script>